import React, { useEffect, useState } from 'react'

function Impuesto(props) {
    const [precio, setPrecio] = useState('');
    const [impuesto, setImpuesto] = useState('');

    useEffect(()=>{
        if(props){
            setImpuesto(props.impuesto);
            setPrecio(props.precio);
        }
    }, [props])

    const percent = ()=>{
        return impuesto.valor * precio / 100
    }

    const total = ()=>{
        let total = precio + percent()
        return `${props.moneda}. ${parseFloat(total).toFixed(2)}`
    }

  return (
    <>
        <strong>Precio unitario por ticket: </strong>
        <h5 className = "text-primary"><strong>{props.precioBoleto}</strong></h5>
        <strong>Servicio de taquilla: </strong>
        <h5 className = "text-primary"><strong>{impuesto.valor} % ({props.moneda}. {percent()})</strong></h5>
        <strong>Total: </strong>
        <h5 className = "text-primary"><strong>{total()}</strong></h5>
    </>
  )
}

export default Impuesto