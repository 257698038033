import { requerido, numero, mensObligatorio, numericoIncorrecto } from "./Validaciones";
import { Post } from "./Generales";
import { Url } from "./Url";

export const AddNewSitio = (campos, callback) => {
    let errores = {
        cantidad: 0,
        errMesss: []
    };

    /* Validaciones */
    requerido   (campos.nombre, errores,        mensObligatorio("Nombre del Sitio"), (resp) => { errores = resp })
    requerido   (campos.direccion, errores,     mensObligatorio("Dirección"), (resp) => { errores = resp })
    requerido   (campos.min, errores,           mensObligatorio("Capacidad Mínima"), (resp) => { errores = resp })
    requerido   (campos.max, errores,           mensObligatorio("Capacidad Máxima"), (resp) => { errores = resp })
    requerido   (campos.paisSelect.value, errores, mensObligatorio("País"), (resp) => { errores = resp })
    requerido   (campos.estadoSelect.value, errores, mensObligatorio("Estado"), (resp) => { errores = resp })
    requerido   (campos.municipioSelect.value, errores, mensObligatorio("Municipio"), (resp) => { errores = resp })
    requerido   (campos.parroquiaSelect.value, errores, mensObligatorio("Parroquia"), (resp) => { errores = resp })
    numero      (campos.latitud, errores,       numericoIncorrecto("Latitud"), (resp) => { errores = resp })
    numero      (campos.longitud, errores,      numericoIncorrecto("Longitud"), (resp) => { errores = resp })
    numero      (campos.min, errores,           numericoIncorrecto("Capacidad Mínima"), (resp) => { errores = resp })
    numero      (campos.max, errores,           numericoIncorrecto("Capacidad Máxima"), (resp) => { errores = resp })

    if (errores.cantidad === 0){
        let data = JSON.parse(sessionStorage.getItem("ContentData"));

        let Content = {
            eid: data.E_ID,
            uid: data.U_ID,
            nombre: campos.nombre,
            dir: campos.direccion,
            latitud: campos.latitud,
            longitud: campos.longitud,
            map: campos.maps,
            min: campos.min,
            max: campos.max,
            pais: campos.paisSelect.value,
            estado: campos.estadoSelect.value,
            municipio: campos.municipioSelect.value,
            parroquia: campos.parroquiaSelect.value
        }

        Post(`${Url}addNewSite`, Content, (resp) => {
            let data = JSON.parse(resp)
            callback([errores, data])
        });

    }else{
        callback([errores])
    }
}

export const Sitios = (callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID
    }

    Post(`${Url}sites`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const SitiosFilter = (pais, estado, municipio, parroquia, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        pais: pais,
        estado: estado,
        municipio: municipio,
        parroquia: parroquia
    }

    Post(`${Url}sitesFilter`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const EditSitio = (id, campos, callback) => {
    let errores = {
        cantidad: 0,
        errMesss: []
    };

    /* Validaciones */
    requerido   (id, errores,                   mensObligatorio("Id del Sitio"), (resp) => { errores = resp })
    requerido   (campos.nombre, errores,        mensObligatorio("Nombre del Sitio"), (resp) => { errores = resp })
    requerido   (campos.direccion, errores,     mensObligatorio("Dirección"), (resp) => { errores = resp })
    requerido   (campos.min, errores,           mensObligatorio("Capacidad Mínima"), (resp) => { errores = resp })
    requerido   (campos.max, errores,           mensObligatorio("Capacidad Máxima"), (resp) => { errores = resp })
    requerido   (campos.paisSelect.value, errores, mensObligatorio("País"), (resp) => { errores = resp })
    requerido   (campos.estadoSelect.value, errores, mensObligatorio("Estado"), (resp) => { errores = resp })
    requerido   (campos.municipioSelect.value, errores, mensObligatorio("Municipio"), (resp) => { errores = resp })
    requerido   (campos.parroquiaSelect.value, errores, mensObligatorio("Parroquia"), (resp) => { errores = resp })
    numero      (campos.latitud, errores,       numericoIncorrecto("Latitud"), (resp) => { errores = resp })
    numero      (campos.longitud, errores,      numericoIncorrecto("Longitud"), (resp) => { errores = resp })
    numero      (campos.min, errores,           numericoIncorrecto("Capacidad Mínima"), (resp) => { errores = resp })
    numero      (campos.max, errores,           numericoIncorrecto("Capacidad Máxima"), (resp) => { errores = resp })

    if (errores.cantidad === 0){
        let data = JSON.parse(sessionStorage.getItem("ContentData"));

        let Content = {
            id: id,
            eid: data.E_ID,
            uid: data.U_ID,
            nombre: campos.nombre,
            dir: campos.direccion,
            latitud: campos.latitud,
            longitud: campos.longitud,
            map: campos.maps,
            min: campos.min,
            max: campos.max,
            pais: campos.paisSelect.value,
            estado: campos.estadoSelect.value,
            municipio: campos.municipioSelect.value,
            parroquia: campos.parroquiaSelect.value
        }

        Post(`${Url}editSite`, Content, (resp) => {
            let data = JSON.parse(resp)
            callback([errores, data])
        });

    }else{
        callback([errores])
    }
}

export const EditStatusSite = (id, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));
    let Content = {
        id: id,
        eid: data.E_ID,
        uid: data.U_ID
    }

    Post(`${Url}editStatusSite`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const DelSite = (id, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));
    let Content = {
        id: id,
        eid: data.E_ID,
        uid: data.U_ID
    }

    Post(`${Url}delSite`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const AddAudit = (id, campos, tipoConsulta, idEvento, callback) => {
    let errores = {
        cantidad: 0,
        errMesss: []
    };

    /* Validaciones */
    requerido   (campos.fila, errores,      mensObligatorio("Filas"), (resp) => { errores = resp })
    requerido   (campos.column, errores,    mensObligatorio("Columnas"), (resp) => { errores = resp })

    if (errores.cantidad === 0){
        let data = JSON.parse(sessionStorage.getItem("ContentData"));

        let Content = {
            eid: data.E_ID,
            uid: data.U_ID,
            idSite: id,
            agrupado: campos.check,
            capacidad: campos.cap,
            cantFilas: campos.fila,
            cantColumnas: campos.column,
            colSelect: JSON.stringify(campos.colSelect),
            filSelect: JSON.stringify(campos.filSelect),
            tamButaca: campos.tamButaca,
            radButaca: campos.radButaca,
            colorBut: campos.colorBut,
            auditorio: JSON.stringify(campos.audit),
            zona: campos.zonaSelect.value,
            alternado: campos.alternar,
            estilo: campos.tipoButaca.value,
            tipoConsulta: tipoConsulta,
            idEvento: idEvento,
            sillasDisabled: JSON.stringify(campos.sillasDisabled),
            cantSillas: JSON.stringify(campos.cantSillas)
        }

        Post(`${Url}addAudit`, Content, (resp) => {
            let data = JSON.parse(resp)
            callback([errores, data])
        });
    }else{
        callback([errores])
    }
}

export const SearchAudit = (id, idZonaN, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idSite: id,
        idZonaN: idZonaN
    }

    Post(`${Url}searchAudit`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const SearchZones = (id, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idSite: id,
    }

    Post(`${Url}searchZones`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const SearchAuditInd = (id, idZona, tipoConsulta, idEvento, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idSite: id,
        idZona: idZona,
        tipoConsulta: tipoConsulta,
        idEvento: idEvento
    }

    Post(`${Url}searchAuditInd`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const SearchAuditCustom = (id, idZona, idEvento, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idSite: id,
        idZona: idZona,
        idEvento: idEvento
    }

    Post(`${Url}searchAuditCustom`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}


export const SearchAllZones = (callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID
    }

    Post(`${Url}searchAllZones`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const AddZoneAudit = (tipo, id, campos, tipoConsulta, idEvento, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        tipo: tipo,
        idSite: id,
        alto: campos.column,
        ancho: campos.fila,
        zonasDisp: JSON.stringify(campos.zonasDisp),
        zonaBuild: JSON.stringify(campos.zona),
        imgSrc: "",
        tipoConsulta: tipoConsulta,
        idEvento: idEvento
    }

    Post(`${Url}addZoneAudit`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const AddZoneAuditArea = (tipo, id, campos, tipoConsulta, idEvento, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));


    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        tipo: tipo,
        idSite: id,
        zonasDisp: JSON.stringify(campos.contentZones),
        zonaBuild: JSON.stringify(campos.mapa),
        imgSrc: campos.file1,
        ancho: campos.width,
        alto: campos.height,
        tipoConsulta: tipoConsulta,
        idEvento: idEvento
    }

    Post(`${Url}addZoneAudit`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const SearchZoneAudit = (id, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idSite: id
    }

    Post(`${Url}searchZoneAudit`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const AddNewZone = (nombre, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        nombre: nombre
    }

    Post(`${Url}addNewZone`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}