import React, { useEffect, useState } from "react";
import { Button, Row, Col, Table, InputGroup, InputGroupAddon, InputGroupText, Input, CardTitle } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faTimesCircle, faFilm, faLink, faCaretLeft, faSave, faMap, faCheck, faClock, faImage, } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { CKEditor } from 'ckeditor4-react';
import Select from 'react-select';
import Compressor from 'compressorjs';

import ConfirmModal from "components/Modals/ConfirmModal";
import ErroresList from "components/Errores/ErroresList";
import SuccessModal from "components/Modals/SuccessModal";

import { UrlImg } from "../../functions/Url";
import Funciones from "./Funciones";
import { formatBytes } from '../../functions/Generales';
import { ModEvent } from "functions/EventosFunctions";
import CustomMap from "components/Maps/CustomMap";
import InputTime from "components/Timers/Datetime";
import { ModEventDraft } from "functions/EventosFunctions";

// Para la fecha
let dateM2 = new Date();
let ddM2 = dateM2.getDate();
let mmM2 = dateM2.getMonth() + 1;
let aaaaM2 = dateM2.getFullYear();
let hM2 = dateM2.getHours();
let mM2 = dateM2.getMinutes();
if (hM2 < 10) hM2 = `0${hM2}`;
if (mM2 < 10) mM2 = `0${mM2}`;
if (ddM2 < 10) ddM2 = `0${ddM2}`;
if (mmM2 < 10) mmM2 = `0${mmM2}`;


const ContinueEvent = (props) => {
    const [botonMod, setBotonMod] = useState({ icon: faSave, text: "Guardar Evento", spin: false, disabled: false })
    const [botonModDraft, setBotonModDraft] = useState({ icon: faClock, text: "Guardar Borrador", spin: false, disabled: false })
    const [botonYes, setBotonYes] = useState({ icon: faCheck, text: "Si", spin: false, disabled: false })

    // ### DATOS PRINCIPALES ###

    // Nombre y URL
    const [nombre, setNombre] = useState("");
    const [url, setUrl] = useState("");

    // Productores
    const [proSelect, setProSelect] = useState([]);
    const [productores, setProductores] = useState([]);

    // Tipo de Evento
    const [tipoESelect, setTipoESelect] = useState([]);
    const [tipoE, setTipoE] = useState([]);

    // Categorias
    const [catSelect, setCatSelect] = useState([]);
    const [categorias, setCategorias] = useState([]);

    // Artistas
    const [artSelect, setArtSelect] = useState([]);
    const [artistas, setArtistas] = useState([]);

    // Edad Mínima
    const [minAge, setMinAge] = useState("");

    // ### LOCALIDAD DEL EVENTO ###

    //   Sitio (Recintos)
    const [sites, setSites] = useState([]);
    const [sitesSelect, setSitesSelect] = useState({ value: "", label: "Selecciona un Sitio", address: "", map: "" });

    // Sipnosis
    const [contentState, setContentState] = useState(props.eventData.draftDataJson.evento.descripcion ? props.eventData.draftDataJson.evento.descripcion : "");

    // ### FUNCIONES ###

    // Horarios y fechas
    const [horaIn, setHoraIn] = useState('12:00 AM');
    const [horaOut, setHoraOut] = useState('12:00 AM');
    const [horaApertura, setApertura] = useState('12:00 AM');
    const [desde, setDesde] = useState(`${aaaaM2}-${mmM2}-${ddM2}`);
    const [hasta, setHasta] = useState(`${aaaaM2}-${mmM2}-${ddM2}`);


    // Funciones del Evento
    const [funciones, setFunciones] = useState([]);
    const [diasEvents, setDiasEvents] = useState([]);

    // Terminos y Condiciones
    const [terminos, setTerminos] = useState(props.eventData.draftDataJson.evento.condiciones ? props.eventData.draftDataJson.evento.condiciones : "");

    // ### METODOS DE PAGO ###
    const [metSelect, setMetSelect] = useState([]);
    const [metodos, setMetodos] = useState([]);
    const [metodosPay, setMetodosPay] = useState([]);
    const [amountPay, setAmountPay] = useState("");
    const [monPSelect, setMonPSelect] = useState({ value: "", label: "- Selecciona -" });
    const [monedas, setMonedas] = useState([]);
    const [monSelect, setMonSelect] = useState([]);


    // ### IMAGENES ###
    const [file1, setFile1] = useState(`${UrlImg}events/default_img.jpg`);
    const [file2, setFile2] = useState(`${UrlImg}events/default_img.jpg`);
    const [plano, setPlano] = useState(`${UrlImg}events/default_img.jpg`);

    const [emailTicket, setEmailTicket] = useState(props.eventData.draftDataJson.evento.email_ticket ? props.eventData.draftDataJson.evento.email_ticket : "");


    // Modal para confirmar
    const [openModalCon, setOpenModalCon] = useState(false)
    const [openModalConDraft, setOpenModalConDraft] = useState(false)

    const [orgSelect, setOrgSelect] = useState([]);
    const [control, setControl] = useState(false);
    const [item1, setItem1] = useState({ nombre: "", tipo: "", size: "" });
    const [item2, setItem2] = useState({ nombre: "", tipo: "", size: "" });
    const [item3, setItem3] = useState({ nombre: "", tipo: "", size: "" });
    const [zonas, setZonas] = useState([]);
    const [zonas2, setZonas2] = useState([]);
    const [preloadSite, setPreloadSite] = useState({ icon: faCaretLeft, text: "Selecciona un sitio para ver los detalles" });
    const [errMess, setErrMess] = useState("")
    const [visibleErr, setVisibleErr] = useState("none")


    const [openModalOk, setOpenModalOk] = useState(false)
    const [modalOk, setModalOk] = useState("")
    const [modalMess, setMmodalMess] = useState("")
    const [dataEventE, setDataEventE] = useState([])

    // useEffect Optimizado
    useEffect(() => {
        const dataJson = props.eventData.draftDataJson;

        // Ordenado de tal forma que se muestre por el orden del formulario

        // ### DATOS PRINCIPALES ###

        // Nombre y URL
        setNombre(dataJson.evento.name_event)
        setUrl(dataJson.evento.url_event)

        /* Productores */
        let contentProduct = [];
        if (props.generalData?.productores) {
            (props.generalData?.productores).forEach(i => {
                contentProduct.push({
                    value: i.id, label: `${i.user_data.name} ${i.user_data.last_name}`
                })
            });
        }


        let productoresSelect = [];
        if (dataJson.productores) {
            (dataJson.productores).forEach(i => {
                const search = contentProduct.find(option => option.value === i.id_user);
                if (search !== undefined) productoresSelect.push(search)
            })
        }
        setProductores(contentProduct);
        setProSelect(productoresSelect)

        /* Tipo de Evento */
        let contentEvent = [];
        if (props.generalData?.eventsType) {
            (props.generalData?.eventsType).forEach(i => {
                if (i.id === +dataJson.evento.id_tipoE) setTipoESelect({ value: i.id, label: i.name_event })
                contentEvent.push({
                    value: i.id, label: i.name_event
                })
            });
        }

        setTipoE(contentEvent)

        /* Categorias */
        let contentCat = [];
        if (props.generalData?.categorias) {
            (props.generalData?.categorias).forEach(i => {
                contentCat.push({
                    value: i.id, label: i.name_category
                })
            });
        }
        let categoriasSelect = [];
        // Verifica si categorias_tags existe y no es una cadena vacía antes de hacer el split
        let tagCategorias = dataJson.evento.categorias_tags ? dataJson.evento.categorias_tags.split(', ') : [];

        if (tagCategorias) {
            tagCategorias.forEach(i => {
                const search = contentCat.find(option => option.label === i.trim());
                if (search !== undefined) {
                    categoriasSelect.push(search);
                }
            });
        }




        setCatSelect(categoriasSelect)
        setCategorias(contentCat)

        /* Artistas */
        let contentArt = [];

        if (props.generalData?.artistas) {
            (props.generalData?.artistas).forEach(i => {
                contentArt.push({
                    value: i.id, label: i.name_artist
                })
            });
        }


        let artistasSelect = [];
        // Verifica si artistas_tags existe y no es una cadena vacía antes de hacer el split
        let tagArtistas = dataJson.evento.artistas_tags ? dataJson.evento.artistas_tags.split(', ') : [];

        if (tagArtistas) {
            tagArtistas.forEach(i => {
                const search = contentArt.find(option => option.label === i);
                if (search !== undefined) artistasSelect.push(search)
            })

        }


        setArtSelect(artistasSelect)
        setArtistas(contentArt)

        /* Edad Mínima */
        setMinAge(dataJson.evento.min_age);

        // ### LOCALIDAD DEL EVENTO ###

        /* Sitio (Recintos) */
        let contentSite = [];
        if (props.generalData?.dataSites) {
            (props.generalData?.dataSites).forEach(i => {
                if (i.id === +dataJson.evento.id_recinto) setSitesSelect({ value: i.id, label: i.name, address: i.address, map: i.map })
                contentSite.push({
                    value: i.id, label: i.name, address: i.address, map: i.map
                })
            });
        }

        setSites(contentSite)

        // Sipnosis"
        // Se setea en el inicio del componente

        // ### FUNCIONES ###

        /* Funciones del Evento */
        let contentDias = []
        let funcions = funciones;
        let idE = 1;
        if (dataJson.funciones) {
            (dataJson.funciones).forEach(i => {
                contentDias.push({
                    id: idE,
                    dia: i.fecha,
                    horaIn: i.hora_in,
                    horaOut: i.hora_out,
                    apertura: i.hora_apertura,
                    precios: i.precios,
                    zonas: i.zonas,
                })

                funcions.push({
                    value: idE,
                    label: `[${i.fecha}] - [${i.hora_in} - ${i.hora_out}]`,
                    precios: i.precios
                })

                idE++;
            });
        }

        setFunciones(funcions);
        setDiasEvents(contentDias)

        // ### METODOS DE PAGO ###

        /* Métodos de Pago */
        let contentMetodos = [];
        if (props.generalData?.pagos) {
            (props.generalData?.pagos).forEach(i => {
                contentMetodos.push({
                    value: i.id, label: i.t_nombre
                })
            });
        }


        let metodosSelect = [];
        let tagMetodosP = dataJson.evento.metodos_pago ? dataJson.evento.metodos_pago.split(', ') : [];

        if (tagMetodosP) {
            tagMetodosP.forEach(i => {
                const search = contentMetodos.find(option => option.label === i);
                if (search !== undefined) metodosSelect.push(search)
            })
        }

        setMetSelect(metodosSelect)
        setMetodos(contentMetodos)

        /* Pagos disponibles Multipagos */
        let contentPay = [];
        // Numeros del 2 al 3
        for (let i = 2; i <= 3; i++) {
            contentPay.push({
                value: i,
                label: i,
            });
        }
        setMetodosPay(contentPay);

        let payMetdos = [];
        let countPay = dataJson.evento.notas;

        if (countPay) {
            contentPay.forEach(option => {
                if (countPay.includes(option.label)) {
                    payMetdos.push(option);
                }
            });
        }


        setAmountPay(payMetdos);

        /* Tipo de Moneda Predeterminada*/
        let monedaEvent = [];
        if (props.generalData?.monedas) {
            (props.generalData?.monedas).forEach(i => {
                if (i.id === +dataJson.evento.moneda_pred) setMonPSelect({ value: i.id, label: i.moneda })
                monedaEvent.push({
                    value: i.id, label: i.moneda
                })
            });
        }

        setMonedas(monedaEvent)

        /* Monedas Disponibles */
        let monedasSelect = [];
        let tagMonedasP = dataJson.evento.monedas ? dataJson.evento.monedas.split(', ') : [];


        if (tagMonedasP) {
            tagMonedasP.forEach(i => {
                const search = monedaEvent.find(option => option.label === i);
                if (search !== undefined) monedasSelect.push(search)
            })
        }


        setMonSelect(monedasSelect)

        // ### IMAGENES ###

        // Genera un timestamp o un valor aleatorio para evitar el caché de la imagen
        const uniqueTimestamp = new Date().getTime();

        /* Imagen */
        setFile1((`${UrlImg}events/${dataJson.evento.image}` !== `${UrlImg}events/eventodefault_img.jpg`)
            ? `${UrlImg}events/${dataJson.evento.image}?timestamp=${uniqueTimestamp}`
            : `${UrlImg}events/default_img.jpg`)

        /* Miniatura */
        setFile2((`${UrlImg}events/${dataJson.evento.thumbnail}` !== `${UrlImg}events/eventodefault_img.jpg`)
            ? `${UrlImg}events/${dataJson.evento.thumbnail}?timestamp=${uniqueTimestamp}`
            : `${UrlImg}events/default_img.jpg`)

        /* Plano */
        setPlano(dataJson.evento.plano !== null
            ? `${UrlImg}planos/${dataJson.evento.plano}?timestamp=${uniqueTimestamp}`
            : `${UrlImg}events/default_img.jpg`)

        setControl(!control)
    }, [props.eventData, props.generalData]);


    let changePro = (proSelect) => { setProSelect(proSelect) }
    let changeTipoE = (tipoESelect) => { setTipoESelect(tipoESelect) }
    let changeCat = (catSelect) => { setCatSelect(catSelect) }
    let changeArt = (artSelect) => { setArtSelect(artSelect) }
    let changeSite = (sitesSelect) => { setSitesSelect(sitesSelect) }
    let changeMetodo = (metSelect) => {
        setMetSelect(metSelect);
        metSelect.forEach((i) => {
            if (i.label === "Multipagos") {
                setAmountPay("");
            }
        });
    };
    let changeMoneda = (monSelect) => { setMonSelect(monSelect) }
    let changeMonedaPret = (monPSelect) => { setMonPSelect(monPSelect) }
    let changeAmountPay = (amountPay) => {
        setAmountPay(amountPay);
    };

    let cambiaHtml = (e) => { setContentState(e.editor.getData()) }
    let cambiaTerm = (e) => { setTerminos(e.editor.getData()) }
    let cambiaTicket = (e) => { setEmailTicket(e.editor.getData()) }
    let cambiaNombre = (e) => {
        let regex = new RegExp("^[a-zA-Z0-9 ]+$");

        if (regex.test(e.target.value) === true) {
            setNombre(e.target.value)
            let cadena = e.target.value;
            let cadenaRemplazada = cadena.replace(/ /g, "_");
            setUrl(cadenaRemplazada.toLowerCase())
        }
    }

    const customStylesSelect = {
        menu: styles => ({ ...styles, color: "#000", fontSize: 14, zIndex: 3 }),
        multiValue: styles => ({ ...styles, backgroundColor: "#ccc" }),
        multiValueLabel: styles => ({ ...styles, color: "#000" }),
        placeholder: styles => ({ ...styles, fontSize: 14, color: "#b4bcc3" }),
    };

    const configuraciones = {
        toolbar: [
            ['Font', 'FontSize', '-', 'Copy', 'Paste', 'PasteText', '-', 'Undo', 'Redo', '-', 'Bold', 'Italic', 'Underline', '-', 'JustifyBlock', 'JustifyCenter', 'JustifyLeft', 'JustifyRight', '-', 'TextColor', 'BGColor', '-', 'Link', 'Maximize'],
        ],
        height: "35vh",
        removePlugins: 'elementspath',
        /* removePlugins: 'resize, elementspath', */
    }

    let cambiaFormato = (fecha) => {
        let dateIn = new Date(fecha);
        dateIn.setDate(dateIn.getDate() + 1);
        let date = new Date(dateIn);

        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let aaaa = date.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;

        return `${dd}/${mm}/${aaaa}`;
    }

    let addFuncion = () => {
        let currentFecha = new Date(desde);
        currentFecha.setDate(currentFecha.getDate() - 1);

        let content = diasEvents;
        let lastId = diasEvents[diasEvents.length - 1];

        let i = 1;
        if (lastId !== undefined) i = lastId.id + 1;

        while (new Date(currentFecha) < new Date(hasta)) {
            let fechaIn = new Date(currentFecha);
            fechaIn.setDate(fechaIn.getDate() + 2);
            let date = new Date(fechaIn);
            let dd = date.getDate();
            let mm = date.getMonth() + 1;
            let aaaa = date.getFullYear();
            if (dd < 10) dd = `0${dd}`;
            if (mm < 10) mm = `0${mm}`;

            currentFecha = `${aaaa}-${mm}-${dd}`;
            let currentFechaFormatted = cambiaFormato(currentFecha); // Almacena currentFecha en una variable nueva

            const search = content.find(option => option.dia === currentFechaFormatted && option.horaIn === horaIn && option.horaOut === horaOut);

            if (search === undefined) {
                content.push({
                    id: i,
                    dia: currentFechaFormatted,
                    horaIn: horaIn,
                    horaOut: horaOut,
                    apertura: horaApertura,
                    zonas: (zonas.length === 0) ? JSON.stringify([{ zona: "", precio: "", color: "" }]) : zonas //(typeof(zonas)=== "string"?JSON.parse(zonas):zonas)
                });
                i++;
            }

            setDiasEvents(content);
            setControl(!control);
        }
    }

    let delFuncion = (id) => {
        diasEvents.forEach((opt, index, objeto) => {
            if (opt.id === id) objeto.splice(index, 1);
        })

        setDiasEvents(diasEvents)
        setControl(!control);
    }

    let addImg = (item) => (e) => {
        document.getElementById(item).click();
    }

    let save = () => {
        setBotonMod({ icon: faCircleNotch, text: "Guardando Cambios", spin: true, disabled: true })
        setBotonYes({ icon: faCircleNotch, text: "Espera", spin: true, disabled: true })
        let campos = {
            nombre, url, proSelect, orgSelect, diasEvents, contentState, minAge, tipoESelect, catSelect, artSelect, terminos, sitesSelect, zonas2, metSelect, monSelect, monPSelect,
            amountPay, emailTicket, file1, file2, plano
        };

        ModEvent(props.idEvento, campos, document.getElementById("fileImg1").value, document.getElementById("fileImg2").value, document.getElementById("fileImg3").value, (resp) => {
            setBotonMod({ icon: faSave, text: "Guardar Cambios", spin: false, disabled: false })
            setBotonYes({ icon: faCheck, text: "Si", spin: false, disabled: false })
            setOpenModalCon(false);

            switch (true) {
                case (resp[0].cantidad > 0):
                    setErrMess(resp[0])
                    setVisibleErr("block")
                    toast.error(<small><FontAwesomeIcon icon={faTimesCircle} /> No se guardó la información</small>);
                    break;
                case (resp[1].status === "error" || resp[1].status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon={faTimesCircle} /> <span dangerouslySetInnerHTML={{ __html: resp[1].mess }}></span></small>);
                    break;
                case (resp[1].status === "ErrorEvento"):
                    toast.error(<small><FontAwesomeIcon icon={faTimesCircle} /> <span dangerouslySetInnerHTML={{ __html: resp[1].mess }}></span></small>);
                    break;
                default:
                    setDataEventE(resp[1].data)
                    setOpenModalOk(true)
                    setModalOk("Datos Modificados")
                    setMmodalMess(resp[1].mess)
                    setVisibleErr("none")
                    setErrMess("")
                    break;
            }
        });
    }

    let saveDraft = () => {
        setBotonMod({ icon: faCircleNotch, text: "Guardando Cambios", spin: true, disabled: true })
        setBotonYes({ icon: faCircleNotch, text: "Espera", spin: true, disabled: true })
        let campos = {
            nombre, url, proSelect, orgSelect, diasEvents, contentState, minAge, tipoESelect, catSelect, artSelect, terminos, sitesSelect, zonas2, metSelect, monSelect, monPSelect,
            amountPay, emailTicket, file1, file2, plano
        };

        ModEventDraft(props.idEvento, campos, document.getElementById("fileImg1").value, document.getElementById("fileImg2").value, document.getElementById("fileImg3").value, (resp) => {
            setBotonMod({ icon: faSave, text: "Guardar Cambios", spin: false, disabled: false })
            setBotonYes({ icon: faCheck, text: "Si", spin: false, disabled: false })
            setOpenModalConDraft(false);

            switch (true) {
                case (resp[0].cantidad > 0):
                    setErrMess(resp[0])
                    setVisibleErr("block")
                    toast.error(<small><FontAwesomeIcon icon={faTimesCircle} /> No se guardó la información</small>);
                    break;
                case (resp[1].status === "error" || resp[1].status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon={faTimesCircle} /> <span dangerouslySetInnerHTML={{ __html: resp[1].mess }}></span></small>);
                    break;
                case (resp[1].status === "ErrorEvento"):
                    toast.error(<small><FontAwesomeIcon icon={faTimesCircle} /> <span dangerouslySetInnerHTML={{ __html: resp[1].mess }}></span></small>);
                    break;
                default:
                    setDataEventE(resp[1].data)
                    setOpenModalOk(true)
                    setModalOk("Datos Modificados")
                    setMmodalMess(resp[1].mess)
                    setVisibleErr("none")
                    setErrMess("")
                    break;
            }
        });
    }

    let validateImageFile = (item, ancho, alto) => {
        return new Promise((resolve, reject) => {
            var o = document.getElementById(item);
            var foto = o.files[0];

            if (!foto) {
                reject(new Error("No se ha seleccionado ninguna imagen."));
                return;
            }

            var img = new Image();
            img.onload = function () {
                if (this.width === ancho && this.height === alto) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            };

            // Manejar errores al cargar la imagen
            img.onerror = function () {
                reject(new Error("Error al cargar la imagen."));
            };

            // Esta línea debe estar fuera del evento onload.
            img.src = URL.createObjectURL(foto);
        });
    };

    let selectFile = (item) => (e) => {
        let file = document.getElementById(item);
        let archivo = file.value;

        if (archivo === "") {
            toast.error("No has seleccionado ningún archivo", { autoClose: 4000 });
            return null
        }

        let extension = archivo.split('.').pop();
        let ext = extension.toLowerCase();
        let fic = archivo.split('\\');
        let nombre = fic[fic.length - 1];
        let size = file.files[0].size;

        if (ext === "jpg" || ext === "jpeg" || ext === "png") {
            let msjSize = formatBytes(size);
            if (size > 1000000) {
                /* Comprimir el archivo */
                let fil = file.files[0];
                new Compressor(fil, {
                    quality: 0.2,
                    convertSize: 1000000,
                    success(result) {
                        /* Convertir a Base 64 */
                        let reader = new FileReader();
                        reader.readAsDataURL(result);

                        msjSize = `Tamaño Original: ${formatBytes(size)} [Tamaño Comprimido: ${formatBytes(result.size)}]`

                        reader.onloadend = function () {
                            if (item === "fileImg3") {
                                setPlano(reader.result);
                            } else if (item === "fileImg1") {
                                // Validar las dimensiones de la imagen
                                validateImageFile(item, 2500, 900)
                                .then(isValid => {
                                    if (isValid) {
                                        setFile1(reader.result);
                                    } else {
                                    toast.error(`Las medidas deben ser 2500 x 900`, { autoClose: 4000 });
                                    document.getElementById(item).value = '';
                                    }
                                })
                                .catch(error => {
                                    console.error(error.message);
                                });
                            } else {
                                // Validar las dimensiones de la imagen
                                validateImageFile(item, 1080, 1080)
                                .then(isValid => {
                                    if (isValid) {
                                        setFile2(reader.result);
                                    } else {
                                    toast.error(`Las medidas deben ser 1080 x 1080`, { autoClose: 4000 });
                                    document.getElementById(item).value = '';
                                    }
                                })
                                .catch(error => {
                                    console.error(error.message);
                                });
                            }
                        };

                        if (item === "fileImg1") {
                            // Validar las dimensiones de la imagen
                            validateImageFile(item, 2500, 900)
                            .then(isValid => {
                                if (isValid) {
                                setItem1({ nombre: `Agregado: (${nombre}`, tipo: extension, size: msjSize })
                                }
                            })
                            .catch(error => {
                                console.error(error.message);
                            });
                            
                        } else if (item === "fileImg3") {
                            setItem3({ nombre: `Agregado: (${nombre}`, tipo: extension, size: msjSize })
                        }
                        else {
                            // Validar las dimensiones de la imagen
                            validateImageFile(item, 1080, 1080)
                            .then(isValid => {
                                if (isValid) {
                                setItem2({ nombre: `Agregado: (${nombre}`, tipo: extension, size: msjSize })
                                }
                            })
                            .catch(error => {
                                console.error(error.message);
                            });
                        }
                    },
                    error(err) {
                        toast.error("La imagen es muy grande y no pudo comprimirse", { autoClose: 4000 });
                        document.getElementById(item).value = "";
                    }
                })
            } else {
                /* Convertir a Base 64 */
                let reader = new FileReader();
                reader.readAsDataURL(file.files[0]);

                reader.onloadend = function () {
                    if (item === "fileImg1") {
                        // Validar las dimensiones de la imagen
                        validateImageFile(item, 2500, 900)
                        .then(isValid => {
                            if (isValid) {
                            setFile1(reader.result);
                            } else {
                            toast.error(`Las medidas deben ser 2500 x 900`, { autoClose: 4000 });
                            document.getElementById(item).value = '';
                            }
                        })
                        .catch(error => {
                            console.error(error.message);
                        });
                    }
                    if (item === "fileImg2") {
                        // Validar las dimensiones de la imagen
                        validateImageFile(item, 1080, 1080)
                        .then(isValid => {
                            if (isValid) {
                            setFile2(reader.result);
                            } else {
                            toast.error(`Las medidas deben ser 1080 x 1080`, { autoClose: 4000 });
                            document.getElementById(item).value = '';
                            }
                        })
                        .catch(error => {
                            console.error(error.message);
                        });
                    }
                    if (item === "fileImg3") {
                        setPlano(reader.result);
                    }
                };


                if (item === "fileImg1") {
                    // Validar las dimensiones de la imagen
                    validateImageFile(item, 2500, 900)
                    .then(isValid => {
                        if (isValid) {
                        setItem1({ nombre: `Agregado: (${nombre}`, tipo: extension, size: formatBytes(size) })
                        } 
                    })
                    .catch(error => {
                        console.error(error.message);
                    });
                } else if (item === "fileImg2") {
                    // Validar las dimensiones de la imagen
                    validateImageFile(item, 1080, 1080)
                    .then(isValid => {
                        if (isValid) {
                        setItem2({ nombre: `Agregado: (${nombre}`, tipo: extension, size: formatBytes(size) })
                        }
                    })
                    .catch(error => {
                        console.error(error.message);
                    });
                }
                else {
                    setItem3({ nombre: `Agregado: (${nombre}`, tipo: extension, size: formatBytes(size) })

                }
            }
        } else {
            toast.error("No has seleccionado un archivo de imagen", { autoClose: 4000 });
            document.getElementById(item).value = "";
            return null
        }
    }

    let infoFile = (item) => {
        if (item.nombre === "") return null;

        return (
            <div style={{ fontSize: 10, color: "#000", fontWeight: "bold" }}>{`${item.nombre} - ${item.size})`}</div>
        )
    }

    let contentEvents = () => {
        if (diasEvents.length === 0) return (
            <tr>
                <td align="center" style={{ padding: 10 }} colSpan={3}><FontAwesomeIcon icon={faTimesCircle} /> <span>No hay fechas registradas para este evento</span></td>
            </tr>
        )

        return (
            diasEvents.map(option => (
                <Funciones data={option} key={option.id} delFuncion={(id) => delFuncion(id)} />
            ))
        )
    }

    let modalConfirm = () => {
        if (openModalCon === false) return null;

        return (
            <ConfirmModal
                isOpen={openModalCon}
                content={<div>¿Deseas modificar este evento?</div>}
                disabled={botonYes.disabled}
                buttonYes={<><FontAwesomeIcon icon={botonYes.icon} spin={botonYes.spin} /> {botonYes.text}</>}
                close={() => setOpenModalCon(false)}
                funcion={() => save()}
            />
        )
    }

    let modalConfirmDraft = () => {
        if (openModalConDraft === false) return null;

        return (
            <ConfirmModal
                isOpen={openModalConDraft}
                content={<div>¿Deseas modificar este evento?</div>}
                disabled={botonYes.disabled}
                buttonYes={<><FontAwesomeIcon icon={botonYes.icon} spin={botonYes.spin} /> {botonYes.text}</>}
                close={() => setOpenModalConDraft(false)}
                funcion={() => saveDraft()}
            />
        )
    }

    let mapa = () => {
        if (sitesSelect.value === "") return (
            <small>Selecciona un sitio para ver el mapa</small>
        )

        if (sitesSelect.mapa === "") return (
            <><FontAwesomeIcon icon={faMap} size="xs" className="text-muted" /> <small>Este sitio no tiene un mapa disponible</small></>
        )

        return (<CustomMap src={sitesSelect.map} map={true} />)
    }

    return (
        <>
            <CardTitle className="twitter">
                <strong>Datos principales</strong>
            </CardTitle>
            <Row>
                <Col md={6}>
                    <small style={{ color: "#000" }}>* Nombre del evento</small>
                    <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <FontAwesomeIcon icon={faFilm} />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="* Nombre del Evento" type="text" maxLength={190} value={nombre} onChange={(e) => cambiaNombre(e)} />
                    </InputGroup>
                </Col>
                <Col md={6}>
                    <small style={{ color: "#000" }}>* Url del evento</small>
                    <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <FontAwesomeIcon icon={faLink} />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="* Url del Evento (Sin Espacios)" type="text" maxLength={190} disabled value={url} /* onChange = {(e) => setUrl((e.target.value).toLowerCase())} */ />
                    </InputGroup>
                </Col>
                <Col md={6} className="custom-mt-10">
                    <small style={{ color: "#000" }}>* Productor del evento</small>
                    <Select
                        isMulti isSearchable={true} styles={customStylesSelect}
                        className="basic-multi-select"
                        onChange={changePro} options={productores}
                        noOptionsMessage={() => { return "Sin Opciones" }}
                        value={proSelect}
                        defaultValue={proSelect}
                        placeholder="Selecciona los Productores"
                    />
                </Col>
                <Col md={3} className="custom-mt-10">
                    <small style={{ color: "#000" }}>* Tipo de evento</small>
                    <Select
                        isSearchable={true} styles={customStylesSelect}
                        className="basic-multi-select"
                        onChange={changeTipoE} options={tipoE}
                        noOptionsMessage={() => { return "Sin Opciones" }}
                        loadingMessage={() => { return <><FontAwesomeIcon icon={faCircleNotch} spin={true} /> Cargando</> }}
                        value={tipoESelect}
                        placeholder="Tipo"
                    />
                </Col>
                <Col md={3} className="custom-mt-10">
                    <small style={{ color: "#000" }}>* Categorías</small>
                    <Select
                        isMulti isSearchable={true} styles={customStylesSelect}
                        className="basic-multi-select"
                        onChange={changeCat} options={categorias}
                        noOptionsMessage={() => { return "Sin Opciones" }}
                        loadingMessage={() => { return <><FontAwesomeIcon icon={faCircleNotch} spin={true} /> Cargando categorías</> }}
                        value={catSelect}
                        defaultValue={catSelect}
                        placeholder="Selecciona las Categorías"
                    />
                </Col>
                <Col md={8}>
                    <small style={{ color: "#000" }}>* Artístas</small>
                    <Select
                        isMulti isSearchable={true} styles={customStylesSelect}
                        className="basic-multi-select"
                        onChange={changeArt} options={artistas}
                        noOptionsMessage={() => { return "Sin Opciones" }}
                        loadingMessage={() => { return <><FontAwesomeIcon icon={faCircleNotch} spin={true} /> Cargando artístas</> }}
                        value={artSelect}
                        defaultValue={artSelect}
                        placeholder="Selecciona los Artístas"
                    />
                </Col>
                <Col md={4}>
                    <small style={{ color: "#000" }}>Edad mínima</small>
                    <Input placeholder="Edad" type="number" value={minAge} onChange={(e) => setMinAge(e.target.value)} />
                </Col>
            </Row>
            <CardTitle className="twitter mt-5">
                <strong>Localidad del evento</strong>
            </CardTitle>
            <Row>
                <Col md={4} className="custom-mt-10" style={{ borderTop: "1px dotted #424242" }}>
                    <small style={{ color: "#000" }}>* Recinto del evento</small>
                    <Select
                        isSearchable={true} styles={customStylesSelect}
                        className="basic-multi-select"
                        onChange={changeSite} options={sites}
                        noOptionsMessage={() => { return "Sin Opciones" }}
                        loadingMessage={() => { return <><FontAwesomeIcon icon={faCircleNotch} spin={true} /> Cargando</> }}
                        value={sitesSelect}
                        placeholder="Selecciona un Sitio"
                    />
                </Col>
                <Col md={8} className="custom-mt-10" style={{ borderTop: "1px dotted #424242" }}>
                    <small style={{ color: "#000" }}>Detalles del Sitio</small>
                    <div style={{ color: "#000" }}><small><FontAwesomeIcon icon={preloadSite.icon} /> {preloadSite.text}</small></div>
                </Col>
                <Col md={12} className="custom-mt-10">
                    <small style={{ color: "#000" }}>Mapa</small>
                    <div style={{ border: "1px solid #ccc", backgroundColor: "#fff", padding: 5, borderRadius: "3px 3px", color: "#000", minHeight: "64px" }} align="center">{mapa()}</div>
                </Col>
                <Col md={12} className="custom-mt-10" style={{ borderTop: "1px dotted #424242" }}>
                    <small style={{ color: "#000", fontSize: 12 }}>* Sinopsis</small>
                    <CKEditor
                        data={contentState}
                        initData={contentState}
                        type="classic"
                        onChange={cambiaHtml}
                        config={configuraciones}
                        editorUrl="https://cdn.ckeditor.com/4.16.0/full-all/ckeditor.js"
                    /* editorUrl = {`${UrlEditor}ckeditor.js`} */
                    />
                </Col>
            </Row>
            <CardTitle className="twitter mt-5">
                <strong>Funciones</strong>
            </CardTitle>
            <Row>
                <Col md={6} className="custom-mt-10" style={{ borderTop: "1px dotted #424242" }}>
                    <Row>
                        <Col md={4}>
                            <small style={{ color: "#000" }}>Comienza</small>
                            <Input placeholder="Desde" type="date" value={desde} onChange={(e) => setDesde(e.target.value)} />
                        </Col>
                        <Col md={4}>
                            <small style={{ color: "#000" }}>Finaliza</small>
                            <Input placeholder="Desde" type="date" value={hasta} onChange={(e) => setHasta(e.target.value)} />
                        </Col>
                        <Col md={4}>
                            <small style={{ color: "#000" }}>Apertura de puerta</small>
                            {/* <Input placeholder = "Apertura" type = "time" value = {horaApertura} onChange = {(e) => setApertura(e.target.value)}/> */}
                            <InputTime value={horaApertura} onChange={(e) => setApertura(e)} />

                        </Col>
                        <Col md={4}>
                            <small style={{ color: "#000" }}>Hora de entrada</small>
                            {/* <Input placeholder = "Desde" type = "time" value = {horaIn} onChange = {(e) => setHoraIn(e.target.value)}/> */}
                            <InputTime value={horaIn} onChange={(e) => setHoraIn(e)} />

                        </Col>
                        <Col md={4}>
                            <small style={{ color: "#000" }}>Hora de salida</small>
                            {/* <Input placeholder = "Desde" type = "time" value = {horaOut} onChange = {(e) => setHoraOut(e.target.value)}/> */}
                            <InputTime value={horaOut} onChange={(e) => setHoraOut(e)} />

                        </Col>
                        <Col md={4} align="right" className="custom-mt-20">
                            <Button color="default" type="button" onClick={addFuncion}>
                                <small>Agregar función</small>
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col md={6} className="custom-mt-10" style={{ borderTop: "1px dotted #424242" }}>
                    <small style={{ color: "#000" }}>* Funciones</small>
                    <div style={{ maxHeight: 200, overflow: "auto" }}>
                        <Table className="mb-0 table-custom-primary" striped hover style={{ boxShadow: "2px 1px 2px #000", fontSize: 10 }}>
                            <tbody>
                                <tr>
                                    <th width="20%">Día</th>
                                    <th width="70%" align="center">Horario</th>
                                    <th width="10%" align="center">Acciones</th>
                                </tr>
                                {contentEvents()}
                            </tbody>
                        </Table>
                    </div>
                </Col>
                <Col md={12} className="custom-mt-10" style={{ borderTop: "1px dotted #424242" }}>
                    <small style={{ color: "#000" }}>Términos y condiciones</small>
                    <CKEditor
                        data={terminos}
                        initData={terminos}
                        type="classic"
                        onChange={cambiaTerm}
                        config={configuraciones}
                        /* editorUrl = {`${UrlEditor}ckeditor.js`} */
                        editorUrl="https://cdn.ckeditor.com/4.16.0/full-all/ckeditor.js"
                    />
                </Col>
            </Row>
            <CardTitle className="twitter mt-5">
                <strong>Metodos de pago</strong>
            </CardTitle>
            <Row>
                <Col md={12} className="custom-mt-10">
                    <small style={{ color: "#000" }}>* Métodos de pago</small>
                    <Select
                        isMulti isSearchable={true} styles={customStylesSelect}
                        className="basic-multi-select"
                        onChange={changeMetodo} options={metodos}
                        noOptionsMessage={() => { return "Sin Opciones" }}
                        loadingMessage={() => { return <><FontAwesomeIcon icon={faCircleNotch} spin={true} /> Cargando</> }}
                        value={metSelect}
                        defaultValue={metSelect}
                        placeholder="Método"
                    />
                </Col>
                {metSelect.map((item) => {
                    if (item.label === "Multipagos") {
                        return (
                            <Col md={12}>
                                <small style={{ color: "#000" }}>* Número de multipagos disponibles:</small>
                                <Select
                                    isSearchable={true} styles={customStylesSelect}
                                    className="basic-multi-select"
                                    onChange={changeAmountPay} options={metodosPay}
                                    noOptionsMessage={() => { return "Sin Opciones" }}
                                    loadingMessage={() => { return <><FontAwesomeIcon icon={faCircleNotch} spin={true} /> Cargando</> }}
                                    value={amountPay}
                                    defaultValue={amountPay}
                                    placeholder="Número de Multipagos disponibles"
                                />
                            </Col>
                        );
                    }
                    // Explicitly return null for cases where the condition is not met
                    return null;
                })}
                <Col md={12} className="custom-mt-10">
                    <small style={{ color: "#000" }}>* Monedas disponibles</small>
                    <Select
                        isMulti
                        isSearchable={true} styles={customStylesSelect}
                        className="basic-multi-select"
                        onChange={changeMoneda} options={monedas}
                        noOptionsMessage={() => { return "Sin Opciones" }}
                        loadingMessage={() => { return <><FontAwesomeIcon icon={faCircleNotch} spin={true} /> Cargando</> }}
                        value={monSelect}
                        placeholder="Moneda"
                    />
                </Col>
                <Col md={12} className="custom-mt-10">
                    <small style={{ color: "#000" }}>* Moneda predeterminada</small>
                    <Select
                        isSearchable={true} styles={customStylesSelect}
                        className="basic-multi-select"
                        onChange={changeMonedaPret} options={monedas}
                        noOptionsMessage={() => { return "Sin Opciones" }}
                        loadingMessage={() => { return <><FontAwesomeIcon icon={faCircleNotch} spin={true} /> Cargando</> }}
                        value={monPSelect}
                        placeholder="Moneda"
                    />
                </Col>
            </Row>
            <CardTitle className="twitter mt-5">
                <strong>Imágenes</strong>
            </CardTitle>
            <Row>
                <Col md={4} className="custom-mt-10" style={{ borderTop: "1px dotted #424242" }}>
                    <div>* Imagen principal <small style={{ color: "#000" }}>(2500x900)</small></div>
                    <img src={file1} onClick={addImg("fileImg1")} title="Haz click aqui para cambiar la imagen" className="img-fluid rounded" alt="event_image" style={{ cursor: "pointer", border: "1px  solid #cccccc" }} />
                    {infoFile(item1)}
                    <input type="file" onChange={selectFile("fileImg1")} id="fileImg1" style={{ display: "none" }} />
                    <Button color="danger"
                        outline size="sm" onClick={() => {
                            setFile1(`${UrlImg}events/default_img.jpg`);
                            document.getElementById('fileImg1').value = '';
                            setItem1({ nombre: '', size: '' });

                        }}
                        className="w-100" style={{ borderRadius: 0 }}>
                        <small><FontAwesomeIcon icon={faImage} /> Quitar imagen</small>
                    </Button>
                </Col>
                <Col md={4} className="custom-mt-10" style={{ borderTop: "1px dotted #424242" }}>
                    <div>Miniatura <small style={{ color: "#000" }}>(1080x1080)</small></div>
                    <img src={file2} onClick={addImg("fileImg2")} title="Haz click aqui para cambiar la imagen" className="img-fluid rounded" alt="thumbnail_image" style={{ cursor: "pointer", border: "1px  solid #cccccc" }} />
                    {infoFile(item2)}
                    <input type="file" onChange={selectFile("fileImg2")} id="fileImg2" style={{ display: "none" }} />
                    <Button color="danger"
                        outline size="sm" onClick={() => {
                            setFile2(`${UrlImg}events/default_img.jpg`);
                            document.getElementById('fileImg2').value = '';
                            setItem2({ nombre: '', size: '' });
                        }}
                        className="w-100" style={{ borderRadius: 0 }}>
                        <small><FontAwesomeIcon icon={faImage} /> Quitar imagen</small>
                    </Button>
                </Col>

                <Col md={4} className="custom-mt-10" style={{ borderTop: "1px dotted #424242" }}>
                    <div>Planimetria<small style={{ color: "#000" }}></small></div>
                    <img src={plano} onClick={addImg("fileImg3")} title="Haz click aqui para cambiar la imagen" className="img-fluid rounded" alt="thumbnail_image" style={{ cursor: "pointer", border: "1px  solid #cccccc" }} />
                    {infoFile(item3)}
                    <input type="file" onChange={selectFile("fileImg3")} id="fileImg3" style={{ display: "none" }} />
                    <Button color="danger"
                        outline size="sm" onClick={() => {
                            setPlano(`${UrlImg}events/default_img.jpg`);
                            document.getElementById('fileImg3').value = '';
                        }}
                        className="w-100" style={{ borderRadius: 0 }}>
                        <small><FontAwesomeIcon icon={faImage} /> Quitar imagen</small>
                    </Button>
                </Col>
                <Col md={12} className="custom-mt-10" style={{ borderTop: "1px dotted #424242" }}>
                    <small style={{ color: "#000" }}>* Email de Compra de ticket</small>
                    <CKEditor
                        data={emailTicket}
                        initData={emailTicket}
                        type="classic"
                        onChange={cambiaTicket}
                        config={configuraciones}
                        /* editorUrl = {`${UrlEditor}ckeditor.js`} */
                        editorUrl="https://cdn.ckeditor.com/4.16.0/full-all/ckeditor.js"
                    />
                </Col>
            </Row>
            <Col md={12} className="custom-mt-20" style={{ borderTop: "1px dotted #424242" }}>
                <Row>
                    <Col md={4} className="custom-mt-20">
                        <div className="text-danger" style={{ fontSize: 10 }}>* Campos obligatorios</div>
                        <ErroresList errMess={errMess} visibleErr={visibleErr} name="popErr" />
                    </Col>
                    <Col md={8} align="right" className="custom-mt-20">
                        <Button disabled={botonModDraft.disabled} color="warning" type="button" onClick={() => setOpenModalConDraft(true)}>
                            <small><FontAwesomeIcon spin={botonModDraft.spin} icon={botonModDraft.icon} /> {botonModDraft.text}</small>
                        </Button>
                        <Button disabled={botonMod.disabled} color="default" type="button" onClick={() => setOpenModalCon(true)}>
                            <small><FontAwesomeIcon spin={botonMod.spin} icon={botonMod.icon} /> {botonMod.text}</small>
                        </Button>
                    </Col>
                </Row>
            </Col>
            {modalConfirm()}
            {modalConfirmDraft()}
            <SuccessModal isOpen={openModalOk} close={() => {
                setOpenModalOk(false)
                props.close(dataEventE)
            }} content={modalMess} contentTitle={modalOk} />
        </>
    )
}

export default ContinueEvent;