import React, {useEffect, useState} from "react";
import { Button, Row, Col, Table, Input } from "reactstrap";
import Skeleton from 'react-loading-skeleton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faTimesCircle, faSave, 
         faSquare, faCheck, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import ImageMapper from 'react-image-mapper';
import Select from 'react-select';
import Switch from "react-switch";

import { SearchZoneEventPrice, AddEventPrice } from "functions/EventosFunctions";
import { UrlImg } from "functions/Url";

import ConfirmModal from "components/Modals/ConfirmModal";
import ErroresList from "components/Errores/ErroresList";

import ZonaInd from "./Zonaind";

let auditBuild = "";
const EditPrecios = (props) => {
    const [control, setControl]         = useState(false);
    const [zonasBuild, setZonasBuild]   = useState([]);
    const [zonasCat, setZonasCat]       = useState([]);
    const [zonas, setZonas]             = useState([]);
    const [funciones, setFunciones]     = useState([]);
    const [zonas2, setZonas2]           = useState([]);
    const [zonaTipo, setZonaTipo]       = useState("");
    const [mapaA, setMapaA]             = useState("");
    const [impuestosDis, setImpuestosDis]   = useState(true);
    const [impuestos, setImpuestos]     = useState([]);
    const [impuestosTaq, setImpuestosTaq]   = useState([]);
    const [impuestoSel, setImpuestoSel] = useState({value: "", label: "- Selecciona -"});
    const [tipoI, setTipoI]             = useState({value: "", label: "- Selecciona -"});
    const [tipoT, setTipoT]             = useState({value: "", label: "- Selecciona -"});
    const [impuestoSelTaq, setImpuestoSelTaq]   = useState({value: "", label: "- Selecciona -"});
    const [diasEvents, setDiasEvents]   = useState([]);
    const [openModalCon, setOpenModalCon]   = useState(false)
    const [botonSave, setBotonSave]     = useState({ icon: faSave, text: "Guardar Cambios", spin: false, disabled: false })
    const [botonYes, setBotonYes]       = useState({ icon: faCheck, text: "Si", spin: false, disabled: false })
    const [errMess, setErrMess]             = useState("")
    const [visibleErr, setVisibleErr]       = useState("none")
    const [activar, setActivar]         = useState(false);
    const [valotI, setValorI]           = useState("");
    const [valotT, setValorT]           = useState("");
    const [impuestosI, setImpuestosI]   = useState([]);
    const [impuestosT, setImpuestosT]   = useState([]);
    const [monedaPred, setMonedaPred]   = useState([]);
    const [servicioTaquilla, setServicioTaquilla]   = useState("");
    const [preloader, setPreloader]    = useState(true);
    
    useEffect(() => {
        auditBuild = <Skeleton height = {300}/>
        SearchZoneEventPrice(props.idSite, props.idEvento, (resp) => {
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                break;
                default:
                    setPreloader(false)
                    if(resp.data_zona_audit === null) {
                        auditBuild = <div align = "center" style = {{height: 300, paddingTop: 140}}>
                        El recinto seleccionado para este evento <strong>No tiene distribuciones definidas</strong>, para definir una distribución ve a la opción 
                        <strong style = {{cursor: "pointer"}} className = "text-danger" onClick = {() => props.openDist()}>Personalizar Distribución</strong>
                        </div>
                        setControl(!control)
                    }else{
                        setZonasCat(JSON.parse(resp.data_zona_audit.zonas_cat))
                    }

                    /* Funciones del Evento */
                    let contentDias = []
                    let funcions = funciones;
                    let idE = 1;
                    (resp.funcionesEvent).forEach(i => {
                        contentDias.push({
                            id: idE,
                            dia: i.fecha,
                            horaIn: i.hora_in,
                            horaOut: i.hora_out,
                            precios: i.precios
                        })

                        funcions.push({
                            value: idE,
                            label: `[${i.fecha}] - [${i.hora_in} - ${i.hora_out}]`,
                            precios: i.precios,
                            idFuncion: i.id
                        })

                        idE++;
                    });

                    setFunciones(funcions);
                    setDiasEvents(contentDias)


                    if(resp.dataZonas !== null){
                        let ContentZonas = []
                        resp.dataZonas.forEach(i => {
                            if(Boolean(i.nombre_zona.status) === true) ContentZonas.push({
                                id: i.id,
                                id_nombre: i.id_nombre,
                                color: i.color,
                                nombre: i.nombre_zona.nombre_zona,
                                valor: 0,
                                moneda: "",
                                status: 1,
                            })
                        })
                        setZonas(ContentZonas)

                        let content = zonas2;
                        funcions.forEach(i => {
                            const search = content.find(option => option.id_funcion === i.value);
                            if (search === undefined){
                                let n = 1;
                                ContentZonas.forEach(i2 => {
                                    let searchZona = (i.precios).find(option => option.id_zona === i2.id);
                                    if (searchZona === undefined){
                                        content.push({
                                            id_funcion: i.value,
                                            id: `${i.value}_${n}`,
                                            idZona: i2.id,
                                            color: i2.color,
                                            id_nombre: i2.id_nombre,
                                            moneda: i2.moneda,
                                            nombre: i2.nombre,
                                            status: false,
                                            valor: i2.valor,
                                            nombre_funcion: i.label,
                                            idFuncion: i.idFuncion
                                        })
                                    }else{
                                        content.push({
                                            id_funcion: i.value,
                                            id: `${i.value}_${n}`,
                                            idZona: searchZona.id_zona,
                                            color: searchZona.color,
                                            id_nombre: searchZona.id_nombre,
                                            moneda: '',
                                            nombre: searchZona.nombre_zona,
                                            status: searchZona.status,
                                            valor: searchZona.precio,
                                            nombre_funcion: i.label,
                                            idFuncion: i.idFuncion
                                        })
                                    }
                                    n++;
                                });
                            }
                        });

                        setZonas2(content)
                    }

                    if (resp.data_zona_audit !== null){
                        setZonaTipo(resp.data_zona_audit.tipo)
                        setMapaA(resp.data_zona_audit.image)
                        setZonasBuild(JSON.parse(resp.data_zona_audit.zonas_build))
                    }else{
                        setZonaTipo("");
                        setMapaA("");
                        setZonasBuild([]);
                    }

                    let ContentImpuesto = [];
                    (resp.itemsImpuestos).forEach(i => {
                        ContentImpuesto.push({
                            value: i.id, label: i.nombre
                        })
                    })

                    let ContentImpuestoTaquilla = [];
                    (resp.itemsTaquilla).forEach(i => {
                        ContentImpuestoTaquilla.push({
                            value: i.id, label: i.nombre
                        })
                    })

                    setImpuestos(ContentImpuesto)
                    setImpuestosTaq(ContentImpuestoTaquilla)
                    setImpuestosDis(false)
                    setMonedaPred(resp.moneda_pred)

                    /* Impuetos */
                    let ContentImp = [];
                    (resp.impuestos).forEach(i => {
                        const search = ContentImpuesto.find(option => option.value === i.id_item);
                        let nombre = "";
                        if (search !== undefined) nombre = search.label;
                        ContentImp.push({
                            idItem: i.id_item,
                            nombre: nombre,
                            valor: i.valor,
                            tipo: i.tipo
                        })
                    });

                    /* Impuestos Taquilla */
                    let ContentImpTaq = [];
                    (resp.impuestos_taq).forEach(i => {
                        const search = ContentImpuestoTaquilla.find(option => option.value === i.id_item);
                        let nombre = "";
                        if (search !== undefined) nombre = search.label;
                        ContentImpTaq.push({
                            idItem: i.id_item,
                            nombre: nombre,
                            valor: i.valor,
                            tipo: i.tipo
                        })
                    });

                    setImpuestosI(ContentImp)
                    setImpuestosT(ContentImpTaq)

                    if (resp.serv_taquilla.length > 0) {
                        setActivar(Boolean(resp.serv_taquilla[0].is_aplicable))
                        setServicioTaquilla(resp.serv_taquilla[0].valor)
                    }
                break;
            }
        })
    }, [props]);

    useEffect(() => {
        setControl(!control)
        switch (true){
            case (preloader === true):
                auditBuild = <Skeleton height = {300}/>
            break;
            case(zonasBuild.length === 0):
                auditBuild = <div align = "center" style = {{height: 300, paddingTop: 140}}>
                El recinto seleccionado para este evento <strong>No tiene distribuciones definidas</strong>, para definir una distribución ve a la opción
                <strong style = {{cursor: "pointer"}} className = "text-danger" onClick = {() => props.openDist()}> PERSONALIZAR DISTRIBUCIÓN</strong>
                </div>
            break;
            default:
                let leyenda = () => (
                    zonasCat.map(option => (
                        <small key = {option.id} style = {{fontSize: 9}}><FontAwesomeIcon icon = {faSquare} style = {{color: option.color}}/> {option.nombre} | </small>
                    ))
                )
        
                let title = (nombre) => {
                    if (nombre !== undefined) return nombre;
                }
        
                let zonaT = () => {
                    let columnas = (data) => (
                        data.map(option => (
                            <td key = {option.id} width = {10} height = {10} title = {title(option.zona.label)} style = {{backgroundColor: option.color}}></td>
                        ))
                    )
        
                    return zonasBuild.map(fila => (
                        <tr key = {`${fila.id}_${control}`}>{columnas(fila.columnas)}</tr>
                    ))
                }
                switch(zonaTipo){
                    case "cuadricula":
                        auditBuild = <>
                            <div className = "table-responsive" align = "center" style = {{marginBottom: 5}}>
                            <table border = "0">
                                <tbody>
                                    {zonaT()}
                                </tbody>
                            </table>
                            </div>
                            <div style = {{borderTop: "1px dotted #424242"}}>{leyenda()}</div>
                        </>
                    break;
                    case "area":
                        auditBuild = <>
                            <div>
                                <div align = "left" style = {{borderBottom: "1px dotted #000", marginBottom: 10}}>{leyenda()}</div>
                                <ImageMapper src = {`${UrlImg}maps/${mapaA}`} map = {zonasBuild}/>
                                <div align = "left" style = {{borderTop: "1px dotted #000", marginTop: 10}}>{leyenda()}</div>
                            </div>
                        </>
                    break;          
                    default: auditBuild = "";
                }
            break;
        }
    }, [zonasBuild]);

    let auditorio = () => {
        sessionStorage.setItem("ContentControl", control);
        return auditBuild
    }

    const customStylesSelect = {
        menu: styles => ({ ...styles, color: "#000", fontSize: 12, zIndex: 3 }),
        multiValue: styles => ({ ...styles, backgroundColor: "#ccc" }),
        multiValueLabel: styles => ({ ...styles, color: "#000" }),
        placeholder: styles => ({ ...styles, fontSize: 14, color: "#b4bcc3" }),
    };

    let changueImpuestoSelect = (impuestoSel) => { setImpuestoSel(impuestoSel) }
    let changeTipoI = (tipoI) => { setTipoI(tipoI) }
    let changeTipoT = (tipoT) => { setTipoT(tipoT) }
    let changueImpuestoSelectTaq = (impuestoSelTaq) => { setImpuestoSelTaq(impuestoSelTaq) }

    let updZona = (campo, id, valor) => {
        let content = zonas2
        content.forEach (i => {
            if(i.id === id) i[campo] = valor;
        })
        
        setZonas2(content)
    }
    
    let contentZonas = (id) => {
        if (zonas2.length === 0) return  (
            <tr>
                <td align = "center" style = {{padding: 10}} colSpan = {4}>No has seleccionado una función</td>
            </tr>
        )

        return(
            zonas2.map(option => {
                if (id === option.id_funcion){
                    return <ZonaInd key = {`${option.id_funcion}_${option.id}`} data = {option} sitioId = {props.idSite} updZona = {(campo, id, valor) => {updZona(campo, id, valor)}}/>
                }else{
                    return null;
                }
            })
        )
    }

    let funcionesEvento = () => {
        if (diasEvents.length === 0) return  (
            <tr>
                <td align = "center" style = {{padding: 10}} colSpan = {4}>Este evento no tiene funciones registradas</td>
            </tr>
        )

        if (zonas2.length === 0) return  (
            <tr>
                <td align = "center" style = {{padding: 10}} colSpan = {4}>Este evento no tiene zonas registradas</td>
            </tr>
        )

        return (
            diasEvents.map(option => (
                <div key = {option.id} style = {{boxShadow: "2px 1px 2px #000"}}>
                    <div style = {{borderTop: "1px dotted #000", paddingTop: 10, fontWeight: "bold"}} className = "text-danger custom-mt-10">Función - [{option.dia}] - [{option.horaIn} - {option.horaOut}]</div>
                    <Table className = "mb-0 table-custom-primary" striped hover  style = {{fontSize: 10 }}>
                        <tbody>
                            <tr>
                                <th width = "40%">Zona</th>
                                <th width = "30%" align = "center">*Precio</th>
                                <th width = "15%" align = "center">Status</th>
                                <th width = "15%" align = "center">Acciones</th>
                            </tr>
                            {contentZonas(option.id)}
                        </tbody>
                    </Table>
                </div>
            ))
        )
    }

    let save = () => {
        setBotonSave({ icon: faCircleNotch, text: "Guardando Cambios", spin: true, disabled: true })
        setBotonYes({ icon: faCircleNotch, text: "Espera", spin: true, disabled: true })

        let campos = {zonas2, impuestosI, impuestosT, activar, servicioTaquilla};

        AddEventPrice(campos, props.idEvento, (resp) => {
            setBotonSave({ icon: faSave, text: "Guardar Cambios", spin: false, disabled: false })
            setBotonYes({ icon: faCheck, text: "Si", spin: false, disabled: false })
            setOpenModalCon(false)

            switch (true) {
                case (resp[0].cantidad > 0):
                    setErrMess(resp[0])
                    setVisibleErr("block")
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> No se guardó la información</small>);
                break;
                case (resp[1].status === "error" || resp[1].status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp[1].mess }}></span></small>);
                break;
                default:
                    toast.info(<small><FontAwesomeIcon icon = {faCheck}/> <span dangerouslySetInnerHTML = {{ __html: resp[1].mess }}></span></small>);
                    setVisibleErr("none")
                    setErrMess("")
                break;
            }
        });
    }

    let modalConfirm = () => {
        if (openModalCon === false) return null;

        return (
            <ConfirmModal
                isOpen = {openModalCon}
                content = {<div>Antes de activar el evento, revisa que los precios esten correctamente agregados en cada una de las funciones. ¿Deseas continuar? </div>}
                disabled = {botonYes.disabled}
                buttonYes = {<><FontAwesomeIcon icon = {botonYes.icon} spin = {botonYes.spin}/> {botonYes.text}</>}
                close = {() => setOpenModalCon(false)}
                funcion = {() => save()}
            />
        )
    }

    let tipos = [
        {value: "porcentaje", label: "Porcentaje (%)"},
        {value: "moneda", label: "Moneda Predeterminada"}
    ]

    let contentImpuestos = () => {
        if (impuestosI.length === 0) return (
            <tr>
                <td align = "center" colSpan = {3}>No has asignado ningún impuesto</td>
            </tr>
        )

        let tipo = (valor) => {
            if (valor.value === "porcentaje") return "%";
            return monedaPred;
        }

        let delImp = (valor) => {
            const filtro = impuestosI.filter(option => option !== valor);
            setImpuestosI(filtro);
        }

        return (
            impuestosI.map(option => (
                <tr key = {Math.random()}>
                    <td><strong>{option.nombre}</strong></td>
                    <td>{`${option.valor} ${tipo(option.tipo)}`}</td>
                    <td><FontAwesomeIcon icon = {faTrash} className = "text-danger" style = {{cursor: "pointer"}} onClick = {() => {delImp(option)}} title = "Eliminar este impuesto de la lista"/></td>
                </tr>
            ))
        )
    }

    let contentImpuestosTaquilla = () => {
        if (impuestosT.length === 0) return (
            <tr>
                <td align = "center" colSpan = {3}>No has asignado ningún impuesto de taquilla</td>
            </tr>
        )

        let tipo = (valor) => {
            if (valor.value === "porcentaje") return "%";
            return monedaPred;
        }

        let delImpTaq = (valor) => {
            const filtro = impuestosT.filter(option => option !== valor);
            setImpuestosT(filtro);
        }

        return (
            impuestosT.map(option => (
                <tr key = {Math.random()}>
                    <td><strong>{option.nombre}</strong></td>
                    <td>{`${option.valor} ${tipo(option.tipo)}`}</td>
                    <td><FontAwesomeIcon icon = {faTrash} className = "text-danger" style = {{cursor: "pointer"}} onClick = {() => {delImpTaq(option)}} title = "Eliminar este impuesto de taquilla de la lista"/></td>
                </tr>
            ))
        )
    }

    let addImpuesto = () => {
        switch(true){
            case (impuestosTaq.value === ""):
                toast.error(<small style = {{fontSize: 12}}><FontAwesomeIcon icon = {faTimesCircle}/> Selecciona primero un <strong>item</strong></small>);
            break;
            case (valotI === ""):
                toast.error(<small style = {{fontSize: 12}}><FontAwesomeIcon icon = {faTimesCircle}/> Debes asignarle un <strong>valor</strong> al impuesto</small>);
            break;
            case (tipoI.value === ""):
                toast.error(<small style = {{fontSize: 12}}><FontAwesomeIcon icon = {faTimesCircle}/> Selecciona el <strong>tipo</strong> de ítem</small>);
            break;
            default:
                let Content = impuestosI;

                Content.push({
                    idItem: impuestoSel.value,
                    nombre: impuestoSel.label,
                    valor: valotI,
                    tipo: tipoI.value
                })

                setImpuestosI(Content)
                setImpuestoSel({value: "", label: "- Selecciona -"})
                setValorI("")
                setTipoI({value: "", label: "- Selecciona -"})
            break;
        }
    }

    let addImpuestoTaquilla = () => {
        switch(true){
            case (servicioTaquilla === ""):
                toast.error(<small style = {{fontSize: 12}}><FontAwesomeIcon icon = {faTimesCircle}/> Selecciona primero un <strong>monto</strong> para aplicar este impuesto</small>);
            break;
            case (impuestoSelTaq.value === ""):
                toast.error(<small style = {{fontSize: 12}}><FontAwesomeIcon icon = {faTimesCircle}/> Selecciona un <strong>item</strong> de taquilla</small>);
            break;
            case (valotT === ""):
                toast.error(<small style = {{fontSize: 12}}><FontAwesomeIcon icon = {faTimesCircle}/> Debes asignarle un <strong>valor</strong> al impuesto de taquilla</small>);
            break;
            case (tipoT.value === ""):
                toast.error(<small style = {{fontSize: 12}}><FontAwesomeIcon icon = {faTimesCircle}/> Selecciona el <strong>tipo</strong> de ítem de taquilla</small>);
            break;
            default:
                let Content = impuestosT;

                Content.push({
                    idItem: impuestoSelTaq.value,
                    nombre: impuestoSelTaq.label,
                    valor: valotT,
                    tipo: tipoT.value
                })

                setImpuestosT(Content)
                setImpuestoSelTaq({value: "", label: "- Selecciona -"})
                setValorT("")
                setTipoT({value: "", label: "- Selecciona -"})
            break;
        }
    }

    return (
        <div style = {{height: "74vh", overflowX: "hidden", overflowY: "auto"}}>
            <Row style = {{padding: 10}}>
                <Col md = {12} style = {{paddingBottom: 10,}}>
                    <div className align = "right">
                        <div>Recinto: <strong className = "text-danger">{props.dataEvent.name}</strong></div>
                        <div>Dirección: <strong className = "text-danger">{props.dataEvent.address}</strong></div>
                    </div>
                </Col>
                <Col md = {6}>
                    <div style = {{color: "#000"}}>Zonas del auditorio</div>
                    <div style = {{overflow: "auto", border: "1px solid #ccc", backgroundColor: "#fff", padding: 5, borderRadius: "3px 3px", color: "#000", minHeight: "64px"}}>{auditorio()}</div>
                </Col>
                <Col md = {6}>
                    <Row>
                        <Col md = {12} className = "custom-mt-10">
                            <div style = {{color: "#000"}}>* Precios por zona</div>
                            <div>
                                {funcionesEvento()}
                            </div>
                        </Col>
                        <Col md = {12} className = "custom-mt-10">
                            <div className = "alert alert-danger" style = {{fontSize: 10}}>Otros Cobros</div>
                        </Col>
                        <Col md = {12}>
                            <div style = {{fontSize: 14, borderBottom: "1px dotted #000"}} className = "text-danger"><strong>Impuestos</strong></div>
                        </Col>
                        <Col md = {4}>
                            <div>Item</div>
                            <Select
                                isDisabled = {impuestosDis}
                                isSearchable = {true} styles = {customStylesSelect}
                                className = "basic-multi-select"
                                onChange = {changueImpuestoSelect} options = {impuestos}
                                noOptionsMessage = {() => { return "Sin Opciones" }}
                                loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                                value = {impuestoSel}
                            />
                        </Col>
                        <Col md = {3}>
                            <div>Valor</div>
                            <Input type = "number" placeholder = "Valor" value = {valotI} onChange = {(e) => setValorI(e.target.value)}/>
                        </Col>
                        <Col md = {4}>
                            <div>Tipo</div>
                            <Select
                                isDisabled = {impuestosDis}
                                isSearchable = {true} styles = {customStylesSelect}
                                className = "basic-multi-select"
                                onChange = {changeTipoI} options = {tipos}
                                noOptionsMessage = {() => { return "Sin Opciones" }}
                                loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                                value = {tipoI}
                            />
                        </Col>
                        <Col md = {1} style = {{padding: 0, marginTop: 21}}>
                            <Button color = "warning" size = "sm" title = "Agregar Impuesto" onClick = {addImpuesto}><FontAwesomeIcon icon = {faPlus}/></Button>
                        </Col>
                        <Col md = {12} className = "custom-mt-10">
                            <Table className = "mb-0 table-custom-primary" striped hover  style = {{boxShadow: "2px 1px 2px #000", fontSize: 10 }}>
                                <tbody>
                                    <tr>
                                        <th width = "65%">Item</th>
                                        <th width = "35%">Valor</th>
                                        <th width = "30px" align = "center"></th>
                                    </tr>
                                    {contentImpuestos()}
                                </tbody>
                            </Table>
                        </Col>

                        <Col md = {12} className = "custom-mt-10">
                            <div style = {{fontSize: 14, borderBottom: "1px dotted #000"}} className = "text-danger"><strong>Servicios de taquilla</strong></div>
                        </Col>
                        <Col md = {4}>
                            <div>Activar este cobro</div>
                            <div align = "center" style = {{paddingTop: 9}}>
                                <Switch
                                    offColor = "#d12c2c"
                                    onHandleColor = "#fff"
                                    onColor = "#67c167"
                                    offHandleColor = "#fff"
                                    checkedIcon = { <div style = {{color: "#fff", marginLeft:20, paddingTop:1, fontWeight: "bold"}}>Activado</div> }
                                    uncheckedIcon = { <div style = {{color: "#fff", marginLeft:-45, paddingTop:1, fontWeight: "bold"}}>Desactivado</div> }
                                    checked = {activar} onChange = {() => {
                                        setActivar(!activar)
                                        if (activar === true) {
                                            setImpuestosT([]);
                                            setServicioTaquilla("");
                                        }
                                    }}
                                    className = "react-switch" height = {20} width = {100}
                                />
                            </div>
                        </Col>
                        <Col md = {8}>
                            <div>Monto</div>
                            <Input type = "number" placeholder = "Indica el monto" value = {servicioTaquilla}
                            onChange = {(e) => {
                                setServicioTaquilla(e.target.value);
                                if (e.target.value !== "") setActivar(true);
                            }}
                            onBlur = {(e) => {
                                if (e.target.value === ""){
                                    setActivar(false)
                                    setImpuestosT([]);
                                    setServicioTaquilla("");
                                }
                            }}
                            />
                        </Col>

                        <Col md = {12} className = "custom-mt-10">
                            <div style = {{fontSize: 14, borderBottom: "1px dotted #000"}} className = "text-danger"><strong>Impuestos de servicios de taquilla</strong></div>
                        </Col>
                        <Col md = {4}>
                            <div>Item</div>
                            <Select
                                isDisabled = {impuestosDis}
                                isSearchable = {true} styles = {customStylesSelect}
                                className = "basic-multi-select"
                                onChange = {changueImpuestoSelectTaq} options = {impuestosTaq}
                                noOptionsMessage = {() => { return "Sin Opciones" }}
                                loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                                value = {impuestoSelTaq}
                            />
                        </Col>
                        <Col md = {3}>
                            <div>Valor</div>
                            <Input type = "number" placeholder = "Valor" value = {valotT} onChange = {(e) => setValorT(e.target.value)}/>
                        </Col>
                        <Col md = {4}>
                            <div>Tipo</div>
                            <Select
                                isDisabled = {impuestosDis}
                                isSearchable = {true} styles = {customStylesSelect}
                                className = "basic-multi-select"
                                onChange = {changeTipoT} options = {tipos}
                                noOptionsMessage = {() => { return "Sin Opciones" }}
                                loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                                value = {tipoT}
                            />
                        </Col>
                        <Col md = {1} style = {{padding: 0, marginTop: 21}}>
                            <Button color = "warning" size = "sm" title = "Agregar Impuesto de Servicios de Taquilla" onClick = {addImpuestoTaquilla}><FontAwesomeIcon icon = {faPlus}/></Button>
                        </Col>
                        <Col md = {12} className = "custom-mt-10">
                            <Table className = "mb-0 table-custom-primary" striped hover  style = {{boxShadow: "2px 1px 2px #000", fontSize: 10 }}>
                                <tbody>
                                    <tr>
                                        <th width = "65%">Item</th>
                                        <th width = "35%">Valor</th>
                                        <th width = "30px" align = "center"></th>
                                    </tr>
                                    {contentImpuestosTaquilla()}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
                <Col md = {8} className = "custom-mt-10">
                    <div className = "text-danger" style = {{fontSize: 10}}>* Campos obligatorios</div>
                    <ErroresList errMess = {errMess} visibleErr = {visibleErr} name = "popErr"/>
                </Col>
                <Col md = {4} align = "right" className = "custom-mt-10">
                    <Button disabled = {botonSave.disabled} color = "default" type="button" onClick = {() => setOpenModalCon(true)}>
                        <small><FontAwesomeIcon spin = {botonSave.spin} icon = {botonSave.icon}/> {botonSave.text}</small>
                    </Button>
                </Col>
            </Row>
            {modalConfirm()}
        </div>
    )
}

export default EditPrecios;