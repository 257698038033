import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Select from 'react-select';

import { Button, Card, CardBody, CardHeader, Row, Col, Table } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faCircleNotch, faTimesCircle, faHome, faGlobe, faSearch, faFrown, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import NavbarI from "components/Navbars/NavbarI.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import Sidebar from '../dashboard/Sidebar';

import ContentModal from "components/Modals/ContentModal";
import SuccessModal from "components/Modals/SuccessModal";

import AddSitio from "./AddSitio";
import { Sitios, SitiosFilter } from "functions/SitiosFunction";
import { ConsultPaises, ConsultEstados, ConsultMunicipios, ConsultParroquias } from "functions/UserFunctions";

import SitiosInd from "./SitiosInd";

const MainSitios = (props) => {
    const [preloader, setPreloader] = useState({icon: faCircleNotch, text: "Buscando Recintos Registrados", spin: true})
    const [openModalContent, setOpenModalContent]   = useState(false);
    const [openModalOk, setOpenModalOk] = useState(false)
    const [modalOkTitle, setModalOkTitle]   = useState("")
    const [modalMess, setMmodalMess]    = useState("")
    const [dataSitios, setDataSitios]   = useState([])
    const [paisSelect, setPaisSelect] = useState({value: "", label: "País"});
    const [paisOpt, setPaisOpt]       = useState({ disabled: false, loading: true })
    const [paises, setPaises]         = useState([]);
    const [estadoSelect, setEstadoSelect] = useState({value: "", label: "Estado"});
    const [estadoOpt, setEstadoOpt]       = useState({ disabled: true, loading: false })
    const [estados, setEstados]           = useState([]);
    const [municipioSelect, setMunicipioSelect] = useState({value: "", label: "Municipio"});
    const [municipioOpt, setMunicipioOpt]       = useState({ disabled: true, loading: false })
    const [municipios, setMunicipios]           = useState([]);
    const [parroquiaSelect, setParroquiaSelect] = useState({value: "", label: "Parroquia"});
    const [parroquiaOpt, setParroquiaOpt]       = useState({ disabled: true, loading: false })
    const [parroquias, setParroquias]           = useState([]);
    const [btnSearch, setBtnSearch]             = useState({icon: faSearch, text: "Buscar", spin: false, disabled: false});

    useEffect(() => {
        Sitios((resp) => {
            if(resp.status === "error_log"){
                setPreloader({icon: faTimesCircle,  text: resp.mess, spin: false})
                toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                setDataSitios([])
            }else{
                if ((resp.data).length === 0)  setPreloader ({icon: faTimesCircle, text: "No existen recintos registrados", spin: false})

                setDataSitios(resp.data)
            }
        })

        let Content = [];
        ConsultPaises((resp) => {
            (resp.data).forEach(i => {
                Content.push({
                value: i.id, label: i.pais_name
                })
            })

            setPaises(Content)
            setPaisOpt({ disabled: true, loading: false })
        })
    }, []);

    let changePais = (paisSelect) => {
        setPaisSelect(paisSelect);
        setEstadoSelect({value: "", label: "Estado"});
        setMunicipioSelect({value: "", label: "Municipio"});
        setParroquiaSelect({value: "", label: "Parroquia"});
    
        let Content = [];
    
        setEstadoOpt({ disabled: false, loading: true })
        ConsultEstados(paisSelect.value, (resp) => {
            (resp.data).forEach(i => {
                Content.push({
                value: i.id, label: i.estado_name
                })
            })
          
            setEstados(Content)
            setEstadoOpt({ disabled: true, loading: false })
        })
    }

    let changeEstado = (estadoSelect) => {
        setEstadoSelect(estadoSelect);
        setMunicipioSelect({value: "", label: "Municipio"});
        setParroquiaSelect({value: "", label: "Parroquia"});
    
        let Content = [];
    
        setMunicipioOpt({ disabled: false, loading: true })
        ConsultMunicipios(estadoSelect.value, (resp) => {
            (resp.data).forEach(i => {
                Content.push({
                value: i.id, label: i.municipio_name
                })
            })
            
            setMunicipios(Content)
            setMunicipioOpt({ disabled: true, loading: false })
        })
    }
    
    let changeMunicipio = (municipioSelect) => {
            setMunicipioSelect(municipioSelect)
            setParroquiaSelect({value: "", label: "Parroquia"});
        
            let Content = [];
            setParroquiaOpt({ disabled: false, loading: true });
            ConsultParroquias(municipioSelect.value, (resp) => {
                (resp.data).forEach(i => {
                    Content.push({
                    value: i.id, label: i.parroquia_name
                    })
                })
            
                setParroquias(Content)
                setParroquiaOpt({ disabled: true, loading: false });
            })
    }
    
    let changeParroquia = (parroquiaSelect) => {
        setParroquiaSelect(parroquiaSelect)
    }

    let searchRecinto = () => {
        if (btnSearch.disabled === true) return null;

        if (paisSelect.value === "" && estadoSelect.value === "" && municipioSelect.value === "" && parroquiaSelect.value === ""){
            toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span>Selecciona al menos una opción de búsqueda</span></small>);
            return null;
        }

        setBtnSearch({icon: faCircleNotch, text: "Buscar", spin: true, disabled: true});

        SitiosFilter(paisSelect.value, estadoSelect.value, municipioSelect.value, parroquiaSelect.value, (resp) => {
            setBtnSearch({icon: faSearch, text: "Buscar", spin: false, disabled: false});
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                break;
                case ((resp.data).length === 0):
                    toast.info(<small><FontAwesomeIcon icon = {faInfoCircle}/> <span style = {{fontSize: 12}}> La búsqueda no obtuvo resultados</span></small>);
                    setPreloader({icon: faTimesCircle, text: "La búsqueda no obtuvo resultados", spin: false})
                    setDataSitios([])
                break;
                default:
                    setDataSitios(resp.data)
                break;
            }
        })
    }

    let addOk = (open, title, mess, data) => {
        setOpenModalOk(open)
        setModalOkTitle(title)
        setMmodalMess(mess)
        setDataSitios(data);
    }
    
    let contentSite = () => {
        if (dataSitios.length === 0) return (
            <tr>
                <td align = "center" style = {{padding: 10}} colSpan = {6}><FontAwesomeIcon spin = {preloader.spin} icon = {preloader.icon}/> <span dangerouslySetInnerHTML = {{ __html: preloader.text }}></span></td>
            </tr>
        )

        return (
            dataSitios.map(option => (
                <SitiosInd key = {option.id} data = {option} addOk = {(open, title, mess, data) => {addOk(open, title, mess, data)}}/>
            )
        ))
    }

    let modalCreateContent = () => (
        <ContentModal
            close = {() => setOpenModalContent(false)}
            isOpen = {openModalContent}
            title = "Agregar un nuevo recinto"
            size = "lg"
            content = {<AddSitio cerrar = {() => {setOpenModalContent(false)}} addOk = {(open, title, mess, data) => {addOk(open, title, mess, data)}} botones = "add"/>}
        />
    )

    const customStylesSelect = {
        menu: styles => ({ ...styles, color: "#000", fontSize: 14, zIndex: 3 }),
        multiValue: styles => ({ ...styles, backgroundColor: "#ccc" }),
        multiValueLabel: styles => ({ ...styles, color: "#000" }),
        placeholder: styles => ({ ...styles, fontSize: 14, color: "#b4bcc3" }),
    };

    return(
        <>
            <NavbarI />
            <main>
                <section className="section section-shaped section-lg">
                        <div className = "d-block d-sm-block d-md-block" align = "right" style = {{paddingRight: 15}}>
                            <small class = "bread-crumb-custom"><Link to = "/dashboard/home"><FontAwesomeIcon icon = {faHome}/></Link> | <FontAwesomeIcon icon = {faGlobe}/> Recintos</small>
                        </div>
                    <Row>
                        <Col md = {3} className = "d-none d-sm-none d-md-block">
                            <Sidebar active = "Recintos"/>
                        </Col>
                        <Col md = {9} className = "custom-p-32">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col md = {6}>
                                            <h5>Recintos</h5>
                                            <div className = "text-muted" style = {{fontSize: 10, marginTop: -10}}>Completa todos los campos para continuar.</div>
                                        </Col>
                                        <Col md = {6} align = "right">
                                            <Button onClick = {() => {setOpenModalContent(true)}} color = "default" type="button">
                                                <small><FontAwesomeIcon icon = {faPlusCircle}/> Agregar recinto</small>
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md = {12}>
                                            <small style = {{color: "#000000"}}>Filtar recintos por:</small>
                                        </Col>
                                        <Col md = {10}>
                                            <Row>
                                                <Col md = {3} className = "mb-3">
                                                    <Select
                                                        isLoading = {paisOpt.loading}
                                                        isSearchable = {true} styles = {customStylesSelect}
                                                        className = "basic-multi-select"
                                                        onChange = {changePais} options = {paises}
                                                        noOptionsMessage = {() => { return "Sin Opciones" }}
                                                        loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando paises</> }}
                                                        value = {paisSelect}
                                                        placeholder = "Pais"
                                                    />
                                                </Col>
                                                <Col md = {3} className = "mb-3">
                                                    <Select
                                                        isLoading = {estadoOpt.loading}
                                                        isSearchable = {true} styles = {customStylesSelect}
                                                        className = "basic-multi-select"
                                                        onChange = {changeEstado} options = {estados}
                                                        noOptionsMessage = {() => { return "Sin Opciones" }}
                                                        loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando estados</> }}
                                                        value = {estadoSelect}
                                                        placeholder = "Estado"
                                                    />
                                                </Col>
                                                <Col md = {3} className = "mb-3">
                                                    <Select
                                                        isLoading = {municipioOpt.loading}
                                                        isSearchable = {true} styles = {customStylesSelect}
                                                        className = "basic-multi-select"
                                                        onChange = {changeMunicipio} options = {municipios}
                                                        noOptionsMessage = {() => { return "Sin Opciones" }}
                                                        loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando municipios</> }}
                                                        value = {municipioSelect}
                                                        placeholder = "Municipio"
                                                    />
                                                </Col>
                                                <Col md = {3} className = "mb-3">
                                                    <Select
                                                        isLoading = {parroquiaOpt.loading}
                                                        isSearchable = {true} styles = {customStylesSelect}
                                                        className = "basic-multi-select"
                                                        onChange = {changeParroquia} options = {parroquias}
                                                        noOptionsMessage = {() => { return "Sin Opciones" }}
                                                        loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando parroquias</> }}
                                                        value = {parroquiaSelect}
                                                        placeholder = "Parroquia"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md = {2} align = "right">
                                            <button onClick = {searchRecinto} className = "btn btn-outline-warning" disabled = {btnSearch.disabled}>
                                                <small><FontAwesomeIcon icon = {btnSearch.icon} spin = {btnSearch.spin}/> {btnSearch.text}</small>
                                            </button>
                                        </Col>
                                    </Row>
                                    <div className = "custom-mt-10 table-responsive"  style = {{overflowY: "auto", overflowX:"auto", height: "50vh"}}>
                                        <Table className = "mb-0" bordered hover  style = {{fontSize: 10 }}>
                                            <tbody>
                                                <tr>
                                                    <th width = "25%">Nombre del recinto</th>
                                                    <th width = "30%" align = "center">Dirección</th>
                                                    <th width = "10%" align = "center">Cap. Min</th>
                                                    <th width = "10%" align = "center">Cap. Máx</th>
                                                    <th width = "10%" align = "center">Status</th>
                                                    <th width = "15%" align = "center">Acciones</th>
                                                </tr>
                                                {contentSite()}
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </section>
            </main>
            {modalCreateContent()}
            <SuccessModal isOpen = {openModalOk} close = {() => {setOpenModalOk(false)}} content = {modalMess} contentTitle = {modalOkTitle}/>
            <CardsFooter />
        </>
    )
}

export default MainSitios;