import React from "react"
import whatsapp from "../../assets/img/icons/common/whatsapp.svg"
import pdf from "../../assets/img/icons/common/pdf.svg"
import { UncontrolledTooltip } from "reactstrap"

export const Whatsapp = ({tooltip})=>{

    return (
        <>
        <UncontrolledTooltip delay={0} target="tip001">{tooltip}</UncontrolledTooltip>
            <a href="https://wa.me/+584120715875?text=Estimado%20equipo%20de%20soporte%20de%20TaquillaEnLinea,%20me%20estoy%20comunicando
            %20para%20solicitar%20información%20detallada%20sobre%20la%20compra%20de%20entradas%20para%20eventos%20a%20través%20de%20su
            %20plataforma.%20Agradecería%20su%20asistencia%20para%20entender%20mejor%20el%20proceso%20de%20compra%20y%20las%20opciones
            %20disponibles.%20Gracias%20por%20su%20tiempo%20y%20ayuda." target="_blank" rel="noreferrer">
            <img height="50" src={whatsapp} alt="WhatsApp" id='tip001' />
        </a>
    </>
    )

}

export const PDF = ({tooltip})=>{

    return (
        <>
            <UncontrolledTooltip delay={0} target="tip002">{tooltip}</UncontrolledTooltip>
            <a href="#" target="_blank" rel="noreferrer"><img height="40" src={pdf} alt="..." id="tip002" /></a>
        </>
    )

}

