import React, { useEffect, useState } from "react";

import { Badge, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faCheckCircle, faTimesCircle, faCircleNotch, faCheck } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import ContentModal2 from "components/Modals/ContentModal2";
import ConfirmModal from "components/Modals/ConfirmModal";

import { UrlImg } from "functions/Url";
import { AddDelSlider } from "functions/SlidersFunctions";
import Exclamation_Change from "assets/img/users_avatar/Exclamation_Change.jpg"

const FuncionDetalle = (props) => {
    const [botonYes, setBotonYes] = useState({ icon: faCheck, text: "Si", spin: false, disabled: false })
    const [botonAc, setBotonAc] = useState({ icon: faCogs, text: "Acciones", spin: false, disabled: false })
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [openModalImg, setOpenModalImg] = useState(false);
    const [file1, setFile1] = useState(`${UrlImg}events/default_img.jpg`);
    const [openModalCon, setOpenModalCon] = useState(false)
    const [status, setStatus] = useState(false)
    const [text, setText] = useState("Añadir")
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        setFile1(`${UrlImg}events/${props.data.evento_info_simple.image}`)

        let search = props.sliders.filter(data => {
            return data.id_evento === props.data.id_evento && data.id_funcion === props.data.id;
        })

        if (search.length > 0) {
            setStatus(true);
            setText("Retirar");
        } else {
            setStatus(false);
            setText("Añadir");
        }

        const status = props.data.evento_info_simple.status;
        const isSearchNonEmpty = search.length === 0;
        // Si el evento esta finalizado o cerrado y no esta en el slider principal
        if ((status === 0 || status === 3) && isSearchNonEmpty) {
            setDisabled(true)
        }
        else {
            setDisabled(false)
        }
    }, [props]);

    let cambiaFormato = (fecha) => {
        let separa = fecha.split("/");
        return `${separa[1]}/${separa[0]}/${separa[2]}`
    }

    let dia = (date) => {
        let diaActual = new Date(cambiaFormato(date));
        let day = diaActual.getDate();

        if (day < 10) day = `0${day}`;

        let dias = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        let meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
        let month = meses[diaActual.getMonth()];

        return `${dias[diaActual.getDay()]} ${day} de ${month} de ${diaActual.getFullYear()}`;
    }

    let modalImg = () => {
        if (openModalImg === false) return null;

        let text = (
            <main>
                <img src={Exclamation_Change} alt="" srcset="" className="img-fluid" width={200} />
                <h1>Debe colocar una imagen en este evento</h1>
            </main>

        )

        let contenido = () => (
            <div align="center" className="img-responsive">
                {(file1 === "http://localhost:8000/images/events/eventodefault_img.jpg" ? text : <img alt="img_event" src={file1} className="img img-fluid" />)}

            </div>
        )

        return (
            <ContentModal2
                isOpen={openModalImg}
                title={`Imagen del Evento`}
                size="xl"
                content={contenido()}
                close={() => setOpenModalImg(false)}
            />
        )
    }

    let modalConfirm = () => {
        if (openModalCon === false) return null;


        let search = props.sliders.filter(data => {
            return data.id_evento === props.data.id_evento && data.id_funcion === props.data.id;
        })
        const status = props.data.evento_info_simple.status;
        const isSearchNonEmpty = search.length === 0;

        // Si el evento esta finalizado o cerrado y no esta en el slider principal
        if ((status === 0 || status === 3) && isSearchNonEmpty) {
            return null;
        }

        let addDel = () => {
            setBotonYes({ icon: faCircleNotch, text: "Espera", spin: true, disabled: true })

            AddDelSlider(props.data.id_evento, props.data.id, (resp) => {
                setBotonYes({ icon: faCheck, text: "Si", spin: false, disabled: false })
                setOpenModalCon(false)
                switch (true) {
                    case (resp.status === "error" || resp.status === "error_log"):
                        toast.error(<small><FontAwesomeIcon icon={faTimesCircle} /> <span dangerouslySetInnerHTML={{ __html: resp.mess }}></span></small>);
                        break;
                    default:
                        toast.info(<small><FontAwesomeIcon icon={faTimesCircle} /> <span dangerouslySetInnerHTML={{ __html: resp.mess }}></span></small>);

                        let search = resp.data.filter(data => {
                            return data.id_evento === props.data.id_evento && data.id_funcion === props.data.id;
                        })

                        if (search.length > 0) {
                            setStatus(true);
                            setText("Retirar");
                        } else {
                            setStatus(false);
                            setText("Añadir");
                        }
                        props.onSliderChange();
                        break;
                }
            })
        }

        return (
            <ConfirmModal
                isOpen={openModalCon}
                content={<div>¿Deseas {text} este evento al <strong>Slider principal</strong>?</div>}
                disabled={botonYes.disabled}
                buttonYes={<><FontAwesomeIcon icon={botonYes.icon} spin={botonYes.spin} /> {botonYes.text}</>}
                close={() => setOpenModalCon(false)}
                funcion={addDel}
            />
        )
    }

    let statusD = () => {
        switch (status) {
            case true:
                return (<h6><Badge color="success"><FontAwesomeIcon icon={faCheckCircle} /> Activo</Badge></h6>)
            case false:
                return (<h6><Badge color="danger"><FontAwesomeIcon icon={faTimesCircle} /> Inactivo</Badge></h6>)
            default: return (<h6><Badge color="light"><FontAwesomeIcon icon={faCircleNotch} spin={true} /> Espera</Badge></h6>)
        }
    }

    return (
        <>
            <tr>
                <td className="text-primary"><strong>{props.data.evento_info_simple.name_event}</strong></td>
                <td>
                    <div><strong>DIA:</strong> {dia(props.data.fecha)}</div>
                    <div><strong>HORA:</strong> {props.data.hora_in}</div>
                </td>
                <td>
                    <strong>{props.data.recinto_simple.name}</strong>
                    <div className="text-info">{props.data.recinto_simple.address}</div>
                </td>
                <td>{props.data.evento_info_simple.categorias_tags}</td>
                <td>{props.data.evento_info_simple.artistas_tags}</td>
                <td>{statusD()}</td>
                <td>
                    <ButtonDropdown isOpen={dropdownOpen} toggle={() => { setDropdownOpen(!dropdownOpen) }} disabled={botonAc.disabled}>
                        <DropdownToggle caret className="btn-outline-info" size="sm">
                            <small><FontAwesomeIcon icon={botonAc.icon} spin={botonAc.spin} /> {botonAc.text}</small>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem disabled={disabled} style={{ padding: 5 }} onClick={() => setOpenModalCon(true)}><small>{text} al slider principal</small></DropdownItem>
                            <DropdownItem style={{ padding: 5 }} onClick={() => setOpenModalImg(true)}><small>Ver imagen del evento</small></DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>
                </td>
            </tr>
            {modalImg()}
            {modalConfirm()}
        </>
    )
}

export default FuncionDetalle;