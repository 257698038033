import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { SearchAuditInd } from "functions/SitiosFunction";
import Butaca from "views/MisSitios/Butaca";
import Mesa from "views/MisSitios/Mesa";

const Butacas = (props) => {
    const [preloadAudit, setPreloadAudit]   = useState(<div align = "center" style = {{minHeight: "40vh", overflow: "auto"}} className = "custom-mt-10"><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Buscando butacas de la zona <strong className = "text-danger">Espera por favor</strong></div>)
    const [audit, setAudit]         = useState([])
    const [colorBut, setColorBut]   = useState("#d8d8d8");
    const [tamButaca, setTamButaca] = useState(18);
    const [radButaca, setRadButaca] = useState(3);
    const [estilo, setEstilo]       = useState("default")
    const [control, setControl]     = useState(false);
    const [alternar, setAlternar]   = useState(false);
    const [customStyles, setCustomStyles]   = useState({
        backgroundColor: "#d8d8d8",
        border: "1px solid #ffffff",
        borderRadius: "3px 3px"
    })
    const [sillasDisabled, setSillasDisabled]   = useState([]);
    const [confMesas, setConsfMesas]        = useState({
        superior: 4,
        izquierda: 4,
        derecha: 4,
        inferior: 4
    })
    const [control2, setControl2]   = useState(false);


    useEffect(() => {
        SearchAuditInd (props.sitioId, props.zonaId, "default", null, (resp) => {
            if (resp.data !== null){
                setColorBut(resp.data.butaca_color);
                setTamButaca(resp.data.butaca_tam);
                setRadButaca(resp.data.butaca_radio)
                setAudit(JSON.parse(resp.data.auditorio_build));
                setEstilo(resp.data.estilo);
                setCustomStyles(state => ({...state,  backgroundColor: resp.data.butaca_color}))
                setAlternar(Boolean(resp.data.alternado))
                setSillasDisabled(JSON.parse(resp.data.sillas_disabled))
                setConsfMesas(JSON.parse(resp.data.cant_sillas))
                /* setTimeout(() => { */setControl2(true)/* }, 200); */
            }else{
                setPreloadAudit(<div align = "center" style = {{minHeight: "40vh", overflow: "auto"}} className = "custom-mt-10"><FontAwesomeIcon icon = {faTimesCircle}/> Esta zona <strong className = "text-danger">no poseee butacas registradas</strong></div>)  
            }
        })
    }, [props]);

    if (audit.length === 0) return preloadAudit

    let numL = -1;

    let colums = (col) => {
        switch(estilo){
            case "libre": return <div align = "center">No aplica la distribución de para el tipo seleccionado</div>;
            case "mesa":return (
                col.map(option => (
                    <Mesa
                        key = {`${option.id_fila}_${option.id}`}
                        confMesas = {confMesas}
                        customStyles = {customStyles}
                        data = {option}
                        numL = {numL}
                        onClick = {() => {return null}}
                        addSilla = {() =>  {return null}}
                        delSilla = {() => {return null}}
                        sillasDisabled = {sillasDisabled}
                        disabled = {true}
                    />
                ))
            )
            default: return(
                col.map(option => (
                    <Butaca
                        titleDis = {true}
                        onClick = {() => {return null}}
                        control = {control}
                        key = {`${option.id_fila}_${option.id}`}
                        data = {option}
                        numL = {numL}
                        sumNumL = {(num) => {numL = numL + num}}
                        styles = {customStyles}
                        tamButaca = {tamButaca}
                        radButaca = {radButaca}
                        colorButaca = {colorBut}
                        estiloButaca = {estilo}
                    />
                ))
            )
        }
    }

    let fontSize = 10;
    let nums = [];
    let numsD = [];

    let stylesSpan = {
        fontSize: fontSize, height: tamButaca, minWidth: tamButaca, maxWidth: tamButaca
    }

    /* nums.push({id: -1, content: <span style = {stylesSpan} className = "butaca-num"></span>}); */
    nums.push({id: 0, content: <span style = {stylesSpan} className = "butaca-num"></span>});
    /* numsD.push({id: -1, content: <span style = {stylesSpan} className = "butaca-num"></span>}); */
    numsD.push({id: 0, content: <span style = {stylesSpan} className = "butaca-num"></span>});


    for (let i = 1, n = 1; i <= audit[0].columnas.length; i++){
        if (i > 99) fontSize = 8;
            numsD.push({id: i, content: <span style = {stylesSpan} className = "butaca-num">{i}</span>});

        /* Buscar el numero en las columnas a ver si estan disabled */
        let idCol = audit[0].columnas[i - 1].id;
        if(!isNaN(Number(idCol)) === true){
            nums.push({id: i, content: <span style = {stylesSpan} className = "butaca-num"><strong className = "text-primary">{n}</strong></span>});
            n++;
        }else{
            nums.push({id: i, content: <span style = {stylesSpan} className = "butaca-num"><FontAwesomeIcon icon = {faCircle} style = {{fontSize: 7, color: "#ccc"}}/></span>});
        }
    }

    let filName = (id, letra) => {
        if(!isNaN(Number(id)) === true){
            return <span style = {{fontSize: 10, height: tamButaca, minWidth: tamButaca, maxWidth: tamButaca}} className = "butaca-num"><strong>{letra}</strong></span>
        }else{
            return <span style = {{fontSize: 7, color: "#ccc", height: tamButaca, minWidth: tamButaca, maxWidth: tamButaca}} className = "butaca-num"><FontAwesomeIcon icon = {faCircle}/></span>
        }
    }

    let num = 0;

    let leyendaSuperior = () => {
        if (estilo === "mesa") return null;

        return (
            <>
                {nums.map(option => ( <React.Fragment key = {option.id}>{option.content}</React.Fragment> ))}
            </>
        )
    }

    if (control2 === false) return null;
    if (estilo === "libre") return <div align = "center">No aplica la distribución de para el tipo seleccionado</div>;
    
    return(
        <div /* align = "center" */ style = {{maxHeight: "70vh", overflow: "auto", paddingBottom: 15}}>
            {leyendaSuperior()}
            
            {audit.map(option => {
                num++;

                let styles = {};
                let butAdicional = "";

                if (num % 2 === 0 && alternar === true){
                    styles = {marginLeft: 20};
                    butAdicional = <span className = "butaca-num" style = {{height: tamButaca, minWidth: 10, backgroundColor: "#fff"}}></span>
                }

                return (
                    <div key = {option.id}>
                        {filName(option.id, option.letraAlt)}
                        {butAdicional}
                        {colums(option.columnas)}
                    </div>
                )
            })}
        </div>   
    )
}

export default Butacas;
