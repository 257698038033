import { SendCode } from 'functions/MultiPaymentFunctions';
import { PayWithTDD } from 'functions/MultiPaymentFunctions';
import React from 'react'
import { toast } from 'react-toastify';

function DebitCardButtons({method, handleCheckboxChange, idFactura, montos, resetMethods}) {
    const handleCode = (method)=>{
        const Data = {
            cardNumber: method.cardNumber,
            ci: method.ci
        }

        SendCode(Data, (resp)=>{
            if (resp.original && resp.original !== undefined) {
                let errors = resp.original.responseBody.error_list;

                errors.map((error)=>
                    toast.error(error.description)
                )
            }else{
                toast.success("Se ha enviado un código de uso único a sus dispositivos vinculados al banco.")
            }
        })
    }

    const handleDebitCard = (method)=>{
        let contentDataEvent = JSON.parse(sessionStorage.getItem("ContentDataEvent"));
        let tasa = JSON.parse(sessionStorage.getItem("ContentDataCurrency"));
        let status = true;

        

        if (method.moneda === "USD") {
            let result = parseFloat(montos.usd) - parseFloat(method.amount)

            if(result < 5 && result !== 0){
                toast.error("El monto restante no puede ser menor a 5$");
                status = false
            }

            if (parseFloat(method.amount) > parseFloat(montos.usd)) {
                toast.error("El monto seleccionado no puede ser mayor al total");
                status = false
            }
        }else if (method.moneda === "Bs."){
            let result = parseFloat(montos.bs) - parseFloat(method.amount)

            if(result < tasa.valor_1usd_moneda_local * 5 && result > 0.0){
                toast.error("El monto restante no puede ser menor a 5$");
                status = false
            }

            if (parseFloat(method.amount) > parseFloat(montos.bs)){
                toast.error("El monto seleccionado no puede ser mayor al total");
                status = false
            }
        } 
        if(status === true){
            PayWithTDD(contentDataEvent.id_evento, contentDataEvent.id, method, contentDataEvent.fecha, contentDataEvent.hora_in, idFactura,(resp)=> {
                if(resp.msg === 'approved'){
                    toast.success("Su pago ha sido registrado satisfactoriamente")
                    let metodos = JSON.parse(resp.factura.data_transaccion)
    
                    let additionalProps = {
                        id_factura: resp.factura.id,
                        ci: metodos.ci,
                        amount: metodos.amount,
                        cardNumber: metodos.cardNumber,
                        cvv: metodos.cvv,
                    }
                    handleCheckboxChange(metodos.id, metodos.method, metodos.moneda, metodos.conciliable  ,additionalProps)
                    resetMethods()
                }else{
                    toast.error(resp.msg)
                }
            })
        }   
    }

  return (
    <div className='mt-2 d-flex justify-content-end'>
    <button className='btn btn-primary' onClick={()=>handleCode(method)}>
        Solicitar Código
    </button>
    <button className='btn btn-primary' onClick={()=>handleDebitCard(method)}>
        Confirmar
    </button>
</div>
  )
}

export default DebitCardButtons