import React, { useEffect, useState } from 'react';
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faFrownOpen } from '@fortawesome/free-solid-svg-icons';

const ErroresList = (props) => {
    const [errMess, setErrMess] = useState(props.errMess)
    const [popoverErr, setPopoverErr] = useState(false)
    const [visibleErr, setVisibleErr] = useState(props.visibleErr)
    const [visibleErr2, setVisibleErr2] = useState(props.visibleErr2)

    useEffect(() => {
        setErrMess(props.errMess)
        setVisibleErr(props.visibleErr)
        setVisibleErr2(props.visibleErr2)
    }, [props]);

    let erroresLists = () => {
        if (errMess !== "") return (
            errMess.errMesss.map(option => (
                <div key = {(Math.random() * 9999) + 1}>
                    <FontAwesomeIcon size = "xs" color = "#C71C22" icon = {faExclamationTriangle}/> <span style = {{fontSize: 10, padding: 5}} dangerouslySetInnerHTML = {{ __html: option }}></span>
                </div>
            ))
        )
    }

    return (
        <div style = {{fontSize: 12, display: visibleErr, visibility: visibleErr2}} className = "text-muted">
            <FontAwesomeIcon color = "#C71C22" icon = {faExclamationTriangle}/> Se han encontrado <strong>({errMess.cantidad}) error(es).</strong> Haz click <Button size = "sm" id = {props.name} className = "btn-padding" color = "danger" onClick = {() => { setPopoverErr(!popoverErr) }}>Aqui</Button> para ver.
            <Popover placement = "top" isOpen = {popoverErr} target = {props.name} toggle = {() => { setPopoverErr(!popoverErr) }}>
                <PopoverHeader className = "modal-error"><FontAwesomeIcon size = "sm" icon = {faFrownOpen}/> Errores encontrados</PopoverHeader>
                <PopoverBody>
                    <div /* style = {{overflowY: "auto", maxHeight: 300}} */ onClick = {() => { setPopoverErr(!popoverErr) }}>
                        {erroresLists()}
                    </div>
                </PopoverBody>
            </Popover>
        </div>
    )
}

export default ErroresList;