import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";

import { Button, Card, CardHeader, CardBody, Input, InputGroupAddon, InputGroupText, InputGroup, Row, Col } from "reactstrap";
import Compressor from 'compressorjs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faEdit, faCircleNotch, faTimesCircle, faCheckCircle, faHome, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import NavbarI from "components/Navbars/NavbarI.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import Sidebar from '../dashboard/Sidebar';
import EventoModal from "../../components/Modals/EventoModal";
import ErroresList from "../../components/Errores/ErroresList";
import ErrorModal2 from "../../components/Modals/ErrorModal2";

import { formatBytes } from '../../functions/Generales';
import { ConsultDatPerfil, ModPerfil, NewPass, RegTarjeta, ConsultarTDC } from '../../functions/PerfilFunctions';
import { UrlImg } from '../../functions/Url';
import SuccessModal from "components/Modals/SuccessModal";


const MainPerfil = (props) => {
    const [botonSave, setBotonSave]     = useState({ icon: faSave, text: "Guardar Cambios", spin: false, disabled: true })
    const [botonPass, setBotonPass]     = useState({ icon: faEdit, text: "Cambiar", spin: false, disabled: true })
    const [openModal, setOpenModal]     = useState(false);
    const [item1, setItem1]             = useState({ nombre: "", tipo: "", size: "" });
    const [file1, setFile1]             = useState(`${UrlImg}default-avatar.png`);
    const [name, setName]               = useState("");
    const [lastName, setLastName]       = useState("");
    const [email, setEmail]             = useState("");
    const [cPass, setCPass]             = useState("");
    const [pass1, setPass1]             = useState("");
    const [pass2, setPass2]             = useState("");
    const [tlf, setTlf] = useState("");
    const [ci, setCi] = useState("");
    const [errMess, setErrMess]         = useState("")
    const [visibleErr, setVisibleErr]   = useState("none")
    const [errMess2, setErrMess2]       = useState("")
    const [visibleErr2, setVisibleErr2] = useState("none")
    const [openModalErr, setOpenModalErr]   = useState(false)
    const [openModalErr2, setOpenModalErr2] = useState(false)
    const [openModalOk, setOpenModalOk] = useState(false)
    const [modalOk, setModalOk]         = useState("")
    const [modalMess, setMmodalMess]    = useState("")
    const [mess, setMess]               = useState("")
    const [imgSelect, setImgSelect]     = useState(false)

    const [openModalTDC, setopenModalTDC]     = useState(false);
    const [nroTarjeta, setnroTarjeta] = useState("");
    const [cvv, setCvv] = useState("");
    const [cid, setCid] = useState("");
    const [fecha, setFecha] = useState("");
    const [cliente, setCliente] = useState("");
    const [alias, setAlias] = useState("");
    
    

    useEffect(() => {
        ConsultDatPerfil((resp) => {
            setName(resp.name)
            setLastName(resp.last_name)
            setEmail(resp.email)
            setCi(resp.ci)
            setTlf(resp.tlf)
            setBotonSave(state => ({...state, disabled: false }))
            setBotonPass(state => ({...state, disabled: false }))
            if(resp.image !== "" && resp.image !== null)setFile1(`${UrlImg}${resp.image}`)
        })
    }, [props]);

    let infoFile = (item) => {
        if(item.nombre === "") return null;

        return(
            <div className = "text-danger" style = {{fontSize: 10}}>{`${item.nombre} - ${item.size})`}</div>
        )
    }
    
    let addImg = (item) => (e) => {
        document.getElementById(item).click();
    }

    let selectFile = (item) => (e) => {
        let file = document.getElementById(item);
        let archivo = file.value;

        if (archivo === ""){
            toast.error("No has seleccionado ningún archivo",{autoClose: 4000});
            setImgSelect(false)
            return null
        }

        let extension = archivo.split('.').pop();
        let ext = extension.toLowerCase();
        let fic = archivo.split('\\');
        let nombre = fic[fic.length -1];
        let size = file.files[0].size;

        if (ext === "jpg" || ext === "jpeg" || ext === "png"){
            let msjSize = formatBytes(size);
            if (size > 1000000) {
                /* Comprimir el archivo */
                let fil = file.files[0];
                new Compressor(fil, {
                    quality: 0.2,
                    convertSize: 1000000,
                    success(result) {
                        /* Convertir a Base 64 */
                        let reader = new FileReader();
                        reader.readAsDataURL(result);

                        msjSize = `Tamaño Original: ${formatBytes(size)} [Tamaño Comprimido: ${formatBytes(result.size)}]`

                        reader.onloadend = function() { setFile1(reader.result) }
                        setItem1({ nombre: `Agregado: (${nombre}`, tipo: extension, size: msjSize })
                    },
                    error(err) {
                        toast.error("La imagen es muy grande y no pudo comprimirse",{autoClose: 4000});
                        document.getElementById(item).value = "";
                    }
                })
            }else{
                /* Convertir a Base 64 */
                let reader = new FileReader();
                reader.readAsDataURL(file.files[0]);

                reader.onloadend = function() { setFile1(reader.result) }
                setItem1({ nombre: `Agregado: (${nombre}`, tipo: extension, size: formatBytes(size) })
            }
            setImgSelect(true)
        }else{
            toast.error("No has seleccionado un archivo de imagen",{autoClose: 4000});
            document.getElementById(item).value = "";
            setImgSelect(false)
            return null
        }
    }

    let saveCreditCard = () => {
        let campos = {nroTarjeta, cvv, cid, fecha, cliente, alias};
        setBotonSave({ icon: faCircleNotch, text: "Guardando Espera", spin: true, disabled: false })

        RegTarjeta(campos, (resp) => {
            setBotonSave ({ icon: faSave, text: "Guardar Cambios", spin: false, disabled: false })
            if(resp[1].errors) {
                let error = resp[1];
                let errores = error.errors
                for(let i = 0; i < errores.length; i++) {
                    toast.error(errores[i],{autoClose: 4000});
                }
            }else if(resp[1].msg) {
                toast.success('Su tarjeta de credito ha sido registrada de manera exitosa',{autoClose: 4000});
            }
        })
       
    }

    let consultTDC = () =>{
        let campos = {tdc_id:null};

        ConsultarTDC(campos, (resp)=> {
        })
    }

    let save = () => {
        let campos = { name, lastName, ci, tlf , email, file1, imgSelect }
        setBotonSave ({ icon: faCircleNotch, text: "Guardando Espera", spin: true, disabled: true })
        
        ModPerfil (campos, document.getElementById("fileImg").value, (resp) => {
            setBotonSave ({ icon: faSave, text: "Guardar Cambios", spin: false, disabled: false })
            switch (true) {
                case (resp[0].cantidad > 0):
                    setErrMess(resp[0])
                    setVisibleErr("block")
                    toast.error(<div><FontAwesomeIcon icon = {faTimesCircle}/> No se guardó la información</div>);
                break;
                case (resp[1].status === "error_log"):
                    setOpenModalErr(true)
                    setMess(resp[1].mess)
                    setVisibleErr("none")
                    setErrMess("")
                break;
                case (resp[1].status === "error"):
                    setOpenModalErr2(true)
                    setMess(resp[1].mess)
                    setVisibleErr("none")
                    setErrMess("")
                break;
                default:
                    setOpenModalOk(true)
                    setModalOk("Datos Modificados")
                    setMmodalMess(resp[1].mess)
                    setVisibleErr("none")
                    setErrMess("")
                break;
            }
        })
    }

    let changePass = () => {
        let campos = { cPass, pass1, pass2 }
        setBotonPass ({ icon: faCircleNotch, text: "Comprobando Espera", spin: true, disabled: true })

        NewPass (campos, (resp) => {
            setBotonPass ({ icon: faEdit, text: "Cambiar", spin: false, disabled: false })
            switch (true) {
                case (resp[0].cantidad > 0):
                    setErrMess2(resp[0])
                    setVisibleErr2("block")
                    toast.error(<div><FontAwesomeIcon icon = {faTimesCircle}/> No se guardó la información</div>);
                break;
                case (resp[1].status === "error"):
                    setVisibleErr2("none")
                    setErrMess2("")
                    toast.error(<div><FontAwesomeIcon icon = {faCheckCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp[1].mess }}></span></div>);
                break;
                default:
                    setOpenModalOk(true)
                    setModalOk("Datos Modificados")
                    setMmodalMess(resp[1].mess)
                    setVisibleErr("none")
                    setErrMess("")
                    setCPass("")
                    setPass1("")
                    setPass2("")
                    setVisibleErr2("none")
                    setErrMess2("")
                    setOpenModal(false)
                break;
            }
        })
    }

    let contentModalTDC = (
        <Row>
            <Col md = {8}>
                <div style = {{color: "#000000", fontWeight: "bold", fontSize: 12}}>Nro. de Tarjeta de Credito</div>
                <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <i className="ni ni-single-02" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder = "Ingresa tu numero de tarjeta de Credito" maxLength ='16' value = {nroTarjeta} onChange = {(e) => setnroTarjeta(e.target.value)}/>
                </InputGroup>
            </Col>
            <Col md = {4}>
                <div style = {{color: "#000000", fontWeight: "bold", fontSize: 12}}>Identificacion</div>
                <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <i className="ni ni-single-02" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder = "Ingresa tu numero de tarjeta de Credito" maxLength ='16' value = {cid} onChange = {(e) => setCid(e.target.value)}/>
                </InputGroup>
            </Col>
            <Col md = {6}>
                <div style = {{color: "#000000", fontWeight: "bold", fontSize: 12}}>CVV</div>
                <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <i className="ni ni-single-02" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder = "Ingresa el codigo de seguridad de la tarjeta" maxLength ='3'  value = {cvv} onChange = {(e) => setCvv(e.target.value)}/>
                </InputGroup>
            </Col>
            <Col md = {6}>
                <div style = {{color: "#000000", fontWeight: "bold", fontSize: 12}}>Fecha de Expiracion MM/AA</div>
                <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <i className="ni ni-single-02" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder = "Fecha de Expiracion MM/AA" maxLength = "5" type = "text" value = {fecha} onChange = {(e) => setFecha(e.target.value)}/>
                </InputGroup>
            </Col>
            <Col md = {8}>
                <div style = {{color: "#000000", fontWeight: "bold", fontSize: 12}}>Propietario</div>
                <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <i className="ni ni-single-02" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder = "Ingresa tu nombre" type = "text" value = {cliente} onChange = {(e) => setCliente(e.target.value)}/>
                </InputGroup>
            </Col>
            <Col md = {4}>
                <div style = {{color: "#000000", fontWeight: "bold", fontSize: 12}}>Alias</div>
                <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <i className="ni ni-single-02" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder = "Ingresa un Alias" type = "text" maxLength = "25" value = {alias} onChange = {(e) => setAlias(e.target.value)}/>
                </InputGroup>
            </Col>

            <Col md = {12}  align = "right" className = "custom-mt-15">
                <Button disabled = {botonSave.disabled} color = "default" type="button" onClick = {saveCreditCard}>
                    <small><FontAwesomeIcon spin = {botonSave.spin} icon = {botonSave.icon}/> {botonSave.text}</small>
                </Button>
            </Col>  
        </Row>
        )

    let contentModal = (
        <Row>
            <Col md = {6}>
                <div className = "text-muted">Contraseña actual</div>
                <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder = "Ingresa tu contraseña actual" type = "password" value = {cPass} onChange = {(e) => setCPass(e.target.value)}/>
                </InputGroup>
            </Col>
            <Col md = {6}>

            </Col>
            <Col md = {6} className = "custom-mt-10">
                <div className = "text-muted">Nueva contraseña</div>
                <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder = "Ingresa la nueva contraseña" type = "password" value = {pass1} onChange = {(e) => setPass1(e.target.value)}/>
                </InputGroup>
            </Col>
            <Col md = {6} className = "custom-mt-10">
                <div className = "text-muted">Confirma la nueva contraseña</div>
                <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder = "Confirma la nueva contraseña" type = "password" value = {pass2} onChange = {(e) => setPass2(e.target.value)}/>
                </InputGroup>
            </Col>
            <Col md = {7}>
                <div style = {{marginTop: 10}}>
                    <ErroresList errMess = {errMess2} visibleErr = {visibleErr2} name = "popErr"/>
                </div>
            </Col>
            <Col md = {5} align = "right" className = "custom-mt-10">
                <Button color="default" type="button" disabled = {botonPass.disabled} onClick = {changePass}>
                    <small><FontAwesomeIcon spin = {botonPass.spin} icon = {botonPass.icon}/> {botonPass.text}</small>
                </Button>
            </Col>
            
        </Row>
    )

    return(
        <>
            <NavbarI />
            <main>
                <section className="section section-shaped section-lg">
                    <div className = "d-block d-sm-block d-md-block" align = "right" style = {{paddingRight: 15}}>
                        <small class = "bread-crumb-custom"><Link to = "/dashboard/home"><FontAwesomeIcon icon = {faHome}/></Link> | <FontAwesomeIcon icon = {faAddressBook}/> Mi Perfil</small>
                    </div>
                    <Row>
                        <Col md = {3} className = "d-none d-sm-none d-md-block">
                            <Sidebar active = "Mi Perfil"/>
                        </Col>
                        <Col md = {9} className = "custom-p-32">
                            <Card>
                                <CardHeader align = "right">
                                    <h5>Mi Perfil</h5>
                                    <div className = "text-muted" style = {{fontSize: 10, marginTop: -10}}>Completa todos los campos para continuar</div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md = {4} align = "center" className = "custom-mt-10" style = {{borderRight: "1px solid #f3f3f3"}}>
                                            <img src = {file1} onClick = {addImg("fileImg")} title = "Haz click aqui para cambiar la imagen" height = "200" width = "200" className = "img rounded-circle" alt = "user_image" style={{cursor: "pointer", border: "1px  solid #cccccc"}}/>
                                            {infoFile(item1)}
                                            <input type = "file" onChange= {selectFile("fileImg")} id = "fileImg" style = {{ display: "none" }}/>
                                        </Col>
                                        <Col md = {8} style = {{padding: 40}}>
                                            <Row>
                                                <Col md = {12}>
                                                    <div style = {{color: "#000000", fontWeight: "bold", fontSize: 12}}>* Nombres</div>
                                                    <InputGroup className="input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                            <i className="ni ni-single-02" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input placeholder = "Ingresa tu nombre" type = "text" value = {name} onChange = {(e) => setName(e.target.value)}/>
                                                    </InputGroup>
                                                </Col>
                                                <Col md = {12} className = "custom-mt-10">
                                                    <div style = {{color: "#000000", fontWeight: "bold", fontSize: 12}}>* Apellidos</div>
                                                    <InputGroup className="input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                            <i className="ni ni-single-02" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input placeholder = "Ingresa tu apellido" type = "text" value = {lastName} onChange = {(e) => setLastName(e.target.value)}/>
                                                    </InputGroup>
                                                </Col>
                                                <Col md = {12}>
                                                    <div style = {{color: "#000000", fontWeight: "bold", fontSize: 12}}>* Identificación</div>
                                                    <InputGroup className="input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                            <i className="ni ni-single-02" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input placeholder = "Ingresa tu Identificacion" type = "number" value = {ci} onChange = {(e) => setCi(e.target.value)}/>
                                                    </InputGroup>
                                                </Col>

                                                <Col md = {12}>
                                                    <div style = {{color: "#000000", fontWeight: "bold", fontSize: 12}}>* Teléfono</div>
                                                    <InputGroup className="input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                            <i className="ni ni-single-02" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input placeholder = "Ingresa tu Telefono" type = "text" value = {tlf} onChange={(event) => {
                                                            const newValue = event.target.value;
                                                            if (!isNaN(newValue)) {
                                                                setTlf(newValue);
                                                            }
                                                            }} />
                                                    </InputGroup>
                                                </Col>
                                                <Col md = {12} className = "custom-mt-10">
                                                    <div style = {{color: "#000000", fontWeight: "bold", fontSize: 12}}>* Correo electrónico</div>
                                                    <InputGroup className="input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                            <i className="ni ni-email-83" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input placeholder = "Ingresa tu correo electrónico" type = "email" value = {email} onChange = {(e) => setEmail(e.target.value)}/>
                                                    </InputGroup>
                                                </Col>
                                                <Col md = {8} className = "custom-mt-10">
                                                    <div style = {{color: "#000000", fontWeight: "bold", fontSize: 12}}>Contraseña</div>
                                                    <InputGroup className="input-group-alternative" disabled title = "Para cambiar la contraseña haz click en CAMBIAR">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                            <i className="ni ni-lock-circle-open" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input placeholder = "Ingresa tu contraseña" type = "password" value = "**********"/>
                                                    </InputGroup>
                                                </Col>
                                                <Col md = {4} className = "custom-mt-10" align = "right">
                                                    <button className="my-4 btn btn-outline-warning" title = "Cambiar la Contraseña" onClick = {() => {setOpenModal(true)}}>
                                                        <small><FontAwesomeIcon spin = {botonPass.spin} disabled = {botonPass.disabled} icon = {faEdit}/> Cambiar</small>
                                                    </button>
                                                </Col>
                                            </Row>
                                            {/* <Row>
                                            <Col md = {3} className = "custom-mt-6" align = "right">
                                                    <button className="my-4 btn btn-outline-warning" title = "Registrar TDC" onClick = {() => {setopenModalTDC(true)}}>
                                                        <FontAwesomeIcon spin = {botonPass.spin} disabled = {botonPass.disabled} icon = {faCreditCard}/><small>Registrar TDC</small>
                                                    </button>
                                                </Col>
                                            </Row> */}
                                        </Col>
                                        <Col md = {7}>
                                            <div className = "text-danger" style = {{fontSize: 10}}>* CAMPOS OBLIGATORIOS</div>
                                            <div style = {{marginTop: -2}}>
                                                <ErroresList errMess = {errMess} visibleErr = {visibleErr} name = "popErr2"/>
                                            </div>
                                        </Col>
                                        <Col md = {5} align = "right">
                                            <Button disabled = {botonSave.disabled} color = "default" type="button" onClick = {save}>
                                                <small><FontAwesomeIcon spin = {botonSave.spin} icon = {botonSave.icon}/> {botonSave.text}</small>
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </section>
            </main>
            <CardsFooter />
            <EventoModal size = "lg" isOpen = {openModal} titulo = "Cambiar la Contraseña" contenido = {contentModal} close = {() => {setOpenModal(false)}}/>
            <EventoModal size = "lg" isOpen = {openModalTDC} titulo = "Registrar Tarjeta de Credito" contenido = {contentModalTDC} close = {() => {setopenModalTDC(false)}}/>

            <ErrorModal2 isOpen = {openModalErr2} close = {() => {setOpenModalErr2(false)}} mess = {mess} />
            <ErrorModal2 isOpen = {openModalErr} close = {() => {
                setOpenModalErr(false);
                document.location.reload();
            }} mess = {mess} />
            <SuccessModal isOpen = {openModalOk} close = {() => {setOpenModalOk(false)}} content = {modalMess} contentTitle = {modalOk}/>
        </>
    )
}

export default MainPerfil;