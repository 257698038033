import React, { useEffect, useState, useRef } from "react";
import { Redirect } from "react-router-dom";
import Select from 'react-select';
import { Link } from "react-router-dom";

import 'moment/locale/es';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faEye, faEyeSlash, faChevronCircleLeft, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Button, Input, InputGroupAddon, InputGroupText, InputGroup, Row, Col } from "reactstrap";

import CardsFooter from "components/Footers/CardsFooter.js";
import ReactDatetime from "react-datetime";

import { AddUser, ConsultPaises, ConsultEstados, ConsultMunicipios, ConsultParroquias } from "../../functions/UserFunctions";
import ErrorsModal from "../../components/Modals/ErrorsModal";
import SuccessModal from "../../components/Modals/SuccessModal";
import { CalculaEdad } from "functions/Generales";

import logoColor from '../../assets/img/logos/logo_origin.png';
import { toast } from "react-toastify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

// jgil: Este es un input que permite aplicar una mascara
import InputMask from 'react-input-mask';

const Register = (props) => {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [bDate, setBDate] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [longAge, setLongAge] = useState("");
  const [email, setEmail] = useState("");
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [btnCreate, setBtnCreate] = useState({ icon: "fa fa-plus", text: "Crear Cuenta" });
  const [errors, setErrors] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalOk, setOpenModalOk] = useState(false);
  const [modalOk, setModalOk] = useState("");
  const [modalMess, setModalMess] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [viewPass1, setViewPass1] = useState({ icon: faEye, title: "Mostrar Contraseña", type: "password", active: true });
  const [viewPass2, setViewPass2] = useState({ icon: faEye, title: "Mostrar Contraseña", type: "password", active: true });
  const [paisSelect, setPaisSelect] = useState({ value: "", label: "País" });
  const [paisOpt, setPaisOpt] = useState({ disabled: false, loading: true })
  const [paises, setPaises] = useState([]);
  const [estadoSelect, setEstadoSelect] = useState({ value: "", label: "Estado" });
  const [estadoOpt, setEstadoOpt] = useState({ disabled: true, loading: false })
  const [estados, setEstados] = useState([]);
  const [municipioSelect, setMunicipioSelect] = useState({ value: "", label: "Municipio" });
  const [municipioOpt, setMunicipioOpt] = useState({ disabled: true, loading: false })
  const [municipios, setMunicipios] = useState([]);
  const [parroquiaSelect, setParroquiaSelect] = useState({ value: "", label: "Parroquia" });
  const [parroquiaOpt, setParroquiaOpt] = useState({ disabled: true, loading: false })
  const [parroquias, setParroquias] = useState([]);
  const [ciSelect, setCiSelect] = useState({ value: "V", label: "V" });
  const [ci, setCi] = useState([
    { value: "V", label: "V" },
    { value: "E", label: "E" },
    { value: "C", label: "C" },
    { value: "J", label: "J" },
    { value: "P", label: "P" }
  ]);
  const [ciNum, setCiNum] = useState("");
  const [modalOkTitle, setModalOkTitle] = useState("")
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [codArea, setCodArea] = useState("");
  const [selectedCodArea, setSelectedCodArea] = useState({ value: "", label: "Cod. Area" })

  const [hasEightChars, setHasEightChars] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [passConfirm, setPassConfirm] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFocused2, setIsFocused2] = useState(false);



  // jgil: Para cerrar el dropdown cuando se haga click fuera de el
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Escuchar por click fuera del dropdown
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Limpiar el event listener cuando el componente se desmonte
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    let Content = [];
    let ContentCod = [];

    ConsultPaises((resp) => {
      (resp.data).forEach(i => {
        Content.push({
          value: i.id, label: i.pais_name
        })
        ContentCod.push({
          value: i.id, label: i.cod_area, pais: i.pais_name
        })
      })

      setPaises(Content)
      setCodArea(ContentCod)
      setPaisOpt({ disabled: true, loading: false })
    })
  }, []);

  let changePais = (paisSelect) => {
    setPaisSelect(paisSelect);
    setEstadoSelect({ value: "", label: "Estado" });
    setMunicipioSelect({ value: "", label: "Municipio" });
    setParroquiaSelect({ value: "", label: "Parroquia" });

    let Content = [];

    setEstadoOpt({ disabled: false, loading: true })
    ConsultEstados(paisSelect.value, (resp) => {
      (resp.data).forEach(i => {
        Content.push({
          value: i.id, label: i.estado_name
        })
      })

      setEstados(Content)
      setEstadoOpt({ disabled: true, loading: false })
    })
  }

  let changeEstado = (estadoSelect) => {
    setEstadoSelect(estadoSelect);
    setMunicipioSelect({ value: "", label: "Municipio" });
    setParroquiaSelect({ value: "", label: "Parroquia" });

    let Content = [];

    setMunicipioOpt({ disabled: false, loading: true })
    ConsultMunicipios(estadoSelect.value, (resp) => {
      (resp.data).forEach(i => {
        Content.push({
          value: i.id, label: i.municipio_name
        })
      })

      setMunicipios(Content)
      setMunicipioOpt({ disabled: true, loading: false })
    })
  }

  let changeMunicipio = (municipioSelect) => {
    setMunicipioSelect(municipioSelect)
    setParroquiaSelect({ value: "", label: "Parroquia" });

    let Content = [];
    setParroquiaOpt({ disabled: false, loading: true });
    ConsultParroquias(municipioSelect.value, (resp) => {
      (resp.data).forEach(i => {
        Content.push({
          value: i.id, label: i.parroquia_name
        })
      })

      setParroquias(Content)
      setParroquiaOpt({ disabled: true, loading: false });
    })
  }

  let changeParroquia = (parroquiaSelect) => {
    setParroquiaSelect(parroquiaSelect)
  }

  let changeCi = (ciSelect) => {
    setCiNum("");
    setCiSelect(ciSelect)
  }

  function eliminarCaracteresEspeciales(cadena) {
    return cadena.replace(/[()\s-]/g, '');
  }
  let CrearCuenta = async (e) => {

    setBtnCreate ({icon: "fa fa-spinner fa-spin", text: "Registrando, espera"})

    e.preventDefault();

    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha("homepage");

    let phoneNumber = selectedOption + eliminarCaracteresEspeciales(phone);

    const required = { ciNum, ciSelect, name, bDate, phoneNumber, age, email, pass1, pass2, paisSelect, estadoSelect, municipioSelect, parroquiaSelect, accepted }
    const fields = { ciNum, ciSelect, name, lastName, bDate, phoneNumber, age, email, pass1, pass2, paisSelect, estadoSelect, municipioSelect, parroquiaSelect, token, accepted }

    AddUser(required, fields, (res) => {
      switch (true) {
        case (res[0].cantidad > 0):
          setErrors(res[0].errMesss)
          setOpenModal(true)
          setBtnCreate({ icon: "fa fa-plus", text: "Crear Cuenta" })
          break;
        case (res[1].status === "errorRecaptcha"):
          toast.error(res[1].msg)
          setBtnCreate({ icon: "fa fa-plus", text: "Crear Cuenta" })
          break;
        case (res[1].status === "error"):
          setErrors(res[1].mess)
          setOpenModal(true)
          setBtnCreate({ icon: "fa fa-plus", text: "Crear Cuenta" })
          break;
        case (res[1].status === "errorBackend"):
          res[1].msg.forEach(error => {
            toast.error(error)
          });
          setBtnCreate({ icon: "fa fa-plus", text: "Crear Cuenta" })
          break;
        default:
          setModalOk("Resultado")
          setOpenModalOk(true)
          setModalMess(res[1].mess)
          setName("")
          setLastName("")
          setBDate("")
          setPhone("")
          setAge("")
          setEmail("")
          setPass1("")
          setPass2("")
          setBtnCreate({ icon: "fa fa-plus", text: "Crear Cuenta" })
          break;
      }
    })
  }

  if (redirect === true) return (
    <Redirect to="/login" />
  )

  let verPass1 = () => {
    (viewPass1.active === true) ?
      setViewPass1({ icon: faEyeSlash, title: "Ocultar Contraseña", type: "text", active: false })
      :
      setViewPass1({ icon: faEye, title: "Mostrar Contraseña", type: "password", active: true })
  }

  let verPass2 = () => {
    (viewPass2.active === true) ?
      setViewPass2({ icon: faEyeSlash, title: "Ocultar Contraseña", type: "text", active: false })
      :
      setViewPass2({ icon: faEye, title: "Mostrar Contraseña", type: "password", active: true })
  }

  const customStylesSelect = {
    menu: styles => ({ ...styles, color: "#000", fontSize: 14, zIndex: 3 }),
    multiValue: styles => ({ ...styles, backgroundColor: "#ccc" }),
    multiValueLabel: styles => ({ ...styles, color: "#000" }),
    placeholder: styles => ({ ...styles, fontSize: 14, color: "#b4bcc3" }),
  };

  const customStylesSelect2 = {
    menu: styles => ({ ...styles, color: "#000", fontSize: 16, zIndex: 3, minWidth: 20 }),
    multiValue: styles => ({ ...styles, backgroundColor: "#ccc" }),
    multiValueLabel: styles => ({ ...styles, color: "#000" }),
    placeholder: styles => ({ ...styles, fontSize: 14, color: "#b4bcc3" }),
    indicatorSeparator: styles => ({ ...styles, backgroundColor: "rgba(255, 255, 255, 0)" }),
    control: styles => ({
      ...styles,
      borderRight: 0,
      borderColor: "#cccccc !important",
      boxShadow: "0 0 0 0px !important",
      borderRadius: 0,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      minHeight: 43,
      "&:hover": { borderColor: "#cccccc !important", boxShadow: "0 0 0 0px !important" }
    }),
  };

  const validatePassword = (password) => {
    const hasEightChars = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasSymbol = /[\W]/.test(password);

    setHasEightChars(hasEightChars);
    setHasUpperCase(hasUpperCase);
    setHasLowerCase(hasLowerCase);
    setHasSymbol(hasSymbol);

    setAccepted(hasEightChars && hasUpperCase && hasLowerCase && hasSymbol);
  };

  const validatePasswordConfirm = (pass, passTwo) => {
    if (pass !== "" && passTwo !== "" && pass === passTwo) {
      setPassConfirm(true);
    } else {
      setPassConfirm(false);
    }
  };
  

  return (
    <>
      <Row style={{ marginLeft: 0, marginRight: 0 }}>
        <Col md={5} style={{ paddingRight: 0, paddingLeft: 0, color: "#ffffff", backgroundColor: "#d1860c" }} align="center" className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
          <div className="img-register" style={{ height: "100vh", paddingTop: "36vh" }}>
            <div style={{ fontSize: 40 }} className="custom-mt-20"><strong>¡Bienvenido!</strong></div>
            <div style={{ fontSize: 15 }} className="custom-mt-20">¿Ya tienes cuenta? Ingresa aquí</div>
            <Link to="/login" className="btn btn-link custom-mt-20" style={{ color: "#ffffff", border: "1px solid #ffffff", borderRadius: 15, paddingLeft: 40, paddingRight: 40 }}>
              <small><strong>Inicia sesión</strong></small>
            </Link>
          </div>
        </Col>
        <Col md={7} style={{ background: "#ffffff", /* height: "100vh" */ }}>
          <Row className="padding-30">
            <Col md={6}>
              <Link to="/"><FontAwesomeIcon icon={faChevronCircleLeft} className="text-info" /> <strong className="text-info" style={{ fontSize: 11 }}>REGRESAR AL INICIO</strong></Link>
            </Col>
            <Col md={6} align="right">
              <img alt="..." src={logoColor} height="auto" width="150px" />
            </Col>

            <Col md={12} align="center" className="padding-15" style={{ marginTop: 30 }}>
              Regístrate en <strong className="text-info">TaquillaEnLínea</strong>
              <div style={{ fontSize: 11 }}>Completa todos los campos para continuar</div>
            </Col>

            <Col md={6} className="custom-mt-10">
              <div style={{ fontSize: 14 }}><strong>Identificación</strong></div>
              <InputGroup>
                <Select
                  isSearchable={true} styles={customStylesSelect2}
                  className="basic-multi-select"
                  onChange={changeCi} options={ci}
                  noOptionsMessage={() => { return "Sin Opciones" }}
                  loadingMessage={() => { return <><FontAwesomeIcon icon={faCircleNotch} spin={true} /> Cargando</> }}
                  value={ciSelect}
                />
                { }
                <Input placeholder="Cédula de identidad" type="text" maxLength={ciSelect.value === 'V' ? '8' : '9'} value={ciNum} onChange={(event) => {
                  const newValue = event.target.value;
                  if (!isNaN(newValue)) {
                    setCiNum(newValue);
                  }
                }}
                />
              </InputGroup>
            </Col>

            <Col md={6} className="custom-mt-10">
              <div style={{ fontSize: 14 }}><strong>Fecha de nacimiento</strong> <small className="text-info"><strong>{longAge.text}</strong></small></div>
              <ReactDatetime
                inputProps={{ placeholder: "DD/MM/AAAA" }}
                timeFormat={false}
                locale="es"
                maxLength='8'
                closeOnSelect={true}
                value={bDate} onChange={(e) => {
                  setBDate(e)
                  CalculaEdad(e, (resp) => {
                    setLongAge(resp[0].fechaTit)
                    setAge(resp[0].edad)
                  })

                }}
              />
            </Col>

            <Col lg={6} className="custom-mt-10">
              <div style={{ fontSize: 14 }}><strong>Nombres</strong></div>
              <InputGroup className="input-group-alternative mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-single-02" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Nombres" maxLength='25' type="text" value={name} onChange={(event) => {
                  const newValue = event.target.value;
                  if (/^[a-zA-Z ]*$/.test(newValue)) {
                    setName(newValue);
                  }
                }} />
              </InputGroup>
            </Col>

            <Col lg={6} className="custom-mt-10">
              <div style={{ fontSize: 14 }}><strong>Apellidos</strong></div>
              <InputGroup className="input-group-alternative mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-single-02" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Apellidos" maxLength='25' type="text" value={lastName} onChange={(event) => {
                  const newValue = event.target.value;
                  if (/^[a-zA-Z ]*$/.test(newValue)) {
                    setLastName(newValue);
                  }
                }} />
              </InputGroup>
            </Col>


            <Col md={6} className="custom-mt-10">

              <Row>
                <Col md={4} xs={6}>
                  {/* Primer article */}
                  <article>
                    <div style={{ fontSize: 14 }}><strong>Cod. area</strong></div>
                    <div className="dropdown " ref={dropdownRef}>
                      <button onClick={() => setIsOpen(!isOpen)} >
                        {selectedOption || 'Cod.  '} | <FontAwesomeIcon icon={faChevronDown} />
                      </button>
                      {isOpen && (
                        <ul>
                          {codArea.map((option) => (
                            <li key={option.value} onClick={() => {
                              setPhone("");
                              setSelectedOption(option.label);
                              setIsOpen(false);
                            }} className="list-item d-flex justify-content-between">
                              {option.label}
                              <img alt="..." src={`/assets/img/flags/${option.pais}.svg`} laz height="auto" width="25px" loading="lazy" />
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </article>
                </Col>

                <Col md={8} xs={6}>
                  {/* Segundo article */}
                  <article>
                    <div style={{ fontSize: 14 }}><strong>Teléfono</strong></div>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-mobile-button" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <InputMask className="form-control" placeholder={selectedOption === '+58' ? "(999)-999-9999" : '999999999999'} mask={selectedOption === '+58' ? "(999)-999-9999" : '999999999999'} maskChar=" " type="text" value={phone} onChange={(event) => {
                        const newValue = event.target.value;
                        setPhone(newValue);
                      }}
                      />
                    </InputGroup>
                  </article>
                </Col>
              </Row>

            </Col>

            <Col md={6} className="custom-mt-10">
              <div style={{ fontSize: 14 }}><strong>Pais (Habitación)</strong></div>
              <Select
                isLoading={paisOpt.loading}
                isSearchable={true} styles={customStylesSelect}
                className="basic-multi-select"
                onChange={changePais} options={paises}
                noOptionsMessage={() => { return "Sin Opciones" }}
                loadingMessage={() => { return <><FontAwesomeIcon icon={faCircleNotch} spin={true} /> Cargando paises</> }}
                value={paisSelect}
                placeholder="Pais"
              />
            </Col>
            <Col md={6} className="custom-mt-10">
              <div style={{ fontSize: 14 }}><strong>Estado (Habitación)</strong></div>
              <Select
                isLoading={estadoOpt.loading}
                isSearchable={true} styles={customStylesSelect}
                className="basic-multi-select"
                onChange={changeEstado} options={estados}
                noOptionsMessage={() => { return "Sin Opciones" }}
                loadingMessage={() => { return <><FontAwesomeIcon icon={faCircleNotch} spin={true} /> Cargando estados</> }}
                value={estadoSelect}
                placeholder="Estado"
              />
            </Col>

            <Col md={6} className="custom-mt-10">
              <div style={{ fontSize: 14 }}><strong>Correo electrónico</strong></div>
              <InputGroup className="input-group-alternative mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Correo electrónico" maxLength="40" type="email" value={email} onChange={(event) =>
                  setEmail(event.target.value.toLowerCase())
                } />
              </InputGroup>
            </Col>

            <Col lg={4} className="custom-mt-10">
              <div style={{ fontSize: 14 }}><strong>Contraseña</strong></div>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Contraseña"
                  type={viewPass1.type}
                  autoComplete="off"
                  value={pass1}
                  onChange={(e) => {
                    setPass1(e.target.value);
                    validatePassword(e.target.value);
                    validatePasswordConfirm(e.target.value, pass2);
                  }}
                  onFocus={() => setIsFocused(true)}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText title={viewPass1.title} onClick={verPass1} style={{ cursor: "pointer" }}><FontAwesomeIcon icon={viewPass1.icon} /></InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              <Row className="ml-1">
                {hasUpperCase || hasLowerCase || hasEightChars || hasSymbol ? <div style={{ width: 60, backgroundColor: 'red', height: 7 }} /> : null}
                {(hasEightChars && hasLowerCase) || hasUpperCase || hasSymbol ? <div style={{ width: 60, backgroundColor: 'orange', height: 7 }} /> : null}
                {hasEightChars && hasLowerCase && hasUpperCase || hasSymbol ? <div style={{ width: 60, backgroundColor: 'yellow', height: 7 }} /> : null}
                {hasUpperCase && hasLowerCase && hasEightChars && hasSymbol ? <div style={{ width: 60, backgroundColor: 'green', height: 7 }} /> : null}
              </Row>
              {isFocused && (
                <div className="m-2">
                  <li style={{ color: hasEightChars ? 'green' : 'red', fontSize: '12px' }}>
                    Debe tener al menos 8 caracteres
                  </li>
                  <li style={{ color: hasUpperCase ? 'green' : 'red', fontSize: '12px' }}>
                    Debe tener al menos una letra mayúscula
                  </li>
                  <li style={{ color: hasLowerCase ? 'green' : 'red', fontSize: '12px' }}>
                    Debe tener al menos una letra minúscula
                  </li>
                  <li style={{ color: hasSymbol ? 'green' : 'red', fontSize: '12px' }}>
                    Debe tener al menos un símbolo
                  </li>
                </div>
              )}
            </Col>


            <Col lg={4} className="custom-mt-10">
              <div style={{ fontSize: 14 }}><strong>Confirma contraseña</strong></div>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Repite la Contraseña" type={viewPass2.type} autoComplete="off" value={pass2} onChange={(e) => {
                  setPass2(e.target.value)
                  validatePasswordConfirm(e.target.value, pass1);
                  }} 
                  onFocus={() => setIsFocused2(true)}
                  />
                <InputGroupAddon addonType="append">
                  <InputGroupText title={viewPass2.title} onClick={verPass2} style={{ cursor: "pointer" }}><FontAwesomeIcon icon={viewPass2.icon} /></InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              {isFocused2 && (
                <div className="m-2">
                  <li style={{ color: passConfirm ? 'green' : 'red', fontSize: '12px' }}>
                    {passConfirm ? 'Las contraseñas coinciden' : 'Las contraseñas no coinciden'}
                  </li>
                </div>
              )}
            </Col>

            <Col md={4} className="custom-mt-10" align="right">
              <Button className="mt-4" color="twitter" type="button" onClick={CrearCuenta} style={{ borderRadius: 15 }}>
                <small><i className={btnCreate.icon}></i> {btnCreate.text}</small>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <ErrorsModal isOpen={openModal} close={() => { setOpenModal(false) }} errores={errors} />
      <SuccessModal isOpen={openModalOk} close={() => {
        setOpenModalOk(false)
        setRedirect(true)
      }} content={modalMess} contentTitle={modalOk} />
      <CardsFooter />
    </>
  )
}

export default Register;
