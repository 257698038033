import React, { useState } from "react";

const InputTime = ({ value, onChange }) => {
  // Descomponer la hora y los minutos de 'value'
  const [hours, setHours] = useState(value.split(":")[0]);
  const [minutes, setMinutes] = useState(value.split(":")[1].split(" ")[0]);
  const [period, setPeriod] = useState(value.split(" ")[1]);

  // Ajustar horas para formato de 12 horas
  const displayHours = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;

  const handleHoursChange = (e) => {
    const newHours = e.target.value;
    setHours(newHours);
    onChange(`${newHours}:${minutes} ${period}`);
  };

  const handleMinutesChange = (e) => {
    const newMinutes = e.target.value;
    setMinutes(newMinutes);
    onChange(`${hours}:${newMinutes} ${period}`);
  };

  const togglePeriod = () => {
    const newPeriod = period === "AM" ? "PM" : "AM";
    setPeriod(newPeriod);
    onChange(`${hours}:${minutes} ${newPeriod}`);
  };
  return (
    <div className="input-time-group">
      <select value={displayHours} onChange={handleHoursChange} className="form-control p-1">
        {Array.from({ length: 12 }, (_, i) => i + 1).map((hour) => (
          <option key={hour} value={hour < 10 ? `0${hour}` : hour}>
            {hour < 10 ? `0${hour}` : hour}
          </option>
        ))}
      </select>
      <span className="mt-2">:</span>
      <select value={minutes} onChange={handleMinutesChange} className="form-control p-1">
        {Array.from({ length: 60 }, (_, i) => i).map((minute) => (
          <option key={minute} value={minute < 10 ? `0${minute}` : minute}>
            {minute < 10 ? `0${minute}` : minute}
          </option>
        ))}
      </select>
      <button className="p-1" onClick={togglePeriod}>{period}</button>
    </div>
  );
};

export default InputTime;