import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CardsFooter from "components/Footers/CardsFooter";
import NavbarI from "components/Navbars/NavbarI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faHome,
  faMoneyBill,
  faTicketAlt,
  faCalendarCheck,
  faTimes,
  faTimesCircle,
  faCalendarAlt,
  faChartLine,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import {
  Badge,
  Col,
  Input,
  Row,
  Table,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "reactstrap";
import Sidebar from "views/dashboard/Sidebar";
import { SearchAllTicketSold } from "functions/EventosFunctions";
import { toast } from "react-toastify";
import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  BarController,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels, // Registra aquí el plugin datalabels
  BarController
);

function ResumenVentas() {
  const [data, setData] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [functPreolad, setFunctPreload] = useState({
    icon: faCircleNotch,
    spin: true,
    text: "Cargando, por favor espere...",
  });

  const [type, setType] = useState("active");

  const [isLoading, setIsLoading] = useState(false);

  const [buttonSearch, setButtonSearch] = useState({
    icon: faEye,
    text: " Mostrar cerrados",
    spin: false,
    disabled: false,
  });

  // Buscador
  const [searchTerm, setSearchTerm] = useState("");

  // Estado para el número de página actual
  const [currentPage, setCurrentPage] = useState(1);
  // Número de elementos por página
  const itemsPerPage = 12;

  useEffect(() => {
    setIsLoading(true); // Iniciar la carga
    SearchAllTicketSold(type, (resp) => {
      setIsLoading(false); // Finalizar la carga, independientemente del resultado
      switch (true) {
        case resp.status === "error" || resp.status === "error_log":
          toast.error(
            <small>
              <FontAwesomeIcon icon={faTimesCircle} />{" "}
              <span dangerouslySetInnerHTML={{ __html: resp.mess }}></span>
            </small>
          );
          break;
        case resp.length === 0:
          setFunctPreload({
            icon: faTimes,
            spin: false,
            text: "Buscando resumen de las ventas",
          });
          setData([]);
          break;
        default:
          setData(resp.data);
          break;
      }
    });
  }, [type]);

  // Lógica para filtrar datos según el término de búsqueda
  const filteredData = data.filter(
    (item) =>
      item.evento.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.funcion.includes(searchTerm) // No conviertas la función a minúsculas, ya que es una fecha
    // Agrega más campos según sea necesario
  );
  // Lógica para calcular la porción de datos a mostrar en la página actual
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Lógica para cambiar de página
  const paginate = (pageNumber) => {
    // Verificar si la página solicitada es válida
    if (
      pageNumber > 0 &&
      pageNumber <= Math.ceil(filteredData.length / itemsPerPage)
    ) {
      setCurrentPage(pageNumber);
    }
  };
  const cargando = (
    <td align="center" style={{ padding: 10 }} colSpan={7}>
      <FontAwesomeIcon icon={functPreolad.icon} spin={functPreolad.spin} />{" "}
      <span>{functPreolad.text}</span>
    </td>
  );

  const toggleRow = (key) => {
    if (expandedRow === key) {
      setExpandedRow(null); // Si la fila ya está expandida, la colapsamos.
    } else {
      setExpandedRow(key); // Expandimos la fila seleccionada.
    }
  };

  const handleSearchStatus = () => {
    setButtonSearch({
      icon: faEye,
      text: "Buscando...",
      spin: true,
      disabled: true,
    });
    setTimeout(() => {
      setType(type === "active" ? "inactive" : "active");
      setButtonSearch({
        icon: faEye,
        text: type === "active" ? " Mostrar confirmados" : " Mostrar cerrados",
        spin: false,
        disabled: false,
      });
    }, 1000);
  };

  const table = currentData.map((item, index) => {
    // Extracción de las etiquetas (meses) y los datos para cada dataset
    const labels = item.grafica.map((data) => data.fecha);
    const soldData = item.grafica.map((data) => data.tickets_vendidos);
    // const courtesyData = item.grafica.map((data) => data.tickets_cortesia);
    // const totallyDolar = item.grafica.map((data) => data.total_vendido_dolares);

    const maxValue = item.total_asientos;

    const options = {
      beginAtZero: false,
      maintainAspectRatio: false,
      responsive: true,
      layout: {
        padding: {
          top: 30, // Ajusta este valor según sea necesario
        },
      },
      plugins: {
        legend: {
          position: "top",
          labels: {
            padding: 10, // Aumenta el espacio entre la leyenda y el gráfico
          },
        },
        title: {
          display: true,
          text: ["Ventas diarias", `Total tickets: ${maxValue}`], // Personaliza el título del gráfico,
        },
        // datalabels: {
        //   align: 'end', // Alinea la etiqueta al final, lo que significa la parte superior en un gráfico de barras
        //   anchor: 'end', // Ancla la etiqueta al final de la barra
        //   formatter: (value, context) => {
        //     return value; // Devuelve el valor que quieres mostrar
        //   },
        //   color: '#555', // Puedes personalizar el color del texto
        //   font: {
        //     weight: 'bold' // Personaliza la fuente si es necesario
        //   }
        // }
      },
      scales: {
        y: {
          type: "linear",
          display: true,
          position: "left",
          beginAtZero: true, // Comienza el eje en 0
          ticks: {
            // Usar un callback para ajustar los ticks a valores enteros
            callback: function (value) {
              if (value % 1 === 0) {
                return value;
              }
            },
          },
          title: {
            display: true,
            text: "Tickets vendidos",
          },
        },
        // y1: { // Este es el nuevo eje y en el lado derecho
        //   type: 'linear',
        //   display: true,
        //   position: 'right',
        //   grid: {
        //     drawOnChartArea: false, // Solo dibuja la cuadrícula para el primer eje y
        //   },
        //   title: {
        //     display: true,
        //     text: 'Dólares (USD)'
        //   }
        // },
        x: {
          title: {
            display: true,
            text: "Fechas de venta",
          },
          // ticks: {
          //   autoSkip: true, // Habilita el auto-skip para evitar superposición de etiquetas
          //   maxRotation: 90, // Máximo ángulo de rotación en grados
          //   minRotation: 45, // Mínimo ángulo de rotación en grados
          // }
        },
      },
    };

    const dataGraf = {
      labels,
      datasets: [
        {
          label: "Tickets vendidos",
          data: soldData,
          yAxisID: "y", // Este dataset usará el primer eje y
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          categoryPercentage: 0.5, // Aumenta para menos espacio entre categorías
          barPercentage: 0.5, // Aumenta para barras más anchas y menos espacio entre ellas
        },
        // {
        //   label: "Cortesia",
        //   data: courtesyData,
        //   borderColor: "rgb(255, 99, 132)",
        //   backgroundColor: "rgba(255, 99, 132, 0.5)",
        //   categoryPercentage: 0.5, // Aumenta para menos espacio entre categorías
        //   barPercentage: 0.5, // Aumenta para barras más anchas y menos espacio entre ellas
        // },
        // {
        //   type: 'line',
        //   label: 'Dólares (USD)',
        //   borderColor: 'rgb(255, 99, 132)',
        //   borderWidth: 2,
        //   fill: false,
        //   data: totallyDolar,
        //   yAxisID: 'y1', // Este dataset usará el segundo eje y
        // },
      ],
    };

    return (
      <>
        <tr key={item.evento + item.funcion}>
          <td>
            <Button color="primary" onClick={() => toggleRow(index)}>
              <FontAwesomeIcon icon={faChartLine} />
            </Button>
          </td>
          <td>
            <strong>{item.evento}</strong>
          </td>
          <td>
            <FontAwesomeIcon icon={faCalendarAlt} /> {item.funcion}
          </td>
          <td>
            <h5 className="m-0 text-center">
              <Badge color="info" pill>
                <FontAwesomeIcon icon={faTicketAlt} /> {item.total_vendidos}
              </Badge>
            </h5>
          </td>
          <td>
            <h5 className="m-0 text-center">
              <Badge color="danger" pill>
                <FontAwesomeIcon icon={faTicketAlt} /> {item.total_cortesia}
              </Badge>
            </h5>
          </td>
          <td>
            <h5 className="m-0 text-center">
              <Badge color="success" pill>
                <FontAwesomeIcon icon={faTicketAlt} /> {item.asientos_restantes}
              </Badge>
            </h5>
          </td>
          <td>
            <strong>USD </strong>
            {item.total_vendido_dolar}
          </td>
          <td>
            <strong>
              <FontAwesomeIcon icon={faTicketAlt} /> {item.total_asientos}
            </strong>
          </td>
        </tr>
        {expandedRow === index && (
          <tr>
            <td colSpan="8">
              {/* Aquí puedes insertar los detalles o una tabla interna para la fila expandida */}
              <h5
                className="m-0 mt-3 badge"
                title="Total de tickets"
                style={{ fontSize: "12px" }}
              >
                <strong>Grafica:</strong>
              </h5>
              <span className="badge badge-info" style={{ fontSize: "12px" }}>
                {item.evento} - {item.funcion}
              </span>
              <Col md={12} style={{ height: "60vh" }}>
                <Chart type="bar" data={dataGraf} options={options} />
              </Col>
            </td>
          </tr>
        )}
      </>
    );
  });
  return (
    <>
      <NavbarI />
      <main className="d-flex justify-content-center">
        <section className="section section-shaped section-lg">
          <div
            className="d-block d-sm-block d-md-block"
            align="right"
            style={{ paddingRight: 15 }}
          >
            <small class="bread-crumb-custom">
              <Link to="/dashboard/home">
                <FontAwesomeIcon icon={faHome} />
              </Link>{" "}
              | <FontAwesomeIcon icon={faMoneyBill} /> Resumen ventas
            </small>
          </div>

          <Row className="d-flex">
            <Col md={3} className="d-none d-sm-none d-md-block">
              <Sidebar active="Resumen ventas" />
            </Col>
            <Col md={9} className="custom-p-32 ">
              <Card>
                <CardHeader>
                  <h4>Resumen de ventas</h4>
                  {/* Buscador */}
                  <div className="d-flex justify-content-between mx-2">
                    <Input
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Buscar..."
                      className="w-25"
                    />

                    <Button
                      disabled={buttonSearch.disabled}
                      onClick={handleSearchStatus}
                    >
                      <FontAwesomeIcon
                        icon={buttonSearch.icon}
                        spin={buttonSearch.spin}
                      />
                      {buttonSearch.text}
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  {isLoading ? (
                    <div className="text-center">{cargando}</div>
                  ) : (
                    <>
                      <h5>
                        <Badge
                          color={type === "active" ? "success" : "danger"}
                          pill
                        >
                          <FontAwesomeIcon icon={faCalendarCheck} />{" "}
                          {type === "active"
                            ? "Eventos confirmados"
                            : "Eventos cerrados"}
                        </Badge>{" "}
                      </h5>
                      <Table responsive="md" style={{ fontSize: " 14px" }}>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Evento</th>
                            <th>Función</th>
                            <th>Tickets vendidos</th>
                            <th>Tickets cortesía</th>
                            <th>Tickets no vendidos</th>
                            <th>Total neto USD</th>
                            <th>Total tickets</th>
                          </tr>
                        </thead>
                        <tbody>
                          {table}
                          {/* {filteredData.length === 0 ? (
                            <tr>
                              <td colSpan="7" align="center">
                                {cargando}
                              </td>
                            </tr>
                          ) : (
                            table
                          )} */}
                        </tbody>
                      </Table>
                    </>
                  )}
                </CardBody>

                <CardFooter className="py-0">
                  <div className="align-content-center">
                    {/* Componente de paginación */}
                    <nav aria-label="Page navigation example">
                      <ul className="pagination">
                        <li className="page-item">
                          <button
                            className="page-link"
                            aria-label="Previous"
                            onClick={() => paginate(currentPage - 1)}
                          >
                            <span aria-hidden="true">&laquo;</span>
                          </button>
                        </li>
                        {/* Lógica para generar dinámicamente los números de página */}
                        {Array.from(
                          {
                            length: Math.ceil(
                              filteredData.length / itemsPerPage
                            ),
                          },
                          (_, index) => (
                            <li
                              key={index}
                              className={`page-item ${index + 1 === currentPage ? "active" : ""
                                }`}
                            >
                              <button
                                className="page-link"
                                onClick={() => paginate(index + 1)}
                              >
                                {index + 1}
                              </button>
                            </li>
                          )
                        )}
                        <li className="page-item">
                          <button
                            className="page-link"
                            aria-label="Next"
                            onClick={() => paginate(currentPage + 1)}
                          >
                            <span aria-hidden="true">&raquo;</span>
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </section>
      </main>
      <CardsFooter />
    </>
  );
}

export default ResumenVentas;
