import React, {useEffect, useState} from "react";
import { Table, Badge } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle, faCircleNotch, faTimes, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import { SearchFunctions } from "functions/EventosFunctions";

import { UrlImg } from "../../functions/Url";

const EventoIndFunciones = (props) => {
    const [funciones, setFunciones]     = useState([]);
    const [funPreload, setFunPreload]   = useState({icon: faCircleNotch, spin: true, text: "Buscando las funciones del evento"});

    useEffect(() => {
        SearchFunctions (props.id, (resp) => {
            (resp.length === 0) ? setFunPreload({icon: faTimes, spin: false, text: "Este evento no tiene funciones registradas"}) : setFunciones(resp.data);
        })
    }, [props]);

    let content = () => {
        if (funciones?.length === 0) return  (
            <tr>
                <td align = "center" style = {{padding: 10}} colSpan = {4}><FontAwesomeIcon icon = {funPreload.icon} spin = {funPreload.spin}/> <span>{funPreload.text}</span></td>
            </tr>
        )

        let statusD = (status) => {
            switch (status) {
                case 1:
                    return (<h6><Badge color = "success"><FontAwesomeIcon icon = {faCheckCircle}/> Activa</Badge></h6>)
                case 0:
                    return (<h6><Badge color = "danger"><FontAwesomeIcon icon = {faTimesCircle}/> Inactiva</Badge></h6>)
                default: return null;
            }
        }

        let imgMala = "http://localhost:8000/images/events/eventodefault_img.jpg"
        let ruta = UrlImg+'events/';
        let defaultImg = "http://localhost:8000/images/events/default_img.jpg"


        return (
            <>
                {funciones && funciones.length > 0 ? (
                funciones.map(option => (
                    <tr key={option.id}>
                    <td style={{width: "50vh"}}>
                        <img
                        src={(ruta + option.evento_info.thumbnail === imgMala) ? defaultImg : ruta + option.evento_info.thumbnail}
                        alt={option.evento_info.url_event}
                        className="img-fluid w-25 p-0"
                        />
                        <p className="text-primary"><strong>{option.evento_info.name_event}</strong></p>
                    </td>
                    <td><p>{option.fecha}</p></td>
                    <td><p>{option.hora_in}</p></td>
                    <td><p>{option.hora_out}</p></td>
                    <td>
                        <div className="text-primary">
                        <h3 className={(option.status === 1) ? "text-primary" : "text-danger ml-5"} style={{margin: "auto", marginLeft: 40}}><FontAwesomeIcon icon={faTicketAlt} /></h3>
                        <h5 className={(option.status === 1) ? "text-primary" : "text-danger"} style={{marginLeft: 25}}>{(option.status === 1) ? "Activo" : "Inactivo"}</h5>
                        </div>
                    </td>
                    </tr>
                ))
                ) : (
                <tr>
                    <td colSpan="5" className="text-center">No hay funciones disponibles</td>
                </tr>
                )}
            </>
            );
    }

    return (
        <div style = {{maxHeight: "30vh", overflow: "auto"}}>
        <Table className = "mb-0 table-custom-primary" striped   style = {{boxShadow: "2px 1px 2px #000", fontSize: 10 }}>
            <tbody>
                <tr className="mb-0">
                    <th align = "center" className="mb--1"><h4>Funciones</h4></th>
                    <th align = "center" className="mb--1"><h6>Fecha de la función</h6></th>
                    <th align = "center" className="mb--1"><h6>Desde</h6></th>
                    <th align = "center" className="mb--1"><h6>Hasta</h6></th>
                    <th align = "center" className="mb--1"><h6>Status</h6></th>
                </tr>
                {content()}
            </tbody>
        </Table>
    </div>
)
}

export default EventoIndFunciones;