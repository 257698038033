import React, {useEffect, useState} from "react";
import { Badge, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle, faCheck, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import ContentModal from "components/Modals/ContentModal";
import SuccessModal from "components/Modals/SuccessModal";
import ConfirmModal from "components/Modals/ConfirmModal";
import AddSitio from "./AddSitio";

import { EditStatusSite, DelSite } from "functions/SitiosFunction";
import AddAuditorio from "./AddAuditorio";

const SitiosInd = (props) => {
    const [dropdownOpen, setDropdownOpen]   = useState(false)
    const [openModalContent, setOpenModalContent]   = useState(false);
    const [openModalOk, setOpenModalOk]     = useState(false)
    const [modalOkTitle, setModalOkTitle]   = useState("")
    const [modalMess, setMmodalMess]        = useState("")
    const [openModalCon, setOpenModalCon]   = useState(false)

    const [nombre, setNombre]   = useState("")
    const [address, setAddress] = useState("")
    const [min, setMin]         = useState("")
    const [max, setMax]         = useState("")
    const [status, setStatus]   = useState("")

    const [botonYes, setBotonYes]   = useState({ icon: faCheck, text: "Si", spin: false, disabled: false })

    const [openModalAudit, setOpenModalAudit]       = useState(false);


    useEffect(() => {
        setNombre(props.data.name)
        setAddress(props.data.address)
        setMin(props.data.min_cap)
        setMax(props.data.max_cap)
        setStatus(props.data.status)
    }, [props]);

    let statusD = (status) => {
        switch (status) {
            case "wait":
                return (<h6><Badge color = "light"><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Espera</Badge></h6>)
            case "1":
                return (<h6><Badge color = "success"><FontAwesomeIcon icon = {faCheckCircle}/> Activo</Badge></h6>)
            case "0":
                return (<h6><Badge color = "danger"><FontAwesomeIcon icon = {faTimesCircle}/> Inactivo</Badge></h6>)
            default: return null;
        }
    }

    
    let activate = (status) => {
        switch (status) {
            case "wait":    return "Cambiando espera"
            case "1":       return "Inactivar recinto"
            case "0":       return "Activar recinto"
            default:        return null;
        }
    }

    let del = (id) => {
        setBotonYes({ icon: faCircleNotch, text: "Espera", spin: true, disabled: true })
        DelSite(id, (resp) => {
            setBotonYes({ icon: faCheck, text: "Si", spin: false, disabled: false })
            setOpenModalCon(false)
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                break;
                default:
                    props.addOk(true, "Recinto eliminado", resp.mess, resp.data)
                break;
            }
        })
    }

    /* https://ticketplate.com/
    https://www.puntoticket.com/ 
    https://www.ticketmundo.com/
    */

    let changeStatus = () => {
        if (status === "wait") return null;
        setStatus("wait")
        EditStatusSite(props.data.id, (resp) => {
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                break;
                default:
                    setStatus(resp.data)
                break;
            }
        })
    }

    let modalEditContent = () => {
        let modOk = (open, title, mess, data) => {
            setOpenModalOk(open)
            setModalOkTitle(title)
            setMmodalMess(mess)
            setNombre(data.nombre)
            setAddress(data.direccion)
            setMin(data.min)
            setMax(data.max)
        }

        return (
            <ContentModal
                close = {() => setOpenModalContent(false)}
                isOpen = {openModalContent}
                title = {`Editar recinto ${nombre}`}
                size = "lg"
                content = {<AddSitio cerrar = {() => {setOpenModalContent(false)}} data = {props.data} modOk = {(open, title, mess, data) => {modOk(open, title, mess, data)}} botones = "edit"/>}
            />
        )
    }

    let modalAuditorio = () => {
        return (
            <ContentModal
                close = {() => setOpenModalAudit(false)}
                isOpen = {openModalAudit}
                title = {`Editar distribución de ${nombre}`}
                size = "xl"
                content = {<AddAuditorio cerrar = {() => {setOpenModalAudit(false)}} idSite = {props.data.id}/>}
            />
        )
    }

    let modalConfirm = () => {
        if (openModalCon === false) return null;

        return (
            <ConfirmModal
                isOpen = {openModalCon}
                content = {<div>¿Deseas eliminar este recinto?</div>}
                disabled = {botonYes.disabled}
                buttonYes = {<><FontAwesomeIcon icon = {botonYes.icon} spin = {botonYes.spin}/> {botonYes.text}</>}
                close = {() => setOpenModalCon(false)}
                funcion = {() => del(props.data.id)}
            />
        )
    }
    
    return(
        <>
            <tr>
                <td><strong><span className = "text-primary">{nombre}</span></strong></td>
                <td>{address}</td>
                <td>{min}</td>
                <td>{max}</td>
                <td align = "center">{statusD(status)}</td>
                <td align = "center">
                    <ButtonDropdown isOpen={dropdownOpen} toggle = {() => { setDropdownOpen(!dropdownOpen) }}>
                        <DropdownToggle caret className = "btn-outline-info" size = "sm">
                            <small>Acciones</small>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem style = {{padding: 5}} onClick = {() => setOpenModalContent(true)}><small>Editar este recinto</small></DropdownItem>
                            {/* <DropdownItem style = {{padding: 5}} onClick = {() => setOpenModalAudit(true)}><small>Ver/Editar la distribución</small></DropdownItem> */}
                            <DropdownItem style = {{padding: 5}} onClick = {changeStatus}><small>{activate(status)}</small></DropdownItem>
                            <DropdownItem style = {{padding: 5}} onClick = {() => setOpenModalCon(true)}><small>Eliminar este recinto</small></DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>
                </td>
            </tr>
            {modalEditContent()}
            {modalAuditorio()}
            {modalConfirm()}
            <SuccessModal isOpen = {openModalOk} close = {() => {setOpenModalOk(false)}} content = {modalMess} contentTitle = {modalOkTitle}/>
        </>
    )
}

export default SitiosInd;