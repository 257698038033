import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  NavLink,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignInAlt,
  faCircleNotch,
  faTimesCircle,
  faUserPlus,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

import { LoginFunction } from "../../functions/UserFunctions";

import logo from "../../assets/img/logos/logo_1.png";
import { toast } from "react-toastify";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const LoginModal = (props) => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [botonLog, setBotonLog] = useState({
    icon: faSignInAlt,
    text: "Iniciar Sesión",
    spin: false,
    disabled: false,
  });
  const [botonReg, setBotonReg] = useState({
    icon: faUserPlus,
    text: "Registrarse",
    spin: false,
    disabled: false,
  });
  const [login, setLogin] = useState(false);
  const [goTo, setGoTo] = useState("");
  const [viewPass, setViewPass] = useState({
    icon: faEye,
    title: "Mostrar Contraseña",
    type: "password",
    active: true,
  });
  const { executeRecaptcha } = useGoogleReCaptcha();

  let enterTab = (next) => (e) => {
    if (e.which === 13) document.getElementById(next).focus();
  };

  let signIn = async (e) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha("homepage");

    if (botonLog.disabled === true) return null;
    let expReg =
      /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i;

    switch (true) {
      case email.trim() === "":
        toast.error(
          <div>
            <FontAwesomeIcon icon={faTimesCircle} /> Debes indicar el{" "}
            <strong>Email</strong>
          </div>
        );
        break;
      case expReg.test(email) === false && email.trim() !== "":
        toast.error(
          <div>
            <FontAwesomeIcon icon={faTimesCircle} /> El campo{" "}
            <strong>Email</strong> tiene un formato incorrecto
          </div>
        );
        break;
      case pass.trim() === "":
        toast.error(
          <div>
            <FontAwesomeIcon icon={faTimesCircle} /> Debes indicar la{" "}
            <strong>Contraseña</strong>
          </div>
        );
        break;
      default:
        setBotonLog({
          icon: faCircleNotch,
          text: "Espera",
          spin: true,
          disabled: true,
        });
        LoginFunction(email, pass, token, (resp) => {
          switch (resp.status) {
            case "warning":
              toast.error(
                <div>
                  <FontAwesomeIcon icon={faTimesCircle} />{" "}
                  <span dangerouslySetInnerHTML={{ __html: resp.mess }}></span>
                </div>
              );
              setBotonLog({
                icon: faSignInAlt,
                text: "Iniciar Sesión",
                spin: false,
                disabled: false,
              });
              break;
            case "errorRecaptcha":
              toast.error(
                <div>
                  <FontAwesomeIcon icon={faTimesCircle} />{" "}
                  <span dangerouslySetInnerHTML={{ __html: resp.mess }}></span>
                </div>
              );
              setBotonLog({
                icon: faSignInAlt,
                text: "Iniciar sesión",
                spin: false,
                disabled: false,
              });
              break;
            default:
              sessionStorage.removeItem("ContentData");
              let ContentData = {
                U_ID: resp.U_ID,
                E_ID: resp.E_ID,
                U_NAME: resp.name,
                U_TYPE: resp.u_t,
                UT_NAME: resp.t_name,
              };
              sessionStorage.setItem(
                "ContentData",
                JSON.stringify(ContentData)
              );
              setGoTo(props.goTo);

              if (login === false) setLogin(true);
              break;
          }
        });
        break;
    }
  };

  let register = () => {
    props.openRegistro();
    props.close();
  };

  if (login === true) return <Redirect to={goTo} />;

  let verPass = () => {
    viewPass.active === true
      ? setViewPass({
          icon: faEyeSlash,
          title: "Ocultar contraseña",
          type: "text",
          active: false,
        })
      : setViewPass({
          icon: faEye,
          title: "Mostrar contraseña",
          type: "password",
          active: true,
        });
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lfo2G0oAAAAAIpjrpDVrEXdN2qMHjUhzE0wSTFM">
      <Row>
        <Col md={12}>
          <Form role="form">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Correo electrónico"
                  type="email"
                  maxLength="40"
                  value={email}
                  onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  onKeyPress={enterTab("pass")}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  id="pass"
                  placeholder="Contraseña"
                  type={viewPass.type}
                  autoComplete="off"
                  value={pass}
                  onChange={(e) => setPass(e.target.value)}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText
                    title={viewPass.title}
                    onClick={verPass}
                    style={{ cursor: "pointer" }}
                  >
                    <FontAwesomeIcon icon={viewPass.icon} />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Form>
        </Col>
        <Col md={4} align="right" style={{ paddingTop: 20 }}>
          <img src={logo} alt="logo" className="img-fluid" />
        </Col>
        <Col md={8} align="right">
          <Button
            className="my-4"
            color="warning"
            type="button"
            onClick={signIn}
            disabled={botonLog.disabled}
          >
            <small>
              <FontAwesomeIcon spin={botonLog.spin} icon={botonLog.icon} />{" "}
              {botonLog.text}
            </small>
          </Button>
        </Col>
        <Col md={12} align="center"> 
          <hr className="m-3"/>
          <p>
            <small>
              ¿No tienes una cuenta?{" "}
            </small>
            <strong>
            <NavLink
              className="mb-2"
              onClick={register}
              disabled={botonReg.disabled}
              style={{ cursor: botonReg.disabled ? "not-allowed" : "pointer" }}
            >
              <FontAwesomeIcon spin={botonReg.spin} icon={botonReg.icon} />{" "}
              {botonReg.text}
            </NavLink>
            </strong>
          </p>
        </Col>

      </Row>
    </GoogleReCaptchaProvider>
  );
};

export default LoginModal;
