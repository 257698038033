import React, {useState} from "react";
import { Input, Badge, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faSquare, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import ContentModal2 from "components/Modals/ContentModal2";
import Butacas from "./Butacas";

const ZonaInd = (props) => {
    const [precio, setPrecio]       = useState(props.data.valor);
    const [disabled, setDisabled]   = useState(Boolean(props.data.status));
    const [dropdownOpen, setDropdownOpen]   = useState(false)
    const [openModal, setOpenModal] = useState(false)

    let statusD = () => {
        switch (disabled) {
            case true:
                return (<h6><Badge color = "success"><FontAwesomeIcon icon = {faCheckCircle}/> Activo</Badge></h6>)
            case false:
                return (<h6><Badge color = "danger"><FontAwesomeIcon icon = {faTimesCircle}/> Inactivo</Badge></h6>)
            default: return null;
        }
    }

    let activate = () => {
        switch (disabled) {
            case true:
            return "Inactivar zona"
            case false:
            return "Activar zona"
            default: return null;
        }
    }

    let changeStatus = () => {
        let valor = !disabled;
        (valor === true) ? valor = 1 : valor = 0;
        props.updZona("status", props.data.id, valor)
        setDisabled(!disabled)
    }

    let changeValor = (valor) => {
        props.updZona("valor", props.data.id, valor)
        setPrecio(valor)
    }

    let modalZona = () => {
        if (openModal === false) return null;

        return (
            <ContentModal2
                isOpen = {openModal}
                title = {`Butacas de la zona - (${props.data.nombre})`}
                size = "lg"
                content = {<Butacas zonaId = {props.data.idZona} sitioId = {props.sitioId}/>}
                close = {() => {setOpenModal(false)}}
            />
        )
    }

    return (
        <>
            <tr>
                <td style = {{fontSize: 12}}><FontAwesomeIcon icon = {faSquare} style = {{color: props.data.color}}/> {props.data.nombre}</td>
                <td>
                    <Input type = "number" value = {precio} onChange = {(e) => changeValor(e.target.value)}/>
                </td>
                <td align = "center">{statusD()}</td>
                <td align = "center">
                    <ButtonDropdown isOpen={dropdownOpen} toggle = {() => { setDropdownOpen(!dropdownOpen) }}>
                        <DropdownToggle caret color = "secondary" size = "sm">
                            <small><FontAwesomeIcon icon = {faCogs}/> Acciones</small>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem header><div style = {{color:props.data.color}} title = {props.data.nombre} align = "center">{props.data.nombre}</div></DropdownItem>
                            <DropdownItem style = {{padding: 5}} onClick = {() => {setOpenModal(true)}}><small>Ver Butacas</small></DropdownItem>
                            <DropdownItem style = {{padding: 5}} onClick = {changeStatus}><small>{activate()}</small></DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>
                </td>
            </tr>
            {modalZona()}
        </>
    )
}

export default ZonaInd;