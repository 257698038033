import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Select from 'react-select';


import { Row, Col, Button } from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faHome, faPlus, faTrash, faUsersCog, faCircleNotch, faTimes, faTimesCircle, faCheck } from '@fortawesome/free-solid-svg-icons';

import NavbarI from "components/Navbars/NavbarI.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import Sidebar from '../dashboard/Sidebar';

import { showUsuario, ShowRoles } from "functions/RolesFunction";
import { useState } from "react";
import { useEffect } from "react";
import { Table } from "react-bootstrap";
import EventoModal from "components/Modals/EventoModal";
import Card from 'react-bootstrap/Card';
import { toast } from "react-toastify";
import { ChangeRole } from "functions/RolesFunction";
import { changeStatus } from "functions/RolesFunction";
import { savePermission } from "functions/RolesFunction";

const BotonWapo = ({user}) => {
  const [statu, setStatu] = useState(user.status)
  const [color, setColor] = useState(((statu == "1") ? 'btn btn-success' : 'btn btn-danger'))
  const [text, setText] = useState((statu == "1") ? 'Habilitado' : 'deshabilitado')

  const habilitar_deshabilitar = () => {
    const Content = {
      userId: user.id
    };
    changeStatus(Content, (resp)=>{
      if (resp.status === 'ok') {
        setStatu(resp.code)
        setColor((color == "btn btn-success") ? 'btn btn-danger' : 'btn btn-success')

        setText(resp.estado)
        toast.success(<small><FontAwesomeIcon icon = {faCheck}/> {resp.msg}</small>)

      }
    });
  }

  return (
    <button type="button" className={color + ' w-75'} onClick={habilitar_deshabilitar}>{text}</button>
  )
}


const SelectRole = (props) => {
  const [data, setData] = useState([]);
  const [functPreolad, setFunctPreload]   = useState({icon: faCircleNotch, spin: true, text: "Cargando, por favor espere..."});
  const cargando = <td align = "center" style = {{padding: 10}} colSpan = {7}><FontAwesomeIcon icon = {functPreolad.icon} spin = {functPreolad.spin}/> <span>{functPreolad.text}</span></td>
  const [roleSeleccionado, setRoleSeleccionado] = useState("");

  useEffect(()=>{
    ShowRoles((resp) =>{
      switch(true){
        case (resp.status === "error" || resp.status === "error_log"):
            toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
        break;
        case (resp.length === 0):
            setFunctPreload({icon: faTimes, spin: false, text: "No existen Usuarios Registrados"});
            setData([])
        break;
        default:
          setData(resp)
        break;
    }
    })
}, [props]);

const handleRoleSeleccionado = (rol) => {
  setRoleSeleccionado(rol);
};

const opcRoles = useMemo(() =>
  data.map(rol => ({ value: rol.id, label: rol.t_name }))
, [data]);



return (
  <>
  <Select
  value={roleSeleccionado}
  onChange={handleRoleSeleccionado}
  options={opcRoles}
  placeholder="Seleccionar rol para asignar"
  />

  <Button variant="primary" className="mt-3" onClick={() => SaveRole(props, roleSeleccionado)}>Asignar Rol</Button>

  </>

)
}

const SaveRole = (props, roleSeleccionado) => {
  const userSelect = props.props;
  const roleSelect = roleSeleccionado.value

  const Content = {
    user : userSelect,
    role : roleSelect
  }

  ChangeRole(Content, (resp)=>{
     switch(true){
        case (resp.status === "error" || resp.status === "error_log"):
            toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
        break;
        case (resp.length === 0):
            console.log('cargando...');
        break;
        default:
          toast.success(<small><FontAwesomeIcon icon = {faCheck}/> {resp.msg}</small>)
        break;
    }
  })

}

const SavePermission = (props, add, del, upd) => {
  const userSelect = props.id;

  let content = {
    user: userSelect,
    add: add === true ? 1 : 0,
    del: del === true ? 1 : 0,
    upd: upd === true ? 1 : 0
  }

  savePermission(content, (resp) => {
    switch(true){
      case (resp.status === "error" || resp.status === "error_log"):
          toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
      break;
      case (resp.length === 0):
          console.log('cargando...');
      break;
      default:
        toast.success(<small><FontAwesomeIcon icon = {faCheck}/> {resp.msg}</small>)
      break;
  }
  });
}


const EstablecerPermiso = ({props}) => {
  const permisos = props.user_rol_option;
  const [add, setAdd] = useState(permisos.length !== 0 ? permisos[0].add === 1 : false);
  const [del, setDel] = useState(permisos.length !== 0 ? permisos[0].del === 1 : false);
  const [upd, setUpd] = useState(permisos.length !== 0 ? permisos[0].upd === 1 : false);

  // let addbd = permisos.add;
  // let delbd = permisos.del;
  // let updbd = permisos.upd;

    // setAdd((addbd === 1) ? true : false);
    // setDel((delbd === 1) ? true : false);
    // setUpd((updbd === 1) ? true : false);

  if (permisos === 0) {
    return (
      <div>
        <p>
          No se le puede asignar permisos a un cliente
        </p>
      </div>
    )
  }
  
  if(permisos.length !== 0){
    const addchange = (event) => {
      setAdd(event.target.checked);
    };
  
    const delChange = (event) => {
      setDel(event.target.checked);
    }
  
    const updChange = (event) => {
      setUpd(event.target.checked);
    }


  
    return (
<div>
  <fieldset className="checkbox-group">
	<legend className="checkbox-group-legend">Choose your favorites</legend>
	<div className="checkbox">
		<label className="checkbox-wrapper">
			<input type="checkbox" className="checkbox-input" checked={add} onChange={addchange}/>
			<span className="checkbox-tile">
				<span className="checkbox-icon">
          <h2><FontAwesomeIcon icon={faPlus} className="mx-2" /></h2>
				</span>
				<span className="checkbox-label">Add</span>
			</span>
		</label>
	</div>

  <div className="checkbox">
		<label className="checkbox-wrapper">
			<input type="checkbox" className="checkbox-input" checked={del} onChange={delChange}/>
			<span className="checkbox-tile">
				<span className="checkbox-icon">
          <h2>          <FontAwesomeIcon icon={faTrash} className="mx-2" />
</h2>
				</span>
				<span className="checkbox-label">Del</span>
			</span>
		</label>
	</div>

  <div className="checkbox">
		<label className="checkbox-wrapper">
			<input type="checkbox" className="checkbox-input" checked={upd} onChange={updChange}/>
			<span className="checkbox-tile">
				<span className="checkbox-icon">
          <h2><FontAwesomeIcon icon={faEdit} className="mx-2" /></h2> 
				</span>
				<span className="checkbox-label">Upd</span>
			</span>
		</label>
	</div>
</fieldset>

<Button variant="primary" className="mt-3" onClick={() => SavePermission(props, add, del, upd)}>Asignar Rol</Button>

</div>
    );
  }else {
    return (
      <div>
        <p>
          No se le puede asignar permisos a un cliente
        </p>
      </div>
    )
  }



}


const CardUser = ({user}) => {
  const [openModal, setOpenModal] = useState(false);
  const [rol, setRol] = useState(user.user_type.t_name);
  


    const handleUserSelection = () => {
    setOpenModal(true)
    setRol(user.user_type.t_name)
  };

  let content = (
    <>
    <Card className="text-center">
      <Card.Body>
        <Card.Title>{user.email} ({rol})</Card.Title>
        <Card.Text>
          <h4>Roles</h4>
          <SelectRole props = {user.id} />
        </Card.Text>
        
      </Card.Body>
      <Card.Footer className="text-muted">
        <h4>Permisos</h4>
        <EstablecerPermiso props = {user} />
        </Card.Footer>
    </Card>
    </>
  )

  return (
    <>
    <Button className="w-75" onClick = {()=>handleUserSelection(user)} >
      {rol}
    </Button>
    <EventoModal size = "lg" isOpen = {openModal} titulo = "Establecer Rol y Permisos" contenido = {content} close = {() => {setOpenModal(false)}}/>
    </>
  )
}


const MainRoles = (props) => {
  // Constantes
    const [data, setData] = useState([]);
    const [functPreolad, setFunctPreload]   = useState({icon: faCircleNotch, spin: true, text: "Buscando las funciones registradas"});
    const cargando = <td align = "center" style = {{padding: 10}} colSpan = {7}><FontAwesomeIcon icon = {functPreolad.icon} spin = {functPreolad.spin}/> <span>Buscando Usuarios Registrados</span></td>
    const [maxPage, setMaxPage] = useState()
    const [page, setPage] = useState(1)

    const Paginate = (page) => {
      let array = [];
      for (let i = 1; i <= page; i++) {
        array.push(i);
      }

      const click =(item) =>{
        setPage(item);
        showUsuario(item, (resp) =>{
          switch(true){
            case (resp.status === "error" || resp.status === "error_log"):
                toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
            break;
            case (resp.length === 0):
                setFunctPreload({icon: faTimes, spin: false, text: "No existen Usuarios Registrados"});
                setData([])
            break;
            default:
              setData(resp.data)
              setMaxPage(resp.last_page)
            break;
        }
        })
      }

      return (
        array.map(item=>(
          (maxPage >= 2) ? <button onClick={()=>click(item,data)} className="btn btn-primary mt-3">{item}</button> : ""
        ))
      )
    }



    useEffect(()=>{
        showUsuario(page, (resp) =>{
          switch(true){
            case (resp.status === "error" || resp.status === "error_log"):
                toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
            break;
            case (resp.length === 0):
                setFunctPreload({icon: faTimes, spin: false, text: "No existen Usuarios Registrados"});
                setData([])
            break;
            case(resp.current_page !== 1):
              setData(resp.data)
            break;
            default:
              setData(resp.data)
              setMaxPage(resp.last_page)
            break;
        }
        })
    }, [props]);

      const table = (
        data.map((user) => (
          
          <tr key={user.id}>
            <td>{user.id}</td>
            <td>{user.email}</td>
            <td>
              <CardUser user = {user}/>
              <div className="d-flex flex-row mt-2 ml-5">
                {(user.user_rol_option[0]) !== undefined ? 
                (user.user_rol_option[0].add) === 1 ? <FontAwesomeIcon icon={faPlus} className="mx-2" /> : ("")
                : ("")}
                {(user.user_rol_option[0]) !== undefined ? 
                (user.user_rol_option[0].del) === 1 ? <FontAwesomeIcon icon={faTrash} className="mx-2" /> : ("")
                : ("")}
                {(user.user_rol_option[0]) !== undefined ? 
                (user.user_rol_option[0].upd) === 1 ? <FontAwesomeIcon icon={faEdit} className="mx-2" /> : ("")
                : ("")}
              </div>
            </td>
            {/* <td>{user.status === "1" ? <BotonWapo className = {"btn btn-success w-75"} texto = {"Habilitado"} /> : <BotonWapo className = {"btn btn-danger w-75"} texto = {"Deshabilitado"} />}</td> */}
            <td>
                <BotonWapo user ={user}/>
            </td>
          </tr>
        ))
      )


    return(
        <>
        <NavbarI />
            <main className="d-flex justify-content-center">
                <section className="section section-shaped section-lg">
                    <div className = "d-block d-sm-block d-md-block" align = "right" style = {{paddingRight: 15}}>
                        <small class = "bread-crumb-custom"><Link to = "/dashboard/home"><FontAwesomeIcon icon = {faHome}/></Link> | <FontAwesomeIcon icon = {faUsersCog}/> Establecer Roles</small>
                    </div>
                    <Row className="d-flex">
                        <Col md = {3} className = "d-none d-sm-none d-md-block">
                            <Sidebar active = "Establecer Roles"/>
                        </Col>
                        <Col md = {9} className = "custom-p-32 ">
                        <h5>Administrar Usuarios</h5>
                          <Table responsive="md">
                              <thead>
                                <tr>
                                  <th>ID</th>
                                  <th>Email</th>
                                  <th>Rol Actual</th>
                                  <th>Status de Usuario</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(data.length === 0) ? cargando : table }
                              </tbody>

                          </Table>
                          
                          <div className="align-content-center">
                            {Paginate(maxPage, data)}
                          </div>
                        </Col>
                    </Row>
                </section>
            </main>
            <CardsFooter />
        </>
    )
}



export default MainRoles;