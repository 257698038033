import React, { useState } from "react";

import { FacebookShareButton, 
    TwitterShareButton
     } from "react-share";

     import twitter from "assets/img/logos/twitter.png";

import { FacebookIcon, } from "react-share";

const Compartir = (props) => {
    const [padding, setPadding] = useState(2)
    const [size, setSize] = useState(props.size)

    return(
        <>
            {/* <small className = "custom-mt-10"><strong>Comparte este evento en tus redes sociales</strong></small> */}
            <div>
                <FacebookShareButton url = {props.url} title = "Compartir en Facebook" style = {{padding: padding}}>
                    <FacebookIcon size = {size} round = {true} bgStyle = {{color: "#424242"}}/>
                </FacebookShareButton>
                {/* <FacebookMessengerShareButton  url = {props.url} title = "Compartir en Linkedin" style = {{padding: padding}}>
                    <FacebookMessengerIcon size = {size} round = {true}/>
                </FacebookMessengerShareButton> */}
                {/* <EmailShareButton url = {props.url} title = "Enviar por Email" style = {{padding: padding}}>
                    <EmailIcon size = {size} round = {true}/>
                </EmailShareButton> */}
                <TwitterShareButton url = {props.url} title = "Compartir en Twitter" style = {{padding: padding}}>
                <img src={twitter} height={size + 12 / 2 }  alt="twitter" />
                </TwitterShareButton>
                {/* <PinterestShareButton url = {props.url} media = {props.url} title = "Compartir en Pinterest" style = {{padding: padding}}>
                    <PinterestIcon size = {size} round = {true}/>
                </PinterestShareButton> */}
                {/* <TelegramShareButton url = {props.url} title = "Compartir en Telegram" style = {{padding: padding}}>
                    <TelegramIcon size = {size} round = {true}/>
                </TelegramShareButton> */}
                {/* <WhatsappShareButton url = {props.url} title = "Compartir en Whatsapp" style = {{padding: padding}}>
                    <WhatsappIcon size = {size} round = {true}/>
                </WhatsappShareButton> */}
                {/* <LinkedinShareButton url = {props.url} title = "Compartir en Linkedin" style = {{padding: padding}}>
                    <LinkedinIcon size = {size} round = {true}/>
                </LinkedinShareButton> */}
                {/* <ViberShareButton url = {props.url} title = "Compartir en Viber" style = {{padding: padding}}>
                    <ViberIcon size = {size} round = {true}/>
                </ViberShareButton> */}
            </div>
        </>
    )
}

export default Compartir;