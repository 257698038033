import React, {useEffect, useState} from "react";
import { toast } from 'react-toastify';

const MesaDist = (props) => {
    const [sillasDisabled, setSillasDisabled]   = useState([]);
    const [control, setControl]     = useState(false);

    const [customStylesDis, setCustomtylesDis] = useState({
        backgroundColor: props.stylesD.backgroundColor,
        border: props.stylesD.border,
        borderRadius: props.stylesD.borderRadius
    })

    const [colorButSel, setColorButSel] = useState({
        backgroundColor: props.colorButSel.backgroundColor,
        border: props.colorButSel.border,
        borderRadius: props.colorButSel.borderRadius
    })

    const [colorButSold, setColorButSold] = useState({
        backgroundColor: props.colorButSold.backgroundColor,
        border: props.colorButSold.border,
        borderRadius: props.colorButSold.borderRadius,
        cursor: props.colorButSold.cursor
    })

    const [colorButTran, setColorButTran] = useState({
        backgroundColor: props.colorButTran.backgroundColor,
        border: props.colorButTran.border,
        borderRadius: props.colorButTran.borderRadius,
        cursor: props.colorButSold.cursor
    })

    const [nomSilla, setNomSilla] = useState('')


    useEffect(() => {
        if (isNaN(Number(props.data.id)) === true){
            setColorButSel(state => ({...state, backgroundColor: "#ffffff"}))
        }else{
            let valor = props.colorButSel.backgroundColor
            setColorButSel(state => ({...state, backgroundColor: valor}))
        }
        
        setColorButSel(state => ({...state,
            backgroundColor: props.colorButSel.backgroundColor,
            border: props.colorButSel.border,
            borderRadius: props.colorButSel.borderRadius
        }))

        setCustomtylesDis(state => ({...state,
            backgroundColor: props.stylesD.backgroundColor,
            border: props.stylesD.border,
            borderRadius: props.stylesD.borderRadius
        }))

        setColorButSold(state => ({...state,
            backgroundColor: props.colorButSold.backgroundColor,
            border: props.colorButSold.border,
            borderRadius: props.colorButSold.borderRadius,
            cursor: props.colorButSold.cursor
        }))

        setColorButTran(state => ({...state,
            backgroundColor: props.colorButTran.backgroundColor,
            border: props.colorButTran.border,
            borderRadius: props.colorButTran.borderRadius,
            cursor: props.colorButSold.cursor
        }))

        const filter = (props.sillasDisabled).filter(option => option.fila === props.data.letraAlt && option.columna === props.data.id)
        setSillasDisabled(filter)
        /* props.addSilla(filter) */
    }, [props]);

    let changeNombreSilla = (num_silla, lugar, mesas)=>{
        let silla;

        if(lugar === 'Derecha'){
            setNomSilla(num_silla);
        }else if(lugar === 'Inferior'){
            silla = parseInt(mesas.derecha) + parseInt(num_silla);
            setNomSilla(silla);
        }else if(lugar === 'Izquierda'){
            silla = parseInt(mesas.derecha) + parseInt(mesas.inferior) + parseInt(num_silla);
            setNomSilla(silla);
        } else if(lugar === 'Superior'){
            silla = parseInt(mesas.izquierda) + parseInt(mesas.inferior) + parseInt(mesas.derecha) + parseInt(num_silla);
            setNomSilla(silla);
        }
    }   

    let sillas = (clase, posicion, lugar) => {
        let Content = [];
        for (let i = 0; i < posicion; i++){
            if (lugar === 'Izquierda' || lugar === 'Inferior'){
                Content.push({
                    num: i,
                    data: `${props.data.letraAlt}${props.data.id}${lugar}${i + 1}`,
                    code: posicion - i - 1 
                }
                );
            }else{
                Content.push({
                    num: i,
                    data: `${props.data.letraAlt}${props.data.id}${lugar}${i + 1}`,
                    code: i
                }
                );
            }}


    let saveCode = (func, option, nomSilla) =>{
        func.onClick(option)
    }

        return (
            Content.map(option => {
                let search = sillasDisabled.find(i => i.data === option.data);
                if (search !== undefined){
                    return <span key = {option.num} detail = {control} className = {clase} style = {{cursor: "default"}}></span>
                }else{
                    let searchVendida = (props.butacasVendidas).find(i => i.codigo_silla === option.data && i.num_silla === option.num + 1)
                    if (searchVendida !== undefined){
                        switch(searchVendida.id_status){
                            case 1:
                                if (searchVendida.idContent === props.idContent){
                                    return <span key = {option.num} detail = {control} onClick = {() => {props.onClickDel(option)}} onMouseOver={()=>changeNombreSilla(option.code +1, lugar, props.confMesas)} title = {`Silla - ${nomSilla}`} className = {clase} style = {colorButSel}></span>
                                }else{
                                    return <span key = {option.num} detail = {control} onClick = {() => {
                                        toast.warn(<small>El asiento <strong>{`Mesa ${props.data.letraAlt}${props.data.id} - Silla ${lugar} ${option.num  + 1}`}</strong> se encuentra en transición por otra persona</small>);
                                    }} title = 'cosa 1' className = {clase} style = {colorButTran}></span>
                                }
                            case 2:
                                if (searchVendida.idContent === props.idContent){
                                    return <span key = {option.num} detail = {control} onClick = {() => {props.onClickDel(option)}} onMouseOver={()=>changeNombreSilla(option.code +1, lugar, props.confMesas)} title = {`Silla - ${nomSilla}`} className = {clase} style = {colorButSel}></span>
                                }else{
                                    return <span key = {option.num} detail = {control} onClick = {() => {
                                        toast.warn(<small>El asiento <strong>{`Mesa ${props.data.letraAlt}${props.data.id} - Silla ${lugar} ${option.num  + 1}`}</strong> se encuentra en transición por otra persona</small>);
                                    }} onMouseOver={()=>changeNombreSilla(option.code +1, lugar, props.confMesas)} title = {`Silla - ${nomSilla}`} className = {clase} style = {colorButTran}></span>
                                }
                            case 4:
                                if (searchVendida.idContent === props.idContent){
                                    return <span key = {option.num} detail = {control} onClick = {() => {props.onClickDel(option)}} onMouseOver={()=>changeNombreSilla(option.code +1, lugar, props.confMesas)} title = {`Silla - ${nomSilla}`} className = {clase} style = {colorButSel}></span>
                                }else{
                                    return <span key = {option.num} detail = {control} onClick = {() => {
                                        toast.warn(<small>El asiento <strong>{`Mesa ${props.data.letraAlt}${props.data.id} - Silla ${lugar} ${option.num  + 1}`}</strong> se encuentra en transición por otra persona</small>);
                                    }} onMouseOver={()=>changeNombreSilla(option.code +1, lugar, props.confMesas)} title = {`Silla - ${nomSilla}`} className = {clase} style = {colorButSold}></span>
                                }
                            default:
                                /* Estos son los casos ya procesados */
                                return <span key = {option.num} detail = {control} /* onClick = {() => {props.onClickDel(option)}} */ onMouseOver={()=>changeNombreSilla(option.code +1, lugar, props.confMesas)} title = {`Silla - ${nomSilla}`} className = {clase} style = {colorButSold}></span>
                        }
                    }else{
                        return <span key = {option.num} detail = {control} onClick = {() => {saveCode(props, option, nomSilla)}} onMouseOver={()=>changeNombreSilla(option.code +1, lugar, props.confMesas)} title = {`Silla - ${nomSilla}`} className = {clase} style = {customStylesDis}></span>
                    }
                }
            })
        )
    }

    let sillasDis = (clase, posicion, lugar) => {
        let Content = [];
        for (let i = 0; i < posicion; i++) Content.push(i);

        return (
            Content.map(option => (
                <span key = {option} className = {clase}></span>
            ))
        )
    }

    switch (true){
        case(!isNaN(Number(props.data.id)) === true && props.data.disabledInd === false):
            return (
                <span className = "mesa-orden">
                    <div style = {{paddingLeft: 10, paddingRight: 10}} align = "center">
                        {sillas("silla-mesa-h-small mesa-default", props.confMesas.superior, "Superior")} 
                    </div>
                    <div>
                        <table>
                            <tr>
                                <td>{sillas("silla-mesa-v-small mesa-default", props.confMesas.izquierda, "Izquierda")}</td>
                                <td className = "mesa-default" onClick = {() => {props.onClickMesa()}} width = "80%" style = {{background: "#f0f0f0", padding: 5, borderRadius: "4px 4px", minWidth: 34}} align = "center" title = {`Mesa ${props.data.letraAlt}${props.data.id}`}>{`${props.data.letraAlt}${props.data.id}`}</td>
                                <td>{sillas("silla-mesa-v-small mesa-default", props.confMesas.derecha, "Derecha")}</td>
                            </tr>
                        </table>
                    </div>
                    <div style = {{paddingLeft: 10, paddingRight: 10}} align = "center">
                        {sillas("silla-mesa-h-small mesa-default", props.confMesas.inferior, "Inferior")} 
                    </div>
                </span>
            )
        case(!isNaN(Number(props.data.id)) === false):
            return (
                <span className = "mesa-orden">
                    <div style = {{paddingLeft: 10, paddingRight: 10}} align = "center">
                        {sillasDis("silla-mesa-h-small mesa-default", props.confMesas.superior, "Superior")} 
                    </div>
                    <div>
                        <table>
                            <tr>
                                <td>{sillasDis("silla-mesa-v-small", props.confMesas.izquierda, "Izquierda")}</td>
                                <td width = "80%" style = {{background: "#ffffff", padding: 5, borderRadius: "4px 4px", minWidth: 34}} align = "center"></td>
                                <td>{sillasDis("silla-mesa-v-small", props.confMesas.derecha, "Derecha")}</td>
                            </tr>
                        </table>
                    </div>
                    <div style = {{paddingLeft: 10, paddingRight: 10}} align = "center">
                        {sillasDis("silla-mesa-h-small mesa-default", props.confMesas.inferior, "Inferior")} 
                    </div>
                </span>
            )
        case(props.data.disabledInd === true):
        return (
            <span className = "mesa-orden">
                <div style = {{paddingLeft: 10, paddingRight: 10}} align = "center">
                    {sillasDis("silla-mesa-h-small mesa-default", props.confMesas.superior, "Superior")} 
                </div>
                <div>
                    <table>
                        <tr>
                            <td>{sillasDis("silla-mesa-v-small mesa-default", props.confMesas.izquierda, "Izquierda")}</td>
                            <td width = "80%" style = {{background: "#ffffff", padding: 5, borderRadius: "4px 4px", minWidth: 34, cursor: "pointer"}} align = "center"></td>
                            <td>{sillasDis("silla-mesa-v-small mesa-default", props.confMesas.derecha, "Derecha")}</td>
                        </tr>
                    </table>
                </div>
                <div style = {{paddingLeft: 10, paddingRight: 10}} align = "center">
                    {sillasDis("silla-mesa-h-small mesa-default", props.confMesas.inferior, "Inferior")} 
                </div>
            </span>
        )
        default: return null;
    }
}

export default MesaDist;
