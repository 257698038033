import React, { useEffect, useState } from "react";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Popover, PopoverHeader, PopoverBody } from 'reactstrap';



const CarritoCompra = (props) => {
    const [popoverErr, setPopoverErr] = useState(false)
    const [compras, setCompras] = useState([])
    const [moneda, setMoneda]   = useState("")
    const [control, setControl]   = useState(false)

    useEffect(() => {
        setCompras(JSON.parse(sessionStorage.getItem("ContentDataCart")))
        let currency = JSON.parse(sessionStorage.getItem("ContentDataCurrency"));

        setTimeout(() => {
            let ContentData = JSON.parse(sessionStorage.getItem("ContentDataCart"));
            let ContentDataEvent = JSON.parse(sessionStorage.getItem("ContentDataEvent"));

            if ((ContentData) !== null){
                setCompras(ContentData)
                setControl(!control)
            }else{
                sessionStorage.removeItem("ContentDataCart")
                setCompras([])
            }
    
            if ((ContentDataEvent) !== null){
                setMoneda(currency.moneda)
            }

        }, 50);
    }, [props.control]);

    let nombreAsiento = (data) => {
        switch(true){
            case(data.silla_code === null && data.silla_num === null):
                return `ASIENTO ${data.fila}${data.columna}`;
            case (data.fila === "N/"):
                return "Zona Libre"
            default:
                let ladoSilla = (string) => {
                    let izquierda = string.indexOf("Izquierda");
                    let superior = string.indexOf("Superior");
                    let derecha = string.indexOf("Derecha");
                    let inferior = string.indexOf("Inferior");
        
                    switch (true){
                        case (izquierda > 0): return "IZQUIERDA";
                        case (superior > 0): return "SUPERIOR";
                        case (derecha > 0): return "DERECHA";
                        case (inferior > 0): return "INFERIOR";
                        default: return "";
                    }
                }

            return `MESA ${data.fila}${data.columna} - SILLA ${ladoSilla(data.silla_code)} ${data.silla_num}`
        }
    }

    let comprasLists = () => (
        compras.map(option => (
            <div key = {option.id}>
                <small style = {{fontSize: 10, padding: 5}}><strong className = "text-danger">{option.asiento}</strong> - {nombreAsiento(option)} - <strong>{moneda} {option.precio}</strong></small>
            </div>
        ))
    )

    let totalPago = () => {
        let cant = 0;
        compras.forEach(opt => {
            cant += opt.precio;
        })
        
        return `${moneda} ${cant}`;
    }

    if (compras === null || compras === undefined) return null;

    if (compras.length === 0) return null;
    
    return (
        <div onClick = {() => { setPopoverErr(!popoverErr) }}>
            <div title = "Mis compras" className = "shoping-car" id = "cart_content">
                <small style = {{cursor: "pointer"}}><FontAwesomeIcon icon = {faShoppingCart} size = "2x"/> <Badge color="danger" pill><span style = {{fontSize: 12}}>{compras.length}</span></Badge></small>
            </div>
            <Popover placement = "bottom" isOpen = {popoverErr} target = "cart_content" toggle = {() => { setPopoverErr(!popoverErr) }}>
                <PopoverHeader><small><FontAwesomeIcon size = "sm" icon = {faShoppingCart}/> Mis compras</small></PopoverHeader>
                <PopoverBody>
                    <div style = {{overflowY: "auto", maxHeight: 300}}>
                        {comprasLists()}
                    </div>
                    {/* <div align = "right" style = {{borderTop: "1px dotted #424242"}}>
                        <strong>TOTAL. <span>{totalPago()}</span></strong>
                    </div> */}
                </PopoverBody>
            </Popover>
        </div>
    )
}

export default CarritoCompra;
