import React, { useEffect, useState, useMemo } from "react";

import Select from 'react-select';


import { Button, Row, Col, Input, InputGroupAddon, InputGroupText, InputGroup } from "reactstrap";
import { toast } from 'react-toastify';

import { SelectBanco, PagoMovil } from "functions/EventosFunctions";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal2 from "../../components/Modals/ErrorModal2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';



export function MenuDesplegablePagoMovil({props}) {
    const [bancos, setBancos] = useState([]);
    const [bancoSeleccionado, setBancoSeleccionado] = useState("");
    const [ciCliente, setCiCliente] = useState("");
    const [telefono, setTelefono] = useState("");


    const [codigoC2P, setCodigoC2P] = useState("");

    const [openModalOk, setOpenModalOk] = useState(false)
    const [modalOk, setModalOk]         = useState("")
    const [modalMess, setMmodalMess]    = useState("")
    const [openModalErr2, setOpenModalErr2] = useState(false)
    const [mess, setMess]               = useState("")
    const [visibleErr, setVisibleErr]   = useState("none")
    const [errMess, setErrMess]         = useState("")

    const [preloader, setPreloader] = useState(false);

    const valorDivisa = JSON.parse(sessionStorage.getItem('ContentDataCurrency'));
    const valorEntrada = JSON.parse(sessionStorage.getItem('ContentDataCart'));
    const cantidadEntradas = valorEntrada.length
    const PrecioEntrada = valorEntrada[0].precio

    const impuesto = JSON.parse(sessionStorage.getItem('ContentDataImpuesto'))
    const dolares = cantidadEntradas * PrecioEntrada

    let servTaquilla = 0
    if(impuesto.servTaquilla){
      if(impuesto.servTaquilla.is_aplicable === 1){
        servTaquilla = impuesto.servTaquilla.valor * dolares / 100
      }
      }

      let servTaquillas = servTaquilla * valorDivisa.valor_1usd_moneda_local;

      let entradas = dolares * valorDivisa.valor_1usd_moneda_local

    let totalBs = entradas + servTaquillas;

    let dolar = totalBs / valorDivisa.valor_1usd_moneda_local;

    // Preloader
    let preloadPay = () => {
        if (preloader === true){
            return (
                <div className = "preload-ticket">
                    <div className = "preload-ticket-icon_card" align = "center" style = {{left: "42% !important"}}>
                        <FontAwesomeIcon icon = {faCircleNotch} size = "5x" spin = {true}/>
                        <div style = {{marginLeft: 0}}>Procesando su compra, por favor espere</div>
                    </div>  
                </div>
            )
        }else{
            return null;
        }
    }

    // Termina el Boton de Terminos y condiciones

    // Select  de Bancos
    useEffect(() => {
      SelectBanco((resp) => {
        setBancos(resp);
      },);
    }, []);
  
    const handleBancoSeleccionado = (banco) => {
      setBancoSeleccionado(banco);
    };
  
    const opcionesBancos = useMemo(() =>
      bancos.map(banco => ({ value: banco.codigo, label: banco.nombre }))
    , [bancos]);

    // Termina el Select de Bancos

    // Funcion Pagar
  
    const pagar = () => {

        // Validaciones
      if(ciCliente == "" || ciCliente == null){
          toast.error("El campo cedula de identidad no puede estar vacia",{autoClose: 4000});
      }
      if(telefono == "" || telefono == null){
          toast.error("El campo Telefono no puede estar vacio",{autoClose: 4000});
      }
      if(bancoSeleccionado == "" || bancoSeleccionado == null){
          toast.error("El campo Banco no puede estar vacio",{autoClose: 4000});
      }
      
      if(ciCliente != "" && telefono != "" && bancoSeleccionado != ""){
          const content = {
              ciCliente: ciCliente,
              telefono: telefono,
              banco: bancoSeleccionado.value,
              codigoC2P: codigoC2P,
              tipoP: props
          }
        //   Fin Validaciones

        // Mensajes Formateados

        // Llamada a la funcion PagoMovil
        setPreloader(true);

        let url = window.location.href;
        let part = url.split("/");
        let urlEvent = part.pop();
        let urlReturn = `/event/compra_pago_movil/${urlEvent}`;

        let ContentDataEvent = JSON.parse(sessionStorage.getItem("ContentDataEvent"));
          PagoMovil(content, urlReturn,ContentDataEvent.id_evento, ContentDataEvent.fecha, ContentDataEvent.hora_in, (resp) =>{
              if(resp.status == "ok"){
                const aprobado = `
                <div>
                <strong><h4 style ="color:black; font-size:14; font-family: system-ui;">${resp.mess}</h4></strong>
              </div><br/>
                <img src="https://media.tenor.com/BntXpMlrGuEAAAAM/check-correct.gif"><br />
                Sera Redireccionado en Breve
                `;
                setOpenModalOk(true)
                setModalOk("Transaccion Exitosa")
                setMmodalMess(aprobado)
                setPreloader(false);

                let ContentResp = {
                  dataPago: resp.dataPago,
                  infoTickets: resp.infoTickets,
                  detallesPago: resp.detallesPago,
                  config: resp.config
              }
              sessionStorage.setItem("ContentResp", JSON.stringify(ContentResp));

                setTimeout(function() {
                    window.location.href = resp.link;
                  }, 5000);
                
              }else{
                const rechazado = `
                <div>
                <strong><h4 style ="color:black; font-size:14; font-family: system-ui; text-align:center;">${resp.mess}</h4></strong>
              </div>
`;
                setOpenModalErr2(true)
                setMess(rechazado)
                setVisibleErr("none")
                setErrMess("")
                setPreloader(false);
              }
          });
  
      }
    }
    // Termina Funcino Pagar

    let c2p = "Inserte el código de C2P"
    let p2c = "Inserte el número de referencia de su pago móvil"

  
    return (
    <main className="px-2 py-3">
    <Row>                        
      <Col md = {12} className="mb-1">
        <strong style = {{fontSize: 18}}><strong>Datos del pago móvil</strong></strong>
    </Col>
    <Col md = {12} className = "custom-mt-10 my-2"> 
      <InputGroup className="input-group-alternative mb-3">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="fa fa-id-card" />
          </InputGroupText>
        </InputGroupAddon>
        <Input placeholder = "Cédula de identidad" name = "ciCliente" type = "text" maxLength = '8'  value = {ciCliente} onChange={(event) => {
            const newValue = event.target.value;
            if (!isNaN(newValue)) {
              setCiCliente(newValue);
            }
          }}/>
      </InputGroup>


    </Col>
    <Col md = {12} className = "custom-mt-10 my-2">
    <InputGroup className="input-group-alternative mb-3">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
          <i className="fa fa-mobile"></i>
          </InputGroupText>
        </InputGroupAddon>
    <Input placeholder = "Telefono del titular" type = "text" maxLength = '11'  value = {telefono} onChange={(event) => {
        const newValue = event.target.value;
        if (!isNaN(newValue)) {
          setTelefono(newValue);
        }
      }}/>
    </InputGroup>
    </Col>
    <Col md = {12} className = "custom-mt-10 my-2">
          <Select
          value={bancoSeleccionado}
          onChange={handleBancoSeleccionado}
          options={opcionesBancos}
          placeholder="Seleccionar banco"
          />
      </Col>
    <Col md = {12} className = "custom-mt-10 my-2">
    <InputGroup className="input-group-alternative mb-3">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="fa fa-id-card" />
          </InputGroupText>
        </InputGroupAddon>
    <Input placeholder = {(props === "C2P") ? c2p : p2c} name = "C2P" type = "text" maxLength = '12'  value = {codigoC2P} onChange={(event) => {
        const newValue = event.target.value;
        if (!isNaN(newValue)) {
          setCodigoC2P(newValue);
        }
      }}/>
      </InputGroup>
    </Col>


    <Col md = {7}className="mt-2">
      <h4>
        <span className="badge badge-warning p-2">Monto a pagar: {totalBs.toFixed(2)} Bs. / 
          {valorDivisa.is_convert === 1 ?
          " " + dolar.toFixed(2) + " USD"
        : " N/A"}
      </span>
      </h4>
      <h5>
        Ref. : {valorDivisa.valor_1usd_moneda_local} Bs.
      </h5>
    </Col>

      <Col md = {5} className = "custom-mt-10 text-right">
          <Button size = "md" color = "primary" onClick = {pagar} title = "Comprar boletos" >Confirmar compra</Button>
      </Col>

      </Row>
      {preloadPay()}
      <SuccessModal isOpen = {openModalOk} close = {() => {setOpenModalOk(false)}} content = {modalMess} contentTitle = {modalOk}/>
      <ErrorModal2 isOpen = {openModalErr2} close = {() => {setOpenModalErr2(false)}} mess = {mess} />

      </main>
    );
  }