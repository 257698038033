import { requerido, sinEspacios, mensObligatorio, selectObligatorio, mensSinEspacios } from "./Validaciones";
import { Post, PostFormData, PostPdf } from "./Generales";
import { Url } from "./Url";
import Payment from "payment";

export const SearchGeneralEvent = (callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID
    }

    Post(`${Url}searchGeneralEvents`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const SearchZoneEvent = (id, urlEvent, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idSite: id,
        urlEvent: urlEvent
    }

    Post(`${Url}searchZoneEvent`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const SearchZoneEventRet = (id, idEvento, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idSite: id,
        idEvento: idEvento
    }

    Post(`${Url}searchZoneEventRet`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const SearchZoneEventPrice = (id, idEvent, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idSite: id,
        idEvent: idEvent
    }

    Post(`${Url}searchZoneEventPrice`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const AddEventPrice = (campos, idEvento, callback) => {
    let errores = {
        cantidad: 0,
        errMesss: []
    };

    /* Validaciones */
    (campos.zonas2).forEach(i =>{
        if(i.status === 1 && i.valor < 1){
            errores.cantidad += 1;
            (errores.errMesss).push(`<strong class = "text-danger">${i.nombre} - ${i.nombre_funcion}</strong> debe tener un valor mayor a 0`)
        }
    })

    if (errores.cantidad === 0){
        let data = JSON.parse(sessionStorage.getItem("ContentData"));

        let Content = {
            eid: data.E_ID,
            uid: data.U_ID,
            zonas: JSON.stringify(campos.zonas2),
            idEvento: idEvento,
            activar: campos.activar,
            impuestosI: JSON.stringify(campos.impuestosI),
            impuestosT: JSON.stringify(campos.impuestosT),
            servicioTaquilla: campos.servicioTaquilla
        }

        Post(`${Url}addEventPrice`, Content, (resp) => {
            let data = JSON.parse(resp)
            callback([errores, data])
        });
    }else{
        callback([errores])
    }
}

export const AddEvent = (campos, img1, img2, img3, callback) => {
    let errores = {
        cantidad: 0,
        errMesss: []
    };

    /* Validaciones */
    requerido   (campos.nombre, errores,            mensObligatorio("Nombre del Evento"), (resp) => { errores = resp })
    requerido   (campos.url, errores,               mensObligatorio("Url del Evento"), (resp) => { errores = resp })
    requerido   (campos.proSelect, errores,         selectObligatorio("al menos un", "Productor"), (resp) => { errores = resp })
    /* requerido   (campos.orgSelect, errores,         selectObligatorio("al menos un", "Organizador"), (resp) => { errores = resp }) */
    requerido   (campos.diasEvents, errores,        selectObligatorio("al menos una", "Función"), (resp) => { errores = resp })
    requerido   (campos.contentState, errores,      mensObligatorio("Descripción"), (resp) => { errores = resp })
    // requerido   (img1, errores,                     selectObligatorio("una", "Imagen Principal"), (resp) => { errores = resp })
    requerido   (campos.tipoESelect, errores,       selectObligatorio("el", "Tipo de Evento"), (resp) => { errores = resp })
    requerido   (campos.catSelect, errores,         selectObligatorio("al menos una", "Categoría"), (resp) => { errores = resp })
    requerido   (campos.artSelect, errores,         selectObligatorio("al menos un", "Artista"), (resp) => { errores = resp })
    requerido   (campos.sitesSelect.value, errores, selectObligatorio("el", "Sitio del Evento"), (resp) => { errores = resp })
    /* if (campos.sitesSelect.value !== "") {
        (campos.zonas2).forEach(i =>{
            if(i.status === 1 && i.valor < 1){
                errores.cantidad += 1;
                (errores.errMesss).push(`<strong class = "text-danger">${i.nombre} - ${i.nombre_funcion}</strong> debe tener un valor mayor a 0`)
            }
        })
    } */
    requerido   (campos.metSelect, errores,         selectObligatorio("los", "Métodos de Pago"), (resp) => { errores = resp })
    requerido   (campos.monSelect, errores,         selectObligatorio("las", "Monedas"), (resp) => { errores = resp })
    requerido   (campos.monPSelect.value, errores,  selectObligatorio("la", "Moneda Predeterminada"), (resp) => { errores = resp })

    // requerido   (campos.emailTicket, errores,       mensObligatorio("Email de Compra"), (resp) => { errores = resp })
    sinEspacios   (campos.url, errores,             mensSinEspacios("Url"), (resp) => { errores = resp });
    requerido (campos.plano, errores, selectObligatorio("el", "Plano"), (resp) => { errores = resp });


    campos.metSelect.forEach((i) => {
        if (i.label === "Multipagos") {
            requerido (campos.amountPay, errores, selectObligatorio("la cantida de", "Multipagos disponibles"), (resp) => { errores = resp });
        }
      });


    if (errores.cantidad === 0){
        let data = JSON.parse(sessionStorage.getItem("ContentData"));

        let Content = {
            eid: data.E_ID,
            uid: data.U_ID,
            nombre: campos.nombre,
            url: campos.url,
            productores: JSON.stringify(campos.proSelect),
            organizadores: JSON.stringify(campos.orgSelect),
            funciones: JSON.stringify(campos.diasEvents),
            descripcion: campos.contentState,
            imagen: img1,
            imagenSrc: campos.file1,
            tumbnail: img2,
            tumbnailSrc: campos.file2,
            plano: img3,
            planoSrc: campos.plano,
            edadMin: campos.minAge,
            tipoEvento: campos.tipoESelect.value,
            categorias: JSON.stringify(campos.catSelect),
            artistas: JSON.stringify(campos.artSelect),
            terminos: campos.terminos,
            sitio: campos.sitesSelect.value,
            zonas: JSON.stringify(campos.zonas2),
            metodos: JSON.stringify(campos.metSelect),
            moneda: JSON.stringify(campos.monSelect),
            monedaPred: campos.monPSelect.value,
            notas: campos.amountPay.value,
            emailTicket: campos.emailTicket,
            ubicacion: campos.ubicacion

        }

        Post(`${Url}addEvent`, Content, (resp) => {
            let data = JSON.parse(resp)
            callback([errores, data])
        });
    }else{
        callback([errores])
    }
}

export const AddEventDraft = (campos, img1, img2, img3, callback) => {
    let errores = {
        cantidad: 0,
        errMesss: []
    };

    /* Validaciones */
    requerido   (campos.nombre, errores,            mensObligatorio("Nombre del Evento"), (resp) => { errores = resp })
    sinEspacios   (campos.url, errores,             mensSinEspacios("Url"), (resp) => { errores = resp });


    campos.metSelect.forEach((i) => {
        if (i.label === "Multipagos") {
            requerido (campos.amountPay, errores, selectObligatorio("la cantida de", "Multipagos disponibles"), (resp) => { errores = resp });
        }
      });


    if (errores.cantidad === 0){
        let data = JSON.parse(sessionStorage.getItem("ContentData"));

        let Content = {
            eid: data.E_ID,
            uid: data.U_ID,
            nombre: campos.nombre,
            url: campos.url,
            productores: JSON.stringify(campos.proSelect),
            organizadores: JSON.stringify(campos.orgSelect),
            funciones: JSON.stringify(campos.diasEvents),
            descripcion: campos.contentState,
            imagen: img1,
            imagenSrc: campos.file1,
            tumbnail: img2,
            tumbnailSrc: campos.file2,
            plano: img3,
            planoSrc: campos.plano,
            edadMin: campos.minAge,
            tipoEvento: campos.tipoESelect.value,
            categorias: JSON.stringify(campos.catSelect),
            artistas: JSON.stringify(campos.artSelect),
            terminos: campos.terminos,
            sitio: campos.sitesSelect.value,
            zonas: JSON.stringify(campos.zonas2),
            metodos: JSON.stringify(campos.metSelect),
            moneda: JSON.stringify(campos.monSelect),
            monedaPred: campos.monPSelect.value,
            notas: campos.amountPay.value,
            emailTicket: campos.emailTicket,
            ubicacion: campos.ubicacion

        }

        Post(`${Url}addEventDraft`, Content, (resp) => {
            let data = JSON.parse(resp)
            callback([errores, data])
        });
    }else{
        callback([errores])
    }
}

export const ModEvent = (idEvent, campos, img1, img2, img3, callback) => {
    let errores = {
        cantidad: 0,
        errMesss: []
    };

    /* Validaciones */
    requerido   (campos.nombre, errores,            mensObligatorio("Nombre del Evento"), (resp) => { errores = resp })
    requerido   (campos.url, errores,               mensObligatorio("Url del Evento"), (resp) => { errores = resp })
    requerido   (campos.proSelect, errores,         selectObligatorio("al menos un", "Productor"), (resp) => { errores = resp })
    /* requerido   (campos.orgSelect, errores,         selectObligatorio("al menos un", "Organizador"), (resp) => { errores = resp }) */
    requerido   (campos.diasEvents, errores,        selectObligatorio("al menos una", "Función"), (resp) => { errores = resp })
    requerido   (campos.contentState, errores,      mensObligatorio("Descripción"), (resp) => { errores = resp })
    /* requerido   (img1, errores,                     selectObligatorio("una", "Imagen Principal"), (resp) => { errores = resp }) */
    requerido   (campos.tipoESelect, errores,       selectObligatorio("el", "Tipo de Evento"), (resp) => { errores = resp })
    requerido   (campos.catSelect, errores,         selectObligatorio("al menos una", "Categoría"), (resp) => { errores = resp })
    requerido   (campos.artSelect, errores,         selectObligatorio("al menos un", "Artista"), (resp) => { errores = resp })
    requerido   (campos.sitesSelect.value, errores, selectObligatorio("el", "Sitio del Evento"), (resp) => { errores = resp })
    /* if (campos.sitesSelect.value !== "") {
        (campos.zonas2).forEach(i =>{
            if(i.status === 1 && i.valor < 1){
                errores.cantidad += 1;
                (errores.errMesss).push(`<strong class = "text-danger">${i.nombre} - ${i.nombre_funcion}</strong> debe tener un valor mayor a 0`)
            }
        })
    } */
    requerido   (campos.metSelect, errores,         selectObligatorio("los", "Métodos de Pago"), (resp) => { errores = resp })
    requerido   (campos.monSelect, errores,         selectObligatorio("las", "Monedas"), (resp) => { errores = resp })
    requerido   (campos.monPSelect.value, errores,  selectObligatorio("la", "Moneda Predeterminada"), (resp) => { errores = resp })
    // requerido   (campos.emailTicket, errores,       mensObligatorio("Email de Compra"), (resp) => { errores = resp })
    sinEspacios   (campos.url, errores,             mensSinEspacios("Url"), (resp) => { errores = resp })

    campos.metSelect.forEach((i) => {
        if (i.label === "Multipagos") {
            requerido (campos.amountPay, errores, selectObligatorio("la cantida de", "Multipagos disponibles"), (resp) => { errores = resp });
        }
    });


            let amountPayValue; 
            // Verifica si campos.amountPay es un array y tiene al menos un elemento
            if (Array.isArray(campos.amountPay) && campos.amountPay.length > 0) {
                // Accede al valor de la primera posición del array
                amountPayValue = campos.amountPay[0].value
            } else {
                // El array está vacío o no es un array
                amountPayValue = campos.amountPay.value
            }

    if (errores.cantidad === 0){
        let data = JSON.parse(sessionStorage.getItem("ContentData"));

        let Content = {
            eid: data.E_ID,
            uid: data.U_ID,
            idEvent:idEvent,
            nombre: campos.nombre,
            url: campos.url,
            productores: JSON.stringify(campos.proSelect),
            organizadores: JSON.stringify(campos.orgSelect),
            funciones: JSON.stringify(campos.diasEvents),
            descripcion: campos.contentState,
            imagen: img1,
            imagenSrc: campos.file1,
            tumbnail: img2,
            tumbnailSrc: campos.file2,
            plano: img3,
            planoSrc: campos.plano,
            edadMin: campos.minAge,
            tipoEvento: campos.tipoESelect.value,
            categorias: JSON.stringify(campos.catSelect),
            artistas: JSON.stringify(campos.artSelect),
            terminos: campos.terminos,
            sitio: campos.sitesSelect.value,
            zonas: JSON.stringify(campos.zonas2),
            metodos: JSON.stringify(campos.metSelect),
            moneda: JSON.stringify(campos.monSelect),
            monedaPred: campos.monPSelect.value,
            notas: amountPayValue,
            emailTicket: campos.emailTicket,
            ubicacion: campos.ubicacion

        }

        Post(`${Url}modEvent`, Content, (resp) => {
            let data = JSON.parse(resp)
            callback([errores, data])
        });
    }else{
        callback([errores])
    }
}

export const ModEventDraft = (idEvent, campos, img1, img2, img3, callback) => {
    let errores = {
        cantidad: 0,
        errMesss: []
    };

    /* Validaciones */
    requerido   (campos.nombre, errores,            mensObligatorio("Nombre del Evento"), (resp) => { errores = resp })
    sinEspacios   (campos.url, errores,             mensSinEspacios("Url"), (resp) => { errores = resp })

    campos.metSelect.forEach((i) => {
        if (i.label === "Multipagos") {
            requerido (campos.amountPay, errores, selectObligatorio("la cantida de", "Multipagos disponibles"), (resp) => { errores = resp });
        }
    });


    let amountPayValue; 
    // Verifica si campos.amountPay es un array y tiene al menos un elemento
    if (Array.isArray(campos.amountPay) && campos.amountPay.length > 0) {
        // Accede al valor de la primera posición del array
        amountPayValue = campos.amountPay[0].value
    } else {
        // El array está vacío o no es un array
        amountPayValue = campos.amountPay.value
    }

    if (errores.cantidad === 0){
        let data = JSON.parse(sessionStorage.getItem("ContentData"));

        let Content = {
            eid: data.E_ID,
            uid: data.U_ID,
            idEvent:idEvent,
            nombre: campos.nombre,
            url: campos.url,
            productores: JSON.stringify(campos.proSelect),
            organizadores: JSON.stringify(campos.orgSelect),
            funciones: JSON.stringify(campos.diasEvents),
            descripcion: campos.contentState,
            imagen: img1,
            imagenSrc: campos.file1,
            tumbnail: img2,
            tumbnailSrc: campos.file2,
            plano: img3,
            planoSrc: campos.plano,
            edadMin: campos.minAge,
            tipoEvento: campos.tipoESelect.value,
            categorias: JSON.stringify(campos.catSelect),
            artistas: JSON.stringify(campos.artSelect),
            terminos: campos.terminos,
            sitio: campos.sitesSelect.value,
            zonas: JSON.stringify(campos.zonas2),
            metodos: JSON.stringify(campos.metSelect),
            moneda: JSON.stringify(campos.monSelect),
            monedaPred: campos.monPSelect.value,
            notas: amountPayValue,
            emailTicket: campos.emailTicket,
            ubicacion: campos.ubicacion

        }

        Post(`${Url}modEventDraft`, Content, (resp) => {
            let data = JSON.parse(resp)
            callback([errores, data])
        });
    }else{
        callback([errores])
    }
}

export const SearchEvents = (status, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        status: status
    }

    Post(`${Url}searchEvents`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const PayTypes = (moneda, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        moneda: moneda
    }
    Post(`${Url}payTypes`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const DisableEvent = (id, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        id: id
    }

    Post(`${Url}disableEvent`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const ConfirmEvent = (id, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        id: id
    }

    Post(`${Url}confirmEvent`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const AprobarEvent = (id, observ, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        id: id,
        observ: observ
    }

    Post(`${Url}aprobarEvent`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const NoAprobarEvent = (id, observ, alternar, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        id: id,
        observ: observ,
        alternar: alternar
    }

    Post(`${Url}noAprobarEvent`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const SearchFunctions = (idEvento, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idEvento: idEvento
    }

    Post(`${Url}searchFunctions`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const DelEvent = (idEvento, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idEvento: idEvento
    }

    Post(`${Url}delEvent`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const SearchEventInd = (idEvento, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idEvento: idEvento
    }

    Post(`${Url}searchEventInd`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const SearchFuncionsIndex = (pais, maxSearch, pagActiva, callback) => {
    let Content = {
        pais: pais,
        maxSearch: maxSearch,
        pagActiva: pagActiva
    }

    Post(`${Url}searchFuncionsIndex`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const SearchRecintosLocal = (localidad, callback) => {
    let Content = {
        localidad: localidad
    }

    Post(`${Url}searchRecintosLocal`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const FilterEvents = (text, artista, localidad, recinto, callback) => {
    let Content = {
        text: text,
        artistaLabel: artista.label,
        artistaValue: artista.value,
        localidad: localidad,
        recinto: recinto
    }

    Post(`${Url}filterEvents`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const SearchFuncionsInd = (evento, callback) => {
    let eventSplice = evento.split("#");

    let Content = {
        idFuncion: eventSplice[1],
        urlEvento: eventSplice[0]
    }

    Post(`${Url}searchFuncionsInd`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const SearchButacasInd = (idRecinto, idNombreZona, idFuncion, nombreZona, idEvento, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idSite: idRecinto,
        idNombreZona: idNombreZona,
        idFuncion: idFuncion,
        nombreZona: nombreZona,
        idEvento: idEvento
    }

    Post(`${Url}searchButacasInd`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const PauseFuncion = (id, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));
    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        id: id
    }

    Post(`${Url}pauseFuncion`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const ActivateFuncion = (id, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));
    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        id: id
    }

    Post(`${Url}activateFuncion`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const CerrarFuncion = (id, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));
    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        id: id
    }

    Post(`${Url}cerrarFuncion`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const AddButacaInd = (campos, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        fila: campos.fila,
        columna: campos.columna,
        idFuncion: campos.idFuncion,
        idZona: campos.idZona,
        idDistribucion: campos.idDistribucion
    }

    Post(`${Url}addButacaInd`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const AddMesaInd = (campos, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        fila: campos.fila,
        columna: campos.columna,
        idFuncion: campos.idFuncion,
        idZona: campos.idZona,
        idDistribucion: campos.idDistribucion,
        sillaNum: campos.sillaNum,
        sillaCode: campos.sillaCode,
        CodeSilla: campos.CodeSilla
    }

    Post(`${Url}addMesaInd`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const AddMesaIndRet = (campos, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        fila: campos.fila,
        columna: campos.columna,
        idFuncion: campos.idFuncion,
        idZona: campos.idZona,
        idDistribucion: campos.idDistribucion,
        sillaNum: campos.sillaNum,
        sillaCode: campos.sillaCode,
        silla_cont: campos.silla_cont,
    }

    Post(`${Url}addMesaIndRet`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const AddEntLibreInd = (campos, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        cantAsientos: campos.cantAsientos,
        eventSelect: campos.eventSelect,
        funcionSelect: campos.funcionSelect,
        idDistribucion: campos.idDistribucion,
        idZona: campos.idZona
    }

    Post(`${Url}addEntLibreInd`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const AddEntLibre = (cantAsientos, eventSelect, funcionSelect, idDistribucion, idZona, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));
    
    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        cantAsientos: cantAsientos,
        eventSelect: eventSelect,
        funcionSelect: funcionSelect,
        idDistribucion: idDistribucion,
        idZona: idZona
    }

    Post(`${Url}addEntLibre`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const AddMesa = (campos, mesa, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));    
    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        asientos: JSON.stringify(campos),
        mesa: mesa
    }

    Post(`${Url}addMesa`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const AddMesaRet = (campos, mesa, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));
    
    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        asientos: JSON.stringify(campos),
        mesa: mesa
    }

    Post(`${Url}addMesaRet`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const DelMesa = (campos, mesa, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));
    
    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        asientos: JSON.stringify(campos),
        mesa: mesa
    }

    Post(`${Url}delMesa`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const DelMesaRet = (campos, mesa, idFuncion, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));
    
    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        asientos: JSON.stringify(campos),
        mesa: mesa,
        idFuncion: idFuncion
    }

    Post(`${Url}delMesaRet`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const AddButacaIndRet = (campos, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        fila: campos.fila,
        columna: campos.columna,
        idFuncion: campos.idFuncion,
        idZona: campos.idZona,
        idDistribucion: campos.idDistribucion
    }

    Post(`${Url}addButacaIndRet`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const AddButacaMultiRet = (campos, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        butacas: JSON.stringify(campos.butacas),
        idFuncion: campos.idFuncion,
        idZona: campos.idZona,
        idDistribucion: campos.idDistribucion
    }

    Post(`${Url}addButacaMultiRet`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const AddMesaMultiRet = (campos, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        butacas: JSON.stringify(campos.butacas),
        idFuncion: campos.idFuncion,
        idZona: campos.idZona,
        idDistribucion: campos.idDistribucion
    }

    Post(`${Url}addMesaMultiRet`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const DelButacaInd = (id, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idButaca: id
    }

    Post(`${Url}delButacaInd`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const DelButacasUser = (callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
    }

    Post(`${Url}delButacasUser`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const DelButacaIndRet = (id, idEvento, idFuncion, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idButaca: id,
        idEvento: idEvento,
        idFuncion: idFuncion
    }

    Post(`${Url}delButacaIndRet`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const PayWithZelle = (transaccionZelle, nombreZelle, emailZelle, fileZelleSrc, img1, idEvento, fecha, horaIn, moneda, urlReturn, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        transaccionZelle: transaccionZelle,
        nombreZelle: nombreZelle,
        emailZelle:emailZelle,
        imagen: img1,
        imagenSrc: fileZelleSrc,
        idEvento: idEvento,
        fecha: fecha,
        horaIn: horaIn,
        moneda: moneda,
        urlReturn: urlReturn
    }

    Post(`${Url}payWithZelle`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const PayWithTransfer = (referencia, fileZelleSrc, img1, idEvento, fecha, horaIn, moneda, urlReturn, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        referencia: referencia,
        imagen: img1,
        imagenSrc: fileZelleSrc,
        idEvento: idEvento,
        fecha: fecha,
        horaIn: horaIn,
        moneda: moneda,
        urlReturn: urlReturn
    }

    Post(`${Url}PayWithTransfer`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const MultipaymentMethod = (u_type, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    const Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        u_type: u_type,
    }

    Post(`${Url}MultipaymentMethods`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const PayWithMultiPay = (form, images, idEvento, fecha, horaIn, moneda, urlReturn, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    // Crear un nuevo objeto FormData
    let formData = new FormData();

    // Agregar datos al FormData
    formData.append('eid', data.E_ID);
    formData.append('uid', data.U_ID);
    formData.append('form', JSON.stringify(form)); // Convertir el objeto form a cadena JSON y agregarlo
    formData.append('idEvento', idEvento);
    formData.append('fecha', fecha);
    formData.append('horaIn', horaIn);
    formData.append('moneda', moneda);
    formData.append('urlReturn', urlReturn);


    images.forEach((imageObject, index) => {
        if (imageObject.img instanceof File) {
            // Agregar cada imagen al FormData con un nombre único y también agregar el método correspondiente
            formData.append(`images[${index}][metodo]`, imageObject.metodo);
            formData.append(`images[${index}][img]`, imageObject.img, imageObject.img.name);
        }
    });
    
    PostFormData(`${Url}PayWithMultiPay`, formData, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });

}

export const PayWithMultiPayTaq = (metodos,clientData, saldoAFavor, idEvento, fecha, hora, moneda, urlReturn, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        moneda: moneda,
        metodos: JSON.stringify(metodos),
        clientData: JSON.stringify(clientData),
        saldoAFavor: JSON.stringify(saldoAFavor),
        idEvento: idEvento,
        fecha: fecha,
        hora: hora,
        urlReturn: urlReturn
    }

    Post(`${Url}PayWithMultipayTaquilla`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const PayWithPayPal = (moneda, urlReturn, idEvento, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        moneda: moneda,
        idEvento: idEvento,
        urlReturn: urlReturn
    }

    Post(`${Url}payWithPayPal`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const PaypalStatus = (valores, fecha, hora, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));
    let Content = valores;

    Content.eid = data.E_ID;
    Content.uid = data.U_ID;
    Content.fecha = fecha;
    Content.hora = hora;
    
    Post(`${Url}paypalStatus`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const PayWithCard = (moneda, urlReturn, idEvento, content, fecha, hora, callback) => {
    let cad = window.btoa(unescape(encodeURIComponent(JSON.stringify(content))));
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        moneda: moneda,
        idEvento: idEvento,
        urlReturn: urlReturn,
        idContent: (cad.match(/.{1,6}(.$)?/g)).reverse(),
        fecha: fecha,
        hora: hora
    }

        const dataCard = {
        card_number: content.data4.trim().replace(/\s+/g, ""),
        card_name: content.data3.trim().replace(/\s+/g, "'"),
        card_date: content.data2.trim().replace("/",""),
        ci_number: "V"+content.ci_number,
        cvc: content.data1
    }

    let cadbS = window.btoa(unescape(encodeURIComponent(JSON.stringify(dataCard))));

    let ContentBs = {
        eid: data.E_ID,
        uid: data.U_ID,
        moneda: moneda,
        idEvento: idEvento,
        urlReturn: urlReturn,
        dataCard: cadbS,
        fecha: fecha,
        hora: hora
    }


    if(moneda == "Bs."){
        Post(`${Url}pagoTDC`, ContentBs, (resp) => {
            let data = JSON.parse(resp)
            callback(data)
        });
    }else{
        Post(`${Url}payWithCard`, Content, (resp) => {
            let data = JSON.parse(resp)
            callback(data)
        });
    }


}

// export const PayWithCard = (moneda, urlReturn, idEvento, content, fecha, hora, callback) => {
//     let cad = window.btoa(unescape(encodeURIComponent(JSON.stringify(content))));
//     let data = JSON.parse(sessionStorage.getItem("ContentData"));

//     let Content = {
//         eid: data.E_ID,
//         uid: data.U_ID,
//         moneda: moneda,
//         idEvento: idEvento,
//         urlReturn: urlReturn,
//         idContent: (cad.match(/.{1,6}(.$)?/g)).reverse(),
//         fecha: fecha,
//         hora: hora
//     }

//     if(moneda == "Bs."){
//         Post(`${Url}payWithCard`, Content, (resp) => {
//             let data = JSON.parse(resp)
//             callback(data)
//         });

//     }else{
//         Post(`${Url}payWithCard`, Content, (resp) => {
//             let data = JSON.parse(resp)
//             callback(data)
//         });

//     }
// }

export const SelectBanco = (callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"))
    
    let Content = {
        uid: data.U_ID,
        eid: data.E_ID
    }

    Post(`${Url}bankCode`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const PagoMovil = (content, urlReturn, idEvento, fecha, hora, callback)=>{
    let data = JSON.parse(sessionStorage.getItem("ContentData"))

    let Content = {
        uid: data.U_ID,
        eid: data.E_ID,
        ci_number: "V"+content.ciCliente,
        client_phone: content.telefono,
        code_bank: content.banco,
        codigoC2P: content.codigoC2P,
        tipoP: content.tipoP,
        urlReturn: urlReturn,
        idEvento: idEvento,
        fecha: fecha,
        hora: hora,
    }

    Post(`${Url}pagoC2P`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
    
}

export const PayCash = (moneda, urlReturn, idEvento, content, fecha, hora, callback)=>{
    let data = JSON.parse(sessionStorage.getItem("ContentData"))

    let Content = {
        uid: data.U_ID,
        eid: data.E_ID,
        nombre: content.nombre,
        correo: content.correo,
        dni: content.identificacion,
        tlf: content.tlf,
        urlReturn: urlReturn,
        idEvento: idEvento,
        fecha: fecha,
        hora: hora,
    }

    Post(`${Url}payCash`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data);
    });
    
}

let clearNumber = (value = "") => {
    return value.replace(/\D+/g, "");
}

export const formatCreditCardNumber = (value) => {
    if (!value) {
      return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;
  
    switch (issuer) {
      case "amex":
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10
        )} ${clearValue.slice(10, 15)}`;
        break;
      case "dinersclub":
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10
        )} ${clearValue.slice(10, 14)}`;
        break;
      default:
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          8
        )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`;
        break;
    }
  
    return nextValue.trim();
}

export const formatExpirationDate = (value) => {
    const clearValue = clearNumber(value);
  
    if (clearValue.length >= 3) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }
  
    return clearValue;
}

export const formatCVC = (value, prevValue, allValues = {}) => {
    const clearValue = clearNumber(value);
    let maxLength = 3;

    if (allValues.number) {
        const issuer = Payment.fns.cardType(allValues.number);
        maxLength = issuer === "amex" ? 4 : 3;
    }

    return clearValue.slice(0, maxLength);
}

export const GenerarTicketPdf = (idTicketVendido, idEvento, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idTicketVendido: idTicketVendido,
        idEvento: idEvento
    }

    PostPdf(`${Url}generarTicketPdf`, Content, (resp) => {
        callback(resp)
    });
}

export const GenerarTicketPdfClient = (tipo, idFactura, dia, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idFactura: idFactura,
        tipo: tipo,
        fecha: dia
    }

    PostPdf(`${Url}generarTicketPdfClient`, Content, (resp) => {
        callback(resp)
    });
}

export const SendTicket = (idTicketVendido, idEvento, email, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idTicketVendido: idTicketVendido,
        idEvento: idEvento,
        email: email
    }

    Post(`${Url}sendTicket`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const searchTicketSold = (idEvento, idFuncion, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idFuncion: idFuncion,
        idEvento: idEvento
    }

    Post(`${Url}searchTicketSold`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const generatePdfTicketSold = (idEvento, idFuncion, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idFuncion: idFuncion,
        idEvento: idEvento
    }

    PostPdf(`${Url}generatePdfTicketSold`, Content, (resp) => {
        callback(resp)
    });
}

export const SearchTicketSoldTaq = (idEvento, idFuncion, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idFuncion: idFuncion,
        idEvento: idEvento
    }

    Post(`${Url}searchTicketSoldTaq`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const AddNewArtist = (nombre, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        nombre: nombre
    }

    Post(`${Url}addNewArtist`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const MisCompras = (callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID
    }

    Post(`${Url}misCompras`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const ChkTickets = (callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID
    }

    Post(`${Url}chkTickets`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const AprobarTransaccion = (token, imgName, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        tokenId: token,
        imgName: imgName
    }

    Post(`${Url}aprobarTransaccion`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const NoAprobarTransaccion = (token, imgName, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        tokenId: token,
        imgName: imgName
    }

    Post(`${Url}noAprobarTransaccion`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const SearchEventsCat = (categ, maxSearch, pagAct, callback) => {
    let Content = {
        categoria: categ,
        maxSearch: maxSearch,
        pagActiva: pagAct
    }

    Post(`${Url}searchEventsCat`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const SearchFunctionsProduct = (evento, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        evento: evento
    }

    Post(`${Url}searchFunctionsProduct`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const SaveImg = (img1, idEvento, imgSrc, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));
    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        imagen: img1,
        idEvento: idEvento,
        imgSrc: imgSrc
    }

    Post(`${Url}saveImg`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const SearchPlano = (idEvento, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));
    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idEvento: idEvento
    }

    Post(`${Url}searchPlano`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const sendMail = (content,  callback) => {

    let Content = {
        email: content.mail,
        nombre: content.name,
        detalleEvento: JSON.stringify(content.detalleEvento),
        fecha: content.fecha,
        hora: content.hora,
        dataTransaccion: JSON.stringify(content.dataTransaction),
        infoTickets: JSON.stringify(content.infoTickets),
        detallePago: JSON.stringify(content.detallePago),
        monedaPred: JSON.stringify(content.detallePago.moneda),
        config: JSON.stringify(content.detallePago.config),
        metodo: content.metodo
    }


    Post(`${Url}sendMail`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
};

export const showTaquilla = (callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
    }

    Post(`${Url}showTaquilla`, Content, (resp) =>{
        let data = JSON.parse(resp)
        callback(data)
    })
}


export const saveVerificadores = (content, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));
    let Content = {        
        eid: data.E_ID,
        uid: data.U_ID,
        evento: content.evento,
        verificadores: JSON.stringify(content.verificadores),
    }

    Post(`${Url}saveVerificador`, Content, (resp) =>{
        let data = JSON.parse(resp)
        callback(data)
    })
}

export const listSelectedVerificadores = (evento, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));
    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        evento: evento
    }

    Post(`${Url}listSelectedVerificadores`, Content, (resp) =>{
        let data = JSON.parse(resp)
        callback(data)
    })
}

export const deletSelectedVerificador = (content, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));
    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        verificador: content.id_verificador,
        evento: content.id_evento
    }

    Post(`${Url}deleteVerificador`, Content, (resp) =>{
        let data = JSON.parse(resp)
        callback(data)    
    })
}

export const SearchAllTicketSold = (type, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));
    let Content = {
        type: type,
        eid: data.E_ID,
        uid: data.U_ID,
    }

    Post(`${Url}SearchAllTicketSold`, Content, (resp) =>{
        let data = JSON.parse(resp)
        callback(data)    
    })
}

