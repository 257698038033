/*eslint-disable*/
import React from "react";
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

import logo from "assets/img/logos/logo_1_b.png";
import twitter from "assets/img/logos/twitter.png";
class CardsFooter extends React.Component {
  render() {
    return (
      <>
        <footer style = {{backgroundColor: "#2d2d2d", padding: 10 }}>
          <Container style = {{paddingTop: 20}}>
            <Row className="row-grid align-items-center">
              <Col lg="6">
                <h3 style = {{color: "#fff"}}>
                  <img src = {logo} alt = "logo" height = "auto" width = "200px" />
                </h3>
                <span className="mb-0 font-weight-light" style = {{color: "#fff", fontSize:15}}>
                Contáctanos si necesitas apoyo: <a href="mailto:atencionalcliente@taquillaenlinea.com"  style={{textDecoration:'underline', color:'white'}}>atencionalcliente@taquillaenlinea.com</a>
                </span><br />
                              </Col>
              <Col className="text-lg-right btn-wrapper" lg="6">
                <div style = {{marginBottom: 20}}>
                  <small className="text-light">Síguenos en las Redes Sociales: </small>
                </div>
                <Button className="btn-icon-only rounded-circle" color="twitter" href="https://x.com/taquillaenlinea?s=11&t=RWt5wtsxjjj23laWf_iQPA" target="_blank" id="tooltip475038074">
                <img src={twitter} className= "w-100" alt="twitter" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip475038074">
                  Síguenos en Twitter
                </UncontrolledTooltip>
                <Button className="btn-icon-only rounded-circle ml-1" color="twitter" href="https://www.facebook.com/taquillaenlinea" target="_blank" id="tooltip837440414">
                  <span className="btn-inner--icon">
                    <i className="fa fa-facebook-square" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip837440414">
                  Síguenos en Facebook
                </UncontrolledTooltip>
                <Button className="btn-icon-only rounded-circle ml-1" color="twitter" href="https://instagram.com/taquillaenlinea?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr" target="_blank" id="tooltip829810202">
                  <span className="btn-inner--icon">
                    <i className="fa fa-instagram" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip829810202" >
                  Sígenos en Instagram
                </UncontrolledTooltip>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-md-between">
              <Col md="12" align = "center">
                <div className="mt-1"><span className="mb-0 font-weight-light" style = {{color: "#fff", fontSize:15}}>
                ¿Desea vender su evento con nosotros? Regístrate y crea tú mismo tu evento. También podemos apoyarte si así lo deseas: escríbenos a</span> <a href="mailto:eventos@taquillaenlinea.com" style={{textDecoration:'underline', color:'white'}}>eventos@taquillaenlinea.com</a> </div>
                <div className="copyright" style = {{color: "#fff", fontSize: 10, padding: 5}}>
                  ©{" "}
                  <span>
                   Copyright 2023 Taquilla en Línea. Todos los derechos reservados
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default CardsFooter;
