import React, {useEffect, useState} from "react";

const Mesa = (props) => {
    const [sillasDisabled, setSillasDisabled]   = useState([]);
    const [control, setControl]   = useState(false);

    useEffect(() => {
        const filter = (props.sillasDisabled).filter(option => option.fila === props.data.letraAlt && option.columna === props.data.id)
        setSillasDisabled(filter)
        props.addSilla(filter)
    }, []);

    let sillas = (clase, posicion, lugar) => {
        let Content = [];
        for (let i = 0; i < posicion; i++) Content.push({
            num: i,
            data: `${props.data.letraAlt}${props.data.id}${lugar}${i + 1}`
        });

        return (
            Content.map(option => {
                let search = sillasDisabled.find(i => i.data === option.data);

                if (search !== undefined){
                    return <span key = {option.num} detail = {control} onClick = {() => addSilla(`${props.data.letraAlt}${props.data.id}${lugar}${option.num  + 1}`)} title = {`Mesa ${props.data.letraAlt}${props.data.id} - Silla ${lugar} ${option.num  + 1} DESACTIVADA`} className = {clase}></span>
                }else{
                    return <span key = {option.num} detail = {control} onClick = {() => delSilla(props.data.letraAlt, props.data.id, lugar, option.num  + 1, `${props.data.letraAlt}${props.data.id}${lugar}${option.num  + 1}`)} title = {`Mesa ${props.data.letraAlt}${props.data.id} - Silla ${lugar} ${option.num  + 1}`} className = {clase} style = {props.customStyles}></span>
                }
            })
        )
    }

    let sillasDis = (clase, posicion, lugar) => {
        let Content = [];
        for (let i = 0; i < posicion; i++) Content.push(i);

        return (
            Content.map(option => (
                <span key = {option} className = {clase}></span>
            ))
        )
    }

    let addSilla = (data) => {
        if (props.disabled === true) return null;

        let Content = [];

        sillasDisabled.forEach(option => {
            if (option.data !== data) Content.push(option)
        })

        props.delSilla(Content)
        setSillasDisabled(Content)
        setControl(!control)
    }

    let delSilla = (fila, columna, lugar, numero, data) => {
        if (props.disabled === true) return null;

        let Content = sillasDisabled;

        Content.push({
            data: data,
            fila: fila,
            columna: columna,
            lugar: lugar,
            numero: numero
        })

        props.addSilla(Content)
        setSillasDisabled(Content)
        setControl(!control)
    }

    switch (true){
        case(!isNaN(Number(props.data.id)) === true && props.data.disabledInd === false):
            return (
                <span className = "mesa-orden">
                    <div style = {{paddingLeft: 10, paddingRight: 10}} align = "center">
                        {sillas("silla-mesa-h-small mesa-default", props.confMesas.superior, "Superior")} 
                    </div>
                    <div>
                        <table>
                            <tr>
                                <td>{sillas("silla-mesa-v-small mesa-default", props.confMesas.izquierda, "Izquierda")}</td>
                                <td className = "mesa-default" onClick = {() => {props.onClick()}} width = "80%" style = {{background: "#f0f0f0", padding: 5, borderRadius: "4px 4px", minWidth: 34}} align = "center" title = {`Mesa ${props.data.letraAlt}${props.data.id}`}>{`${props.data.letraAlt}${props.data.id}`}</td>
                                <td>{sillas("silla-mesa-v-small mesa-default", props.confMesas.derecha, "Derecha")}</td>
                            </tr>
                        </table>
                    </div>
                    <div style = {{paddingLeft: 10, paddingRight: 10}} align = "center">
                        {sillas("silla-mesa-h-small mesa-default", props.confMesas.inferior, "Inferior")} 
                    </div>
                </span>
            )
        case(!isNaN(Number(props.data.id)) === false):
            return (
                <span className = "mesa-orden">
                    <div style = {{paddingLeft: 10, paddingRight: 10}} align = "center">
                        {sillasDis("silla-mesa-h-small mesa-default", props.confMesas.superior, "Superior")} 
                    </div>
                    <div>
                        <table>
                            <tr>
                                <td>{sillasDis("silla-mesa-v-small", props.confMesas.izquierda, "Izquierda")}</td>
                                <td width = "80%" style = {{background: "#ffffff", padding: 5, borderRadius: "4px 4px", minWidth: 34}} align = "center"></td>
                                <td>{sillasDis("silla-mesa-v-small", props.confMesas.derecha, "Derecha")}</td>
                            </tr>
                        </table>
                    </div>
                    <div style = {{paddingLeft: 10, paddingRight: 10}} align = "center">
                        {sillasDis("silla-mesa-h-small mesa-default", props.confMesas.inferior, "Inferior")} 
                    </div>
                </span>
            )
        case(props.data.disabledInd === true):
        return (
            <span className = "mesa-orden">
                <div style = {{paddingLeft: 10, paddingRight: 10}} align = "center">
                    {sillasDis("silla-mesa-h-small mesa-default", props.confMesas.superior, "Superior")} 
                </div>
                <div>
                    <table>
                        <tr>
                            <td>{sillasDis("silla-mesa-v-small mesa-default", props.confMesas.izquierda, "Izquierda")}</td>
                            <td onClick = {() => {props.onClick()}} width = "80%" style = {{background: "#ffffff", padding: 5, borderRadius: "4px 4px", minWidth: 34, cursor: "pointer"}} align = "center" title = {`La mesa ${props.data.letraAlt}${props.data.id} está desactivada`}></td>
                            <td>{sillasDis("silla-mesa-v-small mesa-default", props.confMesas.derecha, "Derecha")}</td>
                        </tr>
                    </table>
                </div>
                <div style = {{paddingLeft: 10, paddingRight: 10}} align = "center">
                    {sillasDis("silla-mesa-h-small mesa-default", props.confMesas.inferior, "Inferior")} 
                </div>
            </span>
        )
        default: return null;
    }
}

export default Mesa;