import { Post } from "./Generales";
import { Url } from "./Url";

export const SearchEventsTaquilla = (callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID
    }

    Post(`${Url}searchEventsTaquilla`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const SearchFunctionsTaquilla = (idEvento, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idEvento: idEvento
    }

    Post(`${Url}searchFunctionsTaquilla`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const VerifyTicket = (idTicketVendido, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idTicketVendido: idTicketVendido
    }

    Post(`${Url}verifyTicket`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const VerifyTicketQr = (contentSplit, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));
    let tipo = contentSplit[0];
    let code = contentSplit[1].split("=");
    let tipoCode = code[0];
    let codeValue = code[1];
    
    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        tipo: tipo,
        tipoCode: tipoCode,
        codeValue: codeValue
    }
    
    Post(`${Url}verifyTicketQr`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });

}

export const VerifyTicketUrl = (tipoSplit, contentSplit, callback) => {
    let Content = {
        tipo: tipoSplit,
        codeValue: contentSplit
    }

    Post(`${Url}verifyTicketUrl`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}
