import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";

import { Button, Card, CardBody, CardHeader, Row, Col, Table } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faCircleNotch, faHome, faFilm, faTimes } from '@fortawesome/free-solid-svg-icons';

import NavbarI from "components/Navbars/NavbarI.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import Sidebar from '../dashboard/Sidebar';

import SuccessModal from "components/Modals/SuccessModal";

import { SearchEvents } from "functions/EventosFunctions";
import EventoInd from "./EventoInd";

const MainEventos = (props) => {
    const [eventos, setEventos]             = useState([]);
    const [eventPreload, setEventPreload]   = useState({icon: faCircleNotch, spin: true, text: "Buscando los Eventos Registrados"});
    const [openModalOk, setOpenModalOk]     = useState(false)
    const [modalOkTitle, setModalOkTitle]   = useState("")
    const [modalMess, setMmodalMess]        = useState("")
    const [reloadIndicator, setReloadIndicator] = useState(0);

    useEffect(() => {
        let getByStatus = [0,1,2,3,4];
        SearchEvents(getByStatus, (resp) => {
            if (resp.data.length === 0){
                setEventPreload({icon: faTimes, spin: false, text: "No Existen Eventos Registrados"})
            } else {
                setEventos(resp.data);
            }
        });
        // Lógica adicional si es necesario
    }, [reloadIndicator]); // Dependencia en reloadIndicator

    // Función para disparar la recarga
    const triggerReload = () => {
        setReloadIndicator(prev => prev + 1);
    };

    let upd = (open, title, mess, data) => {
        setEventos(data);
        if (data.length === 0) setEventPreload({icon: faTimes, spin: false, text: "No Existen Eventos Registrados"});
        setOpenModalOk(open)
        setModalOkTitle(title)
        setMmodalMess(mess)
    }
    
    let contentEvents = () => {
        if (eventos?.length === 0) return  (
            <tr>
                <td align = "center" style = {{padding: 10}} colSpan = {7}><FontAwesomeIcon icon = {eventPreload.icon} spin = {eventPreload.spin}/> <span>{eventPreload.text}</span></td>
            </tr>
        )

        return (eventos?.map(option => (
            <EventoInd key = {option.id} data = {option} upd = {(open, title, mess, data) => {upd(open, title, mess, data)}} triggerReload={triggerReload} updEvent = {(data) => {setEventos(data)}}/>
        )))
    }

    return (
        <>
            <NavbarI />
            <main>
                <section className="section section-shaped section-lg">
                    <div className = "d-block d-sm-block d-md-block" align = "right" style = {{paddingRight: 15}}>
                        <small class = "bread-crumb-custom"><Link to = "/dashboard/home"><FontAwesomeIcon icon = {faHome}/></Link> | <FontAwesomeIcon icon = {faFilm}/> Eventos</small>
                    </div>
                    <Row>
                        <Col md = {3} className = "d-none d-sm-none d-md-block">
                            <Sidebar active = "Eventos"/>
                        </Col>
                        <Col md = {9} className = "custom-p-32">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col md = {6}>
                                            <h5>Eventos</h5>
                                            <div className = "text-muted" style = {{fontSize: 10, marginTop: -10}}>Selecciona agregar un evento o en acciones</div>
                                        </Col>
                                        <Col md = {6} align = "right">
                                            <Link to = "/dashboard/eventos/add_evento">
                                                <Button color = "default" type = "button">
                                                    <small><FontAwesomeIcon icon = {faPlusCircle}/> Agregar evento</small>
                                                </Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className = "custom-mt-10 table-responsive"  style = {{overflowY: "auto", overflowX:"auto", height: "50vh"}}>
                                        <Table className = "mb-0" bordered hover  style = {{fontSize: 10 }}>
                                            <tbody>
                                                <tr>
                                                    <th></th>
                                                    <th width = "30%">Nombre</th>
                                                    <th width = "20%">Lugar</th>
                                                    <th width = "10%" align = "center">Tipo</th>
                                                    <th width = "10%" align = "center">Categorías</th>
                                                    <th width = "10%" align = "center">Artístas</th>
                                                    <th width = "10%" align = "center">Status</th>
                                                    <th width = "10%" align = "center">Acciones</th>
                                                </tr>
                                                {contentEvents()}
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </section>
            </main>
            <SuccessModal isOpen = {openModalOk} close = {() => {setOpenModalOk(false)}} content = {modalMess} contentTitle = {modalOkTitle}/>
            <CardsFooter />
        </>
    )
}

export default MainEventos;
