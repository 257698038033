import React, {  } from 'react';
import { Row, Col } from "reactstrap";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const EventosPreload = (props) => {

    return (
        <Row>
            <Col md = {12}>
                <Skeleton height = {140}/>
            </Col>
            <Col md = {9}>
                <Skeleton count = {2}/>
            </Col>
            <Col md = {3}>
                <Skeleton count = {2}/>
            </Col>
        </Row>
    )
}

export default EventosPreload;