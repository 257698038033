import React, { useEffect, useState } from "react";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "reactstrap";

import ErrorModal2 from "../../components/Modals/ErrorModal2";
import { DelButacasUser } from "functions/EventosFunctions";
import { useRef } from "react";
let timer = "";

const TimerTicket = (props) => {
    const [min, setMin] = useState ("--");
    const [sec, setSec] = useState ("--");
    const [minuto2, setMinuto2] = useState (null);
    const [segundo2, setSegundo2] = useState (null);
    const [errors, setErrors]       = useState("");
    const [openModal, setOpenModal] = useState(false);

    const timerRef = useRef(null);

    useEffect(() => {
        if (!props.control) return;

        let ContentData = JSON.parse(sessionStorage.getItem("ContentDataT"));
        let segundo = ContentData ? ContentData.D002 : null;
        let minuto = ContentData ? ContentData.D001 : null;

        if (minuto === null && segundo === null){
            clearInterval(timerRef.current);
            sessionStorage.removeItem("ContentDataT");
            sessionStorage.removeItem("ContentDataCart");
            setMinuto2(null);
            setSegundo2(null);
        }

        timerRef.current = setInterval(() => {
            if (minuto === null && segundo === null){
                clearInterval(timerRef.current);
                sessionStorage.removeItem("ContentDataT");
                sessionStorage.removeItem("ContentDataCart");
            } else if (minuto <= 0 && segundo <= 0){
                clearInterval(timerRef.current);
                sessionStorage.removeItem("ContentDataT");
                sessionStorage.removeItem("ContentDataCart");

                DelButacasUser(() => {
                    setErrors("<span><i class = 'fa fa-clock-o'/> <span>El tiempo reservado para los asientos seleccionados ha expirado. Vuelve a intentarlo</span></span>");
                    setOpenModal(true);
                });
            } else {
                if (segundo === 0){
                    segundo = 59;
                    minuto = minuto - 1;
                } else {
                    segundo = segundo - 1;
                }

                let ContentD = {
                    D001: minuto,
                    D002: segundo
                }

                sessionStorage.setItem("ContentDataT", JSON.stringify(ContentD));

                let ContentData2 = JSON.parse(sessionStorage.getItem("ContentDataT"));
                if (ContentData2 !== null){
                    minuto = ContentData2.D001;
                    segundo = ContentData2.D002;

                    setMin(minuto < 10 ? `0${minuto}` : minuto);
                    setSec(segundo < 10 ? `0${segundo}` : segundo);

                    setMinuto2(minuto);
                    setSegundo2(segundo);
                } else {
                    clearInterval(timerRef.current);
                }
            }
        }, 1000);

        return () => clearInterval(timerRef.current);
    }, [props.control]);

    useEffect(() => {
        if (!props.control && timerRef.current) {
            clearInterval(timerRef.current);
        }
    }, [props.control]);
    
    if (minuto2 === null && segundo2 === null) return null;

    return (
        (props.control === true) ? <div title = "Tiempo restante para confirmar compra" className = "custom-border_h custom-mt-10 custom-background-ccc">
        <Row>
            <Col md = {6}>
                <small><FontAwesomeIcon icon = {faClock} /> Restante</small>
            </Col>
            <Col md = {6} align = "right">
                <span className = "span-timer">{min}</span><span style = {{fontSize: 18, color: "#2b2b2b"}}> : </span><span className = "span-timer">{sec}</span>
            </Col>
        </Row>
        <ErrorModal2 isOpen = {openModal} close = {() => {
            setOpenModal(false);
            document.location.reload(true);
        }} mess = {errors} />
    </div> : null
    );
};

export default TimerTicket;