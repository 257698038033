let dominio = document.domain;
let protocolo = window.location.protocol;
let api = '';
let tmp = '';
let img = '';
let ckeditor = '';
let puerto = '';
let banesco = '';

(dominio === "localhost") ? puerto = ':3000' : puerto = '';
(dominio === "localhost") ? api = ':8000/api/' : api = '/Back-taquilla-en-linea/public/index.php/api/';
(dominio === "localhost") ? tmp = ':8000/tmp/' : tmp = '/Back-taquilla-en-linea/public/tmp/';
(dominio === "localhost") ? img = ':8000/images/' : img = '/Back-taquilla-en-linea/public/images/';
(dominio === "localhost") ? banesco = '/prueba_banesco/' : banesco = '/Back-taquilla-en-linea/public/index.php/prueba_banesco/';
(dominio === "localhost") ? ckeditor = '/Back-taquilla-en-linea/public/ckeditor/' : ckeditor = '/Back-taquilla-en-linea/public/index.php/ckeditor/';

export const Url = `${protocolo}//${dominio}${api}`;
export const UrlTmp = `${protocolo}//${dominio}${tmp}`;
export const UrlImg = `${protocolo}//${dominio}${img}`;
export const UrlEditor = `${protocolo}//${dominio}${ckeditor}`;
export const UrlMain = `${protocolo}//${dominio}${puerto}`;
export const UrlBanesco = `${protocolo}//${dominio}${banesco}`;


/* export const Url = 'http://192.168.0.108/backend_taquilla/public/api/'
export const UrlImg = 'http://192.168.0.108/backend_taquilla/public/images/'
export const UrlEditor = 'http://192.168.0.108/backend_taquilla/public/ckeditor/'
export const UrlMain = 'http://192.168.0.108:3000' */