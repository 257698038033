import React, {useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import { Card, CardBody, CardHeader, Container, Row, Col } from "reactstrap";

import NavbarI from "components/Navbars/NavbarI.js";
import CardsFooter from "components/Footers/CardsFooter.js";

import {Administrador, Productor, Cliente, Taquilla, Verificador} from "components/Routes/MainRoutes";
import PreguntasFrecuentes from "views/IndexSections/FAQ";

const Dashboard = (props) => {
    const [ContentData, setContentData] = useState(JSON.parse(sessionStorage.getItem("ContentData")))

    useEffect(() => {
        setContentData(JSON.parse(sessionStorage.getItem("ContentData")));
    }, []);

    const verifyCart = ()=>{
        const cart = ['ContentDataEvent', 'ContentDataButacas', 'ContentDataT', 'ContentDataCart'];
    
        cart.forEach(c => {
            if(sessionStorage.getItem(c)){
            sessionStorage.removeItem(c);
            }
        });
        }


    let content = () => {
        if (ContentData === null) return null;

        switch(ContentData.U_TYPE){
            case 1: return (
                <Row>
                    {Administrador.map(option => (
                        <Col md = {3} key = {option.id} style={{padding: 10}} align = "center">
                            <Link to = {option.path}>
                                <Card className="card-lift--hover border-1 custom-card-up" inverse>
                                    <CardHeader align = "center" className = "custom-card-header">
                                        <i className = {option.icon} style = {{fontSize: 20}}></i>
                                    </CardHeader>
                                    <CardBody>
                                        <div style = {{paddingTop: 10, color: "#424242"}}>{option.name}</div>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            )
            case 2: return (
                <Row>
                    {Productor.map(option => (
                        <Col md = {3} key = {option.id} style={{padding: 10}} align = "center">
                            <Link to = {option.path}>
                                <Card className="card-lift--hover border-1 custom-card-up" inverse>
                                    <CardHeader align = "center" className = "custom-card-header">
                                        <i className = {option.icon} style = {{fontSize: 20}}></i>
                                    </CardHeader>
                                    <CardBody>
                                        <div style = {{paddingTop: 10, color: "#424242"}}>{option.name}</div>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            )
            case 3:
            verifyCart()           
            return (
            <div>
                <Row>
                    {Cliente.map(option => (
                        <Col md = {3} key = {option.id} style={{padding: 10}} align = "center">
                            <Link to = {option.path} onClick={()=>verifyCart()}>
                                <Card className="card-lift--hover border-1 custom-card-up" inverse>
                                    <CardHeader align = "center" className = "custom-card-header">
                                        <i className = {option.icon} style = {{fontSize: 20}}></i>
                                    </CardHeader>
                                    <CardBody>
                                        <div style = {{paddingTop: 10, color: option.color}}>{option.name}</div>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            </div>
            )
            case 4:
                verifyCart()           
                return (
                <Row>
                    {Taquilla.map(option => (
                        <Col md = {3} key = {option.id} style={{padding: 10}} align = "center">
                            <Link to = {option.path}>
                                <Card className="card-lift--hover border-1 custom-card-up" inverse>
                                    <CardHeader align = "center" className = "custom-card-header">
                                        <i className = {option.icon} style = {{fontSize: 20}}></i>
                                    </CardHeader>
                                    <CardBody>
                                        <div style = {{paddingTop: 10, color: "#424242"}}>{option.name}</div>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            )
            case 5: return (
                <Row>
                    {Verificador.map(option => (
                        <Col md = {3} key = {option.id} style={{padding: 10}} align = "center">
                            <Link to = {option.path}>
                                <Card className="card-lift--hover border-1 custom-card-up" inverse>
                                    <CardHeader align = "center" className = "custom-card-header">
                                        <i className = {option.icon} style = {{fontSize: 20}}></i>
                                    </CardHeader>
                                    <CardBody>
                                        <div style = {{paddingTop: 10, color: "#424242"}}>{option.name}</div>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            )
            default: return null
        }
    }

    switch(true){
        case (ContentData === null): return (<Redirect to = "/"/>);
        default: return (
            <>
                <NavbarI />
                <main className="mb-5">
                    <section className="section section-shaped section-lg">
                        <Container>
                            <Card className="shadow shadow-lg--hover" style = {{minHeight: "40vh !important", background: "#ffffff !important"}}>
                                <CardBody>
                                    <div className ="alert bg-gradient-info" align = "left"><i className = "fa fa-check-circle"></i> Bienvenid@ {ContentData.U_NAME}</div>
                                    {content()}
                                </CardBody>
                            </Card>
                        </Container>
                    </section>
                </main>
                <CardsFooter />
            </>
        )
    }
}

export default Dashboard;
