import React, { useEffect, useState } from 'react';

import { Modal } from "reactstrap";

const EventoModal = (props) => {
    const [open, setOpen] = useState(props.isOpen);

    useEffect(() => {
        setOpen(props.isOpen)
    }, [props]);


    return(
        <Modal className = "modal-dialog-centered" size = {props.size} isOpen = {open} toggle = {() => props.close()}>
            <div className="modal-header">
                <h6 className="modal-title text-primary" id="modal-title-default">
                  <strong>{props.titulo}</strong>
                </h6>
                <button
                  aria-label = "Close"
                  className = "close"
                  data-dismiss = "modal"
                  type = "button"
                  onClick = {() => props.close()}
                >
                  <span aria-hidden = {true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                {props.contenido}
            </div>
        </Modal>
    )
}

export default EventoModal;