import React, {useEffect, useState} from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Index from "views/Index.js";
import Login from "views/site/Login.js";
import Register from "views/site/Register.js";
import Evento from "../components/Eventos/Evento";
import MainDashboard from './dashboard/MainDashboard';

import {ChkSec} from '../functions/UserFunctions';
import ResetPass from './site/ResetPass';
import PayEvento from 'components/Eventos/PayEvent';
import PayPalEvento from 'components/Eventos/PayPalEvento';
import CreditCardEvento from 'components/Eventos/CreditCardEvento';
import ZelleEvento from 'components/Eventos/ZelleEvento';
import VerifyTickets from './Verificacion/VerifyTickets';
import CategoriaEvento from './Eventos/CategoriaEvento';
import PagoMovilEvento from "components/Eventos/PagoMovilEvento";
import TransferEvento from 'components/Eventos/TransferEvento';
import CashEvento from "components/Eventos/CashEvento";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import MultiPagoEvento from 'components/Eventos/MultiPagoEvento';


const Main = (props) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [ContentData, setContentData] = useState(JSON.parse(sessionStorage.getItem("ContentData")))

    useEffect(() => {
        setContentData(JSON.parse(sessionStorage.getItem("ContentData")));
    }, []);

    let check = () => {
        if (ContentData === null) return null;

        if (ContentData !== null && ContentData.U_ID !== undefined && ContentData.E_ID !== undefined) {
            ChkSec (ContentData.U_ID, ContentData.E_ID, (resp) => {
                if (resp.status === "error"){
                    sessionStorage.removeItem("ContentData")
                    sessionStorage.removeItem("ContentDataEvent")
                    sessionStorage.removeItem("ContentDataButacas")
                    sessionStorage.removeItem("ContentDataPrecioZona")
                }else{
                    return (<Redirect to = "/dashboard"/>)
                }
            })
        }
    }

    let defaultRoutes = () => (
        <GoogleReCaptchaProvider reCaptchaKey="6Lfo2G0oAAAAAIpjrpDVrEXdN2qMHjUhzE0wSTFM">
            {check()}
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact render={props => <Index {...props} />} />
                    <Route path="/login" exact render={props => <Login {...props} />} />
                    <Route path="/event/:id_event" exact render={props => <Evento {...props} />} />
                    <Route path="/event/comprar/:id_event" exact render={props => <PayEvento {...props} />} />
                    <Route path="/event/confirm_compra_paypal/:id_event" exact render={props => <PayPalEvento {...props} />} />
                    <Route path="/event/compra_credit_card/:id_event" exact render={props => <CreditCardEvento {...props} />} />
                    <Route path="/event/compra_pago_movil/:id_event" exact render={props => <PagoMovilEvento {...props} />} />
                    <Route path="/event/compra_zelle/:id_event" exact render={props => <ZelleEvento {...props} />} />
                    <Route path="/event/compra_cash/:id_event" exact render={props => <CashEvento {...props} />} />
                    <Route path="/event/compra_transfer/:id_event" exact render={props => <TransferEvento {...props} />} />
                    <Route path="/event/compra_multipago/:id_event" exact render={props => <MultiPagoEvento {...props} />} />


                    <Route path="/register" exact render={props => <Register {...props} />} />
                    <Route path="/dashboard" exact render={props => <MainDashboard {...props} />} />
                    <Route path = "/dashboard/home" exact render = {props => <MainDashboard {...props} />} />
                    <Route path="/reset_password" exact render={props => <ResetPass {...props} />} />
                    <Route path="/verify_tickets/" exact render={props => <VerifyTickets {...props} />} />
                    <Route path="/verify_ticket/" exact render={props => <VerifyTickets {...props} />} />
                    <Route path="/categoria_evento/:cetegoria" exact render={props => <CategoriaEvento {...props} />} />

                    <Redirect to="/" />
                </Switch>
            </BrowserRouter>
            <ToastContainer autoClose = {4000} transition = {Flip} position="top-right" style = {{fontSize: 16}}/>
        </GoogleReCaptchaProvider>
    )

    switch(true){
        case (ContentData === null): return defaultRoutes();
        case (ContentData.U_TYPE === undefined): return defaultRoutes();
        case (ContentData.U_TYPE === 1 || ContentData.U_TYPE === 2 || ContentData.U_TYPE === 3): return (
            <GoogleReCaptchaProvider reCaptchaKey="6Lfo2G0oAAAAAIpjrpDVrEXdN2qMHjUhzE0wSTFM">
                {check()}
                <BrowserRouter>
                    <Switch>
                        <Route path="/" exact render={props => <Index {...props} />} />
                        <Route path="/login" exact render={props => <Login {...props} />} />
                        <Route path="/event/:id_event" exact render={props => <Evento {...props} />} />
                        <Route path="/event/comprar/:id_event" exact render={props => <PayEvento {...props} />} />
                        <Route path="/event/confirm_compra_paypal/:id_event" exact render={props => <PayPalEvento {...props} />} />
                        <Route path="/event/compra_credit_card/:id_event" exact render={props => <CreditCardEvento {...props} />} />
                        <Route path="/event/compra_pago_movil/:id_event" exact render={props => <PagoMovilEvento {...props} />} />
                        <Route path="/event/compra_zelle/:id_event" exact render={props => <ZelleEvento {...props} />} />
                        <Route path="/event/compra_cash/:id_event" exact render={props => <CashEvento {...props} />} />
                        <Route path="/event/compra_transfer/:id_event" exact render={props => <TransferEvento {...props} />} />
                        <Route path="/event/compra_multipago/:id_event" exact render={props => <MultiPagoEvento {...props} />} />

                        <Route path="/register" exact render={props => <Register {...props} />} />
                        <Route path="/dashboard" exact render={props => <MainDashboard {...props} />} />
                        <Route path = "/dashboard/home" exact render = {props => <MainDashboard {...props} />} />
                        <Route path="/reset_password" exact render={props => <ResetPass {...props} />} />
                        <Route path="/verify_tickets/" exact render={props => <VerifyTickets {...props} />} />
                        <Route path="/verify_ticket/" exact render={props => <VerifyTickets {...props} />} />
                        <Route path="/categoria_evento/:cetegoria" exact render={props => <CategoriaEvento {...props} />} />
                        
                        <Redirect to="/" />
                    </Switch>
                </BrowserRouter>
                <ToastContainer autoClose = {4000} transition = {Flip} position="top-right" style = {{fontSize: 16}}/>
            </GoogleReCaptchaProvider>
        )
        default: return defaultRoutes();
    } 
}

export default Main;