import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Row,
  Col,
  Table,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  CardTitle,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faCircleNotch,
  faTimesCircle,
  faHome,
  faCaretRight,
  faFilm,
  faLink,
  faCaretUp,
  faCaretLeft,
  faSave,
  faClock,
  faCircle,
  faMapMarkerAlt,
  faTimes,
  faSquare,
  faMap,
  faCheck,
  faPlus,
  faArrowCircleDown,
  faExclamation,
  faTrash,
  // Quiero una x
  faImage
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { CKEditor } from "ckeditor4-react";
import Select from "react-select";
import Compressor from "compressorjs";
import ImageMapper from "react-image-mapper";

import NavbarI from "components/Navbars/NavbarI.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import Sidebar from "../dashboard/Sidebar";
import CustomMap from "components/Maps/CustomMap";
import ConfirmModal from "components/Modals/ConfirmModal";
import ErroresList from "components/Errores/ErroresList";
import SuccessModal from "components/Modals/SuccessModal";
import ContentModal from "components/Modals/ContentModal";

import { UrlEditor, UrlImg } from "../../functions/Url";
import { formatBytes } from "../../functions/Generales";

import Funciones from "./Funciones";
import ZonaInd from "./Zonaind";
import "assets/css/custom_style.css";
import AddSitio from "views/MisSitios/AddSitio";

import movie from "assets/img/icons/common/movie.svg";

import InputTime from "components/Timers/Datetime";

import {
  SearchGeneralEvent,
  AddEvent,
  AddNewArtist,
} from "functions/EventosFunctions";
import { AddEventDraft } from "functions/EventosFunctions";
/* import AddCustomAudit from "views/MisSitios/AddCustomAudit"; */

let dateM = new Date();
let ddM = dateM.getDate();
let mmM = dateM.getMonth() + 1;
let aaaaM = dateM.getFullYear();
let hM = dateM.getHours();
let mM = dateM.getMinutes();

if (hM < 10) hM = `0${hM}`;
if (mM < 10) mM = `0${mM}`;
if (ddM < 10) ddM = `0${ddM}`;
if (mmM < 10) mmM = `0${mmM}`;

let auditBuild = "";

const AddEvento = (props) => {
  const [botonSave, setBotonSave] = useState({
    icon: faSave,
    text: "Guardar evento",
    spin: false,
    disabled: false,
  });
  const [botonSaveDraft, setBotonSaveDraft] = useState({
    icon: faClock,
    text: "Guardar borrador",
    spin: false,
    disabled: false,
  });
  const [botonYes, setBotonYes] = useState({
    icon: faCheck,
    text: "Si",
    spin: false,
    disabled: false,
  });
  const [botonYesArt, setBotonYesArt] = useState({
    icon: faSave,
    text: "Guardar",
    spin: false,
    disabled: false,
  });
  const [nombre, setNombre] = useState("");
  const [url, setUrl] = useState("");
  const [selectPro, setSelectPro] = useState({ disabled: true, loading: true });
  const [proSelect, setProSelect] = useState([]);
  const [productores, setProductores] = useState([]);
  const [selectOrg, setSelectOrg] = useState({ disabled: true, loading: true });
  const [orgSelect, setOrgSelect] = useState([]);
  const [organizadores, setOrganizadores] = useState([]);
  const [contentState, setContentState] = useState("");
  const [desde, setDesde] = useState(`${aaaaM}-${mmM}-${ddM}`);
  const [hasta, setHasta] = useState(`${aaaaM}-${mmM}-${ddM}`);
  const [horaIn, setHoraIn] = useState('12:00 AM');
  const [horaOut, setHoraOut] = useState('12:00 AM');
  const [horaApertura, setApertura] = useState('12:00 AM');

  const [diasEvents, setDiasEvents] = useState([]);
  const [control, setControl] = useState(false);
  const [file1, setFile1] = useState(`${UrlImg}events/default_img.jpg`);
  const [file2, setFile2] = useState(`${UrlImg}events/default_img.jpg`);
  const [item1, setItem1] = useState({ nombre: "", tipo: "", size: "" });
  const [item2, setItem2] = useState({ nombre: "", tipo: "", size: "" });
  const [imgSelect, setImgSelect] = useState(false);
  const [minAge, setMinAge] = useState("");
  const [tipoE, setTipoE] = useState([]);
  const [tipoESelect, setTipoESelect] = useState([]);
  const [tipoEop, setTipoEop] = useState({ disabled: true, loading: true });
  const [catOpt, setCatOpt] = useState({ disabled: true, loading: true });
  const [catSelect, setCatSelect] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [artOpt, setArtOpt] = useState({ disabled: true, loading: true });
  const [artSelect, setArtSelect] = useState([]);
  const [artistas, setArtistas] = useState([]);
  const [terminos, setTerminos] = useState("");
  const [sitesOpt, setSitesOpt] = useState({ disabled: true, loading: true });
  const [sitesSelect, setSitesSelect] = useState({
    value: "",
    label: "Selecciona un recinto",
    address: "",
    map: "",
  });
  const [sites, setSites] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [zonas2, setZonas2] = useState([]);
  const [zonaPreload, setZonaPreload] = useState({
    icon: faCaretUp,
    spin: false,
    text: "Selecciona primero un recinto y una función",
  });
  const [preloadSite, setPreloadSite] = useState({
    icon: faCaretLeft,
    text: "Selecciona un recinto para ver los detalles",
  });
  const [preloadAud, setPreloadAud] = useState({
    icon: faCircle,
    spin: false,
    text: "Selecciona un recinto para ver el auditorio",
  });
  const [emailTicket, setEmailTicket] = useState("");
  const [zonasCat, setZonasCat] = useState([]);
  const [zonasBuild, setZonasBuild] = useState([]);
  const [metodoOpt, setMetodoOpt] = useState({ disabled: true, loading: true });
  const [metSelect, setMetSelect] = useState([]);
  const [amountPay, setAmountPay] = useState("");
  const [metodos, setMetodos] = useState([]);
  const [monedaOpt, setMonedaOpt] = useState({ disabled: true, loading: true });
  const [monSelect, setMonSelect] = useState([]);
  const [monPSelect, setMonPSelect] = useState({
    value: "",
    label: "- Selecciona -",
  });
  const [monedas, setMonedas] = useState([]);
  const [metodosPay, setMetodosPay] = useState([]);
  const [openModalCon, setOpenModalCon] = useState(false);
  const [openModalConDraft, setOpenModalConDraft] = useState(false);
  const [openModalNewArt, setOpenModalNewArt] = useState(false);

  /* const [openModalCD, setOpenModalCD]     = useState(false) */
  const [errMess, setErrMess] = useState("");
  const [visibleErr, setVisibleErr] = useState("none");
  const [openModalOk, setOpenModalOk] = useState(false);
  const [modalOk, setModalOk] = useState("");
  const [modalMess, setMmodalMess] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [funciones, setFunciones] = useState([]);
  const [funcionSel, setFuncionSel] = useState({
    value: "",
    label: "- Selecciona -",
  });
  const [funcionesDis, setFuncionesDis] = useState(true);
  const [zonaTipo, setZonaTipo] = useState("");
  const [mapaA, setMapaA] = useState("");
  const [displayBtn, setDisplayBtn] = useState("none");
  const [newArtista, setNewArtista] = useState("");

  const [plano, setPlano] = useState("");
  const [zona, setZona] = useState("");
  const [precios, setPrecios] = useState("");
  const [grupo, setGrupo] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [nextId, setNextId] = useState(0);
  const [color, setColor] = useState("#000000");

  const [openModalContent, setOpenModalContent] = useState(false);
  const [modalOkTitle, setModalOkTitle] = useState("");
  const [dataSitios, setDataSitios] = useState([]);

  const [ubicacion, setUbicacion] = useState('');

  useEffect(() => {
    SearchGeneralEvent((resp) => {
      /* Sitios */
      let contentSites = [];
      resp.dataSites.forEach((i) => {
        contentSites.push({
          value: i.id,
          label: i.name,
          address: i.address,
          mapa: i.map,
        });
      });

      /* Productores */
      let contentProduct = [];
      resp.productores.forEach((i) => {
        contentProduct.push({
          value: i.id,
          label: `${i.user_data.name} ${i.user_data.last_name}`,
        });
      });

      /* Organizadores */
      let contentOrg = [];
      resp.organizadores.forEach((i) => {
        contentOrg.push({
          value: i.id,
          label: `${i.user_data.name} ${i.user_data.last_name}`,
        });
      });

      /* Tipo de Evento */
      let contentTypeE = [];
      resp.eventsType.forEach((i) => {
        contentTypeE.push({
          value: i.id,
          label: i.name_event,
        });
      });

      /* Categorías */
      let contentCategory = [];
      resp.categorias.forEach((i) => {
        contentCategory.push({
          value: i.id,
          label: i.name_category,
        });
      });

      /* Artistas */
      let contentArtist = [];
      resp.artistas.forEach((i) => {
        contentArtist.push({
          value: i.id,
          label: i.name_artist,
        });
      });

      // contentArtist.push({
      //     value: "add", label: <div style = {{borderTop: "1px solid #000000", paddingTop: 10, cursor: "pointer"}} align = "center"><FontAwesomeIcon icon = {faPlusCircle} className = "text-success"/> Agregar Nuevo Artista</div>
      // })

      /* Tipos de Pago */
      let contentnTipPago = [];
      resp.pagos.forEach((i) => {
        contentnTipPago.push({
          value: i.id,
          label: i.t_nombre,
        });
      });

      /* Moneda */
      let contentMoneda = [];
      resp.monedas.forEach((i) => {
        contentMoneda.push({
          value: i.id,
          label: i.moneda,
        });
      });

      /* Pagos disponibles */
      let contentPay = [];
      //Numeros del 2 al 3
      for (let i = 2; i <= 3; i++) {
        contentPay.push({
          value: i,
          label: i,
        });
      }

      setSites(contentSites);
      setProductores(contentProduct);
      setOrganizadores(contentOrg);
      setTipoE(contentTypeE);
      setCategorias(contentCategory);
      setArtistas(contentArtist);
      setMetodos(contentnTipPago);
      setMonedas(contentMoneda);
      setMetodosPay(contentPay);
      setSitesOpt({ disabled: false, loading: false });
      setSelectPro({ disabled: false, loading: false });
      setSelectOrg({ disabled: false, loading: false });
      setTipoEop({ disabled: false, loading: false });
      setCatOpt({ disabled: false, loading: false });
      setArtOpt({ disabled: false, loading: false });
      setMetodoOpt({ disabled: false, loading: false });
      setMonedaOpt({ disabled: false, loading: false });
    });
  }, [props]);

  useEffect(() => {
    setControl(!control);
    if (zonasBuild.length === 0) {
      auditBuild = (
        <>
          <FontAwesomeIcon
            icon={preloadAud.icon}
            spin={preloadAud.spin}
            size="xs"
            className="text-muted"
          />{" "}
          <small>{preloadAud.text}</small>
        </>
      );
    } else {
      if (sitesSelect.value === "") {
        auditBuild = (
          <>
            <FontAwesomeIcon
              icon={preloadAud.icon}
              size="xs"
              className="text-muted"
            />{" "}
            <small>{preloadAud.text}</small>
          </>
        );
      } else {
        let leyenda = () =>
          zonasCat.map((option) => (
            <small key={option.id} style={{ fontSize: 9 }}>
              <FontAwesomeIcon
                icon={faSquare}
                style={{ color: option.color }}
              />{" "}
              {option.nombre} |{" "}
            </small>
          ));

        let title = (nombre) => {
          if (nombre !== undefined) return nombre;
        };

        let zonaT = () => {
          let columnas = (data) =>
            data.map((option) => (
              <td
                key={option.id}
                width={10}
                height={10}
                title={title(option.zona.label)}
                style={{ backgroundColor: option.color }}
              ></td>
            ));

          return zonasBuild.map((fila) => (
            <tr key={`${fila.id}_${control}`}>{columnas(fila.columnas)}</tr>
          ));
        };

        switch (zonaTipo) {
          case "cuadricula":
            auditBuild = (
              <>
                <div
                  className="table-responsive"
                  align="center"
                  style={{ marginBottom: 5 }}
                >
                  <table border="0">
                    <tbody>{zonaT()}</tbody>
                  </table>
                </div>
                <div style={{ borderTop: "1px dotted #424242" }}>
                  {leyenda()}
                </div>
              </>
            );
            break;
          case "area":
            auditBuild = (
              <>
                <div>
                  <div
                    align="left"
                    style={{
                      borderBottom: "1px dotted #000",
                      marginBottom: 10,
                    }}
                  >
                    {leyenda()}
                  </div>
                  <ImageMapper
                    src={`${UrlImg}maps/${mapaA}`}
                    map={zonasBuild}
                  />
                </div>
              </>
            );
            break;
          default:
            auditBuild = "";
        }
      }
    }
  }, [zonasBuild]);

    useEffect(() => {
      // La función handleInputChange,
      function handleInputChange() {
        if (productores && productores.length > 0 && U_type === 2) {
          setProSelect(productores);
        }
      }
      // Llamada a handleInputChange
      handleInputChange();
    }, [productores]); 
    

  const handleChangeColor = (event) => {
    setColor(event.target.value);
  };

  const customStylesSelect = {
    menu: (styles) => ({ ...styles, color: "#000", fontSize: 14, zIndex: 4 }),
    multiValue: (styles) => ({ ...styles, backgroundColor: "#ccc" }),
    multiValueLabel: (styles) => ({ ...styles, color: "#000" }),
    placeholder: (styles) => ({ ...styles, fontSize: 14, color: "#b4bcc3" }),
  };

  const configuraciones = {
    toolbar: [
      [
        "Font",
        "FontSize",
        "-",
        "Copy",
        "Paste",
        "PasteText",
        "-",
        "Undo",
        "Redo",
        "-",
        "Bold",
        "Italic",
        "Underline",
        "-",
        "JustifyBlock",
        "JustifyCenter",
        "JustifyLeft",
        "JustifyRight",
        "-",
        "TextColor",
        "BGColor",
        "-",
        "Link",
        "Maximize",
      ],
    ],
    height: "35vh",
    removePlugins: "elementspath",
    /* removePlugins: 'resize, elementspath', */
  };

  let cambiaFormato = (fecha) => {
    let dateIn = new Date(fecha);
    dateIn.setDate(dateIn.getDate() + 1);
    let date = new Date(dateIn);

    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let aaaa = date.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    return `${dd}/${mm}/${aaaa}`;
  };

  let changePro = (proSelect) => {
    setProSelect(proSelect);
  };
  let changeOrg = (orgSelect) => {
    setOrgSelect(orgSelect);
  };
  let changeTipoE = (tipoESelect) => {
    setTipoESelect(tipoESelect);
  };
  let changeCat = (catSelect) => {
    setCatSelect(catSelect);
  };

  let changeArt = (artSelect) => {
    let num = artSelect.length;
    switch (true) {
      case artSelect[num - 1] === undefined:
        setArtSelect(artSelect);
        break;
      case artSelect[num - 1].value === "add":
        setOpenModalNewArt(true);
        break;
      default:
        setArtSelect(artSelect);
        break;
    }
  };

  const createZonas = (zona, precio, color) => {
    let zona1;
    let precio1;

    if (zona === "" || precio === "") {
      zona === ""
        ? toast.error(<small>Seleccione una zona</small>)
        : (zona1 = zona);
      precio === ""
        ? toast.error(<small>Seleccione una precio para su zona</small>)
        : (precio1 = precio);
    } else {
      zona1 = zona;
      precio1 = precio;
      toast.success(<small>Los datos se han guardado satisfactoriamente</small>)

      setGrupo((prevGrupo) => [
        ...prevGrupo,
        { id: nextId, zona: zona1, precio: precio1, color: color },
      ]);
      setNextId(nextId + 1);
      setZona("");
      setPrecios("");
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const removeZone = (id) => {
    const newGrupo = grupo.filter((item) => item.id !== id);
    setGrupo(newGrupo);
  };

  let showGrupo = () => {
    let dis = grupo.length == 0 ? true : false;

    return (
      <div className="custom-dropdown">
        <button
          className="dropdown-button btn btn-success"
          onClick={toggleDropdown}
          disabled={dis}
        >
          {grupo.length == 0
            ? "No tiene zonas agregadas"
            : "Ver zonas agregadas"}
        </button>
        {showDropdown && (
          <div className="dropdown-content  ml-3">
            {grupo.map((item) => (
              <div className="row">
                <button
                  className="custom-button"
                  value={item.id}
                  onClick={() => removeZone(item.id)}
                  title="Presione para borrar"
                >
                  <strong>
                    <FontAwesomeIcon icon={faTrash} style={{color:'#F5365C'}} /> {item.zona} {item.precio}$
                  </strong>{" "}
                </button>
                <button
                  style={{
                    width: "30px",
                    height: "50px",
                    backgroundColor: item.color,
                    borderRadius: "80",
                  }}
                ></button>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  let changeSite = (sitesSelect) => {
    setSitesSelect(sitesSelect);
    setFuncionesDis(false);
    setPreloadSite({ icon: faMapMarkerAlt, text: sitesSelect.address });
    setZonaPreload({
      icon: faCircleNotch,
      spin: true,
      text: "Buscando las zonas del recinto seleccionado",
    });
    /* setPreloadAud({icon: faCircleNotch, spin: true, text: "Buscando las zonas del sitio seleccionado"}) */
    /* SearchZoneEvent(sitesSelect.value, "", (resp) => {

            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                break;
                default:
                    setDisplayBtn("block")
                    if(resp.dataZonas.length === 0) setZonaPreload({icon: faTimes, spin: false, text: "El recinto seleccionado no posee zonas registradas"})
                    if (resp.data_zona_audit !== null){
                        setZonasCat(JSON.parse(resp.data_zona_audit.zonas_cat))
                    }
                    
                    if(resp.dataZonas !== null){
                        let ContentZonas = []
                        resp.dataZonas.forEach(i => {
                            if(Boolean(i.nombre_zona.status) === true) ContentZonas.push({
                                id: i.id,
                                id_nombre: i.id_nombre,
                                color: i.color,
                                nombre: i.nombre_zona.nombre_zona,
                                valor: 0,
                                moneda: "",
                                status: 1,
                            })
                        })
                        setZonas(ContentZonas)
                        setZonas2([])
                        setFuncionSel({value: "", label: "- Selecciona -"})
                    }

                    if (resp.data_zona_audit !== null){
                        setZonaTipo(resp.data_zona_audit.tipo)
                        setMapaA(resp.data_zona_audit.image)
                        setZonasBuild(JSON.parse(resp.data_zona_audit.zonas_build))
                        setZonaPreload({icon: faCaretLeft, spin: false, text: "Selecciona una función"})
                    }else{
                        setZonaTipo("");
                        setMapaA("");
                        setZonasBuild([]);
                        setPreloadAud({icon: faTimes, spin: false, text: "Este recinto no tiene un auditorio configurado"})
                    }
                break;
            }
        }) */
  };

  let changeMetodo = (metSelect) => {
    setMetSelect(metSelect);
    metSelect.forEach((i) => {
      if (i.label === "Multipagos") {
        setAmountPay("");
      }
    });
  };
  let changeMoneda = (monSelect) => {
    setMonSelect(monSelect);
  };
  let changeMonedaPret = (monPSelect) => {
    setMonPSelect(monPSelect);
  };
  let changeAmountPay = (amountPay) => {
    setAmountPay(amountPay);
  }
  let changeFuncion = (funcionSel) => {
    setFuncionSel(funcionSel);
    let content = zonas2;
    const search = content.find(
      (option) => option.id_funcion === funcionSel.value
    );

    if (search === undefined) {
      zonas.forEach((i) => {
        content.push({
          id_funcion: funcionSel.value,
          id: i.id,
          color: i.color,
          id_nombre: i.id_nombre,
          moneda: i.moneda,
          nombre: i.nombre,
          status: i.status,
          valor: i.valor,
          nombre_funcion: funcionSel.label,
        });
      });

      setZonas2(content);
    }
  };

  let cambiaHtml = (e) => {
    setContentState(e.editor.getData());
  };
  let cambiaTerm = (e) => {
    setTerminos(e.editor.getData());
  };
  let cambiaTicket = (e) => {
    setEmailTicket(e.editor.getData());
  };
  let cambiaNombre = (e) => {
    setNombre(e.target.value);

    let regex = new RegExp("^[a-zA-Z0-9 ]+$");
    if (regex.test(e.target.value) === true) {
      let cadena = e.target.value;
      let cadenaRemplazada = cadena.replace(/ /g, "_");
      setUrl(cadenaRemplazada.toLowerCase());
    }
  };

  let addFuncion = () => {
    let currentFecha = desde;
    let content = diasEvents;
    let funcions = funciones;
    let lastId = diasEvents[diasEvents.length - 1];
    let zonas = grupo;

    switch (true) {
      case zonas.length === 0:
        toast.error(<small>Debe seleccionar al menos una zona</small>);

        break;
      default:
        const search = content.find(
          (option) =>
            option.dia === cambiaFormato(desde) &&
            option.horaIn === horaIn &&
            option.horaOut === horaOut
        );
        if (search === undefined) {
          let num = 0;
          if (lastId !== undefined) num = lastId.id + 1;

          content.push({
            id: num,
            dia: cambiaFormato(desde),
            horaIn: horaIn,
            horaOut: horaOut,
            apertura: horaApertura,
            zonas: zonas,
          });

          funcions.push({
            value: num,
            label: `[${cambiaFormato(
              currentFecha
            )}] - [${horaIn} - ${horaOut}]`,
          });
          break;
        }
    }

    let i = 1;
    if (lastId !== undefined) i = lastId.id + 1;

    while (new Date(currentFecha) < new Date(hasta)) {
      let fechaIn = new Date(currentFecha);
      fechaIn.setDate(fechaIn.getDate() + 2);
      let date = new Date(fechaIn);
      let dd = date.getDate();
      let mm = date.getMonth() + 1;
      let aaaa = date.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;

      currentFecha = `${aaaa}-${mm}-${dd}`;

      const search = content.find(
        (option) =>
          option.dia === cambiaFormato(currentFecha) &&
          option.horaIn === horaIn &&
          option.horaOut === horaOut
      );
      if (search === undefined) {
        content.push({
          id: i,
          dia: cambiaFormato(currentFecha),
          horaIn: horaIn,
          horaOut: horaOut,
          apertura: horaApertura,
          zonas: grupo,
        });

        funcions.push({
          value: i,
          label: `[${cambiaFormato(currentFecha)}] - [${horaIn} - ${horaOut}]`,
        });
        i++;
      }
    }

    setDiasEvents(content);
    setFunciones(funcions);
    setControl(!control);
  };

  let delFuncion = (id) => {
    diasEvents.forEach((opt, index, objeto) => {
      if (opt.id === id) objeto.splice(index, 1);
    });

    funciones.forEach((opt, index, objeto) => {
      if (opt.value === id) {
        objeto.splice(index, 1);
      }
    });

    /* zonas2.forEach((opt, index, objeto) => {
            if(opt.id_funcion === id){
                objeto.splice(index, 1);
            }
        }); */

    setFuncionSel({ value: "", label: "- Selecciona -" });
    setFunciones(funciones);
    setDiasEvents(diasEvents);
    setZonas2([]);
    setControl(!control);
  };

  let addImg = (item) => (e) => {
    document.getElementById(item).click();
  };

  let validateImageFile = (item, ancho, alto) => {
    return new Promise((resolve, reject) => {
      var o = document.getElementById(item);
      var foto = o.files[0];
  
      if (!foto) {
        reject(new Error("No se ha seleccionado ninguna imagen."));
        return;
      }
  
      var img = new Image();
      img.onload = function () {
        if (this.width === ancho && this.height === alto) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
  
      // Manejar errores al cargar la imagen
      img.onerror = function () {
        reject(new Error("Error al cargar la imagen."));
      };
  
      // Esta línea debe estar fuera del evento onload.
      img.src = URL.createObjectURL(foto);
    });
  };

  let selectFile = (item) => (e) => {
    let file = document.getElementById(item);
    let archivo = file.value;


    if (archivo === "") {
      toast.error("No has seleccionado ningún archivo", { autoClose: 4000 });
      setImgSelect(false);
      return null;
    }

    let extension = archivo.split(".").pop();
    let ext = extension.toLowerCase();
    let fic = archivo.split("\\");
    let nombre = fic[fic.length - 1];
    let size = file.files[0].size;

    if (ext === "jpg" || ext === "jpeg" || ext === "png") {
      let msjSize = formatBytes(size);
      if (size > 1000000) {
        /* Comprimir el archivo */
        let fil = file.files[0];
        new Compressor(fil, {
          quality: 0.2,
          convertSize: 1000000,
          success(result) {
            /* Convertir a Base 64 */
            let reader = new FileReader();
            reader.readAsDataURL(result);

            msjSize = `Tamaño original: ${formatBytes(
              size
            )} [Tamaño comprimido: ${formatBytes(result.size)}]`;

            reader.onloadend = function () {
              if (item === "fileImg3") {
                setPlano(reader.result);
              } else if (item === "fileImg1") {
                // Validar tamaño de la imagen
                validateImageFile(item, 2500, 900)
                .then(isValid => {
                  if (isValid) {
                    setFile1(reader.result);
                  } else {
                    toast.error(`Las medidas deben ser 2500 x 900`, { autoClose: 4000 });
                  }
                })
                .catch(error => {
                  console.error(error.message);
                });
              
              } else {
                // Validar tamaño de la imagen
                validateImageFile(item, 1080, 1080)
                .then(isValid => {
                  if (isValid) {
                    setFile2(reader.result);
                  } else {
                    toast.error(`Las medidas deben ser 1080 x 1080`, { autoClose: 4000 });
                  }
                })
                .catch(error => {
                  console.error(error.message);
                });

              }
            };

            if (item === "fileImg1") {
              // Validar tamaño de la imagen
              validateImageFile(item, 2500, 900)
              .then(isValid => {
                if (isValid) {
                  setItem1({
                    nombre: `Agregado: (${nombre}`,
                    tipo: extension,
                    size: msjSize,
                  });
                } 
              })
              .catch(error => {
                console.error(error.message);
              });
            } else if (item === "fileImg2") {
              // Validar tamaño de la imagen
              validateImageFile(item, 1080, 1080)
              .then(isValid => {
                if (isValid) {
                  setItem2({
                    nombre: `Agregado: (${nombre}`,
                    tipo: extension,
                    size: msjSize,
                  });
                } 
              })
              .catch(error => {
                console.error(error.message);
              });
            }
          },
          error(err) {
            toast.error("La imagen es muy grande y no pudo comprimirse", {
              autoClose: 4000,
            });
            document.getElementById(item).value = "";
          },
        });
      } else {
        /* Convertir a Base 64 */
        let reader = new FileReader();
        reader.readAsDataURL(file.files[0]);

        reader.onloadend = function () {
          if (item === "fileImg3") {
            setPlano(reader.result);
          }
          if (item === "fileImg1") {
            // Validar tamaño de la imagen
            validateImageFile(item, 2500, 900)
            .then(isValid => {
              if (isValid) {
                setFile1(reader.result);
              } else {
                toast.error(`Las medidas deben ser 2500 x 900`, { autoClose: 4000 });
              }
            })
            .catch(error => {
              console.error(error.message);
            });
          }
          if (item === "fileImg2") {
            // Validar tamaño de la imagen
            validateImageFile(item, 1080, 1080)
            .then(isValid => {
              if (isValid) {
                setFile2(reader.result);
              } else {
                toast.error(`Las medidas deben ser 1080 x 1080`, { autoClose: 4000 });
              }
            })
            .catch(error => {
              console.error(error.message);
            });
          }
        };

        if (item === "fileImg1") {
          validateImageFile(item, 2500, 900)
          .then(isValid => {
            if (isValid) {
              setItem1({
                nombre: `Agregado: (${nombre}`,
                tipo: extension,
                size: formatBytes(size),
              });
            }
          })
          .catch(error => {
            console.error(error.message);
          });
        }
        if (item === "fileImg2") {
          // Validar tamaño de la imagen
          validateImageFile(item, 1080, 1080)
          .then(isValid => {
            if (isValid) {
              setItem2({
                nombre: `Agregado: (${nombre}`,
                tipo: extension,
                size: formatBytes(size),
              });
            }
          })
          .catch(error => {
            console.error(error.message);
          });
        }
      }
      setImgSelect(true);
    } else {
      toast.error("No has seleccionado un archivo de imagen", {
        autoClose: 4000,
      });
      document.getElementById(item).value = "";
      setImgSelect(false);
      return null;
    }
  };

  let modalConfirm = () => {
    if (openModalCon === false) return null;

    return (
      <ConfirmModal
        isOpen={openModalCon}
        content={<div>¿Deseas agregar este evento?</div>}
        disabled={botonYes.disabled}
        buttonYes={
          <>
            <FontAwesomeIcon icon={botonYes.icon} spin={botonYes.spin} />{" "}
            {botonYes.text}
          </>
        }
        close={() => setOpenModalCon(false)}
        funcion={() => {
          save();
        }}
      />
    );
  };
  let modalConfirmDraft = () => {
    if (openModalConDraft === false) return null;

    return (
      <ConfirmModal
        isOpen={openModalConDraft}
        content={<div>¿Deseas agregar este evento?</div>}
        disabled={botonYes.disabled}
        buttonYes={
          <>
            <FontAwesomeIcon icon={botonYes.icon} spin={botonYes.spin} />{" "}
            {botonYes.text}
          </>
        }
        close={() => setOpenModalConDraft(false)}
        funcion={() => {
          saveDraft()
        }}
      />
    );
  };

  let modalAddArtista = () => {
    if (openModalNewArt === false) return null;

    let save = () => {
      if (botonYesArt.disabled === true) return null;

      if (newArtista.trim() === "") {
        toast.error(
          <small style={{ fontSize: 13 }}>
            <FontAwesomeIcon icon={faTimesCircle} /> El campo{" "}
            <strong>nombre del artista</strong> es obligatorio
          </small>
        );
      } else {
        setBotonYesArt({
          icon: faCircleNotch,
          text: "Guardando",
          spin: true,
          disabled: true,
        });

        AddNewArtist(newArtista.trim(), (resp) => {
          setBotonYesArt({
            icon: faSave,
            text: "Guardar",
            spin: false,
            disabled: false,
          });
          switch (true) {
            case resp.status === "error" || resp.status === "error_log":
              toast.error(
                <small>
                  <FontAwesomeIcon icon={faTimesCircle} />{" "}
                  <span dangerouslySetInnerHTML={{ __html: resp.mess }}></span>
                </small>
              );
              break;
            default:
              setNewArtista("");
              setOpenModalNewArt(false);
              toast.info(
                <small>
                  <FontAwesomeIcon icon={faCheck} />{" "}
                  <span dangerouslySetInnerHTML={{ __html: resp.mess }}></span>
                </small>
              );

              /* Artistas */
              let contentArtist = [];
              resp.data.forEach((i) => {
                contentArtist.push({
                  value: i.id,
                  label: i.name_artist,
                });
              });

              // contentArtist.push({
              //     value: "add", label: <div style = {{borderTop: "1px solid #000000", paddingTop: 10, cursor: "pointer"}} align = "center"><FontAwesomeIcon icon = {faPlusCircle} className = "text-success"/> Agregar Nuevo Artista</div>
              // })

              setArtistas(contentArtist);
              break;
          }
        });
      }
    };

    let Contenido = () => (
      <div style={{ paddingBottom: 10 }}>
        <small>Ingresa el nombre del artísta</small>
        <Input
          type="text"
          value={newArtista}
          onChange={(e) => setNewArtista(e.target.value)}
          placeholder="Nombre del artista"
        />
      </div>
    );

    return (
      <ConfirmModal
        isOpen={openModalNewArt}
        content={Contenido()}
        disabled={botonYesArt.disabled}
        buttonYes={
          <>
            <FontAwesomeIcon icon={botonYesArt.icon} spin={botonYesArt.spin} />{" "}
            {botonYesArt.text}
          </>
        }
        buttonNo={
          <small>
            <FontAwesomeIcon icon={faTimes} /> Cancelar
          </small>
        }
        close={() => setOpenModalNewArt(false)}
        funcion={() => save()}
      />
    );
  };

  let updZona = (campo, id, valor) => {
    let content = zonas2;
    content.forEach((i) => {
      if (i.id === id && i.id_funcion === funcionSel.value) i[campo] = valor;
    });

    setZonas2(content);
  };

  let save = () => {
    setBotonSave({
      icon: faCircleNotch,
      text: "Guardando evento",
      spin: true,
      disabled: true,
    });
    setBotonYes({
      icon: faCircleNotch,
      text: "Espera",
      spin: true,
      disabled: true,
    });

    let campos = {
      nombre,
      url,
      proSelect,
      orgSelect,
      diasEvents,
      contentState,
      minAge,
      tipoESelect,
      catSelect,
      artSelect,
      terminos,
      sitesSelect,
      zonas2,
      metSelect,
      monSelect,
      monPSelect,
      amountPay,
      emailTicket,
      file1,
      file2,
      plano,
      ubicacion,
    };

    AddEvent(
      campos,
      document.getElementById("fileImg1").value,
      document.getElementById("fileImg2").value,
      document.getElementById("fileImg3").value,
      (resp) => {
        setBotonSave({
          icon: faSave,
          text: "Guardar evento",
          spin: false,
          disabled: false,
        });
        setBotonYes({
          icon: faCheck,
          text: "Si",
          spin: false,
          disabled: false,
        });
        setOpenModalCon(false);

        switch (true) {
          case resp[0].cantidad > 0:
            setErrMess(resp[0]);
            setVisibleErr("block");
            toast.error(
              <small>
                <FontAwesomeIcon icon={faTimesCircle} /> No se guardó la
                información
              </small>
            );
            break;
          case resp[1].status === "error" || resp[1].status === "error_log":
            toast.error(
              <small>
                <FontAwesomeIcon icon={faTimesCircle} />{" "}
                <span dangerouslySetInnerHTML={{ __html: resp[1].mess }}></span>
              </small>
            );
            break;
          default:
            setOpenModalOk(true);
            setModalOk("Datos guardados correctamente");
            setMmodalMess(resp[1].mess);
            setVisibleErr("none");
            setErrMess("");
            break;
        }
      }
    );
  };

  let saveDraft = () => {
    setBotonSaveDraft({
      icon: faCircleNotch,
      text: "Guardando Borrador",
      spin: true,
      disabled: true,
    });
    setBotonYes({
      icon: faCircleNotch,
      text: "Espera",
      spin: true,
      disabled: true,
    });

    let campos = {
      nombre,
      url,
      proSelect,
      orgSelect,
      diasEvents,
      contentState,
      minAge,
      tipoESelect,
      catSelect,
      artSelect,
      terminos,
      sitesSelect,
      zonas2,
      metSelect,
      monSelect,
      monPSelect,
      amountPay,
      emailTicket,
      file1,
      file2,
      plano,
      ubicacion,
    };

    AddEventDraft(
      campos,
      document.getElementById("fileImg1").value,
      document.getElementById("fileImg2").value,
      document.getElementById("fileImg3").value,
      (resp) => {
        setBotonSaveDraft({
          icon: faSave,
          text: "Guardar Borrador",
          spin: false,
          disabled: false,
        });
        setBotonYes({
          icon: faCheck,
          text: "Si",
          spin: false,
          disabled: false,
        });
        setOpenModalCon(false);

        switch (true) {
          case resp[0].cantidad > 0:
            setErrMess(resp[0]);
            setVisibleErr("block");
            toast.error(
              <small>
                <FontAwesomeIcon icon={faTimesCircle} /> No se guardó la
                información
              </small>
            );
            break;
          case resp[1].status === "error" || resp[1].status === "error_log":
            toast.error(
              <small>
                <FontAwesomeIcon icon={faTimesCircle} />{" "}
                <span dangerouslySetInnerHTML={{ __html: resp[1].mess }}></span>
              </small>
            );
            break;
          default:
            setOpenModalOk(true);
            setModalOk("Datos guardados correctamente");
            setMmodalMess(resp[1].mess);
            setVisibleErr("none");
            setErrMess("");
            break;
        }
      }
    );
  };

  let infoFile = (item) => {
    if (item.nombre === "") return null;

    return (
      <div
        style={{ fontSize: 10, color: "#000", fontWeight: "bold" }}
      >{`${item.nombre} - ${item.size})`}</div>
    );
  };

  let contentEvents = () => {
    if (diasEvents.length === 0)
      return (
        <tr>
          <td align="center" style={{ padding: 10 }} colSpan={3}>
            <FontAwesomeIcon icon={faTimesCircle} />{" "}
            <span>No hay fechas registradas para este evento</span>
          </td>
        </tr>
      );

    return diasEvents.map((option) => (
      <Funciones
        data={option}
        key={option.id}
        delFuncion={(id) => delFuncion(id)}
      />
    ));
  };

  let contentZonas = () => {
    if (zonas2.length === 0 || funcionSel.value === "")
      return (
        <tr>
          <td align="center" style={{ padding: 10 }} colSpan={4}>
            <FontAwesomeIcon icon={zonaPreload.icon} spin={zonaPreload.spin} />{" "}
            <span>{zonaPreload.text}</span>
          </td>
        </tr>
      );

    return zonas2.map((option) => {
      if (funcionSel.value === option.id_funcion) {
        return (
          <ZonaInd
            key={`${option.id_funcion}_${option.id}`}
            data={option}
            sitioId={sitesSelect.value}
            updZona={(campo, id, valor) => {
              updZona(campo, id, valor);
            }}
          />
        );
      } else {
        return null;
      }
    });
  };

  let mapa = () => {
    if (sitesSelect.value === "")
      return <small>Selecciona un sitio para ver el mapa</small>;

    if (sitesSelect.mapa === "")
      return (
        <>
          <FontAwesomeIcon icon={faMap} size="xs" className="text-muted" />{" "}
          <small>Este recinto no tiene un mapa disponible</small>
        </>
      );

    return <CustomMap src={sitesSelect.mapa} map={true} />;
  };

  let auditorio = () => {
    sessionStorage.setItem("ContentControl", control);
    return auditBuild;
  };

  let nombreZona = () => {
    if (funcionSel.value === "") return null;
    if (zonas2.length === 0)
      return (
        <span className="text-danger" style={{ fontWeight: "bold" }}>
          No hay precios registrados
        </span>
      );
    return (
      <span className="text-danger" style={{ fontWeight: "bold" }}>
        Función: {funcionSel.label}
      </span>
    );
  };
  const openModal = () => {
    setOpenModalNewArt(true);
  };

  const content = JSON.parse(sessionStorage.getItem("ContentData"));

  let U_type = content.U_TYPE;

  let addOk = (open, title, mess, data) => {
    setOpenModalOk(open);
    setModalOkTitle(title);
    setMmodalMess(mess);
    setDataSitios(data);
  };

  let modalCreateContent = () => (
    <ContentModal
      close={() => setOpenModalContent(false)}
      isOpen={openModalContent}
      title="Agregar un nuevo recinto"
      size="lg"
      content={
        <AddSitio
          cerrar={() => {
            setOpenModalContent(false);
          }}
          addOk={(open, title, mess, data) => {
            addOk(open, title, mess, data);
          }}
          botones="add"
        />
      }
    />
  );

  if (redirect === true) return <Redirect to="/dashboard/eventos" />;

  let texto = 'Ej. Si desea comprar en efectivo, debe acercarse a Tecniciencias Libros del Sambil o el día del evento a la taquilla del teatro. Sin embargo, es recomendable que compre su entrada con anticipación debido a que suelen agotarse rápidamente'

  return (
    <>
      <NavbarI />
      <main>
        <section className="section section-shaped section-lg">
          <div className="d-block d-sm-block d-md-block" align="right" style={{ paddingRight: 15 }}>
            <small class="bread-crumb-custom">
              <Link to="/dashboard/home">
                <FontAwesomeIcon icon={faHome} />
              </Link>
              <Link to="/dashboard/eventos">
                <FontAwesomeIcon icon={faFilm} /> eventos
              </Link>
              | <FontAwesomeIcon icon={faPlusCircle} /> Agregar evento
            </small>
          </div>

          <Row>
            <Col md = {3} className = "d-none d-sm-none d-md-block">
                <Sidebar active = "eventos"/>
            </Col>
            <Col md = {9} className = "custom-p-32">
                <CardHeader>
                    <Row>
                        <Col md = {6}>
                            <h5>Agregar evento</h5>
                            <div className = "text-muted" style = {{fontSize: 10, marginTop: -10}}>Completa todos los campos para continuar</div>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <CardTitle className = "twitter">
                            <strong>Datos Principales</strong>
                    </CardTitle>
                    <Row>
                    <Col md = {6}>
                        <small style = {{color: "#000"}}>* Nombre del evento</small>
                        <InputGroup className = "input-group-alternative">
                            <InputGroupAddon addonType = "prepend">
                                <InputGroupText>
                                    <FontAwesomeIcon icon = {faFilm}/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder = "* Nombre del evento" type = "text" maxLength = {190} value = {nombre} onChange = {(e) => cambiaNombre(e)}/>
                        </InputGroup>
                    </Col>
                    <Col md = {6}>
                        <small style = {{color: "#000"}}>* Url del evento</small>
                        <InputGroup className = "input-group-alternative" disabled>
                            <InputGroupAddon addonType = "prepend">
                                <InputGroupText>
                                    <FontAwesomeIcon icon = {faLink}/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder = "* Url del evento (Sin espacios)" type = "text" maxLength = {190} disabled value = {url} /* onChange = {(e) => setUrl((e.target.value).toLowerCase())} *//>
                        </InputGroup>
                    </Col>
                    <Col md = {6} className = "custom-mt-10">
                        <small style = {{color: "#000"}}>* Productor del evento</small>
                        {U_type === 1 ?  <Select
                            /* isDisabled = {selectPro.disabled} */
                            isLoading = {selectPro.loading}
                            isMulti isSearchable = {true} styles = {customStylesSelect}
                            className = "basic-multi-select"
                            onChange = {changePro} 
                            options = {productores}
                            noOptionsMessage = {() => { return "Sin Opciones" }}
                            loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando listado de productores</> }}
                            value = {proSelect}
                            defaultValue = {proSelect}
                            placeholder = "Selecciona los productores"
                        /> :  <InputGroup className = "input-group-alternative" disabled>
                        <InputGroupAddon addonType = "prepend">
                            <InputGroupText>
                                <img src={movie} alt="productor" srcset="" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder = "Productor del evento" type = "text" maxLength = {190} disabled value = {productores && productores.length > 0 ? productores[0].label : "Cargando productor"}/>
                    </InputGroup>}
                    </Col>
                    <Col md = {3} className = "custom-mt-10">
                    <small style = {{color: "#000"}}>* Tipo de evento</small>
                        <Select
                            isLoading = {tipoEop.loading}
                            isSearchable = {true} styles = {customStylesSelect}
                            className = "basic-multi-select"
                            onChange = {changeTipoE} options = {tipoE}
                            noOptionsMessage = {() => { return "Sin Opciones" }}
                            loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                            value = {tipoESelect}
                            placeholder = "Tipo"
                        />
                    </Col>
                    <Col md = {3} className = "custom-mt-10">
                        <small style = {{color: "#000"}}>* Categorías</small>
                        <Select
                            /* isDisabled = {selectPro.disabled} */
                            isLoading = {catOpt.loading}
                            isMulti isSearchable = {true} styles = {customStylesSelect}
                            className = "basic-multi-select"
                            onChange = {changeCat} options = {categorias}
                            noOptionsMessage = {() => { return "Sin Opciones" }}
                            loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando categorías</> }}
                            value = {catSelect}
                            defaultValue = {catSelect}
                            placeholder = "Selecciona las categorías"
                        />
                    </Col>
                    <Col md = {4} className = "custom-mt-10">
                    <small style = {{color: "#000"}}>* Artístas</small>
                        <Select
                            /* isDisabled = {selectPro.disabled} */
                            isLoading = {artOpt.loading}
                            isMulti isSearchable = {true} styles = {customStylesSelect}
                            className = "basic-multi-select"
                            onChange = {changeArt} options = {artistas}
                            noOptionsMessage = {() => { return "Sin Opciones" }}
                            loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando artístas</> }}
                            value = {artSelect}
                            defaultValue = {artSelect}
                            placeholder = "Selecciona los artístas"
                        />
                    </Col>
                    <Col md = {2} style={{marginTop:'32px'}}>
                        <button className="btn btn-success link-success" onClick={openModal}><FontAwesomeIcon icon={faPlus} title="Agregar nuevo artista"></FontAwesomeIcon></button>
                    </Col>
                    <Col md = {2} className = "custom-mt-10">
                        <small style = {{color: "#000"}}>Edad mínima</small>
                        <Input placeholder = "Edad" type = "number" value = {minAge} onChange = {(e) => setMinAge(e.target.value)}/>
                    </Col>
                    </Row>
                    <CardTitle className = "twitter mt-5">
                            <strong>Localidad del evento</strong>
                    </CardTitle>
                    <Row>
                        <Col md = {4}>
                            <small style = {{color: "#000"}}>* Recinto del evento</small>
                            <Select
                                isLoading = {sitesOpt.loading}
                                isSearchable = {true} styles = {customStylesSelect}
                                className = "basic-multi-select"
                                onChange = {changeSite} options = {sites}
                                noOptionsMessage = {() => { return "Sin Opciones" }}
                                loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                                value = {sitesSelect}
                                placeholder = "Selecciona un recinto"
                            />
                            <Row>
                            <Col md={8} className = "custom-mt-20">
                                <small style = {{color: "#000"}}>Nombre de la zona</small>
                                <Input placeholder = "Zonas" type = "text" value = {zona} onChange = {(e) => setZona(e.target.value)}/>
                            </Col>
                            <Col className = "custom-mt-20">
                                <small style = {{color: "#000"}}>Precio de la zona</small>
                                <Input placeholder = "$" type = "number" value = {precios} onChange = {(e) => setPrecios(e.target.value)}/>
                            </Col>
                            <Col md={8} className="mt-5" >
                                {showGrupo()}
                            </Col>
                            </Row>
                        </Col>
                        <Col md={2}>
                        <Button onClick = {() => {setOpenModalContent(true)}} color = "default" type="button" style={{marginTop:'24px'}}>
                            <small><FontAwesomeIcon icon = {faPlusCircle}/> Agregar recinto</small>
                        </Button>
                        <Col  className='mt-4'>
                            <button className="btn btn-success" style={{marginTop:20}} onClick={() => {createZonas(zona, precios, color);
                                  }}><small>Guardar zona</small></button>
                        </Col>
                        </Col>
                        <Col md = {6}>
                            <small style = {{color: "#000"}}>Detalles del recinto</small>
                            <div style = {{color: "#000"}}><small><FontAwesomeIcon icon = {preloadSite.icon}/> {preloadSite.text}</small></div>
                            <small style = {{color: "#000"}}>Mapa</small>
                            <div style = {{border: "1px solid #ccc", backgroundColor: "#fff", padding: 5, borderRadius: "3px 3px", color: "#000", minHeight: "64px"}} align = "center">{mapa()}</div>
                        </Col>
                        <Col md = {12} className='mt-4'>
                          <div>
                            <small style = {{color: "#000"}}>Ubicación de la taquilla física</small>
                          </div>
                          <textarea rows="12" cols="90" placeholder={texto} value={ubicacion} onChange={(e)=>setUbicacion(e.target.value)} />
                        </Col>
                        <Col md = {12} className = "custom-mt-10" style = {{borderTop: "1px dotted #424242"}}>
                            <small style = {{color: "#000"}}>* Sinopsis</small>
                            <CKEditor
                                data = {contentState}
                                type = "classic"
                                onChange = {cambiaHtml} 
                                config = {configuraciones}
                                editorUrl = "https://cdn.ckeditor.com/4.16.0/full-all/ckeditor.js"
                                /* editorUrl = {`${UrlEditor}ckeditor.js`} */
                            />
                        </Col>
                    </Row>
                    <CardTitle className = "twitter mt-5">
                            <strong>Funciones</strong>
                    </CardTitle>
                    <Row>
                    <Col md={6}>
                        <Row>
                        <Col md = {4}>
                        <small style = {{color: "#000"}}>Comienza</small>
                        <Input placeholder = "Desde" type = "date" value = {desde} onChange = {(e) => setDesde(e.target.value)}/>
                    </Col>
                    <Col md = {4}>
                        <small style = {{color: "#000"}}>Finaliza</small>
                        <Input placeholder = "Desde" type = "date" value = {hasta} onChange = {(e) => setHasta(e.target.value)}/>
                    </Col>
                    <Col md = {4}>
                        <small style = {{color: "#000"}}>Apertura de puerta</small>
                        {/* <Input placeholder = "Apertura" type = "time" value = {horaApertura} onChange = {(e) => setApertura(e.target.value)}/> */}
                        <InputTime value={horaApertura} onChange={(e) => setApertura(e)} />
                    </Col>
                    <Col md = {4}>
                        <small style = {{color: "#000"}}>Hora del show</small>
                        {/* <Input placeholder = "Desde" type = "time" value = {horaIn} onChange = {(e) => setHoraIn(e.target.value)}/> */}
                        <InputTime value={horaIn} onChange={(e) => setHoraIn(e)} />
                    </Col>
                    <Col md = {4}>
                        <small style = {{color: "#000"}}>Fin del show</small>
                        {/* <Input placeholder = "Desde" type = "time" value = {horaOut} onChange = {(e) => setHoraOut(e.target.value)}/> */}
                        <InputTime value={horaOut} onChange={(e) => setHoraOut(e)} />

                    </Col>
                    <Col md = {4} align = "right" className = "custom-mt-20">
                        <Button color = "default" type = "button" onClick = {addFuncion}>
                            <small>Agregar función</small> <FontAwesomeIcon icon = {faCaretRight}/>
                        </Button>
                    </Col>
                        </Row>
                    </Col>
                    <Col md = {6} className = "custom-mt-10" style = {{borderTop: "1px dotted #424242"}}>
                        <small style = {{color: "#000"}}>* Funciones</small>
                            <div style = {{maxHeight: 200, overflow:"auto"}}>
                                <Table className = "mb-0 table-custom-primary" striped hover  style = {{boxShadow: "2px 1px 2px #000", fontSize: 10 }}>
                                    <tbody>
                                        <tr>
                                            <th width = "20%">Día</th>
                                            <th width = "70%" align = "center">Horario</th>
                                            <th width = "10%" align = "center">Acciones</th>
                                            <th align="center"></th>
                                        </tr>
                                        {contentEvents()}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        <Col md = {12} className = "custom-mt-10" style = {{borderTop: "1px dotted #424242"}}>
                        <small style = {{color: "#000"}}>* Términos y condiciones</small>
                            <CKEditor
                                data = {terminos}
                                type = "classic"
                                onChange = {cambiaTerm} 
                                config = {configuraciones}
                                /* editorUrl = {`${UrlEditor}ckeditor.js`} */
                                editorUrl = "https://cdn.ckeditor.com/4.16.0/full-all/ckeditor.js"
                            />
                        </Col>
                    </Row>
                    <CardTitle className = "twitter mt-5">
                            <strong>Métodos de pago</strong>
                    </CardTitle>
                    <Row>
                        <Col md = {12}>
                            <small style = {{color: "#000"}}>* Métodos de pago</small>
                            <Select
                                isLoading = {metodoOpt.loading}
                                isMulti isSearchable = {true} styles = {customStylesSelect}
                                className = "basic-multi-select"
                                onChange = {changeMetodo} options = {metodos}
                                noOptionsMessage = {() => { return "Sin Opciones" }}
                                loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                                value = {metSelect}
                                defaultValue = {metSelect}
                                placeholder = "Método"
                            />
                        </Col>

                        {metSelect.map((item) => {
                          if (item.label === "Multipagos") {
                            return (
                              <Col md={12}>
                                <small style={{ color: "#000" }}>* Número de multipagos disponibles:</small>
                                <Select
                                isSearchable = {true} styles = {customStylesSelect}
                                className = "basic-multi-select"
                                onChange = {changeAmountPay} options = {metodosPay}
                                noOptionsMessage = {() => { return "Sin Opciones" }}
                                loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                                value = {amountPay}
                                placeholder = "Número de multipagos "
                            />
                              </Col>
                            );
                          }
                          // Explicitly return null for cases where the condition is not met
                          return null;
                        })}

                        <Col md = {12}>
                            <small style = {{color: "#000"}}>* Monedas disponibles</small>
                            <Select
                                isMulti
                                isLoading = {monedaOpt.loading}
                                isSearchable = {true} styles = {customStylesSelect}
                                className = "basic-multi-select"
                                onChange = {changeMoneda} options = {monedas}
                                noOptionsMessage = {() => { return "Sin Opciones" }}
                                loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                                value = {monSelect}
                                defaultValue = {monSelect}
                                placeholder = "Moneda"
                            />
                        </Col>
                        <Col md = {12}>
                            <small style = {{color: "#000"}}>* Moneda predeterminada</small>
                            <Select
                                isLoading = {monedaOpt.loading}
                                isSearchable = {true} styles = {customStylesSelect}
                                className = "basic-multi-select"
                                onChange = {changeMonedaPret} options = {monedas}
                                noOptionsMessage = {() => { return "Sin Opciones" }}
                                loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                                value = {monPSelect}
                                placeholder = "Moneda"
                            />
                        </Col>
                    </Row>
                    <CardTitle className = "twitter mt-5">
                            <strong>Imágenes</strong>
                    </CardTitle>
                    <Row>
                    <Col md = {4} className = "custom-mt-10" style = {{borderTop: "1px dotted #424242"}}>
                        <div> Imagen principal <small style = {{color: "#000"}}>(2500x900)</small></div>
                        <img src = {file1} onClick = {addImg("fileImg1")} title = "Haz click aqui para cambiar la imagen"  className = "img-fluid rounded" alt = "event_image" style={{cursor: "pointer", border: "1px  solid #cccccc"}}/>
                        {infoFile(item1)}
                        <input type = "file" onChange = {selectFile("fileImg1")} id = "fileImg1" style = {{ display: "none" }}/>

                        <Button color="danger"
                                outline size="sm" onClick={() => 
                                {
                                    setFile1(`${UrlImg}events/default_img.jpg`);
                                    document.getElementById('fileImg1').value = '';
                                    setItem1({nombre: '', size: ''});
                                
                                }}
                                className="w-100" style={{ borderRadius: 0 }}>
                            <small><FontAwesomeIcon icon={faImage}/> Quitar imagen</small>
                        </Button>
                    </Col>
                    <Col md = {4} className = "custom-mt-10" style = {{borderTop: "1px dotted #424242"}}>
                        <div>Miniatura <small style = {{color: "#000"}}>(1080x1080)</small></div>
                        <img src = {file2} onClick = {addImg("fileImg2")} title = "Haz click aqui para cambiar la imagen"  className = "img-fluid rounded" alt = "thumbnail_image" style={{cursor: "pointer", border: "1px  solid #cccccc"}}/>
                        {infoFile(item2)}
                        <input type = "file" onChange = {selectFile("fileImg2")} id = "fileImg2" style = {{ display: "none" }}/>
                        <Button color="danger"
                                outline size="sm" onClick={() => 
                                {
                                    setFile2(`${UrlImg}events/default_img.jpg`);
                                    document.getElementById('fileImg2').value = '';
                                    setItem2({nombre: '', size: ''});
                                }}
                                className="w-100" style={{ borderRadius: 0 }}>
                            <small><FontAwesomeIcon icon={faImage}/> Quitar imagen</small>
                        </Button>
                    </Col>
                    <Col md = {4} className = "custom-mt-10" style = {{borderTop: "1px dotted #424242"}}>
                    <h1>
                      <div style={{marginTop:20}}>
                        <FontAwesomeIcon icon={faArrowCircleDown}></FontAwesomeIcon>&nbsp;&nbsp;<strong>Planimetria</strong>&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowCircleDown}></FontAwesomeIcon>
                      </div>
                    </h1>
                    <h1>
                      <FontAwesomeIcon icon={faMap} onClick={addImg('fileImg3')} title="Agregar planimetria" style = {{cursor: "pointer", fontSize:'120px'}} className={(plano === '') ? "text-danger mr-3 ml-7" : "text-success mr-3 ml-7"} />
                      {(plano === '') ? <FontAwesomeIcon icon={faExclamation} className="text-danger mr-3" /> : <FontAwesomeIcon icon={faCheck} className="text-success"/>}
                    </h1>
                      <input type = "file" onChange = {selectFile("fileImg3")} id = "fileImg3" style = {{ display: "none" }}/>
                      <Button color="danger"
                                outline size="sm" onClick={() => 
                                {
                                    setPlano('');
                                    document.getElementById('fileImg3').value = '';
                                }}
                                className="w-100" style={{ borderRadius: 0 }}>
                            <small><FontAwesomeIcon icon={faImage}/> Quitar imagen</small>
                        </Button>
                    </Col>
                    <Col md = {12} className = "custom-mt-10" style = {{borderTop: "1px dotted #424242"}}>
                        <small style = {{color: "#000"}}>Si desea colocar un mensaje personalizado en el e-ticket (hágalo aqui)</small>
                        <CKEditor
                            data = {emailTicket}
                            type = "classic"
                            onChange = {cambiaTicket} 
                            config = {configuraciones}
                            /* editorUrl = {`${UrlEditor}ckeditor.js`} */
                            editorUrl = "https://cdn.ckeditor.com/4.16.0/full-all/ckeditor.js"
                        />
                    </Col>
                    </Row>
                </CardBody>
                <CardFooter>
                    <Row>
                        <Col md = {8}>
                            <div className = "text-danger" style = {{fontSize: 10}}>* CAMPOS OBLIGATORIOS</div>
                            <ErroresList errMess = {errMess} visibleErr = {visibleErr} name = "popErr"/>
                        </Col>
                        <Col md = {4} align = "right">
                            <Button disabled = {botonSaveDraft.disabled} color = "warning" type="button" onClick = {() => setOpenModalConDraft(true)}>
                                <small><FontAwesomeIcon spin = {botonSaveDraft.spin} icon = {botonSaveDraft.icon}/> {botonSaveDraft.text}</small>
                            </Button>
                            <Button disabled = {botonSave.disabled} color = "default" type="button" onClick = {() => setOpenModalCon(true)}>
                                <small><FontAwesomeIcon spin = {botonSave.spin} icon = {botonSave.icon}/> {botonSave.text}</small>
                            </Button>
                        </Col>
                    </Row>
                </CardFooter>
            </Col>
          </Row>
        </section>
      </main>
      <CardsFooter />
        {modalConfirm()}
        {modalConfirmDraft()}
        {modalAddArtista()}
        <SuccessModal isOpen = {openModalOk} close = {() => {
            setOpenModalOk(false)
            setRedirect(true)
        }} content = {modalMess} contentTitle = {modalOk}/>
      {modalCreateContent()}
    </>
  );
};

export default AddEvento;
