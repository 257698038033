import { Post } from "./Generales";
import { Url } from "./Url";

export const SearchEventsFunctions = (callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID
    }

    Post(`${Url}searchEventsFunctions`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const AddDelSlider = (idEvento, idFuncion, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idEvento: idEvento,
        idFuncion: idFuncion
    }

    Post(`${Url}addDelSlider`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const SearchSliders = (callback) => {
    Post(`${Url}searchSliders`, "", (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const SearchCategories = (callback) => {
    Post(`${Url}searchCategories`, "", (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}