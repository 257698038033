import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";

import { Card, CardBody, CardHeader, Row, Col, Table } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faTimesCircle, faHome, faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import NavbarI from "components/Navbars/NavbarI.js";
import Sidebar from '../dashboard/Sidebar';
import { SearchEventsFunctions } from "functions/SlidersFunctions";
import FuncionDetalle from "./FuncionDetalle";

const MainSliders = (props) => {
    const [funcionesE, setFuncionesE]       = useState([]);
    const [sliders, setSliders]             = useState([]);
    const [functPreolad, setFunctPreload]   = useState({icon: faCircleNotch, spin: true, text: "Buscando las funciones registradas"});
    const [reloadTrigger, setReloadTrigger] = useState(0);

    useEffect(() => {
        const loadEventsFunctions = () => {
            SearchEventsFunctions((resp) => {
                switch(true){
                    case (resp.status === "error" || resp.status === "error_log"):
                        toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                    break;
                    case (resp.data.length === 0):
                        setFunctPreload({icon: faTimes, spin: false, text: "No existen eventos próximos registrados"})
                        setFuncionesE([])
                    break;
                    default:
                        setFuncionesE(resp.data)
                        setSliders(resp.data_sliders)
                    break;
                }
            });
        };

        loadEventsFunctions();
    }, [props, reloadTrigger]);

    
    const triggerReload = () => {
        setReloadTrigger(prev => prev + 1); // Incrementa el disparador para recargar
    };

    let contentEventsFuncions = () => {
        if (funcionesE.length === 0) return  (
            <tr>
                <td align = "center" style = {{padding: 10}} colSpan = {7}><FontAwesomeIcon icon = {functPreolad.icon} spin = {functPreolad.spin}/> <span>{functPreolad.text}</span></td>
            </tr>
        )

        return (
            funcionesE.map(option => (
                <FuncionDetalle key = {option.id} data = {option} sliders = {sliders} onSliderChange={triggerReload}/>
            ))
        )
    }

    return (
        <>
            <NavbarI />
            <main>
                <section className="section section-shaped section-lg">
                    <div className = "d-block d-sm-block d-md-block" align = "right" style = {{paddingRight: 15}}>
                        <small class = "bread-crumb-custom"><Link to = "/dashboard/home"><FontAwesomeIcon icon = {faHome}/></Link> | <i className = "fa fa-picture-o"/> Sliders</small>
                    </div>
                    <Row>
                        <Col md = {3} className = "d-none d-sm-none d-md-block">
                            <Sidebar active = "Sliders"/>
                        </Col>
                        <Col md = {9} className = "custom-p-32">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col md = {12}>
                                            <h5>Sliders</h5>
                                            <div className = "text-muted" style = {{fontSize: 10, marginTop: -10}}>Selecciona las funciones que estarán en el slider principal</div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className = "custom-mt-10 table-responsive"  style = {{overflowY: "auto", overflowX:"auto", height: "50vh"}}>
                                        <small style = {{color: "#000000"}}>Funciones disponibles</small>
                                        <Table className = "mb-0 " hover  style = {{fontSize: 10 }}>
                                            <tbody>
                                                <tr>
                                                    <th width = "20%">Evento</th>
                                                    <th width = "20%">Funcion</th>
                                                    <th width = "20%">Lugar</th>
                                                    <th width = "10%" align = "center">Categorías</th>
                                                    <th width = "10%" align = "center">Artístas</th>
                                                    <th width = "10%" align = "center">Status</th>
                                                    <th width = "10%" align = "center">Acciones</th>
                                                </tr>
                                                {contentEventsFuncions()}
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </section>
            </main>
        </>
    )
}

export default MainSliders;
