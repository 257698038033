import React, {useEffect, useState} from "react";

import { Pagination, PaginationItem, PaginationLink, Container, Row, Col, InputGroup, Input } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faCircleNotch, faEraser, faFrown, faSearch } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';

import EventoCard from "../../components/Eventos/EventoCard";

import { SearchFuncionsIndex, SearchRecintosLocal, FilterEvents } from "functions/EventosFunctions";
import EventosPreload from "components/Skeletons/EventosPreload";

let EventosI = () => {
  const [dataEventos, setDataEventos] = useState([]);
  const [preloader, setPreloader]     = useState({icon: faCircleNotch, text: "Buscando los últimos eventos", spin: true});
  const [preloadE, setPreloadE]       = useState(true);
  const [recintoSelect, setRecintoSelect] = useState({value: "", label: "Recinto"});
  const [recintos, setRecintos]           = useState([]);
  const [recintoOpt, setRecintoOpt]       = useState({ disabled: false, loading: false })
  const [localidadSelect, setLocalidadSelect] = useState({value: "", label: "Localidad"});
  const [localidades, setLocalidades]     = useState([]);
  const [localidOpt, setLocalidOpt]       = useState({ disabled: true, loading: true })
  const [artistaSelect, setArtistaSelect] = useState({value: "", label: "Artista"});
  const [artistas, setArtistas]           = useState([]);
  const [artistaOpt, setArtistaOpt]       = useState({ disabled: true, loading: true })
  const [textSearch, setTextSearch]       = useState("");
  const [buttonSearch, setButtonSearch]   = useState({ icon: faSearch, spin: false, disabled: false })
  const [paginacion, setPaginacion]       = useState(false);
  const [maxSearch, setMaxSearch]         = useState(3);
  const [pagActiva, setPagActiva]         = useState(1);
  const [results, setResults]              = useState(0);
  
  useEffect(() => {
    let pagAct = JSON.parse(sessionStorage.getItem("ContentPagActiva"));
    
    if (pagAct === null || pagAct === undefined){
      searchIni(pagActiva)
    }else{
      setPagActiva(pagAct.num);
      searchIni(pagAct.intervalo)
    }
  }, []);

  let searchIni = (pagina) => {
    SearchFuncionsIndex(1, maxSearch, pagina, (resp) => {
      let ContentArt = [];
      let ContentEst = [];

      (resp.artistas).forEach(i => {
        ContentArt.push({
          value: i.id, label: i.name_artist
        })
      });

      ContentArt.push({
          value: "delArt", label: <div style = {{borderTop: "1px solid #000000", paddingTop: 10, cursor: "pointer"}} align = "center"><FontAwesomeIcon icon = {faEraser} className = "text-success"/> Elminar Selección</div>
      });

      (resp.localidades).forEach(i => {
        ContentEst.push({
          value: i.id, label: i.estado_name
        })
      });

      ContentEst.push({
        value: "delEst", label: <div style = {{borderTop: "1px solid #000000", paddingTop: 10, cursor: "pointer"}} align = "center"><FontAwesomeIcon icon = {faEraser} className = "text-success"/> Elminar Selección</div>
      });

      setArtistas(ContentArt)
      setLocalidades(ContentEst)
      setArtistaOpt({ disabled: false, loading: false })
      setLocalidOpt({ disabled: false, loading: false })
      setPaginacion (resp.paginador);
      
      if(resp.data.length === 0){
        setDataEventos([]);
        setPreloader({icon: faFrown, text: "Actualmente no hay eventos disponibles, Intenta mas tarde", spin: false});
        setPreloadE(false);
      }else{
        setDataEventos(resp.data);
        setPreloadE(false);
        setResults(resp.results);
      }
    })
  }

  let paginador = () => {
    
    let numPag = 1;
    let cantPag = 1;
    let intervalo = 0;

    let arrPag = [{
      num: 1,
      intervalo: 1
    }];

    if(paginacion === true){
      for (let i = 0; i <= results; i++){
        if (numPag > maxSearch){
          cantPag++;
          intervalo += maxSearch;
          arrPag.push({
            num: cantPag,
            intervalo: intervalo + 1
          });

          numPag = 0;
        }
        numPag++;
      }

      let pagClick = (option) => {
        sessionStorage.setItem("ContentPagActiva", JSON.stringify(option));
        setPagActiva(option.num);
        setPreloadE(true);

        SearchFuncionsIndex(1, maxSearch, option.intervalo, (resp) => {
          setDataEventos(resp.data);
          setPreloadE(false);
          setResults(resp.results);
        });
      }

      let pagClickIni = (option) => {
        const search = option.find(i => i.num === pagActiva - 1);
        if (search !== undefined){
          sessionStorage.setItem("ContentPagActiva", JSON.stringify(search.num));
          setPagActiva(search.num);
          setPreloadE(true);
          
          SearchFuncionsIndex(1, maxSearch, search.intervalo, (resp) => {
            setDataEventos(resp.data);
            setPreloadE(false);
            setResults(resp.results);
          });
        }
      }

      let pagClickFin = (option) => {
        const search = option.find(i => i.num === pagActiva + 1);
        if (search !== undefined){
          sessionStorage.setItem("ContentPagActiva", JSON.stringify(search.num));
          setPagActiva(search.num);
          setPreloadE(true);
          
          SearchFuncionsIndex(1, maxSearch, search.intervalo, (resp) => {
            setDataEventos(resp.data);
            setPreloadE(false);
            setResults(resp.results);
          });
        }
      }
      
      let items = () => (
        arrPag.map(option => {
          let active = false
          if(pagActiva === option.num) active = true;
        
          return(
            <PaginationItem active = {active} key = {option.num}>
                <PaginationLink onClick = {() => pagClick(option)}>{option.num}</PaginationLink>
            </PaginationItem>
          )
        })
      );

      let IniDisabled = false;
      let FinDisabled = false;
      if (pagActiva === 1) IniDisabled =  true;
      if (pagActiva === arrPag.length) FinDisabled =  true;
      
      return (
        <Col md = {12} align = "center">
            <Pagination aria-label = "Page navigation example">
                <PaginationItem disabled = {IniDisabled}>
                  <PaginationLink onClick = {() => pagClickIni(arrPag)}><FontAwesomeIcon icon = {faCaretLeft} /></PaginationLink>
                </PaginationItem>
                {items()}
                <PaginationItem disabled = {FinDisabled}>
                  <PaginationLink onClick = {() => pagClickFin(arrPag)}><FontAwesomeIcon icon = {faCaretRight} /></PaginationLink>
                </PaginationItem>
            </Pagination>
        </Col>
      )
    }
  }

  let listEventos = () => {
    let keys = [1, 2, 3, 4, 5, 6];

    if (preloadE === true) return (
      <Row style = {{marginBottom: 40}}>
        {keys.map(option => (
          <Col key = {option} md = {4} className = "custom-mt-20"><EventosPreload /></Col>
        ))}
      </Row>
    );

    if (dataEventos.length === 0) return (
      <div align = "center" style = {{padding: 80}}>
        <FontAwesomeIcon icon = {preloader.icon} spin = {preloader.spin} size = "4x"/> <div className = "text-warning">{preloader.text}</div>
      </div>
    )

    return (
      <Row>
        {dataEventos.map(option => (
          <EventoCard data = {option} key = {option.id} />
        ))}
      </Row>
    )
  }

  
  let changeRecinto = (recintoSelect) => {
    (recintoSelect.value === "delRecinto") ? setRecintoSelect({value: "", label: "Recinto"}) : setRecintoSelect(recintoSelect);
  }

  let changeLocalidad = (localidadSelect) => {
    if(localidadSelect.value === "delEst"){
      setRecintos([]);
      setRecintoSelect({value: "", label: "Recinto"});
      setLocalidadSelect({value: "", label: "Localidad"});
    }else{
      setLocalidadSelect(localidadSelect)

      setRecintoSelect({value: "", label: "Recinto"})
      setRecintos([])
      setRecintoOpt({ disabled: true, loading: true })
      
      SearchRecintosLocal (localidadSelect.value, (resp) => {
        let Content = [];
  
        (resp.data).forEach(i => {
          Content.push({
            value: i.id, label: i.name
          })
        });
  
        Content.push({
          value: "delRecinto", label: <div style = {{borderTop: "1px solid #000000", paddingTop: 10, cursor: "pointer"}} align = "center"><FontAwesomeIcon icon = {faEraser} className = "text-success"/> Elminar Selección</div>
        });

        setRecintos(Content)
        setRecintoOpt({ disabled: false, loading: false })
      })
    }
  }

  let changeArtista = (artistaSelect) => {
    (artistaSelect.value === "delArt") ? setArtistaSelect({value: "", label: "Artista"}) : setArtistaSelect(artistaSelect);
  }

  let searchEventsE = () => {
    if (buttonSearch.disabled === true) return null;

    setPreloadE(true)
    setButtonSearch({ icon: faCircleNotch, spin: true, disabled: true })

    FilterEvents(textSearch, artistaSelect, localidadSelect.value, recintoSelect.value, (resp) => {
      if(resp.data.length === 0) setPreloader({icon: faFrown, text: "No existe ningun evento que coincida con la búsqueda ingresada", spin: false});
      
      setDataEventos(resp.data);
      setPreloadE(false);
      setButtonSearch({ icon: faSearch, spin: false, disabled: false })
    })
  }

  const customStylesSelect = {
    menu: styles => ({ ...styles, color: "#000", fontSize: 14, zIndex: 3, minWidth: 200 }),
    multiValue: styles => ({ ...styles, backgroundColor: "#ccc" }),
    multiValueLabel: styles => ({ ...styles, color: "#000" }),
    placeholder: styles => ({ ...styles, fontSize: 14, color: "#b4bcc3" }),
    indicatorSeparator:  styles => ({ ...styles, backgroundColor: "rgba(255, 255, 255, 0)" }),
    control: styles => ({...styles,
      borderLeft: 0,
      borderRight: 0,
      borderColor: "#cccccc !important",
      boxShadow: "0 0 0 0px !important",
      borderRadius: 0,
      minHeight: 43,
      "&:hover": {borderColor: "#cccccc !important", boxShadow: "0 0 0 0px !important"}
    }),
  };
  
  return (
    <>
      <section className="pb-0 section-components mt--3" style = {{backgroundColor: "#f9f9f9"}}>
        <div>
          <Container className = "custom-margin">
            <Row>
              <Col md = {2}>
                <h2 className="mb-5">
                  <span><strong>Eventos</strong></span>
                </h2>
              </Col>
              <Col md = {10}>
                <InputGroup>
                  <Input placeholder = "Busca un evento" value = {textSearch} onKeyPress = {(e)  => {if (e.which === 13) searchEventsE()}} onChange = {(e) => setTextSearch(e.target.value)}/>
                  <Select
                    isLoading = {artistaOpt.loading}
                    isSearchable = {true} styles = {customStylesSelect}
                    className = "basic-multi-select d-none d-md-block d-lg-block d-xl-block"
                    onChange = {changeArtista} options = {artistas}
                    noOptionsMessage = {() => { return "Sin Opciones" }}
                    loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                    value = {artistaSelect}
                  />
                  <Select
                    isLoading = {localidOpt.loading}
                    isSearchable = {true} styles = {customStylesSelect}
                    className = "basic-multi-select d-none d-md-block d-lg-block d-xl-block"
                    onChange = {changeLocalidad} options = {localidades}
                    noOptionsMessage = {() => { return "Sin Opciones" }}
                    loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                    value = {localidadSelect}
                  />
                  <Select
                    isLoading = {recintoOpt.loading}
                    isSearchable = {true} styles = {customStylesSelect}
                    className = "basic-multi-select d-none d-md-block d-lg-block d-xl-block"
                    onChange = {changeRecinto} options = {recintos}
                    noOptionsMessage = {() => { return "Sin Opciones" }}
                    loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                    value = {recintoSelect}
                  />
                  <button className = "btn-warning custom-btn" onClick ={searchEventsE} title = "Filtrar los eventos" style = {{width: 100}} disabled = {buttonSearch.disabled}><FontAwesomeIcon icon = {buttonSearch.icon} spin = {buttonSearch.spin}/></button>
                </InputGroup>
              </Col>
            </Row>
          </Container>
          <Container className = "custom-margin custom-mt-media-10">
            {listEventos()}
            <Row>
              {paginador()}
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}

export default EventosI;