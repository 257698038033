import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faHome, faSearch, faCircleNotch, faTimesCircle, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, CardBody, CardHeader, Row, Col, Table } from "reactstrap";

import NavbarI from "components/Navbars/NavbarI.js";
import Sidebar from '../dashboard/Sidebar';
import DetalleHistFuncion from "./DetalleHistFuncion";

import { SearchFunctionsProduct } from "functions/EventosFunctions";

const MainHistFunciones = (props) => {
    const [botonSearch, setBotonSearch]     = useState({ icon: faSearch, spin: false, disabled: false, text: "Buscar" })
    const [eventOpt, setEventOpt]           = useState({ disabled: true, loading: true })
    const [functOpt, setFunctOpt]           = useState({ disabled: true, loading: true })
    const [eventos, setEventos]             = useState([]);
    const [eventSelect, setEventSelect]     = useState({value: "", label: "Selecciona"});
    const [tipoSelect, setTipoSelect]       = useState({value: "all", label: "Mostrar Todas las Fuciones"});
    const [tipos, setTipos]                 = useState([
        {value: "all", label: "Mostrar Todas las Fuciones"},
        {value: "Vendido", label: "Mostrar Fuciones Vendidas"},
        {value: "Vencido", label: "Mostrar Fuciones Vencidas"},
        {value: "Activo", label: "Mostrar Fuciones Activas"},
    ]);
    const [funciones, setFunciones]         = useState([]);
    const [funcionesTmp, setFuncionesTmp]   = useState([]);
    const [preload, setPreload]             = useState({icon: faCircleNotch, spin: true, text: "Buscando tus funciones. Espera..."});

    useEffect(() => {
        busquedaEvento(eventSelect.value)
    }, [props]);

    let searchFunctions = () => {

    }
    
    let busquedaEvento = (evento) => {
        setEventOpt({ disabled: true, loading: true })
        setFunctOpt({ disabled: true, loading: true })

        SearchFunctionsProduct(evento, (resp) => {
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                break;
                default:
                    setEventOpt({ disabled: false, loading: false })
                    setFunctOpt({ disabled: false, loading: false })

                    let ContenEvent = [
                        {value: "", label: "Mostrar Todas los eventos"},    
                    ];

                    if (resp.events.length > 0) {
                        (resp.events).forEach(i => {
                            ContenEvent.push({
                                value: i.id_evento, label: i.evento_info.name_event
                            })
                        })
                    }

                    setEventos(ContenEvent);

                    ((resp.data).length === 0) ?
                        setPreload({icon: faTimesCircle, spin: false, text: "No existen coincidencias con la búsqueda seleccionada"})
                    :
                        setPreload({icon: faCheck, spin: false, text: ""});

                    setFunciones(resp.data);
                    setFuncionesTmp(resp.data);
                    setTipoSelect({value: "all", label: "Mostrar Todas las Fuciones"});
                break;
            }
        })
    }

    let changeEvento = (eventSelect) => {
        setEventSelect(eventSelect);
        setEventOpt({ disabled: true, loading: true });
        setFunciones([]);
        setFuncionesTmp([]);
        setPreload({icon: faCircleNotch, spin: true, text: "Buscando tus funciones. Espera..."});
        busquedaEvento(eventSelect.value);
    }

    let changeFunciones = (tipos) => {
        setTipoSelect(tipos)
        let Content = [];

        if (tipos.value === "all"){
            Content = funcionesTmp;
        }else{
            Content = funcionesTmp.filter(option => option[0].status === tipos.value);
        }

        if (Content.length === 0) setPreload({icon: faTimesCircle, spin: false, text: "No existen coincidencias con la búsqueda seleccionada"})
        setFunciones(Content)
    }

    let listFunciones = () => {
        if (funciones.length === 0) return (
            <tr>
               <td colSpan = {7} align = "center">
                   <FontAwesomeIcon icon = {preload.icon} spin = {preload.spin} /> {preload.text}
               </td>
           </tr>
        )
        
        return (
            funciones.map(option => (
                <DetalleHistFuncion key = {option.id} data = {option} updList = {() => busquedaEvento(eventSelect.value)}/>
            ))
        )
    }

    const customStylesSelect = {
        menu: styles => ({ ...styles, color: "#000", fontSize: 14, zIndex: 3 }),
        multiValue: styles => ({ ...styles, backgroundColor: "#ccc" }),
        multiValueLabel: styles => ({ ...styles, color: "#000" }),
        placeholder: styles => ({ ...styles, fontSize: 14, color: "#b4bcc3" }),
    };

    return (
        <>
            <NavbarI />
            <main>
                <section className="section section-shaped section-lg">
                    <div className = "d-block d-sm-block d-md-block" align = "right" style = {{paddingRight: 15}}>
                        <small class = "bread-crumb-custom"><Link to = "/dashboard/home"><FontAwesomeIcon icon = {faHome}/></Link> | <FontAwesomeIcon icon = {faChartLine}/> Funciones</small>
                    </div>
                    <Row>
                        <Col md = {3} className = "d-none d-sm-none d-md-block">
                            <Sidebar active = "Funciones"/>
                        </Col>
                        <Col md = {9} className = "custom-p-32">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col md = {6}>
                                            <h5>Histórico de Funciones</h5>
                                            <div className = "text-muted" style = {{fontSize: 10, marginTop: -10}}>Funciones Culminadas y Vendidas</div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md = {4}>
                                            <small style = {{color: "#000"}}>Buscar por evento</small>
                                            <Select
                                                isLoading = {eventOpt.loading}
                                                isDisabled = {eventOpt.disabled}
                                                isSearchable = {true} styles = {customStylesSelect}
                                                className = "basic-multi-select"
                                                onChange = {changeEvento} options = {eventos}
                                                noOptionsMessage = {() => { return "Sin Opciones" }}
                                                loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                                                value = {eventSelect}
                                            />
                                        </Col>
                                        <Col md = {5}>
                                            <small style = {{color: "#000"}}>Buscar por Tipo de función</small>
                                            <Select
                                                isLoading = {functOpt.loading}
                                                isDisabled = {functOpt.disabled}
                                                isSearchable = {true} styles = {customStylesSelect}
                                                className = "basic-multi-select"
                                                onChange = {changeFunciones} options = {tipos}
                                                noOptionsMessage = {() => { return "Sin Opciones" }}
                                                loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                                                value = {tipoSelect}
                                            />
                                        </Col>
                                        <Col md = {3} align = "right">
                                            <Button color = "default" style = {{marginTop: 12}} onClick = {searchFunctions}><FontAwesomeIcon icon = {botonSearch.icon} spin = {botonSearch.spin} /> {botonSearch.text}</Button>
                                        </Col>
                                        <Col md = {12} className = "custom-mt-10">
                                            <small style = {{color: "#000"}}>Mis funciones</small>
                                            <div style = {{overflowX: "auto"}}>
                                                <Table hover style = {{fontSize: 10 }}>
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan = {2} width = "35%">Funciones</th>
                                                            <th width = "10%" align = "center">Fecha</th>
                                                            <th width = "15%">Capacidad</th>
                                                            <th width = "15%">Tickets vendidos</th>
                                                            <th width = "10%">Status</th>
                                                            <th width = "15%">Acciones</th>
                                                        </tr>
                                                        {listFunciones()}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </section>
            </main>
        </>
    )
}

export default MainHistFunciones;