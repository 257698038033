import React, { useState, useEffect } from 'react';

import {
    Row,
    Col,
    Table,
    CardBody,
    Card,
} from "reactstrap";

export default function DetailsPayTransferBank({ contentCompra, totalPago, contentImpuestos, subTotalEntradas, contentServTaquilla, totalAPagar, children }) {

    // Verifica si el navegador soporta el elemento details
    const [soportaDetails, setSoportaDetails] = useState(false);

    useEffect(() => {
        // Verifica si el navegador soporta el elemento details
        setSoportaDetails('open' in document.createElement('details'));
    }, []);

    // Para agregar mas bancos
    const banks = [
        {
            name: "BANPLUS",
            details: [
                { label: "RIF", value: "J-29671705-2" },
                { label: "Tipo de cuenta", value: "Corriente" },
                { label: "Número de cuenta", value: "0174 0131 99 1314913078" }
            ]
        },
        // Agregue más bancos aquí de la misma manera
    ];

    // Lista de datos bancarios
    const ListaBanco = ({ details }) => (
        <ul className="lista-banco">
            {details.map((detail, index) => (
                <li key={index} className="item-banco">
                    <strong className="destacado">{detail.label}:</strong> {detail.value}
                </li>
            ))}
        </ul>
    );


    return (
        <>
            <Table className="mb-0" hover style={{ fontSize: 10 }}>
                <tbody>
                    <div style={{ overflowY: "auto", maxHeight: "200px", Width: "100%" }}>
                        {contentCompra()}
                    </div>{" "}
                    <tr>
                        <td
                            colSpan={3}
                            className="text-primary"
                            align="center"
                            style={{ padding: 0, paddingTop: 30 }}
                        >
                            <strong>RESUMEN DE TU COMPRA</strong>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} style={{ padding: 5 }} align="left">
                            <strong className="text-info">VALOR DE ENTRADAS:</strong>
                        </td>
                        <td style={{ padding: 5 }} align="right">
                            <strong className="text-info">{totalPago()}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td
                            colSpan={3}
                            className="text-danger"
                            align="left"
                            style={{ padding: 5 }}
                        >
                            <strong>Impuestos</strong>
                        </td>
                    </tr>
                    {contentImpuestos()}
                    <tr>
                        <td
                            colSpan={2}
                            align="left"
                            style={{ padding: 5, paddingTop: 8, background: "#d3d8ef" }}
                        >
                            <strong>SUB TOTAL DE ENTRADAS</strong>
                        </td>
                        <td align="right" style={{ padding: 5, paddingTop: 10, background: "#d3d8ef" }}><strong>{subTotalEntradas()}</strong></td>
                    </tr>
                    {contentServTaquilla()}
                    <tr className="text-info" style={{ fontSize: 14 }}>
                        <td colSpan={2} align="left">
                            <strong>TOTAL A PAGAR:</strong>
                        </td>
                        <td align="right"><strong>{totalAPagar()}</strong></td>
                    </tr>
                </tbody>
            </Table>

            <div className="alert custom-mt-20" style={{ backgroundColor: "#077BD3", color: "#ffffff", marginTop: 20 }}>
                <Row>
                    <Col md={9}>
                        <div style={{ marginTop: 8 }}><strong>Formulario de registro de pago</strong></div>
                    </Col>
                    <Col md={3} align="right">
                        {/* <img src = {logoZelle2} alt = "logo-zelle" height = "auto" width = "70px" className = "img img-fluid"/> */}
                    </Col>
                </Row>
            </div>
            <Row>
                <Col className="custom-mt-10" md={2}>
                    <strong>PASO 1:</strong>
                </Col>
                <Col className="custom-mt-10" md={10}>

                    <p>Realice su pago mediante una transferencia bancaria a cualquiera de las siguientes entidades bancarias que ponemos a su disposición:</p>

                    {/* Renderizado condicional si el navegador no soporta details*/}
                    {banks.map((bank, index) => (
                        <div key={index}>
                            {soportaDetails ? (
                                <details  className="detalle-banco">
                                    <summary className="resumen-banco">{bank.name}</summary>
                                    <ListaBanco details={bank.details} />
                                </details>
                            ) : (
                                <>
                                    <h5 className='m-0'>{bank.name}</h5>
                                    <ListaBanco details={bank.details} />
                                    <hr className='m-2'/>
                                </>
                            )}
                        </div>
                    ))}
                    <span>El monto es: <strong className="text-danger">{totalAPagar()}</strong></span>
                </Col>
                <Col className="custom-mt-10" md={2}>
                    <strong>PASO 2:</strong>
                </Col>
                <Col className="custom-mt-10" md={10}>
                    <p>Una vez hayas realizado el pago, guarda una captura de pantalla del mismo y completa el formulario de aquí abajo.</p>
                    <p>¡Es necesario que registre su pago para que pueda recibir el ticket electrónico en su correo!</p>
                </Col>
                <Col className="custom-mt-10" md={2}>
                    <strong>PASO 3:</strong>
                </Col>
                <Col className="custom-mt-10" md={10}>
                    <p>Una vez hayas completado el registro del pago, es necesario que nuestro equipo lo verifique.</p>
                    <p>Recibirás tu boleto por correo o podrás buscarlo en la sección <strong>Mis compras</strong></p>
                </Col>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            {children}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
