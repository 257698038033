import { requerido, email, mensObligatorio, formatoIncorrecto } from "./Validaciones";
import { Post } from "./Generales";
import { Url } from "./Url";

export const ConsultDatPerfil = (callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"))
    
    let Content = {
        eid: data.E_ID
    }

    Post(`${Url}cperfil`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const ModPerfil = (campos, img, callback) => {

    let errores = {
        cantidad: 0,
        errMesss: []
    };

    /* Validaciones */
    requerido   (campos.name, errores,      mensObligatorio("Nombre"), (resp) => { errores = resp })
    requerido   (campos.lastName, errores,  mensObligatorio("Apellido"), (resp) => { errores = resp })
    requerido   (campos.email, errores,     mensObligatorio("Correo Electrónico"), (resp) => { errores = resp })
    requerido   (campos.ci, errores,  mensObligatorio("Cédula de Identidad"), (resp) => { errores = resp })
    requerido   (campos.tlf, errores,     mensObligatorio("Telefono"), (resp) => { errores = resp })
    email       (campos.email, errores,     formatoIncorrecto("Email"), (resp) => { errores = resp })

    if (errores.cantidad === 0){
        let data = JSON.parse(sessionStorage.getItem("ContentData"))

        let Content = {
            eid: data.E_ID,
            uid: data.U_ID,
            name: campos.name,
            lastName: campos.lastName,
            email: campos.email,
            ci: campos.ci,
            tlf: campos.tlf,
            img: img,
            imgSrc: campos.file1,
            imgSelect: campos.imgSelect
        }

        Post(`${Url}modPerfil`, Content, (resp) => {
            let data = JSON.parse(resp)
            callback([errores, data])
        });
    }else{
        callback([errores])
    }
}

export const RegTarjeta = (campos, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"))

    let errores = {
        cantidad: 0,
        errMesss: []
    };

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        id_usuario: data.id_user,
        nro_tarjeta : campos.nroTarjeta,
        cvv : campos.cvv,
        cid : campos.cid,
        expire_date : campos.fecha,
        nombre_cliente : campos.cliente,
        alias : campos.alias,
    }


    Post(`${Url}registrarTDC`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback([errores, data])
    });}

    export const ConsultarTDC = (campos, callback) => {
        let data = JSON.parse(sessionStorage.getItem("ContentData"))
        
        let Content = {
            eid: data.E_ID,
            uid: data.U_ID,
            id_user: data.id_user,
            tdc_id: campos.tdc_id,
        }
    
        Post(`${Url}consultarTDC`, Content, (resp) => {
            let data = JSON.parse(resp)
            callback(data)
        });
    }

export const NewPass = (campos, callback) => {
    let errores = {
        cantidad: 0,
        errMesss: []
    };

    /* Validaciones */
    requerido   (campos.cPass, errores, mensObligatorio("Contraseña Actual"), (resp) => { errores = resp })
    requerido   (campos.pass1, errores, mensObligatorio("Nueva Contraseña"), (resp) => { errores = resp })
    requerido   (campos.pass2, errores, mensObligatorio("Confirma la Nueva Contraseña"), (resp) => { errores = resp })

    if(campos.pass1 !== campos.pass2){
        let mess = errores.errMesss;
        mess.push(`Las contraseñas <strong class = "text-danger">No coinciden</strong>`)
        
        errores = {
            cantidad: (errores.cantidad + 1),
            errMesss: mess
        }
    }

    if (errores.cantidad === 0){
        let data = JSON.parse(sessionStorage.getItem("ContentData"))

        let Content = {
            eid: data.E_ID,
            uid: data.U_ID,
            currentPass: campos.cPass,
            pass1: campos.pass1,
            pass2: campos.pass2
        }

        Post(`${Url}changePass`, Content, (resp) => {
            let data = JSON.parse(resp)
            callback([errores, data])
        });
    }else{
        callback([errores])
    }
}