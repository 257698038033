import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { Button, Card, CardHeader, CardBody, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col } from "reactstrap";

import NavbarI from "components/Navbars/NavbarI.js";
import CardsFooter from "components/Footers/CardsFooter.js";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faCheckCircle, faTimesCircle, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { VerifyUrlPass, RestartPassUrl } from 'functions/UserFunctions';
import ErrorModal2 from 'components/Modals/ErrorModal2';
import SuccessModal from 'components/Modals/SuccessModal';

const ResetPass = (props) => {
    const [pass, setPass]           = useState("");
    const [pass2, setPass2]         = useState("");
    const [verify, setVerify]       = useState({
        icon: faCircleNotch,
        text: "Comprobando, espera",
        class: "bg-gradient-success",
        spin: true
    });
    const [button, setButton]       = useState({
        icon: faUnlockAlt,
        text: "Cambiar Contraseña",
        spin: false,
        disabled: true
    });
    const [openModal, setOpenModal] = useState(false);
    const [message, setMessage]     = useState("");
    const [modalMess, setModalMess] = useState("");
    const [redirect, setRedirect]   = useState(false);
    const [openModalOk, setOpenModalOk]     = useState(false);
    const [modalOkTitle, setModalOkTitle]   = useState("");

    useEffect(() => {
        let token = getToken()
        if (token === undefined){
            setVerify({
                icon: faTimesCircle,
                text: "Enlace inválido",
                class: "bg-gradient-danger",
                spin: false
            })
        }else{
            VerifyUrlPass (token.id, (resp) => {
                setButton (state => ({...state, disabled: false }))
                if (resp.status === "error"){
                    setVerify({
                        icon: faTimesCircle,
                        text: resp.mess,
                        class: "bg-gradient-danger",
                        spin: false
                    })
                }else{
                    setVerify({
                        icon: faCheckCircle,
                        text: resp.mess,
                        class: "bg-gradient-success",
                        spin: false
                    })
                }
            })
        }
    }, []);

    let getToken = () => {
        let loc = document.location.href;
        if(loc.indexOf('?') > 0){
            let getString = loc.split('?')[1];
            let GET = getString.split('&');
            let get = {};
            for(let i = 0, l = GET.length; i < l; i++){
                let tmp = GET[i].split('=');
                get[tmp[0]] = unescape(decodeURI(tmp[1]));
            }
            return get;
        }
    }

    let restartPassw = () => {
        switch (true) {
            case (pass.trim() === ""):
                setMessage("Debes indicar la <strong>contraseña</strong>")
                setOpenModal(true)
            break;
            case (pass2.trim() === ""):
                setMessage("Debes repetir la <strong>contraseña</strong>")
                setOpenModal(true)
            break;
            case (pass !== pass2):
                setMessage("Las contraseñas <strong>no coinciden</strong>")
                setOpenModal(true)
            break;
            default:
                setButton({
                    icon: faCircleNotch,
                    text: "Guardando, Espera",
                    spin: true,
                    disabled: true
                });

                let token = getToken();
                RestartPassUrl (token.id, pass, pass2, (resp) => {
                    if (resp.status === "error"){
                        setMessage(resp.mess)
                        setOpenModal(true)
                    }else{
                        setModalMess(resp.mess)
                        setModalOkTitle("Datos actualizados")
                        setOpenModalOk(true)
                    }

                    setButton({
                        icon: faUnlockAlt,
                        text: "Cambiar contraseña",
                        spin: false,
                        disabled: false
                    });
                })
            break;
        }
    }

    if (redirect === true) return <Redirect to="/login" />

    return (
        <>
            <NavbarI />
            <main>
                <section className = "section section-shaped section-lg">
                    <Container className = "pt-lg-6">
                        <Row className = "justify-content-center">
                            <Col lg = "7">
                                <Card className="bg-blur shadow border-0">
                                    <CardHeader className = "bg-warning">
                                        <h5 style = {{color: "#ffffff"}}>Restaura tu contraseña</h5>
                                        <div style = {{fontSize: 10, marginTop: -10, color: "#ffffff"}}>Completa todos los campos para continuar</div>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col md = {12}>
                                                <div className = {verify.class} style = {{color: "#ffffff", borderRadius: "4px 4px", padding: 5}}><FontAwesomeIcon icon = {verify.icon} spin = {verify.spin}/> <span dangerouslySetInnerHTML = {{ __html: verify.text }}></span></div>
                                            </Col>
                                            <Col md = {12} style = {{marginTop: 10}}>
                                                <InputGroup className="input-group-alternative">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="ni ni-lock-circle-open" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder = "Nueva contraseña" type = "password" autoComplete = "off" value = {pass} onChange = {(e) => setPass(e.target.value)}/>
                                                </InputGroup>
                                            </Col>
                                            <Col md = {12} style = {{marginTop: 10}}>
                                                <InputGroup className="input-group-alternative">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="ni ni-lock-circle-open" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder = "Repite la contraseña" type = "password" autoComplete = "off" value = {pass2} onChange = {(e) => setPass2(e.target.value)}/>
                                                </InputGroup>
                                            </Col>
                                            <Col md = {12} style = {{marginTop: 10}} align = "right">
                                                <Button color="default" type="button" disabled = {button.disabled}  onClick = {restartPassw}>
                                                    <FontAwesomeIcon icon = {button.icon} spin = {button.spin}/> <small>{button.text}</small>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <CardsFooter />
            <ErrorModal2 isOpen = {openModal} close = {() => {setOpenModal(false)}} mess = {message}/>
            <SuccessModal isOpen = {openModalOk} close = {() => {
                setOpenModalOk(false)
                setRedirect(true)
            }} content = {modalMess} contentTitle = {modalOkTitle}/>
        </>
    )
}

export default ResetPass;