import React, { useEffect, useState } from 'react';
import { Modal } from "reactstrap";

const SuccessModal = (props) => {
    const [open, setOpen] = useState(props.isOpen);

    useEffect(() => {
        setOpen(props.isOpen)
    }, [props]);

    return (
        <Modal className = "modal-dialog-centeredmodal modal-success" contentClassName="bg-gradient-success" size = "md" isOpen = {open} toggle = {() => props.close()}>
            <div className="modal-header">
                <h6 className="modal-title" id="modal-title-notification" style = {{color: "#ffffff", fontWeight: "bold"}}>
                    <i className = "fa fa-check-circle"></i> {props.contentTitle}
                </h6>
                <button aria-label = "Close" className = "close" style = {{color: "#ffffff"}} data-dismiss = "modal" type = "button" onClick = {() => props.close()}>
                <span aria-hidden = {true} style = {{color: "#ffffff"}}>×</span>
                </button>
            </div>
            <div className="modal-body" style = {{background: "#ffffff", color: "#636363"}} align = "center"  dangerouslySetInnerHTML = {{ __html: props.content }}></div>
        </Modal>
    )
}

export default SuccessModal;