import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from 'react-toastify';
import {Doughnut} from 'react-chartjs-2';
import {Chart, ArcElement} from 'chart.js'
import { Button, Card, CardBody, CardHeader, Collapse, Row, Col, Table, Alert } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faCircleNotch, faTimesCircle, faHome, faChevronDown, faArrowUp, faChevronUp, faMoneyBill, faSearch, faFileExport } from '@fortawesome/free-solid-svg-icons';

import NavbarI from "components/Navbars/NavbarI.js";
import Sidebar from '../dashboard/Sidebar';

import { SearchEvents, SearchFunctions, searchTicketSold, generatePdfTicketSold } from "functions/EventosFunctions";
import DetalleVenta from "./DetalleVenta";
import ContentModal2 from "components/Modals/ContentModal2";

Chart.register(ArcElement);

const MainVentas = (props) => {
    const [eventSelect, setEventSelect] = useState({value: "", label: "Selecciona"});
    const [eventos, setEventos]         = useState([]);
    const [eventOpt, setEventOpt]       = useState({ disabled: true, loading: true })
    const [funcionSelect, setFuncionSelect] = useState({value: "", label: "Selecciona", recinto: ""});
    const [funciones, setFunciones]         = useState([]);
    const [funcionesOpt, setFuncionesOpt]   = useState({ disabled: false, loading: false })
    const [botonSearch, setBotonSearch]     = useState({ icon: faSearch, spin: false, disabled: false, text: "Buscar" })
    const [botonPdf, setBotonPdf]           = useState({ icon: faFileExport, spin: false, disabled: false, text: "Generar PDF" })
    const [monedaPred, setMonedaPred]       = useState("");
    const [totalVenta, setTotalVenta]       = useState(0);
    const [preload, setPreload]             = useState({icon: faArrowUp, spin: false, text: "Selecciona un evento y una función"});
    const [ventas, setVentas]               = useState([]);
    const [estadistica, setEstadistica]     = useState([]);
    const [estadisticaTotal, setEstadisticaTotal] = useState({});
    const [colaps, setColaps]               = useState(false);
    const [colapsIconS, setColapsIconS]     = useState(false);
    const [colapsIcon, setColapsIcon]       = useState(faChevronDown);

    // Modal PDF
    const [src, setSrc]                     = useState("")
    const [openModalPdf, setOpenModalPdf]   = useState(false)

    useEffect(() => {
        let getByStatus = [0, 4];
        SearchEvents(getByStatus, (resp) => {
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                break;
                default:
                    setEventOpt({ disabled: false, loading: false })

                    let Content = [];
                    (resp.data).forEach(option => {
                        Content.push({
                            value: option.id,
                            label: option.name_event,
                            recinto: option.id_recinto
                        });
                    })

                    setEventos(Content);
                break;
            }
        })
    }, [props]);

    const customStylesSelect = {
        menu: styles => ({ ...styles, color: "#000", fontSize: 14, zIndex: 3 }),
        multiValue: styles => ({ ...styles, backgroundColor: "#ccc" }),
        multiValueLabel: styles => ({ ...styles, color: "#000" }),
        placeholder: styles => ({ ...styles, fontSize: 14, color: "#b4bcc3" }),
    };

    let changeEvento = (eventSelect) => {
        setEventSelect(eventSelect)
        setFuncionesOpt({disabled: true, loading: true})
        
        SearchFunctions(eventSelect.value, (resp) => {
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                break;
                default:
                    setFuncionesOpt({disabled: false, loading: false})
                    setFuncionSelect({ value: "", label: "Selecciona" })
                    let Content = [];
                    (resp.data).forEach(option => {
                        Content.push({
                            value: option.id,
                            label: `Funcion del ${option.fecha} - Hora: [${option.hora_in} - ${option.hora_out}]`
                        });
                    });

                    setFunciones(Content);
                break;
            }
        })
    }

    let changeFuncion = (funcionSelect) => {
        setFuncionSelect(funcionSelect)        
    }

    let searchTickets = () => {
        switch (true) {
            case (eventSelect.value === ""):
                toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> Selecciona primero un evento</small>);
            break;
            case (funcionSelect.value === ""):
                toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> Selecciona una función</small>);
            break;
            default:
                if (botonSearch.disabled === true) return null;

                setEventOpt(state => ({...state, disabled: true}))
                setFuncionesOpt(state => ({...state, disabled: true}))
                setBotonSearch({ icon: faCircleNotch, spin: true, disabled: true, text: "Buscando" })

                searchTicketSold(eventSelect.value, funcionSelect.value, (resp) => {
                    setEventOpt(state => ({...state, disabled: false}))
                    setFuncionesOpt(state => ({...state, disabled: false}))
                    setBotonSearch({ icon: faSearch, spin: false, disabled: false, text: "Buscar" })

                    switch (true) {
                        case (resp.status === "error" || resp.status === "error_log"):
                            toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                        break;
                        case (resp.data.length === 0):
                            setPreload({icon: faTimesCircle, spin: false, text: "Esta función aún no tiene ventas registradas"})
                            setTotalVenta(0)
                            setVentas([])
                        break;
                        default:
                            updLista(resp);
                        break;
                    }
                })
            break;
        }
    }

    let generatePdf = () => {
        switch (true) {
            case (eventSelect.value === ""):
                toast.error(<small><FontAwesomeIcon icon={faTimesCircle} /> Selecciona primero un evento</small>);
                break;
            case (funcionSelect.value === ""):
                toast.error(<small><FontAwesomeIcon icon={faTimesCircle} /> Selecciona una función</small>);
                break;
            default:
                if (botonPdf.disabled === true) return null;

                setBotonPdf({ icon: faCircleNotch, spin: true, disabled: true, text: "Generando" })
                generatePdfTicketSold(eventSelect.value, funcionSelect.value, (resp) => {

                    setBotonPdf({ icon: faFileExport, spin: false, disabled: false, text: "Generar PDF" })

                    switch (true) {
                        case (resp?.status === "error" || resp.status === "error_log"):
                            toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                        break;
                        default:
                            setSrc(resp);
                            setOpenModalPdf(true);
                        break;
                    }
                })
            break;
        }
    }

    let modalPdf = () => {
        if (openModalPdf === false) return null;

        let contentModal = () => (
            <div style = {{height: "74vh"}}>
                <embed
                    type = "application/pdf"
                    src = {src}
                    id = "pdfDocument"
                    width = "100%"
                    height = "100%"
                />
            </div>
        )

        return (
            <ContentModal2
                isOpen = {openModalPdf}
                title = {`Detalles de los tickets vendidos`}
                size = "xl"
                content = {contentModal()}
                close = {() => {setOpenModalPdf(false)}}
            />
        )
    }

    let updLista = (resp) => {
        let total = 0;
        let moneda = "";
        (resp.data).forEach(i => {
        let detalleTicket = i.data_ticket.detalle_pago;

        if(detalleTicket !== ""){
            let detalleFactura = JSON.parse(detalleTicket);

            let tickets = detalleFactura.dataTickets
            let impuestos = detalleFactura.dataImpuestos
            let servTaquilla = detalleFactura.taquilla

            let impTaquilla = detalleFactura.dataImpTaquilla
            let aplicable = false;
            if (detalleFactura.taquilla !== null && detalleFactura.taquilla !== "") aplicable = Boolean(servTaquilla.aplica);

            let cant = 0;
            tickets.forEach(opt => {
                moneda = opt.moneda;
                cant += parseFloat(opt.precio);
            })


            // impuestos.forEach(opt => cant += parseFloat(opt.monto))
            if (aplicable === true) cant += parseFloat(servTaquilla.valor * cant / 100);
            // impTaquilla.forEach(opt => cant += parseFloat(opt.monto))

            total += parseFloat(cant);
        }
        })

        let ContentEst = [];
        (resp.distribucion).forEach(i => {
            const filter = (resp.detalleTicket).filter(option => option.zona_dist === i.id);
            
            ContentEst.push({
                id: i.id,
                capacidad: i.capacidad,
                nombre: i.zona_detalle.nombre_zona,
                vendidos: filter.length,
                detalleVendidos: filter
            })
        })
        
        // Calcular el total de capacidades y boletos vendidos
        let totalCapacidad = 0;
        let totalVendidos = 0;

        ContentEst.forEach(est => {
            totalCapacidad += est.capacidad;
            totalVendidos += est.vendidos;
        });

        // Crear el objeto con el total
        let ContentEstTotal = {
            nombre: "Total",
            totalCapacidad: totalCapacidad,
            totalVendidos: totalVendidos,
        };
        
        setEstadistica(ContentEst);
        setEstadisticaTotal(ContentEstTotal);
        setMonedaPred(moneda);
        setTotalVenta(total.toFixed(2));
        setVentas(resp.data);
    }

    let ventasList = () => {
        if (ventas.length === 0) return (
            <tr>
                <td colSpan = {6} align = "center">
                    <FontAwesomeIcon icon = {preload.icon} spin = {preload.spin} /> {preload.text}
                </td>
            </tr>
        )
    
        let num = 0;
        return (
            ventas.filter(option => option.data_ticket.detalle_pago !== "").map(option => {
                // La condición ya fue aplicada en el filtro, por lo tanto, todos los elementos aquí tienen detalle_pago no vacío.
                num++;
                return (
                    <DetalleVenta key = {option.id} data = {option} num = {num} updLista = {(data) => updLista(data)}/>
                )
            })
        )
    }
    

    let iconC = () => {
        setColapsIconS(!colapsIconS);
        setColaps(!colaps);

        (colapsIconS === false) ? setColapsIcon(faChevronUp) : setColapsIcon(faChevronDown);
    }

    let estadisticaData = () => {
        if (estadistica.length === 0) return (
            <div align = "center">
                Debes buscar primero un evento y una función
            </div>
        )

        let grafico = (data) => {

            let dataEst = {
                labels: [ 'Vendidos', 'Disponibles' ],
                datasets: [{
                    data: [data.vendidos, (data.capacidad - data.vendidos)],
                    backgroundColor: [ '#3498DB', '#DE932E' ],
                    hoverBackgroundColor: [ '#337ab7', '#DE932E' ]
                }]
            }

            return (
                <Doughnut data = {dataEst}/>
            )
        }

        let graficoTotal = (data) => {

            let dataEst = {
                labels: [ 'Vendidos', 'Disponibles' ],
                datasets: [{
                    data: [data.totalVendidos, (data.totalCapacidad - data.totalVendidos)],
                    backgroundColor: [ '#3498DB', '#DE932E' ],
                    hoverBackgroundColor: [ '#337ab7', '#DE932E' ]
                }]
            }

            return (
                <Doughnut data = {dataEst}/>
            )
        }

        let content = () => (
            estadistica.map(option => (
                <Col md = {2} key = {option.id} align = "center">
                    <Card>
                        <CardBody align = "left">
                            <div align = "center"><small><strong>Zona</strong> {option.nombre}</small></div>
                            <div>{grafico(option)}</div>
                            <div><small><FontAwesomeIcon icon = {faSquare} style = {{color: "#3498DB"}}/> <strong>Vendidas</strong> {option.vendidos}</small></div>
                            <div><small><FontAwesomeIcon icon = {faSquare} style = {{color: "#DE932E"}}/> <strong>Por vender </strong> {option.capacidad - option.vendidos}</small></div>
                            <div><small><strong>Capacidad</strong> {option.capacidad}</small></div>
                        </CardBody>
                    </Card>
                </Col>
            ))
        )

        let contentTotal = () => (
            <Col md={{
                offset: 1,
                size: 3
            }} align="center">
                <Card>
                    <CardBody align="left">
                        <div align="center"><strong>{estadisticaTotal.nombre}</strong></div>
                        <div>{graficoTotal(estadisticaTotal)}</div>
                        <div><small><FontAwesomeIcon icon={faSquare} style={{ color: "#3498DB" }} /> <strong>Vendidas</strong> {estadisticaTotal.totalVendidos}</small></div>
                        <div><small><FontAwesomeIcon icon={faSquare} style={{ color: "#DE932E" }} /> <strong>Por vender </strong> {estadisticaTotal.totalCapacidad - estadisticaTotal.totalVendidos}</small></div>
                        <div><small><strong>Capacidad</strong> {estadisticaTotal.totalCapacidad}</small></div>
                    </CardBody>
                </Card>
            </Col>
        )
        return (
            <Row>
                {content()}
                {contentTotal()}
            </Row>
        )
    }

    return (
        <>
            <NavbarI />
            <main>
                <section className="section section-shaped section-lg">
                    <div className = "d-block d-sm-block d-md-block" align = "right" style = {{paddingRight: 15}}>
                        <small class = "bread-crumb-custom"><Link to = "/dashboard/home"><FontAwesomeIcon icon = {faHome}/></Link> | <FontAwesomeIcon icon = {faMoneyBill}/> Mis Ventas</small>
                    </div>
                    <Row>
                        <Col md = {3} className = "d-none d-sm-none d-md-block">
                            <Sidebar active = "Mis Ventas"/>
                        </Col>
                        <Col md = {9} className = "custom-p-32">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col md = {6}>
                                            <h5>Mis ventas</h5>
                                            <div className = "text-muted" style = {{fontSize: 10, marginTop: -10}}>Selecciona un evento para continuar</div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md = {3}>
                                            <small style = {{color: "#000"}}>* Mis eventos</small>
                                            <Select
                                                isLoading = {eventOpt.loading}
                                                isDisabled = {eventOpt.disabled}
                                                isSearchable = {true} styles = {customStylesSelect}
                                                className = "basic-multi-select"
                                                onChange = {changeEvento} options = {eventos}
                                                noOptionsMessage = {() => { return "Sin Opciones" }}
                                                loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                                                value = {eventSelect}
                                                placeholder = "Eventos"
                                            />
                                        </Col>
                                        <Col md = {3}>
                                            <small style = {{color: "#000"}}>* Funciones</small>
                                            <Select
                                                isLoading = {funcionesOpt.loading}
                                                isDisabled = {funcionesOpt.disabled}
                                                isSearchable = {true} styles = {customStylesSelect}
                                                className = "basic-multi-select"
                                                onChange = {changeFuncion} options = {funciones}
                                                noOptionsMessage = {() => { return "Sin Opciones" }}
                                                loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Buscando funciones</> }}
                                                value = {funcionSelect}
                                                placeholder = "Funciones"
                                            />
                                        </Col>
                                        <Col md = {2} align = "center">
                                            <Button color = "default" style = {{marginTop: 12}} onClick = {searchTickets}><FontAwesomeIcon icon = {botonSearch.icon} spin = {botonSearch.spin} /> {botonSearch.text}</Button>
                                        </Col>
                                        <Col md = {2} align = "center">
                                            <Button color = "warning" style = {{marginTop: 12}} onClick = {generatePdf}><FontAwesomeIcon icon = {botonPdf.icon} spin = {botonPdf.spin} /> {botonPdf.text}</Button>
                                        </Col>
                                        <Col md = {2} align = "center">
                                            <small style = {{color: "#000"}}>Total vendido</small>
                                            <div className = "text-danger" style = {{fontSize: 18, fontWeight: "bold"}}>{`${monedaPred} ${totalVenta}`}</div>
                                        </Col>
                                        <Col md = {12} className = "custom-mt-10">
                                            <Alert color = "primary" onClick = {iconC} style = {{cursor: "pointer"}}>
                                                <i className = "fa fa-line-chart"/> ESTADÍSTICAS DE VENTA
                                                <span style = {{position: "absolute", right: 11, top: 16}}><FontAwesomeIcon icon = {colapsIcon}/></span>
                                            </Alert>
                                            <Collapse isOpen = {colaps}>
                                                <Card style = {{paddingBottom: 10, backgroundColor: "#edeef0"}}>
                                                    <CardBody>
                                                        {estadisticaData()}
                                                    </CardBody>
                                                </Card>
                                            </Collapse>
                                        </Col>
                                        <Col md = {12}>
                                            <small style = {{color: "#000"}}>Entradas vendidas</small>
                                            <div style = {{overflowX: "auto"}}>
                                                <Table hover style = {{fontSize: 10 }}>
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan = {2} width = "15%">N° Factura</th>
                                                            <th width = "20%">Tickets</th>
                                                            <th width = "50%">Detalles del pago</th>
                                                            <th width = "15%">Acciones</th>
                                                        </tr>
                                                        {ventasList()}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </section>
            </main>
            {modalPdf()}
        </>
    )
}

export default MainVentas;