
export const categories = [
  {
    id: 1,
    name: "Música",
    desc: "Eventos de música",
    to: "/",
    icon: "",
  },
  {
    id: 2,
    name: "Teatro",
    desc: "Eventos de teatro",
    to: "/",
    icon: "",
  },
  {
    id: 4,
    name: "Deporte",
    desc: "Eventos de deporte",
    to: "/",
    icon: "",
  },
]

export const BancosVenezuela = [
  {
      "codigo": "0001",
      "nombre": "Banco Central de Venezuela",
      "rif": "G200001100"
  },
  {
      "codigo": "0102",
      "nombre": "Banco de Venezuela S.A.C.A. Banco Universal",
      "rif": "G200099976"
  },
  {
      "codigo": "0104",
      "nombre": "Venezolano de Crédito, S.A. Banco Universal",
      "rif": "J000029709"
  },
  {
      "codigo": "0105",
      "nombre": "Banco Mercantil, C.A. Banco Universal",
      "rif": "J000029610"
  },
  {
      "codigo": "0108",
      "nombre": "Banco Provincial, S.A. Banco Universal",
      "rif": "J000029679"
  },
  {
      "codigo": "0114",
      "nombre": "Bancaribe C.A. Banco Universal",
      "rif": "J000029490"
  },
  {
      "codigo": "0115",
      "nombre": "Banco Exterior C.A. Banco Universal",
      "rif": "J000029504"
  },
  {
      "codigo": "0116",
      "nombre": "Banco Occidental de Descuento, Banco Universal C.A",
      "rif": "J300619460"
  },
  {
      "codigo": "0128",
      "nombre": "Banco Caroní C.A. Banco Universal",
      "rif": "J095048551"
  },
  {
      "codigo": "0134",
      "nombre": "Banesco Banco Universal S.A.C.A.",
      "rif": "J070133805"
  },
  {
      "codigo": "0137",
      "nombre": "Banco Sofitasa, Banco Universal",
      "rif": "J090283846"
  },
  {
      "codigo": "0138",
      "nombre": "Banco Plaza, Banco Universal",
      "rif": "J002970553"
  },
  {
      "codigo": "0146",
      "nombre": "Banco de la Gente Emprendedora C.A",
      "rif": "J301442040"
  },
  {
      "codigo": "0151",
      "nombre": "BFC Banco Fondo Común C.A. Banco Universal",
      "rif": "J000723060"
  },
  {
      "codigo": "0156",
      "nombre": "100% Banco, Banco Universal C.A.",
      "rif": "J085007768"
  },
  {
      "codigo": "0157",
      "nombre": "DelSur Banco Universal C.A.",
      "rif": "J000797234"
  },
  {
      "codigo": "0163",
      "nombre": "Banco del Tesoro, C.A. Banco Universal",
      "rif": "G200051876"
  },
  {
      "codigo": "0166",
      "nombre": "Banco Agrícola de Venezuela, C.A. Banco Universal",
      "rif": "G200057955"
  },
  {
      "codigo": "0168",
      "nombre": "Bancrecer, S.A. Banco Microfinanciero",
      "rif": "J316374173"
  },
  {
      "codigo": "0169",
      "nombre": "Mi Banco, Banco Microfinanciero C.A.",
      "rif": "J315941023"
  },
  {
      "codigo": "0171",
      "nombre": "Banco Activo, Banco Universal",
      "rif": "J080066227"
  },
  {
      "codigo": "0172",
      "nombre": "Bancamica, Banco Microfinanciero C.A.",
      "rif": "J316287599"
  },
  {
      "codigo": "0173",
      "nombre": "Banco Internacional de Desarrollo, C.A. Banco Universal",
      "rif": "J294640109"
  },
  {
      "codigo": "0174",
      "nombre": "Banplus Banco Universal, C.A",
      "rif": "J000423032"
  },
  {
      "codigo": "0175",
      "nombre": "Banco Bicentenario del Pueblo de la Clase Obrera, Mujer y Comunas B.U.",
      "rif": "G200091487"
  },
  {
      "codigo": "0176",
      "nombre": "Novo Banco, S.A. Sucursal Venezuela Banco Universal",
      "rif": "J308918644"
  },
  {
      "codigo": "0177",
      "nombre": "Banco de la Fuerza Armada Nacional Bolivariana, B.U.",
      "rif": "G200106573"
  },
  {
      "codigo": "0190",
      "nombre": "Citibank N.A.",
      "rif": "J000526621"
  },
  {
      "codigo": "0191",
      "nombre": "Banco Nacional de Crédito, C.A. Banco Universal",
      "rif": "J309841327"
  },
  {
      "codigo": "0601",
      "nombre": "Instituto Municipal de Crédito Popular",
      "rif": "G200068973"
  }
]