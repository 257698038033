import React, {useEffect, useState} from "react";
import { Button, Row, Col } from "reactstrap";
import Switch from "react-switch";
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faSquare, faSave, faCheck, faCircleNotch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import SuccessModal from "components/Modals/SuccessModal";
import ConfirmModal from "components/Modals/ConfirmModal";

import { AddZoneAudit } from "functions/SitiosFunction";

const ZonaAuditorio = (props) => {
    const [botonAdd, setBotonAdd]   = useState({ icon: faSave, text: "Guardar Distribución", spin: false, disabled: false })
    const [botonYes, setBotonYes]   = useState({ icon: faCheck, text: "Si", spin: false, disabled: false })
    const [column, setColumn]       = useState(props.ancho)
    const [fila, setFila]           = useState(props.alto)
    const [color, setColor]         = useState("")
    const [zonaS, setZonaS]         = useState(props.zona)
    const [check, setCheck]         = useState(false)
    const [zona, setZona]           = useState([])
    const [control, setControl]     = useState(false)
    const [agregar, setAgregar]     = useState(true)
    const [zonasDisp, setZonaDisp]  = useState([])
    const [openModalCon, setOpenModalCon]   = useState(false)
    const [openModalOk, setOpenModalOk]     = useState(false)
    const [modalOkTitle, setModalOkTitle]   = useState("")
    const [modalMess, setModalMess]         = useState("")

    useEffect(() => {
        setZonaS(props.zona);
        if(props.control === true ) genZona();
        (fila !== "" && column !== "" && zona.length === 0) ? genZona() : conZones();

        (props.data.alto === "" || props.data.alto === undefined) ? setColumn(props.alto) : setColumn(props.data.alto);
        (props.data.ancho === "" || props.data.ancho === undefined) ? setFila(props.ancho) : setFila(props.data.ancho);

        if(props.data.auditZona !== undefined && (props.data.auditZona).length > 0) setZona(props.data.auditZona);
        if(props.data.zonasCat !== undefined && (props.data.zonasCat).length > 0) setZonaDisp(props.data.zonasCat);

    }, [props]);

    let conZones = () => {
        let contentZones = zonasDisp;
        const search = contentZones.find(option => option.id === props.zona.value);
        if (search === undefined){
            if (props.zona.value !== undefined) contentZones.push({
                id: props.zona.value,
                nombre: props.zona.label,
                zona: props.zona,
                color: props.color
            })
        }else{
            contentZones.forEach(i => {
                if (i.id === props.zona.value && i.id !== ''){
                    i.color = props.color;
                }
            })
        }

        setColor (props.color)
        setZonaDisp(contentZones)
    }

    let genZona = () => {
        let colums = (fila) => {
            let contentC = []
            for (let n = 0; n < column; n++){
                contentC.push({
                    id: `${fila}_${n}`,
                    id_fila: fila,
                    zona: "",
                    disabled: true
                })
            }

            return contentC
        }

        let contentF = []
        for (let n = 0; n < fila; n++){
            contentF.push({
                id: n,
                columnas: colums(n)
            })
        }

        if(props.zona !== undefined){
            let contentZones = zonasDisp;
            
            const search = contentZones.find(option => option.id === props.zona.value);
            if (search === undefined){
                if (props.zona.value !== undefined) contentZones.push({
                    id: props.zona.value,
                    nombre: props.zona.label,
                    zona: props.zona,
                    color: props.color
                })
            }else{
                contentZones.forEach(i => {
                    if (i.id === props.zona.value && i.id !== ''){
                        i.color = props.color;
                    }
                })
            }

            setColor (props.color)
            setZonaDisp(contentZones)
        }
        
        setZona(contentF)
    }

    let updZonaMouse = (id, filaId) => {
        if (check === false) return null;
        if (zonaS === undefined) {
            toast.error(<small><FontAwesomeIcon icon = {faExclamationTriangle}/> Debes seleccionar primero una zona</small>);
            setCheck(false)
        }else{
            let content = zona;
            content.forEach(i => {
                if(i.id === filaId){
                    (i.columnas).forEach(col => {
                        if(col.id === id){
                            if (agregar === true){
                                if(col.disabled === true) {
                                    col.color = color;
                                    col.disabled = false;
                                    col.zona = zonaS;
                                }
                            }else{
                                col.color = "#ffffff";
                                col.disabled = true;
                                col.zona = "";
                            }
                        }
                    })
                }
            })
    
            setZona(content)
            setControl(!control)
        }
    }

    let updZona = (id, filaId) => {
        if (check === true) return null;
        if (zonaS === undefined || zonaS.length === 0) {
            toast.error(<small><FontAwesomeIcon icon = {faExclamationTriangle}/> Debes seleccionar primero una zona</small>);
            setCheck(false)
        }else{
            let content = zona;
            content.forEach(i => {
                if(i.id === filaId){
                    (i.columnas).forEach(col => {
                        if(col.id === id){
                            (col.disabled === false) ? col.color = "#ffffff" : col.color = color;
                            (col.disabled === false) ? col.zona = "" : col.zona = zonaS;
                            col.disabled = !col.disabled;
                        }
                    })
                }
            })

            setZona(content)
            setControl(!control)
        }
    }

    let zonaT = () => {
        let columnas = (data) => (
            data.map(option => (
                <td key = {option.id} width = {10} height = {10} onMouseOver = {() => {updZonaMouse(option.id, option.id_fila)}} onClick = {() => {updZona(option.id, option.id_fila)}} className = "zona-default" style = {{backgroundColor: option.color, cursor: "pointer"}}></td>
            ))
        )

        return zona.map(fila => (
            <tr key = {`${fila.id}_${control}`}>{columnas(fila.columnas)}</tr>
        ))
    }

    let cambiaZona = (data) => {
        setColor(data.color)
        setZonaS(data.zona)
    }

    let zonaLeyenda = () => {
        if(zonasDisp.length === 0) return(
            <div>No has seleccionado ninguna zona</div>
        )

        let styles = (zona, color) => {
            if (zona === zonaS) return ({cursor: "pointer", fontWeight: "bold", color: color, fontSize: 12})
            return  ({cursor: "pointer", fontSize: 12})
        }
        
        return (
            zonasDisp.map(option => {
                if (option.id === "") return null
                return(
                    <small key = {option.id} style = {styles(option.zona, option.color)} onClick = {() => cambiaZona(option)} title = "Selecciona para definir la zona" className = "zona-default"><FontAwesomeIcon icon = {faSquare} style = {{color: option.color}}/> {option.nombre} | </small>
                )
            })
        )
    }

    let saveZoneAudit = () => {
        setBotonYes({icon: faCircleNotch, text: "Si", spin: true, disabled: true})
        switch (true){
            case (column === ""):
                toast.error(<small><FontAwesomeIcon icon = {faExclamationTriangle}/> Selecciona una anchura mayor a 1</small>);
            break;
            case (fila === ""):
                toast.error(<small><FontAwesomeIcon icon = {faExclamationTriangle}/> Selecciona una altura mayor a 1</small>);
            break;
            default:
                let campos = {column, fila, zonasDisp, zona}
                
                AddZoneAudit("cuadricula", props.idSite, campos, props.tipo, props.idEvento, (resp) => {
                    setBotonYes({icon: faCheck, text: "Si", spin: false, disabled: false})
                    switch (true) {
                        case (resp.status === "error" || resp.status === "error_log"):
                            toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                        break;
                        default:
                            setOpenModalOk(true);
                            setOpenModalCon(false);
                            setModalOkTitle("Datos Almacenados");
                            setModalMess(resp.mess);
                            
                            let content = [];
                            (resp.data).forEach(i => {
                                content.push({value: i.id, label: i.nombre_zona.nombre_zona});
                            });
                            
                            props.updZonas(content);
                        break;
                    }
                })
            break;
        }
    }

    let modalConfirm = () => {
        if (openModalCon === false) return null;
        
        return (
            <ConfirmModal
                isOpen = {openModalCon}
                content = {<>
                    <div>¿Deseas guardar los cambios de las zonas este auditorio?</div>
                    <small style = {{fontSize: 10}}>Luego de agregar las zonas, ve a la pestaña <strong className = "text-danger">butacas</strong> para definir la cantidad de butacas</small>
                </>}
                disabled = {botonYes.disabled}
                buttonYes = {<><FontAwesomeIcon icon = {botonYes.icon} spin = {botonYes.spin}/> {botonYes.text}</>}
                close = {() => setOpenModalCon(false)}
                funcion = {() => saveZoneAudit()}
            />
        )
    }

    if (fila === "" && column === "") return (
        <div>Selecciona el <strong>Ancho</strong> y el <strong>Alto</strong> para definir las zonas</div>
    )

    return (
        <>
            <div align = "left">
                <Row>
                    <Col md = {8}>
                        <Switch
                            offColor = "#bbbbbb"
                            onHandleColor = "#fff"
                            onColor = "#67c167"
                            offHandleColor = "#fff"
                            checkedIcon = { <div></div> }
                            uncheckedIcon = { <div></div> }
                            checked = {check} onChange = {() => { setCheck(!check) }}
                            className = "react-switch" height = {15} width = {30}
                        /> <span>Seleccionar al pasar el cursor | </span>
                        <Switch
                            offColor = "#d12c2c"
                            onHandleColor = "#fff"
                            onColor = "#67c167"
                            offHandleColor = "#fff"
                            checkedIcon = { <div title = "Agregar recuadros de la zona" style = {{color: "#fff", marginLeft: 10, fontWeight: "bold"}}>AGREGAR</div> }
                            uncheckedIcon = { <div title = "Eliminar recuadros de la zona" style = {{color: "#fff", marginLeft: -38, fontWeight: "bold"}}>ELIMINAR</div> }
                            checked = {agregar} onChange = {() => { setAgregar(!agregar) }}
                            className = "react-switch" height = {15} width = {80}
                        />
                    </Col>
                    <Col md = {4} align = "right">
                        <span className = "text-danger">{zonaS.label}</span>
                    </Col>
                </Row>
            </div>
            <div className = "table-responsive custom-mt-10" style = {{borderTop: "1px dotted #000", paddingTop: 10}}>
                <table border = "1">
                    <tbody>
                        {zonaT()}
                    </tbody>
                </table>
            </div>
            <div align = "left" className = "custom-mt-10" style = {{borderTop: "1px dotted #000"}}>{zonaLeyenda()}</div>
            <div align = "right">
                <Button color = "warning" size = "sm" type = "button" disabled = {botonAdd.disabled} onClick = {() => setOpenModalCon(true)}>
                    <FontAwesomeIcon spin = {botonAdd.spin} icon = {botonAdd.icon}/> <small>{botonAdd.text}</small>
                </Button>
            </div>
            {modalConfirm()}
            <SuccessModal isOpen = {openModalOk} close = {() => {setOpenModalOk(false)}} content = {modalMess} contentTitle = {modalOkTitle}/>

        </>
    )
}

export default ZonaAuditorio;