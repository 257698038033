import React, {useEffect, useState} from "react";
import GoogleMaps from "simple-react-google-maps";

const CustomMap = (props) => {
    /* const [center, setCenter]   = useState({ lat: -34.397, lng: 150.644 })
    const [zoom, setZoom]   = useState(6)
    const [markers, setMarkers]   = useState([
        {latitude: 40.710992, longitude: -74.008292},   
        {latitude: 40.792917, longitude: -73.969497},
        {latitude:  40.710992, longitude: -74.008292} 
    ]) */

    const [src, setSrc]     = useState("")
    const [srcOk, setSrcOk] = useState(false)

    useEffect(() => {
        setSrc(props.src)
        setSrcOk(props.map)
    }, [props]);

    /* return (
        <GoogleMaps
            apiKey = {"AIzaSyBS7N-P5yCjdpWRrQ4PML2PN40JbtqIpb4"}
            style = {{heigh: 200, width: 300}}
            zoom = {12}
            center = {{
                lat: 40.4127355,
                lng: -3.695428
            }}
        />        
    ) */

    if(srcOk === false) return (
        <div align = "center" style = {{marginTop: 80}}>
            <div>Ingresa una dirección de google maps.</div>
            <div>Si no sabes como ingresar una dirección haz click aqui: <a href = "https://www.google.com/maps/@39.550051,-105.782067,6z?hl=es" target = "_blank" style = {{fontSize: 12}}>https://www.google.com/maps/@39.550051,-105.782067,6z?hl=es</a></div>
            <div className = "custom-mt-10">Busca el lugar y presiona el botón <strong>Compartir</strong>, luego selecciona la pestaña <strong className = "text-danger">Insertar un mapa</strong> y copia el html</div>
        </div>
    )

    return (
        <iframe 
            src = {src}
            width = "350"
            height = "250"
            frameBorder = "0"
            style = {{border : "1px solid #cccccc", borderRadius: "4px 4px",/*  boxShadow:"2px 2px 3px #424242" */}}
            allowFullScreen
            title =  "Dirección"
        ></iframe>
    )
}

export default CustomMap;