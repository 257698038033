import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const Funciones = (props) => {
    let zonas;

    try {
        // Intenta parsear 'zona' si es una cadena para convertirla en un array
        zonas = typeof(props.data.zonas) === 'string' ? JSON.parse(props.data.zonas) : props.data.zonas;
    } catch (error) {
        console.error("Error parseando zonas:", error);
        // Asegura que 'zonas' sea un array vacío en caso de error
        zonas = [];
    }

    // Asegura que 'zonas' sea un array antes de continuar (útil si 'props.data.zonas' es indefinido)
    if (!Array.isArray(zonas)) {
        console.warn("Se esperaba un array para 'zonas', se recibió:", zonas);
        zonas = [];
    }

    return (
        <tr>
            <td>{props.data.dia}</td>
            <td>
                Desde <strong className="text-danger">{props.data.horaIn}</strong>, hasta <strong className="text-danger">{props.data.horaOut}</strong>
                <div><strong>Apertura: </strong>{props.data.apertura}</div>
            </td>
            <td>
                <h4><FontAwesomeIcon size="lg" icon={faTrashAlt} title="Eliminar esta función" style={{cursor: "pointer", marginLeft:19}} className="text-danger" onClick={() => {props.delFuncion(props.data.id)}}/></h4>
            </td>
            <td>
                {zonas.map(item => (
                    item.color !== "" ? (
                        <button key={item.id} style={{ backgroundColor: item.color, width: "20px", height: "20px", border: "none", margin: "5px" }} className="">
                            <strong>-</strong>
                        </button>
                    ) : null
                ))}
            </td>
        </tr>
    );
}

export default Funciones;
