import React, { useEffect, useState } from 'react';
import { Modal, Button } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
const ConfirmModal2 = (props) => {
    const [open, setOpen] = useState(props.isOpen);

    useEffect(() => {
        setOpen(props.isOpen)
    }, [props]);

    let botonNo = () => {
        if (props.buttonNo === undefined || props.buttonNo === ""){
            return <><FontAwesomeIcon icon = {faTimes}/> <small>No</small> </>
        }else{
            return props.buttonNo
        }
    }
    
    return (
        <Modal className = "modal-dialog-centeredmodal" size = "md" isOpen = {open} toggle = {() => props.cerrar()}>
            <div className="modal-header">
                <h6 className="modal-title" id="modal-title-notification" style = {{color: "#0667BF", fontWeight: "bold"}}>
                    <i className = "fa fa-question-circle"></i> Confirmación
                </h6>
                <button aria-label = "Close" className = "close" style = {{color: "#424242"}} data-dismiss = "modal" type = "button" onClick = {() => props.cerrar()}>
                    <span aria-hidden = {true} style = {{color: "#424242"}}>×</span>
                </button>
            </div>
            <div className="modal-body" style = {{background: "#ffffff", color: "#424242"}}>
                {props.content}
                <div  align = "right" className='mt-2'>
                    <Button color = "warning" type = "button" disabled = {props.disabled} onClick = {() => props.funcion()}>
                        <small>{props.buttonYes}</small>
                    </Button>
                    <Button color = "default" type = "button" onClick = {() => props.close()} disabled = {props.disabled} >
                        {botonNo()} 
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmModal2;