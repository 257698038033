import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import { UrlImg } from "../../functions/Url";

const EventoCard = (props) => {
    const [soldOut, setSoldOut] = useState("");
    useEffect(() => {
        if (props.data[0].vendidos >= props.data[0].capacidad_total) setSoldOut("soldout");
    }, [props]);

    let strings = (data) => {
        if (data === undefined) return null;
        if (data.length > 43) return data.substring(0, 40) + "..."
        return data
    }

    let cambiaFormato = (fecha) => {
        let separa = fecha.split("/");
        return `${separa[1]}/${separa[0]}/${separa[2]}`
    }

    let dia = (tipo, date) => {
        let diaActual = new Date(cambiaFormato(date));
        let day = diaActual.getDate();
        if (day < 10) day = `0${day}`;
        let meses = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']
        let month = meses[diaActual.getMonth()];

        if (tipo === "dia") {
            return day;
        } else {
            return month;
        }
    }

    return (
        <>

            <Col lg="4" sm="6" style={{ marginBottom: 30, paddingLeft: 18, paddingRight: 18 }}>
                {props.data.evento_info_simple.status === 1 ? (
                    <div className="card-lift--hover shadow border-0" style={{ cursor: "pointer", borderRadius: "12px" }} title="Ver descripción del Evento">
                        <div className="comingsoon">
                            <img width="100%" height="300vh" src={`${UrlImg}events/${props.data.evento_info_simple.thumbnail}`} style={{ borderRadius: "12px 12px 0px 0px" }} alt={`img_${props.data.evento_info_simple.thumbnail}`} />
                        </div>
                        <CardBody className="card-padding">
                            <Row>
                                <Col md={3} sm={3} xs={3} style={{ padding: 20 }} align="center">
                                    <div className="text-info" style={{ fontSize: 14 }}>
                                        {dia("mes", props.data.fecha)}
                                    </div>
                                    <div className="text-info" style={{ fontSize: 20 }}>
                                        <strong>{dia("dia", props.data.fecha)}</strong>
                                    </div>
                                </Col>
                                <Col md={9} sm={9} xs={9} style={{ padding: 20, color: "#000000" }}>
                                    <div style={{ fontSize: 14 }}>
                                        <strong>{strings(props.data.evento_info_simple.name_event)}</strong>
                                    </div>
                                    <div style={{ fontSize: 11, color: "#000000" }}>
                                        {props.data.recinto_simple.name}
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </div>
                ) : props.data.evento_info_simple.status === 4 ? (
                    <Link to={`/event/${props.data.evento_info_simple.url_event}#${props.data.id}`}>
                        <Card className="card-lift--hover shadow border-0" style={{ cursor: "pointer", borderRadius: "12px" }} title="Ver descripción del Evento">
                            <div className={soldOut}>
                                <img width="100%" height="300vh" src={`${UrlImg}events/${props.data.evento_info_simple.thumbnail}`} style={{ borderRadius: "12px 12px 0px 0px" }} alt={`img_${props.data.evento_info_simple.thumbnail}`} />
                            </div>
                            <CardBody className="card-padding">
                                <Row>
                                    <Col md={3} sm={3} xs={3} style={{ padding: 20 }} align="center">
                                        <div className="text-info" style={{ fontSize: 14 }}>
                                            {dia("mes", props.data.fecha)}
                                        </div>
                                        <div className="text-info" style={{ fontSize: 20 }}>
                                            <strong>{dia("dia", props.data.fecha)}</strong>
                                        </div>
                                    </Col>
                                    <Col md={9} sm={9} xs={9} style={{ padding: 20, color: "#000000" }}>
                                        <div style={{ fontSize: 14 }}>
                                            <strong>{strings(props.data.evento_info_simple.name_event)}</strong>
                                        </div>
                                        <div style={{ fontSize: 11, color: "#000000" }}>
                                            {props.data.recinto_simple.name}
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Link>
                ) : null}
            </Col>

        </>
    )
}

export default EventoCard;