import React, {useEffect, useState} from "react";
import { Button, Input, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Table } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faTimes, faSave, faCheck, faUniversity, faExclamationTriangle, faTimesCircle, faPlus,faArrowUp, faSquare, faTrash, faUsers } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Switch from "react-switch";
import classnames from 'classnames';
import Compressor from 'compressorjs';
import ImageMapper from 'react-image-mapper';

import ConfirmModal from "components/Modals/ConfirmModal";
import SuccessModal from "components/Modals/SuccessModal";
import ErroresList from "components/Errores/ErroresList";
import ButacaCustom from "components/Butacas/ButacaCustom";

import { UrlImg } from "functions/Url";
import { formatBytes } from 'functions/Generales';

import ZonaAuditorio from "./ZonasAuditorio";
import Auditorio from "./Auditorio";
import MesasOption from "./MesasOption";

import logoMenuTop from 'assets/img/logos/logo_origin.png';
import { SearchAuditCustom, AddNewZone, AddZoneAuditArea, SearchAuditInd, AddAudit } from "functions/SitiosFunction";
import { map } from "jquery";
import AddImages from "./addImages/AddImages";


const AddCustomAudit = (props) => {
    const [botonAdd, setBotonAdd]   = useState({ icon: faSave, text: "Guardar Butacas", spin: false, disabled: true })
    const [btnAddAr, setBtnAddAr]   = useState({ icon: faSave, text: "Guardar Distribución", spin: false, disabled: false })
    const [botonGen, setBotonGen]   = useState({ icon: faUniversity, text: "Generar", spin: false, disabled: true })
    const [activeTab, setActiveTab]     = useState(1)
    const [check, setCheck]             = useState(false)
    const [displayBtn, setDisplayBtn]   = useState("none")
    const [displayBtn2, setDisplayBtn2] = useState("inline")
    const [fila, setFila]               = useState("")
    const [column, setColumn]           = useState("")
    const [btnDelCol, setBtnDelCol]     = useState({ icon: faTimes, text: "Eliminar", spin: false, disabled: true })
    const [tipoDist, setTipoDist]       = useState({ value: "", label: "Selecciona"})
    const [alto, setAlto]               = useState("")
    const [ancho, setAncho]             = useState("")
    const [botonZon, setBotonZon]       = useState({ icon: faUniversity, text: "Generar", spin: false, disabled: false })
    const [aZonaSelect, setAZonaSelect] = useState({value: "", label: "- Selecciona -"});
    const [zonaAOption, setZonaAOption] = useState({disabled: false, loading: true})
    const [allZonas, setAllZonas]       = useState([]);
    const [colorZone, setColorZone]     = useState("#d8d8d8");
    const [dimensiones, setDimensiones] = useState({alto: "", ancho: ""})
    const [control, setControl]         = useState(false);
    const [dataZona, setDataZona]       = useState("");
    const [zonas, setZonas]             = useState([]);
    const [preloadAudit, setPreloadAudit]   = useState(<div align = "center" className = "custom-mt-10"><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Buscando butacas de la zona <strong className = "text-danger">Espera por favor</strong></div>)
    const [isData, setIsData]           = useState(false)
    const [colorBut, setColorBut]       = useState("#d8d8d8");
    const [tamButaca, setTamButaca]     = useState(18);
    const [radButaca, setRadButaca]     = useState(3);
    const [cap, setCap]                 = useState("")
    const [colSelect, setColSelect]     = useState([]);
    const [filSelect, setFilSelect]     = useState([]);
    const [audit, setAudit]             = useState([])
    const [customStyles, setCustomStyles]   = useState({
        backgroundColor: "#d8d8d8",
        border: "1px solid #ffffff",
        borderRadius: "3px 3px"
    })
    const [delCols, setDelCols]         = useState([]);
    const [delFils, setDelFils]         = useState([]);
    const [zonaOption, setZonaOption]   = useState({disabled: false, loading: true})
    const [zonaSelect, setZonaSelect]   = useState({value: '', label: '- Selecciona -'});
    const [file1, setFile1]             = useState("");
    const [mapa, setMapa]               = useState({
        name: "map1",
        areas:[]
    })
    const [imgSelect, setImgSelect]     = useState(false)
    const [item1, setItem1]             = useState({ nombre: "", tipo: "", size: "" });
    const [zonaName, setZonaName]       = useState("");
    const [bAddZonaN, setBAddZonaN]     = useState({ icon: faPlus, text: "Agregar zona", spin: false, disabled: false })
    const [openModalCon, setOpenModalCon]       = useState(false)
    const [openModalConAr, setOpenModalConAr]   = useState(false)
    const [alternar, setAlternar]       = useState(false)
    const [tipoButaca, setTipoButacat]  = useState({value: "default", label: <><span className = "butaca-select"></span> <span className = "butaca-name-select">Por defecto</span></>})
    const [butacasDisp, setButacasDisp] = useState([
        {value: "default", label: <><span className = "butaca-select"></span> <span className = "butaca-name-select">Por defecto</span></>},
        {value: "cine1", label: <><ButacaCustom  clsName = "butaca-select-cine" tipo = "cine1" fontSize = {20} color = "#d91038"/> <span className = "butaca-name-select">Butaca cine</span></>},
        {value: "silla1", label: <><ButacaCustom clsName = "butaca-select-cine" tipo = "silla1" fontSize = {20} color = "#f4b645"/> <span className = "butaca-name-select">Silla tiffany</span></>},
        {value: "mesa", label: <><span className = "butaca-select" style = {{background: "#7d5d45"}}></span> <span className = "butaca-name-select">Mesas</span></>},
        {value: "libre", label: <><FontAwesomeIcon icon = {faUsers} style = {{color: "#3835ff", fontSize: 14}}/> <span className = "butaca-name-select">Entrada libre</span></>}
    ])
    const [coordenada, setCoordenada]   = useState("")
    const [botonYes, setBotonYes]       = useState({ icon: faCheck, text: "Si", spin: false, disabled: false })
    const [openModalOk, setOpenModalOk] = useState(false)
    const [modalOkTitle, setModalOkTitle]   = useState("")
    const [modalMess, setModalMess]         = useState("")
    const [errMess, setErrMess]             = useState("")
    const [visibleErr, setVisibleErr]       = useState("none")
    const [confMesas, setConsfMesas]        = useState({
        superior: 4,
        izquierda: 4,
        derecha: 4,
        inferior: 4
    })
    const [cantSillas, setCantSillas]           = useState({
        superior: 4,
        izquierda: 4,
        derecha: 4,
        inferior: 4
    });
    const [sillasDisabled, setSillasDisabled]   = useState([]);

    /* -------------------------------------------------------------------------------- */
    const customStylesSelect = {
        menu: styles => ({ ...styles, color: "#000", zIndex: 4 }),
        multiValue: styles => ({ ...styles, backgroundColor: "#ccc" }),
        multiValueLabel: styles => ({ ...styles, color: "#000" })
    };

    const [width, setWidth] = useState("");
    const [height, setHeight] = useState("");


    useEffect(() => {
        SearchAuditCustom(props.idSite, 1, props.idEvento, (resp) => {
            setBotonGen(state => ({...state,  disabled: false}))
            setBotonAdd(state => ({...state,  disabled: false}))
            
            if(resp.data !== null){
                setPreloadAudit("")
                setIsData(true)
                setCheck(true);
                setColorBut(resp.data.butaca_color);
                setTamButaca(resp.data.butaca_tam);
                setRadButaca(resp.data.butaca_radio)
                setCap(resp.data.capacidad);
                setColumn(resp.data.columnas);
                setFila(resp.data.filas);
                setColSelect(JSON.parse(resp.data.del_col));
                setFilSelect(JSON.parse(resp.data.del_fil));
                setAudit(JSON.parse(resp.data.auditorio_build));
                setCustomStyles(state => ({...state,  backgroundColor: resp.data.butaca_color}))
                
                /* Columnas Select */
                let selectCol = [];
                for (let i = 1; i <= (resp.data.columnas); i++){
                    selectCol.push({
                        value: i, label: `Col. ${i}`
                    })
                }

                let selectFil = [];

                let letras = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","U","V","W","X","Y","Z"];

                let letrasFila = (letra, index) => {
                    if (index === 1) numL++; 
                    
                    let pLetra = letras[numL];
                    if (letras[numL] === undefined) pLetra = "";

                    return `${pLetra}${letra}`
                }


                let numL = -2;

                for (let i = 1, n = 1, nAlt = 1; i <= (resp.data.filas); i++, n++, nAlt++){
                    if(n > letras.length) n = 1;
                    
                    let letra = letrasFila(letras[n - 1], n);
                    selectFil.push({
                        value: letra, label: `Fila ${letra}`
                    })
                }

                setDelCols(selectCol)
                setDelFils(selectFil)
            }else{
                setPreloadAudit(<div align = "center" className = "custom-mt-10">Selecciona la cantidad de filas y columnas y presiona el botón <strong className = "text-danger">generar</strong></div>)
            }

            setZonaOption(state => ({...state,  loading: false}))
            setZonaAOption(state => ({...state,  loading: false}))

            let content = [];
            (resp.data_zona).forEach (option => {
                content.push({value: option.id, label: option.nombre_zona.nombre_zona})
            })

            let content2 = [];
            (resp.data_all_zones).forEach (option => {
                content2.push({value: option.id, label: option.nombre_zona})
            })

            /* setZonaSelect(content[0]) */
            setZonas(content)
            setAllZonas(content2)
            
            if(resp.data_zona_audit !== null){
                setDataZona({
                    ancho: resp.data_zona_audit.ancho,
                    alto: resp.data_zona_audit.alto,
                    zonasCat: JSON.parse(resp.data_zona_audit.zonas_cat),
                    auditZona: JSON.parse(resp.data_zona_audit.zonas_build)
                });
                setAlto(resp.data_zona_audit.ancho);
                setAncho(resp.data_zona_audit.alto);

                switch(resp.data_zona_audit.tipo){
                    case "cuadricula":
                        setTipoDist({value: "cuadricula", label: "Por Cuadrícula"})
                    break;
                    case "area":
                        setFile1(`${UrlImg}maps/${resp.data_zona_audit.image}`)
                        setMapa(JSON.parse(resp.data_zona_audit.zonas_build))
                        setTipoDist({value: "area", label: "Por Área"})
                    break;
                    default: return null;
                }
            }
        });
    }, [props]);
    
    useEffect(() => {
        if (audit.length > 0){
            setBtnDelCol(state => ({...state,  disabled: false}))
        }else{
            setBtnDelCol(state => ({...state,  disabled: true}))
        }
        
    }, [audit]);

    useEffect(() => {
        if(isData === false) updAudit()
    }, [colSelect, filSelect]);

    let saveAudit = () => {
        setBotonYes({ icon: faCircleNotch, text: "Espera", spin: true, disabled: true })
        setBotonAdd({ icon: faCircleNotch, text: "Guardar", spin: true, disabled: true })

        let campos = {check, cap, fila, column, colSelect, filSelect, tamButaca, radButaca, colorBut, audit, zonaSelect, tipoButaca, alternar, cantSillas, sillasDisabled}

        AddAudit(props.idSite, campos, "custom", props.idEvento, (resp) => {
            setBotonYes({ icon: faCheck, text: "Si", spin: false, disabled: false })
            setBotonAdd({ icon: faSave, text: "Guardar", spin: false, disabled: false })
            setOpenModalCon(false);

            switch (true) {
                case (resp[0].cantidad > 0):
                    setErrMess(resp[0])
                    setVisibleErr("block")
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> No se guardó la información</small>);
                break;
                case (resp[1].status === "error" || resp[1].status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp[1].mess }}></span></small>);
                break;
                default:
                    /* props.cerrar(); */
                    setOpenModalOk(true);
                    setModalOkTitle("Datos Almacenados");
                    setModalMess(resp[1].mess);
                break;
            }
        })
    }
    
    let updAudit = () => {
        /* if (colSelect === "" || colSelect.length === 0) return null; */

        let letras = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","U","V","W","X","Y","Z"];
        let numL = -2;
        let numLAlt = -2;

        let letrasFila = (letra, index) => {
            if (index === 1) numL++; 
            
            let pLetra = letras[numL];
            if (letras[numL] === undefined) pLetra = "";

            return `${pLetra}${letra}`
        }

        let letrasFilaAlt = (letra, index) => {
            if (index === 1) numLAlt++; 
            
            let pLetra = letras[numLAlt];
            if (letras[numLAlt] === undefined) pLetra = "";

            return `${pLetra}${letra}`
        }

        let filasT = [];
        let cantFilas = 0;
        let cantCols = 0;
        for (let i = 1, n = 1, nAlt = 1; i <= fila; i++, n++){
            if(n > letras.length) n = 1;
            if(nAlt > letras.length) nAlt = 1;
            
            let letra = letrasFila(letras[n - 1], n);
            let letraAlt = letrasFilaAlt(letras[nAlt - 1], nAlt);
            let columnsT = [];

            for (let x = 1, nC = 1; x <= column; x++){
                /* Buscar la x en colSelect.value */
                const searchCol = colSelect.find(col => col.value === x);
                
                if(searchCol === undefined){
                    columnsT.push({
                        id: nC,
                        id_fila: i,
                        letra: letra,
                        letraAlt: letraAlt,
                        disabled: false,
                        disabledInd: false
                    })
                    cantCols++;
                    nC++;
                }else{
                    columnsT.push({
                        id: `dis_${x}`,
                        id_fila: i,
                        letra: letra,
                        letraAlt: letraAlt,
                        disabled: true,
                        disabledInd: false
                    })
                }
            }

            const searchFil = filSelect.find(fil => fil.value === letra);
            if(searchFil === undefined){
                filasT.push({
                    id: i,
                    disabled: false,
                    columnas: columnsT,
                    letra: letra,
                    letraAlt: letraAlt
                })
                nAlt ++;
                cantFilas++;
            }else{
                /* Modificar los id de la columna */
                columnsT.forEach(opt => {
                    if(!isNaN(Number(opt.id)) === true) opt.id = `x_dis_${opt.id}`
                })

                filasT.push({
                    id: `dis_${i}`,
                    disabled: true,
                    columnas: columnsT,
                    letra: letra,
                    letraAlt: ""
                })
            }
        }

        setAudit(filasT)

        let capacidad = "";
        (cantCols === 0 | cantFilas === 0) ? capacidad = 0 : capacidad = cantFilas * parseInt(cantCols / cantFilas);
        setCap(capacidad)
    }

    let selZona = (zonaSelect) => {
        setZonaSelect(zonaSelect)

        setBotonGen({icon: faCircleNotch, text: "Generar", spin: true, disabled: true})
        setBotonAdd(state => ({...state,  disabled: true}))
        setPreloadAudit(<div align = "center" className = "custom-mt-10"><FontAwesomeIcon icon = {faCircleNotch} spin = {true} /> Buscando las <strong>butacas</strong> para la zona seleccionada. Espera.</div>)

        SearchAuditInd (props.idSite, zonaSelect.value, "custom", props.idEvento, (resp) => {
            setBotonGen({icon: faUniversity, text: "Generar", spin: false, disabled: false})
            setBotonAdd(state => ({...state,  disabled: false}))

            if(resp.data !== null){
                let search = butacasDisp.find(option => option.value === resp.data.estilo);
            
                if (resp.data.sillas_disabled !== ""){
                    setSillasDisabled(JSON.parse(resp.data.sillas_disabled))
                }
                
                if (resp.data.cant_sillas !== ""){
                    setCantSillas(JSON.parse(resp.data.cant_sillas))
                }

                setTipoButacat(search)
                setPreloadAudit("")
                setIsData(true)
                setCheck(true);
                setColorBut(resp.data.butaca_color);
                setTamButaca(resp.data.butaca_tam);
                setRadButaca(resp.data.butaca_radio)
                setCap(resp.data.capacidad);
                setColumn(resp.data.columnas);
                setFila(resp.data.filas);
                setColSelect(JSON.parse(resp.data.del_col));
                setFilSelect(JSON.parse(resp.data.del_fil));
                setAudit(JSON.parse(resp.data.auditorio_build));
                setCustomStyles(state => ({...state,  backgroundColor: resp.data.butaca_color}))

                /* Columnas Select */
                let selectCol = [];
                for (let i = 1; i <= (resp.data.columnas); i++){
                    selectCol.push({
                        value: i, label: `Col. ${i}`
                    })
                }

                let selectFil = [];

                let letras = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","U","V","W","X","Y","Z"];

                let letrasFila = (letra, index) => {
                    if (index === 1) numL++; 
                    
                    let pLetra = letras[numL];
                    if (letras[numL] === undefined) pLetra = "";

                    return `${pLetra}${letra}`
                }


                let numL = -2;

                for (let i = 1, n = 1, nAlt = 1; i <= (resp.data.filas); i++, n++, nAlt++){
                    if(n > letras.length) n = 1;
                    
                    let letra = letrasFila(letras[n - 1], n);
                    selectFil.push({
                        value: letra, label: `Fila ${letra}`
                    })
                }

                setDelCols(selectCol)
                setDelFils(selectFil)
            }else{
                let search = butacasDisp.find(option => option.value === "default");
                setTipoButacat(search)
                setPreloadAudit(<div align = "center" className = "custom-mt-10">Selecciona la cantidad de filas y columnas y presiona el botón <strong className = "text-danger">Generar</strong></div>)
                setIsData(false)
                setCheck(true);
                setColorBut("#d8d8d8");
                setTamButaca(18);
                setRadButaca(3)
                setCap("");
                setColumn("");
                setFila("");
                setColSelect([]);
                setFilSelect([]);
                setAudit([]);
                setCustomStyles({
                    backgroundColor: "#d8d8d8",
                    border: "1px solid #ffffff",
                    borderRadius: "3px 3px"
                })
            }
        })
    }

    let genAudit = () => {            
            switch (true) {
            /* case (isData === true): 
                setIsData(false)
            return null */
            case (fila === "" || fila < 1):
                toast.error(<small><FontAwesomeIcon icon = {faExclamationTriangle}/> Selecciona una cantidad de filas mayor a 1</small>);
            break;
            case (column === "" || column < 1):
                toast.error(<small><FontAwesomeIcon icon = {faExclamationTriangle}/> Selecciona una cantidad de columnas mayor a 1</small>);
            break;
            case (fila > 100 && tipoButaca.value !== 'libre'):
                toast.error(<small><FontAwesomeIcon icon = {faExclamationTriangle}/> Selecciona una cantidad de filas menor a 101</small>);
            break;
            case (column > 100 && tipoButaca.value !== 'libre'):
                toast.error(<small><FontAwesomeIcon icon = {faExclamationTriangle}/> Selecciona una cantidad de columnas menor a 101</small>);
            break;
            default:
                setDelCols([]);
                setColSelect([]);
                setDelFils([]);
                setFilSelect([]);
                setAudit([]);

                let capacidad = parseInt(fila) * parseInt(column)
                setCap(capacidad)

                /* Columnas Select */
                let selectCol = [];
                for (let i = 1; i <= column; i++){
                    selectCol.push({
                        value: i, label: `Col. ${i}`
                    })
                }

                /* Filas Select */
                let selectFil = [];

                let letras = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","U","V","W","X","Y","Z"];
                let numL = -2;
                let numLAlt = -2;

                let letrasFila = (letra, index) => {
                    if (index === 1) numL++; 
                    
                    let pLetra = letras[numL];
                    if (letras[numL] === undefined) pLetra = "";

                    return `${pLetra}${letra}`
                }

                let letrasFilaAlt = (letra, index) => {
                    if (index === 1) numLAlt++; 
                    
                    let pLetra = letras[numLAlt];
                    if (letras[numLAlt] === undefined) pLetra = "";
        
                    return `${pLetra}${letra}`
                }

                let filasT = [];
                for (let i = 1, n = 1, nAlt = 1; i <= fila; i++, n++, nAlt++){
                    if(n > letras.length) n = 1;
                    if(nAlt > letras.length) nAlt = 1;

                    let letra = letrasFila(letras[n - 1], n);
                    let letraAlt = letrasFilaAlt(letras[nAlt - 1], nAlt);

                    let columnsT = [];
                    for (let x = 1; x <= column; x++){
                        columnsT.push({
                            id: x,
                            id_fila: i,
                            letra: letra,
                            letraAlt: letraAlt,
                            disabled: false,
                            disabledInd: false
                        })
                    }

                    selectFil.push({
                        value: letra, label: `Fila ${letra}`
                    })

                    filasT.push({
                        id: i,
                        disabled: false,
                        columnas: columnsT,
                        letra: letra,
                        letraAlt: letraAlt,
                    })

                }

                setDelCols(selectCol)
                setDelFils(selectFil)
                setAudit(filasT)
            break;
        }
    }
    
    let cambiaTipoDist = (tipoDist) => {
        setTipoDist(tipoDist)
    }

    let selAllZona = (aZonaSelect) => {
        setAZonaSelect(aZonaSelect)
    }

    let changeStyle = (tipoButaca) => {
        setTipoButacat(tipoButaca)
        setCap(0)
    }

    let delFiles  = (filSelect) => {
        setIsData(false)
        setFilSelect(filSelect)
    }

    let delColum = (colSelect) => {
        setIsData(false)
        setColSelect(colSelect)
    }

    let modalConfirm = () => {
        if (openModalCon === false) return null;
        
        return (
            <ConfirmModal
                isOpen = {openModalCon}
                content = {<div>¿Deseas guardar los cambios?</div>}
                disabled = {botonYes.disabled}
                buttonYes = {<><FontAwesomeIcon icon = {botonYes.icon} spin = {botonYes.spin}/> {botonYes.text}</>}
                close = {() => setOpenModalCon(false)}
                funcion = {() => saveAudit()}
            />
        )
    }

    let modalConfirmAddArea = () => {
        if (openModalConAr === false) return null;

        let saveZoneAudit = () => {
            setBotonYes({icon: faCircleNotch, text: "Si", spin: true, disabled: true})
            switch (true){
                case ((mapa.areas).length === 0):
                    toast.error(<small><FontAwesomeIcon icon = {faExclamationTriangle}/> Selecciona al menos un área para la distribución</small>);
                    setBotonYes({icon: faCheck, text: "Si", spin: false, disabled: false})
                break;
                default:
                    let contentZones = [];
                    let num = 1;

                    (mapa.areas).forEach(i => {
                        let search = contentZones.find(option => option.nombre === i.name);
                        if (search === undefined){
                            contentZones.push({
                                id: num,
                                color: i.preFillColor,
                                nombre: i.name,
                                zona: {value: num, label: i.name}
                            })

                            num++;
                        }
                    })

                    let campos = {file1, width, height, mapa, contentZones}

                    AddZoneAuditArea("area", props.idSite, campos, "custom", props.idEvento, (resp) => {
                        setBotonYes({icon: faCheck, text: "Si", spin: false, disabled: false})
                        switch (true) {
                            case (resp.status === "error" || resp.status === "error_log"):
                                toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                            break;
                            default:
                                let content = [];

                                setOpenModalOk(true);
                                setOpenModalConAr(false);
                                setModalOkTitle("Datos Almacenados");
                                setModalMess(resp.mess);

                                (resp.data).forEach(i => {
                                    content.push({value: i.id, label: i.nombre_zona.nombre_zona});
                                });

                                setZonas(content)
                            break;
                        }
                    });
                break
            }
        }

        return <ConfirmModal
            isOpen = {openModalConAr}
            content = {<>¿Deseas guardar la distribución para este recinto?<div><small><strong className = "text-danger">Importante: Esta función ELIMINARÁ la distribución anterior incluyendo los precios agregados</strong></small></div></>}
            disabled = {botonYes.disabled}
            buttonYes = {<><FontAwesomeIcon icon = {botonYes.icon} spin = {botonYes.spin}/> {botonYes.text}</>}
            close = {() => setOpenModalConAr(false)}
            funcion = {() => saveZoneAudit()}
        />
    }

    let cambiaTab = (tab) => {
        if(activeTab !== tab) setActiveTab(tab);
        (tab === 1) ? setCheck(false) : setCheck(true);
        (tab === 2) ? setDisplayBtn("inline") : setDisplayBtn("none");
        (tab === 2) ? setDisplayBtn2("none") : setDisplayBtn2("inline");
        (tab === 2 && fila !== "" && column !== "") ? setBtnDelCol(state => ({...state,  disabled: false})) : setBtnDelCol(state => ({...state,  disabled: true}));
        (tab === 3) ? setCheck(null)  : setDisplayBtn("inline");
    }

    let addZonaName = () => {
        if (zonaName.trim() === ""){
            toast.error(<small><FontAwesomeIcon icon = {faExclamationTriangle}/> Selecciona un nombre para la zona</small>);
        }else{
            setBAddZonaN({ icon: faCircleNotch, text: "Agregar", spin: true, disabled: true })
            AddNewZone (zonaName.trim(), (resp) => {
                switch (true) {
                    case (resp.status === "error" || resp.status === "error_log"):
                        toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                    break;
                    default:
                        toast.success(<small><FontAwesomeIcon icon = {faCheck}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                        setBAddZonaN({ icon: faPlus, text: "Agregar", spin: false, disabled: false })
                        let content = [];
                        (resp.data).forEach (i => {
                            content.push({ value: i.id, label: i.nombre_zona });
                        });
                        setAllZonas(content);
                        setZonaName("");
                    break;
                }
            })
        }
    }

    let opciones = () => {
        let optionArea = () => {
            if (file1 === "") return null;

            let addCoordenada = () => {
                switch (true){
                    case (aZonaSelect.value === ""):
                        toast.error("Debes seleccionar primero una zona",{autoClose: 4000});
                    break;
                    case(coordenada === ""):
                        toast.error("Debes agregar una coordenada",{autoClose: 4000});
                    break;
                    default:
                        let content = mapa.areas;
                        
                        let coordData = coordenada.split(",")
                        let data = [];
    
                        coordData.forEach(i => {
                            data.push(Number(i.trim()));
                        })

                        let search = content.find(option => JSON.stringify(option.coords) === JSON.stringify(coordData));

                        if (search === undefined) {
                            content.push({
                                shape: "poly",
                                coords: coordData,
                                name:  aZonaSelect.label,
                                preFillColor: colorZone
                            })
        
                            setCoordenada("");
                            setMapa({
                                name: "map1",
                                areas: content 
                            })
                        }else{
                            toast.error("Las coordenadas ingresadas ya estan registradas en este recinto",{autoClose: 4000});
                        }
                    break;
                }
            }

            let delArea = (area) => {
                let Content = [];
                (mapa.areas).forEach(i => {
                    if(i !== area) Content.push(i)
                })
                
                setMapa({
                    name: "map1",
                    areas: Content
                })
            }

            let contentAreas = () => {
                if ((mapa.areas).length === 0) return (
                    <tr>
                        <td colSpan = {2} align = "center">No hay áreas disponibles</td>
                    </tr>
                )

                let num = 0;

                let recorrido = (data) => {
                    let Content = "";

                    let n = 1;
                    data.forEach(i => {
                        (n >= data.length) ?  Content += `${i}` : Content += `${i}, `;
                        n++;
                    })

                    return Content;
                }

                return (mapa.areas).map(option => {
                    num++;
                    return (
                        <tr key = {num} title = {recorrido(option.coords)}>
                            <td>
                                <div><small style = {{cursor: "pointer", fontWeight: "bold", color: option.preFillColor, fontSize: 12}} className = "zona-default"><FontAwesomeIcon icon = {faSquare} style = {{color: option.color}}/></small> | {option.name}</div>
                                <Input type = "text" value ={recorrido(option.coords)}/>
                            </td>
                            <td align = "center"><FontAwesomeIcon icon = {faTrash} className = "text-danger" title = "Eliminar esta área" onClick = {() => delArea(option)}/></td>
                        </tr>
                    )
                })
            }

            return (
                <Row style = {{borderTop: "1px dotted #cccccc"}}>
                    <Col md = {12} className = "custom-mt-10">
                        <span className = "text-primary">Agregar coordenadas</span>
                        <Input type = "text" placeholder = "Ingresa las Coordenadas del Área" value = {coordenada} onChange = {(e) => setCoordenada(e.target.value)}/>
                    </Col>
                    <Col md = {12} align = "right" className = "custom-mt-10">
                        <Button color = "primary"  size = "sm" type = "button" onClick = {addCoordenada} disabled = {botonGen.disabled}>
                            <FontAwesomeIcon icon = {faPlus}/> <small> Agregar coordenada</small>
                        </Button>
                    </Col>
                    <Col md = {12} className = "custom-mt-10">
                        <div>
                            <Table className = "mb-0 table-custom-primary" striped hover  style = {{boxShadow: "2px 1px 2px #000", fontSize: 10 }}>
                                <tbody>
                                    <tr>
                                        <th>Área</th>
                                        <th width = "30px" align = "center"></th>
                                    </tr>
                                    {contentAreas()}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            )
        }

        let opcionMesas = () => {
            if(tipoButaca.value === "mesa") return(
                <MesasOption customStyles = {customStyles} updConf = {(conf) => {
                    setConsfMesas(conf)
                    setCantSillas(conf)
                }}
                cantSillas = {cantSillas}
            />
            )
        }

        if (check === false){
            return (
                <Row>
                    <Col md = {12} align = "center">
                        <span className = "text-primary">Agregar nuevas zonas</span>
                    </Col>
                    <Col md = {12} className = "custom-mt-10">
                        <span className = "text-primary">Nombre de la zona</span>
                        <Input type = "text" value = {zonaName} onChange = {(e) => {setZonaName((e.target.value).toUpperCase())}} placeholder = "Nombre"/>
                    </Col>
                    <Col md = {12} align = "right" className = "custom-mt-10">
                        <Button color = "warning" type = "button" size = "sm" disabled = {bAddZonaN.disabled} onClick = {addZonaName}>
                            <FontAwesomeIcon spin = {bAddZonaN.spin} icon = {bAddZonaN.icon}/> <small>{bAddZonaN.text}</small>
                        </Button>
                    </Col>
                    <Col md = {12} className = "custom-mt-10">
                        {optionArea()}
                    </Col>
                </Row>
            )
        }else if(check === null){
            <div></div>
        }else{
            return (
                <Row>
                    <Col md = {12} align = "center">
                        <span className = "text-danger"><strong>Opciones de la distribución</strong></span>
                    </Col>
                    <Col md = {4} className = "custom-mt-10">
                        <span className = "text-primary">Estilo de la butaca</span>
                    </Col>
                    <Col md = {8} className = "custom-mt-10">
                        <Select
                            isSearchable = {true} styles = {customStylesSelect}
                            onChange = {changeStyle} options = {butacasDisp}
                            value = {tipoButaca}
                        />
                    </Col>
                    {opcionMesas()}
                    <Col md = {12} className = "custom-mt-10" title = "Para eliminar selecciona los números de las columnas reasaltadas en GRIS">
                        <span className = "text-primary">Eliminar columna</span>
                        <Select
                            isDisabled = {btnDelCol.disabled}
                            isMulti isSearchable = {true} styles = {customStylesSelect}
                            className = "basic-multi-select"
                            onChange = {delColum} options = {delCols}
                            noOptionsMessage = {() => { return "Sin Opciones" }}
                            value = {colSelect}
                            defaultValue = {colSelect}
                            placeholder = "Selecciona las Columnas"
                        />
                    </Col>
                    <Col md = {12} className = "custom-mt-10" title = "Para eliminar selecciona las letras de las filas reasaltadas en GRIS">
                        <span className = "text-primary">Eliminar filas</span>
                        <Select
                            isDisabled = {btnDelCol.disabled}
                            isMulti isSearchable = {true} styles = {customStylesSelect}
                            className = "basic-multi-select"
                            onChange = {delFiles} options = {delFils}
                            noOptionsMessage = {() => { return "Sin Opciones" }}
                            value = {filSelect}
                            defaultValue = {filSelect}
                            placeholder = "Selecciona las Filas"
                        />
                    </Col>
                    <Col md = {12} className = "custom-mt-10">
                        <span className = "text-primary">Tamaño de la butaca <strong>({tamButaca})</strong></span>
                        <Input type = "range" min = {13} max = {25} value = {tamButaca} disabled = {btnDelCol.disabled} onChange = {(e) => {setTamButaca(parseInt(e.target.value))}}/>
                    </Col>
                    <Col md = {12} className = "custom-mt-10">
                        <span className = "text-primary">Borde redondeado <strong>({radButaca})</strong></span>
                        <Input type = "range" min = {1} max = {tamButaca / 2} value = {radButaca} disabled = {btnDelCol.disabled} onChange = {(e) => {setRadButaca(parseInt(e.target.value))}}/>
                    </Col>
                    <Col md = {12} className = "custom-mt-10">
                        <span className = "text-primary">Color de la butaca (Libre - No seleccionada)</span>
                        <Input type = "color" disabled = {btnDelCol.disabled} value = {colorBut} onChange = {(e) => {
                            let valor = e.target.value
                            setColorBut(valor)
                            setCustomStyles(state => ({...state, backgroundColor: valor}))
                        }}/>
                    </Col>
                    <Col md = {6} align = "left" className = "custom-mt-10">
                        <div className = "text-primary">Alternar butacas</div>
                    </Col>
                    <Col md = {6} align = "right" className = "custom-mt-10">
                        <Switch
                            offColor = "#d12c2c"
                            onHandleColor = "#fff"
                            onColor = "#67c167"
                            offHandleColor = "#fff"
                            checked = {alternar} onChange = {() => { setAlternar(!alternar) }}
                            className = "react-switch" height = {20} width = {50}
                        />
                    </Col>
                </Row>
            )
        }
    }

    let updCapacidad = (op) => {
        let capacidad = cap;
        (op === "sum") ? capacidad ++ : capacidad--;
        setCap(capacidad)
    }

    let genAuditZon = () => {
        switch (true) {
            case (ancho === "" || ancho < 1):
                toast.error(<small><FontAwesomeIcon icon = {faExclamationTriangle}/> Selecciona una anchura mayor a 1</small>);
            break;
            case (alto === "" || alto < 1):
                toast.error(<small><FontAwesomeIcon icon = {faExclamationTriangle}/> Selecciona una altura mayor a 1</small>);
            break;
            case (ancho > 82):
                toast.error(<small><FontAwesomeIcon icon = {faExclamationTriangle}/> Selecciona una anchura menor a 51</small>);
            break;
            case (alto > 82):
                toast.error(<small><FontAwesomeIcon icon = {faExclamationTriangle}/> Selecciona una altura menor a 51</small>);
            break;
            case (aZonaSelect.value === ""):
                toast.error(<small><FontAwesomeIcon icon = {faExclamationTriangle}/> Selecciona primero una zona</small>);
            break;
            default:
                setControl(true);
                setDimensiones ({alto: alto, ancho: ancho});
                setTimeout(() => {
                    setDimensiones ({alto: alto, ancho: ancho});
                    setControl(false);
                }, 1);
            break;
        }
    }

    let infoFile = (item) => {
        if(item.nombre === "") return null;

        return(
            <div style = {{fontSize: 10, color: "#000", fontWeight: "bold"}}>{`${item.nombre} - ${item.size})`}</div>
        )
    }

    let selectFile = (item) => (e) => {
        let file = document.getElementById(item);
        let archivo = file.value;

        if (archivo === ""){
            toast.error("No has seleccionado ningún archivo",{autoClose: 4000});
            setImgSelect(false)
            return null
        }

        let extension = archivo.split('.').pop();
        let ext = extension.toLowerCase();
        let fic = archivo.split('\\');
        let nombre = fic[fic.length -1];
        let size = file.files[0].size;

        if (ext === "jpg" || ext === "jpeg" || ext === "png"){
            let msjSize = formatBytes(size);
            if (size > 1000000) {
                /* Comprimir el archivo */
                let fil = file.files[0];
                new Compressor(fil, {
                    quality: 0.2,
                    convertSize: 1000000,
                    success(result) {
                        /* Convertir a Base 64 */
                        let reader = new FileReader();
                        reader.readAsDataURL(result);

                        msjSize = `Tamaño Original: ${formatBytes(size)} [Tamaño Comprimido: ${formatBytes(result.size)}]`

                        reader.onloadend = function() {
                            setFile1(reader.result)
                        }

                        setItem1({ nombre: `Agregado: (${nombre}`, tipo: extension, size: msjSize })
                    },
                    error(err) {
                        toast.error("La imagen es muy grande y no pudo comprimirse",{autoClose: 4000});
                        document.getElementById(item).value = "";
                    }
                })
            }else{
                /* Convertir a Base 64 */
                let reader = new FileReader();
                reader.readAsDataURL(file.files[0]);

                reader.onloadend = function() {
                    setFile1(reader.result)
                }

                setItem1({ nombre: `Agregado: (${nombre}`, tipo: extension, size: formatBytes(size) })
            }
            setImgSelect(true)
        }else{
            toast.error("No has seleccionado un archivo de imagen",{autoClose: 4000});
            document.getElementById(item).value = "";
            setImgSelect(false)
            return null
        }
    }

    let editZonas = () => {
        let tipoD = [
            {value: "cuadricula", label: "Por Cuadrícula"},
            {value: "area", label: "Por Área"},
        ];

        let imgMap = () => {
            if (file1 === "") return (
                <div align = "center">Selecciona la imagen del recinto <FontAwesomeIcon icon = {faArrowUp}/></div> 
            )

            let zonaLeyenda = () => {
                if((mapa.areas).length === 0) return(
                    <div>No has seleccionado ninguna zona</div>
                )

                let contentZones = [];
                let num = 0;

                (mapa.areas).forEach(i => {
                    let search = contentZones.find(option => option.name === i.name);
                    if (search === undefined){
                        contentZones.push({
                            id: num,
                            name: i.name,
                            color: i.preFillColor
                        })

                        num++;
                    }
                })

                let styles = (zona, color) => {
                    if (zona === aZonaSelect.label) return ({cursor: "pointer", fontWeight: "bold", color: color, fontSize: 12})
                    return  ({cursor: "pointer", fontSize: 12})
                }
                
                let cambiaZonaAr = (zona) => {
                    let search = allZonas.find(option => option.label === zona.name);

                    setColorZone(zona.color)
                    setAZonaSelect(search)
                }
        
                return (
                    contentZones.map(option => (
                        <small key = {option.id} style = {styles(option.name, option.color)} onClick = {() => cambiaZonaAr(option)} title = "Selecciona para definir la zona" className = "zona-default"><FontAwesomeIcon icon = {faSquare} style = {{color: option.color}}/> {option.name} | </small>
                    ))
                )
            }

            let selectArea = (area) => {
                let search = allZonas.find(option => option.label === area.name);

                setColorZone(area.preFillColor)
                setAZonaSelect(search)
            }

            let cambiaColor = (e) => {
                if (aZonaSelect.value === "") {
                    setColorZone(e)
                }else{
                    setColorZone(e)
                    if ((mapa.areas).length > 0){
                        let Content = mapa.areas
                        Content.forEach(i => {
                            if(i.name === aZonaSelect.label) i.preFillColor = e
                        })
                        
                        /* setMapa(Content) */
                    }
                }
            }

            let botonAdd = () => (
                <div align = "right" className = "custom-mt-10">
                    <Button color = "danger" size = "sm" type = "button" disabled = {btnAddAr.disabled} onClick = {() => setOpenModalConAr(true)}>
                        <FontAwesomeIcon spin = {btnAddAr.spin} icon = {btnAddAr.icon}/> <small>{btnAddAr.text}</small>
                    </Button>
                </div>
            )

            const onImageLoad = () => {
                const img = new Image();
              
                img.onload = () => {
                  // Después de que la imagen se haya cargado completamente
                  setWidth(img.width)
                  setHeight(img.height);
                };
              
                img.src = file1;
              };

            return (
                <div align = "center">
                    <Row style = {{border: "1px dotted #000", backgroundColor: "#f2f2f2", paddingBottom: 10}}>
                        <Col md = {7} title = {aZonaSelect.label} align = "left">
                            <small><strong>Selecciona la zona</strong></small>
                            <Select
                                isDisabled = {zonaAOption.disabled}
                                isLoading = {zonaAOption.loading}
                                isSearchable = {true} styles = {customStylesSelect}
                                onChange = {selAllZona} options = {allZonas}
                                noOptionsMessage = {() => { return "Sin Zonas Registradas" }}
                                loadingMessage = {() => { return <><FontAwesomeIcon spin = {true} icon = {faCircleNotch}/> Cargando zonas</> }}
                                value = {aZonaSelect}
                                placeholder = "Selecciona"
                            />
                        </Col>
                        <Col md = {5} align = "left">
                            <small><strong>Color de la zona</strong></small>
                            <Input type = "color" placeholder = "Color de la Zona" title = "Define el color de la zona en la distribución" value = {colorZone} onChange = {(e) => cambiaColor(e.target.value)}/>
                        </Col>
                    </Row>
                    {botonAdd()}
                    <div align = "left" className = "custom-mt-10">{zonaLeyenda()}</div>
                    <div style = {{marginTop: 10, padding: 10, border: "1px solid #424242", backgroundColor: "#cccccc"}}>
                        <ImageMapper src = {file1} map = {mapa} onClick = {(area) => selectArea(area)} onLoad ={onImageLoad}/>
                    </div>
                    <div align = "left" className = "custom-mt-10">{zonaLeyenda()}</div>
                    {botonAdd()}
                </div>
            )
        }

        switch(tipoDist.value){
            case "cuadricula": return (
                <>
                    <Col md = {2}>
                        <small>Ancho</small>
                        <Input type = "number" placeholder = "Ancho" title = "Muestra la anchura de la distribución" value = {alto} onChange = {(e) => setAlto(e.target.value)}/>
                    </Col>
                    <Col md = {2}>
                        <small>Alto</small>
                        <Input type = "number" placeholder = "Alto" title = "Muestra la altura de la distribución" value = {ancho} onChange = {(e) => setAncho(e.target.value)}/>
                    </Col>
                    <Col md = {2} align = "right" className = "custom-mt-10">
                        <Button color = "success" type = "button" disabled = {botonZon.disabled} onClick = { genAuditZon }>
                            <small>{botonZon.text}</small>
                        </Button>
                    </Col>
                    <Col md = {6}>
                        <Row style = {{border: "1px dotted #000", backgroundColor: "#f2f2f2"}}>
                            <Col md = {7} title = {aZonaSelect.label}>
                                <small><strong>Selecciona la zona</strong></small>
                                <Select
                                    isDisabled = {zonaAOption.disabled}
                                    isLoading = {zonaAOption.loading}
                                    isSearchable = {true} styles = {customStylesSelect}
                                    onChange = {selAllZona} options = {allZonas}
                                    noOptionsMessage = {() => { return "Sin Zonas Registradas" }}
                                    loadingMessage = {() => { return <><FontAwesomeIcon spin = {true} icon = {faCircleNotch}/> Cargando zonas</> }}
                                    value = {aZonaSelect}
                                    placeholder = "Selecciona"
                                />
                            </Col>
                            <Col md = {5}>
                                <small><strong>Color de la zona</strong></small>
                                <Input type = "color" placeholder = "Color de la Zona" title = "Define el color de la zona en la distribución" value = {colorZone} onChange = {(e) => setColorZone(e.target.value)}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col md = {12} className = "custom-mt-15 auditorio-area" align = "center">
                        <ZonaAuditorio 
                            ancho = {dimensiones.ancho}
                            alto = {dimensiones.alto}
                            color = {colorZone}
                            zona = {aZonaSelect}
                            idSite = {props.idSite}
                            control = {control}
                            data = {dataZona}
                            updZonas = {(data) => {setZonas(data)}}
                            tipo = "custom"
                            idEvento = {props.idEvento}
                        />
                    </Col>
                </>
            )
            case "area": return (
                <>
                    <Col md = {6} style = {{padding: 10, paddingTop: 15,}}>
                        <input type = "file" onChange = {selectFile("fileImg1")} id = "fileImg1"/>
                    </Col>
                    <Col md = {6} style = {{padding: 10, paddingTop: 15,}}>
                        {infoFile(item1)}
                    </Col>
                    <Col md = {12} style = {{padding: 10, paddingTop: 15,}}>
                        {imgMap()}
                    </Col>
                </>
            )
            default: return (
                <>
                    <Col md = {6} style = {{padding: 10, paddingTop: 15,}} align = "right">
                        <h6>Selecciona el tipo de distribución:</h6>
                    </Col>
                    <Col md = {6} style = {{padding: 10,}}>
                        <Select
                            isSearchable = {true} styles = {customStylesSelect}
                            onChange = {cambiaTipoDist} options = {tipoD}
                            value = {tipoDist}
                            placeholder = "Selecciona"
                        />
                    </Col>
                    <Col md = {12} align = "center">
                        <img alt="logo_taquilla" src = {logoMenuTop} className = "img img-fluid"/>
                    </Col>
                </>
            )
        }
    }

    let auditorioSillas = () => {
        switch(tipoButaca.value){
            case "libre": return (
                <div align = "center">No aplica la distribución de para el tipo seleccionado</div>
            );
            default:
                if (cap === 0) {
                return (
                    <div align="center" class="custom-mt-10">Selecciona la cantidad de filas y columnas y presiona el botón <strong class="text-danger">Generar</strong></div>
                )
                }else{
                    return (
                        <Auditorio
                            data = {audit}
                            tamButaca = {tamButaca}
                            radButaca = {radButaca}
                            colorBut = {colorBut}
                            customStyles = {customStyles}
                            updCapacidad = {(op) => {updCapacidad(op)}}
                            updAudit = {(data) => setAudit(data)}
                            preloader = {preloadAudit}
                            alternar = {alternar}
                            estiloButaca = {tipoButaca.value}
                            confMesas = {confMesas}
                            sillasDisabled = {sillasDisabled}
                            /* updCantSillas = {(data) => setCantSillas(data)} */
                            updSillasDisabled = {(data) => setSillasDisabled(data)}
                        />
                    )
                }

        }
    }

    let editAuditorio = () => (
        <>
            <Col md = {1}>
                <small>Capacidad</small>
                <h6 className = "text-danger">{cap}</h6>
            </Col>
            <Col md = {4}>
                <small>Zona</small>
                <Select
                    isDisabled = {zonaOption.disabled}
                    isLoading = {zonaOption.loading}
                    isSearchable = {true} styles = {customStylesSelect}
                    onChange = {selZona} options = {zonas}
                    noOptionsMessage = {() => { return "Sin Zonas Registradas" }}
                    loadingMessage = {() => { return <><FontAwesomeIcon spin = {true} icon = {faCircleNotch}/> Cargando Zonas</> }}
                    value = {zonaSelect}
                    placeholder = "Selecciona"
                />
            </Col>
            <Col md = {2}>
                <small>Filas</small>
                <Input type = "number" placeholder = "Filas" title = "Muestra la cantidad de filas" value = {fila} onChange = {(e) => setFila(e.target.value)}/>
            </Col>
            <Col md = {2}>
                <small>Columnas</small>
                <Input type = "number" placeholder = "Columnas" title = "Muestra la cantidad de columnas" value = {column} onChange = {(e) => setColumn(e.target.value)}/>
            </Col>
            <Col md = {3} align = "right" className = "custom-mt-10" style = {{paddingBottom: 10}}>
                <Button color = "danger" type = "button" disabled = {botonGen.disabled} onClick = { genAudit }>
                    <FontAwesomeIcon spin = {botonGen.spin} icon = {botonGen.icon}/> <small>{botonGen.text}</small>
                </Button>
            </Col>
            <Col md = {12} className = "custom-mt-15" align = "left">
                <div className = "auditorio-area">
                {auditorioSillas()}
                </div>
                
            </Col>
        </>
    )

    let addImages = () =>{
        return(

        <Col className='mb-2'>
            <AddImages zonas = {zonas} evento ={props.idEvento} />
        </Col>
        )
    }

    return (
        <Row>
            <Col md = {12}>
                <Row>
                    <Col md = {9}>
                        <Nav tabs>
                            <NavItem style = {{cursor: "pointer"}}>
                                <NavLink className = {classnames({active: activeTab === 1})} onClick = {() => {cambiaTab(1)}}>
                                    <strong>Distribución</strong>
                                </NavLink>
                            </NavItem>
                            <NavItem style = {{cursor: "pointer"}}>
                                <NavLink className = {classnames({active: activeTab === 2})} onClick = {() => {cambiaTab(2)}}>
                                    <strong>Butacas</strong>
                                </NavLink>
                            </NavItem>
                            <NavItem style = {{cursor: "pointer"}}>
                                <NavLink className = {classnames({active: activeTab === 3})} onClick = {() => {cambiaTab(3)}}>
                                    <strong>Zonas</strong>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab = {activeTab}>
                            <TabPane tabId = {1}>
                                <Row style = {{borderBottom: "1px dotted #cccccc", overflowY: "auto", height: "62vh"}}>
                                    {editZonas()}
                                </Row>
                            </TabPane>
                            <TabPane tabId = {2}>
                                <Row style = {{borderBottom: "1px dotted #cccccc"}}>
                                    {editAuditorio()}
                                </Row>
                            </TabPane>
                            <TabPane tabId = {3}>
                                <Row style = {{borderBottom: "1px dotted #cccccc"}}>
                                    {addImages()}
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Col>
                    <Col md = {3} style = {{border: "1px solid #ccc", borderRadius: "4px 4px", maxHeight: "67vh", overflowX: "hidden", overflowY: "auto"}}>
                        {opciones()}
                    </Col>
                </Row>
            </Col>
            <Col md = {8} className = "custom-mt-10">
                <ErroresList errMess = {errMess} visibleErr = {visibleErr} name = "popErr"/>
            </Col>
            <Col md = {4} className = "custom-mt-10" align = "right">
                <Button color = "warning" size = "sm" type = "button" style = {{display: displayBtn}} disabled = {botonAdd.disabled} onClick = {() => setOpenModalCon(true)}>
                    <FontAwesomeIcon spin = {botonAdd.spin} icon = {botonAdd.icon}/> <small>{botonAdd.text}</small>
                </Button>
                {/* <Button color = "danger" type = "button" style = {{display: displayBtn2}} disabled = {botonAdd.disabled} onClick = {() => setOpenModalConAr(true)}>
                    <FontAwesomeIcon spin = {btnAddAr.spin} icon = {btnAddAr.icon}/> <small>{btnAddAr.text}</small>
                </Button> */}
            </Col>
            {modalConfirm()}
            {modalConfirmAddArea()}
            <SuccessModal isOpen = {openModalOk} close = {() => {setOpenModalOk(false)}} content = {modalMess} contentTitle = {modalOkTitle}/>
        </Row>
    )
}


export default AddCustomAudit;

