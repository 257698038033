import { SelectBanco } from "functions/EventosFunctions";
import { MultipaymentMethod } from "functions/EventosFunctions";
import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";

import PagoMovilButton from "./MultiPaymentComponent/PagoMovilButton";
import CreditCardButton from "./MultiPaymentComponent/CreditCardButton";
import GenericButton from "./MultiPaymentComponent/GenericButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { searchHalfInvoice } from "functions/MultiPaymentFunctions";
import DebitCardButtons from "./MultiPaymentComponent/DebitCardButtons";
import InputMask from 'react-input-mask';
import { NumericFormat } from "react-number-format";


function MultiPaymentTaquilla({
  contentCompra,
  totalPago,
  contentImpuestos,
  subTotalEntradas,
  contentServTaquilla,
  handleMethodsPayment,
  totalAPagar,
  children,
  ...props
}) {
  const [methods, setMethods] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedMethods, setSelectedMethods] = useState([]);
  const [montoAPagar, setMontoAPagar] = useState("");
  const [tasaCambio, setTasaCambio] = useState(0);
  const [monto, setMonto] = useState("");
  const [montoBs, setMontoBs] = useState("");
  const [montoTotal, setMontoTotal] = useState("");
  const [bancos, setBancos] = useState("");
  const [bancoSeleccionado, setBancoSeleccionado] = useState("");
  const [metodoSelect, setMetodoSelect] = useState([]);
  const [datosComprador, setDatosComprador] = useState({nombre: '', correo: ''});
  const [montos, setMontos] = useState({bs:0, usd:0});
  const [halfInvoices, setHalfInvoices] = useState([]);
  const [saldoAFavor, setSaldoAfavor] = useState("-0.00");
  const [saldoAFavorBs, setSaldoAfavorBs] = useState("-0.00");

  useEffect(()=>{
    let contentDataEvent =  JSON.parse(sessionStorage.getItem("ContentDataEvent"))

    searchHalfInvoice(contentDataEvent.id_evento, (resp)=>{
      setHalfInvoices(resp.invoice)
    })
  }, [])

  useEffect(() => {
    SelectBanco((resp) => {
      setBancos(resp);
    });
  }, []);

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("ContentData"));

    const u_type = userData.U_TYPE;
    MultipaymentMethod(u_type, (resp) => {
      setMethods(resp.metodos_pago);
    });

    setMontoAPagar(totalAPagar());
    setMontoTotal(monto);
    const contentDataEvent = JSON.parse(
      sessionStorage.getItem("ContentDataEvent")
    );
    const sessionTasaCambio = sessionStorage.getItem("ContentDataCurrency");
    const objetoJSON = JSON.parse(sessionTasaCambio);
    setTasaCambio(objetoJSON);
  }, []);

  useEffect(() => {
    let sumaDeMontosUSD = 0;
    let sumaDeMontosBs = 0;
    
    if (selectedMethods && selectedMethods.length > 0) {
      for (let i = 0; i < selectedMethods.length; i++) {
        if (selectedMethods[i].moneda === "Bs.") {
          // Convertir de Bs a USD antes de sumar
          sumaDeMontosBs += parseFloat(
            selectedMethods[i].amount ? selectedMethods[i].amount : 0
          );

          sumaDeMontosUSD += parseFloat(
            selectedMethods[i].amount
              ? selectedMethods[i].amount / tasaCambio.valor_1usd_moneda_local
              : 0
          );
        } else if (selectedMethods[i].moneda === "USD") {
          sumaDeMontosUSD += parseFloat(
            selectedMethods[i].amount ? selectedMethods[i].amount : 0
          );
          // Convertir de USD a Bs antes de sumar
          sumaDeMontosBs += parseFloat(
            selectedMethods[i].amount
              ? selectedMethods[i].amount * tasaCambio.valor_1usd_moneda_local
              : 0
          );
        }
      }
    }

    let cadena = montoAPagar;
    let totalUSD;
    let totalBs;

    if (cadena && cadena !== "") {
      let partes = cadena.split(" ");
      let montoUSD = parseFloat(partes[3].replace("USD", "").trim());

      let montoBs = parseFloat((montoUSD * tasaCambio.valor_1usd_moneda_local).toFixed(2));

      totalUSD = (montoUSD - sumaDeMontosUSD).toFixed(2)
      totalBs = (montoBs - sumaDeMontosBs).toFixed(2)

      let mostrarUSD = totalUSD;
      let mostrarBs = totalBs;

      if (totalUSD <= 0) {
        mostrarUSD = 0.00;
      }

      if(totalBs <= 0) {
        mostrarBs = 0.00;
      }
      
      let formateadoBs = new Intl.NumberFormat('es-VE', { style: 'currency', currency: 'VES' }).format(
        mostrarBs,
      )

      let formateadoUSD = new Intl.NumberFormat('es-VE', { style: 'currency', currency: 'VES' }).format(
        mostrarUSD,
      )

      setMontos({bs:totalBs, usd:totalUSD})

      setMonto(formateadoUSD.replace('Bs.S', ''));
      setMontoBs(formateadoBs.replace('Bs.S', 'Bs.'));

    }

    console.log((totalUSD))
    console.log((totalBs))

    handleMethodsPayment(selectedMethods, datosComprador, monto, {bs:totalBs, usd:totalUSD});
  }, [tasaCambio, selectedMethods, montoTotal, datosComprador]);

  const opcionesBancos = useMemo(
    () =>
      bancos &&
      bancos.map((banco) => ({ value: banco.codigo, label: banco.nombre })),
    [bancos]
  );

  const handleCheckboxChange = (id, method, moneda, conciliable, additionalProps) => {
    // Siempre elimina el id y el método existente
    setSelectedIds(selectedIds.filter((itemId) => itemId !== id));
    setSelectedMethods(selectedMethods.filter((item) => item.id !== id));
  
    // Siempre agrega el nuevo id y método
    setSelectedIds([...selectedIds, id]);
    setSelectedMethods([
      ...selectedMethods,
      {
        id,
        method,
        moneda,
        conciliable,
        ...additionalProps, // Agrega las propiedades adicionales aquí
      },
    ]);
  };

  const completePayment = ()=> {
    if(selectedMethods.length > 0) {
        return (
          <table className="table">
            <tbody>
              {
                selectedMethods.map((method)=>{
                  return (
                    <tr key={method.id}>
                      <td>
                          {method.method}
                      </td>
                      <td>
                          {method.amount.replace(/\./g, ',')} {method.moneda}
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                    </tr>

                  )
                })
              }

            </tbody>
          </table>
        )
    }else{
      return null;
    }
  }
  
  const handleBancoSeleccionado = (banco, id) => {
    setBancoSeleccionado(banco);

    setMetodoSelect(
      metodoSelect.map((item) =>
        item.id === id ? { ...item, banco: banco.value } : item
      )
    );
  };

  const handleAmountChange = (value, id) => {
    value = value.replace(/\./g, ""); // Quita los puntos
    value = value.replace(/,/g, "."); // Cambia la coma por punto

    setMetodoSelect(
      metodoSelect.map((item) =>
        item.id === id ? { ...item, amount: value } : item
      )
    );
  };

  const handleRefAdd = (value, id) => {
    setMetodoSelect(
      metodoSelect.map((item) =>
        item.id === id ? { ...item, ref: value } : item
      )
    );
  };

  const handleCIAdd = (value, id) => {
    setMetodoSelect(
      metodoSelect.map((item) =>
        item.id === id ? { ...item, ci: value } : item
      )
    );
  };

  const handleCardNumber = (value, id) => {
    setMetodoSelect(
      metodoSelect.map((item) =>
        item.id === id ? { ...item, cardNumber: value } : item
      )
    );
  };

  const handleCvv = (value, id) => {
    setMetodoSelect(
      metodoSelect.map((item) =>
        item.id === id ? { ...item, cvv: value } : item
      )
    );
  };

  const handleTlfn = (value, id) => {
    setMetodoSelect(
      metodoSelect.map((item) =>
        item.id === id ? { ...item, tlfn: value } : item
      )
    );
  };

  const handleExpDate = (value, id) => {
    setMetodoSelect(
      metodoSelect.map((item) =>
        item.id === id ? { ...item, exp: value } : item
      )
    );
  };

  const handleOTP = (value, id) => {
    setMetodoSelect(
      metodoSelect.map((item) =>
        item.id === id ? { ...item, otp: value } : item
      )
    );
  }

  const resetMethods = () => {
    setMetodoSelect([undefined])
  }

  const handleMethod = (method) => {
    if(method !== undefined){
      if (method.method === "Pago móvil" || method.method === "Pago móvil C2P") {
        let idFactura = null;

        (selectedMethods.length > 0) ? idFactura = selectedMethods[0].id_factura : idFactura = null;
        
        return (
          <div>
             <div><strong>Datos del pago móvil</strong></div>
                Número de teléfono: <strong>(0412) 0715875</strong> <br />
                RIF del comercio: <strong>J-296717052</strong> <br />
                Banco: <strong>Banplus (0174)</strong> <br />
            <label htmlFor="">Monto en Bs.:</label>
          <NumericFormat
            type="text"
            value={method.amount}
            allowLeadingZeros 
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2} 
            fixedDecimalScale
            className="form-control"
            onChange={(e) => handleAmountChange(e.target.value, method.id)}
          />
            <label htmlFor="">Cédula del cliente</label>
            <input
              type="text"
              className="form-control"
              value={method.ci}
              onChange={(e) => handleCIAdd(e.target.value, method.id)}
            />
            <label htmlFor="">Teléfono del cliente</label>
            <InputMask
              mask="(9999) 999-9999"
              className="form-control"
              value={method.tlfn}
              onChange={(e) => handleTlfn(e.target.value, method.id)}
            />
            <label htmlFor="">Banco</label>
            <Select
              value={bancoSeleccionado}
              onChange={(selectedOption) =>
                handleBancoSeleccionado(selectedOption, method.id)
              }
              options={opcionesBancos}
              placeholder="Seleccionar banco"
              is
            />
            <label htmlFor="">{method.method === "Pago móvil C2P" ? "Código C2P" : "Referencia" }</label>
            <input
              type="text"
              className="form-control"
              value={method.ref}
              onChange={(e) => handleRefAdd(e.target.value, method.id)}
            />
            <PagoMovilButton method = {method} handleCheckboxChange = {handleCheckboxChange} idFactura = {idFactura} montos = {montos} resetMethods={resetMethods} />
          </div>
        );
      } else if (
        method.method === "Tarjeta de crédito Bs." ||
        method.method === "Tarjeta de crédito USD"
      ) {
        let idFactura = null;

        (selectedMethods.length > 0) ? idFactura = selectedMethods[0].id_factura : idFactura = null;

        return (
          <div>
            <label htmlFor="">Monto en {method.moneda}</label>
          <NumericFormat
            type="text"
            value={method.amount}
            allowLeadingZeros 
            thousandsGroupStyle="thousand"
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2} 
            fixedDecimalScale
            className="form-control"
            onChange={(e) => handleAmountChange(e.target.value, method.id)}
          />
            <label htmlFor="">Cédula</label>
            <input
              type="text"
              className="form-control"
              value={method.ci}
              onChange={(e) => handleCIAdd(e.target.value, method.id)}
              maxLength={9}
            />
            <label htmlFor="">Nro. de tarjeta</label>
            <input
              type="text"
              className="form-control"
              value={method.cardNumber}
              onChange={(e) => handleCardNumber(e.target.value, method.id)}
              maxLength={16}
            />
            <label htmlFor="">CVV</label>
            <input
              type="text"
              className="form-control"
              value={method.cvv}
              onChange={(e) => handleCvv(e.target.value, method.id)}
              maxLength={3}
            />
          <label htmlFor="">Fecha de expiración</label>
          <InputMask
            mask="9999/99"
            className="form-control"
            placeholder="AAAA/MM"
            value={method.exp}
            onChange={(e) => handleExpDate(e.target.value, method.id)}
          />

            <CreditCardButton method={method} handleCheckboxChange={handleCheckboxChange} idFactura ={idFactura} montos = {montos} resetMethods = {resetMethods} />
          </div>
        );
      }else if(method.method === 'Tarjeta de débito Mercantil'){
        let idFactura = null;

        (selectedMethods.length > 0) ? idFactura = selectedMethods[0].id_factura : idFactura = null;

        return (
          <div>
          <label htmlFor="">Monto en {method.moneda}</label>
          <NumericFormat
            type="text"
            value={method.amount}
            allowLeadingZeros 
            thousandsGroupStyle="thousand"
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2} 
            fixedDecimalScale
            className="form-control"
            onChange={(e) => handleAmountChange(e.target.value, method.id)}
          />
            <label htmlFor="">Cédula</label>
            <input
              type="text"
              className="form-control"
              value={method.ci}

              onChange={(e) => handleCIAdd(e.target.value, method.id)}
              maxLength={9}
            />
            <label htmlFor="">Nro. de tarjeta</label>
            <input
              type="text"
              className="form-control"
              value={method.cardNumber}
              onChange={(e) => handleCardNumber(e.target.value, method.id)}
              maxLength={18}
            />
          <label htmlFor="">Fecha de expiración</label>
          <InputMask
            mask="9999/99"
            className="form-control"
            placeholder="AAAA/MM"
            value={method.exp}
            onChange={(e) => handleExpDate(e.target.value, method.id)}
          />
          <label htmlFor="">CVV</label>
            <input
              type="text"
              className="form-control"
              value={method.cvv}
              onChange={(e) => handleCvv(e.target.value, method.id)}
              maxLength={3}
            />
            <label htmlFor="">Código de un solo uso</label>
            <input
              type="text"
              className="form-control"
              value={method.otp}
              onChange={(e) => handleOTP(e.target.value, method.id)}
            />

            <DebitCardButtons method={method} handleCheckboxChange={handleCheckboxChange} idFactura ={idFactura} montos = {montos} resetMethods={resetMethods} />

          </div>
        );
      } else {
        let idFactura = null;

        (selectedMethods.length > 0) ? idFactura = selectedMethods[0].id_factura : idFactura = null;
        let dataTransfer = (
          <>
            <div><strong>Datos de la transferencia</strong></div>

            RIF: <strong>J-29671705-2 </strong> <br />
            Tipo de cuenta: <strong>Corriente</strong> <br />
            Número de cuenta: <strong>0174 0131 99 1314913078</strong> <br />
          </>
        )

        let dataZelle = (
          <>
          <div><strong>Datos de Zelle</strong></div>

          Correo: <strong>enfoquepromocionesyeventos@gmail.com</strong> <br />
          </>
        )

        return (
          <div>
            {method.method === "Transferencia bancaria" ? dataTransfer : dataZelle}


            <label htmlFor="">Monto en {method.moneda}:</label>
          <NumericFormat
            type="text"
            value={method.amount}
            allowLeadingZeros 
            thousandsGroupStyle="thousand"
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2} 
            fixedDecimalScale
            className="form-control"
            onChange={(e) => handleAmountChange(e.target.value, method.id)}
          />
  
              {method.conciliable === 1 ?
                      <div>
                          <label htmlFor="">Referencia:</label>
                          <input type="text" className='form-control'
                          value={method.ref}
                          onChange={(e)=>{handleRefAdd(e.target.value, method.id)}}
                          />
                           <GenericButton method = {method} handleCheckboxChange = {handleCheckboxChange} idFactura ={idFactura} selectedMethod = {selectedMethods} montos = {montos} resetMethods={resetMethods}/>
                      </div>
                          :
                          <div>
                            <GenericButton method = {method} handleCheckboxChange = {handleCheckboxChange} idFactura ={idFactura} selectedMethod = {selectedMethods} montos = {montos} resetMethods={resetMethods}/>
                          </div>
                          }
  
          </div>
        );
      }
    }else{
      return null;
    }
  };

  const handleSelectChange = (event) => {
    const selectedMethod = methods.find((method) => method.method === event.target.value);

    if (selectedMethod) {
      setSelectedIds([selectedMethod.id]);
      setMetodoSelect(
        [{
          id: selectedMethod.id,
          method: selectedMethod.method,
          amount: "",
          moneda: selectedMethod.moneda,
          conciliable: selectedMethod.conciliable,
          ref: "",
          tlfn: "",
          ci: "",
          banco: "",
          cardNumber: "",
          cvv: "",
          exp: "",
          otp: "",
        }],
      );
    }
  };


  const handleInputChange = (event) => {
    setDatosComprador({
        ...datosComprador,
        [event.target.name]: event.target.value
    });
}

const changeInvoice = (event) => {
  const selectedInvoice = halfInvoices.find((invoice) => invoice.id === parseInt(event.target.value))
  
  if (selectedInvoice.detalle_pago === '' || selectedInvoice.detalle_pago === undefined) {
    let newInvoice = JSON.parse(selectedInvoice.data_transaccion)
    newInvoice.id_factura = selectedInvoice.id; // Agrega el id de selectedInvoice a newInvoice

    setSelectedMethods([newInvoice]); // Esto reemplaza el contenido anterior de selectedMethods con newInvoice
  }else{
    let newInvoice = selectedInvoice.detalle_pago
    let formatedInvoice = '[' + newInvoice.replace('}{', '},{') + ']';
    let newInvoiceFormated = JSON.parse(formatedInvoice)    
    // Agrega el id de selectedInvoice a cada objeto de factura en el array
    newInvoiceFormated = newInvoiceFormated.map(invoice => {
        invoice.id_factura = selectedInvoice.id;
        return invoice;
    });
    
    setSelectedMethods(newInvoiceFormated); // Esto reemplaza el contenido anterior de selectedMethods con newInvoice  
  }
}


  return (
    <div>
     <Table className="mb-0" hover style={{ fontSize: 10, display:"none" }} >
                <tbody>
                    <div style={{ overflowY: "auto", maxHeight: "200px", Width: "100%" }}>
                        {contentCompra()}
                    </div>{" "}
                    <tr>
                        <td
                            colSpan={3}
                            className="text-primary"
                            align="center"
                            style={{ padding: 0, paddingTop: 30 }}
                        >
                            <strong>RESUMEN DE TU COMPRA</strong>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} style={{ padding: 5 }} align="left">
                            <strong className="text-info">VALOR DE ENTRADAS:</strong>
                        </td>
                        <td style={{ padding: 5 }} align="right">
                            <strong className="text-info">{totalPago()}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td
                            colSpan={3}
                            className="text-danger"
                            align="left"
                            style={{ padding: 5 }}
                        >
                            <strong>Impuestos</strong>
                        </td>
                    </tr>
                    {contentImpuestos()}
                    <tr>
                        <td
                            colSpan={2}
                            align="left"
                            style={{ padding: 5, paddingTop: 8, background: "#d3d8ef" }}
                        >
                            <strong>SUB TOTAL DE ENTRADAS</strong>
                        </td>
                        <td align="right" style={{ padding: 5, paddingTop: 10, background: "#d3d8ef" }}><strong>{subTotalEntradas()}</strong></td>
                    </tr>
                    {contentServTaquilla()}
                    <tr className="text-info" style={{ fontSize: 14 }}>
                        <td colSpan={2} align="left">
                            <strong>TOTAL A PAGAR:</strong>
                        </td>
                        <td align="right"><strong>{montoAPagar}</strong></td>
                    </tr>
                </tbody>
      </Table>

      <div className="mb-3">
        <select name="facturas" id="facturas" className="form-control" onChange={changeInvoice}>
        <option value="">Seleccione una factura pendiente</option>
            {halfInvoices && halfInvoices.map((invoice)=>{
              let DataT = "";
              let DetalleP = "";

              if(invoice.data_transaccion && invoice.data_transaccion !== undefined){
                DataT = JSON.parse(invoice.data_transaccion);
              }

              if(invoice.detalle_pago && invoice.detalle_pago !== undefined){
                let newInvoice = invoice.detalle_pago
                let formatedInvoice = '[' + newInvoice.replace('}{', '},{') + ']';
                let newInvoiceFormated = JSON.parse(formatedInvoice)
                DetalleP = newInvoiceFormated
              }

              const totalAmount = DetalleP.reduce((total, item) => {
                let amount = parseFloat(item.amount);
                if (item.moneda === 'Bs.') {
                  amount = amount / tasaCambio.valor_1usd_moneda_local; // Convierte de Bs a USD
                }
                return total + amount;
              }, 0);

              return (
                <option value={invoice.id} key={invoice.id}>
                {DataT && DataT !== "" && DetalleP && DetalleP !== "" && !isNaN(totalAmount) ? `Factura Nro.: ${invoice.id} - Monto total: ${totalAmount.toFixed(2)} USD` 
                 : `Factura Nro.: ${invoice.id}`}
               </option>
              )

            })}
        </select>
      </div>

      <CardTitle className="twitter">
      <Row>
          <Col md={6}>
            Monto restante en
            {" "+montoBs}
          </Col>

          <Col md={6}>
            Monto restante en USD 
            {' '+ monto}
          </Col>
        </Row>
      </CardTitle>

      <Card className="p-3">
      <div className="">
    <h3 className="">Datos del cliente</h3>
    <label htmlFor="">Nombre y apellido</label>
    <input type="text" name="nombre" className="form-control" maxLength={60} placeholder="Inserte el nombre y apellido del comprador" onChange={handleInputChange} />
    
    <label htmlFor="">Correo electrónico</label>
    <input type="email" name="correo" className="form-control" maxLength={60} placeholder="Ej. cliente@gmail.com" onChange={handleInputChange} />

    <hr />
</div>
  <h3 className="">Métodos de pago</h3>
      <select name="" id="" className="form-control" onChange={handleSelectChange}>
        <option value="">Seleccione un método de pago</option>
        {methods &&
          methods.sort((a, b) => a.method.localeCompare(b.method)).map((method) => {
            return (
              <option value={method.method} key={method.id}>
                {method.method}
              </option>
            );
          })}
      </select>

        {handleMethod(metodoSelect[0])}
      </Card>

      <CardFooter className="ml-3">
      {completePayment()}
      </CardFooter>
    </div>
  );
}

export default MultiPaymentTaquilla;
