import React, { useEffect, useState } from 'react';
import { Modal } from "reactstrap";

const ContentModal = (props) => {
    const [open, setOpen] = useState(props.isOpen);

    useEffect(() => {
        setOpen(props.isOpen)
    }, [props]);

    return (
        <Modal className = "modal-dialog-centeredmodal"  size = {props.size} isOpen = {open}>
            <div style = {{padding: 30}}>
            <div className = "modal-header" style = {{borderBottom: 0}}>
                <h6 className = "modal-title text-info" id = "modal-title-notification" style = {{fontWeight: "bold"}}>
                    {props.title}
                </h6>
                <button aria-label = "Close" className = "close" data-dismiss = "modal" type = "button" onClick = {() => props.close()}>
                    <span aria-hidden = {true} style = {{color: "#424242"}}>×</span>
                </button>
            </div>
            <div className = "modal-body" style = {{background: "#ffffff", color: "#636363", fontSize: 14, padding: 10}}>{props.content}</div>
            </div>
        </Modal>
    )
}

export default ContentModal;