import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faCheckCircle, faTicketAlt, faCalendarAlt, faMoneyCheck, faPause, faCheck, faStopCircle, faPauseCircle, faPlayCircle, faSpinner, faFrown, faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { toast } from 'react-toastify';

import ConfirmModal from "components/Modals/ConfirmModal";

import { PauseFuncion, ActivateFuncion, CerrarFuncion } from "functions/EventosFunctions";

const DetalleHistFuncion = (props) => {
    const [soldOut, setSoldOut] = useState("");
    const [dropdownOpen, setDropdownOpen]   = useState(false);
    const [botonAc, setBotonAc]             = useState({ icon: faCogs, text: "Acciones", spin: false, disabled: false })
    const [openModalConPause, setOpenModalConPause]     = useState(false)
    const [openModalConActive, setOpenModalConActive]   = useState(false)
    const [openModalFinaliza, setOpenModalFinaliza]     = useState(false)
    const [botonYes, setBotonYes]           = useState({ icon: faCheck, text: "Si", spin: false, disabled: false })
    const [status, setStatus]               = useState(props.data[0].status)
    const [disabled, setDisabled]   = useState(false);

    useEffect(() => {
        if (props.data[0].vendidos >= props.data[0].capacidad_total) setSoldOut("soldout-pq");
        setStatus(props.data[0].status);
        if (props.data[0].status === "Finalizado") setDisabled(true)
    }, [props]);
    
    let cambiaFormato = (fecha) => {
        let separa = fecha.split("/");
        return `${separa[1]}/${separa[0]}/${separa[2]}`
    }

    let dia = (tipo, date) => {
        let diaActual = new Date(cambiaFormato(date));
        let day = diaActual.getDate();
        if(day < 10) day = `0${day}`;
        let meses = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC' ]
        let month = meses[diaActual.getMonth()];

        if (tipo === "dia"){
            return day;
        }else{
            return month;
        }
    }

    let modalConfirmPausar = () => {
        if (openModalConPause === false) return null;
        
        let pausar = () => {
            if (botonYes.disabled === true) return null;

            setBotonYes({ icon: faSpinner, text: "Espera", spin: true, disabled: true });
            PauseFuncion(props.data.id, (resp) => {
                setBotonYes({ icon: faCheck, text: "Si", spin: false, disabled: false });

                switch (true) {
                    case (resp.status === "error" || resp.status === "error_log"):
                        toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                        setOpenModalConPause(false);
                    break;
                    default:
                        setStatus("Pausada");
                        toast.success(<small><span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                        props.updList();
                        setOpenModalConPause(false);
                    break;
                }
            })
        }

        return (
            <ConfirmModal
                isOpen = {openModalConPause}
                content = {<>
                    <div>¿Deseas pausar esta función?</div>
                    <div style = {{paddingLeft: 20, fontSize: 12}}><strong className = "text-primary">{props.data.evento_info_simple.name_event}</strong></div>
                    <div style = {{paddingLeft: 20, fontSize: 12}} className = "text-muted"><strong>{`Funcion: ${props.data.hora_in} | ${props.data.hora_out}`}</strong></div>
                </>}
                disabled = {botonYes.disabled}
                buttonYes = {<><FontAwesomeIcon icon = {botonYes.icon} spin = {botonYes.spin}/> {botonYes.text}</>}
                close = {() => setOpenModalConPause(false)}
                funcion = {() => pausar()}
            />
        )
    }

    let modalConfirmActivar = () => {
        if (openModalConActive === false) return null;

        let activar = () => {
            if (botonYes.disabled === true) return null;

            setBotonYes({ icon: faSpinner, text: "Espera", spin: true, disabled: true });

            ActivateFuncion(props.data.id, (resp) => {
                setBotonYes({ icon: faCheck, text: "Si", spin: false, disabled: false });

                switch (true) {
                    case (resp.status === "error" || resp.status === "error_log"):
                        toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                        setOpenModalConActive(false);
                    break;
                    default:
                        setStatus("Activo");
                        toast.success(<small><span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                        props.updList();
                        setOpenModalConActive(false);
                    break;
                }
            })
        }

        return (
            <ConfirmModal
                isOpen = {openModalConActive}
                content = {<>
                    <div>¿Deseas activar esta función?</div>
                    <div style = {{paddingLeft: 20, fontSize: 12}}><strong className = "text-primary">{props.data.evento_info_simple.name_event}</strong></div>
                    <div style = {{paddingLeft: 20, fontSize: 12}} className = "text-muted"><strong>{`Funcion: ${props.data.hora_in} | ${props.data.hora_out}`}</strong></div>
                </>}
                disabled = {botonYes.disabled}
                buttonYes = {<><FontAwesomeIcon icon = {botonYes.icon} spin = {botonYes.spin}/> {botonYes.text}</>}
                close = {() => setOpenModalConActive(false)}
                funcion = {() => activar()}
            />
        )
    }

    let modalConfirmFinalizar = () => {
        if (openModalFinaliza === false) return null;

        let  finalizar = () => {
            if (botonYes.disabled === true) return null;

            setBotonYes({ icon: faSpinner, text: "Espera", spin: true, disabled: true });

            CerrarFuncion(props.data.id, (resp) => {
                setBotonYes({ icon: faCheck, text: "Si", spin: false, disabled: false });

                switch (true) {
                    case (resp.status === "error" || resp.status === "error_log"):
                        toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                        setOpenModalConActive(false);
                    break;
                    default:
                        setStatus("Finalizado");
                        toast.success(<small><span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                        props.updList();
                        setOpenModalFinaliza(false);
                    break;
                }
            })
        }

        return (
            <ConfirmModal
                isOpen = {openModalFinaliza}
                content = {<>
                    <div>¿Deseas <strong className = "text-danger">FINALIZAR</strong> esta función?</div>
                    <div className = "custom-mt-10" style = {{paddingLeft: 20, fontSize: 12}}><strong className = "text-primary">{props.data.evento_info_simple.name_event}</strong></div>
                    <div style = {{paddingLeft: 20, fontSize: 12}} className = "text-muted"><strong>{`Funcion: ${props.data.hora_in} | ${props.data.hora_out}`}</strong></div>
                    <div className = "custom-mt-10" style = {{fontSize: 12, marginBottom: 10}}><strong>IMPORTANTE: Las funciones finalizadas no pueden activarse</strong></div>
                </>}
                disabled = {botonYes.disabled}
                buttonYes = {<><FontAwesomeIcon icon = {botonYes.icon} spin = {botonYes.spin}/> {botonYes.text}</>}
                close = {() => setOpenModalFinaliza(false)}
                funcion = {() => finalizar()}
            />
        )
    }

    let statusFuncion = () => {
        switch (status){
            case "Preload": return (
                <div align = "center" style = {{fontSize: 16}} className = "text-muted">
                    <FontAwesomeIcon icon = {faCircleNotch} spin = {true}/>
                    <div>Espera</div>
                </div>
            )
            case "Vendido": return (
                <div align = "center" style = {{fontSize: 16}} className = "text-success">
                    <FontAwesomeIcon icon = {faCheckCircle}/>
                    <div>Vendido</div>
                </div>
            )
            case "Vencido": return (
                <div align = "center" style = {{fontSize: 16}} className = "text-danger">
                    <FontAwesomeIcon icon = {faCalendarAlt}/>
                    <div>Vencido</div>
                </div>
            )
            case "Activo": return (
                <div align = "center" style = {{fontSize: 16}} className = "text-primary">
                    <FontAwesomeIcon icon = {faTicketAlt}/>
                    <div>Activo</div>
                </div>
            )
            case "Pausada": return (
                <div align = "center" style = {{fontSize: 16}} className = "text-warning">
                    <FontAwesomeIcon icon = {faPause}/>
                    <div>Pausada</div>
                </div>
            )
            case "Finalizado": return (
                <div align = "center" style = {{fontSize: 16}} className = "text-muted">
                    <FontAwesomeIcon icon = {faTimes}/>
                    <div>Finalizado</div>
                </div>
            )
            default: return null;
        }
    }

    let acciones = () => {
        switch(status){
            case "Vendido": return (
                <div>
                    <DropdownItem title = "Permite solicitar el retiro de dinero obtenido en esta función" style = {{padding: 5}} ><FontAwesomeIcon style = {{color: "#1d592a"}} icon = {faMoneyCheck}/><small>Retirar Dinero</small></DropdownItem>
                </div>
            );
            case "Vencido": return (
                <div>
                    <DropdownItem title = "Permite solicitar el retiro de dinero obtenido en esta función" style = {{padding: 5}} ><FontAwesomeIcon style = {{color: "#1d592a"}} icon = {faMoneyCheck}/><small>Retirar Dinero</small></DropdownItem>
                </div>
            );
            case "Activo": return (
                <div>
                    <DropdownItem title = "Finalizar el evento" onClick = {() => setOpenModalFinaliza(true)} style = {{padding: 5}} ><FontAwesomeIcon style = {{color: "#f5365c"}} icon = {faStopCircle}/><small>Finalizar Evento</small></DropdownItem>
                    <DropdownItem title = "Pausar el evento" onClick = {() => setOpenModalConPause(true)} style = {{padding: 5}} ><FontAwesomeIcon style = {{color: "#DE932E"}} icon = {faPauseCircle}/><small>Pausar Evento</small></DropdownItem>
                </div>
            );
            case "Pausada": return (
                <div>
                    <DropdownItem title = "Permite activar la función" onClick = {() => setOpenModalConActive(true)} style = {{padding: 5}} ><FontAwesomeIcon style = {{color: "#1d592a"}} icon = {faPlayCircle}/><small>Activar Evento</small></DropdownItem>
                </div>
            );
            case "Finalizado": return (
                <div>
                    <DropdownItem title = "Permite solicitar el retiro de dinero obtenido en esta función" style = {{padding: 5}} ><FontAwesomeIcon style = {{color: "#1d592a"}} icon = {faMoneyCheck}/><small>Retirar Dinero</small></DropdownItem>
                </div>
            );
            default: return null
        }
    }

    let color = (clase) => {
        if (status === "Finalizado") return "text-muted"
        return clase
    }

    return (
        <>
            <tr key = {props.data.id}>
                <td align = "center">
                    <div className = {soldOut}>
                    <div className = {color("text-warning")} style = {{fontSize: 14}}>
                        {dia("mes", props.data.fecha)}
                    </div>
                    <div className = {color("text-warning")} style = {{fontSize: 20}}>
                        <strong>{dia("dia", props.data.fecha)}</strong>
                    </div>
                    </div>
                </td>
                <td align = "left">
                    <h6 className = {color("text-primary")}><strong>{props.data.evento_info_simple.name_event}</strong></h6>
                    <div><strong>{`Función: ${props.data.hora_in} | ${props.data.hora_out}`}</strong></div>
                </td>
                <td align = "center"><strong>{props.data.fecha}</strong></td>
                <td align = "center"><h6 className = {color("text-primary")}><strong>{props.data[0].capacidad_total}</strong></h6></td>
                <td align = "center"><h6 className= {color("text-success")}><strong>{props.data[0].vendidos}</strong></h6></td>
                <td align = "center">{statusFuncion()}</td>
                <td align = "center">
                    <ButtonDropdown isOpen={dropdownOpen} toggle = {() => { setDropdownOpen(!dropdownOpen) }}>
                        <DropdownToggle caret className = "btn-outline-muted" size = "sm">
                            <small><FontAwesomeIcon icon = {botonAc.icon} spin = {botonAc.spin}/> {botonAc.text}</small>
                        </DropdownToggle>
                        <DropdownMenu>
                            {acciones()}
                        </DropdownMenu>
                    </ButtonDropdown>
                </td>
            </tr>
            {modalConfirmPausar()}
            {modalConfirmActivar()}
            {modalConfirmFinalizar()}
        </>
    )
}

export default DetalleHistFuncion;