import React, {  } from 'react';
import { Row, Col, Container } from "reactstrap";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const EventoDetallePreload = (props) => {

    return (
        <section style = {{backgroundColor: "#f9f9f9"}}>
            <Container className = "custom-margin">
                
                <Row>
                    <Col md = {12}>
                        <Skeleton height = {400}/>
                    </Col>
                    <Col md = {8} className = "custom-mt-60">
                        <Skeleton count = {9}/>
                    </Col>
                    <Col md = {4} className = "custom-mt-60">
                        <Skeleton count = {9}/>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default EventoDetallePreload;