import { faCircleNotch, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deletSelectedVerificador } from "functions/EventosFunctions";
import { listSelectedVerificadores } from "functions/EventosFunctions";
import { saveVerificadores } from "functions/EventosFunctions";
import { showTaquilla } from "functions/EventosFunctions";
import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import Select from 'react-select';
import { toast } from "react-toastify";
import { Button } from "reactstrap";

const SelectVerificador = (props) => {
    const [data, setData] = useState([]);
    const [evento, setEvento] = useState(props.id);
    const [functPreolad, setFunctPreload]   = useState({icon: faCircleNotch, spin: true, text: "Cargando, por favor espere..."});
    const cargando = <td align = "center" style = {{padding: 10}} colSpan = {7}><FontAwesomeIcon icon = {functPreolad.icon} spin = {functPreolad.spin}/> <span>{functPreolad.text}</span></td>
    const [verificador, setVerificador] = useState([]);

    const [verificadorSelect, setVerificadorSelect] = useState([])
  
    useEffect(()=>{
        showTaquilla((resp)=>{
            switch(true){
                case(resp.status === 'error' || resp.status === 'error_log'):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>)
                break;
                    case (resp.length === 0):
                    setFunctPreload({icon: faTimes, spin: false, text: "No existen usuarios registrados"});
                    setData([])
                break;
                default:
                    setData(resp.verificadores)
                break;
            }
        })
  }, [props]);

  useEffect(()=>{
    listSelectedVerificadores(evento, (resp)=>{
        switch(true){
            case(resp.status === 'error' || resp.status === 'error_log'):
                toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>)
            break;
                case (resp.length === 0):
                setFunctPreload({icon: faTimes, spin: false, text: "No existen usuarios registrados"});
                setVerificadorSelect([])
            break;
            default:
                const response = resp.message

                if(typeof(response) !== 'string'){
                    const res =  response.map(verify =>(
                        {value: verify.verificadores, label: verify.email_verificador }
                    ))
    
                    setVerificadorSelect(res)
                }
            break;
        }
    })
}, [props]);

  const handleVerificador = (verificador) => {
    setVerificadorSelect(verificador)
  };
  
  
  const opcVerify = useMemo(() =>
    data.map(verifyTaquilla => (
        {value:verifyTaquilla.id, label: verifyTaquilla.email }))
  , [data]);

  
  return (
    <>
    <Select
    value={verificadorSelect}
    onChange={handleVerificador}
    options={opcVerify}
    isMulti isSearchable = {true}
    placeholder="Seleccione un verificador para su evento"
    />
        <Button variant="primary" className="mt-3" onClick={()=>SaveVerificador(verificadorSelect, evento)}>Asignar verificador</Button>
    </>
  
  )
  }

  const SaveVerificador = (verificador, evento) =>{
    
    let content = {
        verificadores: verificador,
        evento: evento
    }

    
    if(verificador.length === 0){
        toast.error("Debe seleccionar al menos un verificador")
    }else{
        saveVerificadores(content, (resp)=>{
            switch(true){
                case(resp.status === 'error'):
                    toast.error(resp.mess);
                break;
                default:
                    toast.success(resp.mess);
                break;
            }
    
        })
    }
  }



const Verify = (props) =>{
    return(
        <SelectVerificador id = {props.id} />
    )
}

export default Verify