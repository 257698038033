import React, {useEffect, useState} from "react";
import { Redirect } from "react-router-dom";

import { Pagination, PaginationItem, PaginationLink, Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCircleNotch, faFrown, faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import { SearchEventsCat } from "functions/EventosFunctions";

import NavbarI from "components/Navbars/NavbarI.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import EventosPreload from "components/Skeletons/EventosPreload";
import EventoCard from "../../components/Eventos/EventoCard";

const CategoriaEvento = (props) => {
    const [preloadE, setPreloadE]       = useState(true);
    const [dataEventos, setDataEventos] = useState([]);
    const [preloader, setPreloader]     = useState({icon: faCircleNotch, text: "Buscando los últimos eventos", spin: true});
    const [preNameCat, setPreNameCat]   = useState({icon: faCircleNotch, text: "", spin: true});
    const [redirect, setRedirect]       = useState(false)

    const [paginacion, setPaginacion]       = useState(false);
    const [maxSearch, setMaxSearch]         = useState(3);
    const [pagActiva, setPagActiva]         = useState(1);
    const [results, setResults]              = useState(0);

    useEffect(() => {
        let url = window.location.href;
        let part = url.split("/");
        let urlEvent = part.pop();
        setPreloadE(true);
        setDataEventos([]);
        setPreloader({icon: faCircleNotch, text: "Buscando los últimos eventos", spin: true});
        setPreNameCat({icon: faCircleNotch, text: "", spin: true});

        /* let pagAct = JSON.parse(sessionStorage.getItem("ContentPagActivaCat"));
    
        if (pagAct === null || pagAct === undefined){
            setPagActiva(1);
        }else{
            setPagActiva(pagAct.num);
        } */

        SearchEventsCat(urlEvent, maxSearch, pagActiva, (resp) => {
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                    setRedirect(true)
                break;
                default:
                    if ((resp.data).length === 0){
                        setPreloader({icon: faFrown, text: `No existen eventos registrados para ${resp.cat_name}`, spin: false})
                    }

                    setPreNameCat({icon: "", text: `de ${resp.cat_name}`, spin: false})
                    setDataEventos(resp.data);
                    setPaginacion (resp.paginador);
                    setResults(resp.results);
                    setPreloadE(false);
                break;
            }
        });
    }, [props]);

    let paginador = () => {
        let url = window.location.href;
        let part = url.split("/");
        let urlEvent = part.pop();

        let numPag = 1;
        let cantPag = 1;
        let intervalo = 0;

        let arrPag = [{
            num: 1,
            intervalo: 1
        }];

        if(paginacion === true){
            for (let i = 0; i <= results; i++){
                if (numPag > maxSearch){
                  cantPag++;
                  intervalo += maxSearch;
                  arrPag.push({
                    num: cantPag,
                    intervalo: intervalo + 1
                  });
        
                  numPag = 0;
                }
                numPag++;
            }

            let pagClick = (option) => {
                sessionStorage.setItem("ContentPagActivaCat", JSON.stringify(option));
                setPagActiva(option.num);
                setPreloadE(true);

                SearchEventsCat(urlEvent, maxSearch, option.intervalo, (resp) => {
                  setDataEventos(resp.data);
                  setPreloadE(false);
                  setResults(resp.results);
                });
            }

            let pagClickIni = (option) => {
                const search = option.find(i => i.num === pagActiva - 1);
                if (search !== undefined){
                    sessionStorage.setItem("ContentPagActiva", JSON.stringify(search.num));
                    setPagActiva(search.num);
                    setPreloadE(true);

                    SearchEventsCat(urlEvent, maxSearch, search.intervalo, (resp) => {
                        setDataEventos(resp.data);
                        setPreloadE(false);
                        setResults(resp.results);
                    });
                }
            }

            let pagClickFin = (option) => {
                const search = option.find(i => i.num === pagActiva + 1);
                if (search !== undefined){
                    sessionStorage.setItem("ContentPagActiva", JSON.stringify(search.num));
                    setPagActiva(search.num);
                    setPreloadE(true);

                    SearchEventsCat(urlEvent, maxSearch, search.intervalo, (resp) => {
                        setDataEventos(resp.data);
                        setPreloadE(false);
                        setResults(resp.results);
                    });
                }
            }

            let items = () => (
                arrPag.map(option => {
                    let active = false
                    if(pagActiva === option.num) active = true;
                
                    return(
                        <PaginationItem active = {active} key = {option.num}>
                            <PaginationLink onClick = {() => pagClick(option)}>{option.num}</PaginationLink>
                        </PaginationItem>
                    )
                })
            );

            let IniDisabled = false;
            let FinDisabled = false;
            if (pagActiva === 1) IniDisabled =  true;
            if (pagActiva === arrPag.length) FinDisabled =  true;
    
            return (
                <Col md = {12} align = "center" className='py-3'>
                    <Pagination aria-label = "Page navigation example">
                        <PaginationItem disabled = {IniDisabled}>
                          <PaginationLink onClick = {() => pagClickIni(arrPag)}><FontAwesomeIcon icon = {faCaretLeft} /></PaginationLink>
                        </PaginationItem>
                            {items()}
                        <PaginationItem disabled = {FinDisabled}>
                          <PaginationLink onClick = {() => pagClickFin(arrPag)}><FontAwesomeIcon icon = {faCaretRight} /></PaginationLink>
                        </PaginationItem>
                    </Pagination>
                </Col>
            )
        }
    }

    let listEventos = () => {
        let keys = [1, 2, 3, 4, 5, 6];

        if (preloadE === true) return (
          <Row style = {{marginBottom: 40}}>
            {keys.map(option => (
              <Col key = {option} md = {4} className = "custom-mt-20"><EventosPreload /></Col>
            ))}
          </Row>
        );

        if (dataEventos.length === 0) return (
            <div align = "center" style = {{padding: 80, marginTop:'15vh', marginBottom:"15vh"}}>
                <FontAwesomeIcon icon = {preloader.icon} spin = {preloader.spin} size = "4x"/> <div className = "text-warning">{preloader.text}</div>
            </div>
        )

        return (
            <div style = {{padding: 41}}>
                <Row>
                    {dataEventos.map(option => (
                        <EventoCard data = {option} key = {option.id} />
                    ))}
                </Row>
            </div>
        )
    }

    let contenido = () => (
<section className="pb-0 section-components" style={{backgroundColor: "#f9f9f9", marginTop: "10%"}}>
  <div>
  <Container className="custom-margin">
  <Row>
    <Col xs={12} md={12}>
      <h2 className="mb-5">
        <span className="d-md-inline d-none"><strong>Eventos </strong></span>
        <span className="d-md-none"><br /><strong>Eventos </strong></span>
        <strong><FontAwesomeIcon icon={preNameCat.icon} spin={preNameCat.spin}/> {preNameCat.text}</strong>
      </h2>
    </Col>
  </Row>
</Container>
    <Container className="custom-margin custom-mt-media-10">
      {listEventos()}
      <Row>
        {paginador()}
      </Row>
    </Container>
  </div>
</section>
    )

    if (redirect === true) return <Redirect to = "/"/>;

    return (
        <>
            <NavbarI />
            {contenido()}
            <CardsFooter />
        </>
    )
}

export default CategoriaEvento;