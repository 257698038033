import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Card, CardBody, Container } from "reactstrap";
import Carousel from 'react-bootstrap/Carousel'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faCalendarAlt, faClock } from '@fortawesome/free-solid-svg-icons';

import { UrlImg } from "functions/Url";

import { SearchSliders } from "functions/SlidersFunctions";
let BannerI = (props) => {
  const [data, setData] = useState("")

  useEffect(() => {
    SearchSliders((resp) => {
      setData(resp.data)
    })
  }, [props]);

  if (data === "") return <div className="preload-ticket">
    <div className="preload-ticket-icon"><FontAwesomeIcon icon={faCircleNotch} size="5x" spin={true} /></div>
  </div>

  let cambiaFormato = (fecha) => {
    let separa = fecha.split("/");
    return `${separa[1]}/${separa[0]}/${separa[2]}`
  }

  let validateFechaAndStatus = (status, date) => {
    const [day, month, year] = date.split('/');
    const providedDate = new Date(year, month - 1, day);

    // Obtener la fecha actual
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if (status !== 4 || currentDate > providedDate) {
      return false
    }
    return true
  }

  if (data.length === 0) return (
    <div className="position-relative" style={{ backgroundColor: "#424242" }}>
      <section className="section-hero section-shaped">
        {/* Imagen por defecto */}
      </section>
    </div>
  )

  return (
    <>
      <section className="section pb-0 section-components d-none d-sm-block d-md-block d-lg-block d-xl-block ml-4" style={{ backgroundColor: "#f9f9f9" }}>
        <div className="py-4">
          <Container className="ml--4">
            <Carousel style={{ height: "60vh", display: "flex", width: '103vw' }}>
              {data.map(option => (
                <Carousel.Item style={{ height: '80vh' }} key={option.id} interval={6000}>
                  <div>
                    <img src={`${UrlImg}events/${option.evento_info_simple.image}`} alt="..." style={{ width: "100vw", height: "60vh", display: "flex", justifyContent: "center", alignItems: "center" }} />

                    <div className="banner-button">
                      <div align="center">
                        <span className="bg-light rounded bold p-2">
                          <strong>Función: {option.funcion_info_simple.fecha}</strong>
                        </span><br></br>

                        {validateFechaAndStatus(option.evento_info_simple.status, option.funcion_info_simple.fecha) ? <Link to={`/event/${option.evento_info_simple.url_event}#${option.id_funcion}`} style={{ borderRadius: "12px", padding: '10px 60px' }} className="btn btn-info mt-4">
                          Comprar
                        </Link> : null}

                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </Container>
        </div>
      </section>
    </>
  );
}

export default BannerI;
