
export const Administrador = [
    // {
    //     id: 1,
    //     path: "/dashboard/home",
    //     name: "Inicio",
    //     icon: "fa fa-home",
    //     component: "",
    //     color: "#424242"
    // },
    {
        id: 2,
        path: "/dashboard/perfil",
        name: "Mi perfil",
        icon: "fa fa-address-book",
        component: "",
        color: "#424242"
    },
    // {
    //     id: 3,
    //     path: "/dashboard/cuentas",
    //     name: "Mis cuentas",
    //     icon: "fa fa-credit-card",
    //     component: "",
    //     color: "#424242"
    // },
    {
        id: 4,
        path: "/dashboard/recintos",
        name: "Recintos",
        icon: "fa fa-globe",
        component: "",
        color: "#424242"
    },
    {
        id: 5,
        path: "/dashboard/eventos",
        name: "Eventos",
        icon: "fa fa-film",
        component: "",
        color: "#424242"
    },
    {
        id: 6,
        path: "/dashboard/mis_retiros",
        name: "Mis retiros",
        icon: "fa fa-file-code-o",
        component: "",
        color: "#424242"
    },
    {
        id: 7,
        path: "/dashboard/mis_ventas",
        name: "Mis ventas",
        icon: "fa fa-money",
        component: "",
        color: "#424242"
    },
    {
        id: 8,
        path: "/dashboard/resumen_ventas",
        name: "Resumen ventas",
        icon: "fa fa-money",
        component: "",
        color: "#424242"
    },
    {
        id: 9,
        path: "/dashboard/mis_sliders",
        name: "Sliders",
        icon: "fa fa-picture-o",
        component: "",
        color: "#424242"
    },
    {
        id: 10,
        path: "/dashboard/administrar_usuarios",
        name: "Administrar usuarios",
        icon: "fa fa-users",
        component: "",
        color: "#424242"
    },
];


export const Productor = [
    // {
    //     id: 1,
    //     path: "/dashboard/home",
    //     name: "Inicio",
    //     icon: "fa fa-home",
    //     component: "",
    //     color: "#424242"
    // },
    {
        id: 2,
        path: "/dashboard/perfil",
        name: "Mi perfil",
        icon: "fa fa-address-book",
        component: "",
        color: "#424242"
    },
    {
        id: 3,
        path: "/dashboard/mis_cuentas",
        name: "Mis cuentas",
        icon: "fa fa-credit-card",
        component: "",
        color: "#424242"
    },
    {
        id: 4,
        path: "/dashboard/eventos",
        name: "Eventos",
        icon: "fa fa-film",
        component: "",
        color: "#424242"
    },
    {
        id: 6,
        path: "/dashboard/mis_retiros",
        name: "Mis retiros",
        icon: "fa fa-file-code-o",
        component: "",
        color: "#424242"
    },
    {
        id: 7,
        path: "/dashboard/mis_ventas",
        name: "Mis ventas",
        icon: "fa fa-money",
        component: "",
        color: "#424242"
    },
    {
        id: 8,
        path: "/dashboard/resumen_ventas",
        name: "Resumen ventas",
        icon: "fa fa-money",
        component: "",
        color: "#424242"
    },
    // {
    //     id: 9,
    //     path: "/dashboard/historico_funciones",
    //     name: "Funciones",
    //     icon: "fa fa-line-chart",
    //     component: "",
    //     color: "#424242"
    // }
];

export const Cliente = [
    {
        id: 1,
        path: "/",
        name: "Eventos",
        icon: "fa fa-home",
        component: "",
        color: "#424242"
    },
    {
        id: 2,
        path: "/dashboard/perfil",
        name: "Mi perfil",
        icon: "fa fa-address-book",
        component: "",
        color: "#424242"
    },
    {
        id: 3,
        path: "/dashboard/mis_compras",
        name: "Mis compras",
        icon: "fa fa-paste",
        component: "",
        color: "#424242"
    },
    {
        id: 3,
        path: "/dashboard/atencion_usuario",
        name: "Atencion al usuario",
        icon: "fa fa-whatsapp",
        component: "",
        color: "#424242" 
    }
];

export const Taquilla = [
    {
        id: 1,
        path: "/",
        name: "Venta de Entradas",
        icon: "fa fa-home",
        component: "",
        color: "#424242"
    },
    {
        id: 2,
        path: "/dashboard/perfil",
        name: "Mi perfil",
        icon: "fa fa-address-book",
        component: "",
        color: "#424242"
    },
    // {
    //     id: 3,
    //     path: "/dashboard/venta_entradas",
    //     name: "Venta de entradas",
    //     icon: "fa fa-paste",
    //     component: "",
    //     color: "#424242"
    // }
];

export const Verificador = [
    // {
    //     id: 1,
    //     path: "/dashboard/home",
    //     name: "Inicio",
    //     icon: "fa fa-home",
    //     component: "",
    //     color: "#424242"
    // },
    {
        id: 2,
        path: "/dashboard/perfil",
        name: "Mi perfil",
        icon: "fa fa-address-book",
        component: "",
        color: "#424242"
    },
    {
        id: 3,
        path: "/dashboard/verificacion_taquilla",
        name: "Verificación",
        icon: "fa fa-ticket",
        component: "",
        color: "#424242"
    }
];

export const Taquillero = [
    // {
    //     id: 1,
    //     path: "/dashboard/home",
    //     name: "Inicio",
    //     icon: "fa fa-home",
    //     component: "",
    //     color: "#424242"
    // },
    {
        id: 2,
        path: "/dashboard/perfil",
        name: "Mi perfil",
        icon: "fa fa-address-book",
        component: "",
        color: "#424242"
    },
    {
        id: 3,
        path: "/dashboard/mis_compras",
        name: "Mis compras",
        icon: "fa fa-paste",
        component: "",
        color: "#424242"
    }
];