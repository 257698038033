import React, {useEffect, useState} from "react";

const ButacaCustom = (props) => {
    const [customStyles, setCustomtyles] = useState({
        fontSize: props.fontSize - 3,
        color: props.color,
        padding: 2,
    });

    useEffect(() => {
        switch (props.tipo){
            case "cine1":
                let NumMinimo = 13;
                let NumActual = props.fontSize;
                let dif = (NumActual - NumMinimo)
                let total = (dif * 0.05) + 2
        
                setCustomtyles({
                    fontSize: props.fontSize - 3,
                    color: props.color,
                    padding: total,
                });
            break;
            case "silla1":
                let NumMinimoSilla1 = 13;
                let NumActualSilla1 = props.fontSize;
                let difSilla1 = (NumActualSilla1 - NumMinimoSilla1)
                let totalSilla1 = (difSilla1 * 0.14) + 2.16
        
                setCustomtyles({
                    fontSize: props.fontSize - 1,
                    color: props.color,
                    padding: totalSilla1,
                });
            break;
            default: return null;
        }
    }, [props]);

    switch (props.tipo){
        case "cine1":return (
            <span className = {`${props.clsName} icon-butaca1`} style = {customStyles} title = {props.title} onClick = {() => {
                if (props.onClickFn1 === undefined) return null;
                props.onClickFn1()
            }}>
                <span className = "path1"></span>
                <span className = "path2"></span>
                <span className = "path3"></span>
                <span className = "path4"></span>
                <span className = "path5"></span>
                <span className = "path6"></span>
                <span className = "path7"></span>
                <span className = "path8"></span>
                <span className = "path9"></span>
            </span>
        )
        case "silla1": return (
            <span className = {`${props.clsName} icon-silla1`} style = {customStyles} title = {props.title} onClick = {() => {
                if (props.onClickFn1 === undefined) return null;
                props.onClickFn1()
            }}></span>
        )
        default: return null;
    }
}

export default ButacaCustom;