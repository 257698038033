import React, {useState} from "react";
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCheck, faCircleNotch, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { BancosVenezuela } from "shared/data";
import ConfirmModal from "components/Modals/ConfirmModal";
import { DelCuenta } from "functions/CuentasFunctions";

const DetalleCuenta = (props) => {
    const [openModalCon, setOpenModalCon]   = useState(false)
    const [botonYes, setBotonYes]           = useState({ icon: faCheck, text: "Si", spin: false, disabled: false })

    let modalConfirm = () => {
        if (openModalCon === false) return null;

        let delCuenta = () => {
            setBotonYes({ icon: faCircleNotch, text: "Espera", spin: true, disabled: true })
            
            DelCuenta(props.data.id, (resp) => {
                setBotonYes({ icon: faCheck, text: "Si", spin: false, disabled: false })
                switch (true) {
                    case (resp.status === "error" || resp.status === "error_log"):
                        toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                    break;
                    default:
                        toast.info(<small><FontAwesomeIcon icon = {faCheckCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                        setOpenModalCon(false)
                        props.update(resp.data)
                    break;
                }
            })
        }

        return (
            <ConfirmModal
                isOpen = {openModalCon}
                content = {<div>¿Deseas eliminar esta cuenta de tus registros? </div>}
                disabled = {botonYes.disabled}
                buttonYes = {<><FontAwesomeIcon icon = {botonYes.icon} spin = {botonYes.spin}/> {botonYes.text}</>}
                close = {() => setOpenModalCon(false)}
                funcion = {() => delCuenta()}
            />
        )
    }

    let tipoCuenta = (data) => {
        let search = props.tipos.find(option => option.value === data);
        return search.label
    }

    let detalleCuenta = (data) => {
        let json = JSON.parse(data)
        
        let Content = [];
        for (var clave in json){
            switch(clave){
                case "Tipo de Cuenta":
                    let busqueda = props.tiposCta.find(option => option.value === json[clave]);
                    Content.push({key: clave, value: busqueda.label})
                break;
                case "Banco":
                    busqueda = BancosVenezuela.find(option => option.codigo === json[clave]);
                    Content.push({key: clave, value: busqueda.nombre})
                break;
                case "Tipo":
                    busqueda = props.nac.find(option => option.value === json[clave]);
                    Content.push({key: clave, value: busqueda.label})
                break;
                default:
                    Content.push({key: clave, value: json[clave]})
                break;
            }
        }

        return (
            Content.map(option => (
                <div><strong>{option.key}</strong> {option.value}</div>
            ))
        )
    }

    return (
        <>
            <tr key = {props.data.id}>
                <td><strong className = "text-danger">{tipoCuenta(props.data.tipo_registro)}</strong></td>
                <td>{detalleCuenta(props.data.detalle_registro)}</td>
                <td align = "center" valign = "center">
                    <FontAwesomeIcon icon = {faTrash} size = "lg" className = "text-muted" style = {{cursor: "pointer"}} title = "Eliminar esta cuenta" onClick = {() => setOpenModalCon(true)}/>
                </td>
            </tr>
            {modalConfirm()}
        </>
    )
}

export default DetalleCuenta;