import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";

import "moment/locale/es";
import ReactDatetime from "react-datetime";
import { toast } from "react-toastify";

// jgil: Este es un input que permite aplicar una mascara
import InputMask from "react-input-mask";

import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  NavLink,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignInAlt,
  faCircleNotch,
  faCheckCircle,
  faUserPlus,
  faSpinner,
  faEye,
  faEyeSlash,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import {
  AddUser,
  ConsultPaises,
  ConsultEstados,
  ConsultMunicipios,
  ConsultParroquias,
} from "functions/UserFunctions";
import { CalculaEdad } from "functions/Generales";

import ErrorsModal from "components/Modals/ErrorsModal";

import logo from "assets/img/logos/logo_1.png";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const RegistModal = (props) => {
  const [botonLog, setBotonLog] = useState({
    icon: faSignInAlt,
    text: "Inicia Sesión",
    spin: false,
    disabled: false,
  });
  const [botonReg, setBotonReg] = useState({
    icon: faUserPlus,
    text: "Registrarme",
    spin: false,
    disabled: false,
  });
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [bDate, setBDate] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [errors, setErrors] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [viewPass1, setViewPass1] = useState({
    icon: faEye,
    title: "Mostrar contraseña",
    type: "password",
    active: true,
  });
  const [viewPass2, setViewPass2] = useState({
    icon: faEye,
    title: "Mostrar contraseña",
    type: "password",
    active: true,
  });
  const [longAge, setLongAge] = useState("");
  const [paisSelect, setPaisSelect] = useState({ value: "", label: "País" });
  const [paisOpt, setPaisOpt] = useState({ disabled: false, loading: true });
  const [paises, setPaises] = useState([]);
  const [estadoSelect, setEstadoSelect] = useState({
    value: "",
    label: "Estado",
  });
  const [estadoOpt, setEstadoOpt] = useState({
    disabled: true,
    loading: false,
  });
  const [estados, setEstados] = useState([]);
  const [municipioSelect, setMunicipioSelect] = useState({
    value: "",
    label: "Municipio",
  });
  const [municipioOpt, setMunicipioOpt] = useState({
    disabled: true,
    loading: false,
  });
  const [municipios, setMunicipios] = useState([]);
  const [parroquiaSelect, setParroquiaSelect] = useState({
    value: "",
    label: "Parroquia",
  });
  const [parroquiaOpt, setParroquiaOpt] = useState({
    disabled: true,
    loading: false,
  });
  const [parroquias, setParroquias] = useState([]);
  const [ciSelect, setCiSelect] = useState({ value: "V", label: "V" });
  const [ci, setCi] = useState([
    { value: "V", label: "V" },
    { value: "E", label: "E" },
    { value: "C", label: "C" },
    { value: "J", label: "J" },
    { value: "P", label: "P" },
  ]);
  const [ciNum, setCiNum] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [codArea, setCodArea] = useState("");

  const [isOpen, setIsOpen] = useState(false);


  // jgil: Para la validación de la contraseña
  const [passConfirm, setPassConfirm] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [isFocused2, setIsFocused2] = useState(false);

  const [selectedOption, setSelectedOption] = useState();

  const [hasEightChars, setHasEightChars] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [accepted, setAccepted] = useState(false);

  // jgil: Para cerrar el dropdown cuando se haga click fuera de el
  const dropdownTwoRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownTwoRef.current &&
        !dropdownTwoRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    // Escuchar por click fuera del dropdown
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Limpiar el event listener cuando el componente se desmonte
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let Content = [];
    let ContentCod = [];

    ConsultPaises((resp) => {
      resp.data.forEach((i) => {
        Content.push({
          value: i.id,
          label: i.pais_name,
        });
        ContentCod.push({
          value: i.id,
          label: i.cod_area,
          pais: i.pais_name,
        });
      });

      setPaises(Content);
      setCodArea(ContentCod);
      setPaisOpt({ disabled: true, loading: false });
    });
  }, []);

  let changePais = (paisSelect) => {
    setPaisSelect(paisSelect);
    setEstadoSelect({ value: "", label: "Estado" });
    setMunicipioSelect({ value: "", label: "Municipio" });
    setParroquiaSelect({ value: "", label: "Parroquia" });

    let Content = [];

    setEstadoOpt({ disabled: false, loading: true });
    ConsultEstados(paisSelect.value, (resp) => {
      resp.data.forEach((i) => {
        Content.push({
          value: i.id,
          label: i.estado_name,
        });
      });

      setEstados(Content);
      setEstadoOpt({ disabled: true, loading: false });
    });
  };

  const customStylesSelect2 = {
    menu: (styles) => ({
      ...styles,
      color: "#000",
      fontSize: 16,
      zIndex: 3,
      minWidth: 20,
    }),
    multiValue: (styles) => ({ ...styles, backgroundColor: "#ccc" }),
    multiValueLabel: (styles) => ({ ...styles, color: "#000" }),
    placeholder: (styles) => ({ ...styles, fontSize: 14, color: "#b4bcc3" }),
    indicatorSeparator: (styles) => ({
      ...styles,
      backgroundColor: "rgba(255, 255, 255, 0)",
    }),
    control: (styles) => ({
      ...styles,
      borderRight: 0,
      borderColor: "#cccccc !important",
      boxShadow: "0 0 0 0px !important",
      borderRadius: 0,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      minHeight: 43,
      "&:hover": {
        borderColor: "#cccccc !important",
        boxShadow: "0 0 0 0px !important",
      },
    }),
  };

  let changeCi = (ciSelect) => {
    setCiSelect(ciSelect);
    setCiNum("");
  };

  let changeEstado = (estadoSelect) => {
    setEstadoSelect(estadoSelect);
    setMunicipioSelect({ value: "", label: "Municipio" });
    setParroquiaSelect({ value: "", label: "Parroquia" });

    let Content = [];

    setMunicipioOpt({ disabled: false, loading: true });
    ConsultMunicipios(estadoSelect.value, (resp) => {
      resp.data.forEach((i) => {
        Content.push({
          value: i.id,
          label: i.municipio_name,
        });
      });

      setMunicipios(Content);
      setMunicipioOpt({ disabled: true, loading: false });
    });
  };

  let changeMunicipio = (municipioSelect) => {
    setMunicipioSelect(municipioSelect);
    setParroquiaSelect({ value: "", label: "Parroquia" });

    let Content = [];
    setParroquiaOpt({ disabled: false, loading: true });
    ConsultParroquias(municipioSelect.value, (resp) => {
      resp.data.forEach((i) => {
        Content.push({
          value: i.id,
          label: i.parroquia_name,
        });
      });

      setParroquias(Content);
      setParroquiaOpt({ disabled: true, loading: false });
    });
  };

  let changeParroquia = (parroquiaSelect) => {
    setParroquiaSelect(parroquiaSelect);
  };

  let signIn = () => {
    props.close();
    props.openLogin();
  };

  function eliminarCaracteresEspeciales(cadena) {
    return cadena.replace(/[()\s-]/g, "");
  }

  let register = async (e) => {
    // setBotonReg ({icon: faSpinner, spin: true, text: "Registrando, espera", disabled: true})
    // setBotonLog (state => ({...state, disabled: true }))

    e.preventDefault();

    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha("homepage");

    let phoneNumber = selectedOption + eliminarCaracteresEspeciales(phone);

    const required = {
      ciNum,
      ciSelect,
      name,
      bDate,
      phoneNumber,
      age,
      email,
      pass1,
      pass2,
      paisSelect,
      estadoSelect,
      municipioSelect,
      parroquiaSelect,
      token,
      accepted,
    };

    const fields = {
      ciNum,
      ciSelect,
      name,
      lastName,
      bDate,
      phoneNumber,
      age,
      email,
      pass1,
      pass2,
      paisSelect,
      estadoSelect,
      municipioSelect,
      parroquiaSelect,
      token,
      accepted,
    };

    AddUser(required, fields, (res) => {
      switch (true) {
        case res[0].cantidad > 0:
          setErrors(res[0].errMesss);
          setOpenModal(true);
          setBotonReg({
            icon: faUserPlus,
            text: "Registrarme",
            spin: false,
            disabled: false,
          });
          setBotonLog((state) => ({ ...state, disabled: false }));
          break;
        case res[1].status === "errorRecaptcha":
          toast.error(res[1].msg);
          setBotonReg({
            icon: faUserPlus,
            text: "Registrarme",
            spin: false,
            disabled: false,
          });
          setBotonLog((state) => ({ ...state, disabled: false }));
          break;
        case res[1].status === "error":
          setErrors(res[1].mess);
          setOpenModal(true);
          setBotonReg({
            icon: faUserPlus,
            text: "Registrarme",
            spin: false,
            disabled: false,
          });
          setBotonLog((state) => ({ ...state, disabled: false }));
          break;
        case res[1].status === "errorBackend":
          res[1].msg.forEach((error) => {
            toast.error(error);
          });
          setBotonReg({
            icon: faUserPlus,
            text: "Registrarme",
            spin: false,
            disabled: false,
          });
          setBotonLog((state) => ({ ...state, disabled: false }));
          break;
        default:
          toast.success(
            <div>
              <FontAwesomeIcon icon={faCheckCircle} />{" "}
              <span dangerouslySetInnerHTML={{ __html: res[1].mess }}></span>
            </div>
          );
          signIn();
          break;
      }
    });
  };

  let verPass1 = () => {
    viewPass1.active === true
      ? setViewPass1({
          icon: faEyeSlash,
          title: "Ocultar Contraseña",
          type: "text",
          active: false,
        })
      : setViewPass1({
          icon: faEye,
          title: "Mostrar Contraseña",
          type: "password",
          active: true,
        });
  };

  let verPass2 = () => {
    viewPass2.active === true
      ? setViewPass2({
          icon: faEyeSlash,
          title: "Ocultar Contraseña",
          type: "text",
          active: false,
        })
      : setViewPass2({
          icon: faEye,
          title: "Mostrar Contraseña",
          type: "password",
          active: true,
        });
  };

  const customStylesSelect = {
    menu: (styles) => ({ ...styles, color: "#000", fontSize: 14, zIndex: 3 }),
    multiValue: (styles) => ({ ...styles, backgroundColor: "#ccc" }),
    multiValueLabel: (styles) => ({ ...styles, color: "#000" }),
    placeholder: (styles) => ({ ...styles, fontSize: 14, color: "#b4bcc3" }),
  };

  const validatePassword = (password) => {
    const hasEightChars = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasSymbol = /[\W]/.test(password);

    setHasEightChars(hasEightChars);
    setHasUpperCase(hasUpperCase);
    setHasLowerCase(hasLowerCase);
    setHasSymbol(hasSymbol);

    setAccepted(hasEightChars && hasUpperCase && hasLowerCase && hasSymbol);
  };

  const validatePasswordConfirm = (pass, passTwo) => {
    if (pass !== "" && passTwo !== "" && pass === passTwo) {
      setPassConfirm(true);
    } else {
      setPassConfirm(false);
    }
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lfo2G0oAAAAAIpjrpDVrEXdN2qMHjUhzE0wSTFM">
      <Row>
        <Col md={12}>
          <Form role="form">
            <FormGroup>
              <Row>
                <Col md={6}>
                  <small style={{ color: "#000000" }}>Identificación</small>
                  <InputGroup>
                    <Select
                      isSearchable={true}
                      styles={customStylesSelect2}
                      className="basic-multi-select"
                      onChange={changeCi}
                      options={ci}
                      noOptionsMessage={() => {
                        return "Sin Opciones";
                      }}
                      loadingMessage={() => {
                        return (
                          <>
                            <FontAwesomeIcon icon={faCircleNotch} spin={true} />{" "}
                            Cargando
                          </>
                        );
                      }}
                      value={ciSelect}
                    />
                    <Input
                      placeholder="Cédula de identidad"
                      type="text"
                      maxLength={ciSelect.value === "V" ? "8" : "9"}
                      value={ciNum}
                      onChange={(event) => {
                        const newValue = event.target.value;
                        if (!isNaN(newValue)) {
                          setCiNum(newValue);
                        }
                      }}
                    />
                  </InputGroup>
                </Col>
                <Col lg={6}>
                  <small style={{ color: "#000000" }}>Nombres</small>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Nombres"
                      maxLength="25"
                      type="text"
                      value={name}
                      onChange={(event) => {
                        const newValue = event.target.value;
                        if (/^[a-zA-Z ]*$/.test(newValue)) {
                          setName(newValue);
                        }
                      }}
                    />
                  </InputGroup>
                </Col>
                <Col lg={6}>
                  <small style={{ color: "#000000" }}>Apellidos</small>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Apellidos"
                      maxLength="25"
                      type="text"
                      value={lastName}
                      onChange={(event) => {
                        const newValue = event.target.value;
                        if (/^[a-zA-Z ]*$/.test(newValue)) {
                          setLastName(newValue);
                        }
                      }}
                    />
                  </InputGroup>
                </Col>

                <Col md={6} className="custom-mt-10">
                  <Row>
                    <Col md={4} xs={6}>
                      {/* Primer article */}
                      <article>
                      <small style={{ color: "#000000" }}>Cod. Area</small>
                  <br />

                  <div
                    className="dropdown w-100 form-control"
                    ref={dropdownTwoRef}
                  >
                    <span
                      className="w-100 text-left"
                      onClick={() => setIsOpen(!isOpen)}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {selectedOption || "Cod.  "} |{" "}
                      <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                    {isOpen && (
                      <ul className="m-3">
                        {codArea.map((option) => (
                          <li
                            key={option.value}
                            onClick={() => {
                              setPhone("");
                              setSelectedOption(option.label);
                              setIsOpen(false);
                            }}
                            className="list-item d-flex justify-content-between"
                          >
                            {option.label}
                            <img
                              alt="..."
                              src={`/assets/img/flags/${option.pais}.svg`}
                              height="auto"
                              width="25px"
                            />
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                      </article>
                    </Col>

                    <Col md={8} xs={6}>
                      {/* Segundo article */}
                      <article>
                      <small style={{ color: "#000000" }}>Teléfono</small>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-mobile-button" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <InputMask
                      className="form-control"
                      placeholder="Teléfono"
                      mask={
                        selectedOption === "+58"
                          ? "(999)-999-9999"
                          : "999999999999"
                      }
                      maskChar=" "
                      type="text"
                      value={phone}
                      onChange={(event) => {
                        const newValue = event.target.value;
                        setPhone(newValue);
                      }}
                    />
                  </InputGroup>
                      </article>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6}>
                  <small style={{ color: "#000000" }}>
                    Fecha de Nacimiento
                  </small>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{ placeholder: "Fecha de Nacimiento" }}
                      timeFormat={false}
                      locale="es"
                      closeOnSelect={true}
                      value={bDate}
                      onChange={(e) => {
                        setBDate(e);
                        CalculaEdad(e, (resp) => {
                          setLongAge(resp[0].fechaTit);
                          setAge(resp[0].edad);
                        });
                      }}
                    />
                  </InputGroup>
                  <Col
                    lg={6}
                    align="right"
                    style={{ marginBottom: 10, marginTop: 0 }}
                  >
                    <small style={{ color: "#000000", fontSize: 12 }}>
                      <strong>{longAge.text}</strong>
                    </small>
                  </Col>
                </Col>

                <Col md={6}>
                  <small style={{ color: "#000000" }}>País (Habitación)</small>
                  <Select
                    isLoading={paisOpt.loading}
                    isSearchable={true}
                    styles={customStylesSelect}
                    className="basic-multi-select"
                    onChange={changePais}
                    options={paises}
                    noOptionsMessage={() => {
                      return "Sin Opciones";
                    }}
                    loadingMessage={() => {
                      return (
                        <>
                          <FontAwesomeIcon icon={faCircleNotch} spin={true} />{" "}
                          Cargando paises
                        </>
                      );
                    }}
                    value={paisSelect}
                    placeholder="Pais"
                  />
                </Col>
                <Col md={6}>
                  <small style={{ color: "#000000" }}>
                    Estado (Habitación)
                  </small>
                  <Select
                    isLoading={estadoOpt.loading}
                    isSearchable={true}
                    styles={customStylesSelect}
                    className="basic-multi-select"
                    onChange={changeEstado}
                    options={estados}
                    noOptionsMessage={() => {
                      return "Sin Opciones";
                    }}
                    loadingMessage={() => {
                      return (
                        <>
                          <FontAwesomeIcon icon={faCircleNotch} spin={true} />{" "}
                          Cargando estados
                        </>
                      );
                    }}
                    value={estadoSelect}
                    placeholder="Estado"
                  />
                </Col>
                <Col lg={6}>
                  <small style={{ color: "#000000" }}>Correo electrónico</small>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Correo electrónico"
                      type="email"
                      value={email}
                      maxLength="40"
                      onChange={(event) =>
                        setEmail(event.target.value.toLowerCase())
                      }
                    />
                  </InputGroup>
                </Col>
                <Col lg={6}>
                  <small style={{ color: "#000000" }}>Contraseña</small>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Contraseña"
                      type={viewPass1.type}
                      autoComplete="off"
                      value={pass1}
                      onChange={(e) => {
                        setPass1(e.target.value);
                        validatePassword(e.target.value);
                        validatePasswordConfirm(e.target.value, pass2);
                      }}
                      onFocus={() => setIsFocused(true)}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText
                        title={viewPass1.title}
                        onClick={verPass1}
                        style={{ cursor: "pointer" }}
                      >
                        <FontAwesomeIcon icon={viewPass1.icon} />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <Row className="ml-1">
                    {hasUpperCase ||
                    hasLowerCase ||
                    hasEightChars ||
                    hasSymbol ? (
                      <div
                        style={{ width: 70, backgroundColor: "red", height: 7 }}
                      />
                    ) : null}
                    {(hasEightChars && hasLowerCase) ||
                    hasUpperCase ||
                    hasSymbol ? (
                      <div
                        style={{
                          width: 70,
                          backgroundColor: "orange",
                          height: 7,
                        }}
                      />
                    ) : null}
                    {(hasEightChars && hasLowerCase && hasUpperCase) ||
                    hasSymbol ? (
                      <div
                        style={{
                          width: 70,
                          backgroundColor: "yellow",
                          height: 7,
                        }}
                      />
                    ) : null}
                    {hasUpperCase &&
                    hasLowerCase &&
                    hasEightChars &&
                    hasSymbol ? (
                      <div
                        style={{
                          width: 70,
                          backgroundColor: "green",
                          height: 7,
                        }}
                      />
                    ) : null}
                  </Row>
                  {isFocused && (
                <div className="m-2">
                  <li style={{ color: hasEightChars ? 'green' : 'red', fontSize: '12px' }}>
                    Debe tener al menos 8 caracteres
                  </li>
                  <li style={{ color: hasUpperCase ? 'green' : 'red', fontSize: '12px' }}>
                    Debe tener al menos una letra mayúscula
                  </li>
                  <li style={{ color: hasLowerCase ? 'green' : 'red', fontSize: '12px' }}>
                    Debe tener al menos una letra minúscula
                  </li>
                  <li style={{ color: hasSymbol ? 'green' : 'red', fontSize: '12px' }}>
                    Debe tener al menos un símbolo
                  </li>
                </div>
              )}
                </Col>
                <Col lg={6}>
                  <small style={{ color: "#000000" }}>
                    Confirma contraseña
                  </small>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Repite la Contraseña"
                      type={viewPass2.type}
                      autoComplete="off"
                      value={pass2}
                      onChange={(e) => {
                        setPass2(e.target.value)
                        validatePasswordConfirm(e.target.value, pass1);
                      }}
                      onFocus={() => setIsFocused2(true)}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText
                        title={viewPass2.title}
                        onClick={verPass2}
                        style={{ cursor: "pointer" }}
                      >
                        <FontAwesomeIcon icon={viewPass2.icon} />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {isFocused2 && (
                    <div className="m-2">
                      <li style={{ color: passConfirm ? 'green' : 'red', fontSize: '12px' }}>
                        {passConfirm ? 'Las contraseñas coinciden' : 'Las contraseñas no coinciden'}
                      </li>
                    </div>
                  )}
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </Col>
        <Col md={4} align="right" style={{ paddingTop: 20 }}>
          <img src={logo} alt="logo" className="img-fluid" />
        </Col>
        <Col md={8} align="right">
          <Button
            className="my-4"
            color="default"
            type="button"
            onClick={register}
            disabled={botonReg.disabled}
          >
            <small>
              <FontAwesomeIcon spin={botonReg.spin} icon={botonReg.icon} />{" "}
              {botonReg.text}
            </small>
          </Button>
        </Col>
        <Col md={12} align="center"> 
          <hr className="m-3"/>
          <p>
            <small>
            ¿Ya tienes una cuenta?{" "}
            </small>
            <strong>
            <NavLink
            className="mb-2"
            onClick={signIn}
            disabled={botonLog.disabled}
            style={{ cursor: botonLog.disabled ? "not-allowed" : "pointer" }}
          >
              <FontAwesomeIcon spin={botonLog.spin} icon={botonLog.icon} />{" "}
              {botonLog.text}
          </NavLink>
          </strong>
          </p>
        </Col>
        <ErrorsModal
          isOpen={openModal}
          close={() => {
            setOpenModal(false);
          }}
          errores={errors}
        />
      </Row>
    </GoogleReCaptchaProvider>
  );
};

export default RegistModal;
