import React, { useEffect, useState } from 'react';

import { Modal } from "reactstrap";



const ErrorsModal = (props) => {
    const [open, setOpen] = useState(props.isOpen);

    useEffect(() => {
        setOpen(props.isOpen)
    }, [props]);

    return (
        <Modal className = "modal-dialog-centeredmodal modal-danger" contentClassName="bg-gradient-danger" size = "md" isOpen = {open} toggle = {() => props.close()}>
            <div className="modal-header">
                <h6 className="modal-title" id="modal-title-notification" style = {{color: "#fff"}}>
                    <i className = "fa fa-exclamation-triangle"></i> Se han encontrado <strong>{props.errores.length} errores</strong>
                </h6>
                <button aria-label = "Close" className = "close"  data-dismiss = "modal" type = "button" onClick = {() => props.close()}>
                <span aria-hidden = {true} style = {{color: "#fff"}}>×</span>
                </button>
            </div>
            <div className="modal-body" style = {{background: "#ffffff", color: "#636363"}}>
                {/* <div align = "center" className = "text-danger" style = {{fontSize: 48, marginBottom: 10}}><i className = "fa fa-exclamation-triangle"></i></div> */}
                {props.errores.map(option => (
                    <div key = {Math.random() * 394} style = {{padding: 5}}><i className = "fa fa-check-circle"></i> <span dangerouslySetInnerHTML = {{ __html: option }}></span></div>
                ))}
            </div>
        </Modal>
    )
}

export default ErrorsModal;