import React, {useEffect, useState} from "react";

import { Button } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faTimesCircle, faUsers, faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import ButacaCustom from "components/Butacas/ButacaCustom";
import ConfirmModal from "components/Modals/ConfirmModal";
import SuccessModal from "components/Modals/SuccessModal";

import { VerifyTicket } from "functions/TaquillaFunctions";

const DetalleTicket = (props) => {
    const [openModalCon, setOpenModalCon]   = useState(false)
    const [btnVerify, setBtnVerify]     = useState({icon: faCheck, spin: false, text: "Verificar", disabled: false})
    const [status, setStatus]           = useState(props.data.status_check)
    const [openModalOk, setOpenModalOk] = useState(false);
    const [modalOk, setModalOk]         = useState("");
    const [modalMess, setModalMess]     = useState("");

    useEffect(() => {
        setStatus(props.data.status_check)
    }, [props]);

    let butacaTipo = (idZona, color) => {
        let search = props.distribucions.find(option => option.id_zona === idZona);

        if (search === undefined) return "";

        switch (search.estilo){
            case "default": return (
                <>
                    <span className = "butaca-select" style = {{background: color}}></span> <span className = "butaca-name-select"></span>
                </>
            )
            case "cine1": return (
                <>
                    <ButacaCustom  clsName = "butaca-select-cine" tipo = "cine1" fontSize = {20} color = {color}/> <span className = "butaca-name-select"></span>
                </>
            )
            case "silla1": return (
                <>
                    <ButacaCustom clsName = "butaca-select-cine" tipo = "silla1" fontSize = {20} color = {color}/> <span className = "butaca-name-select"></span>
                </>
            )
            case "libre": return (
                <>
                    <FontAwesomeIcon icon = {faUsers} style = {{color: color, fontSize: 14}}/> <span className = "butaca-name-select"></span>
                </>
            )
            case "mesa": return (
                <>
                    <span className = "butaca-select" style = {{background: color}}></span> <span className = "butaca-name-select"></span>
                </>
            )
            default: return (
                <>
                    <span className = "butaca-select" style = {{background: color}}></span> <span className = "butaca-name-select"></span>
                </>
            )
        }
    }

    let statusCheck = (status) => {
        if (status === "0"){
            return (
                <div align = "center">
                    <FontAwesomeIcon icon = {faTimesCircle} className = "text-danger" size = "2x"/>
                    <div>No verificado</div>
                </div>
            )
        }else{
            return (
                <div align = "center">
                    <FontAwesomeIcon icon = {faCheckCircle} className = "text-success" size = "2x"/>
                    <div>Verificado</div>
                </div>
            )
        }
    }

    let verifyButton = (status) => {
        if (status === "1") return null;

        return (
            <Button size = "sm" onClick = {() => setOpenModalCon(true)} disabled = {btnVerify.disabled} color = "primary"><FontAwesomeIcon icon = {btnVerify.icon} spin = {btnVerify.spin}/> {btnVerify.text}</Button>
        )
    }

    let confirmButaca = (idTicketVendido) => {
        if (btnVerify.disabled === true) return null;

        setOpenModalCon(false)
        setBtnVerify({icon: faCircleNotch, spin: true, text: "Verificar", disabled: true})

        VerifyTicket(idTicketVendido, (resp) => {
            setBtnVerify({icon: faCheck, spin: false, text: "Verificar", disabled: false})

            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                break;
                default:
                    setStatus("1")
                    setOpenModalOk(true)
                    setModalOk(resp.title)
                    setModalMess(resp.mess)
                break;
            }
        })
    }

    let modalConfirm = () => {
        if (openModalCon === false) return null;
        
        return (
            <ConfirmModal
                isOpen = {openModalCon}
                content = {<small>¿Deseas <strong>PROCESAR</strong> el asiento <strong>{`${props.data.data_ticket.butaca_fila}${props.data.data_ticket.butaca_columna}`} - <span style = {{color: props.data.data_ticket.color}}>ZONA {props.data.data_ticket.nombre_zona}</span></strong> de la lista de entradas?<div><small style = {{fontWeight: "bold"}}><span className = "text-danger">IMPORTANTE:</span> Esta opción es definitiva y no se puede deshacer</small></div></small>}
                disabled = {false}
                buttonYes = {<><FontAwesomeIcon icon = {faCheck}/> Si</>}
                close = {() => setOpenModalCon(false)}
                funcion = {() => confirmButaca(props.data.id)}
            />
        )
    }

    let modalOkTicket = () => {
        if (openModalOk === false) return null;
        
        return(
            <SuccessModal isOpen = {openModalOk} close = {() => {
                setOpenModalOk(false)
            }} content = {modalMess} contentTitle = {modalOk}/>
        )
    }

    let ladoSilla = (string) => {
        let izquierda = string.indexOf("Izquierda");
        let superior = string.indexOf("Superior");
        let derecha = string.indexOf("Derecha");
        let inferior = string.indexOf("Inferior");

        switch (true){
            case (izquierda > 0): return "Izquierda";
            case (superior > 0): return "Superior";
            case (derecha > 0): return "Derecha";
            case (inferior > 0): return "Inferior";
            default: return "";
        }
    }

    let asientoName = (estilo) => {
        switch(estilo){
            case "mesa":
                return `Mesa: ${props.data.data_ticket.butaca_fila}${props.data.data_ticket.butaca_columna} - Silla: ${ladoSilla(props.data.data_ticket.codigo_silla)} ${props.data.data_ticket.num_silla}`
            case "libre":
                return <div>N/A</div>
            default:
                return `${props.data.data_ticket.butaca_fila}${props.data.data_ticket.butaca_columna}`
        }
    }

    return (
        <>
            <tr>
                <td>{butacaTipo(props.data.data_ticket.id_zona, props.data.data_ticket.color)} <strong style = {{color: props.data.data_ticket.color, paddingLeft: 10}}>{props.data.data_ticket.nombre_zona}</strong></td>
                <td align = "center"><strong>{asientoName(props.data.data_ticket.estilo)}</strong></td>
                <td>
                    <div className = "text-danger">{`N° ${props.data.id_factura}`}</div>
                    <div>{((props.data.ticket_token).substring(0, 20)).toUpperCase()}...</div>
                </td>
                <td>{statusCheck(status)}</td>
                <td>{verifyButton(status)}</td>
            </tr>
            {modalConfirm()}
            {modalOkTicket()}
        </>
    )
}

export default DetalleTicket;