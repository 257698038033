import React, { useEffect, useState } from "react";
import { Redirect } from 'react-router-dom';
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
// core components
import CardsFooter from "components/Footers/CardsFooter.js";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faCircleNotch, faCheckCircle, faEnvelope, faTimesCircle, faEye, faEyeSlash, faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';

import { LoginFunction, RestartPass } from "../../functions/UserFunctions";
import ErrorModal2 from "../../components/Modals/ErrorModal2";
import ContentModal from "components/Modals/ContentModal";
import logoColor from '../../assets/img/logos/logo_origin.png';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';


const Login = (props) => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [botonLog, setBotonLog] = useState({ icon: faSignInAlt, text: "Iniciar Sesión", spin: false, disabled: false })
  const [login, setLogin] = useState(false);
  const [register, setRegister] = useState(false);
  const [errors, setErrors] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalContent, setOpenModalContent] = useState(false);
  const [botonEmailR, setBotonEmailR] = useState({ icon: faEnvelope, text: "Enviar", spin: false, disabled: false })
  const [emailRec, setEmailRec] = useState("");
  const [viewPass, setViewPass] = useState({ icon: faEye, title: "Mostrar Contraseña", type: "password", active: true });
  const [imgHeader, setImgHeader] = useState(logoColor);
  const { executeRecaptcha } = useGoogleReCaptcha();



  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  let enterTab = (next) => (e) => { if (e.which === 13) document.getElementById(next).focus(); }

  let signIn = async (e) => {
    setBotonLog({ icon: faCircleNotch, text: "Comprobando Espera", spin: true, disabled: true })

    if (botonLog.disabled === true) return null;
    e.preventDefault();

    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha("homepage");

    let expReg = /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i;
    switch (true) {
      case (email.trim() === ""):
        setErrors("Debes indicar el <strong>Email</strong>")
        setOpenModal(true)
        setBotonLog({ icon: faSignInAlt, text: "Iniciar Sesión", spin: false, disabled: false })

        break;
      case (expReg.test(email) === false && email.trim() !== ""):
        setErrors("El campo <strong>Email</strong> tiene un formato incorrecto")
        setOpenModal(true)
        setBotonLog({ icon: faSignInAlt, text: "Iniciar Sesión", spin: false, disabled: false })

        break;
      case (pass.trim() === ""):
        setErrors("Debes indicar la <strong>Contraseña</strong>")
        setOpenModal(true)
        setBotonLog({ icon: faSignInAlt, text: "Iniciar Sesión", spin: false, disabled: false })

        break;
      default:
        LoginFunction(email, pass, token, (resp) => {
          setBotonLog({ icon: faSignInAlt, text: "Iniciar Sesión", spin: false, disabled: false })
          switch (resp.status) {
            case ("warning"):
              setErrors(resp.mess)
              setOpenModal(true)
              setBotonLog({ icon: faSignInAlt, text: "Iniciar Sesión", spin: false, disabled: false })
              break;
            case ('errorRecaptcha'):
              toast.error(resp.msg)
              break;
            default:
              toast.success(<div><FontAwesomeIcon icon={faCheckCircle} /> <span dangerouslySetInnerHTML={{ __html: resp.mess }}></span></div>);
              sessionStorage.removeItem("ContentData")
              let ContentData = {
                U_ID: resp.U_ID,
                E_ID: resp.E_ID,
                id_user: resp.id_user,
                U_NAME: resp.name,
                U_TYPE: resp.u_t,
                UT_NAME: resp.t_name
              }

              sessionStorage.setItem("ContentData", JSON.stringify(ContentData));
              if (login === false) setLogin(true)
              break;
          }
        })
        break;
    }
  }

  let modalContent = () => {
    let restartPass = () => {
      setBotonEmailR({ icon: faCircleNotch, text: "Enviando, espera", spin: true, disabled: true })
      let expReg = /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i;

      switch (true) {
        case (emailRec.trim() === ""):
          toast.error(<div><FontAwesomeIcon icon={faTimesCircle} /> Debes indcar el <strong>email</strong></div>);
          setBotonEmailR({ icon: faEnvelope, text: "Enviar", spin: false, disabled: false })
          break;
        case (expReg.test(emailRec) === false && emailRec.trim() !== ""):
          toast.error(<div><FontAwesomeIcon icon={faTimesCircle} /> El campo <strong>email</strong> tiene un formato incorrecto</div>);
          setBotonEmailR({ icon: faEnvelope, text: "Enviar", spin: false, disabled: false })
          break;
        default:
          RestartPass(emailRec, (resp) => {
            switch (resp.status) {
              case "error":
                toast.error(<div><FontAwesomeIcon icon={faTimesCircle} /> <span dangerouslySetInnerHTML={{ __html: resp.mess }}></span></div>);
                setBotonEmailR({ icon: faEnvelope, text: "Enviar", spin: false, disabled: false })
                break;
              default:
                setBotonEmailR({ icon: faEnvelope, text: "Enviar", spin: false, disabled: false })
                setOpenModalContent(false)
                setEmailRec("")
                toast.success(<div><FontAwesomeIcon icon={faCheckCircle} /> <span dangerouslySetInnerHTML={{ __html: resp.mess }}></span></div>);
                break;
            }
          })
          break;
      }
    }

    let contenido = () => (
      <Row>
        <Col md={12}>Esta opción te enviará un email para restaurar tu contraseña. <div><strong>¿Deseas Continuar?</strong></div></Col>
        <Col md={12} className="custom-mt-20">
          <div>
            <div style={{ marginBottom: 10 }}>Ingresa el email con el que te registraste</div>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-email-83" />
                </InputGroupText>
              </InputGroupAddon>
              <Input placeholder="Correo electrónico" type="email" value={emailRec} onChange={(e) => setEmailRec(e.target.value.toLowerCase())} />
            </InputGroup>
          </div>
        </Col>
        <Col md={12} align="right" className="custom-mt-20">
          <Button color="twitter" type="button" onClick={restartPass} disabled={botonEmailR.disabled} style={{ borderRadius: 15, paddingLeft: 40, paddingRight: 40 }}>
            {/* <FontAwesomeIcon icon = {botonEmailR.icon} spin = {botonEmailR.spin}/> */} {botonEmailR.text}
          </Button>
        </Col>
      </Row>
    )

    return (
      <ContentModal
        isOpen={openModalContent}
        title="Restaurar la contraseña"
        size="md"
        content={contenido()}
        close={() => setOpenModalContent(false)}
      />
    )
  }

  if (login === true) return (<Redirect to="/dashboard" />)
  let url = window.location.href
  let newUrl = url.replace('/login', '/register');

  // if (register === true) return window.location.href = newUrl //(<Redirect to = "/register"/>)

  let verPass = () => {
    (viewPass.active === true) ?
      setViewPass({ icon: faEyeSlash, title: "Ocultar Contraseña", type: "text", active: false })
      :
      setViewPass({ icon: faEye, title: "Mostrar Contraseña", type: "password", active: true })
  }

  return (
    <>
      <Row style={{ marginLeft: 0, marginRight: 0 }}>
        <Col md={7} style={{ background: "#ffffff", /* height: "100vh" */ }}>
          <Row>
            <Col md={12} className="padding-30">
              <Link to="/"><FontAwesomeIcon icon={faChevronCircleLeft} className="text-info" /> <strong className="text-info" style={{ fontSize: 11 }}>REGRESAR AL INICIO</strong></Link>
            </Col>
            <Col md={12} align="center">
              <img alt="..." src={imgHeader} height="auto" width="250px" />
            </Col>
            <Col md={12} align="center" className="padding-15">
              Iniciar Sesión en <strong className="text-info">TaquillaEnLínea</strong>
            </Col>
            <Col md={2}></Col>
            <Col md={8}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Correo electrónico" maxLength="40" type="email" value={email} onChange={(e) => setEmail(e.target.value.toLowerCase())} onKeyPress={enterTab("pass")} />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input id="pass" placeholder="Contraseña" type={viewPass.type} autoComplete="off" value={pass} onChange={(e) => setPass(e.target.value)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      title={viewPass.title}
                      onClick={verPass}
                      style={{ cursor: "pointer" }}
                    >
                      <FontAwesomeIcon icon={viewPass.icon} />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>

              <Col md={12} align="right">
                <small className="text-info" onClick={() => { setOpenModalContent(true) }} style={{ cursor: "pointer" }}><strong>¿Olvidaste tu contraseña?</strong></small>
              </Col>
            </Col>

            <Col md={2}></Col>
          </Row>
          <Col md={12} align="center">
            <Button className="my-4" color="twitter" type="button" onClick={signIn} disabled={botonLog.disabled} style={{ borderRadius: 15 }}>
              <small><FontAwesomeIcon spin={botonLog.spin} icon={botonLog.icon} /> {botonLog.text}</small>
            </Button>
          </Col>
        </Col>
        <Col md={5} style={{ paddingRight: 0, paddingLeft: 0, color: "#ffffff", backgroundColor: "#d1860c" }} align="center" className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
          <div className="img-login" style={{ height: "100vh", paddingTop: "36vh" }}>
            <div style={{ fontSize: 40 }} className="custom-mt-20"><strong>¡Hola, amigo!</strong></div>
            <div style={{ fontSize: 15 }} className="custom-mt-20">Ingresa tus datos personales y comienza el viaje con nosotros</div>
            <Button color="link" className="custom-mt-20" style={{ color: "#ffffff", border: "1px solid #ffffff", borderRadius: 15, paddingLeft: 40, paddingRight: 40 }} type="button" onClick={() => { window.location.href = newUrl }}>
              <small><strong>Regístrate</strong></small>
            </Button>
          </div>
        </Col>
      </Row>
      <CardsFooter />
      <ErrorModal2 isOpen={openModal} close={() => { setOpenModal(false) }} mess={errors} />
      {modalContent()}
    </>
  )
}

export default Login;
