import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import StepWizard from "react-step-wizard";
import Select from 'react-select';
import ImageMapper from 'react-image-mapper';
import Cards from 'react-credit-cards';
import Compressor from 'compressorjs';

import { Container, Button, Card, CardBody, CardFooter, CardTitle, Row, Col, Table, Input } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClock, faFrown, faTags, faUsers, faChevronCircleLeft, faChevronCircleRight, faMoneyCheck, faCaretSquareRight, faCircleNotch,
         faExclamationTriangle, faCircle,faTimesCircle, faTrash, faCreditCard, faHome, faCheck, faExclamationCircle, faBuilding, faMoneyBill, faFileInvoiceDollar, faMobile, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-credit-cards/es/styles-compiled.css'

import NavbarI from "components/Navbars/NavbarI.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import EventoDetallePreload from "components/Skeletons/EventoDetallePreload";
import Nav from "components/Navs/Nav";

import ConfirmModal from "components/Modals/ConfirmModal";
import ContentModal2 from "components/Modals/ContentModal2";
import ButacaDist from "components/Maps/ButacaDist";
import MesaDist from "components/Maps/MesaDist";
import ButacaCustom from "components/Butacas/ButacaCustom";
import EventoModal from "../Modals/EventoModal";


import { MenuDesplegablePagoMovil } from "components/Eventos/PagoMovil";

// jgil: para el pago en transferencia bancaria
import DetailsPayTransferBank from "components/Eventos/PayEvent/DetailsPayTransferBank";

// jgil: para el pago en multipago
import DetailsPayMultipayment from "components/Eventos/PayEvent/DetailsPayMultipayment ";


import {ChkSec} from '../../functions/UserFunctions';
import { UrlImg, UrlZones } from "../../functions/Url";
import { UrlBanesco } from "../../functions/Url";
import { formatBytes } from '../../functions/Generales';
import { SearchZoneEvent, SearchButacasInd, AddButacaInd, AddMesaInd, AddMesa, DelMesa, DelButacaInd, PayWithPayPal, PayTypes, formatCreditCardNumber, formatExpirationDate, formatCVC,
        PayWithCard, AddEntLibreInd, PayWithZelle, PayWithTransfer, PayWithMultiPay } from "functions/EventosFunctions";
import TimerTicket from "components/Timers/TimerTicket";
import CarritoCompra from "components/Carrito/CarritoCompra";
import Compartir from "components/Social/Compartir";

import logoPaypal from "assets/img/logos/paypal-logo.png";
import logoCreditCard from "assets/img/logos/credit_cards.png";
import logoBanesco from "assets/img/logos/banesco.png";
import logoZelle from "assets/img/logos/zelle.png";
import logoZelle2 from "assets/img/logos/zelle-2.png";
import Pago_Movil from "assets/img/logos/Pago_Movil.png";
import bank_transfer from "assets/img/logos/bank_transfer.svg";
import logoMultipago from "assets/img/icons/common/multipago.svg";
import logoCreditCard2 from "assets/img/logos/logo_credit_card.png";
import LogoEfectivo from "assets/img/logos/LogoEfectivo.png";





import { PayCash } from "functions/EventosFunctions";
import { DelButacasUser } from "functions/EventosFunctions";

import { useHistory } from 'react-router-dom';
import Impuesto from "./PayEvent/Impuesto";
import TotalT from "./PayEvent/TotalT";
import { UrlMain } from "functions/Url_serv";
import MultiPaymentTaquilla from "./PayEvent/MultiPaymentTaquilla";
import { PayWithMultiPayTaq } from "functions/EventosFunctions";
import ConfirmModal2 from "components/Modals/ConfirmModal2";


let cantMinutos = 15;
let cantSegundos = 0;

const PayEvento = (props) => {
    const [detalleEvento, setDetalleEvento] = useState(JSON.parse(sessionStorage.getItem("ContentDataEvent")));
    const [redirect, setRedirect]           = useState(false)
    const [ContentData, setContentData]     = useState(JSON.parse(sessionStorage.getItem("ContentData")))
    const [ContentDataEvent, setContentDataEvent]     = useState(JSON.parse(sessionStorage.getItem("ContentDataEvent")))
    const [urlE, setUrlE]                   = useState ("");
    const [butacas, setButacas]             = useState([])
    const [control, setControl]             = useState(false)
    const [controlCart, setControlCart] = useState(false)
    const [urlShare, setUrlShare]       = useState("https://www.taquillaenlinea.com/")

    const [transiciones, setTransiciones]   = useState({
        enterRight: "animated enterRight",
        enterLeft:  "animated enterLeft",
        exitRight:  "animated exitRight",
        exitLeft:   "animated exitLeft",
        intro:      "animated intro",
    })

    const history = useHistory();


    useEffect(() => {
      if (sessionStorage.getItem('reload') && history.location.pathname !== '/') {
        sessionStorage.removeItem('reload');
        sessionStorage.removeItem("ContentDataEvent")
        sessionStorage.removeItem("ContentDataButacas")
        sessionStorage.removeItem("ContentDataT")
        sessionStorage.removeItem("ContentDataCart")
        let url = window.location.href;
        let part = url.split("/");
        let urlEvent = part.pop();

        setControlCart(false);
        window.location.href = `${urlEvent}`
      }
  
      window.addEventListener('beforeunload', () => {
        sessionStorage.setItem('reload', true);
      });
    }, []);

    useEffect(() => {
        let url = window.location.href;
        let part = url.split("/");
        let urlEvent = part.pop();
        setUrlE(urlEvent);

        const urlSinComprar = url.replace('/comprar', '');
        setUrlShare(urlSinComprar);
        
        sessionStorage.setItem("ContentDataButacas", JSON.stringify([]));

        switch (true) {
            case (ContentDataEvent === null):
                toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span style = {{fontSize: 12}}>Ocurrió un error al cargar el evento</span></small>);
                setRedirect(true)
            break;
            case (ContentData === null):
                toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span style = {{fontSize: 12}}>Ocurrió un error al cargar el evento</span></small>);
                setRedirect(true)
            break;
            case (ContentData !== null && ContentData.U_ID !== undefined && ContentData.E_ID !== undefined):
                ChkSec (ContentData.U_ID, ContentData.E_ID, (resp) => {
                    if (resp.status === "error"){
                        sessionStorage.removeItem("ContentData")
                        sessionStorage.removeItem("ContentDataEvent")
                        sessionStorage.removeItem("ContentDataButacas")
                        sessionStorage.removeItem("ContentData")
                        sessionStorage.removeItem("ContentDataT")
                        setRedirect(true)
                    } else {
                        if (document.getElementById("content_event")) {

                            let detalle = JSON.parse(sessionStorage.getItem("ContentDataEvent"))

                            scroll("content_event")
                            setContentDataEvent(detalle)
                            setDetalleEvento(detalle)

                            if (!validateFechaAndStatus(detalle?.evento_info.status, detalle?.fecha)) {
                                history.push('/');
                            }
                        }

                    }
                })
            break;
            default:
                scroll("content_event")
                setContentDataEvent(JSON.parse(sessionStorage.getItem("ContentDataEvent")))
                setDetalleEvento(JSON.parse(sessionStorage.getItem("ContentDataEvent")))
            break;
        }
    }, [props]);

    let validateFechaAndStatus = (status, date) => {
        if (status === undefined  || date === undefined ) return false;
        
        const [day, month, year] = date.split('/');
        const providedDate = new Date(year, month - 1, day);

        // Obtener la fecha actual
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        if (status !== 4 || currentDate > providedDate) {
            return false
        }
        return true
    }

    let scroll = (id) => {
        window.scroll({
            top: (document.getElementById(id).offsetTop) + 60,
            behavior: 'smooth'
        });
    }

    let cambiaFormato = (fecha) => {
        let separa = fecha.split("/");
        return `${separa[1]}/${separa[0]}/${separa[2]}`
    }

    let dia = (date) => {
        let diaActual = new Date(cambiaFormato(date));
        let day = diaActual.getDate();

        if(day < 10) day = `0${day}`;

        let dias = [ 'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        let meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ]
        let month = meses[diaActual.getMonth()];

        return `${dias[diaActual.getDay()]} ${day} de ${month} de ${diaActual.getFullYear()}`;
    }

    let updButacas = () => {
        setButacas(JSON.parse(sessionStorage.getItem("ContentDataButacas")))
    }

    let contentEvento = () => {
        if(ContentDataEvent === null) {
            setRedirect(true)
            return null;
        }

        if(detalleEvento.length === 0) return <EventoDetallePreload />;

        return (
            <>
                <div className = "d-xs-block d-sm-none d-md-none d-lg-none d-xl-none">
                    <Card className="card-lift--hover shadow border-0" style = {{width: "30vw !important"}}>
                        <CardBody>
                            <div className= {`text-uppercase`} style = {{fontSize: 20}}>
                              <strong>{detalleEvento.evento_info.name_event}</strong>
                            </div>
                            <small className = "text-muted">{detalleEvento.recinto.address}</small>

                            <div className = "custom-mt-10"><small className = "text-muted"><FontAwesomeIcon icon = {faCalendarAlt} className = "text-info"/> {dia(detalleEvento.fecha)}</small></div>
                            <div><small className = "text-muted"><FontAwesomeIcon icon = {faClock} className = "text-info"/> {detalleEvento.hora_in} <strong>(Apertura {detalleEvento.hora_apertura})</strong></small></div>
                            <div><small className = "text-muted"><FontAwesomeIcon icon = {faTags} className = "text-info"/> {detalleEvento.evento_info.categorias_tags}</small></div>
                            <div><small className = "text-muted"><FontAwesomeIcon icon = {faUsers} className = "text-info"/> {detalleEvento.evento_info.artistas_tags}</small></div>
                            
                            <div className = "custom-mt-10" style = {{fontSize: 11}}><strong>Comparte el evento en tus redes sociales</strong></div>
                            <Compartir url = {urlShare} size = {30}/>

                            <Row>
                                <Col md = {8} sm = {8} xs = {8}>
                                    <TimerTicket control = {control}/>
                                </Col>
                                <Col md = {4} sm = {4} xs = {4} align = "center">
                                    {/* <CarritoCompra  control = {controlCart}/> */}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
                <div className = "header-banner d-none d-sm-block d-md-block d-lg-block d-xl-block" style={{width:"85vw", height:"60vh", display:"flex", justifyContent: "center",alignItems: "center"}}>
                    <img alt = "..." className = "img-fluid" src = {`${UrlImg}events/${detalleEvento.evento_info.image}`} />
                </div>
                <div className = "banner-button_2 d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    <Card className="card-lift--hover shadow border-0 opacity-custom" style = {{width: "30vw !important", marginBottom:"-10vh"}}>
                        <CardBody className="py-5">
                            <div className= {`text-uppercase`} style = {{fontSize: 20}}>
                              <strong>{detalleEvento.evento_info.name_event}</strong>
                            </div>
                            <small className = "text-muted">{detalleEvento.recinto.address}</small>

                            <div className = "custom-mt-10"><small className = "text-muted"><FontAwesomeIcon icon = {faCalendarAlt} className = "text-info"/> {dia(detalleEvento.fecha)}</small></div>
                            <div><small className = "text-muted"><FontAwesomeIcon icon = {faClock} className = "text-info"/> {detalleEvento.hora_in} <strong>(Apertura {detalleEvento.hora_apertura})</strong></small></div>
                            <div><small className = "text-muted"><FontAwesomeIcon icon = {faTags} className = "text-info"/> {detalleEvento.evento_info.categorias_tags}</small></div>
                            <div><small className = "text-muted"><FontAwesomeIcon icon = {faUsers} className = "text-info"/> {detalleEvento.evento_info.artistas_tags}</small></div>
                            <div><small className = "text-muted"><FontAwesomeIcon icon = {faBuilding} className = "text-info"/> {detalleEvento.recinto.name}</small></div>

                            <div className = "custom-mt-10" style = {{fontSize: 11}}><strong>Comparte el evento en tus redes sociales</strong></div>
                            <Compartir url = {urlShare} size = {30}/>

                            <Row>
                                <Col md = {8} sm = {8} xs = {8}>
                                    <TimerTicket control = {control}/>
                                </Col>
                                <Col md = {4} sm = {4} xs = {4} align = "center">
                                    <CarritoCompra  control = {controlCart}/>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </>
        );
    }
    
    let contentEvento2 = () => {
        if(ContentDataEvent === null) {
            setRedirect(true)
            return null;
        }

        if(detalleEvento.length === 0) return <EventoDetallePreload />;

        return (
            <Row>
                <Col md = {12} className = "container-content">
                    <StepWizard transitions = {transiciones} nav = {<Nav />}>
                        <SelectZona idRecinto = {detalleEvento.id_recinto} butacas = {updButacas}/> 
                        <SelectAsiento butacas = {butacas} updControl = {() => setControl(!control)} updControlCart = {() => setControlCart(!controlCart)} updButacas={updButacas}/>
                        <SelectPayMet />
                    </StepWizard>
                </Col>
            </Row>
        )
    }
    
    if (redirect === true) return <Redirect to = {`/event/${urlE}`}/>;

    return (
        <>
            <NavbarI />
            <section className="section pb-0 section-components custom-height" style = {{backgroundColor: "#f9f9f9"}}>
                <div className="py-5">
                    <Container className = "custom-margin">
                        {contentEvento()}
                    </Container>
                    <div id = "content_event" style={{marginBottom:'5vh'}}>
                        {contentEvento2()}
                    </div>
                </div>
            </section>
            <footer display= {{marginTop:'15vh'}}>
                <CardsFooter />
            </footer>
        </>
    )
}

export default PayEvento;

const Stats = ({nextStep, previousStep, totalSteps, step, firstStep, tipo, disabled, mensajeDis, idZona, butacas, metodoSelect, zonaSel, cambiaZonaSel, contentCard, monedaSelect, disPay, contentZelle, contentTransfer, contentMultipagos, ContentMultiPayTaq, contentEfectivo, props, func }) => {
    const [redirect, setRedirect]   = useState(false)
    const [control, setControl]     = useState(false)
    const [botonNext, setBotonNext] = useState({ icon: faChevronCircleRight, spin: false, disabled: false })
    const [botonFin, setBotonFin]   = useState({ icon: faMoneyCheck, spin: false, disabled: false })
    const [botonYes, setBotonYes]   = useState({ icon: faCheck, text: "Si", spin: false, disabled: false })
    const [preloader, setPreloader] = useState(false);
    const [disBtnPay, setDisBtnPay] = useState(false);
    const [preloaderS, setPreloaderS]       = useState(false);
    const [openModalCon, setOpenModalCon]   = useState(false);
    const [openModalConCash, setOpenModalConCash]   = useState(false);
    const [openModalBanescoPagos, setOpenModalBanescoPagos] = useState(false);
    const [openModalPagoMovil, setopenModalPagoMovil]     = useState(false);
    const [openModalSaldo, setopenModalSaldo]     = useState(false);
    const [tipoPago, setTipoPago] = useState('');
    
    useEffect(() => {
        if (zonaSel !== undefined) setControl(zonaSel)
    }, [zonaSel]);

    useEffect(() => {
        setDisBtnPay(!disPay)
    }, [disPay]);
    
    let cambio = (paso) => {
        paso()
    }

    let funcionSelect = () => {
        switch (tipo){
            case ("SelectZona"):
                let ContentDataEvent = JSON.parse(sessionStorage.getItem("ContentDataEvent"));
                let ContentData = JSON.parse(sessionStorage.getItem("ContentDataPrecioZona"));

                if (ContentDataEvent === null){
                    toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span style = {{fontSize: 12}}>Ocurrió un error al cargar el evento</span></small>);
                    setRedirect(true)
                }else{
                    setBotonNext({ icon: faCircleNotch, spin: true, disabled: true })
                    setControl(false)
                    if (cambiaZonaSel !== undefined && cambiaZonaSel !== ""){
                        cambiaZonaSel(false);
                    }

                    setPreloaderS(true)
                    sessionStorage.removeItem("ContentDataButacas")
                    SearchButacasInd(ContentDataEvent.id_recinto, idZona, ContentData.id_funcion, ContentData.nombre_zona, ContentDataEvent.id_evento, (resp) => {
                        sessionStorage.setItem("ContentDataButacas", JSON.stringify(resp));
                        butacas();
                        setBotonNext({ icon: faChevronCircleRight, spin: false, disabled: false })
                        setControl(false)
                        setPreloaderS(false)
                        cambio(nextStep)
                    })
                }
            break;
            case ("SelectAsiento"):
                cambio(nextStep)
            break;
            default: return null;
        }
    }

    if (zonaSel !== undefined){
        if(control === true && zonaSel === true){
            funcionSelect()
        }
    }

    let payCreditCard = () => {
        setBotonFin({ icon: faCircleNotch, spin: true, disabled: true })
        setBotonYes({ icon: faCircleNotch, text: "Si", spin: true, disabled: true })
        setBotonNext({ icon: faChevronCircleRight, spin: false, disabled: true })
        setDisBtnPay(true)

        let ContentDataEvent = JSON.parse(sessionStorage.getItem("ContentDataEvent"));
    
        let url = window.location.href;
        let part = url.split("/");
        let urlEvent = part.pop();
        let urlReturn = `/event/compra_credit_card/${urlEvent}`;

        PayWithCard(monedaSelect, urlReturn, ContentDataEvent.id_evento, contentCard, ContentDataEvent.fecha, ContentDataEvent.hora_in,(resp) => {
            setBotonFin({ icon: faMoneyCheck, spin: false, disabled: false });
            setBotonYes({ icon: faCheck, text: "Si", spin: false, disabled: false });
            setBotonNext({ icon: faChevronCircleRight, spin: false, disabled: false })
            setOpenModalCon(false)
            setDisBtnPay(false)

            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                break;
                case (resp.status === "pay_warn"):
                    toast.warn(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                break;
                // Pago en BS
                case(resp.status == "ok"):
                    setPreloader(true);
                    /* Detener Cronometro */
                    let Content = {
                        D001: null,
                        D002: null
                    }
                    sessionStorage.setItem("ContentDataT", JSON.stringify(Content));
                    sessionStorage.removeItem("ContentDataT")
                    sessionStorage.removeItem("ContentDataButacas")
                    sessionStorage.removeItem("ContentDataPrecioZona")
                    sessionStorage.removeItem("ContentResp")
                    setControl(!control)

                    let ContentResp = {
                        dataPago: resp.dataPago,
                        infoTickets: resp.infoTickets,
                        detallesPago: resp.detallesPago,
                        config: resp.config
                    }
                    sessionStorage.setItem("ContentResp", JSON.stringify(ContentResp));
                    window.location.href = urlReturn;
                break;
                // Pago en $$$
                default:
                    setPreloader(true);
                    /* Detener Cronometro */
                    Content = {
                        D001: null,
                        D002: null
                    }
                    sessionStorage.setItem("ContentDataT", JSON.stringify(Content));
                    sessionStorage.removeItem("ContentDataT")
                    sessionStorage.removeItem("ContentDataButacas")
                    sessionStorage.removeItem("ContentDataPrecioZona")
                    sessionStorage.removeItem("ContentResp")
                    setControl(!control)

                    ContentResp = {
                        dataPago: resp.dataPago,
                        infoTickets: resp.infoTickets,
                        detallesPago: resp.detallesPago,
                        config: resp.config
                    }
                   
                        sessionStorage.setItem("ContentResp", JSON.stringify(ContentResp));
                        window.location.href = resp.link;
                break;
            }
        });
    }

    let payWithCash = ()=>{
        setBotonFin({ icon: faCircleNotch, spin: true, disabled: true })
        setBotonYes({ icon: faCircleNotch, text: "Si", spin: true, disabled: true })
        setBotonNext({ icon: faChevronCircleRight, spin: false, disabled: true })
        setDisBtnPay(true)

        let ContentDataEvent = JSON.parse(sessionStorage.getItem("ContentDataEvent"));
        

        let url = window.location.href;
        let part = url.split("/");
        let urlEvent = part.pop();
        let urlReturn = `/event/compra_cash/${urlEvent}`;

        PayCash(monedaSelect, urlReturn, ContentDataEvent.id_evento, contentEfectivo, ContentDataEvent.fecha, ContentDataEvent.hora_in,(resp) => {
            setBotonFin({ icon: faMoneyCheck, spin: false, disabled: false });
            setBotonYes({ icon: faCheck, text: "Si", spin: false, disabled: false });
            setBotonNext({ icon: faChevronCircleRight, spin: false, disabled: false })
            setOpenModalCon(false)
            setDisBtnPay(false)

            switch (true) {
                case (resp.status === "error"):
                    resp.errors.forEach(error => {
                        toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: error }} style = {{fontSize: 12}}></span></small>);
                    });
                break;
                case (resp.status === "pay_warn"):
                    toast.warn(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.errors }} style = {{fontSize: 12}}></span></small>);
                break;
                // Pago en $$$
                default:
                    setPreloader(true);
                    /* Detener Cronometro */
                    let Content = {
                        D001: null,
                        D002: null
                    }
                    sessionStorage.setItem("ContentDataT", JSON.stringify(Content));
                    sessionStorage.removeItem("ContentDataT")
                    sessionStorage.removeItem("ContentDataButacas")
                    sessionStorage.removeItem("ContentDataPrecioZona")
                    sessionStorage.removeItem("ContentResp")
                    setControl(!control)

                    let ContentResp = {
                        dataPago: resp.dataPago,
                        infoTickets: resp.infoTickets,
                        detallesPago: resp.detallesPago,
                        config: resp.config,
                        data_client: resp.data_client
                    }
                    sessionStorage.setItem("ContentResp", JSON.stringify(ContentResp));
                    window.location.href = urlReturn;
                break;
            }
        });

    }

    let payWithMultiPay = () => {
        setBotonFin({ icon: faCircleNotch, spin: true, disabled: true })
        setBotonYes({ icon: faCircleNotch, text: "Si", spin: true, disabled: true })
        setBotonNext({ icon: faChevronCircleRight, spin: false, disabled: true })
        setDisBtnPay(true)
        let contentDataEvent = JSON.parse(sessionStorage.getItem("ContentDataEvent"));


        let url = window.location.href;
        let part = url.split("/");
        let urlEvent = part.pop();
        let urlReturn = `/event/compra_multipago/${urlEvent}`;

        PayWithMultiPayTaq(ContentMultiPayTaq.metodos, ContentMultiPayTaq.datosComprador, ContentMultiPayTaq.saldoAFavor, contentDataEvent.id_evento,
            contentDataEvent.fecha, contentDataEvent.hora_in, monedaSelect, urlReturn, (resp) => {
                setBotonFin({ icon: faMoneyCheck, spin: false, disabled: false })
                setDisBtnPay(false)
                
                switch (true) {
                    case (resp.status === "error" || resp.status === "error_log"):
                        toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                    break;
                    case (resp.status === "pay_warn"):
                        toast.warn(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                    break;
                    default:
                        setBotonFin({ icon: faMoneyCheck, spin: false, disabled: false })
                        setDisBtnPay(false)
                        /* Detener Cronometro */
                        let Content = {
                            D001: null,
                            D002: null
                        }
                        sessionStorage.setItem("ContentDataT", JSON.stringify(Content));
                        sessionStorage.removeItem("ContentDataT")
                        sessionStorage.removeItem("ContentDataButacas")
                        sessionStorage.removeItem("ContentDataPrecioZona")
                        sessionStorage.removeItem("ContentResp")
                        setControl(!control);
    
                        let ContentResp = {
                            dataPago: resp.dataPago,
                            infoTickets: resp.infoTickets,
                            detallesPago: resp.detallesPago,
                            config: resp.config
                        }
    
                        sessionStorage.setItem("ContentResp", JSON.stringify(ContentResp));
                        window.location.href = resp.link;
                    break;
                }
            })
    }

    let modalConfirm = () => {
        if (openModalCon === false) return null;
        return (
            <ConfirmModal
                isOpen = {openModalCon}
                content = {<small>Al presionar <strong className = "text-danger">Si</strong> aceptas pagar con la tarjeta de crédito ingresada. ¿Deseas continuar?</small>}
                disabled = {botonYes.disabled}
                buttonYes = {<><FontAwesomeIcon icon = {botonYes.icon} spin = {botonYes.spin}/> {botonYes.text}</>}
                close = {() => setOpenModalCon(false)}
                funcion = {payCreditCard}
            />
        )
    }


    let contentPayPagoMovil = (
        <MenuDesplegablePagoMovil props = {tipoPago} />
    )

        

        

    let modalConfirmCash = () => {
        if (openModalConCash === false) return null;
        const user = JSON.parse(sessionStorage.getItem('ContentData'))

        let ContentDataEvent = JSON.parse(sessionStorage.getItem("ContentDataEvent"));


        const utype = user.U_TYPE;

        if(utype === 4){
            return (
                <ConfirmModal
                    isOpen = {openModalConCash}
                    content = {<small>Al presionar <strong className = "text-danger">Si</strong> aceptas pagar con efectivo en taquilla. ¿Deseas continuar?</small>}
                    disabled = {botonYes.disabled}
                    buttonYes = {<><FontAwesomeIcon icon = {botonYes.icon} spin = {botonYes.spin}/> {botonYes.text}</>}
                    close = {() => setOpenModalConCash(false)}
                    funcion = {payWithCash}
                />
            )
        }else{
            let text = ContentDataEvent.evento_info.taquilla_fisica;
            return (
                <ConfirmModal
                    isOpen = {openModalConCash}
                    content = {(!text || text === null) ? <small>Para realizar pagos en efectivo debe dirigirse a la taquilla el día del evento</small> : text}
                    disabled = {botonYes.disabled}
                    buttonYes = {<><FontAwesomeIcon icon = {botonYes.icon} spin = {botonYes.spin}/> {botonYes.text}</>}
                    close = {() => setOpenModalConCash(false)}
                    funcion = {() => setOpenModalConCash(false)}
                />
            )
        }

    }

    let modalBanescoPagos = () => {
        if (openModalBanescoPagos === false) return null;

        let contenido = (
            <div className = "alert alert-light" style = {{marginTop: 15, color: "#000"}}>
                <Row>
                    <Col md = {12}>
                        <strong style = {{fontSize: 14}}><strong>Datos del cliente</strong></strong>
                    </Col>
                    <Col md = {12}>
                        <small>Cédula de identidad</small>
                        <Input type = "number" placeholder = "* Cédula"/>
                    </Col>
                    <Col md = {12}>
                        <small>Descripción</small>
                        <Input type = "text" maxLength = "60" placeholder = "* Descripción del pago"/>
                    </Col>
                    <Col md = {12} align = "center" style = {{marginTop: 20}}>
                        <small><strong>Haz en el botón para pagar con Banesco</strong></small>
                        <embed src = {UrlBanesco} title = "Pagar con Banesco Pago" style = {{alignItems: "center"}} align = "center" width = "100%" height = "55px"></embed>
                    </Col>
                    <Col md = {12} align = "left">
                        <small><strong>* Campos obligatorios</strong></small>
                    </Col>
                </Row>
            </div>
        )

        return (
            <ContentModal2
                isOpen = {openModalBanescoPagos}
                title = "Pagar con Banesco"
                size = "md"
                content = {contenido}
                close = {() => setOpenModalBanescoPagos(false)}
            />
        )
    }

    let btnFinal = () => {
        let pagar = () => {
            if (botonFin.disabled === true) return null;
            
            if (disPay === false) {
                toast.error(<small><span style = {{fontSize: 12}}>Debes aceptar primero las <strong>condiciones del evento</strong></span></small>);
                return null;
            }
            switch(metodoSelect){                
                case "":
                    toast.error(<small><span style = {{fontSize: 12}}>Debes seleccionar primero un <strong>método de pago</strong></span></small>);
                break;
                case "Paypal":
                    setBotonFin({ icon: faCircleNotch, spin: true, disabled: true })
                    setDisBtnPay(true)

                    let ContentDataEvent = JSON.parse(sessionStorage.getItem("ContentDataEvent"));
                 
                    let url = window.location.href;
                    let part = url.split("/");
                    let urlEvent = part.pop();
                    let urlReturn = `/event/confirm_compra_paypal/${urlEvent}`;
                    
                    PayWithPayPal(monedaSelect, urlReturn, ContentDataEvent.id_evento, (resp) => {
                        setPreloader(true);

                        switch (true) {
                            case (resp.status === "error" || resp.status === "error_log"):
                                toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                                setRedirect(true)
                            break;
                            default:
                                sessionStorage.removeItem("ContentResp");
                                window.location.href = resp.link;
                            break;
                        }
                    })
                break;
                case "Banesco Pagos":
                    setOpenModalBanescoPagos(true);
                break;
                case "Zelle":
                    switch(true){
                        case (contentZelle.transaccionZelle === ""):
                            toast.error(<small><FontAwesomeIcon icon = {faFrown}/> El campo <strong>N° de la transacción</strong> no debe estar vacío</small>);
                        break;
                        case (contentZelle.nombreZelle === ""):
                            toast.error(<small><FontAwesomeIcon icon = {faFrown}/> El campo <strong>nombre del titular Zelle</strong> no debe estar vacío</small>);
                        break;
                        case (contentZelle.emailZelle === ""):
                            toast.error(<small><FontAwesomeIcon icon = {faFrown}/> El campo <strong>correo del titular Zelle</strong> no debe estar vacío</small>);
                        break;
                        default:
                            let file = document.getElementById(contentZelle.fileZelle);
                            let archivo = file.value;
                            if (archivo === ""){
                                toast.error("No has seleccionado ningún archivo",{autoClose: 4000});
                            }else{
                                setBotonFin({ icon: faCircleNotch, spin: true, disabled: true })
                                setDisBtnPay(true)

                                let ContentDataEvent = JSON.parse(sessionStorage.getItem("ContentDataEvent"));

                                let url = window.location.href;
                                let part = url.split("/");
                                let urlEvent = part.pop();
                                let urlReturn = `/event/compra_zelle/${urlEvent}`;

                                PayWithZelle(contentZelle.transaccionZelle, contentZelle.nombreZelle, contentZelle.emailZelle, contentZelle.fileZelleSrc, document.getElementById(contentZelle.fileZelle).value, ContentDataEvent.id_evento,
                                ContentDataEvent.fecha, ContentDataEvent.hora_in, monedaSelect, urlReturn, (resp) => {
                                    switch (true) {
                                        case (resp.status === "error" || resp.status === "error_log"):
                                            toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                                        break;
                                        case (resp.status === "pay_warn"):
                                            toast.warn(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                                        break;
                                        default:
                                            setBotonFin({ icon: faMoneyCheck, spin: false, disabled: false })
                                            setDisBtnPay(false)
                                            /* Detener Cronometro */
                                            let Content = {
                                                D001: null,
                                                D002: null
                                            }
                                            sessionStorage.setItem("ContentDataT", JSON.stringify(Content));
                                            sessionStorage.removeItem("ContentDataT")
                                            sessionStorage.removeItem("ContentDataButacas")
                                            sessionStorage.removeItem("ContentDataPrecioZona")
                                            sessionStorage.removeItem("ContentResp")
                                            setControl(!control);
                        
                                            let ContentResp = {
                                                dataPago: resp.dataPago,
                                                infoTickets: resp.infoTickets,
                                                detallesPago: resp.detallesPago,
                                                config: resp.config
                                            }
                        
                                            sessionStorage.setItem("ContentResp", JSON.stringify(ContentResp));
                                            window.location.href = resp.link;
                                        break;
                                    }
                                })
                            }
                        break;
                    }
                break;
                case "Tarjeta de Crédito":
                    switch(true){
                        case (contentCard.data4 === ""):
                            toast.error(<small><FontAwesomeIcon icon = {faFrown}/> El campo <strong>número de la tarjeta</strong> no debe estar vacío</small>);
                        break;
                        case (contentCard.data3 === ""):
                            toast.error(<small><FontAwesomeIcon icon = {faFrown}/> El campo <strong>titular</strong> no debe estar vacío</small>);
                        break;
                        case (contentCard.data2 === ""):
                            toast.error(<small><FontAwesomeIcon icon = {faFrown}/> El campo <strong>válido hasta</strong> no debe estar vacío</small>);
                        break;
                        case (contentCard.data1 === ""):
                            toast.error(<small><FontAwesomeIcon icon = {faFrown}/> El campo <strong>CVC</strong> no debe estar vacío</small>);
                        break;
                        default:
                            setOpenModalCon(true)
                        break;
                    }
                break;
                case "Efectivo":
                    setOpenModalConCash(true)
                break;
                case "Transferencia bancaria":
                    switch(true){
                        case (contentTransfer.numberTransfer === ""):
                            toast.error(<small><FontAwesomeIcon icon = {faFrown}/> El campo <strong>N° de la transacción</strong> no debe estar vacío</small>);
                        break;
                        default:
                            let file = document.getElementById(contentTransfer.fileTransfer);
                            let archivo = file.value;
                            if (archivo === ""){
                                toast.error("No has seleccionado ningún archivo",{autoClose: 4000});
                            }else{
                                setBotonFin({ icon: faCircleNotch, spin: true, disabled: true })
                                setDisBtnPay(true)

                                let ContentDataEvent = JSON.parse(sessionStorage.getItem("ContentDataEvent"));

                                let url = window.location.href;
                                let part = url.split("/");
                                let urlEvent = part.pop();
                                let urlReturn = `/event/compra_transfer/${urlEvent}`;

                                PayWithTransfer(contentTransfer.numberTransfer, contentTransfer.fileTransferJg, document.getElementById(contentTransfer.fileTransfer).value, ContentDataEvent.id_evento,
                                ContentDataEvent.fecha, ContentDataEvent.hora_in, monedaSelect, urlReturn, (resp) => {
                                    switch (true) {
                                        case (resp.status === "error" || resp.status === "error_log"):
                                            toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                                        break;
                                        case (resp.status === "pay_warn"):
                                            toast.warn(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                                        break;
                                        default:
                                            setBotonFin({ icon: faMoneyCheck, spin: false, disabled: false })
                                            setDisBtnPay(false)
                                            /* Detener Cronometro */
                                            let Content = {
                                                D001: null,
                                                D002: null
                                            }
                                            sessionStorage.setItem("ContentDataT", JSON.stringify(Content));
                                            sessionStorage.removeItem("ContentDataT")
                                            sessionStorage.removeItem("ContentDataButacas")
                                            sessionStorage.removeItem("ContentDataPrecioZona")
                                            sessionStorage.removeItem("ContentResp")
                                            setControl(!control);
                        
                                            let ContentResp = {
                                                dataPago: resp.dataPago,
                                                infoTickets: resp.infoTickets,
                                                detallesPago: resp.detallesPago,
                                                config: resp.config
                                            }
                        
                                            sessionStorage.setItem("ContentResp", JSON.stringify(ContentResp));
                                            window.location.href = resp.link;
                                        break;
                                    }
                                })
                            }
                        break;
                    }
                break;
                case "Pago móvil":
                    setopenModalPagoMovil(true);
                    setTipoPago("P2C");
                break;
                case "Pago móvil C2P":
                    setopenModalPagoMovil(true);
                    setTipoPago("C2P");
                break;
                case "Multipagos":
                    // let proceedWithPayment = true;
                    let contentMoneda = JSON.parse(sessionStorage.getItem("ContentDataCurrency"))
                    let tasa = contentMoneda.valor_1usd_moneda_local;
                    let total = ContentMultiPayTaq.metodos.reduce((sum, metodo) => {
                        let monto = parseFloat(metodo.amount);
                        if (metodo.moneda === 'Bs.') {
                          monto /= tasa;
                        }
                        return sum + monto;
                      }, 0);

                    // if(u_type === 4 || u_type === "4"){
                        const isAmountForEachMethod = ContentMultiPayTaq.metodos.filter(transaccion => transaccion.amount === 0);
                        if(!ContentMultiPayTaq.datosComprador.nombre || !ContentMultiPayTaq.datosComprador.correo){
                            toast.error("Los datos del cliente no deben estar vacios.")
                        }else if (ContentMultiPayTaq.metodos.length === 0) {
                            toast.error("Debes escoger al menos un método de pago para continuar")
                        }else if(isAmountForEachMethod.length > 0) {
                            isAmountForEachMethod.forEach(error => {
                                toast.error(`El monto del método : ${error.method} no puede estar en 0.`)
                            });
                        }                  
                        else{ 
                            if(parseFloat(total).toFixed(2) < parseFloat(ContentMultiPayTaq.monto.replace(',', '.'))){
                                toast.error("El monto pagado no puede ser menor al monto a pagar.")
                            }
                            else{
                                console.log(ContentMultiPayTaq.saldoAFavor)
                                if (ContentMultiPayTaq.saldoAFavor.usd < 0 || ContentMultiPayTaq.saldoAFavor.bs < 0 ){
                                    setBotonFin({ icon: faMoneyCheck, spin: false, disabled: false })
                                    setDisBtnPay(false)
                                    setopenModalSaldo(true)
                                }else{
                                    payWithMultiPay()
                                }
                            }    
                        }


                    // }else{
                    //     switch(true){
                    //         // case (contentMultipagos.form.length < contentDataEvent.evento_info.notas):
                    //         case (contentMultipagos.form.length < parseInt(contentDataEvent.evento_info.notas)):
                    //             toast.error(<small><FontAwesomeIcon icon={faFrown} /> Es necesario ingresar la información en al menos <strong>{parseInt(contentDataEvent.evento_info.notas)} métodos pagos.</strong></small>);
                    //         break;
                    //         default:
                    //                 // Obtener los métodos seleccionados directamente de paymentFormData
                    //                 const selectedPaymentMethods = contentMultipagos.form.map(item => item.metodo);
                            
                    //                 // Verificar si hay una imagen por cada método de pago
                    //                 const isImageForEachMethod = selectedPaymentMethods.every(method => contentMultipagos.images.some(item => item.metodo === method && item.img));

                    //                 // Verificar si hay un monto por cada método de pago
                    //                 const isAmountForEachMethod = selectedPaymentMethods.every(method => contentMultipagos.form.some(item => item.metodo === method && item.monto));

                    //                 // Vefificar si hay un N transaccion
                    //                 const isTransaccionForEachMethod = selectedPaymentMethods.every(method => contentMultipagos.form.some(item => item.metodo === method && item.transaccion));
                                
                    //                 // Mostrar mensaje de error personalizado si no hay imagen para cada método
                    //                 // Validar imagen
                    //                 if (!isImageForEachMethod) {
                    //                     const missingMethods = selectedPaymentMethods.filter(method => !contentMultipagos.images.some(item => item.metodo === method && item.img));
                    //                     const missingMethodsMessage = missingMethods.map(method => `falta la imagen de ${method}`).join(', ');
                    //                     toast.error(<small><FontAwesomeIcon icon={faFrown} /> {missingMethodsMessage}</small>);
                    //                 }
                    //                 // Validar transaccion
                    //                 else if(!isTransaccionForEachMethod){
                    //                     const missingMethods = selectedPaymentMethods.filter(method => !contentMultipagos.form.some(item => item.metodo === method && item.transaccion));
                    //                     const missingMethodsMessage = missingMethods.map(method => `falta el l número de transacción de ${method}`).join(', ');
                    //                     toast.error(<small><FontAwesomeIcon icon={faFrown} /> {missingMethodsMessage}</small>);
                    //                 }
                    //                 // Validar monto
                    //                 else if(!isAmountForEachMethod){
                    //                     const missingMethods = selectedPaymentMethods.filter(method => !contentMultipagos.form.some(item => item.metodo === method && item.monto));
                    //                     const missingMethodsMessage = missingMethods.map(method => `falta el monto de ${method}`).join(', ');
                    //                     toast.error(<small><FontAwesomeIcon icon={faFrown} /> {missingMethodsMessage}</small>);
                    //                 }

                    //                 else {
                    //                 // METODO DE PAGO MULTIPAGOS
                    //                 setBotonFin({ icon: faCircleNotch, spin: true, disabled: true })
                    //                 setDisBtnPay(true)

                    //                 let url = window.location.href;
                    //                 let part = url.split("/");
                    //                 let urlEvent = part.pop();
                    //                 let urlReturn = `/event/compra_multipago/${urlEvent}`;

                    //                 PayWithMultiPay(contentMultipagos.form, contentMultipagos.images, contentDataEvent.id_evento,
                    //                 contentDataEvent.fecha, contentDataEvent.hora_in, monedaSelect, urlReturn, (resp) => {
                                        
                    //                     switch (true) {
                    //                         case (resp.status === "error" || resp.status === "error_log"):
                    //                             toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                    //                         break;
                    //                         case (resp.status === "pay_warn"):
                    //                             toast.warn(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                    //                         break;
                    //                         default:
                    //                             setBotonFin({ icon: faMoneyCheck, spin: false, disabled: false })
                    //                             setDisBtnPay(false)
                    //                             /* Detener Cronometro */
                    //                             let Content = {
                    //                                 D001: null,
                    //                                 D002: null
                    //                             }
                    //                             sessionStorage.setItem("ContentDataT", JSON.stringify(Content));
                    //                             sessionStorage.removeItem("ContentDataT")
                    //                             sessionStorage.removeItem("ContentDataButacas")
                    //                             sessionStorage.removeItem("ContentDataPrecioZona")
                    //                             sessionStorage.removeItem("ContentResp")
                    //                             setControl(!control);
                            
                    //                             let ContentResp = {
                    //                                 dataPago: resp.dataPago,
                    //                                 infoTickets: resp.infoTickets,
                    //                                 detallesPago: resp.detallesPago,
                    //                                 config: resp.config
                    //                             }
                            
                    //                             sessionStorage.setItem("ContentResp", JSON.stringify(ContentResp));
                    //                             window.location.href = resp.link;
                    //                         break;
                    //                     }
                    //                 })
                    //                 }
                    //         break;
                    //     }
                    // }

                break;
                default: return null;
            }            
        }

        return (
            <React.Fragment>
                <Button size = "md" color = "primary" onClick = {pagar} disabled = {disBtnPay} title = "Comprar Boletos" style = {{marginRight: 5}}><FontAwesomeIcon icon = {botonFin.icon} spin = {botonFin.spin}/> Confirmar Compra</Button>
                <EventoModal size = "lg" isOpen = {openModalPagoMovil} titulo = "Pagar con Pago movil" contenido = {contentPayPagoMovil} close = {() => {setopenModalPagoMovil(false)}}/>
                {(ContentMultiPayTaq.saldoAFavor && ContentMultiPayTaq.saldoAFavor.bs && ContentMultiPayTaq.saldoAFavor.usd) ?
                
                <ConfirmModal2 
                isOpen={openModalSaldo} 
  content={`Actualmente, posee un saldo a favor de ${Math.abs(ContentMultiPayTaq?.saldoAFavor?.bs || 0)}Bs.`} 
                cerrar = {()=> {setopenModalSaldo(false)}} close={()=> {setopenModalSaldo(false)}}
                buttonYes = {<><FontAwesomeIcon icon = {botonYes.icon} spin = {botonYes.spin}/> {botonYes.text}</>}
                funcion = {()=>{payWithMultiPay()}}
                disabled = {botonYes.disabled}
                /> : null}
            </React.Fragment>
            
        )
    }

    let preloadTicker = () => {
        if (preloader === true){
            return (
                <div className = "preload-ticket">
                    <div className = "preload-ticket-icon_card" align = "center" style = {{left: "42% !important"}}>
                        <FontAwesomeIcon icon = {faCircleNotch} size = "5x" spin = {true}/>
                        <div style = {{marginLeft: 0}}>Redireccionando, espera</div>
                    </div>  
                </div>
            )
        }else{
            return null;
        }
    }

    let preloadTickerS = () => {
        if (preloaderS === true){
            return (
                <div className = "preload-ticket">
                <div className = "preload-ticket-icon">
                    <FontAwesomeIcon icon = {faCircleNotch} size = "5x" spin = {true}/>
                    </div>
                </div>
            )
        }else{
            return null;
        }
    }


    const removeSession = (previousStep, step, func) => {
        // let content = JSON.parse(sessionStorage.getItem("ContentDataCart"));
        let dataT = sessionStorage.getItem("ContentDataT")
        if(dataT !== null && step === 2){
            sessionStorage.removeItem("ContentDataT");
            sessionStorage.removeItem("ContentDataCart");
            DelButacasUser(() => {
                sessionStorage.removeItem("ContentDataT");
                sessionStorage.removeItem("ContentDataCart");
                props.updControl();
                props.updControlCart();
            });
            previousStep();
        }else{
            previousStep();
        }
        // func()

    }

    let botones = (
        <React.Fragment>
            {preloadTicker()}
            {preloadTickerS()}
            { step > 1 &&
                <Button size = "md" color = "warning" onClick = {()=>{removeSession(previousStep, step, func)}} disabled = {botonFin.disabled} style = {{marginRight: 5}}><FontAwesomeIcon icon = {faChevronCircleLeft}/> Anterior</Button>
            }{ step === 1 &&
                <Link to = {`/`} className = "btn btn-warning" style = {{paddingLeft: 35, paddingRight: 35}}>
                    {/* <FontAwesomeIcon icon = {faArrowCircleLeft}/>  */}Volver
                </Link>
            }{ step < totalSteps ?
                <Button size = "md" color = "info" disabled = {botonNext.disabled}
                    onClick = {() => {
                        if (disabled === true){
                        toast.error(<small><FontAwesomeIcon icon = {faExclamationTriangle}/> <span dangerouslySetInnerHTML = {{ __html: mensajeDis }} style = {{fontSize: 12}}></span></small>);
                        /* return null; */
                        }else{
                        funcionSelect()
                        }
                    }}>Siguiente <FontAwesomeIcon icon = {botonNext.icon} spin = {botonNext.spin}/>
                </Button>
                :
                btnFinal()
            }
        </React.Fragment>
    )

    if (redirect === true) return <Redirect to = {`/`}/>;

    return(
        <>
            <Row>
                <Col md = {12} align = "right">
                    {botones}
                </Col>
            </Row>
            {modalConfirm()}
            {modalConfirmCash()}
            {modalBanescoPagos()}
        </>
    )
}

let auditBuildClient = "";
const SelectZona = (props) => {
    const [zona, setZona]                   = useState([]);
    const [zonasCat, setZonasCat]           = useState([]);
    const [detFuncion, setDetFuncion]       = useState(<React.Fragment>Selecciona una zona (Una vez seleccionados los asientos, tendrá 15 minutos para completar su compra)</React.Fragment>);
    const [precioFuncion, setPrecioFuncion] = useState("");
    const [leyenda, setLeyenda]             = useState(<h5 className = "text-muted"><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando Zonas</h5>);
    const [control, setControl]             = useState(false);
    const [detalleEvento, setDetalleEvento] = useState(JSON.parse(sessionStorage.getItem("ContentDataEvent")));
    const [zonaCursor, setZonaCursor]       = useState("")
    const [zonaId, setZonaId]               = useState("")
    const [disabled, setDisabled]           = useState(true)
    const [control2, setControl2]           = useState(false)
    const [zonaSel, setZonaSel]             = useState(false)
    const [zonaTipo, setZonaTipo]           = useState("");
    const [mapaA, setMapaA]                 = useState("");
    const [alto, setAlto] = useState('')
    const [ancho, setAncho] = useState('')

    useEffect(() => {
        let url = window.location.href;
        let part = url.split("/");
        let urlEvent = part.pop();
        let eventSplice = urlEvent.split("#");

        SearchZoneEvent(props.idRecinto, eventSplice[0], (resp) => {
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    /* toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>); */
                    window.location.href = "/";
                break;
                default:
                    setZonaTipo(resp.data_zona_audit.tipo)
                    setMapaA(resp.data_zona_audit.image)
                    let data = JSON.parse(resp.data_zona_audit.zonas_build)
    
                    if(resp.data_zona_audit.alto) {
                        setAlto(resp.data_zona_audit.alto)
                    }
                    if(resp.data_zona_audit.ancho) {
                        setAncho(resp.data_zona_audit.ancho)
                    }
                    setZona(data)
                break;
            }
        })
         
        setZonasCat(detalleEvento.precios)
    }, []);

    useEffect(() => {
        setControl(!control);

        let title = (nombre) => {
            if (nombre !== undefined) return nombre;
        }

        let zonaT = () => {
            
            let columnas = (data) => (
                data.map(option => {
                    if (option.zona === ""){
                        return (
                            <td key = {option.id} width = {10} height = {10} style = {{backgroundColor: option.color}}></td>
                        )
                    }else{
                        return (
                            <td key = {option.id} width = {10} height = {10} onClick = {() => selectZona(option)} onMouseOver = {() => cursorZona(option.zona.label)} title = {title(option.zona.label)} style = {{backgroundColor: option.color, cursor: "pointer"}}></td>
                        )
                    }

                })
            )

            return zona.map(fila => (
                <tr key = {`${fila.id}_${control}`}>{columnas(fila.columnas)}</tr>
            ))
        }

        switch(zonaTipo){
            case "cuadricula":
                auditBuildClient = <>
                    <div className = "table-responsive" align = "center" style = {{marginBottom: 5}}>
                    <table border = "0">
                        <tbody>
                            {zonaT()}
                        </tbody>
                    </table>
                    </div>
                </>
            break;  

            case "area":
                let width = '';
                const zonaMovil = zona;
                
                if(zona && window.innerWidth < 450){
                    width = 250
                    let common = width / ancho;

                    for (let area of zonaMovil.areas) {
                        for (let i = 0; i < area.coords.length; i++) {
                            if (i % 2 === 0) { // Solo para índices pares
                                area.coords[i] = parseFloat((area.coords[i]) * common, 2);
                            }else{
                                area.coords[i] = parseFloat((area.coords[i]) * common, 2);
                            }
                        }
                    }
                }
                auditBuildClient = <>
                <div style = {{overflow: "auto"}}>
                    <ImageMapper src = {`${UrlImg}maps/${mapaA}`} id = 'imgMap' map = { window.innerWidth < 450 ? zonaMovil : zona} onClick = {(mapa) => {selectZona(mapa)}} onMouseEnter = {(mapa) => cursorZona(mapa.name)} width={width}/>
                </div>
            </>

            break;

            default: auditBuildClient = "";
        }
    }, [zona]);

    let cursorZona = (nombre) => {
        setZonaCursor(nombre)
    }

    let selectZona = (data) => {
        switch(zonaTipo){
            case "cuadricula":
                let search = zonasCat.find(option => option.nombre_zona === data.zona.label);
                if(search === undefined){
                    setPrecioFuncion("");
                    sessionStorage.setItem("ContentDataPrecioZona", JSON.stringify([]));
                }else{
                    let currency = JSON.parse(sessionStorage.getItem("ContentDataCurrency"));
                    setPrecioFuncion(
                        <div>
                            <div><strong>Precio por Entrada:</strong></div>
                            <strong className = "text-primary">{currency.moneda}. {search.precio}</strong>
                        </div>
                    );
                    sessionStorage.setItem("ContentDataPrecioZona", JSON.stringify(search));
                }
        
                if (data.zona === "") {
                    setDetFuncion(<React.Fragment>Selecciona una zona <FontAwesomeIcon icon = {faCaretSquareRight}/></React.Fragment>)
                    setZonaId("");
                    setDisabled(true);
                }else{
                    setDetFuncion(<span>HAS SELECCIONADO: <br/><strong>{data.zona.label}</strong></span>)
                    /* setZonaId(data.zona.value); */
                    setZonaId(search.id_zona);
                    setDisabled(false);
                    setZonaSel(true);
                }  
            break;
            case "area":
                let search1 = zonasCat.find(option => option.nombre_zona === data.name);
                if(search1 === undefined){
                    setPrecioFuncion("");
                    sessionStorage.setItem("ContentDataPrecioZona", JSON.stringify([]));
                }else{
                    let currency = JSON.parse(sessionStorage.getItem("ContentDataCurrency"));
                    setPrecioFuncion(
                        <div>
                            <div><strong>Precio por Entrada:</strong></div>
                            <strong className = "text-primary">{currency.moneda}. {search1.precio}</strong>
                        </div>
                    );
                    sessionStorage.setItem("ContentDataPrecioZona", JSON.stringify(search1));
                }

                if (data.zona === "") {
                    setDetFuncion(<React.Fragment>Selecciona una zona <FontAwesomeIcon icon = {faCaretSquareRight}/></React.Fragment>)
                    setZonaId("");
                    setDisabled(true);
                }else{
                    setDetFuncion(<span>HAS SELECCIONADO: <br/><strong>{data.name}</strong></span>)
                    setZonaId(search1.id_zona);
                    setDisabled(false);
                    setZonaSel(true);
                }
            break;
            default: return null;
        }
    }

    let distrib = () => {
        if (zona.length === 0) return (
            <Skeleton height = {200}/>
        )

        return auditBuildClient;
    }

    let leyend = () => {
        if (zonasCat.length === 0) return leyenda;

        let bolder = (nombre) => {
            if (nombre === zonaCursor) return "bold"
            return "";
        }

        let customClass = (nombre) => {
            if (nombre === zonaCursor) return "text-zona-select text-info"
            return "text-zona";
        }

        let currency = JSON.parse(sessionStorage.getItem("ContentDataCurrency"));

        return (
            zonasCat.sort((a, b) => a.nombre_zona.localeCompare(b.nombre_zona)).map(option => (
                <React.Fragment key = {option.id} >
                    <Col onClick={() => selectZona(option = {zona:{value:option.id_nombre, label:option.nombre_zona}})} md = {9} sm = {9} xs = {9} style = {{fontWeight: bolder(option.nombre_zona), padding: 5, paddingBottom: 15, paddingTop: 15, fontSize: 20, borderBottom: "1px solid #eaeaea", cursor:'pointer', }}><FontAwesomeIcon icon = {faCircle} style = {{color: option.color}} /> <span className = {customClass(option.nombre_zona)}>{option.nombre_zona}</span></Col>
                    <Col onClick={() => selectZona(option = {zona:{value:option.id_nombre, label:option.nombre_zona}})} md = {3} sm = {3} xs = {3} style = {{padding: 5, paddingBottom: 15, paddingTop: 20, fontSize: 15, borderBottom: "1px solid #eaeaea"}} align = "right" className = {customClass(option.nombre_zona)}><strong>{currency.moneda}. {option.precio}</strong></Col>
                </React.Fragment>
            ))
        );
    }

    let updt = () => {
        setControl2(!control2);
        props.butacas()
    } 

    return (
        <Row className="mb-5">
            <Col md = {12} style = {{marginTop: 60}}>
                <Card className = "main-card mb-3" style = {{borderRadius: 30}}>
                    <CardTitle className = "twitter">
                        <strong>Selecciona la zona</strong>
                    </CardTitle>
                    <CardBody>
                        <Row>
                            <Col md = {5}>
                                <Row>
                                    <Col md = {12}>
                                        <div style = {{fontSize: 28}}><strong>Detalles de la zona</strong></div>
                                        <h5 className = "text-info">{detFuncion}</h5>
                                    </Col>
                                    <Col md = {12} className = "custom-mt-10">
                                        {precioFuncion}
                                    </Col>
                                    <Col md = {12} className = "custom-mt-10">
                                        <div><strong>Leyenda:</strong></div>
                                        <Row>{leyend()}</Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md = {7}>
                                <div>
                                    {distrib()}
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <Stats step = {1} {...props} tipo = "SelectZona" disabled = {disabled} mensajeDis = "Primero selecciona una zona" idZona = {zonaId} butacas = {updt} zonaSel = {zonaSel} cambiaZonaSel = {(valor) => setZonaSel(valor)}/>
                    </CardFooter>
                </Card>
            </Col>
            
        </Row>
    )
}

const SelectAsiento = (props) => {
    const [butacas, setButacas] = useState([])
    const [precioBoleto, setPrecioBoleto]   = useState("");
    const [zonaSelect, setZonaSelect]       = useState("");
    const [butacasSelect, setButacasSelect] = useState([]);
    const [total, setTotal]                 = useState(0);
    const [colorButSold, setColorButSold]   = useState("#d8d8d8");
    const [colorButSel, setColorButSel]     = useState("#de932e");
    const [colorButTran, setColorButTran]   = useState("#4caf50");
    const [customStyles, setCustomStyles]   = useState({
        backgroundColor: "#d8d8d8",
        border: "1px solid #ffffff",
        borderRadius: "3px 3px"
    });
    const [tamButaca, setTamButaca] = useState(18);
    const [colorBut, setColorBut]   = useState("#d8d8d8");
    const [radButaca, setRadButaca] = useState(3);
    const [preloader, setPreloader] = useState(false);
    const [idContent, setIdContent] = useState("");
    const [disabled, setDisabled]   = useState(true)
    const [estilo, setEstilo]       = useState("default")
    const [sillasDisabled, setSillasDisabled]   = useState([]);
    const [confMesas, setConsfMesas]        = useState({
        superior: 4,
        izquierda: 4,
        derecha: 4,
        inferior: 4
    })
    const [selectInd, setSelectInd] = useState(true);
    const [cantLibre, setCantLibre] = useState("");
    const [servTaquilla, setServTaquilla] = useState("");
    const [price, setPrice] = useState("")
    const [Currency, setCurrency] = useState("")
    const [imgPlane, setImgPlane] = useState("")

    useEffect(()=>{
        let ContentDataEvent = JSON.parse(sessionStorage.getItem("ContentDataEvent"));
        let ContentData = JSON.parse(sessionStorage.getItem("ContentDataPrecioZona"));

        if(ContentData){
            let idEvento = ContentDataEvent?.id_evento;
            let zoneName = ContentData.nombre_zona.replace(' ', '_')

            const routeImg = `${UrlZones}${idEvento}/${zoneName}.JPG`;
            setImgPlane(routeImg)
        }
    },[props]);

    useEffect(() => {
        let ContentDataPrecioZona = JSON.parse(sessionStorage.getItem("ContentDataPrecioZona"))
        let ContentDataEvent = JSON.parse(sessionStorage.getItem("ContentDataEvent"));
        let ContentDataButacas = JSON.parse(sessionStorage.getItem("ContentDataButacas"));
        let ContentDataCart = JSON.parse(sessionStorage.getItem("ContentDataCart"));

        if (ContentDataCart !== null){
            (ContentDataCart.length === 0) ? setDisabled(true) : setDisabled(false);
        }else{
            setDisabled(true)
        }

        if (ContentDataButacas !== null){
            if(ContentDataButacas.data !== undefined) {
                setEstilo(ContentDataButacas.data.estilo)
                setSillasDisabled(JSON.parse(ContentDataButacas.data.sillas_disabled));
                setConsfMesas(JSON.parse(ContentDataButacas.data.cant_sillas));

                switch (ContentDataButacas.data.estilo){
                    case "libre":
                        updSeleccionadoEntLibre();
                    break;
                    case "mesa":
                        updSeleccionadoMesa();
                    break;
                    default:
                        updSeleccionados();
                    break;
                }
            }
            
            if(ContentDataButacas.idContent !== undefined) setIdContent(ContentDataButacas.idContent)
        }

        if (ContentDataPrecioZona !== null){
            setZonaSelect(ContentDataPrecioZona.nombre_zona)
            setPrice(ContentDataPrecioZona.precio)
        }

        if (ContentDataPrecioZona !== null && ContentDataEvent !== null){
            let currency = JSON.parse(sessionStorage.getItem("ContentDataCurrency"));
            setPrecioBoleto(`${currency.moneda}. ${ContentDataPrecioZona.precio}`)
            setCurrency(currency)
        }
        if ((props.butacas.length) === undefined){
            setButacas(props.butacas)
            setCustomStyles(state => ({...state,  backgroundColor: props.butacas.data.butaca_color}));
            setTamButaca(window.innerWidth < 500 ? 10 : props.butacas.data.butaca_tam);
            setColorBut(props.butacas.data.butaca_color);
            setRadButaca(props.butacas.data.butaca_radio);

            if(butacasSelect.length !== 0){
                setButacas(butacas)
            }

            if(butacas.length !== 0){
                setServTaquilla(props.butacas.impuestos)
            }
        }

    }, [props]);

    let updSeleccionados = () => {
        let ContentDataButacas = JSON.parse(sessionStorage.getItem("ContentDataButacas"));
        let ContentDataPrecioZona = JSON.parse(sessionStorage.getItem("ContentDataPrecioZona"))
        let currency = JSON.parse(sessionStorage.getItem("ContentDataCurrency"));

        let butacasVendidas = ContentDataButacas.dataSold;
        let idUser = ContentDataButacas.idContent;

        if (butacasVendidas !== undefined && idUser !== undefined){
            let Content = [];
            let total = 0;
            butacasVendidas.forEach(opt => {
                if (opt.idContent === idUser && opt.id_status === 1){
                    Content.push({
                        id: opt.id,
                        fila: opt.butaca_fila,
                        columna: opt.butaca_columna,
                        asiento: `Asiento ${opt.butaca_fila}${opt.butaca_columna}`,
                        precio: `${currency.moneda}. ${ContentDataPrecioZona.precio}`
                    });
                    
                    total += ContentDataPrecioZona.precio
                }
            })

            setButacasSelect(Content);
            setTotal(total);
        }
    }

    let updSeleccionadoEntLibre =() => {
        let ContentDataButacas = JSON.parse(sessionStorage.getItem("ContentDataButacas"));
        let ContentDataPrecioZona = JSON.parse(sessionStorage.getItem("ContentDataPrecioZona"))
        let currency = JSON.parse(sessionStorage.getItem("ContentDataCurrency"));

        let butacasVendidas = ContentDataButacas.dataSold;
        let idUser = ContentDataButacas.idContent;

        if (butacasVendidas !== undefined && idUser !== undefined){
            let Content = [];
            let total = 0;
            butacasVendidas.forEach(opt => {
                if (opt.idContent === idUser && opt.id_status === 1){
                    Content.push({
                        id: opt.id,
                        fila: null,
                        columna: null,
                        asiento: `N/A`,
                        precio: `${currency.moneda}. ${ContentDataPrecioZona.precio}`
                    });
                    
                    total += ContentDataPrecioZona.precio
                }
            })

            setButacasSelect(Content);
            setTotal(total);
        }
    }
    
    let updSeleccionadoMesa = () => {
        let ContentDataButacas = JSON.parse(sessionStorage.getItem("ContentDataButacas"));
        let ContentDataPrecioZona = JSON.parse(sessionStorage.getItem("ContentDataPrecioZona"))
        let ContentDataEvent = JSON.parse(sessionStorage.getItem("ContentDataEvent"));
        let currency = JSON.parse(sessionStorage.getItem("ContentDataCurrency"));

        let butacasVendidas = ContentDataButacas.dataSold;
        let idUser = ContentDataButacas.idContent;

        let ladoSilla = (string) => {
            let izquierda = string.indexOf("Izquierda");
            let superior = string.indexOf("Superior");
            let derecha = string.indexOf("Derecha");
            let inferior = string.indexOf("Inferior");

            switch (true){
                case (izquierda > 0): return "Izquierda";
                case (superior > 0): return "Superior";
                case (derecha > 0): return "Derecha";
                case (inferior > 0): return "Inferior";
                default: return "";
            }
        }

        if (butacasVendidas !== undefined && idUser !== undefined){
            let Content = [];
            let total = 0;

            butacasVendidas.forEach(opt => {
                if (opt.idContent === idUser && opt.id_status === 1){
                    let asiento = `ASIENTO ${opt.butaca_fila}${opt.butaca_columna}`;
                    if (opt.codigo_silla !== null) asiento = `MESA ${opt.butaca_fila}${opt.butaca_columna} - SILLA ${ladoSilla(opt.codigo_silla)} ${opt.num_silla}`;

                    Content.push({
                        id: opt.id,
                        fila: opt.butaca_fila,
                        columna: opt.butaca_columna,
                        asiento: asiento,
                        precio: `${currency.moneda}. ${ContentDataPrecioZona.precio}`,
                        silla: opt.num_silla_cont
                    });
                    
                    total += ContentDataPrecioZona.precio
                }
            })

            setButacasSelect(Content);
            setTotal(total);
        }
    }

    let butacasCol = () => {
        switch(estilo){
            case "libre":
                if (butacas.length === 0) return null
                
                let addEntLibre = () => {
                    sessionStorage.removeItem("ContentDataCart");

                    DelButacasUser(() => {
                        sessionStorage.removeItem("ContentDataCart");
                    });

                    if (cantLibre > butacas.disp){
                        toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span> Debes seleccionar una cantidad inferior a {butacas.disp}</span></small>)
                    }else{
                        let ContentDataEvent = JSON.parse(sessionStorage.getItem("ContentDataEvent"))
                                            
                        let Campos = {
                            cantAsientos: cantLibre,
                            eventSelect: ContentDataEvent.id_evento,
                            funcionSelect: ContentDataEvent.id,
                            idDistribucion: butacas.data.id,
                            idZona: butacas.idZona.id
                        }

                        let ContentCarrito = JSON.parse(sessionStorage.getItem("ContentDataCart"))
                        let ContentData = JSON.parse(sessionStorage.getItem("ContentDataPrecioZona"))
                        if (ContentCarrito === null) ContentCarrito = [];

                        setPreloader(true);

                        let addOk = (resp) => {
                            props.updControlCart();
                            setDisabled(false);

                            if (resp.rest === 1) {                    
                                let Content = {
                                    D001: cantMinutos,
                                    D002: cantSegundos
                                }

                                if (butacasSelect.length === 0) {
                                    sessionStorage.setItem("ContentDataT", JSON.stringify(Content));
                                    props.updControl();
                                }
                                
                            }

                            (resp.idData).forEach(i => {
                
                                ContentCarrito.push({
                                    id: i.id,
                                    asiento: ContentData.nombre_zona,
                                    fila: "N/",
                                    columna: "A",
                                    precio: ContentData.precio
                                })
                            })

                            sessionStorage.setItem("ContentDataCart", JSON.stringify(ContentCarrito));

                            let ContentButacas = {
                                data: resp.data,
                                dataSold: resp.dataSold,
                                idContent: resp.idContent,
                                status: resp.status,
                                disp: resp.disp,
                                idZona: props.butacas.idZona
                            }

                            sessionStorage.setItem("ContentDataButacas", JSON.stringify(ContentButacas));
                            setButacas(ContentButacas);
                            setButacas(ContentButacas);
                            setCantLibre("");
                            updSeleccionadoEntLibre();
                        }

                        AddEntLibreInd (Campos, (resp) => {
                            setPreloader(false);
                            switch (true) {
                                case (resp.status === "error" || resp.status === "error_log"):
                                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                                break;
                                case (resp.status === "warn"):
                                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                                    setCantLibre(resp.disp);
                                break;
                                default:
                                    addOk(resp)
                                break;
                            }
                        })
                    }
                }

                return (
                    <div className = "custom-mt-20">
                        <Row>
                            <Col md = {12}>
                                <h4>Selecciona la cantidad de entradas</h4>
                            </Col>
                            <Col md = {6} align = "right" className = "custom-mt-10">
                                <div className = "text-danger">Disponibles: <strong>{butacas.disp}</strong></div>
                            </Col>
                            <Col md = {6} align = "right">
                                <Input type = "number" value = {cantLibre} onChange = {(e) => setCantLibre(e.target.value)}/>
                            </Col>
                            <Col md = {12} align = "right" className = "custom-mt-10">
                                <Button color = "primary" title = "Confirmar la cantidad de entradas seleccionada" onClick = {() => {
                                        (cantLibre <= 0) ? toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span> Debes seleccionar al menos una entrada</span></small>) :
                                        addEntLibre();
                                }}>Seleccionar</Button>
                            </Col>
                        </Row>
                    </div>
                )
            default:
                if (butacas.length === 0) return (
                    <Skeleton height = {200}/>
                )
        
                let audit = JSON.parse(butacas.data.auditorio_build);
                let butacasVendidas  = butacas.dataSold;
                let alternar = butacas.data.alternado;
        
                let filName = (id, letra) => {
                    if(!isNaN(Number(id)) === true){
                        return <span style = {{fontSize: 10, height: tamButaca, minWidth: tamButaca, maxWidth: tamButaca}} className = "butaca-num"><strong>{letra}</strong></span>
                    }else{
                        return <span style = {{fontSize: 7, color: "#ccc", height: tamButaca, minWidth: tamButaca, maxWidth: tamButaca}} className = "butaca-num"><FontAwesomeIcon icon = {faCircle}/></span>
                    }
                }
        
                let numL = -1;
                let colums = (col) => {
                    switch(estilo){
                        case "mesa": return col.map(option => {
                            /* const search = butacasVendidas.find(opt => opt.butaca_fila === option.letraAlt && parseInt(opt.butaca_columna) === option.id); */
                            
                            let stylesD = customStyles;
                            let colorButSelInd = {
                                backgroundColor: colorButSel,
                                border: "1px solid #ffffff",
                                borderRadius: "3px 3px"
                            };
        
                            let colorButTranInd = {
                                backgroundColor: colorButTran,
                                border: "1px solid #ffffff",
                                borderRadius: "3px 3px",
                                cursor: "default"
                            };
        
                            let colorButSoldInd = {
                                backgroundColor: colorButSold,
                                border: "1px solid #ffffff",
                                borderRadius: "3px 3px",
                                cursor: "default"
                            };
                            
                            let optAddButaca = (data) => {
                                if (selectInd === false){
                                    toast.warn(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span> No puedes seleccionar una silla individual. Debes seleccionar la mesa completa</span></small>);
                                }else{
                                    let s = data.data
                                    let derecha = s.indexOf("Derecha");
                                    let izquierda = s.indexOf("Izquierda");
                                    let superior = s.indexOf("Superior");
                                    let inferior = s.indexOf("Inferior");

                                    let silla;
                                    
                                    if (derecha !== -1) {
                                        silla = parseInt(data.code) + 1                                    
                                    } else if(inferior !== -1) {
                                        silla = parseInt(data.code) + parseInt(confMesas.derecha) + 1
                                    } else if (izquierda !== -1) {
                                        silla = parseInt(data.code) + parseInt(confMesas.derecha) + parseInt(confMesas.inferior) + 1
                                    } else if (superior !== -1) {
                                        silla = parseInt(data.code) + parseInt(confMesas.derecha) + parseInt(confMesas.inferior) + parseInt(confMesas.izquierda) + 1
                                    }

                                    addSillaMesa(option, data, silla);
                                }
                            }
        
                            let optDelButaca = (data) => {
                                let search = butacasVendidas.find(option => option.num_silla === data.num + 1 && option.codigo_silla === data.data)
                                if (search !== undefined) delMesaSilla(search.id)
                            }
        
                            let optAddMesa = () => {
                                let ContentData = JSON.parse(sessionStorage.getItem("ContentDataPrecioZona"))
                                let Content = [];
        
                                let sillas = (posicion, lugar) => {
                                    for (let i = 0; i < posicion; i++){
                                        const search = sillasDisabled.find(opt => opt.data === `${option.letraAlt}${option.id}${lugar}${i + 1}`)
                                        
                                        if (search === undefined) Content.push({
                                            fila: option.letraAlt,
                                            columna: option.id,
                                            idFuncion: ContentData.id_funcion,
                                            idZona: ContentData.id_zona,
                                            idDistribucion: props.butacas.data.id,
                                            sillaNum: i + 1,
                                            sillaCode: `${option.letraAlt}${option.id}${lugar}${i + 1}`,
                                        })
                                    }
                                }        
                                sillas(confMesas.derecha, "Derecha")
                                sillas(confMesas.inferior, "Inferior")
                                sillas(confMesas.izquierda, "Izquierda")
                                sillas(confMesas.superior, "Superior")
                                
                                let ContentDataButacas = JSON.parse(sessionStorage.getItem("ContentDataButacas"));
                                let SillasSelectD = 0;
                                let SillasDis = [];
        
                                if (ContentDataButacas !== undefined){
                                    /* Si todas las butacas ya estan seleccionadas entonces eliminar en vez de agregar */
                                    Content.forEach(i => {
                                        const search = (ContentDataButacas.dataSold).find(opt => opt.codigo_silla === i.sillaCode);
                                        if (search !== undefined){
                                            SillasSelectD ++;
                                            SillasDis.push(search);
                                        }
                                    }) 
                                }
                                
                                if(SillasSelectD === Content.length){
                                    delMesa(SillasDis, `${option.letraAlt}${option.id}`)
                                }else{
                                    let newContent = Content.map((obj, index)=>{
                                        return{...obj, silla: index+1}
                                    })
                                    addMesa(newContent, `${option.letraAlt}${option.id}`)
                                }
                            }

                            return <MesaDist
                                key = {`${option.id_fila}_${option.id}`}
                                confMesas = {confMesas}
                                customStyles = {customStyles}
                                data = {option}
                                numL = {numL}
                                sillasDisabled = {sillasDisabled}
                                disabled = {false}
                                onClick = {(data) => {optAddButaca(data)}}
                                onClickDel = {(data) => {optDelButaca(data)}}
                                onClickMesa = {() => {optAddMesa()}} 
                                stylesD = {stylesD}
                                butacasVendidas = {butacasVendidas}
                                colorButSel = {colorButSelInd}
                                colorButTran = {colorButTranInd}
                                colorButSold = {colorButSoldInd}
                                idContent = {idContent}
                            />

                        })
                        default: return col.map(option => {
                            const search = butacasVendidas.find(opt => opt.butaca_fila === option.letraAlt && parseInt(opt.butaca_columna) === option.id);
            
                            let stylesC = customStyles;
            
                            if (search !== undefined) {
                                switch (search.id_status){
                                    case (1):
                                        /* En Tansición */
                                        if (search.idContent === idContent){
                                            stylesC = {
                                                backgroundColor: colorButSel,
                                                border: "1px solid #ffffff",
                                                borderRadius: "3px 3px"
                                            };
                                        }else{
                                            stylesC = {
                                                backgroundColor: colorButTran,
                                                border: "1px solid #ffffff",
                                                borderRadius: "3px 3px"
                                            };
                                        }
                                    break;
                                    default:
                                        stylesC = {
                                            backgroundColor: colorButSold,
                                            border: "1px solid #ffffff",
                                            borderRadius: "3px 3px"
                                        };
                                    break;
                                }                    
                            }
            
                            let optAddButaca = () => {
                                if (search !== undefined) {
                                    switch (search.id_status){
                                        case (1):
                                            if (search.idContent === idContent){
                                                /* Eliminar Seleccion */
                                                delButaca(search.id);
                                            }else{
                                                toast.warn(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span> Este asiento se encuentra en transición</span></small>);
                                                return null;
                                            }
                                        break;
                                        
                                        default: 
                                            toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span> Este asiento no esta disponible</span></small>);
                                        return null
                                    }
                                }else{
                                    addButaca(option);
                                }
                            }                            
                            return <ButacaDist
                                titleDis = {true}
                                onClick = {() => {optAddButaca()}}
                                control = {false}
                                key = {`${option.id_fila}_${option.id}`}
                                data = {option}
                                numL = {numL}
                                sumNumL = {(num) => {numL = numL + num}}
                                styles = {stylesC}
                                tamButaca = {tamButaca}
                                radButaca = {radButaca}
                                colorButaca = {colorBut}
                                estiloButaca = {estilo}
                            />
                        })
                    }
                }
        
                let nums = [];
                let fontSize = window.innerWidth < 500 ? 7 :10;
                let stylesSpan = {
                    fontSize: fontSize, height: tamButaca, minWidth: tamButaca, maxWidth: tamButaca
                }
        
                nums.push({id: 0, content: <span style = {stylesSpan} className = "butaca-num"></span>});
        
                for (let i = 1, n = 1; i <= audit[0].columnas.length; i++){
                    /* Buscar el numero en las columnas a ver si estan disabled */
                    let idCol = audit[0].columnas[i - 1].id;
                    if(!isNaN(Number(idCol)) === true){
                        nums.push({id: i, content: <span style = {stylesSpan} className = "butaca-num"><strong className = "text-primary">{n}</strong></span>});
                        n++;
                    }else{
                        nums.push({id: i, content: <span style = {stylesSpan} className = "butaca-num"><FontAwesomeIcon icon = {faCircle} style = {{fontSize: 7, color: "#ccc"}}/></span>});
                    }
                }
        
                let num = 0;
        
                let leyendeNum = () => {
                    if (estilo === "mesa") return null;
        
                    return <>{nums.map(option => ( <React.Fragment key = {option.id}>{option.content}</React.Fragment> ))}</>
                }
                
            return (
                <div>
                    {leyendeNum()}
                    {audit.map(option => {
                        num++;
                        let styles = {};
                        let butAdicional = "";
    
                        if (num % 2 === 0 && alternar === 1){
                            styles = {marginLeft: 20};
                            butAdicional = <span className = "butaca-num" style = {{height: tamButaca, minWidth: 10, backgroundColor: "#fff"}}></span>
                        }
    
                        return(
                            <div key = {option.id}>
                                {filName(option.id, option.letraAlt)}
                                {butAdicional}
                                {colums(option.columnas)}
                            </div>
                        )
                    })}
                </div>
            )
        }
    }

    let addSillaMesa = (option, data, silla) => {
        let ContentData = JSON.parse(sessionStorage.getItem("ContentDataPrecioZona"))
        let Campos = {
            fila: option.letraAlt,
            columna: option.id,
            idFuncion: ContentData.id_funcion,
            idZona: ContentData.id_zona,
            idDistribucion: props.butacas.data.id,
            sillaNum: data.num + 1,
            sillaCode: data.data,
            CodeSilla: silla
        }

        setPreloader(true);
        AddMesaInd (Campos, (resp) => {
            setPreloader(false);
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                break;
                default:
                    props.updControlCart();
                    setDisabled(false);

                    if (resp.rest === 1) {                    
                        let Content = {
                            D001: cantMinutos,
                            D002: cantSegundos
                        }
                        sessionStorage.setItem("ContentDataT", JSON.stringify(Content));
                        props.updControl();
                    }

                    let ContentCarrito = JSON.parse(sessionStorage.getItem("ContentDataCart"))

                    let resultado = resp.dataSold.find(obj => obj.codigo_silla === data.data);

                    if (ContentCarrito === null){                        
                        let CampoCart = [{
                            id: resp.idData,
                            asiento: ContentData.nombre_zona,
                            fila: option.letraAlt,
                            columna: option.id,
                            precio: ContentData.precio,
                            silla_num: data.num + 1,
                            silla_code: data.data,
                            silla: resultado.num_silla_cont
                        }];

                        sessionStorage.setItem("ContentDataCart", JSON.stringify(CampoCart));
                    }else{
                        ContentCarrito.push({
                            id: resp.idData,
                            asiento: ContentData.nombre_zona,
                            fila: option.letraAlt,
                            columna: option.id,
                            precio: ContentData.precio,
                            silla_num: data.num + 1,
                            silla_code: data.data,
                            silla: resultado.num_silla_cont
                        })

                        sessionStorage.setItem("ContentDataCart", JSON.stringify(ContentCarrito));
                    }

                    let ContentButacas = {
                        data: resp.data,
                        dataSold: resp.dataSold,
                        idContent: resp.idContent,
                        status: resp.status,
                        idZona: props.butacas.idZona
                    }

                    sessionStorage.setItem("ContentDataButacas", JSON.stringify(ContentButacas));
                    setButacas(ContentButacas);
                    setButacas(ContentButacas);
                    updSeleccionadoMesa();
                break;
            }
        })
    }

    let addMesa = (data, mesaNum) => {
        let ContentCarrito = JSON.parse(sessionStorage.getItem("ContentDataCart"))
        let ContentData = JSON.parse(sessionStorage.getItem("ContentDataPrecioZona"))
        if (ContentCarrito === null) ContentCarrito = [];

        setPreloader(true);

        let addOk = (resp) => {

            props.updControlCart();
            setDisabled(false);

            if (resp.rest === 1) {                    
                let Content = {
                    D001: cantMinutos,
                    D002: cantSegundos
                }
                sessionStorage.setItem("ContentDataT", JSON.stringify(Content));
                props.updControl();
            }
            
            (resp.idData).forEach(i => {
                const search = data.find(opt => opt.sillaCode === i.code);

                ContentCarrito.push({
                    id: i.id,
                    asiento: ContentData.nombre_zona,
                    fila: search.fila,
                    columna: search.columna,
                    precio: ContentData.precio,
                    silla_num: search.sillaNum,
                    silla_code: search.sillaCode,
                    silla: search.silla
                })
            })

            sessionStorage.setItem("ContentDataCart", JSON.stringify(ContentCarrito));

            let ContentButacas = {
                data: resp.data,
                dataSold: resp.dataSold,
                idContent: resp.idContent,
                status: resp.status,
                idZona: props.butacas.idZona
            }

            sessionStorage.setItem("ContentDataButacas", JSON.stringify(ContentButacas));
            setButacas(ContentButacas);
            setButacas(ContentButacas);
            updSeleccionadoMesa();
        }

        AddMesa(data, mesaNum, (resp) => {
            setPreloader(false);
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                break;
                case (resp.status === "warn"):
                    toast.warn(<small><FontAwesomeIcon icon = {faExclamationTriangle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                    addOk(resp)
                break;
                default:
                    addOk(resp)
                break;
            }
        })
    }

    let delMesa = (data, mesaNum) => {
        setPreloader(true);

        DelMesa(data, mesaNum, (resp) => {
            setPreloader(false);

            let ContentCarritoCurrent = JSON.parse(sessionStorage.getItem("ContentDataCart"));
            let ContentNewCarrito = [];

            if(ContentCarritoCurrent !== null){
                ContentCarritoCurrent.forEach((option) => {
                    const search = (resp.dataSold).find(opt => opt.id === option.id);

                    if (search !== undefined) ContentNewCarrito.push(option)
                })
            }

            sessionStorage.setItem("ContentDataCart", JSON.stringify(ContentNewCarrito));
            props.updControlCart();

            if (resp.rest === 0) {
                /* Detener Cronometro */
                let Content = {
                    D001: null,
                    D002: null
                }
                sessionStorage.setItem("ContentDataT", JSON.stringify(Content));
                sessionStorage.removeItem("ContentDataT")
                props.updControl();
                setDisabled(true);
            }

            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                break;
                case(resp.status === 'errorMesa'):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                break;
                default:
                    let ContentButacas = {
                        data: resp.data,
                        dataSold: resp.dataSold,
                        idContent: resp.idContent,
                        status: resp.status,
                        idZona: props.butacas.idZona
                    }

                    sessionStorage.setItem("ContentDataButacas", JSON.stringify(ContentButacas));
                    
                    setButacas(ContentButacas)
                    setButacas(ContentButacas)
                    updSeleccionadoMesa();
                break;
            }
        })
    }

    let addButaca = (data) => {
        let ContentData = JSON.parse(sessionStorage.getItem("ContentDataPrecioZona"))

        let Campos = {
            fila: data.letraAlt,
            columna: data.id,
            idFuncion: ContentData.id_funcion,
            idZona: ContentData.id_zona,
            idDistribucion: props.butacas.data.id
        }
       
        setPreloader(true);
        AddButacaInd (Campos, (resp) => {
            setPreloader(false);
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                    let ContentDataButacas = JSON.parse(sessionStorage.getItem("ContentDataButacas"));

                    setButacas(ContentDataButacas);
                    setButacas(ContentDataButacas);
                break;
                default:
                    props.updControlCart();
                    setDisabled(false);

                    if (resp.rest === 1) {                    
                        let Content = {
                            D001: cantMinutos,
                            D002: cantSegundos
                        }
                        sessionStorage.setItem("ContentDataT", JSON.stringify(Content));
                        props.updControl();
                    }

                    let ContentCarrito = JSON.parse(sessionStorage.getItem("ContentDataCart"))

                    if (ContentCarrito === null){                        
                        let CampoCart = [{
                            id: resp.idData,
                            asiento: ContentData.nombre_zona,
                            fila: data.letraAlt,
                            columna: data.id,
                            precio: ContentData.precio,
                            silla_num: null,
                            silla_code: null
                        }];

                        sessionStorage.setItem("ContentDataCart", JSON.stringify(CampoCart));
                    }else{
                        ContentCarrito.push({
                            id: resp.idData,
                            asiento: ContentData.nombre_zona,
                            fila: data.letraAlt,
                            columna: data.id,
                            precio: ContentData.precio,
                            silla_num: null,
                            silla_code: null
                        })

                        sessionStorage.setItem("ContentDataCart", JSON.stringify(ContentCarrito));
                    }

                    let ContentButacas = {
                        data: resp.data,
                        dataSold: resp.dataSold,
                        idContent: resp.idContent,
                        status: resp.status,
                        idZona: props.butacas.idZona
                    }

                    sessionStorage.setItem("ContentDataButacas", JSON.stringify(ContentButacas));
                    setButacas(ContentButacas);
                    setButacas(ContentButacas);
                    updSeleccionados();
                break;
            }
        })
    }

    let delButaca = (data) => {
        setPreloader(true);

        DelButacaInd(data, (resp) => {
            let ContentCarritoCurrent = JSON.parse(sessionStorage.getItem("ContentDataCart"));
            let ContentNewCarrito = [];

            if(ContentCarritoCurrent !== null){
                ContentCarritoCurrent.forEach((option) => {
                    const search = (resp.dataSold).find(opt => opt.id === option.id && opt.idContent === resp.idContent);
                    if (search !== undefined) ContentNewCarrito.push(option)
                })
            }

            sessionStorage.setItem("ContentDataCart", JSON.stringify(ContentNewCarrito));
            props.updControlCart();

            if (resp.rest === 0) {
                /* Detener Cronometro */
                let Content = {
                    D001: null,
                    D002: null
                }
                sessionStorage.setItem("ContentDataT", JSON.stringify(Content));
                sessionStorage.removeItem("ContentDataT")
                props.updControl();
                setDisabled(true);
            }

            setPreloader(false);

            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                break;
                default:
                    let ContentButacas = {
                        data: resp.data,
                        dataSold: resp.dataSold,
                        idContent: resp.idContent,
                        status: resp.status,
                        disp: resp.disp,
                        idZona: resp.idZona
                    }

                    sessionStorage.setItem("ContentDataButacas", JSON.stringify(ContentButacas));
                    setButacas(ContentButacas);

                    let ContentDataButacas = JSON.parse(sessionStorage.getItem("ContentDataButacas"));

                    switch (ContentDataButacas.data.estilo){
                        case "libre":
                            updSeleccionadoEntLibre();
                        break;
                        case "mesa":
                            updSeleccionadoMesa();
                        break;
                        default:
                            updSeleccionados();
                        break;
                    }
                break;
            }
        })
    }

    let delMesaSilla = (data) => {
        setPreloader(true);

        DelButacaInd(data, (resp) => {
            let ContentCarritoCurrent = JSON.parse(sessionStorage.getItem("ContentDataCart"));
            let ContentNewCarrito = [];

            if(ContentCarritoCurrent !== null){
                ContentCarritoCurrent.forEach((option) => {
                    const search = (resp.dataSold).find(opt => opt.id === option.id && opt.idContent === resp.idContent);
                    if (search !== undefined) ContentNewCarrito.push(option)
                })
            }

            sessionStorage.setItem("ContentDataCart", JSON.stringify(ContentNewCarrito));
            props.updControlCart();

            if (resp.rest === 0) {
                /* Detener Cronometro */
                let Content = {
                    D001: null,
                    D002: null
                }
                sessionStorage.setItem("ContentDataT", JSON.stringify(Content));
                sessionStorage.removeItem("ContentDataT")
                props.updControl();
                setDisabled(true);
            }

            setPreloader(false);

            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                break;
                default:
                    let ContentButacas = {
                        data: resp.data,
                        dataSold: resp.dataSold,
                        idContent: resp.idContent,
                        status: resp.status,
                        idZona: props.butacas.idZona
                    }

                    sessionStorage.setItem("ContentDataButacas", JSON.stringify(ContentButacas));
                    setButacas(ContentButacas);
                    updSeleccionadoMesa();
                break;
            }
        })
    }

    let butacasSeleccionadas = () => {
        if (butacasSelect.length === 0) return (
            <Col md = {12} className = "text-info" align = "center" style = {{padding: 5}}>No has seleccionado ningún asiento</Col>
        )

        let tablaTicket = (option) => {
            if (option.asiento === "N/A"){
                return (
                    <>
                        <Col md = {10} sm = {10} xs = {10} style = {{borderBottom: "1px dotted #cccccc", padding: 15}} align = "left"><strong>{option.precio}</strong></Col>
                        <Col md = {2} sm = {2} xs = {2} style = {{borderBottom: "1px dotted #cccccc", padding: 15}} align = "right"><FontAwesomeIcon icon = {faTrash} className = "text-muted" style = {{cursor:"pointer"}} onClick = {() => {delButaca(option.id)}} title = {`Eliminar el ticket de la selección`}/></Col>
                    </>
                )
            }else{
                return (
                    <>
                        <Col md = {7} sm = {7} xs = {7} style = {{borderBottom: "1px dotted #cccccc", padding: 15, paddingLeft: 30}}>{(option.silla) ? `MESA ${option.fila}${option.columna} - SILLA  ${option.silla}`  : option.asiento}</Col>
                        <Col md = {3} sm = {3} xs = {3} style = {{borderBottom: "1px dotted #cccccc", padding: 15}} align = "right"><strong>{option.precio}</strong></Col>
                        <Col md = {2} sm = {2} xs = {2} style = {{borderBottom: "1px dotted #cccccc", padding: 15}} align = "right"><FontAwesomeIcon icon = {faTrash} className = "text-muted" style = {{cursor:"pointer"}} onClick = {() => {delButaca(option.id)}} title = {`Eliminar el ${option.asiento} de la selección`}/></Col>
                    </>
                )
            }
        }
        return butacasSelect.map(option => (
            <React.Fragment key = {option.id}>
                {tablaTicket(option)}
            </React.Fragment>
        ))

    }

    let preloadTicker = () => {
        if (preloader === true) return (
            <div className = "preload-ticket">
               <div className = "preload-ticket-icon"><FontAwesomeIcon icon = {faCircleNotch} size = "5x" spin = {true}/></div> 
            </div>
        )
    }

    let leyendaAsiento = () => {
        switch (estilo) {
            case "default": return (
                <div className = "custom-mt-10">
                    <small><span className = "butaca-default" style = {{
                        backgroundColor: customStyles.backgroundColor,
                        border: "1px solid",
                        height: tamButaca,
                        minWidth: tamButaca,
                        maxWidth: tamButaca,
                        borderRadius: `${radButaca}px ${radButaca}px`
                    }}></span> Disponibles</small> | 
                    <small><span className = "butaca-default" style = {{
                        backgroundColor: colorButSold,
                        border: "1px solid #424242",
                        height: tamButaca,
                        minWidth: tamButaca,
                        maxWidth: tamButaca,
                        borderRadius: `${radButaca}px ${radButaca}px`
                    }}></span> Vendidos</small> | 
                    <small><span className = "butaca-default" style = {{
                        backgroundColor: colorButSel,
                        border: "1px solid #424242",
                        height: tamButaca,
                        minWidth: tamButaca,
                        maxWidth: tamButaca,
                        borderRadius: `${radButaca}px ${radButaca}px`
                    }}></span> Seleccionados</small> | 
                    <small><span className = "butaca-default" style = {{
                        backgroundColor: colorButTran,
                        border: "1px solid #424242",
                        height: tamButaca,
                        minWidth: tamButaca,
                        maxWidth: tamButaca,
                        borderRadius: `${radButaca}px ${radButaca}px`
                    }}></span> En transición</small>
                </div>
            )
            case "libre": return null;
            case "mesa": return (
                <div className = "custom-mt-10">
                    <small><span className = "butaca-default" style = {{
                        backgroundColor: customStyles.backgroundColor,
                        border: "1px solid",
                        height: 10,
                        minWidth: 10,
                        maxWidth: 10,
                        borderRadius: `5px 5px`
                    }}></span> Disponibles</small> | 
                    <small><span className = "butaca-default" style = {{
                        backgroundColor: colorButSold,
                        border: "1px solid #424242",
                        height: 10,
                        minWidth: 10,
                        maxWidth: 10,
                        borderRadius: `5px 5px`
                    }}></span> Vendidos</small> | 
                    <small><span className = "butaca-default" style = {{
                        backgroundColor: colorButSel,
                        border: "1px solid #424242",
                        height: 10,
                        minWidth: 10,
                        maxWidth: 10,
                        borderRadius: `5px 5px`
                    }}></span> Seleccionados</small> | 
                    <small><span className = "butaca-default" style = {{
                        backgroundColor: colorButTran,
                        border: "1px solid #424242",
                        height: 10,
                        minWidth: 10,
                        maxWidth: 10,
                        borderRadius: `5px 5px`
                    }}></span> En transición</small>
                </div>
            );
            default: return (
                <div className = "custom-mt-10">
                    <ButacaCustom clsName = "butaca-default" tipo = {estilo} fontSize = {18} color = {customStyles.backgroundColor} title = "" onClickFn1 = {() => {return null}}/> <small style = {{display: "table-cell"}}>Disponibles |</small>
                    <ButacaCustom clsName = "butaca-default" tipo = {estilo} fontSize = {18} color = {colorButSold} title = "" onClickFn1 = {() => {return null}}/> <small style = {{display: "table-cell"}}>Vendidos |</small>
                    <ButacaCustom clsName = "butaca-default" tipo = {estilo} fontSize = {18} color = {colorButSel} title = "" onClickFn1 = {() => {return null}}/> <small style = {{display: "table-cell"}}>Seleccionados |</small>
                    <ButacaCustom clsName = "butaca-default" tipo = {estilo} fontSize = {18} color = {colorButTran} title = "" onClickFn1 = {() => {return null}}/> <small style = {{display: "table-cell"}}>En transición</small>
                </div>
            )
        }
    }
    
    return (
        <>
            {preloadTicker()}
            <Row>
                <Col md = {12} style = {{marginTop: 60}}>
                    <Card className = "main-card mb-3" style = {{borderRadius: 30}}>
                        <CardTitle className = "twitter">
                            <strong>Selecciona los Asientos</strong>
                        </CardTitle>
                        <CardBody>
                            <Row>
                                <Col md = {5}>
                                    <Row>
                                        <Col md = {12}>
                                            <div style = {{fontSize: 28}}><strong>Detalles de los asientos</strong></div>
                                        </Col>
                                        <Col md = {12} className = "custom-mt-15">
                                            <div style = {{fontSize: 20}}><strong>Usted está aquí</strong></div>
                                            <img src={imgPlane} alt="" srcset="" style={{width:'300px', marginTop:'30px'}} />
                                        </Col>

                                        <Col md = {12} className = "custom-mt-15">
                                            <div style = {{fontSize: 20}}><strong>Zona seleccionada:</strong></div>
                                            <h5 className = "text-primary"><strong>{zonaSelect}</strong></h5>
                                        </Col>
                                        <Col md = {12} className = "custom-mt-15">
                                            <Impuesto 
                                            precio={price} 
                                            impuesto={servTaquilla} 
                                            precioBoleto = {precioBoleto} 
                                            moneda = {Currency.moneda} 
                                            />
                                        </Col>
                                        <Col md = {12}>
                                            <strong>Asientos seleccionados en esta zona: {butacasSelect.length > 0 ? butacasSelect.length : null} </strong>
                                            {(estilo === 'libre') ? null :<Row style = {{overflowY: "auto", maxHeight: "200px"}}>{butacasSeleccionadas()}</Row>}
                                        </Col>
                                        <Col md = {5}>
                                            <TotalT
                                            total = {total}
                                            servTaquilla = {servTaquilla}
                                            moneda = {Currency.moneda}
                                            precio={price}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md = {7}>
                                    <div style = {{overflow: "auto", padding: 15, height: "67vh"}}>
                                        {butacasCol()}
                                    </div>
                                    {leyendaAsiento()}
                                    <small><strong>Importante:</strong> Los boletos estarán reservados por <strong>{cantMinutos} minutos</strong> mientras completas tu orden.</small>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter>
                            <Stats step = {2} {...props} tipo = "SelectAsiento" func = {()=>setButacas(butacas)} disabled = {disabled} props = {props} mensajeDis = "Primero selecciona al menos un asiento"/>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

let totalPay = 0;
const SelectPayMet = (props) => {
    const [ContentDataEvent, setContentDataEvent]   = useState(JSON.parse(sessionStorage.getItem("ContentDataEvent")))
    const [payMetodos, setPayMetodos]               = useState([])
    const [metodoSelect, setMetodoSelect]           = useState("")
    const [currency, setCurrency]                   = useState("")
    const [monedas, setMonedas]                     = useState([])
    const [monedaSelect, setMonedaSelect]           = useState({value: "", label: "Selecciona"})
    const [pagosDisp, setPagosDisp]                 = useState("")
    const [cardNumber, setCardNumber]               = useState("")
    const [cardName, setCardName]                   = useState("")
    const [cardDate, setCardDate]                   = useState("")
    const [cardCvc, setCardCvc]                     = useState("")
    const [ciNumber, setCiNumber]                   = useState("")
    const [focused, setFocused]                     = useState("")
    const [check, setCheck]                         = useState(false);
    const [openModal, setOpenModal]                 = useState(false);  
    const [transaccionZelle, setTransaccionZelle]   = useState("")
    const [nombreZelle, setNombreZelle]             = useState("")
    const [numberTransfer, setNumberTransfer]      = useState("")
    const [emailZelle, setEmailZelle]               = useState("")
    const [file1, setFile1]                         = useState("");
    const [multiPayForm, setMultiPayForm]           = useState([]);
    const [multiPayImgs, setMultiPayImgs]             = useState([]);

    const [cashNombre, setCashNombre] = useState("")
    const [cashIdentificacion, setCashIdentificacion] = useState("")
    const [cashTlf, setCashTlf] = useState("")
    const [cashCorreo, setCashCorreo] = useState("")

    const [multiPayTaquMeth, setMultiPayTaqMeth] = useState("")
    const [datos_comprador, setDatos_comprador] = useState([])
    const [totalMultipay, setTotalMultipay] = useState(0);
    const [saldoAFavor, setSaldoAfavor] = useState({bs:0, usd:0});


    useEffect(() => {
        totalPay = 0;
    }, [cardNumber, cardName, cardDate, cardCvc, focused, check, openModal]);

    useEffect(() => {
        totalPay = 0;
        let vCurrency = JSON.parse(sessionStorage.getItem("ContentDataCurrency"));
        let ContentDataButacas = JSON.parse(sessionStorage.getItem("ContentDataButacas"));

        setCurrency(vCurrency)

        if (ContentDataEvent !== null){
            let Content = []
            let monedas = (ContentDataEvent.evento_info.monedas).split(',');

            monedas.forEach((i) => {
                Content.push({
                    value: i.trim(), label: i.trim()
                })
            })

            setMonedas(Content);
            setPayMetodos((ContentDataEvent.evento_info.metodos_pago).split(','));
        }

        /* if (ContentDataButacas !== null ){
            if (ContentDataButacas.length > 0 || ContentDataButacas !== undefined){
                if(ContentDataButacas.data!== undefined){
                    setEstilo(ContentDataButacas.data.estilo)
                }
            }
        } */
    }, [props]);

    // jgil para el componente de transaccion multipago
    // Componente Padre
    function handleFormDataFromChild(data) {
        // Manejar los datos aquí
        setMultiPayForm(data);
        // Puede establecer estos datos en el estado del padre o realizar otras acciones
    }
    function handleImgDataFromChild(data) {
        // Manejar los datos aquí
        setMultiPayImgs(data);
        // Puede establecer estos datos en el estado del padre o realizar otras acciones
    }

    let changeMoneda = (monedaSelect) => {
        totalPay = 0;
        setMonedaSelect(monedaSelect)
        setPagosDisp("");

        if (monedaSelect.value === "Bs. y USD") {
            setMetodoSelect("Multipagos")
        }

        PayTypes (monedaSelect.value, (resp) => {
            let Content = [];
            (resp.data).forEach(i => {
                const search = payMetodos.find(option => option.trim() === i.tipo);
                if (search !== undefined) Content.push(i.tipo)
            })
            
            /* setPayMetodos(Content); */
            setPagosDisp(Content);
        })
    }

    let metodos = () => {
        if (monedaSelect.value === "") return (
            <div style = {{padding: 10}} className = "text-danger">
                <strong><FontAwesomeIcon icon = {faExclamationCircle} /> No has seleccionado ninguna moneda</strong>
            </div>
        )

        if (pagosDisp === "") return (
            <div align = "center" style = {{padding: 10}} className = "text-danger">
                <small><FontAwesomeIcon icon = {faCircleNotch} spin = {true} /> Cargando los tipos de pago disponibes para este evento</small>
            </div>
        )

        if(payMetodos.length === 0) return (
            <tr><td className = "text-danger" align = "center" style = {{padding: 10}}>Este evento no posee un método de pago disponible</td></tr>
        )

        let i = 0;
        
        let colorBg = (option) => {
            if (option === metodoSelect) return {
                backgroundColor: "#e4f0ff",
                color: "#0665ae",
                borderRadius: 4, 
            }

            return {
                borderRadius: 4, 
            }
        }

        let imagenesPagos = {
            'Pago móvil C2P': Pago_Movil,
            'Pago móvil': Pago_Movil,
            "Efectivo Bs.":  LogoEfectivo,
            "Efectivo USD":LogoEfectivo,
            "Tarjeta de crédito Bs.":logoCreditCard2,
            "Tarjeta de crédito USD":logoCreditCard2,
            "Tarjeta de débito Mercantil" : logoCreditCard2,
            "Transferencia bancaria": bank_transfer,
            "Zelle": logoZelle,
        }        

        return (
            pagosDisp.filter(option => option !== 'Multipagos').map(option => {
                i++;
                return (<tr key = {i} style = {{padding: 10, cursor: "pointer", border: 0}} onClick = {() => {
                    totalPay = 0;
                    setMetodoSelect(option)
                }} title = {`Selecciona ${option} como método de pago`}>
                <td style={colorBg(option)}>
                <img src={imagenesPagos[option] || ""} alt={option} style={{width:'60px'}}/> {option}
                </td>
            </tr>)
            })
        )
    }

    let selectFile = (item) => (e) => {
        let file = document.getElementById(item);
        let archivo = file.value;

        if (archivo === ""){
            toast.error("No has seleccionado ningún archivo",{autoClose: 4000});
            return null
        }

        let extension = archivo.split('.').pop();
        let ext = extension.toLowerCase();
        let fic = archivo.split('\\');
        let nombre = fic[fic.length -1];
        let size = file.files[0].size;

        if (ext === "jpg" || ext === "jpeg" || ext === "png"){
            let msjSize = formatBytes(size);
            if (size > 1000000) {
                /* Comprimir el archivo */
                let fil = file.files[0];
                new Compressor(fil, {
                    quality: 0.2,
                    convertSize: 1000000,
                    success(result) {
                        /* Convertir a Base 64 */
                        let reader = new FileReader();
                        reader.readAsDataURL(result);

                        reader.onloadend = function() {
                            setFile1(reader.result)
                        }
                    },
                    error(err) {
                        toast.error("La imagen es muy grande y no pudo comprimirse",{autoClose: 4000});
                        document.getElementById(item).value = "";
                    }
                })
            }else{
                /* Convertir a Base 64 */
                let reader = new FileReader();
                reader.readAsDataURL(file.files[0]);

                reader.onloadend = function() {
                    setFile1(reader.result)
                }
            }
        }else{
            toast.error("No has seleccionado un archivo de imagen válido",{autoClose: 4000});
            document.getElementById(item).value = "";
            return null
        }
    }

    let metodoSeleccionado = () => {
        if(metodoSelect === "") return (
            <tr><td className = "text-danger" align = "center" style = {{padding: 10}}><strong><FontAwesomeIcon icon = {faExclamationCircle}/> Selecciona un método de pago</strong></td></tr>
        )

        let ContentData = JSON.parse(sessionStorage.getItem("ContentDataCart"));
        let moneda = currency.moneda;
        if (ContentData === null || ContentData.length === 0) return null;
        
        let totalPago = () => {
            let cant = 0;

            let ContentImpuestos =  JSON.parse(sessionStorage.getItem("ContentDataImpuesto"));
            let impuestos = ContentImpuestos.impuesto;

            ContentData.forEach(opt => {
                cant += opt.precio;
            })


            /* ----------------------- */
            let valorImp = 0;
            impuestos.forEach(option => {
                valorImp += parseFloat(valorImpuestoNotFormated(option.tipo, option.valor, cant));
            })
            
            let totalImp = (valorImp).toFixed(2);

            let cantRest = cant - totalImp;

            /* ---------------------- */
            
            return `${precioConvert(cantRest)}`;
        }

        let subTotalEntradas = () => {
            let cant = 0;

            let ContentImpuestos =  JSON.parse(sessionStorage.getItem("ContentDataImpuesto"));
            let impuestos = ContentImpuestos.impuesto;

            ContentData.forEach(opt => {
                cant += opt.precio;
            })

            /* ----------------------- */
            let valorImp = 0;
            impuestos.forEach(option => {
                valorImp += parseFloat(valorImpuestoNotFormated(option.tipo, option.valor, cant));
            })
            
            let totalImp = Math.round(valorImp).toFixed(2);

            let cantRest = cant - totalImp;
            /* ---------------------- */
            
            totalPay = parseFloat(cantRest) + parseFloat(totalImp);

            return `${precioConvert(parseFloat(cantRest) + parseFloat(totalImp))}`;
        }

        let ladoSilla = (string) => {
            let izquierda = string.indexOf("Izquierda");
            let superior = string.indexOf("Superior");
            let derecha = string.indexOf("Derecha");
            let inferior = string.indexOf("Inferior");

            switch (true){
                case (izquierda > 0): return "Izquierda";
                case (superior > 0): return "Superior";
                case (derecha > 0): return "Derecha";
                case (inferior > 0): return "Inferior";
                default: return "";
            }
        }

        let contentCompra = () => {
            let texto = (data) => {
                switch (true){
                    case (data.fila === "N/" && data.columna === "A"):
                        return "Zona Libre";
                    case (data.silla_code === null || data.silla_code === ""):
                        return `ASIENTO ${data.fila}${data.columna}`;
                    default:
                        return `MESA ${data.fila}${data.columna} - SILLA ${data.silla}`
                }

                /* if (data.silla_code === null || data.silla_code === "") return `ASIENTO ${data.fila}${data.columna}`;

                return `MESA ${data.fila}${data.columna} - SILLA ${ladoSilla(data.silla_code)} ${data.silla_num}` */
            }

            return (
                ContentData.map(option => (
                    <tr key = {option.id}>
                        <td style = {{padding: "7px 7px 7px 7px"}} colSpan = {2} width = "80%"><strong>{option.asiento} - {(texto(option)).toLocaleUpperCase()}</strong></td>
                        <td style = {{padding: "7px 7px 7px 7px"}} width = "20%" align = "right">{/* <strong>{moneda} {option.precio}</strong> */}</td>
                    </tr>
                ))
            )
        }

        let precioConvert = (precio) => {
            let ContentCurrency = JSON.parse(sessionStorage.getItem("ContentDataCurrency"));

            if (ContentCurrency.moneda !== monedaSelect.value){
                /* Hacer la conversión */
                let cant = precio * ContentCurrency.valor_1usd_moneda_local;
                return `${monedaSelect.value} ${cant.toFixed(2)}`
            }else{
                return `${ContentCurrency.moneda} ${precio}`;
            }
        }

        let contentCompraBancoLocal = () => {

            let texto = (data) => {
                switch (true){
                    case (data.fila === "N/" && data.columna === "A"):
                        return "Zona Libre";
                    case (data.silla_code === null || data.silla_code === ""):
                        return `ASIENTO ${data.fila}${data.columna}`;
                    default:
                        return `MESA ${data.fila}${data.columna} - SILLA ${data.silla}`
                }

                /* if (data.silla_code === null || data.silla_code === "") return `ASIENTO ${data.fila}${data.columna}`;

                return `MESA ${data.fila}${data.columna} - SILLA ${ladoSilla(data.silla_code)} ${data.silla_num}` */
            }
            
            return (
                ContentData.map(option => (
                    <tr key = {option.id}>
                        <td style = {{padding: "7px 7px 7px 7px"}} colSpan = {2} width = "80%"><strong>{option.asiento} - {(texto(option)).toLocaleUpperCase()}</strong></td>
                        <td style = {{padding: "7px 7px 7px 7px"}} width = "20%" align = "right">{/* <strong>{precioConvert(option.precio)}</strong> */}</td>
                    </tr>
                ))
            )
        }
        
        let tipoImpuesto = (valor) => {
            if (valor === "porcentaje") return "%"
            return moneda
        }

        let valorImpuesto = (tipo, valor, totalV) => {
            let dataCurrency = JSON.parse(sessionStorage.getItem('ContentDataCurrency'));

            if (tipo === "porcentaje"){
                let total = (valor * totalV) / 100;

                /* let totalP = totalPay + total;
                totalPay = totalP */

                if(monedaSelect.value === 'Bs.'){
                    let Newtotal = total * dataCurrency.valor_1usd_moneda_local
                    return `${monedaSelect.value} ${Newtotal.toFixed(2)}`
                }

                return `${monedaSelect.value} ${total.toFixed(2)}`
            }else{
                /* let totalP = totalPay + valor;
                totalPay = totalP */

                return `${monedaSelect.value} ${valor.toFixed(2)}`
            }
        }

        let valorImpuestoNotFormated = (tipo, valor, totalV) => {
            if (tipo === "porcentaje"){
                let total = (valor * totalV) / 100;

                return `${total.toFixed(2)}`
            }else{
                return `${valor.toFixed(2)}`
            }
        }

        let valorImpuestoBancoLocal = (tipo, valor, totalV) => {
            if (tipo === "porcentaje"){
                let total = (valor * totalV) / 100;

                /* let totalP = totalPay + total;
                totalPay = totalP */

                return `${precioConvert(total)}`
            }else{
                /* let totalP = totalPay + valor;
                totalPay = totalP */

                return `${precioConvert(valor)}`
            }
        }

        let contentImpuestos = () => {
            let ContentImpuestos =  JSON.parse(sessionStorage.getItem("ContentDataImpuesto"));
            let impuestos = ContentImpuestos.impuesto;

            if (impuestos.length === 0) return(
                <tr>
                    <td colSpan = {3} style = {{padding: 5}}>No Aplica</td>
                </tr>
            )

            let cant = 0;
            ContentData.forEach(opt => cant += opt.precio);

            return(
                impuestos.map(option => (
                    <tr key = {option.id}>
                        <td colSpan = {2} align = "left" className = "text-danger" width = "80%" style = {{padding: "7px 7px 7px 17px"}}>{option.nombre_impuesto.nombre} {option.valor} {tipoImpuesto(option.tipo)}</td>
                        <td width = "20%" align = "right" className = "text-danger" style = {{padding: "7px 7px 7px 7px"}}>{valorImpuesto(option.tipo, option.valor, cant)}</td>
                    </tr>
                ))  
            )
        }

        let contentImpuestosBancoLocal = () => {
            let ContentImpuestos =  JSON.parse(sessionStorage.getItem("ContentDataImpuesto"));
            let impuestos = ContentImpuestos.impuesto;

            if (impuestos.length === 0) return(
                <tr>
                    <td colSpan = {3} style = {{padding: 5}}>No Aplica</td>
                </tr>
            )

            let cant = 0;
            ContentData.forEach(opt => cant += opt.precio);

            return(
                impuestos.map(option => (
                    <tr key = {option.id}>
                        <td colSpan = {2} align = "left" width = "80%" style = {{padding: "7px 7px 7px 17px"}}>{option.nombre_impuesto.nombre} {option.valor} {tipoImpuesto(option.tipo)}</td>
                        <td width = "20%" align = "right" style = {{padding: "7px 7px 7px 7px"}}>{valorImpuestoBancoLocal(option.tipo, option.valor, cant)}</td>
                    </tr>
                ))  
            )
        }

        let contentImpTaquilla = () => {
            let ContentImpuestos =  JSON.parse(sessionStorage.getItem("ContentDataImpuesto"));
            let impuestosTaquilla = ContentImpuestos.impuestoTaquilla;
            let servTaquilla = ContentImpuestos.servTaquilla;

            if (impuestosTaquilla.length === 0) return null;

            impuestosTaquilla.forEach(i => {
                totalPay += parseFloat(valorImpuestoNotFormated(i.tipo, i.valor, servTaquilla.valor));
            })

            return(
                impuestosTaquilla.map(option => (
                    <tr key = {option.id}>
                        <td colSpan = {2} align = "lefft" width = "80%" style = {{padding: "7px 7px 7px 7px"}}>{option.nombre_impuesto.nombre} {option.valor} {tipoImpuesto(option.tipo)}</td>
                        <td width = "20%" align = "right" style = {{padding: "7px 7px 7px 7px"}}>{valorImpuesto(option.tipo, option.valor, servTaquilla.valor)}</td>
                    </tr>
                ))  
            )
        }

        let contentImpTaquillaBancoLocal = () => {
            let ContentImpuestos =  JSON.parse(sessionStorage.getItem("ContentDataImpuesto"));
            let impuestosTaquilla = ContentImpuestos.impuestoTaquilla;
            let servTaquilla = ContentImpuestos.servTaquilla;

            if (impuestosTaquilla.length === 0) return null;

            impuestosTaquilla.forEach(i => {
                totalPay += parseFloat(valorImpuestoNotFormated(i.tipo, i.valor, servTaquilla.valor));
            })

            return(
                impuestosTaquilla.map(option => (
                    <tr key = {option.id}>
                        <td colSpan = {2} align = "lefft" width = "80%" style = {{padding: "7px 7px 7px 7px"}}>{option.nombre_impuesto.nombre} {option.valor} {tipoImpuesto(option.tipo)}</td>
                        <td width = "20%" align = "right" style = {{padding: "7px 7px 7px 7px"}}>{valorImpuestoBancoLocal(option.tipo, option.valor, servTaquilla.valor)}</td>
                    </tr>
                ))  
            )
        }

        let contentServTaquilla = () => {
            let ContentImpuestos =  JSON.parse(sessionStorage.getItem("ContentDataImpuesto"));
            let servTaquilla = ContentImpuestos.servTaquilla;
            let aplicable = Boolean(servTaquilla.is_aplicable);

            let dataCart = JSON.parse(sessionStorage.getItem('ContentDataCart'));

            let precio = dataCart[0].precio * dataCart.length;

            let subtotalserv = precio * servTaquilla.valor / 100;

            let dataCurrency = JSON.parse(sessionStorage.getItem('ContentDataCurrency'));

            let totalServ = subtotalserv
            
            if (aplicable === true){
                let totalP = totalPay + subtotalserv;


                if(monedaSelect.value === 'Bs.'){
                    totalServ = subtotalserv * dataCurrency.valor_1usd_moneda_local
                    totalP = totalP * dataCurrency.valor_1usd_moneda_local
                }

                totalPay = totalP

                return (
                    <>
                        <tr>
                            <td colSpan = {2} align = "left"  style = {{padding: "7px 7px 7px 7px"}}><strong>Servicios de taquilla {servTaquilla.valor}%</strong></td>
                            <td align = "right" style = {{padding: "7px 7px 7px 7px"}}> {monedaSelect.value} {totalServ.toFixed(2)}</td>
                        </tr>
                        {contentImpTaquilla()}
                    </>
                )
            }else{
                return null;
            }
        }

        let contentServTaquillaBancoLocal = () => {
            let ContentImpuestos =  JSON.parse(sessionStorage.getItem("ContentDataImpuesto"));
            let servTaquilla = ContentImpuestos.servTaquilla;
            let aplicable = Boolean(servTaquilla.is_aplicable);
            
            if (aplicable === true){
                let totalP = totalPay + servTaquilla.valor;
                totalPay = totalP

                return (
                    <>
                        <tr>
                            <td colSpan = {2} align = "left"  style = {{padding: "7px 7px 7px 7px"}}><strong>Servicios de taquilla</strong></td>
                            <td align = "right" style = {{padding: "7px 7px 7px 7px"}}>{precioConvert(servTaquilla.valor)}</td>
                        </tr>
                        {contentImpTaquillaBancoLocal()}
                    </>
                )
            }else{
                return null;
            }
        }

        let totalAPagar = () => {
            return `${monedaSelect.value} ${totalPay.toFixed(2)}`;
        }

        let totalAPagarBancoLocal = () => {
            return `${precioConvert(totalPay)}`;
        }

        let inputFocus = (e) => {
            totalPay = 0;
            setFocused(e.target.name)
        };

        const handleMethodsPayment = (data, userData, totalMultipay, saldoAFavor) => {
            setMultiPayTaqMeth(data);
            setDatos_comprador(userData);
            setTotalMultipay(totalMultipay)
            setSaldoAfavor(saldoAFavor);
        } 


        switch (metodoSelect){
            case "Paypal":
                return (
                    <Table className = "mb-0" hover style = {{fontSize: 10}}>
                        <tbody>
                        <div style={{ overflowY: 'auto', maxHeight: '200px', Width:'100%' }}>
                            {contentCompra()}
                        </div>
                            <tr>
                                <td colSpan = {3} className = "text-primary" align = "center" style = {{padding: 0, paddingTop: 30}}><strong>RESUMEN DE TU COMPRA</strong></td>
                            </tr>
                            <tr>
                                <td colSpan = {2} style = {{padding: 5}} align = "left">
                                    <strong className = "text-info">VALOR DE ENTRADAS:</strong>
                                </td>
                                <td style = {{padding: 5}} align = "right">
                                    <strong className = "text-info">{totalPago()}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan = {3} className = "text-danger" align = "left" style = {{padding: 5}}><strong>Impuestos</strong></td>
                            </tr>
                            {contentImpuestos()}
                            <tr>
                                <td colSpan = {2} align = "left" style = {{padding: 5, paddingTop: 8, background: "#d3d8ef"}}><strong>SUB TOTAL DE ENTRADAS</strong></td>
                                <td align = "right" style = {{padding: 5, paddingTop: 10, background: "#d3d8ef"}}><strong>{subTotalEntradas()}</strong></td>
                            </tr>
                            {contentServTaquilla()}
                            <tr className = "text-info" style = {{fontSize: 14}}>
                                <td colSpan = {2} align = "left">
                                    <strong>TOTAL A PAGAR:</strong>
                                </td>
                                <td align = "right">
                                    <strong>{totalAPagar()}</strong>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                )
            case "Transferencia bancaria":
                return (
                <DetailsPayTransferBank  
                    contentCompra={contentCompra}
                    totalPago={totalPago}
                    contentImpuestos={contentImpuestos}
                    subTotalEntradas={subTotalEntradas}
                    contentServTaquilla={contentServTaquilla}
                    totalAPagar={totalAPagar}
                >
                    <Row>
                        <Col md = {12}>
                            <small>N° de la transacción</small>
                            <Input type = "text" value = {numberTransfer} onChange={(event) => {
                            const newValue = event.target.value;
                            if (!isNaN(newValue)) {
                            setNumberTransfer(newValue);
                            }
                        }} placeholder = "Ej.1234567890" />
                        </Col>
                        <Col md = {12} className = "custom-mt-10">
                            <small>Carga aquí el comprobante de pago</small>
                            <Input type="file" id="fileTransfer1" accept = "image/*" onChange = {selectFile("fileTransfer1")}/>
                        </Col>
                    </Row>
                </DetailsPayTransferBank>
                
                )
            case "Pago móvil":
                return (<>
                        <Table className = "mb-0" hover style = {{fontSize: 10}}>
                    <tbody>
                    <div style={{ overflowY: 'auto', maxHeight: '200px', Width:'100%' }}>
                        {contentCompra()}
                    </div>                            <tr>
                            <td colSpan = {3} className = "text-primary" align = "center" style = {{padding: 0, paddingTop: 30}}><strong>RESUMEN DE TU COMPRA</strong></td>
                        </tr>
                        <tr>
                            <td colSpan = {2} style = {{padding: 5}} align = "left">
                                <strong className = "text-info">VALOR DE ENTRADAS:</strong>
                            </td>
                            <td style = {{padding: 5}} align = "right">
                                <strong className = "text-info">{totalPago()}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan = {3} className = "text-danger" align = "left" style = {{padding: 5}}><strong>Impuestos</strong></td>
                        </tr>
                        {contentImpuestos()}
                        <tr>
                            <td colSpan = {2} align = "left" style = {{padding: 5, paddingTop: 8, background: "#d3d8ef"}}><strong>SUB TOTAL DE ENTRADAS</strong></td>
                            <td align = "right" style = {{padding: 5, paddingTop: 10, background: "#d3d8ef"}}><strong>{subTotalEntradas()}</strong></td>
                        </tr>
                        {contentServTaquilla()}
                        <tr className = "text-info" style = {{fontSize: 14}}>
                            <td colSpan = {2} align = "left">
                                <strong>TOTAL A PAGAR:</strong>
                            </td>
                            <td align = "right">
                                <strong>{totalAPagar()}</strong>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <div className="mt-4" style={{boxShadow:'5px 5px 5px 5px gray'}}>
                    <div style={{backgroundColor:"#077bd3", padding:'1vh'}} className="text-center"><h5 style={{ color:'white'}}><strong>Datos del pago móvil</strong></h5></div>
                    <Row style={{marginLeft:"3vh", marginTop:"1vh"}}>
                        <Col>
                            <p>Número de teléfono: </p>
                            <p>RIF del comercio: </p>
                            <p>Banco: </p>
                        </Col>
                        <Col>
                            <p>(0412) 0715875</p>
                            <p><strong>J-</strong>296717052</p>
                            <p>Banplus (0174)</p>
                        </Col>
                    </Row>
                </div>
                <p className="mt-3 text-dark bold">
                    <strong>
                        Cuando realice el pago móvil, debe especificar la cantidad exacta del monto de la transacción, incluyendo los céntimos. Cualquier discrepancia en el monto, puede ocasionar que el sistema no reconozca el pago 
                    </strong>
                </p>

                </>)
            case "Pago móvil C2P":                    
            return (<>
                    <Table className = "mb-0" hover style = {{fontSize: 10}}>
                <tbody>
                <div style={{ overflowY: 'auto', maxHeight: '200px', Width:'100%' }}>
                    {contentCompra()}
                </div>                            <tr>
                        <td colSpan = {3} className = "text-primary" align = "center" style = {{padding: 0, paddingTop: 30}}><strong>RESUMEN DE TU COMPRA</strong></td>
                    </tr>
                    <tr>
                        <td colSpan = {2} style = {{padding: 5}} align = "left">
                            <strong className = "text-info">VALOR DE ENTRADAS:</strong>
                        </td>
                        <td style = {{padding: 5}} align = "right">
                            <strong className = "text-info">{totalPago()}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan = {3} className = "text-danger" align = "left" style = {{padding: 5}}><strong>Impuestos</strong></td>
                    </tr>
                    {contentImpuestos()}
                    <tr>
                        <td colSpan = {2} align = "left" style = {{padding: 5, paddingTop: 8, background: "#d3d8ef"}}><strong>SUB TOTAL DE ENTRADAS</strong></td>
                        <td align = "right" style = {{padding: 5, paddingTop: 10, background: "#d3d8ef"}}><strong>{subTotalEntradas()}</strong></td>
                    </tr>
                    {contentServTaquilla()}
                    <tr className = "text-info" style = {{fontSize: 14}}>
                        <td colSpan = {2} align = "left">
                            <strong>TOTAL A PAGAR:</strong>
                        </td>
                        <td align = "right">
                            <strong>{totalAPagar()}</strong>
                        </td>
                    </tr>
                </tbody>
            </Table>
            </>)
            case "Banesco Pagos": return (
                <>
                    <Table className = "mb-0" hover style = {{fontSize: 10}}>
                        <tbody>
                            {contentCompraBancoLocal()}
                            <tr>
                                <td colSpan = {3} className = "text-primary" align = "center" style = {{padding: 0, paddingTop: 30}}><strong>RESUMEN DE TU COMPRA</strong></td>
                            </tr>
                            <tr>
                                <td colSpan = {2} style = {{padding: 5}} align = "left">
                                    <strong className = "text-info">VALOR DE ENTRADAS:</strong>
                                </td>
                                <td style = {{padding: 5}} align = "right">
                                    <strong className = "text-info">{totalPago()}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan = {3} className = "text-danger" align = "left" style = {{padding: 5}}><strong>Impuestos</strong></td>
                            </tr>
                            {contentImpuestosBancoLocal()}
                            <tr>
                                <td colSpan = {2} align = "left" style = {{padding: 5, paddingTop: 8, background: "#d3d8ef"}}><strong>SUB TOTAL DE ENTRADAS</strong></td>
                                <td align = "right" style = {{padding: 5, paddingTop: 10, background: "#d3d8ef"}}><strong>{subTotalEntradas()}</strong></td>
                            </tr>
                            {contentServTaquillaBancoLocal()}
                            <tr className = "text-info" style = {{fontSize: 14}}>
                                <td colSpan = {2} align = "left">
                                    <strong>TOTAL A PAGAR:</strong>
                                </td>
                                <td align = "right">
                                    <strong>{totalAPagarBancoLocal()}</strong>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </>
            );
            case "Zelle": return (
                <>
                    <Table className = "mb-0" hover style = {{fontSize: 10}}>
                        <tbody>
                        <div style={{ overflowY: 'auto', maxHeight: '100px', Width:'100%' }}>
                            {contentCompra()}
                        </div>                            <tr>
                                <td colSpan = {3} className = "text-primary" align = "center" style = {{padding: 0, paddingTop: 30}}><strong>RESUMEN DE TU COMPRA</strong></td>
                            </tr>
                            <tr>
                                <td colSpan = {2} style = {{padding: 5}} align = "left">
                                    <strong className = "text-info">VALOR DE ENTRADAS:</strong>
                                </td>
                                <td style = {{padding: 5}} align = "right">
                                    <strong className = "text-info">{totalPago()}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan = {3} className = "text-danger" align = "left" style = {{padding: 5}}><strong>Impuestos</strong></td>
                            </tr>
                            {contentImpuestos()}
                            <tr>
                                <td colSpan = {2} align = "left" style = {{padding: 5, paddingTop: 8, background: "#d3d8ef"}}><strong>SUB TOTAL DE ENTRADAS</strong></td>
                                <td align = "right" style = {{padding: 5, paddingTop: 10, background: "#d3d8ef"}}><strong>{subTotalEntradas()}</strong></td>
                            </tr>
                            {contentServTaquilla()}
                            <tr className = "text-info" style = {{fontSize: 14}}>
                                <td colSpan = {2} align = "left">
                                    <strong>TOTAL A PAGAR:</strong>
                                </td>
                                <td align = "right">
                                    <strong>{totalAPagar()}</strong>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className = "alert custom-mt-20" style = {{backgroundColor: "#8700d6", color: "#ffffff", marginTop: 20}}>
                        <Row>
                            <Col md = {9}>
                                <div style = {{marginTop: 8}}><strong>Formulario de registro de pago</strong></div>
                            </Col>
                            <Col md = {3} align = "right">
                                <img src = {logoZelle2} alt = "logo-zelle" height = "auto" width = "70px" className = "img img-fluid"/>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col className = "custom-mt-10" md = {2}>
                            <strong>PASO 1:</strong>
                        </Col>
                        <Col className = "custom-mt-10" md = {10}>
                            <p>Debes enviar un pago via Zelle a <strong>enfoquepromocionesyeventos@gmail.com</strong> por el monto <strong className = "text-danger">{totalAPagar()}</strong></p>
                        </Col>
                        <Col className = "custom-mt-10" md = {2}>
                            <strong>PASO 2:</strong>
                        </Col>
                        <Col className = "custom-mt-10" md = {10}>
                            <p>¡Es necesario que registre su pago para que pueda recibir el ticket electrónico en su correo!</p>
                        </Col>
                        <Col className = "custom-mt-10" md = {2}>
                            <strong>PASO 3:</strong>
                        </Col>
                        <Col className = "custom-mt-10" md = {10}>
                            <p>Una vez hayas completado el registro del pago, es necesario que nuestro equipo lo verifique.</p>
                            <p>Recibirás tu boleto por correo o podrás buscarlo en la sección <strong>Mis Compras</strong></p>
                        </Col>
                        <Col md = {12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md = {5}>
                                            <small>N° de la transacción</small>
                                            <Input type = "text" value = {transaccionZelle} onChange={(event) => {
                                const newValue = event.target.value;
                                setTransaccionZelle(newValue);
                            }} placeholder = "Ej.1234567890" />
                                        </Col>
                                        <Col md = {7}>
                                            <small>Nombre de la persona que realizó el pago Zelle </small>
                                            <Input type = "text" maxLength={30} value = {nombreZelle} onChange={(event) => {
                                        const newValue = event.target.value;
                                        if (/^[a-zA-Z ]*$/g.test(newValue)) {
                                            setNombreZelle(newValue);
                                        }
                                    }}placeholder = "Nombre del titular Zelle" />
                                        </Col>
                                        <Col md = {12} className = "custom-mt-10">
                                            <small>Correo de la persona que realizó el pago Zelle</small>
                                            <Input type = "email" value = {emailZelle} maxLength={40} onChange = {(e) => setEmailZelle(e.target.value)} placeholder = "Correo del titular Zelle" />
                                        </Col>
                                        <Col md = {12} className = "custom-mt-10">
                                            <small>Carga aquí el comprobante de pago</small>
                                            <Input type = "file" id = "fileZelle1" accept = "image/*" onChange = {selectFile("fileZelle1")}/>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </>
            );
            case "Tarjeta de Crédito":
                return (
                    <>
                        <Table className = "mb-0" hover style = {{fontSize: 10}}>
                            <tbody>
                            <div style={{ overflowY: 'auto', maxHeight: '200px', Width:'100%' }}>
                            {contentCompra()}
                        </div>                                <tr>
                                    <td colSpan = {3} className = "text-primary" align = "center" style = {{padding: 0, paddingTop: 30}}><strong>RESUMEN DE TU COMPRA</strong></td>
                                </tr>
                                <tr>
                                    <td colSpan = {2} style = {{padding: 5}} align = "left">
                                        <strong className = "text-info">VALOR DE ENTRADAS:</strong>
                                    </td>
                                    <td style = {{padding: 5}} align = "right">
                                        <strong className = "text-info">{totalPago()}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan = {3} className = "text-danger" align = "left" style = {{padding: 5}}><strong>Impuestos</strong></td>
                                </tr>
                                {contentImpuestos()}
                                <tr>
                                    <td colSpan = {2} align = "left" style = {{padding: 5, paddingTop: 8, background: "#d3d8ef"}}><strong>SUB TOTAL DE ENTRADAS</strong></td>
                                    <td align = "right" style = {{padding: 5, paddingTop: 10, background: "#d3d8ef"}}><strong>{subTotalEntradas()}</strong></td>
                                </tr>
                                {contentServTaquilla()}
                                <tr className = "text-info" style = {{fontSize: 14}}>
                                    <td colSpan = {2} align = "left">
                                        <strong>TOTAL A PAGAR:</strong>
                                    </td>
                                    <td align = "right">
                                        <strong>{totalAPagar()}</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <Row style = {{marginTop: 60}}>
                            <Col md = {5}>
                                <Row>
                                    <Col md = {12}>
                                        <strong style = {{fontSize: 14}}><strong>Datos de la tarjeta</strong></strong>
                                    </Col>
                                    <Col md = {12} className = "custom-mt-10">
                                        <Input type = "tel" name = "number" onFocus = {(e) => inputFocus(e)} value = {cardNumber} onChange = {(e) => setCardNumber(formatCreditCardNumber(e.target.value))} pattern = "[\d| ]{16,22}" placeholder = "Numero de la Tarjeta"/>
                                    </Col>
                                    <Col md = {12} className = "custom-mt-10">
                                    <Input type = "text" name = "name" onFocus = {(e) => inputFocus(e)} maxLength = '20' value = {cardName} placeholder = "titular"  onChange={(event) => {
                                        const newValue = event.target.value;
                                        if (/^[a-zA-Z ]*$/g.test(newValue)) {
                                            setCardName(newValue);
                                        }
                                    }}/>
                                    
                                    </Col>
                                    <Col md = {12} className = "custom-mt-10">
                                    <Input placeholder = "Identificacion" name = "ci" type = "text" maxLength = '9'  value = {ciNumber} onChange={(event) => {
                                        const newValue = event.target.value;
                                        if (!isNaN(newValue)) {
                                            setCiNumber(newValue);
                                        }
                                    }}/>
                                    </Col>
                                    <Col md = {7} className = "custom-mt-10">
                                        <Input type = "tel" name = "expiry" onFocus = {(e) => inputFocus(e)} value = {cardDate} onChange = {(e) => setCardDate(formatExpirationDate(e.target.value))} pattern = "\d\d/\d\d" placeholder = "Válido Hasta"/>
                                    </Col>
                                    <Col md = {5} className = "custom-mt-10">
                                        <Input type = "tel" name = "cvc" onFocus = {(e) => inputFocus(e)} onBlur = {() => setFocused("")} value = {cardCvc} onChange = {(e) => setCardCvc(formatCVC(e.target.value))} pattern = "\d{3,4}" placeholder = "CVC"/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md = {7} className = "custom-mt-media-10">
                                <Cards
                                    cvc = {cardCvc}
                                    expiry = {cardDate}
                                    focused = {focused}
                                    name = {cardName}
                                    number = {cardNumber}
                                    locale = {{valid: 'Válido hasta'}}
                                    placeholders = {{name: 'AQUI TU NOMBRE'}}
                                />
                            </Col>
                            
                        </Row>
                    </>
                )
            case "Efectivo":
                const user = JSON.parse(sessionStorage.getItem('ContentData'))
                const utype = user.U_TYPE;
                if (utype === 4){
                    return (
                        <>
                        <Table className = "mb-0" hover style = {{fontSize: 10}}>
                            <tbody>
                            <div style={{ overflowY: 'auto', maxHeight: '200px', Width:'100%' }}>
                            {contentCompra()}
                        </div>                                <tr>
                                    <td colSpan = {3} className = "text-primary" align = "center" style = {{padding: 0, paddingTop: 30}}><strong>RESUMEN DE TU COMPRA</strong></td>
                                </tr>
                                <tr>
                                    <td colSpan = {2} style = {{padding: 5}} align = "left">
                                        <strong className = "text-info">VALOR DE ENTRADAS:</strong>
                                    </td>
                                    <td style = {{padding: 5}} align = "right">
                                        <strong className = "text-info">{totalPago()}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan = {3} className = "text-danger" align = "left" style = {{padding: 5}}><strong>Impuestos</strong></td>
                                </tr>
                                {contentImpuestos()}
                                <tr>
                                    <td colSpan = {2} align = "left" style = {{padding: 5, paddingTop: 8, background: "#d3d8ef"}}><strong>SUB TOTAL DE ENTRADAS</strong></td>
                                    <td align = "right" style = {{padding: 5, paddingTop: 10, background: "#d3d8ef"}}><strong>{subTotalEntradas()}</strong></td>
                                </tr>
                                {contentServTaquilla()}
                                <tr className = "text-info" style = {{fontSize: 14}}>
                                    <td colSpan = {2} align = "left">
                                        <strong>TOTAL A PAGAR:</strong>
                                    </td>
                                    <td align = "right">
                                        <strong>{totalAPagar()}</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <Row>
                            <Col md = {12}>
                                <strong style = {{fontSize: 14}}><strong>Datos del cliente</strong></strong>
                            </Col>
                            <Col md = {12} className = "custom-mt-10">
                                <Input type = "text" name = "name" onFocus = {(e) => inputFocus(e)} maxLength = {30} value = {cashNombre} onChange={(event) => {
                                        const newValue = event.target.value;
                                        if (/^[a-zA-Z ]*$/g.test(newValue)) {
                                            setCashNombre(newValue);
                                        }
                                    }} placeholder = "Nombre y apellido"/>
                            </Col>
                            <Col md = {12} className = "custom-mt-10">
                                <Input type = "text" name = "name" onFocus = {(e) => inputFocus(e)} maxLength={40} value = {cashCorreo} onChange = {(e) => setCashCorreo(e.target.value)} placeholder = "Correo electrónico"/>
                            </Col>
                            <Col md = {12} className = "custom-mt-10">
                                <Input type = "text" name = "name" onFocus = {(e) => inputFocus(e)} maxLength = {9} value = {cashIdentificacion} onChange={(event) => {
                                const newValue = event.target.value;
                                if (!isNaN(newValue)) {
                                setCashIdentificacion(newValue);
                                }
                            }} placeholder = "Identificación"/>
                            </Col>

                            <Col md = {12} className = "custom-mt-10">
                                <Input type = "text" name = "name" onFocus = {(e) => inputFocus(e)} maxLength = {12} value = {cashTlf} onChange={(event) => {
                                const newValue = event.target.value;
                                if (!isNaN(newValue)) {
                                setCashTlf(newValue);
                                }
                            }} placeholder = "Teléfono"/>
                            </Col>                                    
                        </Row>
                        </>  
                )
                }else{
                    let text = ContentDataEvent.evento_info.taquilla_fisica;

                    return(
                        <div>
                            <p>
                                {(!text || text === null) ? <div>Si desea comprar en efectivo, debe acercarse a la taquilla el dia de el evento</div> : text}
                                
                            </p>
                        </div>
                    )
                }

            case "Multipagos":
            return (
                <>
                    <div>
                        <MultiPaymentTaquilla contentCompra={contentCompra}
                        totalPago={totalPago}
                        contentImpuestos={contentImpuestos}
                        subTotalEntradas={subTotalEntradas}
                        contentServTaquilla={contentServTaquilla}
                        totalAPagar={totalAPagar}
                        handleMethodsPayment={handleMethodsPayment} // Pasar la función como prop
                        onImageChange={handleImgDataFromChild} />
                    </div>
                        {/* <DetailsPayMultipayment contentCompra={contentCompra}
                        totalPago={totalPago}
                        contentImpuestos={contentImpuestos}
                        subTotalEntradas={subTotalEntradas}
                        contentServTaquilla={contentServTaquilla}
                        totalAPagar={totalAPagar}
                        onFormDataChange={handleFormDataFromChild} // Pasar la función como prop
                        onImageChange={handleImgDataFromChild}
                    ></DetailsPayMultipayment> */}
                </>
            )

            default: return null;
        }
    }

    const customStylesSelect = {
        menu: styles => ({ ...styles, color: "#000", fontSize: 14 }),
        multiValue: styles => ({ ...styles, backgroundColor: "#ccc" }),
        multiValueLabel: styles => ({ ...styles, color: "#000" }),
        placeholder: styles => ({ ...styles, fontSize: 14, color: "#b4bcc3" }),
    };

    let modal = () => {
        let ContentDataEvent = JSON.parse(sessionStorage.getItem("ContentDataEvent"));

        if (openModal === false) return null;
        return (
            <ContentModal2
                isOpen = {openModal}
                title = "Condiciones del evento"
                size = "lg"
                content = {<div style = {{height: "35vh", overflowY: "auto"}} dangerouslySetInnerHTML = {{ __html: ContentDataEvent.evento_info.condiciones }}/>}
                close = {() => setOpenModal(false)}
            />
        )
    }
    

    return (
        <>
            <Row>
                <Col md = {12} style = {{marginTop: 60}}>
                    <Card className = "main-card mb-3" style = {{borderRadius: 30}}>
                        <CardTitle className = "twitter">
                            <strong>Selecciona el método de pago</strong>
                        </CardTitle>
                        <CardBody style = {{padding: 40}}>
                            <Row>
                            <Col md = {12}>
                                            <Row>
                                                <Col md={12} style = {{paddingTop: 5}}>
                                                    <strong>Selecciona la moneda:</strong>
                                                </Col>
                                                <br />
                                                <Col md = {4}>
                                                    <Select
                                                        isSearchable = {true} styles = {customStylesSelect}
                                                        className = "basic-multi-select"
                                                        onChange = {changeMoneda} options = {monedas}
                                                        noOptionsMessage = {() => { return "Sin Opciones" }}
                                                        value = {monedaSelect}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        {
                                            monedaSelect.value === 'Bs. y USD' ? null :
                                            <Col md = {4}>
                                            <Row>
                                                <Col md = {12} className = "custom-mt-10">
                                                    <div style = {{fontSize: 20, paddingTop: 20, paddingBottom: 20}}><strong>Tipos de pago disponibles:</strong></div>
                                                    <Table className = "mb-0" hover>
                                                        <tbody>
                                                            {metodos()}
                                                        </tbody>
                                                    </Table>
                                                    <Col md = {7}>
                                            <strong>Método seleccionado: <span className = "text-info">{metodoSelect}</span></strong>
                                        </Col>
                                                </Col>
                                            </Row>
                                        </Col>
                                        }
                                <Col md = { monedaSelect.value === 'Bs. y USD' ? 12 : 8} style = {{paddingLeft: 60, marginTop:20}}>
                                    {metodoSeleccionado()}
                                    <Row>
                                        <Col md = {12} align = "left" className = "custom-mt-10" style = {{marginLeft: 25}}><Input type = "checkbox" style = {{cursor: "pointer"}} value = {check} onClick = {() => {
                                            totalPay = 0;
                                            setCheck(!check)
                                        }}/>
                                            <span align = "center" style = {{cursor: "pointer"}} title = "Ver condiciones para este evento">
                                                <small onClick = {() => {
                                                    totalPay = 0;
                                                    setOpenModal(true)
                                                }}>
                                                    Acepto las condiciones del evento (para más detalles, hacer click aquí)
                                                </small>
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter>
                            <Stats step = {3} {...props} tipo = "SelectPayMet" monedaSelect = {monedaSelect.value} disPay = {check} disabled = {false} metodoSelect = {metodoSelect} props = {props} contentCard = {{
                                data1: cardCvc,
                                data2: cardDate,
                                data3: cardName,
                                data4: cardNumber,
                                ci_number: ciNumber // ci_number
                            }}
                            contentZelle = {{
                                fileZelle: "fileZelle1",
                                transaccionZelle: transaccionZelle,
                                nombreZelle: nombreZelle,
                                emailZelle: emailZelle,
                                fileZelleSrc: file1
                            }}
                            contentTransfer = {{
                                fileTransfer: "fileTransfer1",
                                numberTransfer: numberTransfer,
                                fileTransferJg: file1
                            }}
                            contentMultipagos = {{
                                images: multiPayImgs,
                                form: multiPayForm
                            }}
                            contentEfectivo = {{
                                nombre: cashNombre,
                                correo: cashCorreo,
                                identificacion: cashIdentificacion,
                                tlf: cashTlf
                            }}
                            ContentMultiPayTaq = {{
                                metodos: multiPayTaquMeth,
                                datosComprador: datos_comprador,
                                monto: totalMultipay,
                                saldoAFavor: saldoAFavor
                            }}
                            />
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            {modal()}
        </>
    )
}