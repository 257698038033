import React, {useEffect, useState} from "react";
import { toast } from 'react-toastify';
import { Badge, Input, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faTimesCircle, faCircleNotch, faCheck, faEnvelope, faFilePdf, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import ContentModal2 from "components/Modals/ContentModal2";

import { GenerarTicketPdf, SendTicket } from "functions/EventosFunctions";

const RetiroTicket = (props) => {
    const [ContentData, setContentData]     = useState(JSON.parse(sessionStorage.getItem("ContentData")))
    const [botonAc, setBotonAc]             = useState({ icon: faCogs, text: "Acciones", spin: false, disabled: false })
    const [dropdownOpen, setDropdownOpen]   = useState(false)
    const [btnSend, setBtnSend]             = useState({icon: faEnvelope, text: "Enviar por Correo", spin: false, disabled: false})
    const [btnPdf, setBtnPdf]               = useState({icon: faFilePdf, text: "Generar Ticket .Pdf", spin: false, disabled: false})
    const [botonOk, setBotonOk]             = useState({icon: faPaperPlane, text: "Enviar", spin: false, disabled: false})
    const [src, setSrc]                     = useState("")
    const [openModalPdf, setOpenModalPdf]   = useState(false)
    const [openModalSend, setOpenModalSend] = useState(false)
    const [email, setEmail]                 = useState("")

    useEffect(() => {
        setContentData(JSON.parse(sessionStorage.getItem("ContentData")));
    }, []);

    let generarPdf = () => {
        if (btnPdf.disabled === true || btnSend.disabled === true) return null;

        setBtnPdf({icon: faCircleNotch, text: "Generar Ticket .Pdf", spin: true, disabled: true})
        setBotonAc (state => ({...state, icon: faCircleNotch, spin: true, disabled: true}))

        let idTicketVendido = props.data.id;
        GenerarTicketPdf(idTicketVendido, props.data.id_evento, (resp) => {
            setSrc(resp);
            setOpenModalPdf(true);
            setBtnPdf({icon: faFilePdf, text: "Generar Ticket .Pdf", spin: false, disabled: false})
            setBotonAc (state => ({...state, icon: faCogs, spin: false, disabled: false}))
        })
    }

    let sendPdf = () => {
        if (btnPdf.disabled === true || btnSend.disabled === true) return null;
        let expReg = /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i;

        switch(true){
            case(email === ""):
                toast.error(<small style = {{fontSize: 13}}><FontAwesomeIcon icon = {faTimesCircle}/> Ingresa primero un <strong>correo electrónico</strong> para enviar el ticket</small>);
            break;
            case (expReg.test(email) === false && email.trim() !== ""):
                toast.error(<small style = {{fontSize: 13}}><FontAwesomeIcon icon = {faTimesCircle}/> El campo <strong>email</strong> tiene un formato incorrecto</small>);
            break;
            default:
                setBtnSend({icon: faCircleNotch, text: "Generar Ticket .Pdf", spin: true, disabled: true})
                setBotonOk({icon: faCircleNotch, text: "Enviando", spin: true, disabled: true})
                setBotonAc (state => ({...state, icon: faCircleNotch, spin: true, disabled: true}))
        
                let idTicketVendido = props.data.id;
                SendTicket(idTicketVendido, props.data.id_evento, email, (resp) => {
                    switch (true) {
                        case (resp.status === "error" || resp.status === "error_log"):
                            toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                        break;
                        default:
                            setOpenModalSend(false);
                            setBtnSend({icon: faEnvelope, text: "Enviar por Correo", spin: false, disabled: false})
                            setBotonOk({icon: faPaperPlane, text: "Enviar", spin: false, disabled: false})
                            setBotonAc (state => ({...state, icon: faCogs, spin: false, disabled: false}));
                            setEmail("");
                            props.updList(resp.tickets);
                            toast.info(<small><FontAwesomeIcon icon = {faCheck}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                        break;
                    }
                })
            break;
        }
    }

    let printPdf = () => {
        switch (true){
            case (ContentData === null): return null;
            case (ContentData.U_TYPE === 1): return (
                <DropdownItem style = {{padding: 5}} onClick = {generarPdf}>
                    <FontAwesomeIcon style = {{color: "#c80f0f"}} icon = {btnPdf.icon} spin = {btnPdf.spin}/><small>{btnPdf.text}</small>
                </DropdownItem>
            )
            default: return null;
        }
    }

    let modalPdf = () => {
        if (openModalPdf === false) return null;

        let contentModal = () => (
            <div style = {{height: "74vh"}}>
                <embed
                    type = "application/pdf"
                    src = {src}
                    id = "pdfDocument"
                    width = "100%"
                    height = "100%"
                />
            </div>
        )
        let title;

        switch(props.data.estilo){
            case "mesa": 
                title = `MESA ${props.data.butaca_fila}${props.data.butaca_columna} - SILLA ${(props.data.num_silla_cont) ? props.data.num_silla_cont : ladoSilla(props.data.codigo_silla) + ' ' + props.data.num_silla} `;
            break
             case "libre": 
                title =  "NO APLICA";
            break
            default:  
                title = `ASIENTO ${props.data.butaca_fila}${props.data.butaca_columna}`;
            break
        }



        return (
            <ContentModal2
                isOpen = {openModalPdf}
                title = {"TICKET - "+title}
                size = "xl"
                content = {contentModal()}
                close = {() => {setOpenModalPdf(false)}}
            />
        )
    }

    let modalSend = () => {
        if (openModalSend === false) return null;

        let contentModal = () => (
            <div>
                <Input type = "email" placeholder = "Ingresa el email" value = {email} onChange = {(e) => setEmail(e.target.value)}/>
                <div className = "custom-mt-10" align = "right">
                    <Button color = "primary" disabled = {botonOk.disabled} onClick = {sendPdf}><FontAwesomeIcon icon = {botonOk.icon} spin = {botonOk.spin}/> {botonOk.text}</Button>
                </div>
            </div>
        )

        let title;

        switch(props.data.estilo){
            case "mesa": 
                title = `MESA ${props.data.butaca_fila}${props.data.butaca_columna} - SILLA ${(props.data.num_silla_cont) ? props.data.num_silla_cont : ladoSilla(props.data.codigo_silla) + ' ' + props.data.num_silla} `;
            break
             case "libre": 
                title =  "NO APLICA";
            break
            default:  
                title = `ASIENTO ${props.data.butaca_fila}${props.data.butaca_columna}`;
            break
        }

        return (
            <ContentModal2
                isOpen = {openModalSend}
                title = {`Ticket - ` + title}
                size = "xs"
                content = {contentModal()}
                close = {() => {setOpenModalSend(false)}}
            />
        )

    }

    let enviado = () => {
        if (props.data.enviado_a === null || props.data.enviado_a === "") return null;
        
        return (
            <h6><Badge color = "danger"><FontAwesomeIcon icon = {faPaperPlane}/> Enviado a: {props.data.enviado_a}</Badge></h6>
        );
    }

    let sendTicketBtn = () => {
        if (props.data.enviado_a !== null) return <div style = {{padding: 5, fontSize: 10}} className = "text-muted" align = "center">Ticket Enviado</div>;

        return (
            <DropdownItem style = {{padding: 5}} onClick = {() => setOpenModalSend(true)}><FontAwesomeIcon style = {{color: "#ffc107"}} icon = {btnSend.icon} spin = {btnSend.spin}/><small>{btnSend.text}</small></DropdownItem>
        );
    }

    let ladoSilla = (string) => {
        if (string === undefined) return null;

        let izquierda = string.indexOf("Izquierda");
        let superior = string.indexOf("Superior");
        let derecha = string.indexOf("Derecha");
        let inferior = string.indexOf("Inferior");

        switch (true){
            case (izquierda > 0): return "IZQUIERDA";
            case (superior > 0): return "SUPERIOR";
            case (derecha > 0): return "DERECHA";
            case (inferior > 0): return "INFERIOR";
            default: return "";
        }
    }
    
    let asiento = () => {
        switch(props.data.estilo){
            case "mesa": 
            return `MESA ${props.data.butaca_fila}${props.data.butaca_columna} - SILLA ${(props.data.num_silla_cont) ? props.data.num_silla_cont : ladoSilla(props.data.codigo_silla) + ' ' + props.data.num_silla} `;
            case "libre": return "NO APLICA";
            default: return `ASIENTO ${props.data.butaca_fila}${props.data.butaca_columna}`;
        }
        /* if (props.data.codigo_silla !== null)  */
    }

    return (
        <>
            <tr>
                <td>
                    <div><strong style = {{fontSize: 14, color: props.data.color}}>{props.data.nombre_zona}</strong></div>
                    <div><strong>Recinto:</strong> {props.data.name}</div>
                    <div><strong>Dirección:</strong> {props.data.address}</div>
                    {enviado()}
                </td>
                <td align style = {{fontSize: 14}}>{asiento()}</td>
                <td align = "center" className = "text-info"><strong>{props.data.fecha} - {props.data.hora_in}</strong></td>
                <td align = "center">
                    <ButtonDropdown isOpen={dropdownOpen} toggle = {() => { setDropdownOpen(!dropdownOpen) }} disabled = {botonAc.disabled}>
                        <DropdownToggle caret className = "btn-outline-info" size = "sm">
                            <small><FontAwesomeIcon icon = {botonAc.icon} spin = {botonAc.spin}/> {botonAc.text}</small>
                        </DropdownToggle>
                        <DropdownMenu>
                            {sendTicketBtn()}
                            {printPdf()}
                        </DropdownMenu>
                    </ButtonDropdown>
                </td>
            </tr>
            {modalPdf()}
            {modalSend()}
        </>
    )
}

export default RetiroTicket;