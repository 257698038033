export const formatNumber = (num) => {
    if (!num || num == 'NaN') return '0';
    if (num === 'Infinity') return '&#x221e;';
    num = num.toString().replace(/\$|\,/g, '');
    if (isNaN(num))
        num = "0";
    let sign = (num === (num = Math.abs(num)));
    num = Math.floor(num * 100 + 0.50000000001);
    let cents = num % 100;
    num = Math.floor(num / 100).toString();
    if (cents < 10)
        cents = "0" + cents;
    for (let i = 0; i < Math.floor((num.length - (1 + i)) / 3) ; i++)
        num = num.substring(0, num.length - (4 * i + 3)) + '.' + num.substring(num.length - (4 * i + 3));
    return (((sign) ? '' : '-') + num + ',' + cents);
}

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    let k = 1024;
    let dm = decimals < 0 ? 0 : decimals;
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

let IntentosPost = 0;
export const Post = (url, body, resp) => {
    let myHeaders = new Headers();
    myHeaders.append("accept", "application/json");

    let formdata = new FormData();
    
    let obj = Object.keys(body);
    obj.forEach ((value) => formdata.append(value, body[value]));

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(url, requestOptions)
    .then(response => {response.text().then(respuesta);
        function respuesta(data) {
            resp (data);
        }
    },error => {
        IntentosPost++;

        (IntentosPost > 5) ? IntentosPost = 0 : Post(url, body, (respuesta) => {
            resp(respuesta)
        })
    })
}

export const PostFormData = (url, formData, callback) => {
    let myHeaders = new Headers();
    myHeaders.append("accept", "application/json");


    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow'
    };

    fetch(url, requestOptions)
    .then(response => {response.text().then(respuesta);
        function respuesta(data) {
            callback (data);
        }
    },error => {
        IntentosPost++;

        (IntentosPost > 5) ? IntentosPost = 0 : Post(url, formData, (respuesta) => {
            callback(respuesta)
        })
    })
};

let IntentosPostPdf = 0;
export const PostPdf = (url, body, resp) => {
    fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers:{'Content-Type' : 'application/json', 'charset' : 'UTF-8'},})
    .then(response => response.blob())
    .then(blob => {
        if (blob.size < 10) {
            resp ("error");
        }else{
            let url = window.URL.createObjectURL(blob);
            resp(url)
        }
    },error => {
        IntentosPostPdf++;
        (IntentosPostPdf > 5) ? IntentosPostPdf = 0 : PostPdf(url, body, (respuesta) => {
            resp(respuesta)
        })
    })
}

export const CalculaEdad = (fecha, respuesta) => {
    let newFecha = new Date(fecha)
    let dia = newFecha.getDate()
    let mes = (newFecha.getMonth() + 1)
    let year = newFecha.getFullYear()

    let fecha_hoy = new Date();
    let ahora_year = fecha_hoy.getYear();
    let ahora_mes = (fecha_hoy.getMonth()+1);
    let ahora_dia = fecha_hoy.getDate();

    let edad = (ahora_year + 1900) - year;
    
    if ( ahora_mes < mes ) edad--;
    if ((mes === ahora_mes) && (ahora_dia < dia)) edad--;
    if (edad > 1900) edad -= 1900;

    let meses=0;

    if(ahora_mes > mes) meses = ahora_mes - mes;
    if(ahora_mes < mes) meses = 12-(mes - ahora_mes);
    if(ahora_mes === mes && dia > ahora_dia) meses = 11;

    let dias=0;

    if(ahora_dia > dia) dias = ahora_dia-dia;
    if(ahora_dia < dia) {
        let ultimoDiaMes = new Date(ahora_year, ahora_mes, 0);
        dias = ultimoDiaMes.getDate() - (dia - ahora_dia);
    }

    let fullEdad = {
        edad: edad,
        fechaTit: { text: `${edad} años ${meses} meses y ${dias} dias`, status: "text-info" },
    }

    respuesta([fullEdad])
    
}