import React, {useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import { CardBody, CardTitle, Card, Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown, faCalendarAlt, faClock, faTags, faUsers, faCircleNotch, faCreditCard, faCheckCircle, faArrowCircleLeft, faUniversity, faMap, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faCcMastercard, faCcVisa, faCcAmex, faCcDinersClub, faCcDiscover } from "@fortawesome/free-brands-svg-icons";

import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import QRCode from 'qrcode.react';

import NavbarI from "components/Navbars/NavbarI.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import Compartir from "components/Social/Compartir";
import EventoDetallePreload from "components/Skeletons/EventoDetallePreload";

import { VerifyTicketUrl } from "functions/TaquillaFunctions";
import { UrlImg } from "functions/Url";
import { UrlMain } from "functions/Url";

const VerifyTickets = (props) => {
    const [redirect, setRedirect]           = useState(false)
    const [preloader, setPreloader]         = useState(true);
    const [detalleEvento, setDetalleEvento] = useState([]);
    const [urlShare, setUrlShare]           = useState("https://www.novasapiens.cl/posts/botones-de-compartir-con-redes-sociales-en-gatsby-js")
    const [payQR, setPayQR]                 = useState(<Skeleton height = {200} width = {200}/>);
    const [payDetalle, setPayDetalle]       = useState(<Skeleton count = {9}/>);
    const [ticketDetalle, setTicketDetalle] = useState(<Skeleton count = {9}/>);

    useEffect(() => {
        let url = window.location.href;
        let part = url.split("/");
        let urlEvent = part.pop();
        let split = urlEvent.split("?");

        switch (split[0]) {
            case "verify_tickets":
                let splitToken = split[1].split("=");

                switch (true){
                    case (splitToken[0] !== "token"):
                        setRedirect(true)
                    break;
                    case (splitToken[1] === undefined):
                        setRedirect(true)
                    break;
                    default:
                        VerifyTicketUrl(splitToken[0], splitToken[1], (resp) => {
                            switch (true) {
                                case (resp.status === "error" || resp.status === "error_log"):
                                    toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                                    setRedirect(true)
                                break;
                                default:
                                    setPreloader(false)
                                    setDetalleEvento(resp.data);
                                    setTicketDetalle(ticketContent(resp.data))
                                    setPayDetalle(infoContent(resp.data))
    
                                    setPayQR(<div className = "qr-code-zona">
                                        <div className = "qr-code">
                                            <QRCode bgColor = "#ffffff" fgColor = "#424242" renderAs = "svg" xmlns = 'http://www.w3.org/2000/svg' value = {`${UrlMain}/verify_tickets?token=${(resp.data)[0].ticket_token}`}/>
                                        </div>
                                        <div className = "qr-code-text"><small>No reveles este código</small></div>
                                    </div>)
                                break;
                            }
                        })
                    break;
                }
            break;
            case "verify_ticket":
                let splitId = split[1].split("=");

                switch (true){
                    case (splitId[0] !== "id"):
                        setRedirect(true)
                    break;
                    case (splitId[1] === undefined):
                        setRedirect(true)
                    break;
                    default:
                        VerifyTicketUrl(splitId[0], splitId[1], (resp) => {
                            switch (true) {
                                case (resp.status === "error" || resp.status === "error_log"):
                                    toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                                    setRedirect(true)
                                break;
                                default:
                                    setPreloader(false)
                                    setDetalleEvento(resp.data);
                                    setTicketDetalle(ticketContent(resp.data))
                                    setPayDetalle(infoContent(resp.data))
    
                                    setPayQR(<div className = "qr-code-zona">
                                        <div className = "qr-code">
                                            <QRCode bgColor = "#ffffff" fgColor = "#424242" renderAs = "svg" xmlns = 'http://www.w3.org/2000/svg' value = {`${UrlMain}/verify_tickets?token=${(resp.data)[0].ticket_token}`}/>
                                        </div>
                                        <div className = "qr-code-text"><small>No reveles este código</small></div>
                                    </div>)
                                break;
                            }
                        })
                    break;
                }
            break;
            default:
                setRedirect(true)
            break;
        }
    }, []);

    let preloadTicker = () => {
        if (preloader === true){
            return (
                <div className = "preload-ticket">
                <div className = "preload-ticket-icon">
                    <FontAwesomeIcon icon = {faCircleNotch} size = "5x" spin = {true}/>
                    <div style = {{marginLeft: -44}}>Cargando informción</div>
                    </div>
                </div>
            )
        }else{
            return null;
        }
    }

    let cambiaFormato = (fecha) => {
        let separa = fecha.split("/");
        return `${separa[1]}/${separa[0]}/${separa[2]}`
    }

    let dia = (date, format) => {
        let diaActual = "";
        (format === true) ? diaActual = new Date(cambiaFormato(date)) : diaActual = new Date(date)
        let day = diaActual.getDate();

        if(day < 10) day = `0${day}`;

        let dias = [ 'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        let meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ]
        let month = meses[diaActual.getMonth()];

        return `${dias[diaActual.getDay()]} ${day} de ${month} de ${diaActual.getFullYear()}`;
    }

    let status = (data) => {
        switch(data){
            case "succeeded": return (
                <div><FontAwesomeIcon icon = {faCheckCircle} className = "text-success"/> Aprobada</div>
            )
            default: return (
                <div><FontAwesomeIcon icon = {faTimesCircle} className = "text-danger"/> No aprobada</div>
            )
        }
    }

    let iconCard = (tipo) => {
        switch(tipo){
            case "visa": return faCcVisa;
            case "mastercard": return faCcMastercard;
            case "amex": return faCcAmex;
            case "discover": return faCcDiscover;
            case "diners": return faCcDinersClub;
            default: return faCreditCard;
        }
    }

    let tipoCard = (tipo) => {
        switch(tipo){
            case "visa": return "VISA";
            case "mastercard": return "MASTERCARD";
            case "discover": return "DISCOVER";
            case "diners": return "DINERS CLUB";
            case "amex": return "AMERICAN EXPRESS";
            default: return "TARJETA DE CRÉDITO";
        }
    }

    let infoContent = (data) => {
        if (data[0].data_ticket_url.metodo_pago === "cortesia") return (
            <Row className = "custom-border_h" style = {{marginLeft: 5, boxShadow: "1px 1px #cccccc", paddingBottom: 10,}}>
                 <Col md = {12} style = {{borderBottom: "1px dashed #cccccc", paddingBottom: 10}}>
                    TICKET DE CORTESÍA
                 </Col>
            </Row>
        )

        let dataT = JSON.parse(data[0].data_ticket_url.data_transaccion);
        
        switch (dataT.tipo_t){
            case "paypal": return (
                <Row className = "custom-border_h" style = {{marginLeft: 5, boxShadow: "1px 1px #cccccc", paddingBottom: 10,}}>
                    <Col md = {8} style = {{borderBottom: "1px dashed #cccccc", paddingBottom: 10}}>
                        <div className = "text-primary"><strong>{(dataT.metodo).toUpperCase()}</strong></div>
                        <div style = {{fontSize: 12}}>{dia(dataT.fecha, false)}</div>
                    </Col>
                    <Col md = {4} style = {{borderBottom: "1px dashed #cccccc", paddingBottom: 10}} align = "right" className = "text-primary">
                        <strong>{`${dataT.moneda} ${dataT.total}`}</strong>
                    </Col>
                    <Col md = {12} className = "custom-mt-10">
                        ID de la transacción: <strong>{dataT.transactionId}</strong>
                    </Col>
                    <Col md = {4} className = "custom-mt-10">
                        <div><small><strong>Titular de la cuenta</strong></small></div>
                        <div><small>{`${dataT.email}`}</small></div>
                        <div><small>{`${dataT.nombre} ${dataT.apellido}`}</small></div>
                        <div><small>{`${dataT.linel}`}</small></div>
                        <div><small>{`${dataT.state}`}</small></div>
                        <div><small>{`${dataT.city}`}</small></div>
                        <div><small>{`${dataT.postalCode}`}</small></div>
                        <div><small>{`${dataT.countryCode}`}</small></div>
                    </Col>
                    <Col md = {8} className = "custom-mt-10">
                        <div><small><strong>Información del vendedor</strong></small></div>
                        <div><small>{`${dataT.vendedorEmail}`}</small></div>
                        <div className ="custom-mt-10"><small><strong>Información de la compra</strong></small></div>
                        <Row>
                            <Col md = {8} style = {{borderBottom: "1px solid #cccccc", paddingBottom: 10, fontSize: 14}} className = "text-danger"><small>{`${dataT.descripcion}`}</small></Col>
                            <Col md = {4} style = {{borderBottom: "1px solid #cccccc", paddingBottom: 10}} align = "right"><small>{`${dataT.moneda} ${dataT.subtotal}`}</small></Col>
                            <Col md = {8}><small><strong>Total</strong></small></Col>
                            <Col md = {4} align = "right"><small><strong>{`${dataT.moneda} ${dataT.subtotal}`}</strong></small></Col>
                        </Row>
                    </Col>
                </Row>
            )
            case "credit_card": return (
                <Row className = "custom-border_h" style = {{marginLeft: 5, boxShadow: "1px 1px #cccccc", paddingBottom: 10,}}>
                    <Col md = {8} style = {{borderBottom: "1px dashed #cccccc", paddingBottom: 10}}>
                        <div className = "text-primary"><strong>{tipoCard(dataT.metodo)}</strong></div>
                        <div><FontAwesomeIcon icon = {iconCard(dataT.metodo)} size = "lg" className = "text-default"/> ********{dataT.last4}</div>
                    </Col>
                    <Col md = {4} style = {{borderBottom: "1px dashed #cccccc", paddingBottom: 10}} align = "right" className = "text-primary">
                        <strong>{`${(dataT.moneda).toUpperCase()} ${dataT.monto}`}</strong>
                    </Col>
                    <Col md = {4} className = "custom-mt-10">
                        <div><small><strong>Titular de la cuenta</strong></small></div>
                        <small>{(dataT.titular).toUpperCase()}</small>
                    </Col>
                    <Col md = {8} className = "custom-mt-10">
                        <div className = "custom-mt-10"><small><strong>Status</strong></small></div>
                        <small>{status(dataT.status)}</small>
                        <div className = "custom-mt-10"><small><strong>Información de la compra</strong></small></div>
                        <Row>
                            <Col md = {8} style = {{borderBottom: "1px solid #cccccc", paddingBottom: 10, fontSize: 14}} className = "text-danger"><small>{`${dataT.descripcion}`}</small></Col>
                            <Col md = {4} style = {{borderBottom: "1px solid #cccccc", paddingBottom: 10}} align = "right"><small>{`${(dataT.moneda).toUpperCase()} ${dataT.monto}`}</small></Col>
                            <Col md = {8}><small><strong>Total</strong></small></Col>
                            <Col md = {4} align = "right"><small><strong>{`${(dataT.moneda).toUpperCase()} ${dataT.monto}`}</strong></small></Col>
                        </Row>
                    </Col>
                </Row>
            )
            case "pago_movil": return (
                <Row className = "custom-border_h" style = {{marginLeft: 5, boxShadow: "1px 1px #cccccc", paddingBottom: 10,}}>
                <Col md = {8} style = {{borderBottom: "1px dashed #cccccc", paddingBottom: 10}}>
                    <div className = "text-primary"><strong>Pago móvil</strong></div>
                </Col>
                <Col md = {4} style = {{borderBottom: "1px dashed #cccccc", paddingBottom: 10}} align = "right" className = "text-primary">
                    <strong>{`${(dataT.moneda).toUpperCase()} ${dataT.monto}`}</strong>
                </Col>
                <Col md = {4} className = "custom-mt-10">
                    <div><small><strong>Titular de la Cuenta</strong></small></div>
                    <small>{(dataT.titular).toUpperCase()}</small>
                </Col>
                <Col md = {8} className = "custom-mt-10">
                    <div className = "custom-mt-10"><small><strong>Status</strong></small></div>
                    <small>{status(dataT.status)}</small>
                    <div className = "custom-mt-10"><small><strong>Información de la compra</strong></small></div>
                    <Row>
                        <Col md = {8} style = {{borderBottom: "1px solid #cccccc", paddingBottom: 10, fontSize: 14}} className = "text-danger"><small>{`${dataT.descripcion}`}</small></Col>
                        <Col md = {4} style = {{borderBottom: "1px solid #cccccc", paddingBottom: 10}} align = "right"><small>{`${(dataT.moneda).toUpperCase()} ${dataT.monto}`}</small></Col>
                        <Col md = {8}><small><strong>Total</strong></small></Col>
                        <Col md = {4} align = "right"><small><strong>{`${(dataT.moneda).toUpperCase()} ${dataT.monto}`}</strong></small></Col>
                    </Row>
                </Col>
            </Row>
            );
            default: return null
        }
    }

    let ticketContent = (data) => {
        let tickets = () => {
            if (data[0].data_ticket_url.metodo_pago === "cortesia") return (
                <>
                    <Col md = {8} className = "custom-mt-10">
                        <h6 className = "text-default"><strong>{(data[0].data_ticket_url.name_event).toUpperCase()}</strong></h6>
                        <div><small><FontAwesomeIcon icon = {faUniversity}/> {data[0].data_ticket_url.name}</small></div>
                        <div><small><FontAwesomeIcon icon = {faMap}/> {data[0].data_ticket_url.address}</small></div>
                        <div><small><FontAwesomeIcon icon = {faCalendarAlt}/> {`${dia(data[0].data_ticket_url.fecha, true)}`}</small></div>
                        <div><small><FontAwesomeIcon icon = {faClock}/> {`${data[0].data_ticket_url.hora_in}`}<strong> (Apertura {data[0].data_ticket_url.hora_apertura})</strong></small></div>
                    </Col>
                    <Col md = {4} align = "right" className = "custom-mt-10">
                        <small className = "text-danger"><strong>{data[0].data_ticket_url.nombre_zona}</strong></small> | <small className = "text-danger"><strong>ASIENTO {data[0].data_ticket_url.butaca_fila}{data[0].data_ticket_url.butaca_columna}</strong></small>
                        <div><small><strong>CORTESÍA</strong></small></div>
                    </Col>
                    <Col md = {12} style = {{borderBottom: "1px dashed #cccccc", paddingBottom: 10, fontSize: 11}} align = "center"></Col>
                </>
            )

            let dataP = JSON.parse(data[0].data_ticket_url.detalle_pago);

            return (
                dataP.dataTickets.map(option => (
                    <React.Fragment  key = {option.id}>
                        <Col md = {8} className = "custom-mt-10">
                            <h6 className = "text-default"><strong>{(data[0].data_ticket_url.name_event).toUpperCase()}</strong></h6>
                            <div><small><FontAwesomeIcon icon = {faUniversity}/> {data[0].data_ticket_url.name}</small></div>
                            <div><small><FontAwesomeIcon icon = {faMap}/> {data[0].data_ticket_url.address}</small></div>
                            <div><small><FontAwesomeIcon icon = {faCalendarAlt}/> {`${dia(data[0].data_ticket_url.fecha, true)}`}</small></div>
                            <div><small><FontAwesomeIcon icon = {faClock}/> {`${data[0].data_ticket_url.hora_in}`}<strong> (Apertura {data[0].data_ticket_url.hora_apertura})</strong></small></div>
                        </Col>
                        <Col md = {4} align = "right" className = "custom-mt-10">
                            <small className = "text-danger"><strong>{option.zona}</strong></small> | <small className = "text-danger"><strong>ASIENTO {option.asiento}</strong></small>
                            <div><small><strong>{`${option.moneda} ${option.precio}`}</strong></small></div>
                        </Col>
                        <Col md = {12} style = {{borderBottom: "1px dashed #cccccc", paddingBottom: 10, fontSize: 11}} align = "center"></Col>
                    </React.Fragment>
                ))
            )
        }

        return(
            <Row className = "custom-border_h" style = {{marginLeft: 5, boxShadow: "1px 1px #cccccc"}}>
                {tickets()}
            </Row>
        )
    }

    let contentEvento = () => {
        if (preloader === true || detalleEvento[0] === undefined) return (
            <EventoDetallePreload />
        )

        return (
            <>
                <div className = "header-banner">
                    <img alt = "..." className = "img-fluid" src = {`${UrlImg}events/${detalleEvento[0].data_ticket_url.image}`} />
                </div>
                <div className = "banner-button_2">
                    <Card className = "card-lift--hover shadow border-0" style = {{width: "30vw !important"}}>
                        <CardBody className="py-5">
                            <div className= {`text-uppercase`} style = {{fontSize: 20}}>
                                <strong>{detalleEvento[0].data_ticket_url.name_event}</strong>
                                <Row>
                                    <Col className = "text-lg-left custom-mt-10" lg = "12" style = {{maxHeight: "35vh", overflowY: "auto"}}>
                                        <div><small className = "text-muted" style = {{fontSize: 12}}><FontAwesomeIcon icon = {faCalendarAlt} className = "text-info"/> {dia(detalleEvento[0].data_ticket_url.fecha, true)}</small></div>
                                        <div><small className = "text-muted" style = {{fontSize: 12}}><FontAwesomeIcon icon = {faClock} className = "text-info"/> {detalleEvento[0].data_ticket_url.hora_in} <strong>(Apertura {detalleEvento[0].data_ticket_url.hora_apertura})</strong></small></div>
                                        <div><small className = "text-muted" style = {{fontSize: 12}}><FontAwesomeIcon icon = {faTags} className = "text-info"/> {detalleEvento[0].data_ticket_url.categorias_tags}</small></div>
                                        <div><small className = "text-muted" style = {{fontSize: 12}}><FontAwesomeIcon icon = {faUsers} className = "text-info"/> {detalleEvento[0].data_ticket_url.artistas_tags}</small></div>
                                        <Compartir url = {urlShare} size = {30} className = "custom-mt-20"/>
                                    </Col>
                                    <Col md = {12}>
                                        <Link to = "/" className = "mt-4 btn btn-outline-warning" style = {{borderRadius: 15}}><FontAwesomeIcon icon = {faArrowCircleLeft}/> Volver</Link>
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </>
        )
    }

    let contentEvento2 = () => {
        return (
            <Container className = "custom-margin">
                <Row>
                    <Col md = {12} style = {{marginTop: 60}}>
                        <Card className = "main-card mb-3" style = {{borderRadius: 30}}>
                            <CardTitle className = "twitter">
                                <strong>DETALLES DE TU COMPRA</strong>
                            </CardTitle>
                            <CardBody>
                                <Row>
                                    <Col md = {8}>
                                        <div>
                                            {payDetalle}    
                                        </div>
                                        <h6 className = "text-info custom-mt-20" style = {{marginLeft: 20}}><strong>DETALLES DE TUS TICKETS</strong></h6>
                                        <div>
                                            {ticketDetalle}    
                                        </div>
                                    </Col>
                                    <Col md = {4} align = "center">
                                        <h6 className = "text-info"><strong>CÓDIGO QR</strong></h6>
                                        <div>
                                            {payQR}    
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }

    if (redirect === true) return <Redirect to = {`/`}/>;

    return (
        <>
            <NavbarI />
            <section className="section pb-0 section-components" style = {{backgroundColor: "#f9f9f9"}}>
                <div className="py-5">
                    <Container className = "custom-margin">
                        {contentEvento()}
                    </Container>
                    <div>
                        {contentEvento2()}
                    </div>
                </div>
            </section>
        </>
    )


    return (
        <>
            {preloadTicker()}
            <NavbarI />
            <main className = "profile-page">
                <section className = "section-profile-cover section-shaped my-0 section-custom">
                    <div className="shape shape-style-1 shape-light alpha-4" style = {{backgroundColor: "#424242"}}>
                        
                    </div>
                    <div className = "separator separator-bottom separator-skew" style = {{borderBottom: "1px solid #ccc"}}>
                    </div>
                </section>
                <section className = "container section">
                    {contentEvento()}
                </section>
            </main>
            <CardsFooter />
        </>
    )
}

export default VerifyTickets;