import React, {useEffect, useState} from "react";

import { Badge, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faCheckCircle, faTimesCircle, faCircleNotch, faCheck, faExclamationCircle, faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Switch from "react-switch";

import ContentModal2 from "components/Modals/ContentModal2";
import ConfirmModal2 from "components/Modals/ConfirmModal2";
import ConfirmModal from "components/Modals/ConfirmModal";
import AddCustomAudit from "views/MisSitios/AddCustomAudit";

import EventoIndFunciones from "./EventoIndFunciones";
import EditEvento from "./EditEvento";
import EditPrecios from "./EditPrecios";
import Verificadores from './Verificadores';

import { SearchEventInd, SearchGeneralEvent, DisableEvent, DelEvent, AprobarEvent, NoAprobarEvent } from "functions/EventosFunctions";
import { Verificador } from "components/Routes/MainRoutes";
import ContinueEvent from "./ContinueEvent";
import { ConfirmEvent } from "functions/EventosFunctions";

const EventoInd = (props) => {
    const [openModalFunciones, setOpenModalFunciones]   = useState(false)
    const [dropdownOpen, setDropdownOpen]   = useState(false)

    const [newDropdownOpen, setNewDropdownOpen] = useState(false);
    const [botonop, setBotonOp]             = useState({ icon: faArrowAltCircleDown, spin: false, disabled: false })

    const [status, setStatus]               = useState(props.data.status)
    const [openModalCon, setOpenModalCon]   = useState(false)
    const [openModalDis, setOpenModalDis]   = useState(false)
    const [openModalConfirmar, setOpenModalConfirmar]   = useState(false)
    const [botonYes, setBotonYes]           = useState({ icon: faCheck, text: "Si", spin: false, disabled: false })
    const [botonAc, setBotonAc]             = useState({ icon: faCogs, text: "Acciones", spin: false, disabled: false })
    const [generalData, setGeneralData]     = useState([]);
    const [eventData, setEventData]         = useState([]);
    const [openModalCD, setOpenModalCD]     = useState(false)
    const [openModalContent, setOpenModalContent]   = useState(false);
    const [openModalContinueEvent, setOpenModalContinueEvent]   = useState(false);
    const [openModalPreios, setOpenModalPrecios]    = useState(false);
    const [openModalAprobar, setOpenModalAprobar]   = useState(false);
    const [disabled, setDisabled]   = useState(false);
    const [observ, setObserv]       = useState("");
    const [botonAp, setBotonAp]     = useState({ icon: faCheck, text: "Aprobar", spin: false, disabled: false })
    const [botonNap, setBotonNap]   = useState({ icon: faTimesCircle, text: "No Aprobar", spin: false, disabled: false })
    const [alternar, setAlternar]   = useState(false);

    const [modalVerify, setModalVerify] = useState(false);

    useEffect(() => {
        setStatus(props.data.status);
        let Utype = JSON.parse(sessionStorage.getItem("ContentData"));
        if (Utype.U_TYPE === 2){
            (props.data.status === 2) ? setDisabled(true) : setDisabled(false);
        }
    }, [props]);

    let del = () => {
        setBotonYes({ icon: faCircleNotch, text: "Espera", spin: true, disabled: true })
        DelEvent (props.data.id, (resp) => {
            setBotonYes({ icon: faCheck, text: "Si", spin: false, disabled: false })
            setOpenModalCon(false)
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                break;
                default:
                    props.upd(true, "Evento Eliminado", resp.mess, resp.data)
                break;
            }
        })
    }

    let statusD = () => {
        switch (status) {
            case "wait":
                return (<h6><Badge color = "light"><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Espera</Badge></h6>)
            case 2:
                return (<h6><Badge color = "warning"><FontAwesomeIcon icon = {faExclamationCircle}/> Pendiente</Badge></h6>)
            case 1:
                return (<h6><Badge color = "info"><FontAwesomeIcon icon = {faCheckCircle}/> Activo</Badge></h6>)
            case 0:
                return (<h6><Badge color = "danger"><FontAwesomeIcon icon = {faTimesCircle}/> Cerrado</Badge></h6>)
            case 3:
                return (<h6><Badge color = "dark"><FontAwesomeIcon icon = {faExclamationCircle}/> Borrador</Badge></h6>)
            case 4:
                return (<h6><Badge color = "success"><FontAwesomeIcon icon = {faCheckCircle}/> Confirmado</Badge></h6>)
            default: return null;
        }
    }

    let cambiaStatus = () => {
        switch (status) {
            case "wait":
                return null
            case 2:
                let Utype = JSON.parse(sessionStorage.getItem("ContentData"));
                if (Utype.U_TYPE === 1){
                    setOpenModalAprobar(true)
                }else{
                    toast.info("Debes esperar que el evento esté aprobado", 4000)
                }
            break;
            default:
                setOpenModalDis(true);
            break;
        }
        if (status === "wait") return null
        
    }

    let disEvento = () => {
        if (status === "wait") return null
        
        setStatus("wait")
        setBotonYes({ icon: faCircleNotch, text: "Espera", spin: true, disabled: true })
        
        DisableEvent(props.data.id, (resp) => {
            setBotonYes({ icon: faCheck, text: "Si", spin: false, disabled: false })

            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                break;
                default:
                    (resp.data === true) ? setStatus(1) : setStatus(0);
                    toast.success(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                    setOpenModalDis(false)
                break;
            }
        })
    }

    let modalDisabled = () => {
        if (openModalDis === false) return null;

        let estatus = status;
        let mess = "";
        if (estatus === 0) {
            mess = <div>Al activar el evento ten en cuenta que debes definir la distribución y los precios.
                <div className = "text-danger"><strong>¿Deseas continuar?</strong></div>
            </div>
        }else{
            mess = <div>Al cerrar este evento ya no estará disponible para los usuarios.
                <div className = "text-danger"><strong>¿Deseas continuar?</strong></div>
            </div> 
        }

        return (
            <ConfirmModal
                isOpen = {openModalDis}
                content = {mess}
                disabled = {botonYes.disabled}
                buttonYes = {<><FontAwesomeIcon icon = {botonYes.icon} spin = {botonYes.spin}/> {botonYes.text}</>}
                close = {() => setOpenModalDis(false)}
                funcion = {disEvento}
            />
        )
    }

    let confirmarEvento = () => {
        setOpenModalConfirmar(true);
    }

    let confirEvento = () => {
        if (status === "wait") return null
        
        setStatus("wait")
        setBotonYes({ icon: faCircleNotch, text: "Espera", spin: true, disabled: true })
        
        ConfirmEvent(props.data.id, (resp) => {
            setBotonYes({ icon: faCheck, text: "Si", spin: false, disabled: false })

            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    setStatus(1)
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                break;
                default:
                    (resp.data === 4) ? setStatus(4) : setStatus(0);
                    toast.success(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                    setOpenModalConfirmar(false)
                break;
            }
        })
    }

    let modalConfirmar = () => {
        if (openModalConfirmar === false) return null;

        let mess = <div>Una vez confirmado el evento, se procederá a la apertura de la venta de entradas para el público general. Es importante destacar que no será posible realizar cambios o modificaciones en la distribución de asientos ni en el precio de las entradas.<div className="text-danger"><strong>¿Deseas continuar?</strong></div></div>;

        return (
            <ConfirmModal
                isOpen = {openModalConfirmar}
                content = {mess}
                disabled = {botonYes.disabled}
                buttonYes = {<><FontAwesomeIcon icon = {botonYes.icon} spin = {botonYes.spin}/> {botonYes.text}</>}
                close = {() => setOpenModalConfirmar(false)}
                funcion = {confirEvento}
            />
        )
    }

    let modalAprobar = () => {
        
        let mess = <div>
            <div>¿Qué acción deseas realizar con este evento?</div>
            <div style = {{marginBottom: 10}}><textarea className = "form-control" rows = "3" placeHolder = "Observaciones" value = {observ} onChange = {(e) =>setObserv(e.target.value)}></textarea></div>
            <div style = {{marginBottom: 10, padding: 5}} align = "right">
                <small>Eliminar evento en caso de no aprobar</small> <Switch
                    offColor = "#d12c2c"
                    onHandleColor = "#fff"
                    onColor = "#67c167"
                    offHandleColor = "#fff"
                    checked = {alternar} onChange = {() => { setAlternar(!alternar) }}
                    className = "react-switch" height = {15} width = {37}
                />
            </div>
        </div>

        let aprobEvento = () => {
            if (botonAp.disabled === true) return null;

            setBotonAp({icon: faCircleNotch, text: "Aprobar", spin: true, disabled: true});
            setBotonNap(state => ({...state, disabled: true }))
            
            AprobarEvent(props.data.id, observ, (resp) => {
                setBotonAp({icon: faCheck, text: "Aprobar", spin: false, disabled: false});
                setBotonNap(state => ({...state, disabled: false }))

                switch (true) {
                    case (resp.status === "error" || resp.status === "error_log"):
                        toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                    break;
                    default:
                        (resp.status === "ok") ? setStatus(1) : setStatus(0);
                        toast.success(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                        setOpenModalAprobar(false)
                    break;
                }
            })
        }
        
        let noAprobEvento = () => {
            if (botonNap.disabled === true) return null;

            setBotonNap({icon: faCircleNotch, text: "No aprobar", spin: true, disabled: true});
            setBotonAp(state => ({...state, disabled: true }));

            NoAprobarEvent(props.data.id, observ, alternar, (resp) => {
                setBotonNap({icon: faTimesCircle, text: "No aprobar", spin: false, disabled: false});
                setBotonAp(state => ({...state, disabled: false }));

                switch (true) {
                    case (resp.status === "error" || resp.status === "error_log"):
                        toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                    break;
                    default:
                        props.upd(true, "Proceso completado", resp.mess, resp.data)
                        setOpenModalAprobar(false)
                    break;
                }
            })
        }

        return (
            <ConfirmModal2
                isOpen = {openModalAprobar}
                content = {mess}
                disabled = {botonAp.disabled}
                buttonYes = {<small style = {{fontSize: 12}}><FontAwesomeIcon icon = {botonAp.icon} spin = {botonAp.spin}/> {botonAp.text}</small>}
                buttonNo = {<small style = {{fontSize: 12}}><FontAwesomeIcon icon = {botonNap.icon} spin = {botonNap.spin}/> {botonNap.text}</small>}
                close = {noAprobEvento}
                funcion = {aprobEvento}
                cerrar = {() => setOpenModalAprobar(false)}
            />
        )
    }

    let modalFunciones = () => {
        if (openModalFunciones === false) return null;
        return (
            <ContentModal2
                isOpen = {openModalFunciones}
                title = {`Funciones de ${(props.data.name_event).substring(0,30)}`}
                size = "md"
                content = {<EventoIndFunciones id = {props.data.id} />}
                close = {() => setOpenModalFunciones(false)}
            />
        )
    }

    let modalVerificadores = () => {
        if (modalVerify === false) return null;
        return (
            <ContentModal2
                isOpen = {modalVerify}
                title = {`Verificadores de ${(props.data.name_event).substring(0,30)}`}
                size = "md"
                content = {<Verificadores id = {props.data.id} />}
                close = {() => setModalVerify(false)}
            />
        )
    }

    let modalConfirm = () => {
        if (openModalCon === false) return null;

        return (
            <ConfirmModal
                isOpen = {openModalCon}
                content = {<div>¿Deseas eliminar este evento?</div>}
                disabled = {botonYes.disabled}
                buttonYes = {<><FontAwesomeIcon icon = {botonYes.icon} spin = {botonYes.spin}/> {botonYes.text}</>}
                close = {() => setOpenModalCon(false)}
                funcion = {del}
            />
        )
    }

    let openEditEvent = () => {
        setBotonAc({ icon: faCircleNotch, text: "Espera", spin: true, disabled: true })
        SearchGeneralEvent((data) => {
            SearchEventInd(props.data.id, (resp) => {
                let content = [{
                    dataEvento: resp.dataEvento,
                    productoresEvent: resp.productoresEvent,
                    organizadoresEvent: resp.organizadoresEvent,
                    funcionesEvent: resp.funcionesEvent,
                    preciosEvent: resp.preciosEvent
                }]

                setGeneralData(data)
                setEventData(content)
                setBotonAc({ icon: faCogs, text: "Acciones", spin: false, disabled: false })
                setOpenModalContent(true)
            })
        })
    }

    let openContinueEvent = () => {
        setBotonAc({ icon: faCircleNotch, text: "Espera", spin: true, disabled: true })
        SearchGeneralEvent((data) => {
            SearchEventInd(props.data.id, (resp) => {

                const objeto = resp.dataEvento.borrador_event
                const draftDataJson = JSON.parse(objeto);

                let content = {
                    dataEvento: resp.dataEvento,
                    productoresEvent: resp?.productoresEvent,
                    organizadoresEvent: resp?.organizadoresEvent,
                    funcionesEvent: resp?.funcionesEvent,
                    preciosEvent: resp?.preciosEvent,
                    draftDataJson : draftDataJson
                }
                setGeneralData(data)
                setEventData(content)
                setBotonAc({ icon: faCogs, text: "Acciones", spin: false, disabled: false })
                setOpenModalContinueEvent(true)
            })
        })
    }

    let modalEditEvent = () => {
        if (openModalContent === false) return null;
        return (
            <ContentModal2
                isOpen = {openModalContent}
                title = "Editar este evento"
                size = "xl"
                content = {<EditEvento generalData = {generalData} eventData = {eventData} idEvento = {props.data.id} close = {(data) => {
                    setOpenModalContent(false)
                    props.updEvent(data)
                }}/>}
                close = {() => setOpenModalContent(false)}
            />
        )
    }

    let modalContinueEvent = () => {
        if (openModalContinueEvent === false) return null;
        return (
            <ContentModal2
                isOpen = {openModalContinueEvent}
                title = "Continuar este evento"
                size = "xl"
                content = {<ContinueEvent generalData = {generalData} eventData = {eventData} idEvento = {props.data.id} close = {(data) => {
                    setOpenModalContinueEvent(false)
                    props.triggerReload()
                }}/>}
                close = {() => setOpenModalContinueEvent(false)}
            />
        )
    }

    let modalCustomDist = () => {
        if (openModalCD === false) return null;

        return (
            <ContentModal2
                isOpen = {openModalCD}
                title = {`Distribución personalizada (${props.data.name_event})`}
                size = "xl"
                content = {<AddCustomAudit idSite = {props.data.id_recinto} idEvento = {props.data.id} />}
                close = {() => {setOpenModalCD(false)}}
            />
        )
    }

    let modalPrecios = () => {
        if (openModalPreios === false) return null;

        let openDist = () => {
            setOpenModalPrecios(false)
            setOpenModalCD(true)
        }

        return (
            <ContentModal2
                isOpen = {openModalPreios}
                title = {`Editar precios (${props.data.name_event})`}
                size = "xl"
                content = {<EditPrecios idSite = {props.data.id_recinto} idEvento = {props.data.id} dataEvent = {props.data.sitio_simple} openDist = {() => openDist()}/>}
                close = {() => {setOpenModalPrecios(false)}}
            />
        )
    }

    let openMenu = () => {
        setNewDropdownOpen(!newDropdownOpen);
    }

    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let type = data.U_TYPE;
    return (
        <>
        <tr>
            <td>
                <button className="rounded-pill border border-primary btn btn-primary" onClick={openMenu}>
                    <FontAwesomeIcon icon = {botonop.icon} spin = {botonop.spin}/>
                </button>
            </td>
            <td className = "text-primary"><strong>{props.data.name_event}</strong></td>
            <td>
                <strong>{props.data.sitio_simple?.name}</strong>
                <div className = "text-info">{props.data.sitio_simple?.address}</div>
            </td>
            <td align = "center">{props.data.tipo_evento?.name_event}</td>
            <td>{props.data.categorias_tags}</td>
            <td>{props.data.artistas_tags}</td>
            <td align = "center">{statusD()}</td>


                <td>
                    <ButtonDropdown isOpen={dropdownOpen} toggle={() => { setDropdownOpen(!dropdownOpen) }} disabled={botonAc.disabled}>
                        <DropdownToggle caret className="btn-outline-info" size="sm">
                            <small><FontAwesomeIcon icon={botonAc.icon} spin={botonAc.spin} /> {botonAc.text}</small>
                        </DropdownToggle>

                        {(status === 0) ?
                            <DropdownMenu>
                                {/* Cerrado === 0 */}
                                <DropdownItem style={{ padding: 5 }} disabled={disabled}><small>Sin acciones...</small>
                                </DropdownItem>
                            </DropdownMenu> :
                            (status === 3) ?
                                <DropdownMenu>
                                    {/* Borrador === 3 */}
                                    <DropdownItem style={{ padding: 5 }} disabled={disabled} onClick={openContinueEvent}><small>Continuar este evento</small></DropdownItem>
                                </DropdownMenu>

                                : <DropdownMenu>

                                    {/* Pendiente */}
                                    {((status !== 1 && status !== 4) || (type === 1 && status !== 4)) ? <DropdownItem style={{ padding: 5 }} disabled={disabled} onClick={openEditEvent}><small>Editar este evento</small></DropdownItem> : null}

                                    {/* Distribución */}
                                    {(type === 1) && (status !== 4)? <DropdownItem style={{ padding: 5 }} onClick={() => setOpenModalCD(true)}><small>Personalizar distribución</small></DropdownItem> : null}

                                    {/* Precios */}
                                    {(type === 1) && (status !== 4) ? <DropdownItem style={{ padding: 5 }} onClick={() => setOpenModalPrecios(true)}><small>Definir precios</small></DropdownItem> : null}
                                    
                                    {/* Activo */}
                                    {(type === 1) && (status !== 1) && (status !== 4) ? <DropdownItem style={{ padding: 5 }} onClick={cambiaStatus}><small>Activar evento</small></DropdownItem> : null}

                                    {/* Confirmado */}
                                    {(type === 1) && (status === 1) ? <DropdownItem style={{ padding: 5 }} onClick={confirmarEvento}><small>Confirmar evento</small></DropdownItem> : null}

                                    {/* Eliminar */}
                                    {(type === 1) ? <DropdownItem style={{ padding: 5 }} disabled={disabled} onClick={() => setOpenModalCon(true)}><small>Eliminar este evento</small></DropdownItem> : null}

                                    {/* Verificador */}
                                    <DropdownItem style={{ padding: 5 }} onClick={() => setModalVerify(true)}><small>Establecer verificador</small></DropdownItem>

                                    {/* Cerrar */}
                                    {(type === 1) && (status !== 2) ? <DropdownItem style={{ padding: 5 }} onClick={cambiaStatus}><small>Cerrar evento</small></DropdownItem> : null}

                                </DropdownMenu>}

                    </ButtonDropdown>
                </td>
        </tr>

        <tr style={{transition: "max-height .5s ease-out", display: newDropdownOpen ? "table-row" : "none"}}>
            <th colspan="8">{<EventoIndFunciones id = {props.data.id} />}</th>
        </tr>

        {modalFunciones()}
        {modalConfirm()}
        {modalEditEvent()}
        {modalContinueEvent()}
        {modalCustomDist()}
        {modalPrecios()}
        {modalDisabled()}
        {modalAprobar()}
        {modalVerificadores()}
        {modalConfirmar()}
        </>
    )
}

export default EventoInd;
