import React from 'react'
import { Col } from 'reactstrap';

function TotalT(props) {

    const totalT = () => {
        let ContentDataEvent = JSON.parse(sessionStorage.getItem("ContentDataEvent"));

        let cantEntradas = props.total / props.precio
        let imp = props.servTaquilla.valor * props.precio / 100
        let totalImp = imp * cantEntradas;


        if ( ContentDataEvent !== null){
            return `${props.moneda}. ${parseFloat(props.total + totalImp).toFixed(2)}`;
        }else{
            return null;
        }
    }

  return (
    <>
        <Col md = {7} align = "right"  className = "custom-mt-10">
            <div><strong>Total:</strong></div>
        </Col>
        <div className = "custom-mt-10 mb-3" align = "right"><strong>{totalT()}</strong></div>
    </>
  )
}

export default TotalT