import React, {useEffect, useState} from "react";
import { toast } from "react-toastify";

import { Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert, Collapse, Row, Col, Table } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faGift,faCreditCard, faCogs, faFilePdf, faTicketAlt, faCircleNotch, faExclamationTriangle, faCheckCircle, faCheck, faCheckSquare, faTimes, faFrown, faInfoCircle, faMobileAlt, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { faPaypal, faCcMastercard, faCcVisa, faCcAmex, faCcDinersClub, faCcDiscover } from "@fortawesome/free-brands-svg-icons";

import ContentModal2 from "components/Modals/ContentModal2";

import { GenerarTicketPdf, GenerarTicketPdfClient, AprobarTransaccion, NoAprobarTransaccion } from "functions/EventosFunctions";
import logoZelle from "assets/img/logos/zelle.png";
import logoMultipago from "assets/img/icons/common/multipago.svg";
import logoTransfer from "assets/img/logos/bank_transfer.svg";
import { UrlTmp } from "../../functions/Url";

let totalPay = 0;
const DetalleVenta = (props) => {
    const [detallePago, setDetallePago] = useState("");
    const [detalleTicket, setDetalleTicket] = useState("");
    const [colaps, setColaps]           = useState(false);
    const [colapsIconS, setColapsIconS] = useState(false);
    const [colapsIcon, setColapsIcon]   = useState(faChevronDown);
    const [botonAc, setBotonAc]             = useState({ icon: faCogs, text: "Acciones", spin: false, disabled: false })
    const [dropdownOpen, setDropdownOpen]   = useState(false)
    const [btnPdf, setBtnPdf]               = useState({icon: faTicketAlt, text: "Ver Ticket .Pdf", spin: false, disabled: false})
    const [btnFactura, setBtnFactura]       = useState({icon: faFilePdf, text: "Ver Factura .Pdf", spin: false, disabled: false})
    const [src, setSrc]                     = useState("")
    const [openModalPdf, setOpenModalPdf]   = useState(false)
    const [estilo, setEstilo]               = useState("")
    const [statusTicket, setStatusTicket]   = useState("")
    const [openModalAprPay, setOpenModalAprPay] = useState(false)
    const [botonAprobar, setBotonAprobar]       = useState({ icon: faCheck, text: "Aprobar", spin: false, disabled: false })
    const [botonNoAprobar, setBotonNoAprobar]   = useState({ icon: faTimes, text: "No Aprobar", spin: false, disabled: false })
    const [tokenTicket, setTokenTicket]     = useState("")
    const [dataTicket, setDataTicket] = useState("")

    useEffect(() => {
        setEstilo(props.data.data_ticket.estilo)
        setDetalleTicket(props.data.data_ticket.detalle_pago);
        setDetallePago(props.data.data_ticket.data_transaccion);
        setStatusTicket(props.data.status_venta);
        setTokenTicket(props.data.ticket_token)
        setDataTicket(props.data.data_ticket);
    }, [props]);

    let convertMoneda = (moneda, valor) => {
        /* let ContentCurrency = JSON.parse(sessionStorage.getItem("ContentDataCurrency"));
        let ContentRsp =  JSON.parse(sessionStorage.getItem("ContentResp"));
        
        if(ContentRsp.config.convert_local === 1){
            let newValor = (valor * ContentCurrency.valor_1usd_moneda_local).toFixed(2);
            return `${(ContentRsp.config.moneda.moneda).toUpperCase()} ${newValor}`
        }else{ */
            return `${(moneda).toUpperCase()} ${valor.toFixed(2)}`
        /* } */
    }

    let cambiaFormato = (fecha) => {
        let separa = fecha.split("/");
        return `${separa[1]}/${separa[0]}/${separa[2]}`
    }

    let dia = (date, format) => {
        let diaActual = "";
        (format === true) ? diaActual = new Date(cambiaFormato(date)) : diaActual = new Date(date)
        let day = diaActual.getDate();

        if(day < 10) day = `0${day}`;

        let meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ]
        let month = meses[diaActual.getMonth()];

        return `${day} de ${month} de ${diaActual.getFullYear()}`;
    }

    let diaYMT = (date) => {
        let diaActual = new Date(date);
        let day = diaActual.getDate();
        let month = diaActual.getMonth() + 1;

        if(day < 10) day = `0${day}`;
        if(month < 10) month = `0${month}`;
        return `${day}/${month}/${diaActual.getFullYear()}`;
    }

    let ladoSilla = (string) => {
        let izquierda = string.indexOf("Izquierda");
        let superior = string.indexOf("Superior");
        let derecha = string.indexOf("Derecha");
        let inferior = string.indexOf("Inferior");

        switch (true){
            case (izquierda > 0): return "Izquierda";
            case (superior > 0): return "Superior";
            case (derecha > 0): return "Derecha";
            case (inferior > 0): return "Inferior";
            default: return "";
        }
    }

    let tickets = () => {
        if(detalleTicket === ""){
            let asiento = `${props.data.data_ticket.butaca_fila} ${props.data.data_ticket.butaca_columna}`;
            let zona = props.data.data_ticket.nombre_zona;
            let precio  = `${props.data.data_ticket.moneda} ${props.data.data_ticket.monto}`;

            switch(estilo){
                case "mesa":
                    asiento = `${props.data.data_ticket.butaca_fila}${props.data.data_ticket.butaca_columna}`;
                break;
                case "libre":
                    asiento = "N/A";
                break;
                default:
                    asiento = `${props.data.data_ticket.butaca_fila}${props.data.data_ticket.butaca_columna}`;
                break;
            }
            
            return (
                <tr className = "text-danger">
                    <td>{zona}</td>
                    <td align = "center">{asiento}</td>
                    <td align = "center">{precio}</td>
                </tr>
            )
        }

        let tickets = JSON.parse(detalleTicket);

        let asientoName = (info) => {
            switch(estilo){
                case "mesa": return (<>
                    <div align = "left">{`Mesa: ${info.asiento}`}</div>
                    <div align = "left">{(info.num_silla_cont) ? `Silla: ${info.num_silla_cont}` : `Silla: ${ladoSilla(info.sillaCode)} ${info.sillaNum}`}</div>
                </>)
                case "libre": return <div>N/A</div>
                default: return `${info.asiento}`;
            } 
        }


        return (tickets.dataTickets).map(option => (
            <tr key = {option.id}>
                <td>{option.zona}</td>
                <td align = "center">{asientoName(option)}</td>
                <td align = "center">{option.moneda} {option.precio}</td>
            </tr>
        ));
    }

    let detalle = () => {
        if(detalleTicket === "") return null

        let detalleFactura = JSON.parse(detalleTicket);

        let tickets = detalleFactura.dataTickets
        let impuestos = detalleFactura.dataImpuestos
        let servTaquilla = detalleFactura.taquilla
        let impTaquilla = detalleFactura.dataImpTaquilla

        let aplicable = false;

        if (detalleFactura.taquilla !== null && detalleFactura.taquilla !== "") aplicable = Boolean(servTaquilla.aplica);

        let totalPago = () => {
            let cant = 0;
            let moneda = ""
            tickets.forEach(opt => {
                moneda = opt.moneda;
                cant += opt.precio;
            })
            
            let totalP = totalPay + cant;
            totalPay = totalP

            return convertMoneda(moneda, cant);
        }

        let contentImpuestos = () => {
            if (impuestos.length === 0) return(
                <tr>
                    <td colSpan = {2} style = {{padding: 5}}>No Aplica</td>
                </tr>
            )

            return(
                impuestos.map(option => (
                    <tr key = {option.id}>
                        <td align = "right" style = {{padding: 5}}>{option.nombre.nombre} {option.valor}</td>
                        {/* <td width = "20%" align = "right" style = {{padding: 5}}>{convertMoneda(option.moneda, option.monto)}</td> */}
                        <td align = "right" style = {{padding: 5}}>{option.moneda} {(option.monto)}</td>
                    </tr>
                ))
            )
        }

        let contentImpTaquilla = () => {
            if (impTaquilla.length === 0) return null;

            return(
                impTaquilla.map(option => (
                    <tr key = {option.id}>
                        <td align = "right" style = {{padding: 5}}>{option.nombre.nombre} {option.valor}</td>
                        {/* <td width = "20%" align = "right" style = {{padding: 5}}>{convertMoneda(option.moneda, option.monto)}</td> */}
                        <td align = "right" style = {{padding: 5}}>{option.moneda} {(option.monto)}</td>
                    </tr>
                ))  
            )
        }

        let contentServTaquilla = () => {
            if (aplicable === true){
                let totalP = totalPay + servTaquilla.valor;
                totalPay = totalP

                return (
                    <>
                        <tr>
                            <td align = "right" style = {{padding: 10, paddingRight: 5}}><strong>Servicios de taquilla:</strong></td>
                            {/* <td align = "right" style = {{padding: 10, paddingRight: 5}}>{convertMoneda(servTaquilla.moneda, servTaquilla.valor)}</td> */}
                            <td align = "right" style = {{padding: 10, paddingRight: 5}}>{(servTaquilla.valor)} %</td>
                        </tr>
                        {contentImpTaquilla()}
                    </>
                )
            }else{
                return null;
            }

        }

        let totalAPagar = () => {
            let cant = 0;
            let moneda = ""
            tickets.forEach(opt => {
                moneda = opt.moneda;
                cant += parseFloat(opt.precio);
            })

            // impuestos.forEach(opt => cant += parseFloat(opt.monto))
            if (aplicable === true) cant += parseFloat(servTaquilla.valor * cant / 100);
            // impTaquilla.forEach(opt => cant += parseFloat(opt.monto))
            
            /* return convertMoneda(moneda, cant); */ 
            return convertMoneda(moneda, cant); 
        }

        return (
            <Table className = "mb-0" style = {{fontSize: 10}}>
                <tbody>
                    <tr>
                        <td align = "right" width = "65%" style = {{padding: 5}}>
                            <strong>SUB-TOTAL:</strong>
                        </td>
                        <td style = {{padding: 5}} width = "35%" align = "right">
                            <strong>{totalPago()}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td align = "right" style = {{padding: 10, paddingRight: 5}}><strong>Impuestos:</strong></td>
                        <td></td>
                    </tr>
                    {contentImpuestos()}
                    {contentServTaquilla()}
                    <tr className = "text-danger" style = {{fontSize: 12}}>
                        <td align = "right" style = {{padding: 5}}>
                            <strong>TOTAL PAGADO:</strong>
                        </td>
                        <td style = {{padding: 5}} align = "right">
                            <strong>{totalAPagar()}</strong>
                        </td>
                    </tr>
                </tbody>
            </Table>
        )
    }

    let resumen = () => {
        if(detallePago === "") return null

        let detallePagoFactura= JSON.parse(detallePago);

        switch(detallePagoFactura.tipo_t){
  
            case "efectivo": 
            return (
                <div className = "card card-body" style = {{padding: 6}}>
                   <div className = "custom-mt-10"><strong>Titular</strong></div>
                    <div><span>{detallePagoFactura.titular}</span></div>
                    <div className = "custom-mt-10"><span><strong>Correo del titular Zelle</strong></span></div>
                    <span>{detallePagoFactura.email_titular?.toUpperCase()}</span>
                    <div className = "custom-mt-10"><strong>Fecha de la transacción</strong></div>
                    <div><span>{dia(detallePagoFactura.fecha, false)}</span></div>
                    <div className = "custom-mt-10"><strong>Método de pago</strong></div>
                    <div><span>{detallePagoFactura.metodo?.toUpperCase()}</span></div>
                </div>
            )
            case "paypal": return (
                <div className = "card card-body" style = {{padding: 6}}>
                    <div><span><strong>Fecha: </strong>{dia(detallePagoFactura.fecha, false)}</span></div>
                    <div className = "custom-mt-10"><span className = "text-danger"><strong>Total: {detallePagoFactura.moneda} {detallePagoFactura.total}</strong></span></div>
                    <div className = "custom-mt-10"><strong>Titular de la cuenta</strong></div>
                    <div><span>{detallePagoFactura.email}</span></div>
                    <div><span>{detallePagoFactura.nombre} {detallePagoFactura.apellido}</span></div>
                    <div><span>{detallePagoFactura.linel}</span></div>
                    <div><span>{detallePagoFactura.state}</span></div>
                    <div><span>{detallePagoFactura.city}</span></div>
                    <div><span>{detallePagoFactura.postalCode}</span></div>
                    <div><span>{detallePagoFactura.countryCode}</span></div>
                </div>
            )
            case "credit_card": return (
                <div className = "card card-body" style = {{padding: 6}}>
                    <div><span><strong>Fecha: </strong>{dia(detallePagoFactura.fecha, false)}</span></div>
                    <div className = "custom-mt-10"><span className = "text-danger"><strong>Total: {detallePagoFactura.moneda.toUpperCase()} {detallePagoFactura.monto}</strong></span></div>
                    <div className = "custom-mt-10"><strong>Titular de la cuenta</strong></div>
                    <div><span>{detallePagoFactura.titular}</span></div>
                    <div><span>{detallePagoFactura.metodo.toUpperCase()}</span></div>
                    <div><span>********{detallePagoFactura.last4}</span></div>
                </div>
            )
            case "zelle": return (
                <div className = "card card-body" style = {{padding: 6}}>
                    <div><span><strong>Nombre del titular Zelle</strong></span></div>
                    <span>{(detallePagoFactura.titular).toUpperCase()}</span>
                    <div className = "custom-mt-10"><span><strong>Correo del titular Zelle</strong></span></div>
                    <span>{(detallePagoFactura.email_titular).toUpperCase()}</span>
                    <div className = "custom-mt-10"><span><strong>Fecha de la transacción</strong></span></div>
                    <span>{(detallePagoFactura.fecha)}</span>
                </div>
            )
            case "pago_movil":   
            return (
                <div className = "card card-body" style = {{padding: 6}}>
                    <div className = "custom-mt-10"><strong>Titular</strong></div>
                    <div><span>{detallePagoFactura.titular}</span></div>
                    <div className = "custom-mt-10"><strong>Teléfono del Titular</strong></div>
                    <div><span>{detallePagoFactura.last4}</span></div>
                    <div className = "custom-mt-10"><strong>Fecha de la transacción</strong></div>
                    <div><span>{dia(detallePagoFactura.fecha, false)}</span></div>
                    <div className = "custom-mt-10"><strong>Método de pago</strong></div>
                    <div><span>{detallePagoFactura.metodo?.toUpperCase()}</span></div>
                </div>
            )
            case "transferencia": return (
                <div className = "card card-body" style = {{padding: 6}}>
                   <div><span><strong>Nombre del titular</strong></span></div>
                    <span>{detallePagoFactura.user_data?.nombre.toUpperCase()}</span>
                    <div className = "custom-mt-10"><span><strong>Correo del titular</strong></span></div>
                    <span>{detallePagoFactura.user_data?.email.toUpperCase()}</span>
                    <div className = "custom-mt-10"><span><strong>Fecha de la transacción</strong></span></div>
                    <span>{detallePagoFactura.fecha}</span>
                </div>
            )
            case "multipago": return (
                <div className = "card card-body" style = {{padding: 6}}>
                   <div><span><strong>Nombre del titular</strong></span></div>
                    <span>{detallePagoFactura.user_data.nombre?.toUpperCase()}</span>
                    <div className = "custom-mt-10"><span><strong>Correo del titular</strong></span></div>
                    <span>{detallePagoFactura.user_data.correo?.toUpperCase()}</span>
                    <div className = "custom-mt-10"><span><strong>Fecha de la transacción</strong></span></div>
                    <span>{detallePagoFactura.fecha}</span>
                </div>
            )
            default: return null;
        }
    }

    let iconC = () => {
        setColapsIconS(!colapsIconS);
        setColaps(!colaps);

        (colapsIconS === false) ? setColapsIcon(faChevronUp) : setColapsIcon(faChevronDown);
    }

    let iconCard = (tipo) => {
        switch(tipo){
            case "visa": return faCcVisa;
            case "mastercard": return faCcMastercard;
            case "amex": return faCcAmex;
            case "discover": return faCcDiscover;
            case "diners": return faCcDinersClub;
            default: return faCreditCard;
        }
    }

    let iconPay = () => {

        let totalAPagar = (detalle) => {
            // Convertir el detalle de pago en un objeto
            let detallePagoFactura = JSON.parse(detalle);

            // Sumar el total de los tickets
            let cant = 0;
            let moneda = ""
            detallePagoFactura.dataTickets.forEach(opt => {
                moneda = opt.moneda;
                cant += parseFloat(opt.precio);
            })

            // Sumar el total de los impuestos
            if (detallePagoFactura.taquilla.aplica === 1) cant += parseFloat(detallePagoFactura.taquilla.valor * cant / 100);
            
            // Retornar el total a pagar
            return convertMoneda(moneda, cant); 
        }

        if(detallePago === "") return null

        let detallePagoFactura= JSON.parse(detallePago);
        switch(detallePagoFactura.tipo_t){
            case "efectivo": return (
                <div><FontAwesomeIcon icon = {faMoneyBill} size = "2x"/><strong style = {{fontSize: 15}}> - {totalAPagar(dataTicket.detalle_pago)}</strong></div>
            )
            case "paypal": return (
                <div><FontAwesomeIcon icon = {faPaypal} size = "2x"/><strong style = {{fontSize: 15}}> - {totalAPagar(dataTicket.detalle_pago)}</strong></div>
            )
            case "credit_card":return (
                <div><FontAwesomeIcon icon = {iconCard(detallePagoFactura.metodo)} size = "2x"/><strong style = {{fontSize: 15}}> - {totalAPagar(dataTicket.detalle_pago)}</strong></div>
            )
            case "zelle": return (
                <div><img src = {logoZelle} alt = "logo-zelle" height = "auto" width = "40px" className = "img img-fluid"/><strong style = {{fontSize: 15}}> - {totalAPagar(dataTicket.detalle_pago)}</strong></div>
            )
            case "pago_movil": 
            return (
                <div><FontAwesomeIcon icon = {faMobileAlt} size = "2x"/><strong style = {{fontSize: 15}}> - {totalAPagar(dataTicket.detalle_pago)}</strong></div>
            )
            case "transferencia": return (
                <div><img src = {logoTransfer} alt = "logo-trasferencia" height = "auto" width = "40px" className = "img img-fluid"/><strong style = {{fontSize: 15}}> - {totalAPagar(dataTicket.detalle_pago)}</strong></div>
            )
            case "multipago": 
            return (
                <div><img src = {logoMultipago} alt = "logo-multipago" height = "auto" width = "35px" className = "img img-fluid"/><strong style = {{fontSize: 15}}> - {totalAPagar(dataTicket.detalle_pago)}</strong></div>
            )
            default: return null;
        }
    }

    let generarPdf = () => {
        if (btnPdf.disabled === true) return null;

        if (statusTicket === "0"){
            toast.warn(<small><strong>Debes confirmar primero el pago para poder ver los boletos</strong></small>)
        }else{
            setBtnPdf({icon: faCircleNotch, text: "Ver Ticket .Pdf", spin: true, disabled: true})
            setBotonAc (state => ({...state, icon: faCircleNotch, spin: true, disabled: true}))
    
            if(detalleTicket === ""){
                GenerarTicketPdf(props.data.id_ticket, props.data.id_evento, (resp) => {
                    setSrc(resp);
                    setOpenModalPdf(true);
                    setBtnPdf({icon: faFilePdf, text: "Ver Ticket .Pdf", spin: false, disabled: false})
                    setBotonAc (state => ({...state, icon: faCogs, spin: false, disabled: false}))
                })
            }else{
                let detallePagoFactura = JSON.parse(detallePago);
                GenerarTicketPdfClient(detallePagoFactura.tipo_t, props.data.id_factura, diaYMT(detallePagoFactura.fecha), (resp) => {
                    setSrc(resp);
                    setOpenModalPdf(true);
                    setBtnPdf({icon: faFilePdf, text: "Ver Ticket .Pdf", spin: false, disabled: false})
                    setBotonAc (state => ({...state, icon: faCogs, spin: false, disabled: false}))
                }) 
            }
        }
    }

    let modalPdf = () => {
        if (openModalPdf === false) return null;

        let contentModal = () => (
            <div style = {{height: "74vh"}}>
                <embed
                    type = "application/pdf"
                    src = {src}
                    id = "pdfDocument"
                    width = "100%"
                    height = "100%"
                />
            </div>
        )

        return (
            <ContentModal2
                isOpen = {openModalPdf}
                title = {`Detalles del Ticket`/* `Ticket - [${props.data.nombre_zona} - ASIENTO ${props.data.butaca_fila}${props.data.butaca_columna}]` */}
                size = "xl"
                content = {contentModal()}
                close = {() => {setOpenModalPdf(false)}}
            />
        )
    }

    let modalAprobarPago = () => {
        if (openModalAprPay === false) return null;

        let detallePagoFactura = JSON.parse(detallePago);
        let metodos = detallePagoFactura.tipo_t === "multipago" ? JSON.parse(detallePagoFactura.metodos) : null;
        let imagenes = detallePagoFactura.capture_img;
        
        let aprobar = () => {
        if (botonAprobar.disabled === true || botonNoAprobar.disabled === true) return null;
            setBotonNoAprobar(state => ({...state,  disabled: true}))
            setBotonAprobar({ icon: faCircleNotch, text: "Espera", spin: true, disabled: true })
            setBotonAc ({ icon: faCircleNotch, text: "Acciones", spin: true, disabled: true })
            
            AprobarTransaccion(tokenTicket, detallePagoFactura.capture_img, (resp) => {
                setBotonNoAprobar(state => ({...state,  disabled: false}))
                setBotonAprobar({ icon: faCheck, text: "Aprobar", spin: false, disabled: false })
                setBotonAc ({ icon: faCogs, text: "Acciones", spin: false, disabled: false })

                switch (true) {
                    case (resp.status === "error" || resp.status === "error_log"):
                        toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                    break;
                    default:
                        props.updLista(resp)
                        toast.info(<small><FontAwesomeIcon icon = {faInfoCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                        setOpenModalAprPay(false)
                    break;
                }
            })
        }

        let noAprobar = () => {
            if (botonAprobar.disabled === true || botonNoAprobar.disabled === true) return null;
            setBotonAprobar(state => ({...state,  disabled: true}))
            setBotonNoAprobar({ icon: faCircleNotch, text: "Espera", spin: true, disabled: true })
            setBotonAc ({ icon: faCircleNotch, text: "Acciones", spin: true, disabled: true })

            NoAprobarTransaccion(tokenTicket, detallePagoFactura.capture_img, (resp) => {
                setBotonAprobar(state => ({...state,  disabled: false}))
                setBotonNoAprobar({ faTimes, text: "No Aprobar", spin: false, disabled: false })
                setBotonAc ({ icon: faCogs, text: "Acciones", spin: false, disabled: false })

                switch (true) {
                    case (resp.status === "error" || resp.status === "error_log"):
                        toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                    break;
                    default:
                        props.updLista(resp)
                        toast.info(<small><FontAwesomeIcon icon = {faInfoCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                        setOpenModalAprPay(false)
                    break;
                }
            })
        }
 
        
        let contentModal = () => (
            
            <Row>
                 {/* Condicionar en el caso de que sea zelle y y en caso que sea  transferencia*/}
           
                {/* Condicionar en el caso de que sea zelle y y en caso que sea  transferencia*/}
                {detallePagoFactura.tipo_t === "transferencia" && (
                    <>
                     <Col md = {8}>
                        <div className = "card card-body" style = {{padding: 6}}>
                            <div><span><strong>Capture de la transacción</strong></span></div>
                            <img src = {`${UrlTmp}${detallePagoFactura.capture_img}`} alt = "img-capture"/>
                        </div>
                    </Col>
                    
                 
                    <Col md={4}>
                        <div><span><strong>N° de la transacción</strong></span></div>
                        <span className="text-danger" style={{ fontSize: 16 }}>{detallePagoFactura.num_transaccion?.toUpperCase()}</span>
                        <div className="custom-mt-10"><span><strong>Nombre del titular</strong></span></div>
                        <span style={{ fontSize: 16 }}>{detallePagoFactura.user_data?.nombre.toUpperCase()}</span>
                        <div className="custom-mt-10"><span><strong>Correo del titular</strong></span></div>
                        <span style={{ fontSize: 16 }}>{detallePagoFactura.user_data?.email.toUpperCase()}</span>
                        <div className="custom-mt-10"><span><strong>Fecha de la transacción</strong></span></div>
                        <span style={{ fontSize: 16 }}>{detallePagoFactura.fecha}</span>
                        <div className="custom-mt-20 text-danger" stlye={{ borderTop: "1px solid #424224" }}>
                            <strong>¿Qué deseas realizar con esta transacción?</strong>
                        </div>
                        <div align="right" className="custom-mt-10">
                            <Button color="primary" onClick={aprobar} disabled={botonAprobar.disabled}><FontAwesomeIcon icon={botonAprobar.icon} spin={botonAprobar.spin} /> {botonAprobar.text}</Button>
                            <Button color="warning" onClick={noAprobar} disabled={botonNoAprobar.disabled}><FontAwesomeIcon icon={botonNoAprobar.icon} spin={botonNoAprobar.spin} /> {botonNoAprobar.text}</Button>
                        </div>
                        <div className="custom-mt-20" align="right" stlye={{ borderTop: "1px solid #424224" }}>
                            <strong>IMPORTANTE: Esta opción no puede deshacerse</strong>
                        </div>
                    </Col>
                    </>
                    )}
                     {/* Condicionar en el caso de que sea zelle y y en caso que sea  transferencia*/}
                {detallePagoFactura.tipo_t === "zelle" && (
                    <>
                    
                    <Col md = {8}>
                    <div className = "card card-body" style = {{padding: 6}}>
                        <div><span><strong>Capture de la transacción</strong></span></div>
                        <img src = {`${UrlTmp}${detallePagoFactura.capture_img}`} alt = "img-capture"/>
                    </div>
                </Col>
                <Col md={4}>
                        <div><span><strong>N° de la transacción</strong></span></div>
                        <span className="text-danger" style={{ fontSize: 16 }}>{detallePagoFactura.num_transaccion?.toUpperCase()}</span>
                        <div className="custom-mt-10"><span><strong>Nombre del titular Zelle</strong></span></div>
                        <span style={{ fontSize: 16 }}>{detallePagoFactura.titular?.toUpperCase()}</span>
                        <div className="custom-mt-10"><span><strong>Correo del titular Zelle</strong></span></div>
                        <span style={{ fontSize: 16 }}>{detallePagoFactura.email_titular?.toUpperCase()}</span>
                        <div className="custom-mt-10"><span><strong>Fecha de la transacción</strong></span></div>
                        <span style={{ fontSize: 16 }}>{detallePagoFactura.fecha}</span>
                        <div className="custom-mt-20 text-danger" stlye={{ borderTop: "1px solid #424224" }}>
                            <strong>¿Qué deseas realizar con esta transacción?</strong>
                        </div>
                        <div align="right" className="custom-mt-10">
                            <Button color="primary" onClick={aprobar} disabled={botonAprobar.disabled}><FontAwesomeIcon icon={botonAprobar.icon} spin={botonAprobar.spin} /> {botonAprobar.text}</Button>
                            <Button color="warning" onClick={noAprobar} disabled={botonNoAprobar.disabled}><FontAwesomeIcon icon={botonNoAprobar.icon} spin={botonNoAprobar.spin} /> {botonNoAprobar.text}</Button>
                        </div>
                        <div className="custom-mt-20" align="right" stlye={{ borderTop: "1px solid #424224" }}>
                            <strong>IMPORTANTE: Esta opción no puede deshacerse</strong>
                        </div>
                    </Col>
                    </>
                   
                )}
                {/* Condicionar en el caso de que sea multipago*/}
                {detallePagoFactura.tipo_t === "multipago" && (
                    <>

                    <Col md={8}>
                        {metodos.map((metodo, index) => {
                        let metodoName = metodo.metodo ? metodo.metodo : (metodo.method ? metodo.method : null);
                        
                        return (
                            <div key={index}>
                                <div className="card card-body" style={{ padding: 6 }}>
                                    <div><h5><strong>{metodoName}</strong></h5></div>
                                    
                                    {(imagenes && imagenes !== undefined) ? <div><div><span><strong>Capturas de la transacción</strong></span></div> <img src={`${UrlTmp}${imagenes[index]}`} alt={`img-capture-${index}`}/></div> : 
                                    <Row>
                                        <Col>
                                        <h6>Referencia Bancaria:</h6>
                                            <span><h5>{metodo.ref}</h5></span>
                                        </Col>
                                        <Col>
                                            <h6>Monto:</h6>
                                            <span><h5>{metodo.amount} {metodo.moneda} </h5></span>

                                        </Col>
                                    </Row> }
                                </div>
                            </div>
                        )
                    })}
                    </Col>

                    <Col md={4}>
                        <div><span><strong>N° de la transacción</strong></span></div>
                        <span className="text-danger" style={{ fontSize: 16 }}>{detallePagoFactura.num_transaccion?.toUpperCase()}</span>
                        <div className="custom-mt-10"><span><strong>Nombre del titular</strong></span></div>
                        <span style={{ fontSize: 16 }}>{detallePagoFactura.user_data.nombre?.toUpperCase()}</span>
                        <div className="custom-mt-10"><span><strong>Correo del titular</strong></span></div>
                        <span style={{ fontSize: 16 }}>
                        {detallePagoFactura.user_data.email 
                            ? detallePagoFactura.user_data.email.toUpperCase() 
                            : detallePagoFactura.user_data.correo 
                            ? detallePagoFactura.user_data.correo.toUpperCase() 
                            : ''}
                        </span>
                        <div className="custom-mt-10"><span><strong>Fecha de la transacción</strong></span></div>
                        <span style={{ fontSize: 16 }}>{detallePagoFactura.fecha}</span>
                        <div className="custom-mt-20 text-danger" stlye={{ borderTop: "1px solid #424224" }}>
                            <strong>¿Qué deseas realizar con esta transacción?</strong>
                        </div>
                        <div align="right" className="custom-mt-10">
                            <Button color="primary" onClick={aprobar} disabled={botonAprobar.disabled}><FontAwesomeIcon icon={botonAprobar.icon} spin={botonAprobar.spin} /> {botonAprobar.text}</Button>
                            <Button color="warning" onClick={noAprobar} disabled={botonNoAprobar.disabled}><FontAwesomeIcon icon={botonNoAprobar.icon} spin={botonNoAprobar.spin} /> {botonNoAprobar.text}</Button>
                        </div>
                        <div className="custom-mt-20" align="right" stlye={{ borderTop: "1px solid #424224" }}>
                            <strong>IMPORTANTE: Esta opción no puede deshacerse</strong>
                        </div>
                    </Col>
                    </>
                )}
            </Row>
            
        )

        return (
            <ContentModal2
                isOpen = {openModalAprPay}
                title = {`Detalles de la Compra`}
                size = "xl"
                content = {contentModal()}
                close = {() => {setOpenModalAprPay(false)}}
            />
        )
    }

    let statusTickets = () => {
        if(detalleTicket === "") return null
        if (statusTicket === "0"){
            return (<div className = "alert alert-warning" style = {{fontSize: 12}}><FontAwesomeIcon icon = {faExclamationTriangle} /> Pago por confirmar</div>)
        }else{
            return (<div className = "alert alert-success" style = {{fontSize: 12}}><FontAwesomeIcon icon = {faCheckCircle} /> Pago confirmado</div>)
        }
    }

    let botonAprobarPago = () => {
        if (statusTicket === "0"){
            return (
                <DropdownItem title = "Permite ver los detalles del pago y aprobarlo o rechazarlo" style = {{padding: 5}} onClick = {() => setOpenModalAprPay(true)}><FontAwesomeIcon style = {{color: "#1d592a"}} icon = {faCheckSquare}/><small>Aprobar Pago</small></DropdownItem>
            )
        }else{
            return (
                <>
                    {/* <DropdownItem style = {{padding: 5}} onClick = {generarPdf}><FontAwesomeIcon style = {{color: "#c80f0f"}} icon = {btnFactura.icon} spin = {btnFactura.spin}/><small>{btnFactura.text}</small></DropdownItem> */}
                    <DropdownItem style = {{padding: 5}} onClick = {generarPdf}><FontAwesomeIcon style = {{color: "#c80f0f"}} icon = {btnPdf.icon} spin = {btnPdf.spin}/><small>{btnPdf.text}</small></DropdownItem>
                </>
            )
        } 
    }

    return (
        <>
            <tr>
                <td width = "10px" align = "center" valign = "middle"><strong>{props.num}</strong></td>
                <td width = "15%">{props.data.data_ticket.idFactura}</td>
                <td>
                    <Table>
                        <tbody>
                            <tr>
                                <td><strong>Zona</strong></td>
                                <td><strong>Asiento</strong></td>
                                <td><strong>Precio</strong></td>
                            </tr>
                            {tickets()}
                        </tbody>
                    </Table>
                </td>
                <td>
                    <Alert color = "info" onClick = {iconC} style = {{color: "#0665d1", backgroundColor: "#d0e4f6", cursor: "pointer"}}>
                        <Row>
                            <Col md = {6}>
                                <small>DETALLES DE LA COMPRA</small>
                            </Col>
                            <Col md = {6}>
                                <small>RESUMEN DEL PAGO</small>
                            </Col>
                        </Row>
                        <span style = {{position: "absolute", right: 11, top: 16}}><FontAwesomeIcon icon = {colapsIcon}/></span>
                    </Alert>
                    <Collapse isOpen = {colaps}>
                        <div className = "custom-background-fff" style = {{padding: 10}}>
                            <Row>
                                <Col md = {6}>
                                    {detalle()}
                                </Col>
                                <Col md = {6}>
                                    {resumen()}
                                </Col>
                            </Row>
                        </div>
                    </Collapse>
                    <Row>
                        <Col md = {7} className = "custom-mt-10">
                            {statusTickets()}
                        </Col>
                        <Col md = {5} align = "right" className = "custom-mt-10">
                            {iconPay()}
                        </Col>
                    </Row>
                </td>
                <td align = "center" >
                    <ButtonDropdown isOpen={dropdownOpen} toggle = {() => { setDropdownOpen(!dropdownOpen) }}>
                        <DropdownToggle caret className = "btn-outline-info" size = "sm">
                            <small><FontAwesomeIcon icon = {botonAc.icon} spin = {botonAc.spin}/> {botonAc.text}</small>
                        </DropdownToggle>
                        <DropdownMenu>
                            {botonAprobarPago()}
                        </DropdownMenu>
                    </ButtonDropdown>
                </td>
            </tr>
            {modalPdf()}
            {modalAprobarPago()}
        </>
    )
}

export default DetalleVenta;