import React, { useEffect, useState } from 'react';

import { Modal } from "reactstrap";

const WarnModal = (props) => {
    const [open, setOpen] = useState(props.isOpen);

    useEffect(() => {
        setOpen(props.isOpen)
    }, [props]);

    return (
        <Modal className = "modal-dialog-centeredmodal modal-warning" contentClassName="bg-gradient-warning" size = "md" isOpen = {open} toggle = {() => props.close()}>
            <div className="modal-header">
                <h6 className="modal-title" id="modal-title-notification" style = {{color: "#fff"}}>
                    <i className = "fa fa-exclamation-triangle"></i> <strong>Importante</strong>
                </h6>
                <button aria-label = "Close" className = "close"  data-dismiss = "modal" type = "button" onClick = {() => props.close()}>
                <span aria-hidden = {true} style = {{color: "#fff"}}>×</span>
                </button>
            </div>
            <div className="modal-body" style = {{background: "#ffffff", color: "#636363"}}>
                <div style = {{padding: 5}}><span dangerouslySetInnerHTML = {{ __html: props.mess }}></span></div>
            </div>
        </Modal>
    )
}

export default WarnModal;