import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { toast } from 'react-toastify';
import QRCode from 'qrcode.react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown, faCalendarAlt, faClock, faTags, faUsers, faCircleNotch, faCheckCircle, faInfoCircle, faArrowCircleLeft, faFile, faUniversity, faMap, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, Table, Row, Col, Alert, Container, CardBody, CardTitle } from "reactstrap";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { PaypalStatus } from "functions/EventosFunctions";
import { UrlImg } from "functions/Url";
import { UrlMain } from "functions/Url";

import NavbarI from "components/Navbars/NavbarI.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import TimerTicket from "components/Timers/TimerTicket";
import ContentModal2 from "components/Modals/ContentModal2";
import Compartir from "components/Social/Compartir";

import logoPaypal from "assets/img/logos/paypal-logo.png";

let totalPay = 0;

const PayPalEvento = (props) => {
    const [redirect, setRedirect]   = useState(false)
    const [redirect2, setRedirect2] = useState(false)
    const [control, setControl]     = useState(false)
    const [urlE, setUrlE]           = useState ("");
    const [preloader, setPreloader] = useState(true);
    const [detalleEvento, setDetalleEvento] = useState([JSON.parse(sessionStorage.getItem("ContentDataEvent"))]);
    const [payDetalle, setPayDetalle]       = useState(<Skeleton count = {9}/>);
    const [payQR, setPayQR]                 = useState(<Skeleton height = {200} width = {200}/>);
    const [ticketDetalle, setTicketDetalle] = useState(<Skeleton count = {9}/>);
    const [openModal, setOpenModal]         = useState(false);
    const [detalleFactura, setDetalleFactura]   = useState("");
    const [urlShare, setUrlShare]           = useState("https://www.novasapiens.cl/posts/botones-de-compartir-con-redes-sociales-en-gatsby-js")
    const [alertVisible, setAlertVisible]   = useState(true);

    useEffect(() => {
        totalPay = 0;
        let ContentResp = JSON.parse(sessionStorage.getItem("ContentResp"))
        let ContentDataEvent = JSON.parse(sessionStorage.getItem("ContentDataEvent"));

        if (ContentResp === null) {
            let url = window.location.href;
            let part1 = url.split("/");
            let part2 = part1.pop();
            let part3 = part2.split("?");
            let part4 = part3.pop();
            let part5 = part4.split("&");
            
            setUrlE(part2);
    
            if (part5.length >= 3){
                setRedirect(false);
                let Content = new Object();
                part5.forEach(i => {
                    let part = i.split("=");
                    Content[part[0]] = part[1];
                })
    
                toast.info(<small><FontAwesomeIcon icon = {faInfoCircle}/> <span style = {{fontSize: 12}}>Por favor no cierres esta ventana</span></small>);
                PaypalStatus(Content, ContentDataEvent.fecha, ContentDataEvent.hora_in, (resp) => {
                    setControl(!control);
                    switch (true) {
                        case (resp.status === "error" || resp.status === "error_log"):
                            toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                            setRedirect(true)
                        break;
                        case (resp.status === "pay_warn"):
                            toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                            setRedirect2(true)
                        break;
                        case (resp.infoTickets.length === 0):
                            toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span style = {{fontSize: 12}}>Ocurrió un error al cargar la página</span></small>);
                            setRedirect(true)
                        break;
                        default:
                            /* Detener Cronometro */
                            let Content = {
                                D001: null,
                                D002: null
                            }
                            sessionStorage.setItem("ContentDataT", JSON.stringify(Content));
                            sessionStorage.removeItem("ContentDataT")
                            sessionStorage.removeItem("ContentDataButacas")
                            sessionStorage.removeItem("ContentDataPrecioZona")
                            setControl(!control);
    
                            setPayQR(<div className = "qr-code-zona">
                                <div className = "qr-code">
                                    <QRCode bgColor = "#ffffff" fgColor = "#424242" renderAs = "svg" xmlns = 'http://www.w3.org/2000/svg' value = {`${UrlMain}/verify_tickets?token=${resp.infoTickets[0].token}`}/>
                                </div>
                                <div className = "qr-code-text"><small>No reveles este código</small></div>
                            </div>)
    
                            let ContentResp = {
                                dataPago: resp.dataPago,
                                infoTickets: resp.infoTickets,
                                detallesPago: resp.detallesPago,
                                config: resp.config
                            }

                            sessionStorage.setItem("ContentResp", JSON.stringify(ContentResp));


                            setDetalleFactura(resp.detallesPago)
                            setPayDetalle(infoContent(resp))
                            setTicketDetalle(ticketContent(resp))
                            setPreloader(false)
                            toast.success(<small><FontAwesomeIcon icon = {faCheckCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }} style = {{fontSize: 12}}></span></small>);
                        break;
                    }
                })
            }else{
                toast.error(<small><FontAwesomeIcon icon = {faFrown}/> <span style = {{fontSize: 12}}>Ocurrió un error al cargar el pago</span></small>);
                setRedirect(true);
            }
        }else{
            setPayQR(<div className = "qr-code-zona">
                <div className = "qr-code">
                    <QRCode bgColor = "#ffffff" fgColor = "#424242" renderAs = "svg" xmlns = 'http://www.w3.org/2000/svg' value = {`${UrlMain}/verify_tickets?token=${ContentResp.infoTickets[0].token}`}/>
                </div>
                <div className = "qr-code-text"><small>No reveles este código</small></div>
            </div>)

            setDetalleFactura(ContentResp.detallesPago)
            setPayDetalle(infoContent(ContentResp))
            setTicketDetalle(ticketContent(ContentResp))
            setPreloader(false)
        }
    }, []);

    let convertMoneda = (moneda, valor) => {
        let ContentCurrency = JSON.parse(sessionStorage.getItem("ContentDataCurrency"));
        let ContentRsp =  JSON.parse(sessionStorage.getItem("ContentResp"));
        
        if(ContentRsp.config.convert_local === 1){
            let newValor = (valor * ContentCurrency.valor_1usd_moneda_local).toFixed(2);
            return `${(ContentRsp.config.moneda.moneda).toUpperCase()} ${newValor}`
        }else{
            return `${(moneda).toUpperCase()} ${valor.toFixed(2)}`
        }
    }
    
    let cambiaFormato = (fecha) => {
        let separa = fecha.split("/");
        return `${separa[1]}/${separa[0]}/${separa[2]}`
    }

    let dia = (date, format) => {
        let diaActual = "";
        (format === true) ? diaActual = new Date(cambiaFormato(date)) : diaActual = new Date(date)
        let day = diaActual.getDate();

        if(day < 10) day = `0${day}`;

        let dias = [ 'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        let meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ]
        let month = meses[diaActual.getMonth()];

        return `${dias[diaActual.getDay()]} ${day} de ${month} de ${diaActual.getFullYear()}`;
    }

    let infoContent = (data) => (
        <Row style = {{marginLeft: 5, paddingBottom: 10,}}>
            <Col md = {8} style = {{borderBottom: "1px dashed #cccccc", paddingBottom: 10}}>
                <div className = "text-primary"><strong>{(data.dataPago.metodo).toUpperCase()}</strong></div>
                <div style = {{fontSize: 12}}>{dia(data.dataPago.fecha, false)}</div>
            </Col>
            <Col md = {4} style = {{borderBottom: "1px dashed #cccccc", paddingBottom: 10}} align = "right" className = "text-primary">
                <strong>{convertMoneda(data.dataPago.moneda, data.dataPago.total)}</strong>
            </Col>
            <Col md = {12} className = "custom-mt-10">
                ID de la Transacción: <strong>{data.dataPago.transactionId}</strong>
            </Col>
            <Col md = {4} className = "custom-mt-10">
                <div><small><strong>Titular de la Cuenta</strong></small></div>
                <div><small>{`${data.dataPago.email}`}</small></div>
                <div><small>{`${data.dataPago.nombre} ${data.dataPago.apellido}`}</small></div>
                <div><small>{`${data.dataPago.linel}`}</small></div>
                <div><small>{`${data.dataPago.state}`}</small></div>
                <div><small>{`${data.dataPago.city}`}</small></div>
                <div><small>{`${data.dataPago.postalCode}`}</small></div>
                <div><small>{`${data.dataPago.countryCode}`}</small></div>
            </Col>
            <Col md = {8} className = "custom-mt-10">
                <div><small><strong>Información del vendedor</strong></small></div>
                <div><small>{`${data.dataPago.vendedorEmail}`}</small></div>
                <div className ="custom-mt-10"><small><strong>Información de la compra</strong></small></div>
                <Row>
                    <Col md = {8} style = {{borderBottom: "1px solid #cccccc", paddingBottom: 10, fontSize: 14}} className = "text-danger"><small>{`${data.dataPago.descripcion}`}</small></Col>
                    <Col md = {4} style = {{borderBottom: "1px solid #cccccc", paddingBottom: 10}} align = "right"><small>{convertMoneda(data.dataPago.moneda, data.dataPago.subtotal)}</small></Col>
                    <Col md = {8}><small><strong>Total</strong></small></Col>
                    <Col md = {4} align = "right"><small><strong>{convertMoneda(data.dataPago.moneda, data.dataPago.subtotal)}</strong></small></Col>
                    <Col md = {12} align = "right" className = "custom-mt-10">
                        <Button size = "sm" color = "default"  onClick = {() => {
                            totalPay = 0;
                            setOpenModal(true)
                        }} title = "Ver detalles de la factura"><FontAwesomeIcon icon = {faFile}/> Ver detalles</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )

    let ladoSilla = (string) => {
        let izquierda = string.indexOf("Izquierda");
        let superior = string.indexOf("Superior");
        let derecha = string.indexOf("Derecha");
        let inferior = string.indexOf("Inferior");

        switch (true){
            case (izquierda > 0): return "IZQUIERDA";
            case (superior > 0): return "SUPERIOR";
            case (derecha > 0): return "DERECHA";
            case (inferior > 0): return "INFERIOR";
            default: return "";
        }
    }

    let ticketContent = (data) => {
        let asiento = (info) => {
            switch (true){
                case (info.asiento === ""):
                    return "ZONA LIBRE";
                case (info.sillaCode === null || info.sillaCode === ""):
                    return `ASIENTO ${info.asiento}`;
                default:
                    return `MESA ${info.asiento} - SILLA ${ladoSilla(info.sillaCode)} ${info.sillaNum}`;
            }

            /* if (info.sillaCode === null || info.sillaCode === "") return `ASIENTO ${info.asiento}`;
            return `MESA ${info.asiento} - SILLA ${ladoSilla(info.sillaCode)} ${info.sillaNum}` */
        }

        let tickets = () => (
            (data.infoTickets).map(option => (
                <React.Fragment  key = {option.id}>
                    <Col md = {8} className = "custom-mt-10">
                        <h6 className = "text-default"><strong>{(detalleEvento[0].evento_info.name_event).toUpperCase()}</strong></h6>
                        <div><small><FontAwesomeIcon icon = {faUniversity}/> {detalleEvento[0].recinto.name}</small></div>
                        <div><small><FontAwesomeIcon icon = {faMap}/> {detalleEvento[0].recinto.address}</small></div>
                        <div><small><FontAwesomeIcon icon = {faCalendarAlt}/> {`${dia(option.fecha, true)}`}</small></div>
                        <div><small><FontAwesomeIcon icon = {faClock}/> {`${option.desde}`}<strong> (Apertura {option.apertura})</strong></small></div>
                    </Col>
                    <Col md = {4} align = "right" className = "custom-mt-10" style = {{fontSize: 14}}>
                        <small className = "text-danger"><strong>{option.zona}</strong></small> | <small className = "text-danger"><strong>{asiento(option)}</strong></small>
                        <div><small><strong>{convertMoneda(option.moneda, option.precio)}</strong></small></div>
                    </Col>
                    <Col md = {12} style = {{borderBottom: "1px dashed #cccccc", paddingBottom: 10, fontSize: 11}} align = "center">
                    </Col>
                </React.Fragment>
            ))
        )

        return(
            <Row style = {{marginLeft: 5}}>
                {tickets()}
            </Row>
        )
    }

    if (redirect === true) return <Redirect to = {`/event/${urlE}`}/>;
    if (redirect2 === true) return <Redirect to = {`/`}/>;

    let contentEvento = () => (
        <>
            <div className = "header-banner">
                <img alt = "..." className = "img-fluid" src = {`${UrlImg}events/${detalleEvento[0].evento_info.image}`} />
            </div>
            <div className = "banner-button_2">
                <Card className="card-lift--hover shadow border-0" style = {{width: "30vw !important"}}>
                    <CardBody className="py-5">
                        <div className= {`text-uppercase`} style = {{fontSize: 20}}>
                            <strong>{detalleEvento[0].evento_info.name_event}</strong>

                            <Row>
                                <Col className = "text-lg-left custom-mt-10" lg = "12" style = {{maxHeight: "35vh", overflowY: "auto"}}>
                                    <div><small className = "text-muted" style = {{fontSize: 12}}><FontAwesomeIcon icon = {faCalendarAlt} className = "text-info"/> {dia(detalleEvento[0].fecha, true)}</small></div>
                                    <div><small className = "text-muted" style = {{fontSize: 12}}><FontAwesomeIcon icon = {faClock} className = "text-info"/> {detalleEvento[0].hora_in} <strong>(Apertura {detalleEvento[0].hora_apertura})</strong></small></div>
                                    <div><small className = "text-muted" style = {{fontSize: 12}}><FontAwesomeIcon icon = {faTags} className = "text-info"/> {detalleEvento[0].evento_info.categorias_tags}</small></div>
                                    <div><small className = "text-muted" style = {{fontSize: 12}}><FontAwesomeIcon icon = {faUsers} className = "text-info"/> {detalleEvento[0].evento_info.artistas_tags}</small></div>
                                    <Compartir url = {urlShare} size = {30} className = "custom-mt-20"/>
                                </Col>
                                <Col md = {12}>
                                    <Link to = "/" className = "mt-4 btn btn-outline-warning" style = {{borderRadius: 15}}><FontAwesomeIcon icon = {faArrowCircleLeft}/> Volver</Link>
                                </Col>
                                <Col md = {12} style = {{paddingRight: 20}}>
                                    <TimerTicket control = {control}/>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </>
        
    )

    let contentEvento2 = () => (
        <Container className = "custom-margin">
            <Row>
                <Col md = {12} style = {{marginTop: 60}}>
                    <Card className = "main-card mb-3" style = {{borderRadius: 30}}>
                        <CardTitle className = "twitter">
                            <strong>DETALLES DE TU COMPRA</strong>
                        </CardTitle>
                        <CardBody>
                            <Row>
                                <Col md = {12} className = "custom-pr-10" style = {{paddingBottom: 20}}>
                                    <Alert color= "success" style = {{color: "#424242"}} isOpen = {alertVisible} toggle = {() => setAlertVisible(false)}>
                                        <FontAwesomeIcon icon = {faCheckCircle}/> Hemos enviado a tu correo los detalles de tu compra, así como los boletos. Si no lo encuentras, revisa tu carpeta de Spam
                                    </Alert>
                                </Col>
                                <Col md = {8}>
                                    <div>
                                        {payDetalle}    
                                    </div>
                                    <h6 className = "text-info custom-mt-20" style = {{marginLeft: 20}}><strong>DETALLES DE TUS TICKETS</strong></h6>
                                    <div>
                                        {ticketDetalle}    
                                    </div>
                                </Col>
                                <Col md = {4} align = "center">
                                    <h6 className = "text-info"><strong>CÓDIGO QR</strong></h6>
                                    <div>
                                        {payQR}    
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
    
    let preloadTicker = () => {
        if (preloader === true){
            return (
                <div className = "preload-ticket">
                <div className = "preload-ticket-icon">
                    <FontAwesomeIcon icon = {faCircleNotch} size = "5x" spin = {true}/>
                    <div style = {{marginLeft: -35}}>Confirmando el pago</div>
                    </div>
                </div>
            )
        }else{
            return null;
        }
    }

    let contentModal = () => {
        let tickets = detalleFactura.dataTickets
        let impuestos = detalleFactura.dataImpuestos
        let servTaquilla = detalleFactura.taquilla
        let impTaquilla = detalleFactura.dataImpTaquilla
        let aplicable = Boolean(servTaquilla.aplica);

        let totalPago = () => {
            let cant = 0;
            let moneda = ""
            tickets.forEach(opt => {
                moneda = opt.moneda;
                cant += opt.precio;
            })
            
            let totalP = totalPay + cant;
            totalPay = totalP

            return convertMoneda(moneda, cant);
        }

        let totalAPagar = () => {
            let cant = 0;
            let moneda = ""
            tickets.forEach(opt => {
                moneda = opt.moneda;
                cant += opt.precio;
            })

            impuestos.forEach(opt => cant += parseFloat(opt.monto))

            if (aplicable === true) cant += servTaquilla.valor;
            impTaquilla.forEach(opt => cant += parseFloat(opt.monto))
            

            return convertMoneda(moneda, cant); 
        }

        let contentCompra = () => {   
            let asiento = (info) => {
                switch (true){
                    case (info.asiento === ""):
                        return "ZONA LIBRE";
                    case (info.sillaCode === null || info.sillaCode === ""):
                        return `ASIENTO ${info.asiento}`;
                    default:
                        return `MESA ${info.asiento} - SILLA ${ladoSilla(info.sillaCode)} ${info.sillaNum}`;
                }           
            }

            return (
                tickets.map(option => (
                    <tr key = {option.id}>
                        <td style = {{padding: 5}} className = "text-danger" width = "40%">{option.zona}</td>
                        <td style = {{padding: 5}} width = "40%">{asiento(option)}</td>
                        <td style = {{padding: 5}} width = "20%" align = "right">{convertMoneda(option.moneda, option.precio)}</td>
                    </tr>
                ))
            )
        }

        let contentImpuestos = () => {
            if (impuestos.length === 0) return(
                <tr>
                    <td colSpan = {3} style = {{padding: 5}}>No aplica</td>
                </tr>
            )

            return(
                impuestos.map(option => (
                    <tr key = {option.id}>
                        <td colSpan = {2} align = "right" width = "80%" style = {{padding: 5}}>{option.nombre.nombre} {option.valor}</td>
                        <td width = "20%" align = "right" style = {{padding: 5}}>{convertMoneda(option.moneda, option.monto)}</td>
                    </tr>
                ))
            )
        }

        let contentImpTaquilla = () => {
            if (impTaquilla.length === 0) return null;

            return(
                impTaquilla.map(option => (
                    <tr key = {option.id}>
                        <td colSpan = {2} align = "right" width = "80%" style = {{padding: 5}}>{option.nombre.nombre} {option.valor}</td>
                        <td width = "20%" align = "right" style = {{padding: 5}}>{convertMoneda(option.moneda, option.monto)}</td>
                    </tr>
                ))  
            )
        }

        let contentServTaquilla = () => {
            if (aplicable === true){
                let totalP = totalPay + servTaquilla.valor;
                totalPay = totalP

                return (
                    <>
                        <tr>
                            <td colSpan = {2} align = "right" style = {{padding: 10, paddingRight: 5}}><strong>Servicios de taquilla:</strong></td>
                            <td align = "right" style = {{padding: 10, paddingRight: 5}}>{convertMoneda(servTaquilla.moneda, servTaquilla.valor)}</td>
                        </tr>
                        {contentImpTaquilla()}
                    </>
                )
            }else{
                return null;
            }

        }


        let logo = () => {
            switch (detalleFactura.metodo){
                case "paypal": return <img src = {logoPaypal} alt = "logo-paypal" height = "auto" width = "100px" className = "img img-fluid"/>
                default : return null;
            }
        }

        return (
            <>
                <Row>
                    <Col md = {7}>
                        <small>Método seleccionado: <strong>{detalleFactura.metodo}</strong></small>
                    </Col>
                    <Col md = {5} align = "right">
                        {logo()}
                    </Col>
                </Row>
                <Table className = "mb-0 custom-mt-10" hover style = {{fontSize: 10, border: "1px solid #ccc"}}>
                    <tbody>
                        {contentCompra()}
                        <tr>
                            <td colSpan = {2} align = "right" style = {{padding: 5, backgroundColor: "#edebeb"}}>
                                <strong>SUB-TOTAL:</strong>
                            </td>
                            <td style = {{padding: 5, backgroundColor: "#edebeb"}} align = "right">
                                <strong>{totalPago()}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan = {2} align = "right" style = {{padding: 10, paddingRight: 5}}><strong>Impuestos:</strong></td>
                            <td></td>
                        </tr>
                        {contentImpuestos()}
                        {contentServTaquilla()}
                        <tr className = "text-danger" style = {{fontSize: 12}}>
                            <td colSpan = {2} align = "right" style = {{padding: 5, backgroundColor: "#edebeb"}}>
                                <strong>TOTAL A PAGAR:</strong>
                            </td>
                            <td style = {{padding: 5, backgroundColor: "#edebeb"}} align = "right">
                                <strong>{totalAPagar()}</strong>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </>
        )
        
    }

    let modal = () => {
        if (openModal === false) return null;
        return (
            <ContentModal2
                isOpen = {openModal}
                title = "Detalles de tu compra"
                size = "md"
                content = {contentModal()}
                close = {() => setOpenModal(false)}
            />
        )
    }

    return (
        <>
            {preloadTicker()}
            <NavbarI />
            <section className="section pb-0 section-components" style = {{backgroundColor: "#f9f9f9"}}>
                <div className="py-5">
                    <Container className = "custom-margin">
                        {contentEvento()}
                    </Container>
                    <div>
                        {contentEvento2()}
                    </div>
                </div>
            </section>
            {modal()}
            <CardsFooter />
        </>
    )
}

export default PayPalEvento;
