import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClock, faTags, faUsers, faCheckCircle, faUniversity, faArrowCircleLeft, faFile, faMap } from '@fortawesome/free-solid-svg-icons';

import { Button, Card, Table, Row, Col, Alert, Container, CardBody, CardTitle } from "reactstrap";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { UrlImg } from "functions/Url";

import NavbarI from "components/Navbars/NavbarI.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import TimerTicket from "components/Timers/TimerTicket";
import ContentModal2 from "components/Modals/ContentModal2";
import Compartir from "components/Social/Compartir";

import logaTransfer from "assets/img/logos/Pago_Movil.png";

let totalPay = 0;

const TransferEvento = (props) => {
    const [detalleEvento, setDetalleEvento] = useState([JSON.parse(sessionStorage.getItem("ContentDataEvent"))]);
    const [control, setControl]             = useState(false)
    const [urlShare, setUrlShare]           = useState("https://www.novasapiens.cl/posts/botones-de-compartir-con-redes-sociales-en-gatsby-js")
    const [payDetalle, setPayDetalle]       = useState(<Skeleton count = {9}/>);
    const [ticketDetalle, setTicketDetalle] = useState(<Skeleton count = {9}/>);
    const [openModal, setOpenModal]         = useState(false);
    const [detalleFactura, setDetalleFactura]   = useState("");
    const [alertVisible, setAlertVisible]   = useState(true);

    useEffect(() => {
        let Content = JSON.parse(sessionStorage.getItem("ContentResp"))
        let ContentD = {
            D001: null,
            D002: null
        }

        sessionStorage.setItem("ContentDataT", JSON.stringify(ContentD));
        sessionStorage.removeItem("ContentDataT")
        setControl(!control);

        setPayDetalle(infoContent(Content.dataPago))
        setTicketDetalle(ticketContent(Content.infoTickets))

        setDetalleFactura(Content.detallesPago)
    }, []);


    let convertMonedaImpuesto = (moneda, valor) => {
        let ContentCurrency = JSON.parse(sessionStorage.getItem("ContentDataCurrency"));
        let ContentRsp =  JSON.parse(sessionStorage.getItem("ContentResp"));
        let impuesto = JSON.parse(sessionStorage.getItem("ContentDataImpuesto"));
        let impuestos = impuesto.impuesto;
        let imp = 0
        
        if (impuestos){
            impuestos.forEach(opt => imp += opt.valor * valor / 100);
            // if (aplicable === true) cant -= parseFloat(servTaquilla.valor * cant / 100);
            // impTaquilla.forEach(opt => cant = parseFloat(opt.monto))
        }


        
        if(ContentRsp.config.convert_local === 1){
            let noImp = valor - imp;
            let newValor = (noImp * ContentCurrency.valor_1usd_moneda_local).toFixed(2);
            return `${(ContentRsp.config.moneda.moneda).toUpperCase()} ${newValor}`
        }else{
            return `${(moneda).toUpperCase()} ${valor.toFixed(2)}`
        }
    }

    let convertMoneda = (moneda, valor) => {
        let ContentCurrency = JSON.parse(sessionStorage.getItem("ContentDataCurrency"));
        let ContentRsp =  JSON.parse(sessionStorage.getItem("ContentResp"));
        
        if(ContentRsp.config.convert_local === 1){
            let newValor = (valor * ContentCurrency.valor_1usd_moneda_local).toFixed(2);
            return `${(ContentRsp.config.moneda.moneda).toUpperCase()} ${newValor}`
        }else{
            return `${(moneda).toUpperCase()} ${valor.toFixed(2)}`
        }
    }

    let infoContent = (data) => {

        let impuesto = JSON.parse(sessionStorage.getItem("ContentResp"));
        let impuestos = impuesto.detallesPago.taquilla.valor;

        let imp = parseInt(data.monto) * impuestos /100 ;

        return (
        <Row className = "custom-border_h" style = {{marginLeft: 5, boxShadow: "1px 1px #cccccc", paddingBottom: 10,}}>
            <Col md = {8} style = {{borderBottom: "1px dashed #cccccc", paddingBottom: 10}}>
                <div><img src = {logaTransfer} alt = "logo-transferencia" height = "auto" width = "100px" className = "img img-fluid img-thumbnail"/></div>
                <div className = "text-primary"><strong>N° {data.num_transaccion}</strong></div>

            </Col>
            <Col md = {4} style = {{borderBottom: "1px dashed #cccccc", paddingBottom: 10}} align = "right" className = "text-primary">
                <strong>{data.moneda} {data.monto}</strong>
            </Col>
            <Col md = {4} className = "custom-mt-10">
                <div><small><strong>Nombre del titular</strong></small></div>
                <small>{data.user_data.nombre?.toUpperCase()}</small>
                <div><small><strong>Correo del titular</strong></small></div>
                <small>{data.user_data.correo?.toUpperCase()}</small>
                <div><small><strong>Fecha de la transacción</strong></small></div>
                <small>{(data.fecha)}</small>
            </Col>
            <Col md = {8} className = "custom-mt-10">

                <div className = "custom-mt-10"><small><strong>Status</strong></small></div>
                <small>{data.status}</small>
                <div className = "custom-mt-10"><small><strong>Información de la compra</strong></small></div>
                <Row>
                    <Col md = {8} style = {{borderBottom: "1px solid #cccccc", paddingBottom: 10, fontSize: 14}} className = "text-danger"><small>Compra realizada por transferencia bancaria</small></Col>
                    <Col md = {4} style = {{borderBottom: "1px solid #cccccc", paddingBottom: 10}} align = "right"><small>{data.moneda} {data.monto}</small></Col>
                    <Col md = {8}><small><strong>Total</strong></small></Col>
                    <Col md = {4} align = "right"><small><strong>{data.moneda} {data.monto}</strong></small></Col>
                    <Col md = {12} align = "right" className = "custom-mt-10">
                        <Button size = "sm" color = "default"  onClick = {() => {
                            totalPay = 0;
                            setOpenModal(true)
                        }} title = "Ver detalles de la factura"><FontAwesomeIcon icon = {faFile}/> Ver detalles</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )}

    let ladoSilla = (string) => {
        let izquierda = string.indexOf("Izquierda");
        let superior = string.indexOf("Superior");
        let derecha = string.indexOf("Derecha");
        let inferior = string.indexOf("Inferior");

        switch (true){
            case (izquierda > 0): return "IZQUIERDA";
            case (superior > 0): return "SUPERIOR";
            case (derecha > 0): return "DERECHA";
            case (inferior > 0): return "INFERIOR";
            default: return "";
        }
    }

    let ticketContent = (data) => {

        let asiento = (info) => {
            switch (true){
                case (info.asiento === ""):
                    return "ZONA LIBRE";
                case (info.sillaCode === null || info.sillaCode === ""):
                    return `ASIENTO ${info.asiento}`;
                default:
                    return `MESA ${info.asiento} - SILLA ${info.num_silla_cont}`;
            }

            /* if (info.sillaCode === null || info.sillaCode === "") return `ASIENTO ${info.asiento}`;
            return `MESA ${info.asiento} - SILLA ${ladoSilla(info.sillaCode)} ${info.sillaNum}` */
        }

        let tickets = () => (
            (data).map(option => (
                <React.Fragment  key = {option.id}>
                    <Col md = {8} className = "custom-mt-10">
                        <h6 className = "text-default"><strong>{(detalleEvento[0].evento_info.name_event).toUpperCase()}</strong></h6>
                        <div><small><FontAwesomeIcon icon = {faUniversity}/> {detalleEvento[0].recinto.name}</small></div>
                        <div><small><FontAwesomeIcon icon = {faMap}/> {detalleEvento[0].recinto.address}</small></div>
                        <div><small><FontAwesomeIcon icon = {faCalendarAlt}/> {`${dia(option.fecha, true)}`}</small></div>
                        <div><small><FontAwesomeIcon icon = {faClock}/> {`${option.desde}`}<strong> (Apertura {option.apertura})</strong></small></div>
                    </Col>
                    <Col md = {4} align = "right" className = "custom-mt-10" style = {{fontSize: 14}}>
                        <small className = "text-danger"><strong>{option.zona}</strong></small> | <small className = "text-danger"><strong>{asiento(option)}</strong></small>
                        <div><small><strong>{convertMoneda(option.moneda, option.precio)}</strong></small></div>
                    </Col>
                    <Col md = {12} style = {{borderBottom: "1px dashed #cccccc", paddingBottom: 10, fontSize: 11}} align = "center">
                    </Col>
                </React.Fragment>
            ))
        )

        return(
            <Row className = "custom-border_h" style = {{marginLeft: 5, boxShadow: "1px 1px #cccccc"}}>
                {tickets()}
            </Row>
        )
    }

    let dia = (date, format) => {
        let diaActual = "";
        (format === true) ? diaActual = new Date(cambiaFormato(date)) : diaActual = new Date(date)
        let day = diaActual.getDate();

        if(day < 10) day = `0${day}`;

        let dias = [ 'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        let meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ]
        let month = meses[diaActual.getMonth()];

        return `${dias[diaActual.getDay()]} ${day} de ${month} de ${diaActual.getFullYear()}`;
    }

    let cambiaFormato = (fecha) => {
        let separa = fecha.split("/");
        return `${separa[1]}/${separa[0]}/${separa[2]}`
    }

    let contentEvento = () => (
        <>
            <div className = "header-banner">
                <img alt = "..." className = "img-fluid" src = {`${UrlImg}events/${detalleEvento[0].evento_info.image}`} />
            </div>
            <div className = "banner-button_2">
                <Card className="card-lift--hover shadow border-0" style = {{width: "30vw !important"}}>
                    <CardBody className="py-5">
                        <div className= {`text-uppercase`} style = {{fontSize: 20}}>
                            <strong>{detalleEvento[0].evento_info.name_event}</strong>

                            <Row>
                                <Col className = "text-lg-left custom-mt-10" lg = "12" style = {{maxHeight: "35vh", overflowY: "auto"}}>
                                    <div><small className = "text-muted" style = {{fontSize: 12}}><FontAwesomeIcon icon = {faCalendarAlt} className = "text-info"/> {dia(detalleEvento[0].fecha, true)}</small></div>
                                    <div><small className = "text-muted" style = {{fontSize: 12}}><FontAwesomeIcon icon = {faClock} className = "text-info"/> {detalleEvento[0].hora_in} <strong>(Apertura {detalleEvento[0].hora_apertura})</strong></small></div>
                                    <div><small className = "text-muted" style = {{fontSize: 12}}><FontAwesomeIcon icon = {faTags} className = "text-info"/> {detalleEvento[0].evento_info.categorias_tags}</small></div>
                                    <div><small className = "text-muted" style = {{fontSize: 12}}><FontAwesomeIcon icon = {faUsers} className = "text-info"/> {detalleEvento[0].evento_info.artistas_tags}</small></div>
                                    <Compartir url = {urlShare} size = {30} className = "custom-mt-20"/>
                                </Col>
                                <Col md = {12}>
                                    <Link to = "/" className = "mt-4 btn btn-outline-warning" style = {{borderRadius: 15}}><FontAwesomeIcon icon = {faArrowCircleLeft}/> Volver</Link>
                                </Col>
                                <Col md = {12} style = {{paddingRight: 20}}>
                                    <TimerTicket control = {control}/>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </>
    )

    let contentEvento2 = () => (
        <Container className = "custom-margin">
            <Row>
                <Col md = {12} style = {{marginTop: 60}}>
                    <Card className = "main-card mb-3" style = {{borderRadius: 30}}>
                        <CardTitle className = "twitter">
                            <strong>DETALLES DE TU COMPRA</strong>
                        </CardTitle>
                        <CardBody>
                            <Row>
                                <Col md = {12} className = "custom-pr-10" style = {{paddingBottom: 20}}>
                                    <Alert color= "warning" isOpen = {alertVisible} toggle = {() => setAlertVisible(false)}>
                                        <FontAwesomeIcon icon = {faCheckCircle}/> Hemos enviado a tu correo los detalles de tu compra. <strong>IMPORTANTE: Dicho correo es de información de la compra de tus boletos y no es válido para el evento. Cuando tu pago sea confirmado recibirás un correo con los boletos válidos</strong>
                                    </Alert>
                                </Col>
                                <Col md = {12} className = "custom-pr-10">
                                    <div>
                                        {payDetalle}    
                                    </div>
                                    <h6 className = "text-info custom-mt-20"><strong>DETALLES DE TUS TICKETS</strong></h6>
                                    <div>
                                        {ticketDetalle}    
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )

    let contentModal = () => {
        let tickets = detalleFactura.dataTickets
        let impuestos = detalleFactura.dataImpuestos
        let servTaquilla = detalleFactura.taquilla
        let impTaquilla = detalleFactura.dataImpTaquilla
        let aplicable = (servTaquilla) ? Boolean(servTaquilla.aplica) : false

        let totalAPagar = () => {
            let cant = 0;
            let moneda = ""
            tickets.forEach(opt => {
                moneda = opt.moneda;
                cant += parseFloat(opt.precio);
            })

            // impuestos.forEach(opt => cant += parseFloat(opt.monto))
            if (aplicable === true) cant += parseFloat(servTaquilla.valor * cant / 100);
            // impTaquilla.forEach(opt => cant += parseFloat(opt.monto))
            
            return convertMoneda(moneda, cant.toFixed(2));
        }



        let asiento = (info) => {
            switch (true){
                case (info.asiento === ""):
                    return "ZONA LIBRE";
                case (info.sillaCode === null || info.sillaCode === ""):
                    return `ASIENTO ${info.asiento}`;
                default:
                    return `MESA ${info.asiento} - SILLA ${info.num_silla_cont}`;
            }
        }

        let contentCompra = () => {  
            let Content = JSON.parse(sessionStorage.getItem("ContentResp"))

            let Tick = Content.infoTickets;
          
            return (
                Tick.map(option => (
                    <tr key = {option.id}>
                        <td style = {{padding: 5}} className = "text-danger" width = "40%">{option.zona}</td>
                        <td style = {{padding: 5}} width = "40%">{asiento(option)}</td>
                        <td style = {{padding: 5}} width = "20%" align = "right">{convertMonedaImpuesto(option.moneda, option.precio)}</td>
                    </tr>
                ))
            )
        }

        let contentImpuestos = () => {
            if (impuestos.length === 0) return(
                <tr>
                    <td colSpan = {3} style = {{padding: 5}}>No Aplica</td>
                </tr>
            )

            return(
                impuestos.map(option => (
                    <tr key = {option.id}>
                        <td colSpan = {2} align = "right" width = "80%" style = {{padding: 5}}>{option.nombre} {option.valor}</td>
                        <td width = "20%" align = "right" style = {{padding: 5}}>{convertMoneda(option.moneda, option.monto)}</td>
                    </tr>
                ))
            )
        }

        let contentImpTaquilla = () => {
            if (impTaquilla.length === 0) return null;

            return(
                impTaquilla.map(option => (
                    <tr key = {option.id}>
                        <td colSpan = {2} align = "right" width = "80%" style = {{padding: 5}}>{option.nombre.nombre} {option.valor}</td>
                        <td width = "20%" align = "right" style = {{padding: 5}}>{convertMoneda(option.moneda, option.monto)}</td>
                    </tr>
                ))  
            )
        }

        let contentServTaquilla = () => {
            if (aplicable === true){
                let totalP = totalPay + servTaquilla.valor;
                totalPay = totalP

                let cant = 0;
                tickets.forEach(opt => {
                    cant += opt.precio;
                })

                let ContentCurrency = JSON.parse(sessionStorage.getItem("ContentDataCurrency"));
                let total = servTaquilla.valor * cant * ContentCurrency.valor_1usd_moneda_local / 100

                return (
                    <>
                        <tr>
                            <td colSpan = {2} align = "right" style = {{padding: 10, paddingRight: 5}}><strong>Servicios de taquilla:</strong></td>
                            <td align = "right" style = {{padding: 10, paddingRight: 5}}>{"BS. "} {total.toFixed(2)}</td>
                        </tr>
                        {contentImpTaquilla()}
                    </>
                )
            }else{
                return null;
            }

        }

        let subTotal = () => {
            let cant = 0;
            let moneda = ""
            tickets.forEach(opt => {
                moneda = opt.moneda;
                cant += parseFloat(opt.precio);
            })

            impuestos.forEach(opt => cant -= parseFloat(opt.monto))
            
            return convertMoneda(moneda, cant.toFixed(2));
        }

        return (
            <>
                <Row>
                    <Col md = {7}>
                        <small>Método seleccionado: <strong>Transferencia bancaria</strong></small>
                    </Col>
                    <Col md = {5} align = "right">
                        
                    </Col>
                </Row>
                <Table className = "mb-0 custom-mt-10" hover style = {{fontSize: 10, border: "1px solid #ccc"}}>
                    <tbody>
                        {contentCompra()}
                        <tr>
                            <td colSpan = {2} align = "right" style = {{padding: 5, backgroundColor: "#edebeb"}}>
                                <strong>SUB-TOTAL:</strong>
                            </td>
                            <td style = {{padding: 5, backgroundColor: "#edebeb"}} align = "right">
                                <strong>{subTotal()}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan = {2} align = "right" style = {{padding: 10, paddingRight: 5}}><strong>Impuestos:</strong></td>
                            <td></td>
                        </tr>
                        {contentImpuestos()}
                        {contentServTaquilla()}
                        <tr className = "text-danger" style = {{fontSize: 12}}>
                            <td colSpan = {2} align = "right" style = {{padding: 5, backgroundColor: "#edebeb"}}>
                                <strong>TOTAL A PAGAR:</strong>
                            </td>
                            <td style = {{padding: 5, backgroundColor: "#edebeb"}} align = "right">
                                <strong>{totalAPagar()}</strong>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </>
        )
        
    }
    
    let modal = () => {
        if (openModal === false) return null;
        return (
            <ContentModal2
                isOpen = {openModal}
                title = "Detalles de tu compra"
                size = "md"
                content = {contentModal()}
                close = {() => setOpenModal(false)}
            />
        )
    }

    return(
        <>
            <NavbarI />
            <section className="section pb-0 section-components" style = {{backgroundColor: "#f9f9f9"}}>
                <div className="py-5">
                    <Container className = "custom-margin">
                        {contentEvento()}
                    </Container>
                    <div>
                        {contentEvento2()}
                    </div>
                </div>
            </section>
            {modal()}
            <CardsFooter />
        </>
    )
}

export default TransferEvento;