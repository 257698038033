import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";

import { Button, Card, Container, CardBody, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCircle, faClock, faFrown, faTags, faUsers, faPauseCircle, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import NavbarI from "components/Navbars/NavbarI.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import EventoDetallePreload from "components/Skeletons/EventoDetallePreload";
import CustomMap from "components/Maps/CustomMap";
import ContentModal2 from "components/Modals/ContentModal2";
import LoginModal from "components/LogModal/LoginModal";
import RegistModal from "components/LogModal/RegistModal";

import { SearchFuncionsInd } from "functions/EventosFunctions";
import { UrlImg } from "../../functions/Url";
import { ChkSec } from '../../functions/UserFunctions';
import Compartir from "components/Social/Compartir";
import { ChkTickets } from "functions/EventosFunctions";


const Evento = (props) => {
    const [detalleEvento, setDetalleEvento] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [redirect, setRedirect] = useState(false)
    const [preloadE, setPreloadE] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [btnComprar, setBtnComprar] = useState({ text: "Comprar", disabled: false })
    const [ContentData, setContentData] = useState(JSON.parse(sessionStorage.getItem("ContentData")))
    const [openModalLog, setOpenModalLog] = useState(false);
    const [openModalReg, setOpenModalReg] = useState(false);
    const [login, setLogin] = useState(false);
    const [urlE, setUrlE] = useState("");
    const [urlShare, setUrlShare] = useState("https://www.taquillaenlinea.com/")
    const [control, setControl] = useState(false);
    const [soldOut, setSoldOut] = useState("header-banner d-none d-sm-block d-md-block d-lg-block d-xl-block");
    const [disButton, setDisButton] = useState({ disabled: false, color: "primary" });
    const [contentSoldout, setContentSoldout] = useState("");

    useEffect(() => {
        setContentData(JSON.parse(sessionStorage.getItem("ContentData")));

        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;

        let url = window.location.href;
        let part = url.split("/");
        let urlEvent = part.pop();
        setUrlShare(url)

        SearchFuncionsInd(urlEvent, (resp) => {
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon={faFrown} /> <span dangerouslySetInnerHTML={{ __html: resp.mess }} style={{ fontSize: 12 }}></span></small>);
                    // setRedirect(true)
                    break;
                default:
                    switch (true) {
                        case (resp.data[0].vendidos >= resp.data[0].capacidad_total):
                            setSoldOut("soldout-gr header-banner d-none d-sm-block d-md-block d-lg-block d-xl-block");
                            setDisButton({ disabled: true, color: "light" });
                            setBtnComprar(state => ({ ...state, disabled: true }));
                            setContentSoldout(
                                <div className="alert alert-danger" style={{ padding: "12px", backgroundColor: "#d60000" }}><FontAwesomeIcon icon={faFrown} /> Este evento no tiene entradas disponibles</div>
                            )
                            break;
                        case (resp.activo === 0):
                            setDisButton({ disabled: true, color: "light" });
                            setBtnComprar(state => ({ ...state, disabled: true }));
                            setContentSoldout(
                                <div className="alert alert-warning" style={{ padding: "12px", }}><FontAwesomeIcon icon={faPauseCircle} /> Esta función está pausada</div>
                            )
                            break;
                        default:
                            break;
                    }

                    setDetalleEvento(resp.data)

                    if (!validateFechaAndStatus(resp.data.evento_info.status, resp.data.fecha)) {
                        // Devolver al inicio
                        setRedirect(true)
                    }


                    setCurrency(resp.currency)
                    sessionStorage.removeItem("ContentDataEvent")
                    sessionStorage.removeItem("ContentDataCurrency")
                    sessionStorage.removeItem("ContentDataImpuesto")
                    sessionStorage.setItem("ContentDataEvent", JSON.stringify(resp.data));
                    sessionStorage.setItem("ContentDataCurrency", JSON.stringify(resp.currency));
                    sessionStorage.setItem("ContentDataImpuesto", JSON.stringify({
                        impuesto: resp.impuestos,
                        impuestoTaquilla: resp.impuesto_taquilla,
                        servTaquilla: resp.ser_taquilla
                    }));

                    setPreloadE(false)
                    setUrlE(urlEvent)
                    break;
            }
        })
    }, []);

    let comprar = () => {
        setBtnComprar({ text: "Espera", disabled: true })
        switch (true) {
            case (ContentData === null):
                setOpenModalLog(true);
                setBtnComprar({ text: "Comprar", disabled: false })
                break;
            case (ContentData !== null && ContentData.U_ID !== undefined && ContentData.E_ID !== undefined):
                ChkTickets((resp) => {
                    stopCrono()
                })

                ChkSec(ContentData.U_ID, ContentData.E_ID, (resp) => {
                    stopCrono()
                    if (resp.status === "error") {
                        sessionStorage.removeItem("ContentData")
                        setOpenModalLog(true);
                    } else {
                        setOpenModalLog(false);
                        if (login === false) setLogin(true)
                    }
                })
                break;
            default: return null;
        }
    }

    let stopCrono = () => {
        /* Detener Cronometro */
        let Content = {
            D001: null,
            D002: null
        }
        sessionStorage.setItem("ContentDataT", JSON.stringify(Content));
        sessionStorage.removeItem("ContentDataT")
        sessionStorage.removeItem("ContentDataButacas")
        sessionStorage.removeItem("ContentDataPrecioZona")
        setControl(!control);
    }

    let cambiaFormato = (fecha) => {
        let separa = fecha.split("/");
        return `${separa[1]}/${separa[0]}/${separa[2]}`
    }

    let dia = (date) => {
        let diaActual = new Date(cambiaFormato(date));
        let day = diaActual.getDate();

        if (day < 10) day = `0${day}`;

        let dias = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        let meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
        let month = meses[diaActual.getMonth()];

        return `${dias[diaActual.getDay()]} ${day} de ${month} de ${diaActual.getFullYear()}`;
    }

    let validateFechaAndStatus = (status, date) => {
        const [day, month, year] = date.split('/');
        const providedDate = new Date(year, month - 1, day);

        // Obtener la fecha actual
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        if (status !== 4 || currentDate > providedDate) {
            return false
        }
        return true
    }

    let precios = () => (
        (detalleEvento.precios.sort((a, b) => a.nombre_zona.localeCompare(b.nombre_zona))).map(option => (
            <React.Fragment key={option.id}>
                <Col md={9} sm={9} xs={9} style={{ padding: 5, paddingBottom: 15, paddingTop: 15, fontSize: 12, borderBottom: "1px solid #eaeaea" }}><FontAwesomeIcon icon={faCircle} style={{ color: option.color }} /> {option.nombre_zona}</Col>
                <Col md={3} sm={3} xs={3} style={{ padding: 5, paddingBottom: 15, paddingTop: 15, fontSize: 12, borderBottom: "1px solid #eaeaea" }} className="text-danger" align="right"><strong>{currency.moneda}. {option.precio}</strong></Col>
            </React.Fragment>
        ))
    )

    let modal = () => {
        if (openModal === false) return null;
        return (
            <ContentModal2
                isOpen={openModal}
                title="Términos y condiciones"
                size="lg"
                content={<div style={{ height: "35vh", overflowY: "auto" }} dangerouslySetInnerHTML={{ __html: detalleEvento.evento_info.condiciones }} />}
                close={() => setOpenModal(false)}
            />
        )
    }

    let modalRegistro = () => {
        if (openModalReg === false) return null;

        return (
            <ContentModal2
                isOpen={openModalReg}
                title={<div>
                    <h5 style={{ color: "#0667BF" }}>Regístrate</h5>
                    <div style={{ fontSize: 10, marginTop: -10, color: "#424242" }}>Completa todos los campos</div>
                </div>}
                size="lg"
                content={<RegistModal goTo={`/event/comprar/${urlE}`} close={() => setOpenModalReg(false)} openLogin={() => setOpenModalLog(true)} />}
                close={() => setOpenModalReg(false)}
            />
        )
    }

    let modalLogin = () => {
        if (openModalLog === false) return null;

        return (
            <ContentModal2
                isOpen={openModalLog}
                title={<div>
                    <h5 style={{ color: "#0667BF" }}>Inicia sesión</h5>
                    <div style={{ fontSize: 10, marginTop: -10, color: "#424242" }}>Completa todos los campos</div>
                </div>}
                size="md"
                content={<LoginModal goTo={`/event/comprar/${urlE}`} close={() => setOpenModalLog(false)} openRegistro={() => setOpenModalReg(true)} />}
                close={() => setOpenModalLog(false)}
            />
        )
    }

    let contentEvento = () => {
        if (preloadE === true) return null

        return (
            <>
                <div className="d-xs-block d-sm-none d-md-none d-lg-none d-xl-none" style={{ marginBottom: '250px' }}>
                    <Card className="card-lift--hover shadow border-0" style={{ width: "30vw !important", marginTop: -10 }}>
                        <CardBody className="py-3">
                            <div className={`text-uppercase`} style={{ fontSize: 20 }}>
                                <strong>{detalleEvento.evento_info.name_event}</strong>
                            </div>
                            <Row style={{ paddingRight: 20, paddingLeft: 20 }}>
                                {precios()}
                                <Col align="center" lg="12" style={{ paddingRight: 25 }}>
                                    <Link to={`/`} className="mt-4 btn btn-outline-warning" style={{ borderRadius: 15 }}>
                                        Regresar
                                    </Link>
                                    <Button className="mt-4" color="primary" onClick={comprar} disabled={btnComprar.disabled} style={{ borderRadius: 15 }}>
                                        {btnComprar.text}
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
                <div className={soldOut}>
                    <img alt="..." style={{ width: "150vw", height: "70vh", display: "flex", justifyContent: "center", alignItems: "center" }} className="img-fluid" src={`${UrlImg}events/${detalleEvento.evento_info.image}`} />
                </div>
                <div className="banner-button_2 d-none d-sm-block d-md-block d-lg-block d-xl-block" style={{ width: "30vw !important", marginBottom: "20vh" }}>
                    <Card className="card-lift--hover shadow border-0 mb-5 opacity-custom" style={{ width: "30vw !important" }}>
                        <CardBody className="py-3">
                            <div className={`text-uppercase`} style={{ fontSize: 20 }}>
                                <strong>{detalleEvento.evento_info.name_event}</strong>
                            </div>
                            <Row style={{ paddingRight: 20, paddingLeft: 20 }}>
                                {precios()}
                                <Col align="center" lg="12" style={{ paddingRight: 25 }}>
                                    <Link to={`/`} className="mt-4 btn btn-outline-warning" style={{ borderRadius: 15 }}>
                                        Regresar
                                    </Link>

                                    {validateFechaAndStatus(detalleEvento.evento_info.status, detalleEvento.fecha) ? <Button className="mt-4" color={disButton.color} onClick={comprar} disabled={btnComprar.disabled} style={{ borderRadius: 15 }}>
                                        {btnComprar.text}
                                    </Button> : <Button className="mt-4" color="danger">Evento inactivo</Button>}

                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </>
        )
    }

    let contentEvento2 = () => {
        if (preloadE === true) return (
            <EventoDetallePreload />
        )

        return (
            <Row>
                <Col lg="4" align="justify" className="custom-padding-media-l" style={{ paddingLeft: 120, paddingRight: 30, overflow: 'auto', wordWrap: 'break-word' }}>

                    <div className="mt--2" style={{ fontSize: 25, marginBottom: 20 }}><strong>Descripción</strong></div>
                    <div dangerouslySetInnerHTML={{ __html: detalleEvento.evento_info.descripcion }} />
                </Col>
                <Col lg="4" align="justify" className="custom-padding-media-r">
                    <div style={{ fontSize: 25, marginBottom: 20 }}><strong>Detalles</strong></div>
                    {contentSoldout}
                    <div className="custom-mt-10"><small><FontAwesomeIcon icon={faCalendarAlt} className="text-info" /> {dia(detalleEvento.fecha)}</small></div>
                    <div><small><FontAwesomeIcon icon={faClock} className="text-info" /> {detalleEvento.hora_in} <strong>(Apertura de puerta: {detalleEvento.hora_apertura})</strong></small></div>
                    <div><small><FontAwesomeIcon icon={faTags} className="text-info" /> {detalleEvento.evento_info.categorias_tags}</small></div>
                    <div><small><FontAwesomeIcon icon={faUsers} className="text-info" /> {detalleEvento.evento_info.artistas_tags}</small></div>
                    <div><small><FontAwesomeIcon icon={faBuilding} className="text-info" /> {detalleEvento.recinto.name}</small></div>
                    <div className="custom-mt-100"><strong>Comparte el evento en tus redes sociales</strong></div>
                    <Compartir url={urlShare} size={40} />
                </Col>

                <Col lg="4" align="justify" className="custom-padding-media-r">
                    <div style={{ fontSize: 25, marginBottom: 20 }}><strong>Locación del evento</strong></div>
                    <div align="center" style={{ overflow: "auto" }}><CustomMap src={detalleEvento.recinto.map} map={true} /></div>

                    <div style={{ cursor: "pointer" }} className="custom-mt-20" title="Ver términos y condiciones para este evento">
                        <small className="text-info" onClick={() => setOpenModal(true)}>
                            <strong>Términos y condiciones</strong>
                        </small>
                    </div>
                </Col>
            </Row>
        )
    }

    if (redirect) return <Redirect to="/" />;
    if (login && urlE) return <Redirect to={`/event/comprar/${urlE}`} />;

    return (
        <>
            <NavbarI />
            <section className="section pb-0 section-components" style={{ backgroundColor: "#f9f9f9" }}>
                <div className="py-5">
                    <Container className="custom-margin">
                        {contentEvento()}
                    </Container>
                    <div style={{ marginTop: -200 }}>
                        {contentEvento2()}
                    </div>
                </div>
            </section>
            <CardsFooter />
            {modal()}
            {modalLogin()}
            {modalRegistro()}
        </>
    )
}

export default Evento;