import React, { useState } from 'react';
import axios from 'axios';
import { Input } from 'reactstrap';
import { Url } from 'functions/Url';
import { toast } from 'react-toastify';

function AddImages({ zonas, evento }) {
  const [images, setImages] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const user = JSON.parse(sessionStorage.getItem('ContentData'));
  const handleImageChange = (method, event) => {
    if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];

        // Opcional: Aquí puedes agregar validaciones adicionales (tipo de archivo, tamaño, etc.)

        // Crear un objeto con el método e img
        const updatedImageObj = {
            metodo: method,
            img: file  // Almacena el archivo sin comprimir
        };

        // Obtener una copia del estado actual
        const currentImageArray = Array.isArray(images) ? [...images] : [];

        // Encontrar el índice del método existente en el array
        const existingMethodIndex = currentImageArray.findIndex(item => item.metodo === method);

        if (existingMethodIndex > -1) {
            // Si ya existe una imagen para el método, reemplazarla
            currentImageArray[existingMethodIndex] = updatedImageObj;
        } else {
            // Si no existe una imagen para el método, agregarla al array
            currentImageArray.push(updatedImageObj);
        }

        // Actualizar el estado con el nuevo array de objetos
        setImages(currentImageArray);

    }
};

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('eid', user.E_ID);
    formData.append('uid', user.U_ID);
    formData.append('event', evento);
    
    if (images.length < zonas.length -1) {
        toast.error("Debe subir todas las imagenes para poder continuar")
    }else{
        setDisabled(true)

        images.forEach((imageObject, index) => {
            if (imageObject.img instanceof File) {
                // Agregar cada imagen al FormData con un nombre único y también agregar el método correspondiente
                formData.append(`images[${index}][zona]`, imageObject.metodo);
                formData.append(`images[${index}][img]`, imageObject.img, imageObject.img.name);
            }
        });
    
        try {
          const response = await axios.post(`${Url}SaveImagesZones`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
    
          if(response.data.status === 'error'){
            toast.error(response.data.mess)
          }else{
            toast.success(response.data.mess);
          }
    
    
        } catch (error) {
          toast.error(error)
        }finally{
            setDisabled(false)
        }
    }

  };

  return (
    <div className="text-primary mb-2">
      Subir Estadio Referencial
      {zonas ? zonas.map(zona => (
        <div key={zona.label} className='mt-3'>
            {(zona.label === 'TARIMA' || zona.label === 'ESCENARIO' ? null :
            <span>
            <span className="text-primary ml-3">Zona: {zona.label}</span>
                <Input
                type="file"
                id={zona.value}
                accept="image/*"
                onChange={(e) => handleImageChange(zona.label, e)}
                style={{ maxWidth: '400px' }}
            /> 
            </span>
)}
       
        </div>
      )) : null}
      <div className='mt-3 d-flex justify-content-end'>
        <button className='btn btn-warning' onClick={handleSubmit} disabled={disabled}>
            {disabled ? 'Cargando...' : 'Enviar imágenes'}
        </button>
      </div>
    </div>
  );
}

export default AddImages;