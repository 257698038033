import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from 'react-toastify';
import ImageMapper from 'react-image-mapper';

import { Button, Card, CardBody, CardHeader, Input, Row, Col, Table } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faSquare, faCircleNotch, faTimesCircle, faHome, faTimes, faCode, faCaretUp, faChevronLeft, faChevronUp, faArrowUp, faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import NavbarI from "components/Navbars/NavbarI.js";
import Sidebar from '../dashboard/Sidebar';
import ButacaDist from "components/Maps/ButacaDist";
import MesaDist from "components/Maps/MesaDist";
import ConfirmModal from "components/Modals/ConfirmModal";

import { SearchEvents, SearchFunctions, SearchZoneEventRet, SearchButacasInd, AddMesaIndRet, AddMesaRet, AddButacaIndRet, DelButacaIndRet, DelMesaRet,
        AddButacaMultiRet, AddMesaMultiRet, AddEntLibre } from "functions/EventosFunctions";
import { UrlImg } from "functions/Url";
import RetiroTicket from "./RetiroTicket";

let auditorioB = "";

const MainRetiros = (props) => {
    const [eventSelect, setEventSelect] = useState({value: "", label: "Selecciona un evento"});
    const [eventos, setEventos]         = useState([]);
    const [eventOpt, setEventOpt]       = useState({ disabled: true, loading: true })
    const [funcionSelect, setFuncionSelect] = useState({value: "", label: "Selecciona primero un evento", recinto: ""});
    const [desdeSelect, setDesdeSelect] = useState({value: "", label: "Desde"});
    const [hastaSelect, setHastaSelect] = useState({value: "", label: "Hasta"});
    const [funciones, setFunciones]     = useState([]);
    const [desde, setDesde]             = useState([]);
    const [hasta, setHasta]             = useState([]);
    const [funcionesOpt, setFuncionesOpt]   = useState({ disabled: false, loading: false })
    const [selectMulOpt, setSelectMulOpt]   = useState({ disabled: false, loading: false })
    const [zonaPreload, setZonaPreload] = useState({icon: faCaretUp, spin: false, text: "Selecciona primero un evento"});
    const [preloadAud, setPreloadAud]   = useState({icon: faCircle, spin: false, text: "Selecciona un evento para ver el auditorio"});
    const [preloadBut, setPreloadBut]   = useState({icon: faCircle, spin: false, text: "Selecciona primero un evento"});
    const [zonasCat, setZonasCat]       = useState([]);
    const [butacas, setButacas]         = useState([]);
    const [zonas, setZonas]             = useState([]);
    const [zonas2, setZonas2]           = useState([]);
    const [zonasBuild, setZonasBuild]   = useState([]);
    const [dataSelect, setDataSelect]   = useState([]);
    const [dataButacas, setDataButacas] = useState("");
    const [colorButSold, setColorButSold]   = useState("#d8d8d8");
    const [colorButSel, setColorButSel]     = useState("#de932e");
    const [colorButTran, setColorButTran]   = useState("#4caf50");
    const colorButacaCortesia = '#F85C0D';
    const [preloader, setPreloader]     = useState(false);
    const [tipoAudit, setTipoAudit]     = useState("");
    const [mapaA, setMapaA]             = useState("");
    const [preloadRet, setPreloadRet]   = useState({icon: faArrowUp, spin: false, text: "Selecciona un evento"});
    const [listRetiro, setListRetiro]   = useState([]);
    const [openModalCon, setOpenModalCon]   = useState(false)
    const [openModalConM, setOpenModalConM] = useState(false)
    const [openModalConMesaInd, setOpenModalConMesaInd] = useState(false)
    const [openModalConMesa, setOpenModalConMesa]       = useState(false)
    const [openModalLibre, setOpenModalLibre] = useState(false)
    const [cantLibre, setCantLibre] = useState("");
    const [cantDisp, setCantDisp] = useState(0);

    const [butacaAdd, setButacaAdd]     = useState()
    const [selectInd, setSelectInd]     = useState(true);
    const [sillasDisabled, setSillasDisabled]           = useState([]);
    const [confMesas, setConsfMesas]        = useState({
        superior: 4,
        izquierda: 4,
        derecha: 4,
        inferior: 4
    })

    useEffect(() => {
        let getByStatus = [4];
        SearchEvents(getByStatus, (resp) => {
            setEventOpt({ disabled: false, loading: false })

            let Content = [];
            (resp.data).forEach(option => {
                Content.push({
                    value: option.id,
                    label: option.name_event,
                    recinto: option.id_recinto
                });
            })

            setEventos(Content);
        })
    }, [props]);

    let ladoSilla = (string) => {
        let izquierda = string.indexOf("Izquierda");
        let superior = string.indexOf("Superior");
        let derecha = string.indexOf("Derecha");
        let inferior = string.indexOf("Inferior");

        switch (true){
            case (izquierda > 0): return "Izquierda";
            case (superior > 0): return "Superior";
            case (derecha > 0): return "Derecha";
            case (inferior > 0): return "Inferior";
            default: return "";
        }
    }

    let changeEvento = (eventSelect) => {
        setEventSelect(eventSelect)
        setFuncionesOpt({disabled: true, loading: true})
        setPreloadAud({icon: faCircleNotch, spin: true, text: "Buscando la distrubución de este evento"})
        setPreloadBut({icon: faCircleNotch, spin: true, text: "Buscando la distrubución de este evento"});
        setPreloadRet({icon: faCircleNotch, spin: true, text: "Buscando tickets"})
        setTipoAudit("")
        setDataButacas("");
        
        SearchZoneEventRet(eventSelect.recinto, eventSelect.value, (resp) => {
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                break;
                default:
                    setPreloadBut({icon: faChevronLeft, spin: false, text: "Selecciona una zona para ver las butacas"});
                    setFuncionesOpt({disabled: false, loading: false})
                    setListRetiro(resp.tickets)

                    if(resp.tickets.length === 0){
                        setPreloadRet({icon: faTimesCircle, spin: false, text: "No has retirado tickets en este evento en ninguna función"})
                    }

                    if (resp.data_zona_audit !== null){
                        let zonasC = JSON.parse(resp.data_zona_audit.zonas_cat);
                        let Content = [];
                        
                        (resp.dataZonas).forEach(i => {
                            let search = zonasC.find(opt => opt.nombre === i.nombre_zona.nombre_zona);
                            if (search !== undefined){
                                Content.push({
                                    color: i.color,
                                    id: i.id,
                                    nombre: i.nombre_zona.nombre_zona,
                                    zona: {
                                        value: i.id,
                                        label: i.nombre_zona.nombre_zona
                                    }
                                })
                            }
                        })
                        setZonasCat(Content)
                    }

                    if(resp.dataZonas !== null){
                        let ContentZonas = []
                        resp.dataZonas.forEach(i => {
                            if(Boolean(i.nombre_zona.status) === true) ContentZonas.push({
                                id: i.id,
                                id_nombre: i.id_nombre,
                                color: i.color,
                                nombre: i.nombre_zona.nombre_zona,
                                valor: 0,
                                moneda: "",
                                status: 1,
                                id_recinto: i.id_recinto
                            })
                        })

                        setZonas(ContentZonas)
                        setZonas2([])
                    }

                    if (resp.data_zona_audit !== null){
                        switch(resp.data_zona_audit.tipo){
                            case "cuadricula":
                                setZonasBuild(JSON.parse(resp.data_zona_audit.zonas_build))
                            break;
                            case "area":
                                setZonasBuild(JSON.parse(resp.data_zona_audit.zonas_build))
                                setMapaA(resp.data_zona_audit.image)
                            break;
                            default: return null;
                        }

                        setTipoAudit(resp.data_zona_audit.tipo)
                    }else{
                        setZonasBuild([]);
                        setPreloadAud({icon: faTimes, spin: false, text: "Este evento no tiene un auditorio configurado"})
                        setTipoAudit("")
                        setMapaA("");
                    }
                break;
            }
        })

        SearchFunctions(eventSelect.value, (resp) => {
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                break;
                default:
                    setFuncionSelect({ value: "", label: "Selecciona una función" })
                    let Content = [];
                    (resp.data).forEach(option => {
                        Content.push({
                            value: option.id,
                            label: `Funcion del ${option.fecha} - Hora: [${option.hora_in} - ${option.hora_out}]`
                        });
                    });

                    setFunciones(Content);
                break;
            }
        })
    }

    let changeFuncion = (funcionSelect) => {
        switch(true){
            case (dataSelect.length === 0):
                setFuncionSelect(funcionSelect)
            break;
            default:
                setFuncionSelect(funcionSelect)
                setDataButacas([])
                setPreloadBut({icon: faCircleNotch, spin: true, text: "Buscando las butacas"});
                SearchButacasInd(dataSelect.id_recinto, dataSelect.id_zona, funcionSelect.value, dataSelect.name_zona, eventSelect.value, (resp) => {
                    let data = resp.data.auditorio_build;
                    let content = [];

                    if(data !== ""){
                        let filas = JSON.parse(data);
                        let num = 0;
                        filas.forEach(i => {
                            let columnas = i.columnas;
                            columnas.forEach(n => {
                                if (n.disabledInd === false && !isNaN(Number(n.id)) === true){                                   
                                    content.push({
                                        id: num,
                                        fila: n.letraAlt,
                                        columna: n.id,
                                        value: `${n.letraAlt}${n.id}`,
                                        label: `${n.letraAlt}${n.id}`
                                    })
                                    num++;
                                }
                            })
                        })
                    }

                    setSillasDisabled(JSON.parse(resp.data.sillas_disabled));
                    setConsfMesas(JSON.parse(resp.data.cant_sillas));
                    setDesde(content);
                    setHasta(content);

                    setDataButacas(resp)
                })
            break;
        }
    }

    let changeDesde = (desdeSelect) => {
        setDesdeSelect(desdeSelect)
        setHastaSelect({value: "", label: "Hasta"})
        setHasta(desde);
        /* Actualizar el select hasta para que tome los de id superior */

        let content = []

        hasta.forEach(i => {
            if (i.id > desdeSelect.id) content.push(i);
        })

        setHasta(content);
    }

    let changeHasta = (hastaSelect) => {
        setHastaSelect(hastaSelect)
    }

    const customStylesSelect = {
        menu: styles => ({ ...styles, color: "#000", fontSize: 14, zIndex: 3 }),
        multiValue: styles => ({ ...styles, backgroundColor: "#ccc" }),
        multiValueLabel: styles => ({ ...styles, color: "#000" }),
        placeholder: styles => ({ ...styles, fontSize: 14, color: "#b4bcc3" }),
    };

    let selectZona = (data) => {
        switch(tipoAudit){
            case "cuadricula":
                if(funcionSelect.value === ""){
                    setPreloadBut({icon: faChevronUp, spin: false, text: <span>Selecciona una función para ver las butacas</span>});
                    setDataSelect({
                        id_recinto: eventSelect.recinto,
                        id_zona: data.zona.value,
                        name_zona: data.zona.label,
                        color: data.color,
                        id_funcion: ""
                    });
                }else{
                    setDataSelect({
                        id_recinto: eventSelect.recinto,
                        id_zona: data.zona.value,
                        name_zona: data.zona.label,
                        color: data.color,
                        id_funcion: ""
                    });
    
                    setDataButacas([])
                    setPreloadBut({icon: faCircleNotch, spin: true, text: "Buscando las butacas"});
                    SearchButacasInd(eventSelect.recinto, data.zona.value, funcionSelect.value, data.zona.label, eventSelect.value, (resp) => {
                        let data = resp.data.auditorio_build;
                        let content = [];

                        if(data !== ""){
                            let filas = JSON.parse(data);
                            let num = 0;
                            filas.forEach(i => {
                                let columnas = i.columnas;
                                columnas.forEach(n => {
                                    if (n.disabledInd === false && !isNaN(Number(n.id)) === true){                                   
                                        content.push({
                                            id: num,
                                            fila: n.letraAlt,
                                            columna: n.id,
                                            value: `${n.letraAlt}${n.id}`,
                                            label: `${n.letraAlt}${n.id}`
                                        })
                                        num++;
                                    }
                                })
                            })
                        }

                        setSillasDisabled(JSON.parse(resp.data.sillas_disabled));
                        setConsfMesas(JSON.parse(resp.data.cant_sillas));
                        setDesde(content);
                        setHasta(content);
                        
                        setDataButacas(resp)
                    })
                }
            break;
            
            case "area":
                let search = zonasCat.find(option => option.nombre === data.name);
                if(funcionSelect.value === ""){
                    setPreloadBut({icon: faChevronUp, spin: false, text: <span>Selecciona una función para ver las butacas</span>});
                    setDataSelect({
                        id_recinto: eventSelect.recinto,
                        /* id_zona: search.zona.value, */
                        id_zona: search.id,
                        name_zona: search.zona.label,
                        color: data.preFillColor,
                        id_funcion: ""
                    });
                }else{
                    setDataSelect({
                        id_recinto: eventSelect.recinto,
                        /* id_zona: search.zona.value, */
                        id_zona: search.id,
                        name_zona: search.zona.label,
                        color: data.preFillColor,
                        id_funcion: ""
                    });
    
                    setDataButacas([])
                    setPreloadBut({icon: faCircleNotch, spin: true, text: "Buscando las butacas"});
                    SearchButacasInd(eventSelect.recinto, search.id/* , search.zona.value */, funcionSelect.value, search.nombre, eventSelect.value, (resp) => {
                        let data = resp.data.auditorio_build;
                        let content = [];

                        if(data !== ""){
                            let filas = JSON.parse(data);
                            let num = 0;
                            filas.forEach(i => {
                                let columnas = i.columnas;
                                columnas.forEach(n => {
                                    if (n.disabledInd === false && !isNaN(Number(n.id)) === true){                                   
                                        content.push({
                                            id: num,
                                            fila: n.letraAlt,
                                            columna: n.id,
                                            value: `${n.letraAlt}${n.id}`,
                                            label: `${n.letraAlt}${n.id}`
                                        })
                                        num++;
                                    }
                                })
                            })
                        }

                        setSillasDisabled(JSON.parse(resp.data.sillas_disabled));
                        setConsfMesas(JSON.parse(resp.data.cant_sillas));
                        setDesde(content);
                        setHasta(content);

                        setDataButacas(resp)
                    })
                }
            break;
            default: return null;
        }
    }

    let auditorio = () => {
        if (zonas.length === 0) return (
            <div style = {{minHeight: 200}}><FontAwesomeIcon icon = {preloadAud.icon} spin = {preloadAud.spin} size = "xs" className = "text-muted"/> <small>{preloadAud.text}</small></div>
        )

        let leyenda = () => (
            zonasCat.map(option => (
                <small key = {option.id} style = {{fontSize: 9}}><FontAwesomeIcon icon = {faSquare} style = {{color: option.color}}/> {option.nombre} | </small>
            ))
        )

        let title = (nombre) => {
            if (nombre !== undefined) return nombre;
        }

        let zonaT = () => {
            let columnas = (data) => (
                data.map(option => {
                    if (option.zona === ""){
                        return (
                            <td key = {option.id} width = {10} height = {10} style = {{backgroundColor: option.color}}></td>
                        )
                    }else{
                        return (
                            <td key = {option.id} width = {10} height = {10} onClick = {() => selectZona(option)} title = {title(option.zona.label)} style = {{backgroundColor: option.color, cursor: "pointer"}}></td>
                        )
                    }
                })
            )

            return zonasBuild.map(fila => (
                <tr key = {`${fila.id}`}>{columnas(fila.columnas)}</tr>
            ))
        }

        switch(tipoAudit){
            case "cuadricula": return (
                <>
                    <div className = "table-responsive" align = "center" style = {{marginBottom: 5}}>
                        <table border = "0">
                            <tbody>
                                {zonaT()}
                            </tbody>
                        </table>
                    </div>
                    <div style = {{borderTop: "1px dotted #424242"}}>{leyenda()}</div>
                </>
            )
            case "area": return (
                <>
                    <div align = "left" style = {{borderBottom: "1px dotted #000", marginBottom: 10}}>{leyenda()}</div>
                      <ImageMapper src = {`${UrlImg}maps/${mapaA}`} map = {zonasBuild} onClick = {(mapa) => {selectZona(mapa)}}/>
                </>
            )
            default: return null;
        }
    }

    let addButaca = (data) => {
        let Campos = {
            fila: data.letraAlt,
            columna: data.id,
            idFuncion: funcionSelect.value,
            idZona: dataButacas.data.id_zona,
            idDistribucion: dataButacas.data.id
        }
        
        setOpenModalCon(false);
        setPreloader(true);

        AddButacaIndRet (Campos, (resp) => {
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                    setPreloader(false);
                break;
                default:
                    setListRetiro(resp.tickets);
                    SearchButacasInd(dataSelect.id_recinto, dataSelect.id_zona, funcionSelect.value, dataSelect.name_zona, eventSelect.value, (resp) => {
                        let data = resp.data.auditorio_build;
                        let content = [];

                        if(data !== ""){
                            let filas = JSON.parse(data);
                            let num = 0;
                            filas.forEach(i => {
                                let columnas = i.columnas;
                                columnas.forEach(n => {
                                    if (n.disabledInd === false && !isNaN(Number(n.id)) === true){                                   
                                        content.push({
                                            id: num,
                                            fila: n.letraAlt,
                                            columna: n.id,
                                            value: `${n.letraAlt}${n.id}`,
                                            label: `${n.letraAlt}${n.id}`
                                        })
                                        num++;
                                    }
                                })
                            })
                        }

                        setSillasDisabled(JSON.parse(resp.data.sillas_disabled));
                        setConsfMesas(JSON.parse(resp.data.cant_sillas));
                        setDesde(content);
                        setHasta(content);

                        setDataButacas(resp)
                        setPreloader(false);
                    })
                break;
            }
        });
    }

    let delButaca = (data) => {
        setPreloader(true);
        DelButacaIndRet(data, eventSelect.value, funcionSelect.value, (resp) => {
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                    setPreloader(false);
                break;
                default:
                    setListRetiro(resp.tickets);
                    fButacasInd();
                break;
            }
        });
    }

    let retMultiple = () => {
        switch(true){
            case (desdeSelect.value === ""):
                toast.error(<small><FontAwesomeIcon icon = {faExclamationTriangle}/> <span style = {{fontSize: 12}}>Debes seleccionar desde que butaca deseas retirar</span></small>);
            break;
            case (hastaSelect.value === ""):
                toast.error(<small><FontAwesomeIcon icon = {faExclamationTriangle}/> <span style = {{fontSize: 12}}>Debes seleccionar hasta que butaca deseas retirar</span></small>);
            break;
            default:
                setOpenModalConM(true)
            break;
        }
    }

    let addButacaMult = () => {
        let content = [];

        desde.forEach(i => {
            if(i.id >= desdeSelect.id && i.id <= hastaSelect.id){
                content.push(i);
            }
        })

        console.log(funcionSelect)
        console.log(dataSelect)
        console.log(dataButacas)

        let Campos = {
            butacas: content,
            idFuncion: funcionSelect.value,
            idZona: ('idZona' in dataButacas) ? dataButacas.idZona.id : dataButacas.data.id_zona,
            idDistribucion: dataButacas.data.id
        }

        setOpenModalConM(false);
        setPreloader(true);

        AddButacaMultiRet (Campos, (resp) => {
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                    setPreloader(false);
                break;
                default:
                    if (resp.butOcupadas.length > 0) {
                        toast.warn(<small><FontAwesomeIcon icon = {faExclamationTriangle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                    }else{
                        toast.info(<small><FontAwesomeIcon icon = {faCheck}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                    }

                    setListRetiro(resp.tickets);
                    setDesdeSelect({value: "", label: "Desde"});
                    setHastaSelect({value: "", label: "Hasta"});

                    setDataButacas(resp)
                    setPreloader(false);
                break;
            }
        })
    }

    let addMesaMult = () => {
        let content = [];
        let num = 0;
        
        let mesasadd = (lugar, nombre, i) => {
            for (let n = 1; n <= lugar; n++) {
                num++;

                let sillasDis = JSON.parse(dataButacas.data.sillas_disabled);
                let find = sillasDis.find(option => option.data === `${i.label}${nombre}${n}`);

                if (find === undefined) content.push({
                    id: num,
                    fila: i.fila,
                    columna: i.columna,
                    label: i.label,
                    value: i.value,
                    numSilla: n,
                    codigoSilla: `${i.label}${nombre}${n}`
                })
            }
        }
        
        desde.forEach(i => {            
            if (dataButacas.data !== null){
                let cantSillas = JSON.parse(dataButacas.data.cant_sillas);
                let derecha = parseInt(cantSillas.derecha);
                let inferior = parseInt(cantSillas.inferior);
                let izquierda = parseInt(cantSillas.izquierda);
                let superior = parseInt(cantSillas.superior);
                
                if(i.id >= desdeSelect.id && i.id <= hastaSelect.id){
                    mesasadd(derecha, "Derecha", i);
                    mesasadd(inferior, "Inferior", i);
                    mesasadd(izquierda, "Izquierda", i);
                    mesasadd(superior, "Superior", i);
                }
            }
        })

        let Campos = {
            butacas: content,
            idFuncion: funcionSelect.value,
            idZona: dataSelect.id_zona,
            idDistribucion: dataButacas.data.id
        }

        setOpenModalConM(false);
        setPreloader(true);

        AddMesaMultiRet (Campos, (resp) => {
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                    setPreloader(false);
                break;
                default:
                    if (resp.butOcupadas.length > 0) {
                        toast.warn(<small><FontAwesomeIcon icon = {faExclamationTriangle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                    }else{
                        toast.info(<small><FontAwesomeIcon icon = {faCheck}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                    }

                    setListRetiro(resp.tickets);
                    setDesdeSelect({value: "", label: "Desde"});
                    setHastaSelect({value: "", label: "Hasta"});

                    setDataButacas(resp)
                    setPreloader(false);
                break;
            }
        })
    }

    let modalConfirm = () => {
        if (openModalCon === false) return null;        
        return (
            <ConfirmModal
                isOpen = {openModalCon}
                content = {<small>¿Deseas retirar el asiento <strong className = "text-primary">{butacaAdd.letraAlt}{butacaAdd.id}</strong> de la distribución?<div><small style = {{fontWeight: "bold"}}><span className = "text-danger">IMPORTANTE:</span> Sólo los administradores pueden ELIMINAR los tickets otorgados en esta sección</small></div></small>}
                disabled = {false}
                buttonYes = {<><FontAwesomeIcon icon = {faCheck}/> Si</>}
                close = {() => setOpenModalCon(false)}
                funcion = {() => addButaca(butacaAdd)}
            />
        )
    }

    let modalConfirmMult = () => {
        if (openModalConM === false) return null;

        if (dataButacas.data.estilo === "mesa") return (
            <ConfirmModal
                isOpen = {openModalConM}
                content = {<small>¿Deseas retirar <strong>las mesas</strong> desde <strong className = "text-primary">{desdeSelect.label}</strong> hasta <strong className = "text-primary">{hastaSelect.label}</strong> de la distribución?<div><small style = {{fontWeight: "bold"}}><span className = "text-danger">IMPORTANTE:</span> Sólo los administradores pueden ELIMINAR los tickets otorgados en esta sección</small></div></small>}
                disabled = {false}
                buttonYes = {<><FontAwesomeIcon icon = {faCheck}/> Si</>}
                close = {() => setOpenModalConM(false)}
                funcion = {() => addMesaMult()}
            />
        ) 
        
        return (
            <ConfirmModal
                isOpen = {openModalConM}
                content = {<small>¿Deseas retirar <strong>los asientos</strong> desde <strong className = "text-primary">{desdeSelect.label}</strong> hasta <strong className = "text-primary">{hastaSelect.label}</strong> de la distribución?<div><small style = {{fontWeight: "bold"}}><span className = "text-danger">IMPORTANTE:</span> Sólo los administradores pueden ELIMINAR los tickets otorgados en esta sección</small></div></small>}
                disabled = {false}
                buttonYes = {<><FontAwesomeIcon icon = {faCheck}/> Si</>}
                close = {() => setOpenModalConM(false)}
                funcion = {() => addButacaMult()}
            />
        ) 
    }

    let modalConfirmMesaInd = () => {
        if (openModalConMesaInd === false) return null;

        return (
            <ConfirmModal
                isOpen = {openModalConMesaInd}
                content = {<small>¿Deseas retirar la mesa <strong className = "text-primary">{butacaAdd.option.letraAlt}{butacaAdd.option.id} - SILLA {(butacaAdd.silla) ? butacaAdd.silla : (ladoSilla(butacaAdd.data.data)).toLocaleUpperCase() (butacaAdd.data.num) + 1}</strong> de la distribución?<div><small style = {{fontWeight: "bold"}}><span className = "text-danger">IMPORTANTE:</span> Sólo los administradores pueden ELIMINAR los tickets otorgados en esta sección</small></div></small>}
                disabled = {false}
                buttonYes = {<><FontAwesomeIcon icon = {faCheck}/> Si</>}
                close = {() => setOpenModalConMesaInd(false)}
                funcion = {() => addSillaMesa(butacaAdd.option, butacaAdd.data, butacaAdd.silla)}
            />
        )
    }

    let modalConfirmMesa = () => {
        if (openModalConMesa === false) return null;

        return (
            <ConfirmModal
                isOpen = {openModalConMesa}
                content = {<small>¿Deseas retirar todas las sillas disponibles de la mesa <strong className = "text-primary">{butacaAdd.data}</strong> de la distribución?<div><small style = {{fontWeight: "bold"}}><span className = "text-danger">IMPORTANTE:</span> Sólo los administradores pueden ELIMINAR los tickets otorgados en esta sección</small></div></small>}
                disabled = {false}
                buttonYes = {<><FontAwesomeIcon icon = {faCheck}/> Si</>}
                close = {() => setOpenModalConMesa(false)}
                funcion = {() => addMesa(butacaAdd.option, butacaAdd.data)}
            />
        )
    }

    let modalConfirmLibre = () => {
        if (openModalLibre === false) return null;
        let text = "";
        if (cantLibre > 1) text = "s";

        return (
            <ConfirmModal
                isOpen = {openModalLibre}
                content = {<small>¿Deseas retirar <strong className = "text-primary">{cantLibre} entrada{text}</strong> de la distribución?<div><small style = {{fontWeight: "bold"}}><span className = "text-danger">IMPORTANTE:</span> Sólo los administradores pueden ELIMINAR los tickets otorgados en esta sección</small></div></small>}
                disabled = {false}
                buttonYes = {<><FontAwesomeIcon icon = {faCheck}/> Si</>}
                close = {() => setOpenModalLibre(false)}
                funcion = {() => addEntLibre()}
            />
        )
    }

    let fButacasInd = () => {
        SearchButacasInd(dataSelect.id_recinto, dataSelect.id_zona, funcionSelect.value, dataSelect.name_zona, eventSelect.value, (resp) => {
            let data = resp.data.auditorio_build;
            let content = [];

            if(data !== ""){
                let filas = JSON.parse(data);
                let num = 0;
                filas.forEach(i => {
                    let columnas = i.columnas;
                    columnas.forEach(n => {
                        if (n.disabledInd === false && !isNaN(Number(n.id)) === true){                                   
                            content.push({
                                id: num,
                                fila: n.letraAlt,
                                columna: n.id,
                                value: `${n.letraAlt}${n.id}`,
                                label: `${n.letraAlt}${n.id}`
                            })
                            num++;
                        }
                    })
                })
            }

            setSillasDisabled(JSON.parse(resp.data.sillas_disabled));
            setConsfMesas(JSON.parse(resp.data.cant_sillas));
            setDesde(content);
            setHasta(content);

            setDataButacas(resp)
            setPreloader(false);
        })
    }


    let addSillaMesa = (option, data, silla) => {
        let Campos = {
            fila: option.letraAlt,
            columna: option.id,
            idFuncion: funcionSelect.value,
            idZona: dataButacas.data.id_zona,
            idDistribucion: dataButacas.data.id,
            sillaNum: data.num + 1,
            sillaCode: data.data,
            silla_cont: silla
        }

        setOpenModalConMesaInd(false);
        setPreloader(true);


        AddMesaIndRet (Campos, (resp) => {
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                    setPreloader(false);
                break;
                default:
                    setListRetiro(resp.tickets);
                    fButacasInd()
                break;
            }
        })
    }
    
    let delMesa = (data, mesaNum) => {
        setPreloader(true);

        DelMesaRet(data, mesaNum, funcionSelect.value, (resp) => {
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                    setPreloader(false);
                break;
                default:
                    setListRetiro(resp.tickets);
                    fButacasInd()
                break;
            }
        });
    }



    let addMesa = (data, mesaNum) => {
        setOpenModalConMesa(false);
        setPreloader(true);
        AddMesaRet(data, mesaNum, (resp) => {
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                    setPreloader(false);
                break;
                default:
                    setListRetiro(resp.tickets);
                    fButacasInd()
                break;
            }
        })
    }


    let addEntLibre = () => {
        setOpenModalLibre(false);
        setPreloader(true);

        AddEntLibre(cantLibre, eventSelect.value, funcionSelect.value, dataButacas.data.id, dataButacas.data.id_zona, (resp) => {
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                    setPreloader(false);
                    setCantLibre(resp.disp);
                break;
                default:
                    setCantLibre("");
                    setListRetiro(resp.tickets);
                    fButacasInd()
                break;
            }
        })
    }

    let butacasSelect = () => {
        if (dataButacas.length === 0)  return (
            <div style = {{minHeight: 200}}><FontAwesomeIcon icon = {preloadBut.icon} spin = {preloadBut.spin} size = "xs" className = "text-muted"/> <small>{preloadBut.text}</small></div>
        )

        let audit  = JSON.parse(dataButacas.data.auditorio_build);
        let butacasVendidas  = dataButacas.dataSold;
        let tamButaca = dataButacas.data.butaca_tam;
        let idContent = dataButacas.idContent;
        let radButaca = dataButacas.data.butaca_radio;
        let colorBut = dataButacas.data.butaca_color;
        let alternar = dataButacas.data.alternado;
        let estilo = dataButacas.data.estilo;

        let customStyles = {
            backgroundColor: dataButacas.data.butaca_color,
            border: "1px solid #ffffff",
            borderRadius: "3px 3px"
        }

        let filName = (id, letra) => {
            if(!isNaN(Number(id)) === true){
                return <span style = {{fontSize: 10, height: tamButaca, minWidth: tamButaca, maxWidth: tamButaca}} className = "butaca-num"><strong>{letra}</strong></span>
            }else{
                return <span style = {{fontSize: 7, color: "#ccc", height: tamButaca, minWidth: tamButaca, maxWidth: tamButaca}} className = "butaca-num"><FontAwesomeIcon icon = {faCircle}/></span>
            }
        }

        let numL = -1;

        let colums = (col) => {  
            switch(estilo){
                case "mesa": return col.map(option => {
                    /* const search = butacasVendidas.find(opt => opt.butaca_fila === option.letraAlt && parseInt(opt.butaca_columna) === option.id); */
                    
                    let stylesD = customStyles;
                    let colorButSelInd = {
                        backgroundColor: colorButSel,
                        border: "1px solid #ffffff",
                        borderRadius: "3px 3px"
                    };

                    let colorButTranInd = {
                        backgroundColor: colorButTran,
                        border: "1px solid #ffffff",
                        borderRadius: "3px 3px",
                        cursor: "default"
                    };

                    let colorButSoldInd = {
                        backgroundColor: colorButSold,
                        border: "1px solid #ffffff",
                        borderRadius: "3px 3px",
                        cursor: "default"
                    };

                    let colorButCortesiaInd = {
                        backgroundColor: colorButacaCortesia,
                        border: "1px solid #ffffff",
                        borderRadius: "3px 3px",
                        cursor: "default"
                    }
                    
                    let optAddButaca = (data) => {
                        if (selectInd === false){
                            toast.warn(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span> No puedes seleccionar una silla individual. Debes seleccionar la mesa completa</span></small>);
                        }else{
                            let s = data.data
                                    let derecha = s.indexOf("Derecha");
                                    let izquierda = s.indexOf("Izquierda");
                                    let superior = s.indexOf("Superior");
                                    let inferior = s.indexOf("Inferior");

                                    let silla;
                                    
                                    if (derecha !== -1) {
                                        silla = parseInt(data.code) + 1                                    
                                    } else if(inferior !== -1) {
                                        silla = parseInt(data.code) + parseInt(confMesas.derecha) + 1
                                    } else if (izquierda !== -1) {
                                        silla = parseInt(data.code) + parseInt(confMesas.derecha) + parseInt(confMesas.inferior) + 1
                                    } else if (superior !== -1) {
                                        silla = parseInt(data.code) + parseInt(confMesas.derecha) + parseInt(confMesas.inferior) + parseInt(confMesas.izquierda) + 1
                                    }

                            setButacaAdd({option: option, data: data, silla: silla});

                            setOpenModalConMesaInd(true)
                        }
                    }

                    let optDelButaca = (data) => {
                        let search = butacasVendidas.find(option => option.num_silla === data.num + 1 && option.codigo_silla === data.data)
                        if (search !== undefined) delButaca(search.id)
                    }

                    let optAddMesa = () => {
                        let Content = [];
                                
                        let sillas = (posicion, lugar) => {
                            for (let i = 0; i < posicion; i++){
                                const search = sillasDisabled.find(opt => opt.data === `${option.letraAlt}${option.id}${lugar}${i + 1}`);

                                if (search === undefined) Content.push({
                                    fila: option.letraAlt,
                                    columna: option.id,
                                    idFuncion: funcionSelect.value,
                                    idZona: dataButacas.data.id_zona,
                                    idDistribucion: dataButacas.data.id,
                                    sillaNum: i + 1,
                                    sillaCode: `${option.letraAlt}${option.id}${lugar}${i + 1}`
                                })
                            }
                        }

                        sillas(confMesas.derecha, "Derecha")
                        sillas(confMesas.inferior, "Inferior")
                        sillas(confMesas.izquierda, "Izquierda")
                        sillas(confMesas.superior, "Superior")
                        
                        let ContentDataButacas = dataButacas;
                        let SillasSelectD = 0;
                        let SillasDis = [];

                        if (ContentDataButacas !== undefined){
                            /* Si todas las butacas ya estan seleccionadas entonces eliminar en vez de agregar */

                            Content.forEach(i => {
                                const search = (ContentDataButacas.dataSold).find(opt => opt.codigo_silla === i.sillaCode);
                                if (search !== undefined){
                                    SillasSelectD ++;
                                    SillasDis.push(search);
                                }
                            }) 
                        }
                        
                        if(SillasSelectD === Content.length){
                            delMesa(SillasDis, `${option.letraAlt}${option.id}`)
                        }else{
                            let newContent = Content.map((obj, index)=>{
                                return{...obj, silla: index+1}
                            })
                            setButacaAdd({option: newContent, data: `${option.letraAlt}${option.id}`});
                            setOpenModalConMesa(true);
                        }
                    }

                    return <MesaDist
                        key = {`${option.id_fila}_${option.id}`}
                        confMesas = {confMesas}
                        customStyles = {customStyles}
                        data = {option}
                        numL = {numL}
                        sillasDisabled = {sillasDisabled}
                        disabled = {false}
                        onClick = {(data) => {optAddButaca(data)}}
                        onClickDel = {(data) => {optDelButaca(data)}}
                        onClickMesa = {() => {optAddMesa()}} 
                        stylesD = {stylesD}
                        butacasVendidas = {butacasVendidas}
                        colorButSel = {colorButSelInd}
                        colorButTran = {colorButTranInd}
                        colorButSold = {colorButSoldInd}
                        idContent = {idContent}
                    />
                })
                default: return col.map(option => {
                    const search = butacasVendidas.find(opt => opt.butaca_fila === option.letraAlt && parseInt(opt.butaca_columna) === option.id);
    
                    let stylesC = customStyles;
    
                    if (search !== undefined) {
                        switch (search.id_status){
                            case (1):
                                /* En Tansición */
                                if (search.idContent === idContent){
                                    stylesC = {
                                        backgroundColor: colorButSel,
                                        border: "1px solid #ffffff",
                                        borderRadius: "3px 3px"
                                    };
                                }else{
                                    stylesC = {
                                        backgroundColor: colorButTran,
                                        border: "1px solid #ffffff",
                                        borderRadius: "3px 3px"
                                    };
                                }
                            break;
                            case (2):
                                /* En Tansición */
                                if (search.idContent === idContent){
                                    stylesC = {
                                        backgroundColor: colorButSel,
                                        border: "1px solid #ffffff",
                                        borderRadius: "3px 3px"
                                    };
                                }else{
                                    stylesC = {
                                        backgroundColor: colorButTran,
                                        border: "1px solid #ffffff",
                                        borderRadius: "3px 3px"
                                    };
                                }
                            break;
                            case (4):
                                /* En Tansición */
                                if (search.idContent === idContent){
                                    stylesC = {
                                        backgroundColor: colorButSel,
                                        border: "1px solid #ffffff",
                                        borderRadius: "3px 3px"
                                    };
                                }else{
                                    stylesC = {
                                        backgroundColor: colorButacaCortesia,
                                        border: "1px solid #ffffff",
                                        borderRadius: "3px 3px"
                                    };
                                }
                            break;
                            default:
                                stylesC = {
                                    backgroundColor: colorButSold,
                                    border: "1px solid #ffffff",
                                    borderRadius: "3px 3px"
                                };
                            break;
                        }                    
                    }
    
                    let optAddButaca = () => {
                        if (search !== undefined) {
                            switch (search.id_status){
                                case (1):
                                    if (search.idContent === idContent){
                                        /* Eliminar Seleccion */
                                        delButaca(search.id);
                                    }else{
                                        toast.warn(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span> Este asiento se encuentra en transición</span></small>);
                                        return null;
                                    }
                                break;
                                case (2):
                                    if (search.idContent === idContent){
                                        /* Eliminar Seleccion */
                                        delButaca(search.id);
                                    }else{
                                        toast.warn(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span> Este asiento se encuentra en transición</span></small>);
                                        return null;
                                    }
                                break;
                                case (4):
                                    if (search.idContent === idContent){
                                        /* Eliminar Seleccion */
                                        delButaca(search.id);
                                    }else{
                                        toast.warn(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span> Este asiento se encuentra retirado por otro usuario</span></small>);
                                        return null;
                                    }
                                break;
                                default: 
                                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span> Este asiento no esta disponible</span></small>);
                                return null
                            }
                        }else{
                            setButacaAdd(option)
                            setOpenModalCon(true)
                            /* addButaca(option); */
                        }
                    }
    
                    return <ButacaDist
                        titleDis = {true}
                        onClick = {() => {optAddButaca()}}
                        control = {false}
                        key = {`${option.id_fila}_${option.id}`}
                        data = {option}
                        numL = {numL}
                        sumNumL = {(num) => {numL = numL + num}}
                        styles = {stylesC}
                        tamButaca = {tamButaca}
                        radButaca = {radButaca}
                        colorButaca = {colorBut}
                        estiloButaca = {estilo}
                    />
                })
            }
        }

        let nums = [];
        let fontSize = 10;
        let stylesSpan = {
            fontSize: fontSize, height: tamButaca, minWidth: tamButaca, maxWidth: tamButaca
        }

        nums.push({id: 0, content: <span style = {stylesSpan} className = "butaca-num"></span>});

        for (let i = 1, n = 1; i <= audit[0].columnas.length; i++){
            /* Buscar el numero en las columnas a ver si estan disabled */
            let idCol = audit[0].columnas[i - 1].id;
            if(!isNaN(Number(idCol)) === true){
                nums.push({id: i, content: <span style = {stylesSpan} className = "butaca-num"><strong className = "text-primary">{n}</strong></span>});
                n++;
            }else{
                nums.push({id: i, content: <span style = {stylesSpan} className = "butaca-num"><FontAwesomeIcon icon = {faCircle} style = {{fontSize: 7, color: "#ccc"}}/></span>});
            }
        }

        let num = 0;

        let leyendeNum = () => {
            if (estilo === "mesa") return null;

            return <>{nums.map(option => ( <React.Fragment key = {option.id}>{option.content}</React.Fragment> ))}</>
        }

        return (
            <div>
                {leyendeNum()}
                {audit.map(option => {
                    num++;

                    let styles = {};
                    let butAdicional = "";

                    if (num % 2 === 0 && alternar === 1){
                        styles = {marginLeft: 20};
                        butAdicional = <span className = "butaca-num" style = {{height: tamButaca, minWidth: 10, backgroundColor: "#fff"}}></span>
                    }

                    return(
                        <div key = {option.id}>
                            {filName(option.id, option.letraAlt)}
                            {butAdicional}
                            {colums(option.columnas)}
                        </div>
                    )
                })}
            </div>
        )
    }

    let tamButaca = 18;

    let radButaca = 4;

    let customStyles = {
        backgroundColor: "#d8d8d8",
        border: "1px solid #ffffff",
        borderRadius: "3px 3px"
    }

    let preloadTicker = () => {
        if (preloader === true) return (
            <div className = "preload-ticket">
               <div className = "preload-ticket-icon"><FontAwesomeIcon icon = {faCircleNotch} size = "5x" spin = {true}/></div> 
            </div>
        )
    }

    let retiros = () => {
        if (listRetiro.length === 0) return (
           <tr>
               <td colSpan = {4} align = "center">
                   <FontAwesomeIcon icon = {preloadRet.icon} spin = {preloadRet.spin} /> {preloadRet.text}
               </td>
           </tr> 
        )

        return (
            listRetiro.map(option => (
                <RetiroTicket data = {option} key = {option.id} updList = {(data) => {setListRetiro(data)}}/>
            ))
        )
    }

    let titleRet = () => {
        switch (true){
            case (dataButacas === ""): return <small><strong>SELECCIONA UNA FUNCIÓN Y UNA ZONA</strong></small>
            case (dataButacas.length === 0): return <small><strong>SELECCIONA UNA FUNCIÓN Y UNA ZONA</strong></small>
            case (dataButacas.data.estilo === "mesa"): return <small><strong>RESERVA MÚLTIPLE DE MESAS</strong></small>
            default: return <small><strong>RESERVA MÚLTIPLE DE ASIENTOS</strong></small>
        }
    }

    let cantRetiros = () => {
        if (funcionSelect.value === "") return null;
        let filter = listRetiro.filter(i => i.idFuncion === funcionSelect.value);

        return (
            <div className = "alert alert-danger custom-mt-15" align = "center" style = {{padding: 5}}>
                <small>Seleccionados</small>
                <div style = {{fontSize: 12}}><strong>{filter.length} Asientos</strong></div>
            </div>
        )
    }
 
    let nameZone = () => {
        if (dataSelect.length === 0) return null;

        return (
            <div style = {{fontWeight: "bold", color: dataSelect.color}} align = "center">
                ZONA {(dataSelect.name_zona).toUpperCase()}
            </div>
        )
    }

    let retiroSillas = () => {
        if (dataButacas === "" || dataButacas.length === 0) return null;
        let estilo = dataButacas.data.estilo;
        switch(estilo){
            case "libre": return null;
            default: return (
                <div className = "alert alert-info custom-mt-10">
                    {titleRet()}
                    <Row>
                        <Col md = {4}>
                            <small style = {{fontWeight: "bold"}}>Desde:</small>
                            <Select
                                isLoading = {selectMulOpt.loading}
                                isSearchable = {true} styles = {customStylesSelect}
                                className = "basic-multi-select"
                                onChange = {changeDesde} options = {desde}
                                noOptionsMessage = {() => { return "Sin opciones" }}
                                loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Buscando</> }}
                                value = {desdeSelect}
                                placeholder = "Desde"
                            />
                        </Col>
                        <Col md = {4}>
                            <small style = {{fontWeight: "bold"}}>Hasta:</small>
                            <Select
                                isLoading = {selectMulOpt.loading}
                                isSearchable = {true} styles = {customStylesSelect}
                                className = "basic-multi-select"
                                onChange = {changeHasta} options = {hasta}
                                noOptionsMessage = {() => { return "Sin Opciones" }}
                                loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Buscando</> }}
                                value = {hastaSelect}
                                placeholder = "Hasta"
                            />
                        </Col>
                        <Col md = {4} align = "right" style = {{paddingTop: 20,}}>
                            <Button size = "md"  color = "primary" title = "Este botón permite reservar el lote de asientos seleccionados. Si deseas retirar uno solo haz click en la butaca deseada" onClick = {retMultiple}>Retirar</Button>
                        </Col>
                    </Row>
                </div>
            )
        }
    }
    
    let auditorioSillas = () => {
        if (dataButacas === "") return <div align = "center" className = "custom-mt-10">Selecciona una función y una zona</div>;
        if (dataButacas.length === 0) return <div align = "center" className = "custom-mt-10"><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando Espera</div>;
        let estilo = dataButacas.data.estilo;

        switch(estilo){
            case "libre":
                return <div className = "custom-mt-10">
                    <Row>
                        <Col md = {6}>
                            <small>Selecciona la candidad de entradas</small>
                            <div><small>Disponibles: <strong>{dataButacas.disp}</strong></small></div>
                        </Col>
                        <Col md = {6}>
                            <Input type = "number" value = {cantLibre} onChange = {(e) => setCantLibre(e.target.value)}/>
                        </Col>
                        <Col md = {12} align = "right" className = "custom-mt-10">
                            <Button color = "primary" title = "Retirar la cantidad de entradas seleccionada" onClick = {() => {
                                (cantLibre <= 0) ? toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span> Debes seleccionar al menos una entrada</span></small>) :
                                setOpenModalLibre(true);
                            }}>Retirar</Button>
                        </Col>
                    </Row>
                </div>;
            default: return (
                <div>
                    <div>
                        <small style = {{fontWeight: "bold", color: "#000"}}>Selecciona un asiento para reservar</small>
                    </div>
                    <div style = {{overflow: "auto", padding: 10, minHeight: "64px", backgroundColor: "#fff"}}>{butacasSelect()}</div>
                    <div className = "custom-mt-10" style = {{color: "#000000"}}>
                        <small><span className = "butaca-default" style = {{
                            backgroundColor: "#0667bf",
                            border: "1px solid",
                            height: tamButaca,
                            minWidth: tamButaca,
                            maxWidth: tamButaca,
                            borderRadius: `${radButaca}px ${radButaca}px`
                        }}></span> Disponibles</small> | 
                        <small><span className = "butaca-default" style = {{
                            backgroundColor: colorButSold,
                            border: "1px solid #424242",
                            height: tamButaca,
                            minWidth: tamButaca,
                            maxWidth: tamButaca,
                            borderRadius: `${radButaca}px ${radButaca}px`
                        }}></span> Vendidos</small> | 
                        <small><span className = "butaca-default" style = {{
                            backgroundColor: colorButSel,
                            border: "1px solid #424242",
                            height: tamButaca,
                            minWidth: tamButaca,
                            maxWidth: tamButaca,
                            borderRadius: `${radButaca}px ${radButaca}px`
                        }}></span> Seleccionados</small> | 
                        <small><span className = "butaca-default" style = {{
                            backgroundColor: colorButTran,
                            border: "1px solid #424242",
                            height: tamButaca,
                            minWidth: tamButaca,
                            maxWidth: tamButaca,
                            borderRadius: `${radButaca}px ${radButaca}px`
                        }}></span>En transición</small>  | 
                            <small><span className = "butaca-default" style = {{
                            backgroundColor: colorButacaCortesia,
                            border: "1px solid #424242",
                            height: tamButaca,
                            minWidth: tamButaca,
                            maxWidth: tamButaca,
                            borderRadius: `${radButaca}px ${radButaca}px`
                        }}></span> Retirados</small>
                    </div>
                </div>
            )
        }
    }
    return (
        <>
            {preloadTicker()}
            <NavbarI />
            <main>
                <section className="section section-shaped section-lg">
                    <div className = "d-block d-sm-block d-md-block" align = "right" style = {{paddingRight: 15}}>
                        <small class = "bread-crumb-custom"><Link to = "/dashboard/home"><FontAwesomeIcon icon = {faHome}/></Link> | <FontAwesomeIcon icon = {faCode}/> Mis retiros</small>
                    </div>
                    <Row>
                        <Col md = {3} className = "d-none d-sm-none d-md-block">
                            <Sidebar active = "Mis Retiros"/>
                        </Col>
                        <Col md = {9} className = "custom-p-32">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col md = {6}>
                                            <h5>Mis retiros</h5>
                                            <div className = "text-muted" style = {{fontSize: 10, marginTop: -10}}>Selecciona un evento para continuar</div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md = {6}>
                                            <small style = {{color: "#000"}}>* Mis eventos</small>
                                            <Select
                                                isLoading = {eventOpt.loading}
                                                isSearchable = {true} styles = {customStylesSelect}
                                                className = "basic-multi-select"
                                                onChange = {changeEvento} options = {eventos}
                                                noOptionsMessage = {() => { return "Sin Opciones" }}
                                                loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                                                value = {eventSelect}
                                                placeholder = "Eventos"
                                            />
                                        </Col>
                                        <Col md = {12} className = "custom-mt-10 custom-border">
                                            <small style = {{color: "#000"}}>* Lista de retiros</small>
                                            <div style = {{overflowY: "auto", maxHeight:"55vh"}}>
                                                <Table className = "mb-0" hover style = {{fontSize: 10 }}>
                                                    <tbody>
                                                        <tr>
                                                            <th width = "50%">Zona</th>
                                                            <th width = "20%">Asiento</th>
                                                            <th width = "15%">Función</th>
                                                            <th>Acciones</th>
                                                        </tr>
                                                        {retiros()}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                        <Col md = {6} className = "custom-mt-20" style = {{borderTop: "1px solid #cccccc"}}>
                                            <small style = {{color: "#000"}}>Distribución</small>
                                            <div style = {{overflow: "auto", backgroundColor: "#fff", padding: 5, minHeight: "100px !importante"}}>{auditorio()}</div>
                                        </Col>
                                        <Col md = {6} className = "custom-mt-20" style = {{borderTop: "1px solid #cccccc"}}>
                                            <Row>
                                                <Col md = {8}>
                                                    <small style = {{color: "#000"}}>* Funciones</small>
                                                    {/* <div>
                                                        <small className = "text-danger"><strong>IMPORTANTE: Sólo los Administradores pueden ELIMINAR los tickets otorgados en esta sección</strong></small>
                                                    </div> */}
                                                    <Select
                                                        isLoading = {funcionesOpt.loading}
                                                        isSearchable = {true} styles = {customStylesSelect}
                                                        className = "basic-multi-select"
                                                        onChange = {changeFuncion} options = {funciones}
                                                        noOptionsMessage = {() => { return "Sin Opciones" }}
                                                        loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Buscando funciones</> }}
                                                        value = {funcionSelect}
                                                        placeholder = "Funciones"
                                                    />
                                                </Col>
                                                <Col md = {4}>{cantRetiros()}</Col>
                                                <Col md = {12}>
                                                    {retiroSillas()}
                                                </Col>
                                                <Col md = {12}>
                                                    <small style = {{color: "#000"}}>Asientos {nameZone()}</small>
                                                    {auditorioSillas()}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </section>
            </main>
            {modalConfirm()}
            {modalConfirmMult()}
            {modalConfirmMesaInd()}
            {modalConfirmMesa()}
            {modalConfirmLibre()}
        </>
    )
}

export default MainRetiros;