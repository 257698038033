import { requerido, mensObligatorio, selectObligatorio} from "./Validaciones";
import { Post } from "./Generales";
import { Url } from "./Url";

export const AddCuenta = (campos, callback) => {
    let errores = {
        cantidad: 0,
        errMesss: []
    };

    switch(campos.tipoSelect.value){
        case "cuenta":
            requerido   (campos.tipoCtaSelect.value, errores,   selectObligatorio("el", "Tipo de Cuenta"), (resp) => { errores = resp })
            requerido   (campos.bancoSelect.value, errores,     selectObligatorio("el", "Banco"), (resp) => { errores = resp })
            requerido   (campos.numCta, errores,                mensObligatorio("Número de Cuenta"), (resp) => { errores = resp })
            requerido   (campos.nacSelect.value, errores,       selectObligatorio("el", "Tipo de Documento"), (resp) => { errores = resp })
            requerido   (campos.tipoDoc, errores,               mensObligatorio("Número dle Documento"), (resp) => { errores = resp })
            requerido   (campos.nombre, errores,                mensObligatorio("Titular de la Cuenta"), (resp) => { errores = resp })
        break;
        case "pago_movil":
            requerido   (campos.bancoSelect.value, errores,     selectObligatorio("el", "Banco"), (resp) => { errores = resp })
            requerido   (campos.ci, errores,                    mensObligatorio("Cédula"), (resp) => { errores = resp })
            requerido   (campos.tlf, errores,                   mensObligatorio("Teléfono"), (resp) => { errores = resp })
        break;
        case "zelle":

        break;
        case "paypal":

        break;
        default:
            errores = {
                cantidad: 0,
                errMesss: []
            };
        break;
    }

    if (errores.cantidad === 0){
        let data = JSON.parse(sessionStorage.getItem("ContentData"));

        let Content = {
            eid: data.E_ID,
            uid: data.U_ID,
            tipoSelect: campos.tipoSelect.value,
            tipoCtaSelect: campos.tipoCtaSelect.value,
            bancoSelect: campos.bancoSelect.value,
            numCta: campos.numCta,
            nacSelect: campos.nacSelect.value,
            numDoc: `${campos.nacSelect.label}-${campos.tipoDoc}`,
            nombre: campos.nombre,
            ci: campos.ci,
            tlf: campos.tlf
        }

        Post(`${Url}addCuenta`, Content, (resp) => {
            let data = JSON.parse(resp)
            callback([errores, data])
        });
    }else{
        callback([errores])
    }
}

export const SearchCuenta = (callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID
    }

    Post(`${Url}searchCuenta`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const DelCuenta = (id, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        id: id
    }

    Post(`${Url}delCuenta`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}