import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import { faHome, faUsersCog } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CardsFooter from "components/Footers/CardsFooter"
import NavbarI from "components/Navbars/NavbarI"
import React from "react"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap"
import PreguntasFrecuentes from "views/IndexSections/FAQ"
import Sidebar from "views/dashboard/Sidebar"
import {Whatsapp, PDF} from "../../components/Social/Whatsapp"

const MainAtencionUser = ()=>{

    const preguntas = [{
        'titulo': '¿Qué hacer si realice un pago erróneo al valor de la entrada?',
        'contenido': 'Debe contactar atención al cliente de TaquillaEnLinea y manifestar que realizó erróneamente el pago de la entrada. ',
    },
    {
        'titulo': '¿Cómo puedo realizar el pago con C2P?',
        'contenido': 'Al hacer click en el siguiente enlace, se le redireccionará a un documento en formato PDF que contiene los distintos métodos de solicitud del codigo C2P desde su banco preferido.',    
    }]

    return(
        <>
        <NavbarI />
        <main>
            <section className="section section-shaped section-lg">
                <div className = "d-block d-sm-block d-md-block" align = "right" style = {{paddingRight: 15}}>
                    <small class = "bread-crumb-custom"><Link to = "/dashboard/home"><FontAwesomeIcon icon = {faHome}/></Link> | <FontAwesomeIcon icon = {faWhatsapp}/> Atencion al usuario</small>
                </div>
                <Row>
                    <Col md = {3} className = "d-none d-sm-none d-md-block">
                        <Sidebar active = "Mi Perfil"/>
                    </Col>
                    <Col md = {9} className = "custom-p-32">
                        <Card>
                            <CardHeader align = "left">
                                <h5>Atencion al usuario</h5>
                            </CardHeader>
                            <CardBody>
                                <PreguntasFrecuentes preguntas={preguntas} titulo = {"Preguntas Frecuentes"} type={'text-info'} />
                                <section className="mb-3 ml-3">
                                    <span className="mr-3">
                                        <PDF tooltip={'Informacion C2P'} />
                                    </span>
                                    <span>
                                        <Whatsapp tooltip={'Para más información'} />
                                    </span>
                                </section>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </section>
        </main>
        <footer style={{marginTop:'39px'}}>
            <CardsFooter />
        </footer>
    </>
    )
}


export default MainAtencionUser