import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { QrReader } from 'react-qr-reader';

import { Button, Card, CardBody, CardHeader, Input, Row, Col, Table, InputGroup, InputGroupAddon } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faTimesCircle, faHome, faArrowUp, faTicketAlt, faQrcode, faSearch } from '@fortawesome/free-solid-svg-icons';

import NavbarI from "components/Navbars/NavbarI.js";
import Sidebar from '../dashboard/Sidebar';
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal2 from "components/Modals/ErrorModal2";
import WarnModal from "components/Modals/WarnModal";

import { SearchEventsTaquilla, SearchFunctionsTaquilla, VerifyTicketQr } from "functions/TaquillaFunctions";
import { SearchTicketSoldTaq } from "functions/EventosFunctions";
import DetalleTicket from "./DetalleTicket";

let control = false;
const MainVerificacionTaquilla = (props) => {
    const [preloader, setPreloader]     = useState(false);
    const [eventSelect, setEventSelect] = useState({value: "", label: "Selecciona un Evento"});
    const [eventos, setEventos]         = useState([]);
    const [eventOpt, setEventOpt]       = useState({ disabled: true, loading: true })
    const [funcionesOpt, setFuncionesOpt]   = useState({ disabled: false, loading: false })
    const [funciones, setFunciones]     = useState([]);
    const [funcionSelect, setFuncionSelect] = useState({value: "", label: "Selecciona Primero un Evento", recinto: ""});
    const [preload, setPreload]         = useState({icon: faArrowUp, spin: false, text: "Selecciona un evento y una función"});
    const [ventas, setVentas]           = useState([]);
    const [ventas2, setVentas2]         = useState([]);
    const [ventas3, setVentas3]         = useState([]);
    const [distribucions, setDistribucions] = useState([]);
    const [numTicket, setNumTicket]     = useState("");
    const [iconSearch, setIconSearch]   = useState({icon: faSearch, spin: false});
    const [zonasOpt, setZonasOpt]       = useState({ disabled: false, loading: false })
    const [zonas, setZonas]             = useState([]);
    const [zonasSelect, setZonaselect]  = useState([]);
    const [asientoOpt, setAsientoOpt]       = useState({ disabled: false, loading: false })
    const [asientos, setAsientos]           = useState([]);
    const [asientoSelect, setAsientoselect] = useState([]);
    const [camara, setCamara]           = useState(false);
    const [classCol, setClassCol]       = useState("custom-mt.10")
    const [textScan, setTextScan]       = useState("Escanear Entrada")

    const [data, setData]               = useState('');
    const [modalQr, setModalQr]         = useState(false);
    const [modalQrVerifyOk, setModalQrVerifyOk]     = useState(false);
    const [modalQrVerifyNOk, setModalQrVerifyNOk]   = useState(false);
    const [modalQrVerifyAllNOk, setModalQrVerifyAllNOk]     = useState(false);
    const [modalQrVerifyAllWarn, setModalQrVerifyAllWarn]   = useState(false);
    const [modalQrVerifyAllOk, setModalQrVerifyAllOk]       = useState(false);
    const [messQrOk, setMessQrOk]       = useState('');
    const [messQrNOk, setMessQrNOk]     = useState('');
    const [messQrAllOk, setMessQrAllOk]         = useState('');
    const [messQrAllNOk, setMessQrAllNOk]       = useState('');
    const [titleQrAllNOk, setTitleQrAllNOk]     = useState('');
    const [titleQrAllWarn, setTitleQrAllWarn]   = useState('');
    const [titleQrAllOk, setTitleQrAllOk]       = useState('');
    
    useEffect(() => {
        SearchEventsTaquilla((resp) => {
            setEventOpt({ disabled: false, loading: false })

            let Content = [];
            (resp.data).forEach(option => {
                Content.push({
                    value: option.evento_detail.id,
                    label: option.evento_detail.name_event,
                    recinto: option.evento_detail.id_recinto
                });
            })

            setEventos(Content);
        })
    }, [props]);

    useEffect(() => {
        (camara === false) ? setClassCol("custom-mt-10") : setClassCol("");
        (camara === false) ? setTextScan("Escanear Entrada") : setTextScan("Ocultar Escaner");
        
    }, [camara]);

    useEffect(() => {
        if (data !== "" && data !== undefined){
            setCamara(false)
            setModalQr(true)
        } 
    }, [data]);

    let ladoSilla = (string) => {
        let izquierda = string.indexOf("Izquierda");
        let superior = string.indexOf("Superior");
        let derecha = string.indexOf("Derecha");
        let inferior = string.indexOf("Inferior");

        switch (true){
            case (izquierda > 0): return "Izquierda";
            case (superior > 0): return "Superior";
            case (derecha > 0): return "Derecha";
            case (inferior > 0): return "Inferior";
            default: return "";
        }
    }

    let modalQrVerifiOk = () => {
        if (modalQrVerifyOk === false) return null;

        return(
            <SuccessModal isOpen = {modalQrVerifyOk} close = {() => {
                setModalQrVerifyOk(false)
                setCamara(true)
            }} content = {messQrOk} contentTitle = "Código Verificado"/>
        )
    }

    let modalQrVerifiNOk = () => {
        if (modalQrVerifyNOk === false) return null;
        
        return (
            <ErrorModal2 isOpen = {modalQrVerifyNOk} close = {() => {
                setModalQrVerifyNOk(false)
                setCamara(true)
            }} mess = {messQrNOk}/>
        )
    }

    let statusCheck = (status) => {
        if (status === "1") return `<div> 
            <i class = "fa fa-times-circle fa-xl text-danger"></i>
            <div><small><strong>NO VÁLIDO</strong></small></div>
        </div>`;

        return `<div> 
            <i class = "fa fa-check-circle fa-xl text-success"></i>
            <div><small><strong>VERIFICADO</strong></small></div>
        </div>`;
    }

    let modalQrVerifiAllNOk = () => {
        if (modalQrVerifyAllNOk === false) return null;
        
        let Content = `${titleQrAllNOk}<div align = "left" class = "custom-mt-10" style = "overflow-x: hidden; overflow-y: auto; max-height: 62vh"><small><strong>Especificaciones de los Tickets:</strong></small>`;

        messQrAllNOk.forEach(i => {
            Content += `<div class = "card card-body detail-ticket">
                <div class = "row">
                    <div class = "col-md-9 col-sm-9 col-xs-9" align = "left">
                        <div><small><strong>Evento:</strong> <strong class = "text-primary">${i.evento}</strong></small></div>
                        <div><small><strong>Ticket N°:</strong> ${i.ticket_num}</small></div>
                        <div><small><strong>Detalle:</strong> <strong class = "text-danger">${i.asiento} - ZONA [${i.zona}]</strong></small></div>
                    </div>
                    <div class = "col-md-3 col-sm-3 col-xs-3" align = "center" style = "margin-top: 10px">
                        ${statusCheck(i.status)}
                    </div>
                </div>
            </div>`;
        })

        Content += `</div>`;

        return (
            <ErrorModal2 isOpen = {modalQrVerifyAllNOk} close = {() => {
                setModalQrVerifyAllNOk(false)
                setCamara(true)
            }} mess = {Content}/>
        )
    }

    let modalQrVerifiAllWarning = () => {
        if (modalQrVerifyAllWarn === false) return null;
        
        let Content = `${titleQrAllWarn}<div align = "left" class = "custom-mt-10" style = "overflow-x: hidden; overflow-y: auto; max-height: 62vh"><small><strong>Especificaciones de los Tickets:</strong></small>`;

        messQrAllNOk.forEach(i => {
            Content += `<div class = "card card-body detail-ticket">
                <div class = "row">
                    <div class = "col-md-9 col-sm-9 col-xs-9" align = "left">
                        <div><small><strong>Evento:</strong> <strong class = "text-primary">${i.evento}</strong></small></div>
                        <div><small><strong>Ticket N°:</strong> ${i.ticket_num}</small></div>
                        <div><small><strong>Detalle:</strong> <strong class = "text-danger">${i.asiento} - ZONA [${i.zona}]</strong></small></div>
                    </div>
                    <div class = "col-md-3 col-sm-3 col-xs-3" align = "center" style = "margin-top: 10px">
                        ${statusCheck(i.status)}
                    </div>
                </div>
            </div>`;
        })

        messQrAllOk.forEach(i => {
            Content += `<div class = "card card-body detail-ticket">
                <div class = "row">
                    <div class = "col-md-9 col-sm-9 col-xs-9" align = "left">
                        <div><small><strong>Evento:</strong> <strong class = "text-primary">${i.evento}</strong></small></div>
                        <div><small><strong>Ticket N°:</strong> ${i.ticket_num}</small></div>
                        <div><small><strong>Detalle:</strong> <strong class = "text-danger">${i.asiento} - ZONA [${i.zona}]</strong></small></div>
                    </div>
                    <div class = "col-md-3 col-sm-3 col-xs-3" align = "center" style = "margin-top: 10px">
                        ${statusCheck(i.status)}
                    </div>
                </div>
            </div>`;
        })

        Content += `</div>`;
        
        return (
            <WarnModal isOpen = {modalQrVerifyAllWarn} close = {() => {
                setModalQrVerifyAllWarn(false)
                setCamara(true)
            }} mess = {Content}/>
        )
    }

    let modalQrVerifiAllOk = () => {
        if (modalQrVerifyAllOk === false) return null;

        let Content = `${titleQrAllOk}<div align = "left" class = "custom-mt-10" style = "overflow-x: hidden; overflow-y: auto; max-height: 62vh"><small><strong>Especificaciones de los Tickets:</strong></small>`;

        messQrAllOk.forEach(i => {
            Content += `<div class = "card card-body detail-ticket">
                <div class = "row">
                    <div class = "col-md-9 col-sm-9 col-xs-9" align = "left">
                        <div><small><strong>Evento:</strong> <strong class = "text-primary">${i.evento}</strong></small></div>
                        <div><small><strong>Ticket N°:</strong> ${i.ticket_num}</small></div>
                        <div><small><strong>Detalle:</strong> <strong class = "text-danger">${i.asiento} - ZONA [${i.zona}]</strong></small></div>
                    </div>
                    <div class = "col-md-3 col-sm-3 col-xs-3" align = "center" style = "margin-top: 10px">
                        ${statusCheck(i.status)}
                    </div>
                </div>
            </div>`;
        })

        Content += `</div>`;

        return(
            <SuccessModal isOpen = {modalQrVerifyAllOk} close = {() => {
                setModalQrVerifyAllOk(false);
                setCamara(true)
            }} content = {Content} contentTitle = "Verificado"/>
        )
    }

    let modalSearchQr = () => {
        if (modalQr === false) return null;
        
        let url = data;
        let part = url.split("/");
        let urlEvent = part.pop();
        let split = urlEvent.split("?");
        
        if (split[0] === "verify_tickets" || split[0] === "verify_ticket"){
            if (control === false){
                control = true;

                VerifyTicketQr(split, (resp) => {
                    control = false;    
                    setModalQr(false)
                
                    switch (true) {
                        case (resp.status === "error" || resp.status === "error_log"):
                            setMessQrNOk(resp.mess)
                            setModalQrVerifyNOk(true)
                        break;
                        case (resp.status === "error_all"):
                            setMessQrAllNOk(resp.tickets_error)
                            setTitleQrAllNOk(resp.mess)
                            setModalQrVerifyAllNOk(true)
                        break;
                        case (resp.status === "warning_all"):
                            setTitleQrAllWarn(resp.mess);
                            setMessQrAllNOk(resp.tickets_error);
                            setMessQrAllOk(resp.tickets_ok);
                            setModalQrVerifyAllWarn(true);
                        break;
                        case (resp.status === "ok_all"):
                            setTitleQrAllOk(resp.mess);
                            setMessQrAllOk(resp.tickets_ok);
                            setModalQrVerifyAllOk(true);
                        break;
                        default:
                            setMessQrOk(resp.mess)
                            setModalQrVerifyOk(true)
                        break;
                    }
                })
            }

            let dataContent = () => {
                return (
                    `<div align = "center">
                        <div class = "spinner"></div>
                        <h6>Verificando el Código</h6>
                    </div>`
                )
            }
    
            return(
                <SuccessModal isOpen = {modalQr} close = {() => {
                    setModalQr(false)
                    setCamara(true)
                }} content = {dataContent()} contentTitle = "Verificando el código"/>
            )
        }else{
            let dataContent = () => {

                return (
                    `<div align = "center">
                        <div class = "fa fa-times-circle fa-2x text-danger"></div>
                        <h6>El código es inválido</h6>
                    </div>`
                )
            }

            return (
                <ErrorModal2 isOpen = {modalQr} close = {() => {
                    setModalQr(false)
                    setCamara(true)
                }} mess = {dataContent()}/>
            )
        }
    }
    
    let preloadTicker = () => {
        if (preloader === true) return (
            <div className = "preload-ticket">
               <div className = "preload-ticket-icon"><FontAwesomeIcon icon = {faCircleNotch} size = "5x" spin = {true}/></div> 
            </div>
        )
    }

    let changeEvento = (eventSelect) => {
        setEventSelect(eventSelect)
        setFuncionesOpt({disabled: true, loading: true})
        setFuncionSelect({value: "", label: "Selecciona Primero un Evento", recinto: ""});

        SearchFunctionsTaquilla(eventSelect.value, (resp) => {
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                break;
                default:
                    setFuncionesOpt({disabled: false, loading: false})
                    setFuncionSelect({ value: "", label: "Selecciona" })
                    let Content = [];
                    (resp.funciones).forEach(option => {
                        Content.push({
                            value: option.id,
                            label: `Funcion del ${option.fecha} - Hora: [${option.hora_in} - ${option.hora_out}]`
                        });
                    });

                    setFunciones(Content);
                break;
            }
        })
    }

    let changeFuncion = (funcionSelect) => {
        setFuncionSelect(funcionSelect) 
        setNumTicket("")

        setEventOpt({disabled: true, loading: true})
        setFuncionesOpt({disabled: true, loading: true})
        setZonasOpt({disabled: true, loading: true})
        setAsientoOpt({disabled: true, loading: true})

        setVentas([])
        setPreload({icon: faCircleNotch, spin: true, text: "Buscando Tickets de la función seleccionada"})

        SearchTicketSoldTaq(eventSelect.value, funcionSelect.value, (resp) => {
            setEventOpt({disabled: false, loading: false})
            setFuncionesOpt({disabled: false, loading: false})
            setZonasOpt({disabled: false, loading: false})
            setAsientoOpt({disabled: false, loading: false})

            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                    setPreload({icon: faArrowUp, spin: false, text: "Selecciona un evento y una función"});
                    setVentas([])
                    setVentas2([])
                break;
                case (resp.data.length === 0):
                    setPreload({icon: faTimesCircle, spin: false, text: "Esta función aún no tiene ventas registradas"})
                    setVentas([])
                    setVentas2([])
                break;
                default:
                    let vendidos = resp.ticketVendido;

                    let zonasAc = zonas;
                    let asientosAc = asientos;

                    vendidos.forEach(i => {
                        let idZona = i.data_ticket.id_zona;
                        let nombreZona = i.data_ticket.nombre_zona;
                        let idAsiento = i.data_ticket.id;

                        let search = zonasAc.find(option => option.value === idZona);
                        let searchAsiento = asientosAc.find(option => option.value === idAsiento);

                        if (search === undefined) zonasAc.push({
                            value: idZona,
                            label: nombreZona
                        })

                        if (searchAsiento === undefined) {
                            switch(i.data_ticket.estilo){
                                case "libre": break;
                                case "mesa":
                                    let asiento = `Mesa: ${i.data_ticket.butaca_fila}${i.data_ticket.butaca_columna} - Silla: ${ladoSilla(i.data_ticket.codigo_silla)}${i.data_ticket.num_silla}`;
                                    
                                    asientosAc.push({
                                        value: idAsiento,
                                        label: asiento
                                    })
                                break;
                                default:
                                    let asientos = `${i.data_ticket.butaca_fila}${i.data_ticket.butaca_columna}`;

                                    asientosAc.push({
                                        value: idAsiento,
                                        label: asientos
                                    })
                                break;
                            }
                        }
                    })

                    let sortAsientos = asientosAc.sort((a, b) => {
                        let fa = a.label,
                            fb = b.label;
                    
                        if (fa < fb) return -1;
                        if (fa > fb) return 1;
                        return 0;
                    });

                    setZonas(zonasAc);
                    setAsientos(sortAsientos);
                    setVentas(resp.ticketVendido);
                    setVentas2(resp.ticketVendido);
                    setDistribucions(resp.distribucion)
                break;
            }
        })
    }
    
    let changeZona = (zonasSelect) => {
        setZonaselect(zonasSelect)
        setNumTicket("")

        let ventasAc = ventas2;
        let ventasContent = [];
        let asientosAc = [];

        if (zonasSelect.length === 0) ventasContent = ventas2;

        ventasAc.forEach(i => {
            let search = zonasSelect.find(option => option.value === i.data_ticket.id_zona)

            if (search !== undefined) ventasContent.push(i)
        });
        
        ventasContent.forEach(i => {
            let idAsiento = i.data_ticket.id;

            let searchAsiento = asientosAc.find(option => option.value === idAsiento);

            if (searchAsiento === undefined){
                if (searchAsiento === undefined) {
                    switch(i.data_ticket.estilo){
                        case "libre": break;
                        case "mesa":
                            let asiento = `Mesa: ${i.data_ticket.butaca_fila}${i.data_ticket.butaca_columna} - Silla: ${ladoSilla(i.data_ticket.codigo_silla)}${i.data_ticket.num_silla}`;
                            
                            asientosAc.push({
                                value: idAsiento,
                                label: asiento
                            })
                        break;
                        default:
                            asiento = `${i.data_ticket.butaca_fila}${i.data_ticket.butaca_columna}`;

                            asientosAc.push({
                                value: idAsiento,
                                label: asiento
                            })
                        break;
                    }
                }
            }
        })

        setAsientos(asientosAc);
        setAsientoselect([]);
        setVentas(ventasContent);
        setVentas3(ventasContent);
    }

    let changeAsiento = (asientoSelect) => {
        setAsientoselect(asientoSelect)
        setNumTicket("")

        let ventasAc = ventas3;
        let ventasContent = [];

        if (asientoSelect.length === 0) ventasContent = ventas;
        if (zonasSelect.length === 0) ventasContent = ventas3;

        ventasAc.forEach(i => {
            let search = asientoSelect.find(option => option.value === i.data_ticket.id)

            if (search !== undefined) ventasContent.push(i)
        });

        setVentas(ventasContent);
    }

    let searchIdTicketBtn = () => {
        setAsientoselect([]);
        setZonaselect([]);
        setAsientos([]);

        let ventasAc = ventas2;
        let ventasContent = [];

        if (numTicket === "") ventasContent = ventas2;

        ventasAc.forEach(i => {
            if(i.id_factura === parseInt(numTicket)) ventasContent.push(i)
        });

        if (ventasContent.length === 0){
            setPreload({icon: faTimesCircle, spin: false, text: "No existen coincidencias con el Id del Ticket ingresado"})
            toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> No existen coincidencias con el Id del ticket ingresado</small>);
        }
        
        setVentas(ventasContent);
    }

    let searchIdTicket = (e) => {      
        if (e.which === 13){
            setAsientoselect([]);
            setZonaselect([]);
            setAsientos([]);
    
            let ventasAc = ventas2;
            let ventasContent = [];
    
            if ((numTicket) === "") ventasContent = ventas2;
    
    
            ventasAc.forEach(i => {
                if(i.id_factura === parseInt(numTicket)) ventasContent.push(i)
            });
    
            if (ventasContent.length === 0){
                setPreload({icon: faTimesCircle, spin: false, text: "No existen coincidencias con el Id del Ticket ingresado"})
                toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> No existen coincidencias con el Id del ticket ingresado</small>);
            }
            
            setVentas(ventasContent);
        }
    }
    
    let vendidos = () => {
        if (ventas.length === 0) return (
            <tr>
               <td colSpan = {5} align = "center">
                   <FontAwesomeIcon icon = {preload.icon} spin = {preload.spin} /> {preload.text}
               </td>
           </tr>
        )

        let sortVentas = ventas.sort((a, b) => {
            let fa = a.data_ticket.nombre_zona,
                fb = b.data_ticket.nombre_zona;
        
            if (fa < fb) return -1;
            if (fa > fb) return 1;
            return 0;
        });

        return (
            sortVentas.map(option => (
                <DetalleTicket key = {option.id} data = {option} distribucions = {distribucions}/>
            ))
        )

    }

    let camQr = () => {
        if (camara === false) return null;

        return (
            <div>
                <QrReader onResult = {(result, error) => { /* if (!!result) */ setData(result?.text); }} constraints = {{ facingMode: 'environment' }}/>
            </div>
        )
    }
    
    const customStylesSelect = {
        menu: styles => ({ ...styles, color: "#000", fontSize: 14, zIndex: 3 }),
        multiValue: styles => ({ ...styles, backgroundColor: "#ffe1af", borderRadius: "4px" }),
        multiValueLabel: styles => ({ ...styles, color: "#000" }),
        placeholder: styles => ({ ...styles, fontSize: 14, color: "#b4bcc3" }),
    };

    return (
        <>
            {preloadTicker()}
            <NavbarI />
            <main>
                <section className="section section-shaped section-lg">
                    <div className = "d-block d-sm-block d-md-block" align = "right" style = {{paddingRight: 15}}>
                        <small class = "bread-crumb-custom"><Link to = "/dashboard/home"><FontAwesomeIcon icon = {faHome}/></Link> | <FontAwesomeIcon icon = {faTicketAlt}/> Verificación de Entradas</small>
                    </div>
                    <Row>
                        <Col md = {3} className = "d-none d-sm-none d-md-block">
                            <Sidebar active = "Verificación"/>
                        </Col>
                        <Col md = {9} className = "custom-p-32">
                            <Card>
                            <CardHeader>
                                    <Row>
                                        <Col md = {6}>
                                            <h5>Verificación de entradas</h5>
                                            <div className = "text-muted" style = {{fontSize: 10, marginTop: -10}}>Selecciona un evento para continuar</div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                    <Col md = {4} align = "center" className = "custom-mt-10">
                                            <Row>
                                                <Col md = {12} className = {classCol}>
                                                    <div style = {{marginBottom: 15}}>{camQr()}</div>
                                                    <Button size = "md" onClick = {() => setCamara(!camara)} color = "danger"><FontAwesomeIcon icon = {faQrcode} /> {textScan}</Button>
                                                </Col>
                                                <Col md = {12} align = "center" className = "custom-mt-15">
                                                    <h6>Filtrar entradas</h6>
                                                </Col>
                                                <Col md = {12}>
                                                    <small>Buscar por número (Ticket ID)</small>
                                                    <InputGroup>
                                                        <Input  type = "number" placeholder = "Número del Ticket" title = "Indica el Número del Ticket" value = {numTicket} onChange = {(e) => {
                                                            if ((e.target.value) === "") setVentas(ventas2);  
                                                            setNumTicket(e.target.value)
                                                        }} onKeyPress = {searchIdTicket}/>
                                                        <InputGroupAddon addonType = "prepend" color = "danger">
                                                            <button onClick = {searchIdTicketBtn} style = {{paddingLeft:20, paddingRight: 20}} class = "btn-warning custom-btn"><FontAwesomeIcon icon = {iconSearch.icon} spin = {iconSearch.spin}/></button>    
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </Col>
                                                <Col md = {12} className = "custom-mt-10">
                                                    <small>Buscar por zona</small>
                                                    <Select
                                                        isMulti
                                                        isLoading = {zonasOpt.loading}
                                                        isSearchable = {true} styles = {customStylesSelect}
                                                        className = "basic-multi-select"
                                                        onChange = {changeZona} options = {zonas}
                                                        noOptionsMessage = {() => { return "Sin Opciones" }}
                                                        loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Buscando zonas</> }}
                                                        value = {zonasSelect}
                                                        placeholder = "Selecciona las zonas"
                                                    />
                                                </Col>
                                                <Col md = {12} className = "custom-mt-10">
                                                    <small>Buscar por asiento</small>
                                                    <Select
                                                        isMulti
                                                        isLoading = {asientoOpt.loading}
                                                        isSearchable = {true} styles = {customStylesSelect}
                                                        className = "basic-multi-select"
                                                        onChange = {changeAsiento} options = {asientos}
                                                        noOptionsMessage = {() => { return "Sin Opciones" }}
                                                        loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Buscando Asientos</> }}
                                                        value = {asientoSelect}
                                                        placeholder = "Selecciona los asientos"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md = {8}>
                                            <Row>
                                                <Col md = {6}>
                                                    <small style = {{color: "#000"}}>* Mis eventos</small>
                                                    <Select
                                                        isLoading = {eventOpt.loading}
                                                        isSearchable = {true} styles = {customStylesSelect}
                                                        className = "basic-multi-select"
                                                        onChange = {changeEvento} options = {eventos}
                                                        noOptionsMessage = {() => { return "Sin Opciones" }}
                                                        loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                                                        value = {eventSelect}
                                                        placeholder = "Eventos"
                                                    />  
                                                </Col>
                                                <Col md = {6}>
                                                    <small style = {{color: "#000"}}>* Funciones</small>
                                                    <Select
                                                        isLoading = {funcionesOpt.loading}
                                                        isSearchable = {true} styles = {customStylesSelect}
                                                        className = "basic-multi-select"
                                                        onChange = {changeFuncion} options = {funciones}
                                                        noOptionsMessage = {() => { return "Sin Opciones" }}
                                                        loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Buscando funciones</> }}
                                                        value = {funcionSelect}
                                                        placeholder = "Funciones"
                                                    />
                                                </Col>
                                                <Col md = {12}>
                                                    <small style = {{color: "#000"}}>* Lista de entradas vendidas</small>
                                                    <div style = {{overflowY: "auto", maxHeight:"55vh"}}>
                                                        <Table className = "mb-0 table-custom-primary" striped hover  style = {{boxShadow: "2px 1px 2px #000", fontSize: 10 }}>
                                                            <tbody>
                                                                <tr>
                                                                    <th width = "35%">Zona</th>
                                                                    <th width = "10%">Asiento</th>
                                                                    <th width = "25%">Ticeket ID</th>
                                                                    <th width = "15%">Estatus</th>
                                                                    <th>Acciones</th>
                                                                </tr>
                                                                {vendidos()}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </section>
            </main>
            {modalSearchQr()}
            {modalQrVerifiOk()}
            {modalQrVerifiNOk()}
            {modalQrVerifiAllNOk()}
            {modalQrVerifiAllWarning()}
            {modalQrVerifiAllOk()}
        </>
    )
}

export default MainVerificacionTaquilla;