import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from 'react-toastify';

import { Button, Card, CardBody, CardHeader, Row, Col, Table, Input } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faTimesCircle, faHome, faCreditCard, faPlusCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import NavbarI from "components/Navbars/NavbarI.js";
import Sidebar from '../dashboard/Sidebar';
import ErrorsModal from "components/Modals/ErrorsModal";

import { BancosVenezuela } from "shared/data";
import { SearchCuenta, AddCuenta } from "functions/CuentasFunctions";
import DetalleCuenta from "./DetalleCuenta";

const MainCuentas = (props) => {
    const [tipoSelect, setTipoSelect]   = useState({value: "cuenta", label: "Cuenta Bancaria"});
    const [tipos, setTipo]              = useState([
        {value: "cuenta", label: "Cuenta Bancaria"},
        {value: "pago_movil", label: "Pago Móvil"},
        {value: "zelle", label: "Zelle"},
        {value: "paypal", label: "PayPal"},
    ]);
    const [tipoOpt, setTipoOpt]         = useState({ disabled: false, loading: false })
    const [tipoCtaSelect, setCtaSelect] = useState({value: "", label: "Selecciona"});
    const [tiposCta, setTipoCta]        = useState([
        {value: "ahorro", label: "Ahorros"},
        {value: "corriente", label: "Corriente"}
    ])
    const [tipoCtaOpt, setTipoCtaOpt]   = useState({ disabled: false, loading: false })
    const [bancoSelect, setBancoSelect] = useState({value: "", label: "Selecciona"});
    const [bancos, setBancos]           = useState([])
    const [bancosOpt, setBancosOpt]     = useState({ disabled: false, loading: false })
    const [numCta, setnumCta]           = useState("")
    const [nacSelect, setNacSelect]     = useState({value: "", label: "Selecciona"});
    const [nac, setNac]                 = useState([
        {value: "v", label: "V"},
        {value: "e", label: "E"},
        {value: "p", label: "P"},
        {value: "j", label: "J"},
        {value: "g", label: "G"},
        {value: "m", label: "M"},
        {value: "c", label: "C"},

    ])
    const [nacOpt, setNacOpt]           = useState({ disabled: false, loading: false })
    const [tipoDoc, setTipoDoc]         = useState("")
    const [nombre, setNombre]           = useState("")
    const [botonCta, setBotonCta]       = useState({ icon: faPlusCircle, spin: false, disabled: false, text: "Agregar" })
    const [ci, setCi]                   = useState("")
    const [tlf, setTlf]                 = useState("")
    const [cuentas, setCuentas]         = useState([])
    const [preload, setPreload]         = useState({icon: faCircleNotch, spin: true, text: "Buscando listado de cuentas registradas"});
    const [errors, setErrors]           = useState([]);
    const [openModal, setOpenModal]     = useState(false);

    useEffect(() => {
        let Content = [];
        BancosVenezuela.forEach(i => {
            Content.push({
                value: i.codigo, label: `${i.codigo} - ${i.nombre}`
            })
        })

        setBancos(Content);

        SearchCuenta((resp) => {
            if (resp.data.length === 0) setPreload({icon: faTimesCircle, spin: false, text: "No tienes cuentas registradas"})
            setCuentas(resp.data)
        })
    }, []);

    const customStylesSelect = {
        menu: styles => ({ ...styles, color: "#000", fontSize: 14, zIndex: 3 }),
        multiValue: styles => ({ ...styles, backgroundColor: "#ccc" }),
        multiValueLabel: styles => ({ ...styles, color: "#000" }),
        placeholder: styles => ({ ...styles, fontSize: 14, color: "#b4bcc3" }),
    };

    let changeTipo = (tipoSelect) => { setTipoSelect(tipoSelect) }
    let changeTipoCta = (tipoCtaSelect) => { setCtaSelect(tipoCtaSelect) }
    let changeBanco = (bancoSelect) => { setBancoSelect(bancoSelect) }
    let changeNac = (nacSelect) => { setNacSelect(nacSelect) }

    let addCuenta = () => {
        if (botonCta.disabled === true) return null;

        setBotonCta({ icon: faCircleNotch, spin: true, disabled: true, text: "Agregar" })

        let campos = { tipoSelect, tipoCtaSelect, bancoSelect, numCta, nacSelect, tipoDoc, nombre, ci, tlf }

        AddCuenta(campos, (resp) => {
            setBotonCta({ icon: faPlusCircle, spin: false, disabled: false, text: "Agregar" })
            
            switch (true) {
                case (resp[0].cantidad > 0):
                    setErrors (resp[0].errMesss)
                    setOpenModal(true)
                break;
                case (resp[1].status === "error" || resp[1].status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp[1].mess }}></span></small>);
                break;
                default:
                    toast.info(<small><FontAwesomeIcon icon = {faCheckCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp[1].mess }}></span></small>);
                    
                    setCtaSelect({value: "", label: "Selecciona"});
                    setBancoSelect({value: "", label: "Selecciona"});
                    setNacSelect({value: "", label: "Selecciona"});
                    setnumCta("")
                    setTipoDoc("")
                    setNombre("")
                    setCi("")
                    setTlf("")
                    setCuentas(resp[1].data)
                break;
            }
        })
    }

    let cuentasList = () => {
        if (cuentas.length === 0) return (
            <tr>
               <td colSpan = {3} align = "center">
                   <FontAwesomeIcon icon = {preload.icon} spin = {preload.spin} /> {preload.text}
               </td>
           </tr>
        )

        return (
            cuentas.map(option => (
                <DetalleCuenta key = {option.id} data = {option} tipos = {tipos} tiposCta = {tiposCta} nac = {nac} update = {(data) => {
                    if (data.length === 0) setPreload({icon: faTimesCircle, spin: false, text: "No tienes cuentas registradas"})
                    setCuentas(data)
                }}/>
            ))
        )
    }

    let tipoSeleccionado = () => {
        switch(tipoSelect.value){
            case "": return (
                <div align = "center" style = {{paddingTop: 16}}>No has seleccionado ningpun tipo de cuenta</div>
            )
            case "cuenta": return (
                <Card>
                    <CardBody>
                        <Row>
                            <Col md = {3}>
                                <small style = {{color: "#000"}}>Tipo de cuenta</small>
                                <Select
                                    isLoading = {tipoCtaOpt.loading}
                                    isDisabled = {tipoCtaOpt.disabled}
                                    isSearchable = {true} styles = {customStylesSelect}
                                    className = "basic-multi-select"
                                    onChange = {changeTipoCta} options = {tiposCta}
                                    noOptionsMessage = {() => { return "Sin Opciones" }}
                                    loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                                    value = {tipoCtaSelect}
                                />
                            </Col>
                            <Col md = {4} title = {bancoSelect.label}>
                                <small style = {{color: "#000"}}>Banco</small>
                                <Select
                                    isLoading = {bancosOpt.loading}
                                    isDisabled = {bancosOpt.disabled}
                                    isSearchable = {true} styles = {customStylesSelect}
                                    className = "basic-multi-select"
                                    onChange = {changeBanco} options = {bancos}
                                    noOptionsMessage = {() => { return "Sin Opciones" }}
                                    loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                                    value = {bancoSelect}
                                />
                            </Col>
                            <Col md = {5}>
                                <small style = {{color: "#000"}}>Número de cuenta</small>
                                <Input placeholder = "Ingresa los 20 dígitos" type = "number" maxLength = {20} value = {numCta} onChange = {(e) => setnumCta(e.target.value)}/>
                            </Col>
                            <Col md = {2}>
                                <small style = {{color: "#000"}}>Tipo</small>
                                <Select
                                    isLoading = {nacOpt.loading}
                                    isDisabled = {nacOpt.disabled}
                                    isSearchable = {true} styles = {customStylesSelect}
                                    className = "basic-multi-select"
                                    onChange = {changeNac} options = {nac}
                                    noOptionsMessage = {() => { return "Sin Opciones" }}
                                    loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                                    value = {nacSelect}
                                />
                            </Col>
                            <Col md = {3}>
                                <small style = {{color: "#000"}}>Documento</small>
                                <Input placeholder = "Número del documento" type = "number" maxLength = {20} value = {tipoDoc} onChange = {(e) => setTipoDoc(e.target.value)}/>
                            </Col>
                            <Col md = {4}>
                                <small style = {{color: "#000"}}>Titular</small>
                                <Input placeholder = "Nombre y apellido" type = "text" maxLength = {190} value = {nombre} onChange = {(e) => setNombre(e.target.value)}/>
                            </Col>
                            <Col md = {3} style = {{paddingTop: 10}} align = "right">
                                <Button color = "default" style = {{marginTop: 12}} onClick = {addCuenta}><FontAwesomeIcon icon = {botonCta.icon} spin = {botonCta.spin} /> {botonCta.text}</Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            )
            case "pago_movil": return (
                <Card>
                    <CardBody>
                        <Row>
                            <Col md = {4} title = {bancoSelect.label}>
                                <small style = {{color: "#000"}}>Banco</small>
                                <Select
                                    isLoading = {bancosOpt.loading}
                                    isDisabled = {bancosOpt.disabled}
                                    isSearchable = {true} styles = {customStylesSelect}
                                    className = "basic-multi-select"
                                    onChange = {changeBanco} options = {bancos}
                                    noOptionsMessage = {() => { return "Sin Opciones" }}
                                    loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                                    value = {bancoSelect}
                                />
                            </Col>
                            <Col md = {4}>
                                <small style = {{color: "#000"}}>Cédula</small>
                                <Input placeholder = "Número del documento" type = "number" maxLength = {20} value = {ci} onChange = {(e) => setCi(e.target.value)}/>
                            </Col>
                            <Col md = {4}>
                                <small style = {{color: "#000"}}>Teléfono</small>
                                <Input placeholder = "Número de teléfono" type = "number" maxLength = {20} value = {tlf} onChange = {(e) => setTlf(e.target.value)}/>
                            </Col>
                            <Col md = {12} style = {{paddingTop: 10}} align = "right">
                                <Button color = "default" style = {{marginTop: 12}} onClick = {addCuenta}><FontAwesomeIcon icon = {botonCta.icon} spin = {botonCta.spin} /> {botonCta.text}</Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            )
            default: return null;
        }
    }

    return (
        <>
            <NavbarI />
            <main>
                <section className="section section-shaped section-lg">
                    <div className = "d-block d-sm-block d-md-block" align = "right" style = {{paddingRight: 15}}>
                        <small class = "bread-crumb-custom"><Link to = "/dashboard/home"><FontAwesomeIcon icon = {faHome}/></Link> | <FontAwesomeIcon icon = {faCreditCard}/> Mis Cuentas</small>
                    </div>
                    <Row>
                        <Col md = {3} className = "d-none d-sm-none d-md-block">
                            <Sidebar active = "Mis Cuentas"/>
                        </Col>
                        <Col md = {9} className = "custom-p-32">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col md = {6}>
                                            <h5>Mis Cuentas</h5>
                                            <div className = "text-muted" style = {{fontSize: 10, marginTop: -10}}>Completa los campos de con la información de tus cuentas</div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md = {3} className = "mb-3">
                                            <small style = {{color: "#000"}}>Tipo de Registro</small>
                                            <Select
                                                isLoading = {tipoOpt.loading}
                                                isDisabled = {tipoOpt.disabled}
                                                isSearchable = {true} styles = {customStylesSelect}
                                                className = "basic-multi-select"
                                                onChange = {changeTipo} options = {tipos}
                                                noOptionsMessage = {() => { return "Sin Opciones" }}
                                                loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando</> }}
                                                value = {tipoSelect}
                                            />
                                        </Col>
                                        <Col md = {9}>{tipoSeleccionado()}</Col>
                                        <Col md = {12} className = "custom-mt-10">
                                            <div>
                                                <Table className = "mb-0" hover style = {{fontSize: 10 }}>
                                                    <tbody>
                                                        <tr>
                                                            <th width = "15%">Tipo de Registro</th>
                                                            <th width = "70%">Detalles</th>
                                                            <th width = "15%">Acciones</th>
                                                        </tr>
                                                        {cuentasList()}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </section>
            </main>
          <ErrorsModal isOpen = {openModal} close = {() => {setOpenModal(false)}} errores = {errors} />
        </>
    )
}

export default MainCuentas;