import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import Butaca from "./Butaca";
import Mesa from "./Mesa";

const Auditorio = (props) => {
    const [audit, setAudit]         = useState([])
    const [control, setControl]     = useState(false);
    const [tamButaca, setTamButaca] = useState(props.tamButaca);
    const [colorBut, setColorBut]   = useState(props.colorBut);
    const [customStyles, setCustomStyles]   = useState(props.customStyles);
    const [preloader, setPreloader] = useState(props.preloader);
    const [radButaca, setRadButaca] = useState(props.radButaca);
    const [dataSillas, setDataSillas]       = useState([]);
    
    useEffect(() => {
        setAudit(props.data)
        setTamButaca(props.tamButaca)
        setColorBut(props.colorBut)
        setCustomStyles(props.customStyles)
        setPreloader(props.preloader)
        setRadButaca(props.radButaca)
    }, [props]);

    let selectButaca = (data) => {
        let content = audit;
        content.forEach(i => {
            if(i.id === data.id_fila){
                (i.columnas).forEach(col => {
                    if(data === col) {
                        (col.disabledInd === true) ? props.updCapacidad("sum") : props.updCapacidad("res");
                        col.disabledInd = !col.disabledInd;
                    }
                })
            } 
        })

        props.updAudit(content);
        setControl(!control);
    }

    if (audit.length === 0) return preloader

    let numL = -1;

    let addSillas = (data) => {
        let Content = dataSillas

        data.forEach (i => {
            const search = Content.find(option => option.data === i.data);
            if (search === undefined) Content.push(i)
        })

        setDataSillas(Content)
        props.updSillasDisabled(Content)
    }

    let delSillas = (data) => {
        let Content = dataSillas

        const filter = Content.filter(option => option.data !== data)

        setDataSillas(filter)
        props.updSillasDisabled(filter)
    }

    let colums = (col) => {
        switch(props.estiloButaca){
            case "libre": return null;
            case "mesa": return (
                col.map(option => (
                    <Mesa
                        key = {`${option.id_fila}_${option.id}`}
                        confMesas = {props.confMesas}
                        customStyles = {customStyles}
                        data = {option}
                        numL = {numL}
                        onClick = {(data = option) => selectButaca(option)}
                        addSilla = {(data) => addSillas(data)}
                        delSilla = {(data) => delSillas(data)}
                        sillasDisabled = {props.sillasDisabled}
                        disabled = {false}
                    />
                ))
            )
            default: return(
                col.map(option => (
                    <Butaca
                        titleDis = {false}
                        onClick = {(data = option) => selectButaca(option)}
                        control = {control}
                        key = {`${option.id_fila}_${option.id}`}
                        data = {option}
                        numL = {numL}
                        sumNumL = {(num) => {numL = numL + num}}
                        styles = {customStyles}
                        tamButaca = {tamButaca}
                        radButaca = {radButaca}
                        colorButaca = {colorBut}
                        estiloButaca = {props.estiloButaca}
                    />
                ))
            )
        }
    }

    let fontSize = 10;
    let nums = [];
    let numsD = [];

    let stylesSpan = {
        fontSize: fontSize, height: tamButaca, minWidth: tamButaca, maxWidth: tamButaca
    }

    nums.push({id: -1, content: <span style = {stylesSpan} className = "butaca-num"></span>});
    nums.push({id: 0, content: <span style = {stylesSpan} className = "butaca-num"></span>});
    numsD.push({id: -1, content: <span style = {stylesSpan} className = "butaca-num"></span>});
    numsD.push({id: 0, content: <span style = {stylesSpan} className = "butaca-num"></span>});

    for (let i = 1, n = 1; i <= audit[0].columnas.length; i++){
        if (i > 99) fontSize = 8;
            numsD.push({id: i, content: <span style = {stylesSpan} className = "butaca-num">{i}</span>});

        /* Buscar el numero en las columnas a ver si estan disabled */
        let idCol = audit[0].columnas[i - 1].id;
        if(!isNaN(Number(idCol)) === true){
            nums.push({id: i, content: <span style = {stylesSpan} className = "butaca-num">{n}</span>});
            n++;
        }else{
            nums.push({id: i, content: <span style = {stylesSpan} className = "butaca-num"><FontAwesomeIcon icon = {faCircle} style = {{fontSize: 7, color: "#ccc"}}/></span>});
        }
    }

    let filName = (id, letra) => {
        if(!isNaN(Number(id)) === true){
            return <span style = {{fontSize: 10,  height: tamButaca, minWidth: tamButaca, maxWidth: tamButaca}} className = "butaca-num">{letra}</span>
        }else{
            return <span style = {{fontSize: 7, color: "#ccc", height: tamButaca, minWidth: tamButaca, maxWidth: tamButaca}} className = "butaca-num"><FontAwesomeIcon icon = {faCircle}/></span>
        }
    }

    let num = 0;


    let leyendaSuperior = () => {
        if (props.estiloButaca === "mesa") return null;

        return (
            <>
                <span style = {{color: "#fff", backgroundColor: "#ccc", fontWeight: "bold"}}>
                    {numsD.map(option => ( <React.Fragment key = {option.id}>{option.content}</React.Fragment> ))}
                </span>
                {nums.map(option => ( <React.Fragment key = {option.id}>{option.content}</React.Fragment> ))} 
            </>
        )
    }

    return(
        <>
            {leyendaSuperior()}
            {audit.map(option => {
                num++;

                let styles = {};
                let butAdicional = "";
                if (num % 2 === 0 && props.alternar === true){
                    styles = {marginLeft: 20};
                    butAdicional = <span className = "butaca-num" style = {{height: tamButaca, minWidth: 10, backgroundColor: "#fff"}}></span>
                }

                return (
                    <div key = {option.id}/*  style = {styles} */>
                        <span style = {{fontSize: 10, backgroundColor: "#ccc", fontWeight: "bold", color: "#fff", height: tamButaca, minWidth: tamButaca, maxWidth: tamButaca}} className = "butaca-num">{option.letra}</span>
                        {filName(option.id, option.letraAlt)}
                        {butAdicional}
                        {colums(option.columnas)}
                    </div>
                )
            })}
        </>   
    )
}

export default Auditorio;