import React, {useEffect, useState, Fragment} from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Dashboard from "./Dashboard";

import {ChkSec} from '../../functions/UserFunctions';
import MainPerfil from "../Perfil/MainPerfil";
import MainSitios from "../MisSitios/MainSitios";
import MainEventos from "views/Eventos/MainEventos";
import AddEvento from "views/Eventos/AddEvento";


import Index from "views/Index.js";
import Login from "views/site/Login.js";
import Register from "views/site/Register.js";
import Evento from "components/Eventos/Evento";
import PayEvento from 'components/Eventos/PayEvent';
import ResetPass from '../site/ResetPass';
import MainRetiros from "views/MisRetiros/MainRetiros";
import MainVentas from "views/MisVentas/MainVentas";
import MainSliders from "views/Sliders/MainSliders";
import MainCuentas from "views/MisCuentas/MainCuentas";
import MainCompras from "views/MisCompras/MainCompras";
import MainVerificacionTaquilla from "views/Verificacion/MainVerificacionTaquilla";
import MainHistFunciones from "views/Historico/MainHistFunciones";
import MainRoles from "views/Roles/MainRoles";
import MainAtencionUser from "views/Perfil/AtencionUsuario";
import ResumenVentas from "views/MisVentas/ResumenVentas";


const MainDashboard = (props) => {
    const [ContentData, setContentData] = useState(JSON.parse(sessionStorage.getItem("ContentData")))
    useEffect(() => {
        setContentData(JSON.parse(sessionStorage.getItem("ContentData")));
    }, []);

    let check = () => {
        if (ContentData === null) return (<Redirect to = "/"/>);

        if (ContentData !== null && ContentData.U_ID !== undefined && ContentData.E_ID !== undefined) {
            ChkSec (ContentData.U_ID, ContentData.E_ID, (resp) => {
                if (resp.status === "error"){
                    sessionStorage.removeItem("ContentData")
                    sessionStorage.removeItem("ContentDataEvent")
                    sessionStorage.removeItem("ContentDataButacas")
                    sessionStorage.removeItem("ContentDataPrecioZona")
                    return (<Redirect to = "/"/>)
                }
            })
        }
    }

    switch(true){
        case (ContentData === null): return (<Redirect to = "/"/>);
        case (ContentData.U_TYPE === 1):
            check();
            return (
            <Fragment>
                <BrowserRouter>
                    <Switch>
                        <Route path="/" exact render={props => <Index {...props} />} />
                        <Route path="/login" exact render={props => <Login {...props} />} />
                        <Route path="/event/:id_event" exact render={props => <Evento {...props} />} />
                        <Route path="/event/comprar/:id_event" exact render={props => <PayEvento {...props} />} />
                        <Route path="/register" exact render={props => <Register {...props} />} />
                        <Route path="/reset_password" exact render={props => <ResetPass {...props} />} />

                        <Route path = "/dashboard/home" exact render = {props => <Dashboard {...props} />} />
                        <Route path = "/dashboard/perfil" exact render = {props => <MainPerfil {...props} />} />
                        <Route path = "/dashboard/recintos" exact render = {props => <MainSitios {...props} />} />
                        <Route path = "/dashboard/eventos" exact render = {props => <MainEventos {...props} />} />
                        <Route path = "/dashboard/eventos/add_evento" exact render = {props => <AddEvento {...props} />} />
                        <Route path = "/dashboard/mis_retiros" exact render = {props => <MainRetiros {...props} />} />
                        <Route path = "/dashboard/mis_ventas" exact render = {props => <MainVentas {...props} />} />
                        <Route path = "/dashboard/resumen_ventas" exact render = {props => <ResumenVentas {...props} />} />
                        <Route path = "/dashboard/mis_sliders" exact render = {props => <MainSliders {...props} />} />
                        <Route path = "/dashboard/administrar_usuarios" exact render = {props => <MainRoles {...props} />} />
                        
                        <Redirect to="/dashboard/home" />
                    </Switch>
                </BrowserRouter>
            </Fragment>
        )
        case (ContentData.U_TYPE === 2):
            check();
            return (
            <Fragment>
                <BrowserRouter>
                    <Switch>
                        <Route path="/" exact render={props => <Index {...props} />} />
                        <Route path="/login" exact render={props => <Login {...props} />} />
                        <Route path="/event/:id_event" exact render={props => <Evento {...props} />} />
                        <Route path="/event/comprar/:id_event" exact render={props => <PayEvento {...props} />} />
                        <Route path="/register" exact render={props => <Register {...props} />} />
                        <Route path="/reset_password" exact render={props => <ResetPass {...props} />} />

                        <Route path = "/dashboard/home" exact render = {props => <Dashboard {...props} />} />
                        <Route path = "/dashboard/perfil" exact render = {props => <MainPerfil {...props} />} />
                        <Route path = "/dashboard/eventos" exact render = {props => <MainEventos {...props} />} />
                        <Route path = "/dashboard/eventos/add_evento" exact render = {props => <AddEvento {...props} />} />
                        <Route path = "/dashboard/mis_retiros" exact render = {props => <MainRetiros {...props} />} />
                        <Route path = "/dashboard/mis_ventas" exact render = {props => <MainVentas {...props} />} />
                        <Route path = "/dashboard/resumen_ventas" exact render = {props => <ResumenVentas {...props} />} />
                        <Route path = "/dashboard/mis_cuentas" exact render = {props => <MainCuentas {...props} />} />
                        <Route path = "/dashboard/historico_funciones" exact render = {props => <MainHistFunciones {...props} />} />
                        
                        <Redirect to="/dashboard/home" />
                    </Switch>
                </BrowserRouter>
            </Fragment>
        )
        case (ContentData.U_TYPE === 3):
            check();
            return (
            <Fragment>
                <BrowserRouter>
                    <Switch>
                        <Route path="/" exact render={props => <Index {...props} />} />
                        <Route path="/login" exact render={props => <Login {...props} />} />
                        <Route path="/event/:id_event" exact render={props => <Evento {...props} />} />
                        <Route path="/event/comprar/:id_event" exact render={props => <PayEvento {...props} />} />
                        <Route path="/register" exact render={props => <Register {...props} />} />
                        <Route path="/reset_password" exact render={props => <ResetPass {...props} />} />

                        <Route path = "/dashboard/home" exact render = {props => <Dashboard {...props} />} />
                        <Route path = "/dashboard/perfil" exact render = {props => <MainPerfil {...props} />} />
                        <Route path = "/dashboard/mis_compras" exact render = {props => <MainCompras {...props} />} />
                        <Route path="/dashboard/atencion_usuario" exact render={props => <MainAtencionUser {...props} />} />

                        <Redirect to="/dashboard/home" />
                    </Switch>
                </BrowserRouter>
            </Fragment>
        )
        case (ContentData.U_TYPE === 4):
            check();
            return (
            <Fragment>
                <BrowserRouter>
                    <Switch>
                        <Route path="/" exact render={props => <Index {...props} />} />
                        <Route path="/login" exact render={props => <Login {...props} />} />
                        <Route path="/event/:id_event" exact render={props => <Evento {...props} />} />
                        <Route path="/event/comprar/:id_event" exact render={props => <PayEvento {...props} />} />
                        <Route path="/register" exact render={props => <Register {...props} />} />
                        <Route path="/reset_password" exact render={props => <ResetPass {...props} />} />

                        <Route path = "/dashboard/home" exact render = {props => <Dashboard {...props} />} />
                        <Route path = "/dashboard/perfil" exact render = {props => <MainPerfil {...props} />} />
                        
                        <Redirect to="/dashboard/home" />
                    </Switch>
                </BrowserRouter>
            </Fragment>
        )
        case (ContentData.U_TYPE === 5):
            check();
            return (
            <Fragment>
                <BrowserRouter>
                    <Switch>
                        <Route path="/" exact render={props => <Index {...props} />} />
                        <Route path="/login" exact render={props => <Login {...props} />} />
                        <Route path="/event/:id_event" exact render={props => <Evento {...props} />} />
                        <Route path="/event/comprar/:id_event" exact render={props => <PayEvento {...props} />} />
                        <Route path="/register" exact render={props => <Register {...props} />} />
                        <Route path="/reset_password" exact render={props => <ResetPass {...props} />} />

                        <Route path = "/dashboard/home" exact render = {props => <Dashboard {...props} />} />
                        <Route path = "/dashboard/perfil" exact render = {props => <MainPerfil {...props} />} />
                        <Route path = "/dashboard/verificacion_taquilla" exact render = {props => <MainVerificacionTaquilla {...props} />} />
                        
                        <Redirect to="/dashboard/home" />
                    </Switch>
                </BrowserRouter>
            </Fragment>
        )
        default: return null;
    }
}

export default MainDashboard;
