import React from "react";

// reactstrap components

// core components
import NavbarI from "components/Navbars/NavbarI.js";
import CardsFooter from "components/Footers/CardsFooter.js";

// index page sections
import BannerI from "./IndexSections/BannerI.js";
import EventosI from "./IndexSections/EventosI.js";

class Index extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <NavbarI />
        <main ref="main">
          <BannerI />
          <EventosI />
          {/* <Modals /> */}
        </main>
        <footer display = {{position: 'fixed', bottom: 0}}>
          <CardsFooter />
        </footer>
      </>
    );
  }
}

export default Index;
