export const requerido = (campo, errores, mensaje, respuesta) => {
    let err = errores.cantidad
    let mess = errores.errMesss

    let contenido = {
        cantidad: err,
        errMesss: mess
    };

    if (campo === null || campo.length === 0 || campo === ""){
        mess.push(mensaje)
        contenido = {
            cantidad: err + 1,
            errMesss: mess
        }
    }

    respuesta (contenido)
}

export const numero = (campo, errores, mensaje, respuesta) => {
    let err = errores.cantidad
    let mess = errores.errMesss

    let contenido = {
        cantidad: err,
        errMesss: mess
    };

    if (!isNaN(Number(campo)) === false && campo === ""){
        mess.push(mensaje)
        contenido = {
            cantidad: err + 1,
            errMesss: mess
        }
    }

    respuesta (contenido)
}

export const sinEspacios = (campo, errores, mensaje, respuesta) => {
    let err = errores.cantidad
    let mess = errores.errMesss

    let contenido = {
        cantidad: err,
        errMesss: mess
    };
    
    if (campo.indexOf(" ") >= 0 && campo !== ""){
        mess.push(mensaje)
        contenido = {
            cantidad: err + 1,
            errMesss: mess
        }
    }

    respuesta (contenido)
}

export const email = (campo, errores, mensaje, respuesta) => {
    let err = errores.cantidad
    let mess = errores.errMesss

    let expReg = /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i;

    let contenido = {
        cantidad: err,
        errMesss: mess
    };

    if (expReg.test(campo) === false && campo !== ""){
        mess.push(mensaje)
        contenido = {
            cantidad: err + 1,
            errMesss: mess
        }
    }

    respuesta (contenido)
}

export const mensObligatorio = (campo) => {
    return `El campo <strong class = "text-danger">${campo}</strong> es obligatorio`
}

export const selectObligatorio = (campo1, campo2) => {
    return `Debes seleccionar ${campo1} <strong class = "text-danger">${campo2}</strong>`
}

export const formatoIncorrecto = (campo) => {
    return `El campo <strong class = "text-danger">${campo}</strong> tiene un formato incorrecto`
}

export const numericoIncorrecto = (campo) => {
    return `El campo <strong class = "text-danger">${campo}</strong> debe contener solo números`
}

export const mensSinEspacios = (campo) => {
    return `El campo <strong class = "text-danger">${campo}</strong> no debe contener espacios`
}