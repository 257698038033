import { Post } from "./Generales";
import { Url } from "./Url";

export const PayWithPagoMovilTaq = (idEvento,idFuncion, metodos, fecha, hora, factura, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        moneda: metodos.moneda,
        metodos: JSON.stringify(metodos),
        idEvento: idEvento,
        idFuncion:idFuncion,
        fecha: fecha,
        hora: hora,
        idFactura: factura,
        // urlReturn: urlReturn
    }


    Post(`${Url}PayWithPagoMovilTaq`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const PayWithCreditCardTaq = (idEvento,idFuncion, metodos, fecha, hora, factura, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        moneda: metodos.moneda,
        metodos: JSON.stringify(metodos),
        idEvento: idEvento,
        idFuncion:idFuncion,
        fecha: fecha,
        hora: hora,
        idFactura: factura,

    }

    Post(`${Url}PayWithTDCTaq`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const PayGenericTaq = (idEvento,idFuncion, metodos, fecha, hora, factura, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        moneda: metodos.moneda,
        metodos: JSON.stringify(metodos),
        idEvento: idEvento,
        idFuncion:idFuncion,
        fecha: fecha,
        hora: hora,
        idFactura: factura,
        // urlReturn: urlReturn
    }


    Post(`${Url}PayGenericTaq`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const searchHalfInvoice = (id_evento, callback) =>{
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        idEvento:id_evento
    }

    Post(`${Url}searchHalfInvoice`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const SendCode = (DataCard, callback)=>{
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        card_number: DataCard.cardNumber,
        ci: DataCard.ci,
    }

    Post(`${Url}ReciveCode`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const PayWithTDD = (idEvento,idFuncion, metodos, fecha, hora, factura, callback)=>{

    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        moneda: metodos.moneda,
        metodos: JSON.stringify(metodos),
        idEvento: idEvento,
        idFuncion:idFuncion,
        fecha: fecha,
        hora: hora,
        idFactura: factura,

    }

    Post(`${Url}PayWithTDD`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}