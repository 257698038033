import { Url } from "./Url";
import { Post, PostPdf } from "./Generales";


export const showUsuario = (page, callback) =>{
    let data = JSON.parse(sessionStorage.getItem("ContentData"));
    let pages = page;

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID
    }

    Post(`${Url}administrarUsuarios?page=${pages}`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const ShowRoles = (callback) =>{
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID
    }

    Post(`${Url}administrarRoles`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });

}

export const ShowPermisos = (callback) =>{
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID
    }

    Post(`${Url}administrarPermisos`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const ChangeRole = (content, callback) =>{
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        user: content.user,
        role: content.role
    }

    Post(`${Url}cambiarRole`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const changeStatus = (content, callback) =>{
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        status: content.statusU,
        user: content.userId    
    }

    Post(`${Url}changeStatus`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}

export const savePermission = (content, callback) => {
    let data = JSON.parse(sessionStorage.getItem("ContentData"));

    let Content = {
        eid: data.E_ID,
        uid: data.U_ID,
        user: content.user,
        add: content.add,
        del: content.del,
        upd: content.upd,
    }

    Post(`${Url}changePermission`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)
    });
}