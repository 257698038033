import React, {useEffect, useState} from "react";
import { Button, Input, InputGroupAddon, InputGroupText, InputGroup, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faTimes, faSave, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Select from 'react-select';

import { AddNewSitio, EditSitio } from "functions/SitiosFunction";
import { ConsultPaises, ConsultEstados, ConsultMunicipios, ConsultParroquias } from "functions/UserFunctions";

import ErroresList from "components/Errores/ErroresList";
import CustomMap from "components/Maps/CustomMap";


const AddSitio = (props) => {
    const [nombre, setNombre]       = useState("");
    const [direccion, setDireccion] = useState("");
    const [latitud, setLatitud]     = useState("");
    const [longitud, setLongitud]   = useState("");
    const [maps, setMaps]           = useState("");
    const [mapOk, setMapsOk]        = useState(false);
    const [min, setMin]             = useState("");
    const [max, setMax]             = useState("");
    const [botonAdd, setBotonAdd]   = useState({ icon: faSave, text: "Agregar", spin: false, disabled: false })
    const [botonEdit, setBotonEdit] = useState({ icon: faSave, text: "Modificar", spin: false, disabled: false })
    const [errMess, setErrMess]         = useState("")
    const [visibleErr, setVisibleErr]   = useState("none")
    const [paisSelect, setPaisSelect]   = useState({value: "", label: "País"});
    const [paisOpt, setPaisOpt]         = useState({ disabled: false, loading: true })
    const [paises, setPaises]           = useState([]);
    const [estadoSelect, setEstadoSelect] = useState({value: "", label: "Estado"});
    const [estadoOpt, setEstadoOpt]       = useState({ disabled: true, loading: false })
    const [estados, setEstados]           = useState([]);
    const [municipioSelect, setMunicipioSelect] = useState({value: "", label: "Municipio"});
    const [municipioOpt, setMunicipioOpt]       = useState({ disabled: true, loading: false })
    const [municipios, setMunicipios]           = useState([]);
    const [parroquiaSelect, setParroquiaSelect] = useState({value: "", label: "Parroquia"});
    const [parroquiaOpt, setParroquiaOpt]       = useState({ disabled: true, loading: false })
    const [parroquias, setParroquias]           = useState([]);
    
    useEffect(() => {
        if (props.botones === "edit"){
            setNombre(props.data.name);
            setDireccion(props.data.address);
            setMaps(props.data.map);
            setMin(props.data.min_cap);
            setMax(props.data.max_cap);
            
            let search = (props.data.map).includes('www.google.com/maps')
            if (search === true) setMapsOk(true)

            let ContentPais = [];
            let ContentEstados = [];
            let ContentMunicipios = [];
            let ContentParroquias = [];

            setEstadoOpt({ disabled: false, loading: true });
            setMunicipioOpt({ disabled: false, loading: true });
            setParroquiaOpt({ disabled: false, loading: true });

            ConsultPaises((resp) => {
                (resp.data).forEach(i => {
                    ContentPais.push({
                    value: i.id, label: i.pais_name
                    })
                })
    
                setPaises(ContentPais)

                let search = ContentPais.find(option => option.value === props.data.id_pais);
                if(search !== undefined) setTimeout(() => setPaisSelect(search), 100);

                setPaisOpt({ disabled: true, loading: false })
            }) 

            ConsultEstados(props.data.id_pais, (resp) => {
                (resp.data).forEach(i => {
                    ContentEstados.push({
                    value: i.id, label: i.estado_name
                    })
                })
                
                setEstados(ContentEstados)
                
                let search = ContentEstados.find(option => option.value === props.data.id_estado);
                if(search !== undefined) setTimeout(() => setEstadoSelect(search), 100);
                
                setEstadoOpt({ disabled: true, loading: false })
            })

            ConsultMunicipios(props.data.id_estado, (resp) => {
                (resp.data).forEach(i => {
                    ContentMunicipios.push({
                    value: i.id, label: i.municipio_name
                    })
                })
                
                setMunicipios(ContentMunicipios)
                
                let search = ContentMunicipios.find(option => option.value === props.data.id_municipio);
                if(search !== undefined) setTimeout(() => setMunicipioSelect(search), 100);

                setMunicipioOpt({ disabled: true, loading: false })
            })

            ConsultParroquias(props.data.id_municipio, (resp) => {
                (resp.data).forEach(i => {
                    ContentParroquias.push({
                    value: i.id, label: i.parroquia_name
                    })
                })
            
                setParroquias(ContentParroquias)

                let search = ContentParroquias.find(option => option.value === props.data.id_parroquia);
                if(search !== undefined) setTimeout(() => setParroquiaSelect(search), 100);

                setParroquiaOpt({ disabled: true, loading: false });
            })
        }else{
            let Content = [];
            ConsultPaises((resp) => {
                (resp.data).forEach(i => {
                    Content.push({
                    value: i.id, label: i.pais_name
                    })
                })
    
                setPaises(Content)
                setPaisOpt({ disabled: true, loading: false })
            })   
        }
    }, [props]);

    let changePais = (paisSelect) => {
        setPaisSelect(paisSelect);
        setEstadoSelect({value: "", label: "Estado"});
        setMunicipioSelect({value: "", label: "Municipio"});
        setParroquiaSelect({value: "", label: "Parroquia"});
    
        let Content = [];
    
        setEstadoOpt({ disabled: false, loading: true })
        ConsultEstados(paisSelect.value, (resp) => {
            (resp.data).forEach(i => {
                Content.push({
                value: i.id, label: i.estado_name
                })
            })
            
            setEstados(Content)
            setEstadoOpt({ disabled: true, loading: false })
        })
    }
    
    let changeEstado = (estadoSelect) => {
        setEstadoSelect(estadoSelect);
        setMunicipioSelect({value: "", label: "Municipio"});
        setParroquiaSelect({value: "", label: "Parroquia"});
    
        let Content = [];
    
        setMunicipioOpt({ disabled: false, loading: true })
        ConsultMunicipios(estadoSelect.value, (resp) => {
            (resp.data).forEach(i => {
                Content.push({
                value: i.id, label: i.municipio_name
                })
            })
            
            setMunicipios(Content)
            setMunicipioOpt({ disabled: true, loading: false })
        })
    }
    
    let changeMunicipio = (municipioSelect) => {
            setMunicipioSelect(municipioSelect)
            setParroquiaSelect({value: "", label: "Parroquia"});
        
            let Content = [];
            setParroquiaOpt({ disabled: false, loading: true });
            ConsultParroquias(municipioSelect.value, (resp) => {
                (resp.data).forEach(i => {
                    Content.push({
                    value: i.id, label: i.parroquia_name
                    })
                })
            
                setParroquias(Content)
                setParroquiaOpt({ disabled: true, loading: false });
            })
    }
    
    let changeParroquia = (parroquiaSelect) => {
            setParroquiaSelect(parroquiaSelect)
    }

    let addSite = () => {
        if (botonAdd.disabled === true) return null;

        setBotonAdd({ icon: faCircleNotch, text: "Espera", spin: true, disabled: true })
        let campos = { nombre, direccion, latitud, longitud, min, max, maps, paisSelect, estadoSelect, municipioSelect, parroquiaSelect }

        AddNewSitio (campos, (resp) => {
            setBotonAdd({ icon: faSave, text: "Agregar", spin: false, disabled: false })
            switch (true) {
                case (resp[0].cantidad > 0):
                    setErrMess(resp[0])
                    setVisibleErr("block")
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> No se guardó la información</small>);
                break;
                case (resp[1].status === "error" || resp[1].status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp[1].mess }}></span></small>);
                break;
                default:
                    props.cerrar();
                    props.addOk(true, "Recinto Agregado", resp[1].mess, resp[1].data)
                break;
            }
        })
    }

    let modSite = () => {
        if (botonEdit.disabled === true) return null;
        setBotonEdit({ icon: faCircleNotch, text: "Espera", spin: true, disabled: true })
        setBotonAdd(state => ({...state,  disabled: true}))

        let campos = { nombre, direccion, latitud, longitud, min, max, maps, paisSelect, estadoSelect, municipioSelect, parroquiaSelect }

        EditSitio (props.data.id, campos, (resp) => {
            setBotonEdit({ icon: faSave, text: "Modificar", spin: false, disabled: false })
            setBotonAdd(state => ({...state,  disabled: false}))

            switch (true) {
                case (resp[0].cantidad > 0):
                    setErrMess(resp[0])
                    setVisibleErr("block")
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> No se guardó la información</small>);
                break;
                case (resp[1].status === "error" || resp[1].status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp[1].mess }}></span></small>);
                break;
                default:
                    props.cerrar();
                    props.modOk(true, "Recinto Modificado", resp[1].mess, campos)
                break;
            }
        })
    }

    let splitMap = (ruta) => {
        setMaps (ruta)
        let src =  ruta.split('"');
        if (src[1] !== undefined) {
            let search = src[1].includes('www.google.com/maps')
            if (search === true){
                setMaps(src[1])
                setMapsOk(true)
            }else{
                setMapsOk(false)
            }
        }
    }

    let button = () => {
        switch(props.botones){
            case "add":
                return (
                    <Button color = "default" type = "button" disabled = {botonAdd.disabled} onClick = {addSite}>
                        <FontAwesomeIcon spin = {botonAdd.spin} icon = {botonAdd.icon}/> <small>{botonAdd.text}</small>
                    </Button>
                )
            case "edit":
                return(
                    <Button color = "warning" type = "button" disabled = {botonEdit.disabled} onClick = {modSite}>
                        <FontAwesomeIcon spin = {botonEdit.spin} icon = {botonEdit.icon}/> <small>{botonEdit.text}</small>
                    </Button>
                )
            default: return null
        }
    }

    const customStylesSelect = {
        menu: styles => ({ ...styles, color: "#000", fontSize: 14, zIndex: 3 }),
        multiValue: styles => ({ ...styles, backgroundColor: "#ccc" }),
        multiValueLabel: styles => ({ ...styles, color: "#000" }),
        placeholder: styles => ({ ...styles, fontSize: 14, color: "#b4bcc3" }),
    };

    return (
        <Row>
            <Col md = {6}>
                <Row>
                    <Col md = {12}>
                        <InputGroup className = "input-group-alternative">
                            <InputGroupAddon addonType = "prepend">
                                <InputGroupText>
                                    <i className = "fa fa-bank"/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder = "* Nombre del recinto" type = "text" maxLength = {190} value = {nombre} onChange = {(e) => setNombre(e.target.value)}/>
                        </InputGroup>
                    </Col>
                    <Col md = {12} className = "custom-mt-10">
                        <textarea placeholder = "* Dirección" className = "form-control" value = {direccion} onChange = {(e) => setDireccion(e.target.value)}></textarea>
                    </Col>
                    <Col md = {6} className = "custom-mt-10">
                        <small className = "text-primary">País</small>
                        <Select
                            isLoading = {paisOpt.loading}
                            isSearchable = {true} styles = {customStylesSelect}
                            className = "basic-multi-select"
                            onChange = {changePais} options = {paises}
                            noOptionsMessage = {() => { return "Sin Opciones" }}
                            loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando Países</> }}
                            value = {paisSelect}
                            placeholder = "Pais"
                        />
                    </Col>
                    <Col md = {6} className = "custom-mt-10">
                        <small className = "text-primary">Estado</small>
                        <Select
                            isLoading = {estadoOpt.loading}
                            isSearchable = {true} styles = {customStylesSelect}
                            className = "basic-multi-select"
                            onChange = {changeEstado} options = {estados}
                            noOptionsMessage = {() => { return "Sin Opciones" }}
                            loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando estados</> }}
                            value = {estadoSelect}
                            placeholder = "Estado"
                        />
                    </Col>
                    <Col md = {6} className = "custom-mt-10">
                        <small className = "text-primary">Municipio</small>
                        <Select
                            isLoading = {municipioOpt.loading}
                            isSearchable = {true} styles = {customStylesSelect}
                            className = "basic-multi-select"
                            onChange = {changeMunicipio} options = {municipios}
                            noOptionsMessage = {() => { return "Sin Opciones" }}
                            loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando municipios</> }}
                            value = {municipioSelect}
                            placeholder = "Municipio"
                        />
                    </Col>
                    <Col md = {6} className = "custom-mt-10 mb-3">
                        <small className = "text-primary">Parroquia</small>
                        <Select
                            isLoading = {parroquiaOpt.loading}
                            isSearchable = {true} styles = {customStylesSelect}
                            className = "basic-multi-select"
                            onChange = {changeParroquia} options = {parroquias}
                            noOptionsMessage = {() => { return "Sin Opciones" }}
                            loadingMessage = {() => { return <><FontAwesomeIcon icon = {faCircleNotch} spin = {true}/> Cargando parroquias</> }}
                            value = {parroquiaSelect}
                            placeholder = "Parroquia"
                        />
                    </Col>
                    {/* <Col md = {12} className = "custom-mt-10">
                        <small className = "text-primary">Coordenadas</small>
                    </Col>
                    <Col md = {6}>
                        <InputGroup className = "input-group-alternative">
                            <InputGroupAddon addonType = "prepend">
                                <InputGroupText>
                                    <i className = "fa fa-map-marker"/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder = "Latitud" type = "number" maxLength = {190} value = {latitud} onChange = {(e) => setLatitud(e.target.value)}/>
                        </InputGroup>
                    </Col>
                    <Col md = {6}>
                        <InputGroup className = "input-group-alternative">
                            <InputGroupAddon addonType = "prepend">
                                <InputGroupText>
                                    <i className = "fa fa-map-marker"/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder = "Longitud" type = "number" maxLength = {190} value = {longitud} onChange = {(e) => setLongitud(e.target.value)}/>
                        </InputGroup>
                    </Col> */}
                </Row>
            </Col>
            <Col md = {6} align = "center">
                <CustomMap src = {maps} map = {mapOk}/>
            </Col>
            <Col md = {6}>
                <Row>
                    <Col md = {12} className = "custom-mt-10">
                        <small className = "text-primary">Google Maps</small>
                    </Col>
                    <Col md = {12}>
                        <textarea placeholder = "Ruta de Google Maps" className = "form-control" value = {maps} onChange = {(e) => splitMap(e.target.value)}></textarea>

                        {/* <InputGroup className = "input-group-alternative">
                            <InputGroupAddon addonType = "prepend">
                                <InputGroupText>
                                    <i className = "fa fa-map-marker"/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder = "Ruta de Google Maps" type = "text" maxLength = {600} value = {maps} onChange = {(e) => splitMap(e.target.value)}/>
                        </InputGroup> */}
                    </Col>
                </Row>
            </Col>
            <Col md = {6}>
                <Row>
                    <Col md = {12} className = "custom-mt-10">
                        <small className = "text-primary">Capacidad</small>
                    </Col>
                    <Col md = {6} className = "mb-3">
                        <InputGroup className = "input-group-alternative">
                            <InputGroupAddon addonType = "prepend">
                                <InputGroupText>
                                    <i className = "fa fa-group"/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder = "* Mínima" type = "number" maxLength = {190} value = {min} onChange = {(e) => setMin(e.target.value)}/>
                        </InputGroup>
                    </Col>
                    <Col md = {6} className = "mb-3">
                        <InputGroup className = "input-group-alternative">
                            <InputGroupAddon addonType = "prepend">
                                <InputGroupText>
                                    <i className = "fa fa-group"/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder = "* Máxima" type = "number" maxLength = {190} value = {max} onChange = {(e) => setMax(e.target.value)}/>
                        </InputGroup>
                    </Col>
                </Row>
            </Col>
            <Col md = {7} className = "custom-mt-10">
                <ErroresList errMess = {errMess} visibleErr = {visibleErr} name = "popErr"/>
                <div className = "text-danger"><small>* Campos obligatorios</small></div>
            </Col>
            <Col md = {5} className = "custom-mt-10" align = "right">
                {button()}
                <button className = "btn btn-outline-warning" type = "button" disabled = {botonAdd.disabled} onClick = {() => {
                    if (botonAdd.disabled === true) return null;
                    props.cerrar()
                }}>
                    <FontAwesomeIcon icon = {faTimes}/><small>Cancelar</small>
                </button>
            </Col>
        </Row>
    )
}

export default AddSitio;