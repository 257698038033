import React, {useEffect, useState} from "react";
import ButacaCustom from "components/Butacas/ButacaCustom";

const ButacaDist = (props) => {
    const [customStyles, setCustomtyles] = useState({
        backgroundColor: props.styles.backgroundColor,
        border: props.styles.border,
        height: props.tamButaca,
        minWidth: props.tamButaca,
        maxWidth: props.tamButaca,
        borderRadius: `${props.radButaca}px ${props.radButaca}px`
    })

    const [customStylesDis, setCustomtylesDis] = useState({
        border: props.styles.border,
        height: props.tamButaca,
        minWidth: props.tamButaca,
        maxWidth: props.tamButaca,
        borderRadius: `${props.radButaca}px ${props.radButaca}px`
    })

    useEffect(() => {
        if (isNaN(Number(props.data.id)) === true){
            setCustomtyles(state => ({...state, backgroundColor: "#ffffff"}))
        }else{
            let valor = props.styles.backgroundColor
            setCustomtyles(state => ({...state, backgroundColor: valor}))
        }
        
        setCustomtyles(state => ({...state,
            height: props.tamButaca,
            minWidth: props.tamButaca,
            maxWidth: props.tamButaca,
            borderRadius: `${props.radButaca}px ${props.radButaca}px`
        }))

        setCustomtylesDis(state => ({...state,
            height: props.tamButaca,
            minWidth: props.tamButaca,
            maxWidth: props.tamButaca,
            borderRadius: `${props.radButaca}px ${props.radButaca}px`
        }))
    }, [props]);

    switch (true){
        case(!isNaN(Number(props.data.id)) === true && props.data.disabledInd === false):
            switch(props.estiloButaca){
                case "default": return (
                    <span className = "butaca-default" onClick = {() => {props.onClick()}} style = {customStyles} title = {`Asiento ${props.data.letraAlt}${props.data.id}`}></span>
                )
                default: return (
                    <ButacaCustom clsName = "butaca-default" tipo = {props.estiloButaca} fontSize = {props.tamButaca} color = {props.styles.backgroundColor} title = {`Asiento ${props.data.letraAlt}${props.data.id}`} onClickFn1 = {() => props.onClick()}/>
                );
            }
        case(!isNaN(Number(props.data.id)) === false):
            return (
                <span className = "butaca-disabled" style = {customStyles}></span>
            )
        case(props.data.disabledInd === true):
            let title = `La butaca ${props.data.letraAlt}${props.data.id} está desactivada`;
            if (props.titleDis === true) title = "";
            return (
                <span className = "butaca-disabled-ind2"  style = {customStylesDis} title = {title}></span> 
            )
        default: return null;
    }
}

export default ButacaDist;