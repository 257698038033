import { requerido, email, numero, sinEspacios, mensObligatorio, formatoIncorrecto, numericoIncorrecto,  mensSinEspacios } from "./Validaciones";
import { Post } from "./Generales";
import { Url } from "./Url";

export const AddUser = (requeridos, campos, callback) => {

    let errores = {
        cantidad: 0,
        errMesss: []
    };
    let date = new Date(requeridos.bDate);
    let dd = date.getDate();
    let mm = date.getMonth()+1;
    let yyyy = date.getFullYear();
    let espacios = requeridos.name.split(" ").length - 1;

    // Esto es para que no se pueda poner mas de un espacio en el apellido
    let espaciosApellido = campos.lastName.split(" ").length - 1;


    /* Validaciones */
    requerido   (requeridos.ciNum, errores,  mensObligatorio("Cédula de Identidad"), (resp) => { errores = resp })
    requerido   (date, errores,             mensObligatorio("Fecha de Nacimiento"), (resp) => { errores = resp })
    requerido   (requeridos.name, errores,  mensObligatorio("Nombre"), (resp) => { errores = resp })
    requerido   (requeridos.age, errores,   mensObligatorio("Edad"), (resp) => { errores = resp })
    requerido   (requeridos.phoneNumber, errores, mensObligatorio("Teléfono"), (resp) => { errores = resp })
    requerido   (requeridos.email, errores, mensObligatorio("Email"), (resp) => { errores = resp })
    requerido   (requeridos.pass1, errores, mensObligatorio("Contraseña"), (resp) => { errores = resp })
    requerido   (requeridos.pass2, errores, mensObligatorio("Repite la Contraseña"), (resp) => { errores = resp })
    requerido   (requeridos.paisSelect.value, errores, mensObligatorio("País"), (resp) => { errores = resp })
    /* requerido   (requeridos.municipioSelect.value, errores, mensObligatorio("Municipio"), (resp) => { errores = resp })
    requerido   (requeridos.parroquiaSelect.value, errores, mensObligatorio("Parroquia"), (resp) => { errores = resp }) */
    email       (requeridos.email, errores, formatoIncorrecto("Email"), (resp) => { errores = resp })
    numero      (requeridos.phoneNumber, errores, numericoIncorrecto("Teléfono"), (resp) => { errores = resp })
    sinEspacios (requeridos.pass1, errores, mensSinEspacios("Contraseña"), (resp) => { errores = resp })
    sinEspacios (requeridos.pass2, errores, mensSinEspacios("Repite la Contraseña"), (resp) => { errores = resp })


    if (requeridos.phoneNumber.length < 8){
        let mess = errores.errMesss;
        mess.push("Debe ingresar un número telefónico <strong class = 'text-danger'>válido</strong>")

        errores = {
            cantidad: errores.cantidad + 1,
            errMesss: mess
        }
    }

    // Lo mismo que lo de ababjo pero con el apellido
    if (espaciosApellido > 1) {
        let mess = errores.errMesss;
        mess.push("Su apellido <strong class = 'text-danger'>no debe contener</strong> mas de un espacio")

        errores = {
            cantidad: errores.cantidad + 1,
            errMesss: mess
        }
    }

    if (espacios > 1) {
        let mess = errores.errMesss;
        mess.push("Su nombre <strong class = 'text-danger'>no debe contener</strong> mas de un espacio")

        errores = {
            cantidad: errores.cantidad + 1,
            errMesss: mess
        }
    }

    if(requeridos.accepted === false){

        let mess = errores.errMesss;
        mess.push("Su contraseña <strong class = 'text-danger'>No cumple con los requisitos</strong> de seguridad")

        errores = {
            cantidad: errores.cantidad + 1,
            errMesss: mess
        }
    }

    if (requeridos.pass1 !== requeridos.pass2){
        let mess = errores.errMesss;
        mess.push("Las contraseñas <strong class = 'text-danger'>No Coinciden</strong>")

        errores = {
            cantidad: errores.cantidad + 1,
            errMesss: mess
        }
    }
    

    if(requeridos.age < 18){
        let mess = errores.errMesss;
        mess.push("Debe ser  <strong class = 'text-danger'>mayor de edad</strong> para poder registrarse")

        errores = {
            cantidad: errores.cantidad + 1,
            errMesss: mess
        }
    } else if(requeridos.age > 100){
        let mess = errores.errMesss;
        mess.push("Debe <strong class = 'text-danger'>estar vivo</strong> para poder registrarse")

        errores = {
            cantidad: errores.cantidad + 1,
            errMesss: mess
        }
    }

    if (errores.cantidad === 0){
        let Content = {
            ciNac: campos.ciSelect.value,
            ci: campos.ciNum,
            name: campos.name, 
            last_name: campos.lastName,
            date_of_birth: `${yyyy}-${mm}-${dd}`,
            phone_number: campos.phoneNumber,
            age: campos.age,
            email: campos.email,
            password: campos.pass1,
            password_confirmation: campos.pass2,
            pais: campos.paisSelect.value,
            estado: campos.estadoSelect.value,
            municipio: 1,
            parroquia: 1,
            recaptchaToken: campos.token
        }

        Post(`${Url}register`, Content, (resp) => {
            let data = JSON.parse(resp)
            callback([errores, data])
        }); 
    }else{
        callback([errores])
    }
}

export const LoginFunction = (user, pass, token, callback) => {
    let Content = {
        user: user, 
        pass: pass,
        recaptchaToken: token
    }

    Post(`${Url}login`, Content, (resp) => {
        let data = JSON.parse(resp)
        callback(data)

    });
}

export const ChkSec = (uid, eid, callback) => {
    let Content = {
        uid: uid, 
        eid: eid,  
    }

    Post(`${Url}chkSec`, Content, (resp) => {
        let data = JSON.parse(resp);
        callback(data)
    });
}

export const RestartPass = (email, callback) => {
    let Content = {
        email: email  
    }

    Post(`${Url}restartPass`, Content, (resp) => {
        let data = JSON.parse(resp);
        callback(data)
    });
}

export const VerifyUrlPass = (token, callback) => {
    let Content = {
        token: token
    }

    Post(`${Url}verifyUrlPass`, Content, (resp) => {
        let data = JSON.parse(resp);
        callback(data)
    });
}

export const RestartPassUrl = (token, pass1, pass2, callback) => {
    let Content = {
        token: token,
        pass1: pass1,
        pass2: pass2
    }

    Post(`${Url}restartPassUrl`, Content, (resp) => {
        let data = JSON.parse(resp);
        callback(data)
    });
}

export const ConsultPaises = (callback) => {
    Post(`${Url}consultPaises`, "", (resp) => {
        let data = JSON.parse(resp);
        callback(data)
    });
}

export const ConsultEstados = (idPais, callback) => {
    let Content = {
        idPais: idPais
    }

    Post(`${Url}consultEstados`, Content, (resp) => {
        let data = JSON.parse(resp);
        callback(data)
    });
}

export const ConsultMunicipios = (idEstado, callback) => {
    let Content = {
        idEstado: idEstado
    }

    Post(`${Url}consultMunicipios`, Content, (resp) => {
        let data = JSON.parse(resp);
        callback(data)
    });
}

export const ConsultParroquias = (idMunicipio, callback) => {
    let Content = {
        idMunicipio: idMunicipio
    }

    Post(`${Url}consultParroquias`, Content, (resp) => {
        let data = JSON.parse(resp);
        callback(data)
    });
}