import React, {useEffect, useState} from "react";
import { Button, Input, Col } from "reactstrap";

const MesasOption = (props) => {
    const [superior, setSuperior]   = useState(4);
    const [izquierda, setIzquierda] = useState(4);
    const [derecha, setDerecha]     = useState(4);
    const [inferior, setInferior]   = useState(4);

    let sillas = (clase, posicion) => {
        let Content = [];
        for (let i = 0; i < posicion; i++) Content.push(i);

        return (
            Content.map(option => (
                <span key = {option} className = {clase} style = {props.customStyles}></span>
            ))
        )
    }

    useEffect(() => {
        setSuperior(props.cantSillas.superior)
        setIzquierda(props.cantSillas.izquierda)
        setDerecha(props.cantSillas.derecha)
        setInferior(props.cantSillas.inferior)

        props.updConf({
            superior: props.cantSillas.superior,
            izquierda: props.cantSillas.izquierda,
            derecha: props.cantSillas.derecha,
            inferior: props.cantSillas.inferior
        })
    }, []);

    let updConfig = () => {
        props.updConf({
            superior: superior,
            izquierda: izquierda,
            derecha: derecha,
            inferior: inferior
        })
    }

    return (
        <>
            <Col md = {12} style = {{borderTop: "1px solid #0000001a"}} className = "custom-mt-10"></Col>
            <Col md = {12} align = "left" className = "custom-mt-10">
                <span className = "text-primary">Agregar sillas</span>
            </Col>
            <Col md = {4} className = "custom-mt-10"></Col>
            <Col md = {4} className = "custom-mt-10" align = "center">
                <small>Superior</small>
                <Input type = "number" value = {superior} onChange = {(e) => {
                    switch(true){
                        case ((e.target.value) > 4): setSuperior(4); break;
                        case ((e.target.value) < 0): setSuperior(0); break;
                        default: setSuperior(e.target.value); break;
                    }
                }}/>
            </Col>
            <Col md = {4} className = "custom-mt-10"></Col>
            {/* ------- */}
            <Col md = {4}></Col>
            <Col md = {4} align = "center" className = "custom-mt-10 mb-2">
                {sillas("silla-mesa-h", superior)}
            </Col>
            <Col md = {4}></Col>
            {/* ------- */}
            <Col md = {3} style = {{paddingLeft: 6, paddingRight: 0,}} align = "center" className = "text-center">
                <small>Izquierda</small>
                <Input type = "number" value = {izquierda} onChange = {(e) => setIzquierda(e.target.value)}/>
            </Col>
            <Col md = {1} style = {{paddingLeft: 0, paddingRight: 0,}} align = "right" className = "text-center">
                {sillas("silla-mesa-v", izquierda)}
            </Col>
            <Col md = {4} align = "center" style = {{paddingLeft: 7, paddingRight: 7,}}>
                <div className = "mesa"></div>
            </Col>
            <Col md = {1} style = {{paddingLeft: 0, paddingRight: 0,}} className = "text-center">
                {sillas("silla-mesa-v", derecha)}
            </Col>
            <Col md = {3} style = {{paddingLeft: 0, paddingRight: 6,}} align = "center" className = "text-center">
                <small>Derecha</small>
                <Input type = "number" value = {derecha} onChange = {(e) => setDerecha(e.target.value)}/>
            </Col>
            {/* ------- */}
            <Col md = {4}></Col>
            <Col md = {4} align = "center" className = "custom-mt-7">
                {sillas("silla-mesa-h", inferior)}
            </Col>
            <Col md = {4}></Col>
            {/* ------- */}
            <Col md = {4} className = "custom-mt-10"></Col>
            <Col md = {4} className = "custom-mt-10" align = "center">
                <Input type = "number" value = {inferior} onChange = {(e) => {
                    switch(true){
                        case ((e.target.value) > 4): setInferior(4); break;
                        case ((e.target.value) < 0): setInferior(0); break;
                        default: setInferior(e.target.value); break;
                    }
                }}/>
                <small>Inferior</small>
            </Col>
            <Col md = {4} className = "custom-mt-10"></Col>
            <Col md = {12} className = "custom-mt-10" align = "center">
                <Button color = "info" type = "button" size = "sm" onClick = {updConfig}>
                    Aplicar configuración
                </Button>
            </Col>
            <Col md = {12} style = {{borderTop: "1px solid #0000001a"}} className = "custom-mt-10"></Col>
        </>
    )
}

export default MesasOption;