import React, { useState } from 'react';
import '../../assets/css/custom_style.css' ; // Asegúrate de importar tu archivo CSS

const PreguntasFrecuentes = ({preguntas,titulo, type}) => {
  const [indiceActivo, setIndiceActivo] = useState(null);

  const handleClick = (indice) => {
    setIndiceActivo(indiceActivo === indice ? null : indice);
  };

  return (
    <>
    <h4 className={type}><strong>{titulo}</strong></h4>
        <div className="accordion mb-3" id="accordionExample">
      {preguntas.map((pregunta, indice) => (
        <div className="card" key={indice}>
          <div className="card-header" id={`heading${indice}`}>
            <h2 className="mb-0">
              <span
                className={`btn btn-link btn-block text-left  ${indiceActivo === indice ? '' : 'collapsed'}`}
                type="button"
                data-toggle="collapse"
                data-target={`#collapse${indice}`}
                aria-expanded={indiceActivo === indice}
                aria-controls={`collapse${indice}`}
                onClick={() => handleClick(indice)}
              >
                {pregunta.titulo}
              </span>
            </h2>
          </div>
          <div
            id={`collapse${indice}`}
            className={`accordion-collapse collapse ${indiceActivo === indice ? 'show' : ''}`}
            aria-labelledby={`heading${indice}`}
            data-parent="#accordionExample">
            <div className="card-body acordeon-animado">
              {pregunta.contenido}
            </div>
          </div>
        </div>
      ))}
    </div></>
  );
};

export default PreguntasFrecuentes;
