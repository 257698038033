import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";
import {Administrador, Cliente, Productor, Taquilla, Verificador} from "components/Routes/MainRoutes";


const Sidebar = (props) => {
    const [ContentData, setContentData] = useState(JSON.parse(sessionStorage.getItem("ContentData")))

    useEffect(() => {
        setContentData(JSON.parse(sessionStorage.getItem("ContentData")));
    }, []);

    let css = (name) => {
        if (name === props.active) return "custom-border-r-active";
        return "custom-border-r";
    }

    let stylesIcon = (name, color) => {
        if (name === props.active) return {
            color: "##05539a"
        }
        
        return {color: "#424242"}
    }

    let content = () => {
        if (ContentData === null) return null;

        switch(ContentData.U_TYPE){
            case 1: return (
                <Row>
                    {Administrador.map(option => (
                        <Col md = {12} key = {option.id} className = "custom-ml-0">
                            <Link to = {option.path}>
                                <Card className = {css(option.name)}>
                                    <CardBody style = {{padding: 12}}>
                                        <i className = {option.icon} style = {stylesIcon(option.name, option.color)}></i> <span style = {{marginLeft: 10}}>{option.name}</span>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            )
            case 2: return (
                <Row>
                    {Productor.map(option => (
                        <Col md = {12} key = {option.id} className = "custom-ml-0">
                            <Link to = {option.path}>
                                <Card className = {css(option.name)}>
                                    <CardBody style = {{padding: 12}}>
                                        <i className = {option.icon} style = {stylesIcon(option.name, option.color)}></i> <span style = {{marginLeft: 10}}>{option.name}</span>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            )
            case 3: return (
                <Row>
                    {Cliente.map(option => (
                        <Col md = {12} key = {option.id} className = "custom-ml-0">
                            <Link to = {option.path}>
                                <Card className = {css(option.name)}>
                                    <CardBody style = {{padding: 12}}>
                                        <i className = {option.icon}style = {stylesIcon(option.name, option.color)}></i> <span style = {{marginLeft: 10}}>{option.name}</span>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            )
            case 4: return (
                <Row>
                    {Taquilla.map(option => (
                        <Col md = {12} key = {option.id} className = "custom-ml-0">
                            <Link to = {option.path}>
                                <Card className = {css(option.name)}>
                                    <CardBody style = {{padding: 12}}>
                                        <i className = {option.icon} style = {stylesIcon(option.name, option.color)}></i> <span style = {{marginLeft: 10}}>{option.name}</span>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            )
            case 5: return (
                <Row>
                    {Verificador.map(option => (
                        <Col md = {12} key = {option.id} className = "custom-ml-0">
                            <Link to = {option.path}>
                                <Card className = {css(option.name)}>
                                    <CardBody style = {{padding: 12}}>
                                        <i className = {option.icon} style = {stylesIcon(option.name, option.color)}></i> <span style = {{marginLeft: 10}}>{option.name}</span>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            )
            default: return null;
        }
    }

    return(
        <Container>
            <Card style = {{background: '#ffffff !important'}}>
                <CardHeader style = {{backgroundColor: '#ffffff !important'}}>
                    <Row>
                        <Col md = {12}>
                            <h5><strong>Opciones</strong></h5>
                            <div style = {{fontSize: 12, marginTop: -10}}>Selecciona una opción</div>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody className = "custom-p-0">
                    {content()}
                </CardBody>
            </Card>
        </Container>
)
}

export default Sidebar;