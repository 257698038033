let dominio = document.domain;
let protocolo = window.location.protocol;
let api = '';
let tmp = '';
let img = '';
let ckeditor = '';
let puerto = '';
let banesco = '';

(dominio === "https://www.taquillaenlinea.com/") ? puerto = ':3000' : puerto = '';
(dominio === "https://www.taquillaenlinea.com/") ? api = '/index.php/api/' : api = '/index.php/api/';
(dominio === "https://www.taquillaenlinea.com/") ? tmp = '/tmp/' : tmp = '/tmp/';
(dominio === "https://www.taquillaenlinea.com/") ? img = '/images/' : img = '/images/';
(dominio === "https://www.taquillaenlinea.com/") ? banesco = '/prueba_banesco/' : banesco = '/index.php/prueba_banesco/';
(dominio === "https://www.taquillaenlinea.com/") ? ckeditor = '/ckeditor/' : ckeditor = '/index.php/ckeditor/';

export const Url = 'https://api.taquillaenlinea.com/index.php/api/'
export const UrlImg = 'https://api.taquillaenlinea.com/images/'
export const UrlEditor = 'https://api.taquillaenlinea.com/ckeditor/'
export const UrlMain = 'https://taquillaenlinea.com/' // Aqui si no, colocar la ip del front junto con el puerto
export const UrlBanesco = 'https://api.taquillaenlinea.com/index.php/prueba_banesco/'
export const UrlTmp = 'https://api.taquillaenlinea.com/tmp/'
export const UrlZones = 'https://api.taquillaenlinea.com/zones/'

//export const Url = `${protocolo}//${dominio}${api}`;
//export const UrlTmp = `${protocolo}//${dominio}${tmp}`;
//export const UrlImg = `${protocolo}//${dominio}${img}`;
//export const UrlEditor = `${protocolo}//${dominio}${ckeditor}`;
//export const UrlMain = `${protocolo}//${dominio}${puerto}`;
//export const UrlBanesco = `${protocolo}//${dominio}${banesco}`;


/*export const Url = 'https://api.taquillaenlinea.com/api/';
export const UrlImg = 'https://api.taquillaenlinea.com/images/';
export const UrlEditor = 'https://api.taquillaenlinea.com/ckeditor/';
export const UrlMain = 'https://www.taquillaenlinea.com';
export const UrlBanesco = `$https://www.taquillaenlinea.com/index.php/prueba_banesco/`;*/
