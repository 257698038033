import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

import { Card, CardBody, CardHeader, Row, Col, Table } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faTimesCircle, faHome, faPaste } from '@fortawesome/free-solid-svg-icons';

import NavbarI from "components/Navbars/NavbarI.js";
import Sidebar from '../dashboard/Sidebar';
import { MisCompras } from "functions/EventosFunctions";

import DetalleCompra from "./DetalleCompra";

const MainCompras = (props) => {
    const [preload, setPreload] = useState({icon: faCircleNotch, spin: true, text: "Buscando tus entradas compradas"});
    const [compras, setCompras] = useState([]);

    useEffect(() => {
        MisCompras ((resp) => {
            switch (true) {
                case (resp.status === "error" || resp.status === "error_log"):
                    toast.error(<small><FontAwesomeIcon icon = {faTimesCircle}/> <span dangerouslySetInnerHTML = {{ __html: resp.mess }}></span></small>);
                break;
                case (resp.data.length === 0):
                    setPreload({icon: faTimesCircle, spin: false, text: "Esta función aún no tiene ventas registradas"})
                break;
                default:
                    (resp.data).forEach(i => {
                        setCompras(resp.data);
                    })
                break;
            }
        })
    }, [props]);

    let comprasList = () => {
        if (compras.length === 0) return (
            <tr>
               <td colSpan = {6} align = "center">
                   <FontAwesomeIcon icon = {preload.icon} spin = {preload.spin} /> {preload.text}
               </td>
           </tr>
        )

        let num = 0;

        return (
            compras.map(option => {
                num++;
                return (
                    <DetalleCompra key = {option.id} data = {option} num = {num}/>
                )
            })
        )
    }

    return (
        <>
            <NavbarI />
            <main>
                <section className="section section-shaped section-lg">
                    <div className = "d-block d-sm-block d-md-block" align = "right" style = {{paddingRight: 15}}>
                        <small class = "bread-crumb-custom"><Link to = "/dashboard/home"><FontAwesomeIcon icon = {faHome}/></Link> | <FontAwesomeIcon icon = {faPaste}/> Mis Compras</small>
                    </div>
                    <Row>
                        <Col md = {3} className = "d-none d-sm-none d-md-block">
                            <Sidebar active = "Mis Compras"/>
                        </Col>
                        <Col md = {9} className = "custom-p-32">
                            <Card >
                                <CardHeader>
                                    <Row>
                                        <Col md = {6}>
                                            <h5>Mis Compras</h5>
                                            <div className = "text-muted" style = {{fontSize: 10, marginTop: -10}}>Compras registradas</div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md = {12}>
                                            <small style = {{color: "#424242"}}>Entradas compradas</small>
                                            <div style = {{overflowX: "auto"}}>
                                                <Table className = "mb-0" hover style = {{fontSize: 10 }}>
                                                    <tbody>
                                                        <tr>
                                                            <th>N°</th>
                                                            <th width = "15%">Evento</th>
                                                            <th width = "20%">Tickets</th>
                                                            <th width = "50%">Detalles del pago</th>
                                                            <th width = "15%">Acciones</th>
                                                        </tr>
                                                        {comprasList()}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </section>
            </main>
        </>
    )
}

export default MainCompras;