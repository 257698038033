import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  UncontrolledCollapse, DropdownMenu, DropdownToggle, UncontrolledDropdown, Media, NavbarBrand, Navbar,
  Nav, Row, Col,
} from "reactstrap";
import { faSignOutAlt, faUserCircle, faChevronDown } from '@fortawesome/free-solid-svg-icons';


import logoMenuTop from '../../assets/img/logos/logo_origin.png';

import { SearchCategories } from "functions/SlidersFunctions";
import { DelButacasUser } from "functions/EventosFunctions";


const NavbarI = (props) => {
  const [collapseClasses, setCollapseClasses] = useState("")
  const [ContentData, setContentData] = useState("")
  const [categorias, setCategorias] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  const history = useHistory();


  // jgil: Para cerrar el dropdown cuando se haga click fuera de el
  const navDropdownRef = useRef(null);
  const buttonRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && navDropdownRef.current && !navDropdownRef.current.contains(event.target)) {
        buttonRef.current.click();
      }
    };

    // Escuchar por click fuera del dropdown
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Limpiar el event listener cuando el componente se desmonte
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);


  useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));

    headroom.init();
    setContentData(JSON.parse(sessionStorage.getItem("ContentData")));

    SearchCategories((resp) => {
      setCategorias(resp.data)
    })

  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };


  let onExiting = () => {
    setCollapseClasses("collapsing-out")
  };

  let onExited = () => {
    setIsOpen(false)
    setCollapseClasses("")
  };

  let Logout = () => {
    DelButacasUser(() => {
      sessionStorage.removeItem("ContentData")
      sessionStorage.removeItem("ContentDataEvent")
      sessionStorage.removeItem("ContentDataButacas")
      sessionStorage.removeItem("ContentDataPrecioZona")
      sessionStorage.removeItem("ContentDataT")
      sessionStorage.removeItem("ContentDataCart")
      // Verifica si la ruta actual es la raíz
      if (window.location.pathname === '/') {
        // Recarga la página desde la caché
        window.location.reload();
      } else {
        // Redirige a la raíz
        history.push('/');
      }
    });
  }

  let toLogin = () => (
    <>
      <UncontrolledDropdown nav>
        <DropdownToggle nav>
          <span className="btn btn-outline-warning"><i className="fa fa-sign-in"></i> <span style={{ marginLeft: 2 }}>Ingresar</span></span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-s" style={{ top: "auto !important" }}>
          <div className="dropdown-menu-inner">
            <Media className="d-flex align-items-center">
              <Media body className="ml-3">
                <Link to="/register">
                  <div style={{ color: "#000", fontSize: 12 }}><strong>Regístrarme</strong></div>
                </Link>
              </Media>
            </Media>
            <Media className="d-flex align-items-center">
              <Media body className="ml-3">
                <Link to="/login">
                  <div style={{ color: "#000", fontSize: 12 }}><strong>Inicio de sesión</strong></div>
                </Link>
              </Media>
            </Media>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  )

  let contentMenu = () => {

    switch (true) {
      case (ContentData === null): return toLogin()
      case (ContentData.U_ID === undefined): return toLogin()
      case (ContentData.U_ID === ""): return toLogin()
      default: return (
        <Nav className="nav-link-inner--text align-items-md-center" navbar>
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <i className="ni ni-ui-04 d-lg-none mr-1" />
              <span><strong>Bienvenid@ {ContentData.U_NAME}</strong></span>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-s" style={{ top: "auto !important" }}>
              <div className="dropdown-menu-inner">
                <Media className="d-flex align-items-center">
                  <Media body className="ml-3">
                    <Link to="/dashboard">
                      <h6 className="heading text-primary mb-md-1">
                        <FontAwesomeIcon icon={faUserCircle} className="text-primary" /> Mi cuenta
                      </h6>
                    </Link>
                  </Media>
                </Media>
                <Media className="d-flex align-items-center">
                  <Media body className="ml-3">
                    <h6 className="heading text-primary mb-md-1" onClick={Logout} style={{ cursor: "pointer" }}>
                      <FontAwesomeIcon icon={faSignOutAlt} className="text-danger" /> Cerrar sesión
                    </h6>
                  </Media>
                </Media>
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      )
    }
  }

  const verifyCart = () => {
    const cart = ['ContentDataEvent', 'ContentDataButacas', 'ContentDataT', 'ContentDataCart'];

    cart.forEach(c => {
      if (sessionStorage.getItem(c)) {
        sessionStorage.removeItem(c);
      }
    });
  }

  return (
    <>
      <header className="header-global" ref={navDropdownRef}>
        <Navbar className="navbar-main navbar-transparent navbar-light headroom" expand="lg" id="navbar-main">
          {/* <Container> */}
          <NavbarBrand className="mr-lg-5">
            <Link to="/" onClick={verifyCart}><img src={logoMenuTop} style={{ height: "auto", width: 200 }} alt="logo_menu" /></Link>
          </NavbarBrand>
          <button className="navbar-toggler" id="navbar_global" ref={buttonRef} onClick={toggle}>
            <span className="fa fa-navicon" />
          </button>
          <UncontrolledCollapse
            toggler="#navbar_global"
            navbar
            className={isOpen ? 'show' : ''}
            onExiting={onExiting}
            onExited={onExited}
          >

            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">

                  <img alt="..." src={logoMenuTop} />

                </Col>

                <Col className="collapse-close" xs="6">

                  <button className="navbar-toggler" id="navbar_global" onClick={toggle}>
                    <span />
                    <span />
                  </button>

                </Col>
              </Row>
            </div>
            <Nav className="navbar-nav-hover align-items-lg-center" navbar>

              {/* Categorias Comentadas */}
              {/* <UncontrolledDropdown nav>
                <DropdownToggle nav>
                  <i className="ni ni-ui-04 d-lg-none mr-1" />
                  <span className="nav-link-inner--text">Categorías <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: 9 }} /></span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-xl">
                  <div className="dropdown-menu-inner" style={{ overflowY: "auto", msOverflowX: "hidden", maxHeight: "40vh" }}>
                    {categorias.map(option => (
                      <Media className="d-flex align-items-center" key={option.id}>
                        <Media body className="ml-3">
                          <Link to={`/categoria_evento${option.to_category}`}>
                            <h6 className="heading text-primary mb-md-1">
                              {option.name_category}
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              {option.desc_category}
                            </p>
                          </Link>
                        </Media>
                      </Media>
                    ))}
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown> */}
            </Nav>
            <Nav className="align-items-lg-center ml-lg-auto" navbar >
              {contentMenu()}
            </Nav>
          </UncontrolledCollapse>
          {/* </Container> */}
        </Navbar>
      </header>
    </>
  )
}

export default NavbarI;
