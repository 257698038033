import React from 'react';
import { Row, Col } from "reactstrap";

const Nav = (props) => {
    const dots = [];
    for (let i = 1; i <= props.totalSteps; i += 1) {
        const isActive = props.currentStep >= i;
        
        dots.push((
            <Col md = {4} sm = {4} xs = {4} key = {`step-${i}`} align = "center">
                <span className = {`linea-h ${isActive ? 'lh-enabled' : 'lh-disabled'}`} style = {{display: (i === 3) ?  'none' : 'inline-block'}}/>
                <span className = {`dot2 ${isActive ? 'active2' : 'dot2-disabled'}`} /* onClick = {() => props.goToStep(i)} */>
                    <strong>{i}</strong>
                </span>
            </Col>
        ));
    }

    return (
        <Row className = "nav2">{dots}</Row>
    );
};

export default Nav;
